import { useState, useImperativeHandle, forwardRef } from 'react'
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import SaveButton from 'components/misc/Button';
import Api from 'helpers/Api';

function Clone(props, ref) {

    const defaultData = {
        categories: 1,
        files: 1,
        features: 1,
    };

    const [state, setState] = useState({
        show: false,
        params: null,
        id: null,
        data: defaultData,
        loading: false,
        onSuccess: null,
        onClose: null,
    });

    useImperativeHandle(ref, () => ({
        open: (id) => {
            handleShow(id);
        },
        close: () => {
            handleClose();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        }
    }));

    const handleShow = (id) => {
        setState(prev => ({
            ...prev,
            show: true,
            id: id,
            data: defaultData,
        }));
    }

    const handleClose = () => {
        setState(prev => ({
            ...prev,
            show: false,
        }));

        if (typeof state.onClose === 'function') {
            state.onClose();
        }
    }

    const handleSave = e => {

        e.preventDefault();

        if (state.loading) {
            return;
        }

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        let url = 'articles/clone'

        Api.post(url, {
            id: state.id,
            with: state.data,
        }).then(res => {
            if (typeof state.onSuccess === 'function') {
                state.onSuccess(res.data)
            }

            handleClose();
        }).catch(() => {
            //
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }));
        });
    }

    const handleCheckboxChange = e => {
        const name = e.target.name;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: prev.data[name] ? false : true
            }
        }));
    }


    return (
        <Modal centered show={state.show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Клониране на артикул
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Alert>
                    Изберете кои данни на артикула освен основните да бъдат клонирани.
                </Alert>
                <Form.Check
                    type="switch"
                    name="categories"
                    label="Клонирай категоризация"
                    value={1}
                    checked={Number(state.data?.categories) === 1}
                    onChange={handleCheckboxChange}
                />
                <Form.Check
                    type="switch"
                    name="files"
                    label="Клонирай изображения"
                    value={1}
                    checked={Number(state.data?.files) === 1}
                    onChange={handleCheckboxChange}
                />
                <Form.Check
                    type="switch"
                    name="features"
                    label="Клонирай характеристики"
                    value={1}
                    checked={Number(state.data?.features) === 1}
                    onChange={handleCheckboxChange}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Отказ
                </Button>
                <SaveButton
                    loading={state.loading}
                    className="save"
                    onClick={e => handleSave(e)}
                />
            </Modal.Footer>
        </Modal>
    )
}

export default forwardRef(Clone);