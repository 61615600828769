import { useRef } from 'react';
import Form from '../partials/Form';
import { useArticleContext } from '../Provider';
import SaveButton from 'components/misc/Button';

function Index(props) {

    const context = useArticleContext();

    const formRef = useRef(null);

    const onSuccess = (data) => {
        if (typeof props.onSuccess === 'function') {
            props.onSuccess(data);
        }
    }

    return (
        <>
            <Form
                ref={formRef}
                id={props.id}
                show={props.show}
                hide={props.hide}
                onSuccess={onSuccess}
            />

            <div
                className="mt-3"
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <SaveButton
                    loading={context.state?.loading}
                    className="save"
                    onClick={e => formRef.current?.save(e)}
                />
            </div>
        </>
    )
}

export default Index;