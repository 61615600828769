import { useEffect, useRef, useState } from 'react';
import Api from 'helpers/Api';
import { buildUrl, useQuery } from 'helpers/Url';
import { useLocation, useNavigate, Link, useParams } from 'react-router-dom';
import { Button, Table, Form, Row, Col, Container, InputGroup } from 'react-bootstrap';
import { ArrowLeftCircle, Cash, Coin, CurrencyDollar, Envelope, Files, InfoCircle, PencilSquare, Percent, Rainbow, Receipt, TrashFill, Upload, XCircle } from 'react-bootstrap-icons';
import { useNestedState } from 'helpers/NestedState';
import { useCurrencyContext } from 'providers/Currency';
import { useValidation } from 'helpers/Validation';
import { useAuthDataContext } from 'providers/Auth';
import { cloneDeep } from 'lodash';
import { useAppContext } from 'providers/App';

import Question from 'components/modals/Question';
import Layout from 'components/layout/Layout';
import Client from 'components/pages/clients/partials/AddOrEdit';
import Article from 'components/pages/articles/partials/AddOrEdit';
import Address from 'components/pages/clients/partials/Addresses';
import Close from './partials/Close';
import PackageCard from './partials/PackageCard';
import SelectAccountRowStorno from './partials/SelectAccountRowStorno';
import SendMail from './partials/SendMail';
import EditAttributes from './partials/EditAttributes';

//misc
import Loader from 'components/misc/Loader';
import Autocomplete from 'components/misc/Autocomplete';
import SaveButton from 'components/misc/Button';
import HelpInfo from 'components/misc/Info';
import Error from 'components/modals/Error';
import Info from 'components/modals/Error';

import moment from 'moment';

import { addExtraPrice, calcDiscount, calcExtraPrice, convertFromRateToRate, isNumeric, removeExtraPrice, vatPrice } from 'helpers/Price';
import Refs from 'Refs';
import DatePicker from 'components/partials/filter/DatePicker';
import Import from 'components/modals/Import';
import { TYPES } from 'constants/imports';
import PriceValue from 'components/partials/PriceValue';

let timeout;

function Index() {

    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const query = useQuery();

    const app = useAppContext();
    const auth = useAuthDataContext();
    const currencies = useCurrencyContext();

    const store = auth.getUser()?.getStore();

    const defaultData = {
        store: store,
        user_id: auth.getUser().getId(),
        with_invoice: 0,
        currency_id: currencies.getDefault()?.id || '',
        currency_rate: currencies.getDefault()?.rate || '',
        currency: currencies.getDefault(),
        rows: [{}],
        allow_close: true,
    };

    const [state, setState] = useNestedState({
        loading: false,
        loadingBase: false,
        loadingExit: false,
        loadingClose: false,
        dataLoading: false,
        data: defaultData,
        timeout: null
    });

    const [cashdesks, setCashdesks] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [speditors, setSpeditors] = useState([]);
    const [addresses, setAddresses] = useState([]);

    const [validations, setValidations] = useValidation();

    const formRef = useRef(null);
    const clientModalRef = useRef(null);
    const articleModalRef = useRef(null);
    const addressModalRef = useRef(null);
    const questionModalRef = useRef(null);
    const errorModalRef = useRef(null);
    const infoModalRef = useRef(null);
    const closeModalRef = useRef(null);
    const packageCardModalRef = useRef(null);
    const selectStornoModalRef = useRef(null);
    const importModalRef = useRef(null);
    const sendMailModalRef = useRef(null);
    const attributesModalRef = useRef(null);

    useEffect(() => {
        if (!params.id) {
            setState(prev => ({
                ...prev,
                data: defaultData
            }));
        }
    }, [params.id, auth.getUser()?.getStore()]);

    useEffect(() => {
        Api.get('accounts/payment-methods')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setPaymentMethods(res.data);
                }
            });

        Api.get('accounts/speditors')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setSpeditors(res.data);
                }
            });
    }, []);

    useEffect(() => {
        Api.get('accounts/cashdesks', {
            params: {
                store_id: state.data.store?.id,
            }
        }).then(res => {
            if (Array.isArray(res.data)) {
                setCashdesks(res.data);
            }
        });
    }, [state.data.store?.id])

    useEffect(() => {
        if (params.id) {
            loadData();
        }
    }, [params.id]);

    // зареждане на данни от поръчка/сметка
    useEffect(() => {
        if (params.id) {
            return;
        }

        // от клиентска заявка
        if (query.get('from_order_id')) {
            loadDataFromOrder();
        }

        // от зареждане
        if (query.get('from_load_id')) {
            loadDataFromStoreLoad();
        }

        // от прехвърляне
        if (query.get('from_move_id')) {
            loadDataFromStoreMove();
        }

        // от сметка
        if (query.get('from_account_id')) {
            // сторно от сметка
            if (query.get('storno')) {
                loadDataFromAccountStorno();
            }
            // копие от сметка
            else {
                loadDataFromAccount();
            }
        }

        // за клиент
        if (query.get('client_id')) {
            loadDataFromClient();
        }

        // сторно от връщане
        if (query.get('from_return_id')) {
            loadDataFromReturn();
        }

        // от документ
        if (query.get('from_document_id')) {
            loadDataFromDocument();
        }
    }, [location]);

    useEffect(() => {
        if (state.data.rows.length === 0) {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    rows: [{}]
                }
            }));
        }
    }, [state.data.rows]);

    const loadData = () => {

        loading(true);

        let url = 'accounts/edit-data';

        Api.get(url, {
            params: {
                id: params.id
            }
        }).then(res => {
            handleResponse(res);
        }).catch(err => {
            handleError(err);
        }).finally(() => {
            loading(false);
        })
    }

    const loadDataFromOrder = () => {

        loading(true);

        let url = 'accounts/from-order';

        Api.get(url, {
            params: {
                order_id: query.get('from_order_id'),
                store_id: query.get('store_id')
            }
        }).then(res => {
            handleResponse(res);
        }).catch(err => {
            handleError(err);
        }).finally(() => {
            loading(false);
        })
    }

    const loadDataFromStoreLoad = () => {

        loading(true);

        let url = 'accounts/from-storeload';

        Api.get(url, {
            params: {
                load_id: query.get('from_load_id')
            }
        }).then(res => {
            handleResponse(res);
        }).catch(err => {
            handleError(err);
        }).finally(() => {
            loading(false);
        })
    }

    const loadDataFromStoreMove = () => {

        loading(true);

        let url = 'accounts/from-storemove';

        Api.get(url, {
            params: {
                move_id: query.get('from_move_id')
            }
        }).then(res => {
            handleResponse(res);
        }).catch(err => {
            handleError(err);
        }).finally(() => {
            loading(false);
        })
    }

    const loadDataFromAccountStorno = () => {
        loading(true);

        let url = 'accounts/storno-from-account';

        Api.get(url, {
            params: {
                account_id: query.get('from_account_id'),
                rows: query.getAll('rows[]')
            }
        }).then(res => {
            handleResponse(res);
        }).catch(err => {
            handleError(err);
        }).finally(() => {
            loading(false);
        })
    }

    const loadDataFromAccount = () => {
        loading(true);

        let url = 'accounts/account-from-account';

        Api.get(url, {
            params: {
                account_id: query.get('from_account_id'),
                rows: query.getAll('rows[]')
            }
        }).then(res => {
            handleResponse(res);
        }).catch(err => {
            handleError(err);
        }).finally(() => {
            loading(false);
        })
    }

    const loadDataFromClient = () => {
        loading(true);

        let url = 'accounts/from-client';

        Api.get(url, {
            params: {
                client_id: query.get('client_id'),
            }
        }).then(res => {
            handleResponse(res);
        }).catch(err => {
            handleError(err);
        }).finally(() => {
            loading(false);
        })
    }

    const loadDataFromReturn = () => {

        loading(true);

        let url = 'accounts/storno-from-return';

        Api.get(url, {
            params: {
                return_id: query.get('from_return_id')
            }
        }).then(res => {
            handleResponse(res);
        }).catch(err => {
            handleError(err);
        }).finally(() => {
            loading(false);
        })
    }

    const loadDataFromDocument = () => {

        loading(true);

        let url = 'accounts/from-document';

        Api.get(url, {
            params: {
                document_id: query.get('from_document_id')
            }
        }).then(res => {
            handleResponse(res);
        }).catch(err => {
            handleError(err);
        }).finally(() => {
            loading(false);
        })
    }

    const handleResponse = res => {
        let data = res.data;

        let rows = data.rows || [];

        // data.currency_rate = data.currency?.rate;

        rows = rows.map(row => {
            row.article.currency = data.currency;

            return row;
        });

        data.rows = rows;

        setState(prev => ({
            ...prev,
            data: data,
        }));

        // възможните адреси на клиента за доставка
        setAddresses(data?.client?.addresses || []);
    }

    const handleError = error => {
        console.log(error);

        const _err = error.response;

        if (_err && _err.status) {
            if (_err.status === 422) {
                setValidations(_err.data.errors);
            } else {
                let modal = errorModalRef.current;

                if (modal) {
                    modal.open(_err.data.error || _err.data.message);

                    modal.onClose(() => {
                        navigate(-1, {
                            replace: true
                        });
                    });
                }
            }
        }
    }

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            dataLoading: Boolean(loading)
        }));
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleCheckboxChange = e => {
        const name = e.target.name;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: prev.data[name] ? false : true
            }
        }));
    }

    const handleCashdeskChange = e => {
        handleInputChange(e);
    }

    const handleCurrencyChange = e => {
        const name = e.target.name
        const value = e.target.value

        setCurrency(value);

    }

    const setCurrency = id => {
        let currency = currencies.data.find(c => Number(c.id) === Number(id));

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                currency_id: id,
                currency: currency,
                currency_rate: currency ? currency.rate : prev.data.currency_rate,
            }
        }));

        if (state.data.rows.filter(r => r.article).length > 0) {
            let modal = questionModalRef.current;

            if (!modal) {
                return;
            }

            modal.open('Искате ли да се изчислят единичните цени на артикулите спрямо избраната валута?');

            modal.onSuccess(() => {
                setState(prev => ({
                    ...prev,
                    data: {
                        ...prev.data,
                        rows: prev.data.rows.map(r => {
                            let article = r.article;

                            if (article && article.currency) {
                                r.current_price = convertFromRateToRate(r.current_price, article.currency.rate, currency.rate);
                                r.actual_price = convertFromRateToRate(r.actual_price, article.currency.rate, currency.rate);
                                r.avg_delivery_price = convertFromRateToRate(r.avg_delivery_price, article.currency.rate, currency.rate);
                                r.avg_delivery_price_with_vat = convertFromRateToRate(r.avg_delivery_price_with_vat, article.currency.rate, currency.rate);
                                r.current_delivery_price = convertFromRateToRate(r.current_delivery_price, article.currency.rate, currency.rate);
                                r.current_delivery_price_with_vat = convertFromRateToRate(r.current_delivery_price_with_vat, article.currency.rate, currency.rate);
                                r.price_ratio = calculatePriceRatio(r.current_price, r.actual_price);
                                r.article.currency = currency;
                            }

                            return r;
                        })
                    }
                }))
            });
        }
    }

    const handleDiscountChange = e => {
        let value = e.target.value;

        if (!value.includes('+') && !value.includes('-') && value.length > 0) {
            value = '-' + value;
        }

        if (value.includes('+')) {
            value = value.replace('-', '');
        }

        if (value < -100) {
            value = -100;
        }

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                discount: value,
            }
        }));
    }

    const getFormData = () => {
        let form = formRef.current;

        let data = new FormData(form);

        if (params.id) {
            data.append('id', params.id)
        }

        if (query.get('from_order_id')) {
            data.append('from_order_id', query.get('from_order_id'));
        }

        if (query.get('from_account_id')) {
            data.append('from_account_id', query.get('from_account_id'));
        }

        if (query.get('from_return_id')) {
            data.append('from_return_id', query.get('from_return_id'));
        }

        if (query.get('storno')) {
            data.append('storno', query.get('storno'));
        }

        if (query.get('from_document_id')) {
            data.append('from_document_id', query.get('from_document_id'));
        }

        return data;
    }

    const save = (close = false, exit = false, onSuccess = null) => {
        if (state.loading) {
            return;
        }

        setState(prev => ({
            ...prev,
            loading: true
        }));

        setValidations(null);

        let url = params.id ? 'accounts/edit' : 'accounts/add';

        if (close) {
            url = params.id ? 'accounts/edit-and-close' : 'accounts/add-and-close';
        }

        let data = getFormData();

        let body = data;

        if (close) {
            body = {};

            body.id = data.get('id');
            body.from_order_id = data.get('from_order_id');
            body.from_account_id = data.get('from_account_id');
            body.from_return_id = data.get('from_return_id');
            body.storno = data.get('storno');
            body.from_document_id = data.get('from_document_id');

            body.account = Object.fromEntries(data.entries());
            body.close = Object.fromEntries(close.entries());

            body = JSON.stringify(body);
        }

        Api.post(url, body, {
            headers: {
                'content-type': close ? 'application/json' : 'multipart/form-data'
                // 'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (exit) {
                navigate('/accounts');
            } else {
                navigate(buildUrl('/accounts/' + res.data.id + '/edit', {
                    closed: close ? 1 : 0,
                }), {
                    replace: true
                });

                handleResponse(res);
            }

            if (typeof onSuccess === 'function') {
                onSuccess(res.data);
            }
        }).catch(error => {
            const _err = error.response;

            if (_err && _err.status) {
                if (_err.status === 422) {
                    setValidations(_err.data.errors);
                } else {
                    let modal = errorModalRef.current;

                    if (modal) {
                        modal.open(_err.data.error || _err.data.message);
                    }
                }
            }

        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
                loadingBase: false,
                loadingExit: false,
                loadingClose: false,
            }));
        });
    }

    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
            loadingBase: true,
            loadingExit: false,
            loadingClose: false,
        }));

        save(false, false, () => {
            app.showSuccess('Действието е успешно!');
        });
    }

    const handleSaveAndExit = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
            loadingBase: false,
            loadingExit: true,
            loadingClose: false,
        }));

        save(false, true, () => {
            app.showSuccess('Действието е успешно!');
        });
    }

    const handleSaveAndClose = e => {
        e.preventDefault();

        // setState(prev => ({
        //     ...prev,
        //     loading: true,
        //     loadingBase: false,
        //     loadingExit: false,
        //     loadingClose: true,
        // }));

        let modal = closeModalRef.current;

        modal.open(getFormData());

        modal.onSave(data => {
            save(data, false, (res) => {
                app.showSuccess(`Сметка ${res.id} е приключена успешно!`);

                modal.hide();
            });
        });
    }

    const handleChangeClient = data => {

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                client: data,
                client_id: data?.id || '',
                discount: Number(data?.discount),
                paymethod_id: data?.method_id,
            }
        }));

        setAddresses(data?.addresses || []);

        let address = null;

        if (data?.addresses) {
            address = data.addresses[0];
        }

        handleChangeAddress(address);

    }

    const handleChangeClientName = data => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                client: null,
                client_id: '',
                paymethod_id: data?.method_id,
            }
        }));

        setAddresses([]);

        handleChangeAddress(null);
    }

    const handleAddClient = () => {
        let modal = Refs.getInstance().getRef('newClient');

        if (modal) {
            modal.add();
            modal.onSuccess(client => {
                if (client) {
                    handleChangeClient(client);
                }
            });
        }
    }

    const handleShowClient = id => {
        let modal = Refs.getInstance().getRef('client');

        if (modal) {
            modal.open(id);
        }
    }

    const handleChangeUser = data => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                creator: data,
                user_id: data?.id || '',
            }
        }));
    }

    const handleChangeAddress = address => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                deliveryaddress: address,
                delivery_address_id: address?.id,
                speditor_id: address?.speditor_id,
            }
        }));
    }

    const handleEditAddress = () => {
        if (!state.data.delivery_address_id) {
            return;
        }

        let modal = addressModalRef.current;

        if (modal) {
            modal.edit(state.data.delivery_address_id);

            modal.onSuccess(address => {
                if (address) {
                    handleChangeAddress(address);

                    // ако го има в списъка да се обнови...
                    setAddresses(prev => prev.map(a => {
                        if (Number(a.id) === Number(address.id)) {
                            a = address;
                        }

                        return a;
                    }));
                }
            });
        }
    }

    const handleAddAddress = (checkClient = true) => {
        if (!state.data.client_id) {
            if (checkClient) {
                let modal = infoModalRef.current;

                if (modal) {
                    modal.open('Изберете клиент');
                }
            }

            return;
        }

        let modal = addressModalRef.current;

        if (modal) {

            modal.add(state.data.client_id);

            modal.onSuccess(address => {
                if (address) {
                    handleChangeAddress(address);

                    // да го добавим в списъка
                    setAddresses(prev => prev.concat(address));
                }
            });
        }
    }

    const handleClickAddress = () => {
        if (addresses.length === 0) {
            handleAddAddress(false);
        }
    }

    const handleAddRow = e => {
        e.preventDefault()

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: prev.data.rows.concat({})
            }
        }))
    }

    const handleRemoveRow = (e, index) => {
        e.preventDefault()

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: prev.data.rows.filter((row, i) => i !== index)
            }
        }))
    }

    const handleChangeArticleInput = (name, index) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: Object.values({
                    ...prev.data.rows,
                    [index]: {
                        ...prev.data.rows[index],
                        article: null,
                        article_id: null,
                        attrs: [], // изчистваме атрибутитие
                    }
                })
            }
        }));
    }

    const handleChangeArticle = (data, index) => {

        let row = state.data.rows[index];

        let currency = data?.currency;

        data.currency = state.data.currency;

        let currentPrice = convertFromRateToRate(data.current_price || data.actual_price, currency?.rate, state.data.currency_rate);
        let actualPrice = convertFromRateToRate(data.actual_price, currency?.rate, state.data.currency_rate);
        let avgDeliveryPrice = convertFromRateToRate(data.avg_delivery_price, currency?.rate, state.data.currency_rate);
        let avgDeliveryPriceWithVat = convertFromRateToRate(data.avg_delivery_price_with_vat, currency?.rate, state.data.currency_rate);
        let currentDeliveryPrice = convertFromRateToRate(data.current_delivery_price, currency?.rate, state.data.currency_rate);
        let currentDeliveryPriceWithVat = convertFromRateToRate(data.current_delivery_price_with_vat, currency?.rate, state.data.currency_rate);
        let priceRatio = calculatePriceRatio(currentPrice, actualPrice);

        let hasAttrs = data.attrs && data.attrs.length > 0;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: Object.values({
                    ...prev.data.rows,
                    [index]: {
                        ...prev.data.rows[index],
                        article: data,
                        article_id: data.id,
                        amount: data.amount_unit || 1,
                        current_price: currentPrice,
                        actual_price: actualPrice,
                        avg_delivery_price: avgDeliveryPrice,
                        avg_delivery_price_with_vat: avgDeliveryPriceWithVat,
                        current_delivery_price: currentDeliveryPrice,
                        current_delivery_price_with_vat: currentDeliveryPriceWithVat,
                        price_ratio: priceRatio,
                        price_rules: data.price_rules || [],
                        attrs: [], // изчистваме атрибутитие
                        hasPrice: !(data.current_price === null && data.actual_price === null)
                    }
                })
            }
        }));

        // ако има атрибути да покажем модала за редакцията им...
        if (hasAttrs) {
            handleEditArticleAttributes(data, [], index);
        }
    }

    const handleAddArticle = (index) => {
        let modal = Refs.getInstance().getRef('newArticle');

        if (modal) {
            modal.add();
            modal.onSuccess(article => {
                if (article) {
                    handleChangeArticle(article, index);
                }
            });
        }
    }

    const handleShowArticle = id => {
        let modal = Refs.getInstance().getRef('article');

        if (modal) {
            modal.open(id);
        }
    }

    const handleEditArticleAttributes = (article, attrs = [], index) => {
        let modal = attributesModalRef.current;

        if (!modal) {
            return;
        }

        modal.open(article, attrs);

        modal.onSuccess(attrs => {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    rows: Object.values({
                        ...prev.data.rows,
                        [index]: {
                            ...prev.data.rows[index],
                            attrs: attrs
                        }
                    })
                }
            }));
        })
    }

    const handleShowPriceRules = row => {
        let rules = row.price_rules || [];

        if (rules.length > 0) {
            if (rules.length > 1) {
                showChoosePriceRule(rules);
            } else {
                showPriceRule(rules[0]);
            }
        }
    }

    const showPriceRule = id => {
        let modal = Refs.getInstance().getRef('priceRule');

        if (!modal) {
            return;
        }

        modal.edit(id);
    }

    const showChoosePriceRule = (ids) => {
        let modal = errorModalRef.current;

        if (!modal) {
            return;
        }

        modal.open(
            <div style={{ textAlign: 'center' }}>
                Изберете номер на ценово правило, което искате да видите:
                <br />
                <br />
                <div style={{ display: 'flex', justifyContent: 'center', }}>
                    {ids.map(id =>
                        <Button key={id} onClick={e => showPriceRule(id)} style={{ marginRight: '5px' }}>
                            {id}
                        </Button>
                    )}
                </div>
            </div>
        )
    }

    const handleRowAmountChange = (e, index) => {
        let value = e.target.value;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: Object.values({
                    ...prev.data.rows,
                    [index]: {
                        ...prev.data.rows[index],
                        amount: value,
                    }
                })
            }
        }));
    }

    const handleRowAmountBlur = (row, index) => {
        if (row.amount < 0 && !row.id) {
            showSelectStorno(row, index);
        }
    }

    const showSelectStorno = (row, index) => {
        let modal = selectStornoModalRef.current;

        if (!modal) {
            return;
        }

        if (!row.article_id) {
            return;
        }

        modal.open(row.article_id);

        modal.onSave(selected => {
            let stornodata = {
                storno_of_account_row_id: selected.storno_of_account_row_id
            }

            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    rows: Object.values({
                        ...prev.data.rows,
                        [index]: {
                            ...prev.data.rows[index],
                            current_price: selected.current_price,
                            discount: selected.discount,
                            stornodata: stornodata,
                        }
                    })
                }
            }));
        });
    }

    const handleRowCurrentPriceChange = (e, index) => {
        let value = e.target.value;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: Object.values({
                    ...prev.data.rows,
                    [index]: {
                        ...prev.data.rows[index],
                        current_price: value,
                        price_ratio: calculatePriceRatio(value, prev.data.rows[index].actual_price)
                    }
                })
            }
        }));
    }

    const calculatePriceRatio = (from, to) => {
        return ((parseFloat(from || 0) / parseFloat(to || 0)) - 1) * 100;
    }

    // при смяна на количеството 
    const handleQtyChange = (e, index) => {
        handleInputChange(e);

        let value = e.target.value;

    }

    const handleRowDiscountChange = (e, index) => {
        let value = e.target.value;

        if (!value.includes('+') && !value.includes('-') && value.length > 0) {
            value = '-' + value;
        }

        if (value.includes('+')) {
            value = value.replace('-', '');
        }

        if (value < -100) {
            value = -100;
        }

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: Object.values({
                    ...prev.data.rows,
                    [index]: {
                        ...prev.data.rows[index],
                        discount: value
                    }
                })
            }
        }));
    }

    const handleRowPriceRatioChange = (e, index) => {
        let value = e.target.value;

        if (!value.includes('+') && !value.includes('-') && value.length > 0) {
            value = '-' + value;
        }

        if (value.includes('+')) {
            value = value.replace('-', '');
        }

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: Object.values({
                    ...prev.data.rows,
                    [index]: {
                        ...prev.data.rows[index],
                        price_ratio: value,
                        current_price: prev.data.rows[index].actual_price + ((prev.data.rows[index].actual_price * value) / 100),
                    }
                })
            }
        }));
    }

    const getRowTotalPrice = row => {
        return (parseFloat(row.current_price || 0) * parseFloat(row.amount || 0));
    }

    const getRowTotalDiscount = row => {
        return calcDiscount(getRowTotalPrice(row), parseFloat(row.discount || 0));
    }

    const getRowTotalRealPrice = row => {
        return getRowTotalPrice(row) + getRowTotalDiscount(row);
    }

    const getRowPriceRatio = row => {
        return row.price_ratio || '';
    }

    const getRowsWithArticle = () => {
        return state.data.rows.filter(r => r.article_id);
    }

    const getSelectedCount = () => {
        // само редовете с избран артикул да се четат
        let rows = getRowsWithArticle();

        return rows.length;
    }

    const getTotalQty = () => {
        let total = 0;

        state.data.rows.map(row => {
            total += parseFloat(row.quantity || 0);
        });

        return total;
    }

    const getTotalSum = () => {
        let total = 0;

        state.data.rows.map(row => {
            total += getRowTotalRealPrice(row);
        });

        return total;
    }

    const getTotalDiscount = () => {
        return calcDiscount(getTotalSum(), state.data.discount);
    }

    const getTotalReal = () => {
        return getTotalSum() + getTotalDiscount();
    }

    const isDiscount = () => {
        return parseFloat(state.data.discount || 0) <= 0;
    }

    const isDeliveryToAddress = () => {
        return true;
    }

    const getAddressName = address => {
        if (!address) {
            return '';
        }

        let name = [];

        if (address.delivery_type === 'address') {
            name = [
                address.dest_name_full,
                address.address,
                address.description,
                address.country_name,
            ];
        }

        if (address.delivery_type === 'office') {
            name = [
                address.dest_name_full,
                address.office_name,
            ];
        }

        if (address.delivery_type === 'store') {
            name = [
                address?.store?.translation?.name || address?.store?.name,
            ];
        }

        return name.filter(p => p).join(', ');
    }

    const getExtendedAddressName = address => {
        if (!address) {
            return '';
        }

        let map = {
            address: 'Адрес на клиент',
            office: 'Офис на куриер',
            store: 'Търговски обект'
        };

        let type = map[address.delivery_type];

        return type + ': ' + getAddressName(address);
    }

    const handleAddCard = e => {
        e.preventDefault();

        save(false, false, (res) => {
            addCard(res);
        });

    }

    const addCard = (account) => {
        let modal = packageCardModalRef.current;

        if (!modal) {
            return;
        }

        modal.open(account.id, account.client_id);

        // modal.onSave(data => {
        //     // save(false, false, false, () => {
        //     //     app.showSuccess('Действието е успешно!');
        //     // });

        //     console.log(data);
        // })

        modal.onSuccess(res => {
            handleResponse(res);
        });
    }

    const handleImport = () => {
        let modal = importModalRef.current;

        if (!modal) {
            return;
        }

        modal.open();
        // modal.setParams(state.data);

        modal.onChunk(({ is_test, type_id, results }) => {
            // console.log(data);

            // setState(prev => ({
            //     ...prev,
            //     data: {
            //         ...prev.data,
            //         rows: results
            //     }
            // }))

            // замяна или добавяне
            let erase = Number(type_id) === 1;

            let rows;

            if (erase) {
                rows = [];
            } else {
                rows = cloneDeep(state.data.rows);
            }

            // console.log(erase)
            // console.log(rows)
            // console.log(results);

            results.map((row, index) => {
                let data = row.article;

                let currency = data?.currency;

                data.currency = state.data.currency;

                let currentPrice = convertFromRateToRate(row.current_price || data.current_price || data.actual_price, currency?.rate, state.data.currency_rate);
                let actualPrice = convertFromRateToRate(data.actual_price, currency?.rate, state.data.currency_rate);
                let avgDeliveryPrice = convertFromRateToRate(data.avg_delivery_price, currency?.rate, state.data.currency_rate);
                let avgDeliveryPriceWithVat = convertFromRateToRate(data.avg_delivery_price_with_vat, currency?.rate, state.data.currency_rate);
                let currentDeliveryPrice = convertFromRateToRate(data.current_delivery_price, currency?.rate, state.data.currency_rate);
                let currentDeliveryPriceWithVat = convertFromRateToRate(data.current_delivery_price_with_vat, currency?.rate, state.data.currency_rate);
                let priceRatio = calculatePriceRatio(currentPrice, actualPrice);

                rows.push({
                    ...row,
                    article: data,
                    article_id: data.id,
                    amount: data.amount_unit,
                    current_price: currentPrice,
                    actual_price: actualPrice,
                    avg_delivery_price: avgDeliveryPrice,
                    avg_delivery_price_with_vat: avgDeliveryPriceWithVat,
                    current_delivery_price: currentDeliveryPrice,
                    current_delivery_price_with_vat: currentDeliveryPriceWithVat,
                    price_ratio: priceRatio
                });

            });

            // console.log(rows);

            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    rows: Object.values(rows)
                }
            }));
        })

        modal.onSuccess(() => {
            // alert(1);
        })
    }

    const isCurrentPriceDisabled = (row) => {
        if (auth.getUser().permission('accounts_edit_current_price') === false) {
            return true;
        }

        if (row.hasPrice === false && auth.getUser().permission('set_empty_price_article_in_account') === false) {
            return true;
        }

        return false;
    }

    const handleShow = () => {
        let modal = Refs.getInstance().getRef('account');

        modal.open(state.data.id);
    }

    const handleSend = () => {
        let modal = sendMailModalRef.current;

        if (!modal) {
            return;
        }

        modal.show(state.data.id, state.data.client?.email);
    }

    const handleCreateInvoice = () => {
        navigate('/invoices/new?from_account_id=' + state.data.id);
    }

    const handleCreateCreditNote = () => {
        navigate('/invoices/new?from_account_id=' + state.data.id + '&cn=1');
    }

    const handleCreateStorno = () => {
        navigate('/accounts/new?from_account_id=' + state.data.id + '&storno=1');
    }

    const handleCreateClone = () => {
        navigate('/accounts/new?from_account_id=' + state.data.id);
    }

    const handleDelete = () => {
        let modal = questionModalRef.current;

        modal.open('Сигурни ли сте?');
        modal.onSuccess(() => {
            handleDestroy(state.data.id);
        })
    }

    const handleDestroy = id => {
        Api.post('accounts/delete', {
            id: id
        }).then(res => {
            if (res.data.success) {
                navigate('/accounts');
            }
        });
    }

    return (
        <>
            <Article
                ref={articleModalRef}
            />

            <Address
                ref={addressModalRef}
            />

            <Question
                ref={questionModalRef}
            />

            <Error
                ref={errorModalRef}
            />

            <Info
                ref={infoModalRef}
            />

            <Close
                ref={closeModalRef}
                loading={state.loading}
                validations={validations}
            />

            <SendMail
                ref={sendMailModalRef}
            />

            <EditAttributes
                ref={attributesModalRef}
            />

            <PackageCard
                ref={packageCardModalRef}
                loading={state.loading}
                validations={validations}
            />

            <SelectAccountRowStorno
                ref={selectStornoModalRef}
            />

            <Import
                ref={importModalRef}
                type={TYPES.ACCOUNT}
                createText="Замяна"
                updateText="Добавяне"
            />

            <Layout>
                <div className="container-fluid">

                    <div className="page-head">
                        <h1 className="display-6">
                            <Link to={-1} className="link-dark text-decoration-none">
                                <ArrowLeftCircle />
                            </Link> {params.id ? 'Редактиране на продажба' : 'Нова продажба'}
                        </h1>

                        <div className="buttons">

                        </div>
                    </div>

                    <br />

                    <div className="panel">

                        {state.dataLoading
                            ?
                            <Loader />
                            :
                            <>
                                {state.data.id &&
                                    <div className="mb-3">
                                        <Button size="sm" variant="primary" onClick={handleShow} disabled={state.data.allow_view === false}>
                                            <InfoCircle /> Преглед
                                        </Button>
                                        <Button size="sm" variant="primary" onClick={handleSend} style={{ marginLeft: '10px' }}>
                                            <Envelope /> Изпращане
                                        </Button>
                                        {state.data.closed && state.data.allow_invoice &&
                                            <Button size="sm" variant="primary" onClick={handleCreateInvoice} style={{ marginLeft: '10px' }}>
                                                <Receipt /> Издай фактура
                                            </Button>
                                        }
                                        {state.data.allow_credit_note &&
                                            <Button size="sm" variant="danger" onClick={handleCreateCreditNote} style={{ marginLeft: '10px' }}>
                                                <Receipt /> Издай кредитно известие
                                            </Button>
                                        }
                                        {state.data.allow_storno &&
                                            <Button size="sm" variant="secondary" onClick={handleCreateStorno} style={{ marginLeft: '10px' }}>
                                                Сторно сметка
                                            </Button>
                                        }
                                        {state.data.allow_clone &&
                                            <Button size="sm" variant="secondary" onClick={handleCreateClone} style={{ marginLeft: '10px' }}>
                                                <Files /> Клонирай
                                            </Button>
                                        }
                                        {state.data.allow_delete &&
                                            <Button size="sm" variant="danger" onClick={handleDelete} style={{ marginLeft: '10px' }}>
                                                <XCircle /> Анулирай
                                            </Button>
                                        }
                                    </div>
                                }

                                <Form ref={formRef}>
                                    {/* <Container> */}
                                    <div className="form-panel">
                                        <div className="head">
                                            Данни за продажбата
                                        </div>
                                        <Row>
                                            <Col className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Сметка №
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder=""
                                                            name="ref"
                                                            value={state.data.id || ''}
                                                            readOnly
                                                            size="sm"
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Обслужва
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Autocomplete
                                                            variant="basic"
                                                            url="accounts/admins"
                                                            inputValue={``}
                                                            inputIdName={`user_id`}
                                                            inputIdValue={state.data?.user_id || ''}
                                                            onChange={data => handleChangeUser(data)}
                                                            error={Boolean(validations && validations.user_id && (validations.user_id[0] || validations.user_id))}
                                                            helperText={validations && validations.user_id && (validations.user_id[0] || validations.user_id)}
                                                            size="sm"
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Създадена на
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder=""
                                                            name="created_at"
                                                            value={state.data?.created_at ? moment(state.data.created_at).format('DD.MM.YYYY HH:mm') : ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations?.created_at)}
                                                            size="sm"
                                                            readOnly
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations?.created_at && (validations.created_at[0] || validations.created_at)}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Търговски обект
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Control
                                                            placeholder=""
                                                            name="store_name"
                                                            value={state.data.store?.translation?.name || state.data.store?.name || ''}
                                                            isInvalid={Boolean(validations?.store_id)}
                                                            size="sm"
                                                            readOnly
                                                        />
                                                        <input type="hidden" name="store_id" value={state.data.store?.id || ''} />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations?.store_id && (validations.store_id[0] || validations.store_id)}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Каса
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Select
                                                            name="cashdesk_id"
                                                            value={state.data?.cashdesk_id || ''}
                                                            onChange={handleCashdeskChange}
                                                            isInvalid={Boolean(validations?.cashdesk_id)}
                                                            size="sm"
                                                        >
                                                            {cashdesks.map(o =>
                                                                <option key={o.id} value={o.id}>{o?.translation?.name || o.name}</option>
                                                            )}

                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations?.cashdesk_id && (validations.cashdesk_id[0] || validations.cashdesk_id)}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Дата на продажба
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <DatePicker
                                                            variant="basic"
                                                            type="datetime-local"
                                                            name="date"
                                                            value={state.data?.date ? moment(state.data.date).format('YYYY-MM-DDTHH:mm') : ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations?.date)}
                                                            size="sm"
                                                            error={validations?.date && (validations.date[0] || validations.date)}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={4} className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Клиент
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Autocomplete
                                                            variant="basic"
                                                            url="accounts/clients"
                                                            inputName="client_name"
                                                            inputIdName="client_id"
                                                            inputValue={state.data?.client?.name}
                                                            inputIdValue={state.data?.client_id || ''}
                                                            onChange={handleChangeClient}
                                                            onInputChange={handleChangeClientName}
                                                            onClickShowButton={handleShowClient}
                                                            onClickAddButton={handleAddClient}
                                                            inputDisabled={auth.getUser().permission('accounts_set_client') === false}
                                                            addButtonDisabled={auth.getUser().permission('clients_edit') === false}
                                                            error={Boolean(validations?.client_id)}
                                                            helperText={validations?.client_id && (validations.client_id[0] || validations.client_id)}
                                                            renderText={data => {
                                                                return (
                                                                    <div style={{ display: 'flex' }}>
                                                                        {/* <div style={{ width: '100px', marginRight: '10px' }}>
                                                                            {data.id}
                                                                        </div> */}
                                                                        <div style={{ width: '180px', marginRight: '10px' }}>
                                                                            {data.name}
                                                                        </div>
                                                                        <div style={{ width: '150px', }}>
                                                                            {data.tel || data.email}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }}
                                                            renderInputText={data => data.name}
                                                            size="sm"
                                                            listStyle={{
                                                                minWidth: 'max-content'
                                                            }}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col sm={4} className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Отстъпка/Надценка <HelpInfo>минус - отстъпка в проценти <br />плюс - надценка в проценти</HelpInfo>
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Control
                                                            type="text"
                                                            step="0.01"
                                                            placeholder=""
                                                            name="discount"
                                                            value={state.data?.discount || ''}
                                                            onChange={handleDiscountChange}
                                                            size="sm"
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations?.discount && (validations.discount[0] || validations.discount)}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col sm={4} className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Валута
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Select
                                                            name="currency_id"
                                                            value={state.data?.currency_id || ''}
                                                            onChange={handleCurrencyChange}
                                                            isInvalid={Boolean(validations?.currency_id)}
                                                            size="sm"
                                                        >
                                                            {currencies.data.map(o =>
                                                                <option key={o.id} value={o.id}>{o?.translation?.name || o.name}</option>
                                                            )}

                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations?.currency_id && (validations.currency_id[0] || validations.currency_id)}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={4} className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Спедитор
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Select
                                                            name="speditor_id"
                                                            value={state.data?.speditor_id || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations?.speditor_id)}
                                                            size="sm"
                                                        >
                                                            <option value="">Няма</option>
                                                            {speditors.map(o =>
                                                                <option key={o.id} value={o.id}>{o?.translation?.name || o.name}</option>
                                                            )}

                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations?.speditor_id && (validations.speditor_id[0] || validations.speditor_id)}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col sm={4} className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Товарителница №
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Control
                                                            placeholder=""
                                                            name="tracking_number"
                                                            value={state.data?.tracking_number || ''}
                                                            onChange={handleInputChange}
                                                            size="sm"
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations?.tracking_number && (validations.tracking_number[0] || validations.tracking_number)}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col sm={4} className="mb-3">
                                                {isDeliveryToAddress() &&
                                                    <Form.Group as={Row}>
                                                        <Form.Label column="sm" sm={4} className="text-left">
                                                            Данни за доставка
                                                        </Form.Label>
                                                        <Col sm={8}>
                                                            {/* <InputGroup size="sm">
                                                                <Form.Select
                                                                    name="delivery_address_id"
                                                                    value={state.data?.delivery_address_id || ''}
                                                                    onChange={handleInputChange}
                                                                    size="sm"
                                                                >
                                                                    {addresses.map(address =>
                                                                        <option
                                                                            key={address.id}
                                                                            value={address.id}
                                                                        >
                                                                            {getAddressName(address)}
                                                                        </option>
                                                                    )}
                                                                </Form.Select>
                                                                <Button
                                                                    variant="outline-secondary"
                                                                    onClick={handleAddAddress}
                                                                >
                                                                    <PlusLg />
                                                                </Button>
                                                            </InputGroup> */}
                                                            <Autocomplete
                                                                variant="basic"
                                                                data={addresses}
                                                                filterable={false}
                                                                inputValue={getAddressName(state.data?.deliveryaddress)}
                                                                inputIdName={`delivery_address_id`}
                                                                inputIdValue={state.data?.delivery_address_id || ''}
                                                                onChange={data => handleChangeAddress(data)}
                                                                onClickAddButton={e => handleAddAddress()}
                                                                onClick={e => handleClickAddress()}
                                                                error={Boolean(validations && validations.delivery_address_id && (validations.delivery_address_id[0] || validations.delivery_address_id))}
                                                                helperText={validations && validations.delivery_address_id && (validations.delivery_address_id[0] || validations.delivery_address_id)}
                                                                renderText={data => getExtendedAddressName(data)}
                                                                renderInputText={data => getAddressName(data)}
                                                                size="sm"
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                }
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={4}>
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Заглавие
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Control
                                                            placeholder=""
                                                            name="alias"
                                                            value={state.data?.alias || ''}
                                                            onChange={handleInputChange}
                                                            size="sm"
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations?.alias && (validations.alias[0] || validations.alias)}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col sm={4}>
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Телефон
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Control
                                                            placeholder=""
                                                            name="tel"
                                                            value={state.data?.tel || ''}
                                                            onChange={handleInputChange}
                                                            size="sm"
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations?.tel && (validations.tel[0] || validations.tel)}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </div>


                                    <div className="form-panel mt-3">
                                        <div className="head">
                                            Артикули
                                        </div>
                                        <Table className="valign-top big">
                                            <thead>
                                                <tr>
                                                    <th style={{ width: '5%' }}>
                                                        #
                                                    </th>
                                                    <th style={{ width: '15%' }}>
                                                        Артикул
                                                    </th>
                                                    <th>
                                                        Количество
                                                    </th>
                                                    <th>
                                                        Единична цена <HelpInfo>с ДДС</HelpInfo>
                                                    </th>
                                                    <th>
                                                        Отстъпка
                                                    </th>
                                                    <th>
                                                        Тотал
                                                    </th>
                                                    {auth.getUser().permission('show_delivery_price') &&
                                                        <>
                                                            <th>
                                                                Себестойност <HelpInfo>с ДДС</HelpInfo>
                                                            </th>
                                                            <th>
                                                                Последно <HelpInfo>Последно зареждане с ДДС</HelpInfo>
                                                            </th>
                                                        </>
                                                    }
                                                    <th>
                                                        Базова цена
                                                    </th>
                                                    <th>
                                                        Баз$ / Тек$ <HelpInfo>Отношение между базова и текуща продажна цена в проценти</HelpInfo>
                                                    </th>
                                                    <th className="options">

                                                    </th>
                                                </tr>

                                            </thead>
                                            <tbody>
                                                {state.data.rows.map((row, index) =>
                                                    <tr key={'r-' + index} className={row.is_service ? 'trqbva-da-e-disabled' : ''}>
                                                        <td title={row.id || '-'} style={{ width: '5%' }}>
                                                            {index + 1}

                                                            <input type="hidden" name={`rows[${index}][id]`} value={state.data.rows[index].id || ''} />

                                                            {row.stornodata &&
                                                                <input type="hidden" name={`rows[${index}][stornodata][storno_of_account_row_id]`} value={row.stornodata.storno_of_account_row_id || ''} />
                                                            }

                                                            <input type="hidden" name={`rows[${index}][article_id]`} value={state.data.rows[index].article_id || ''} />
                                                            <input type="hidden" name={`rows[${index}][depot_id]`} value={state.data.rows[index].depot_id || ''} />

                                                            {(row.price_rules || []).map(id =>
                                                                <input key={id} type="hidden" name={`rows[${index}][price_rules][]`} value={id} />
                                                            )}
                                                        </td>
                                                        <td style={{ width: '15%' }}>
                                                            {row.is_special
                                                                ?
                                                                <>
                                                                    <Form.Control
                                                                        type="text"
                                                                        value={state.data?.rows[index] && state.data?.rows[index]?.article?.article_name ? state.data?.rows[index]?.article?.article_name : ''}
                                                                        isInvalid={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].article_id && (validations.rows[index].article_id[0] || validations.rows[index].article_id))}
                                                                        size="sm"
                                                                        readOnly={true}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {validations && validations.rows && validations.rows[index] && validations.rows[index].article_id && (validations.rows[index].article_id[0] || validations.rows[index].article_id)}
                                                                    </Form.Control.Feedback>
                                                                </>
                                                                :
                                                                <>
                                                                    <Autocomplete
                                                                        variant="basic"
                                                                        url="accounts/articles"
                                                                        params={{
                                                                            client_id: state.data.client_id,
                                                                        }}
                                                                        inputName="article_name"
                                                                        inputIdName={`rows[${index}][article_id]`}
                                                                        inputValue={state.data?.rows[index] && state.data?.rows[index]?.article?.article_name ? state.data?.rows[index]?.article?.article_name : ''}
                                                                        inputIdValue={state.data?.rows[index] && state.data?.rows[index].article_id ? state.data?.rows[index].article_id : ''}
                                                                        onChange={data => handleChangeArticle(data, index)}
                                                                        onInputChange={data => handleChangeArticleInput(data, index)}
                                                                        onClickAddButton={e => handleAddArticle(index)}
                                                                        onClickShowButton={handleShowArticle}
                                                                        error={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].article_id && (validations.rows[index].article_id[0] || validations.rows[index].article_id))}
                                                                        helperText={validations && validations.rows && validations.rows[index] && validations.rows[index].article_id && (validations.rows[index].article_id[0] || validations.rows[index].article_id)}
                                                                        renderText={data => {
                                                                            return (
                                                                                <div style={{ display: 'flex', }}>
                                                                                    <div style={{ marginRight: '10px' }}>
                                                                                        {data.id}
                                                                                    </div>
                                                                                    <div style={{ width: '150px', marginRight: '10px' }}>
                                                                                        {data.article_name}
                                                                                    </div>
                                                                                    <div style={{ width: '150px', marginRight: '10px' }}>
                                                                                        {data.ref_num}
                                                                                    </div>
                                                                                    <div style={{ width: '100px', marginRight: '20px', textAlign: 'right' }}>
                                                                                        {data.quantity} {data.amount_type_short}
                                                                                    </div>
                                                                                    <div>
                                                                                        <PriceValue prefix={data.currency?.prefix} sufix={data.currency?.sufix}>{data.current_price}</PriceValue>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }}
                                                                        renderInputText={data => data.article_name}
                                                                        size="sm"
                                                                        autoFocus
                                                                        addButtonDisabled={auth.getUser().permission('articles_edit') === false}
                                                                        listStyle={{
                                                                            minWidth: 'max-content'
                                                                        }}
                                                                    />

                                                                </>
                                                            }

                                                            {row.attrs && row.attrs.length > 0 &&
                                                                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                                                                    <div>
                                                                        {row.attrs.map(attr =>
                                                                            <div key={attr.id}>
                                                                                /{attr.name}: {attr.value}/

                                                                                <input type="hidden" name={`rows[${index}][attrs][${attr.id}]`} value={attr.value || ''} />
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    <PencilSquare
                                                                        onClick={e => handleEditArticleAttributes(row.article, row.attrs, index)}
                                                                        style={{
                                                                            fontSize: '14px',
                                                                            marginLeft: '10px',
                                                                            marginTop: '3px',
                                                                            cursor: 'pointer'
                                                                        }}
                                                                    />
                                                                </div>
                                                            }

                                                            {/* {row.stornodata &&
                                                                <>
                                                                    <span style={{ fontSize: '12px' }}>
                                                                        Сторно на сметка №{row.stornodata.storno_of_account_row_id}
                                                                    </span>

                                                                    {!row.id &&
                                                                        <span
                                                                            onClick={e => showSelectStorno(row, index)}
                                                                        >
                                                                            промени
                                                                        </span>
                                                                    }
                                                                </>
                                                            } */}
                                                        </td>

                                                        <td>
                                                            <InputGroup size="sm" style={{ minWidth: '95px' }}>
                                                                <Form.Control
                                                                    type="number"
                                                                    step="0.0001"
                                                                    name={`rows[${index}][amount]`}
                                                                    value={state.data?.rows[index] && state.data?.rows[index].amount ? state.data?.rows[index].amount : ''}
                                                                    onChange={e => handleRowAmountChange(e, index)}
                                                                    onBlur={e => handleRowAmountBlur(row, index)}
                                                                    isInvalid={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].amount && (validations.rows[index].amount[0] || validations.rows[index].amount))}
                                                                    size="sm"
                                                                    className="price-input"
                                                                    readOnly={auth.getUser().permission('accounts_allow_quantity_change') === false}
                                                                />

                                                                <InputGroup.Text>
                                                                    {state.data?.rows[index]?.article?.amount_type_short || '-'}
                                                                </InputGroup.Text>

                                                                {Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].amount) &&
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {validations && validations.rows && validations.rows[index] && validations.rows[index].amount && (validations.rows[index].amount[0] || validations.rows[index].amount)}
                                                                    </Form.Control.Feedback>
                                                                }
                                                            </InputGroup>

                                                        </td>
                                                        <td>
                                                            <InputGroup size="sm" style={{ minWidth: '95px' }}>
                                                                <Form.Control
                                                                    type="number"
                                                                    step="0.0001"
                                                                    name={`rows[${index}][current_price]`}
                                                                    value={state.data?.rows[index].current_price || ''}
                                                                    onChange={e => handleRowCurrentPriceChange(e, index)}
                                                                    isInvalid={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].current_price && (validations.rows[index].current_price[0] || validations.rows[index].current_price))}
                                                                    size="sm"
                                                                    className={`price-input`}
                                                                    readOnly={row.is_special ? true : false}
                                                                    disabled={isCurrentPriceDisabled(row)}
                                                                />

                                                                {state.data?.currency &&
                                                                    <InputGroup.Text>
                                                                        {state.data.currency.prefix || state.data.currency.sufix || '-'}/{state.data?.rows[index]?.article?.amount_type_short || '-'}
                                                                    </InputGroup.Text>
                                                                }
                                                            </InputGroup>
                                                        </td>
                                                        <td>
                                                            <InputGroup size="sm" style={{ minWidth: '95px' }}>
                                                                <Form.Control
                                                                    type="text"
                                                                    name={`rows[${index}][discount]`}
                                                                    value={state.data?.rows[index].discount || ''}
                                                                    onChange={e => handleRowDiscountChange(e, index)}
                                                                    isInvalid={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].discount && (validations.rows[index].discount[0] || validations.rows[index].discount))}
                                                                    size="sm"
                                                                    className={`price-input`}
                                                                    readOnly={row.is_special ? true : false}
                                                                />
                                                                <InputGroup.Text>
                                                                    %
                                                                </InputGroup.Text>
                                                            </InputGroup>
                                                        </td>
                                                        <td>
                                                            <InputGroup size="sm" style={{ minWidth: '95px' }}>
                                                                <Form.Control
                                                                    type="number"
                                                                    step="0.0001"
                                                                    value={getRowTotalRealPrice(row)}
                                                                    size="sm"
                                                                    className={`price-input ${getRowTotalRealPrice(row) < 0 && 'negative-value'}`}
                                                                    disabled
                                                                />

                                                                {state.data?.currency &&
                                                                    <InputGroup.Text>
                                                                        {state.data.currency.prefix || state.data.currency.sufix || '-'}
                                                                    </InputGroup.Text>
                                                                }
                                                            </InputGroup>
                                                        </td>
                                                        {auth.getUser().permission('show_delivery_price') &&
                                                            <>
                                                                <td>
                                                                    <InputGroup size="sm" style={{ minWidth: '95px' }}>
                                                                        <Form.Control
                                                                            type="number"
                                                                            step="0.0001"
                                                                            value={row.avg_delivery_price_with_vat || ''}
                                                                            size="sm"
                                                                            className={`price-input `}
                                                                            disabled
                                                                        />

                                                                        {state.data?.currency &&
                                                                            <InputGroup.Text>
                                                                                {state.data.currency.prefix || state.data.currency.sufix || '-'}/{state.data?.rows[index]?.article?.amount_type_short || '-'}
                                                                            </InputGroup.Text>
                                                                        }
                                                                    </InputGroup>
                                                                </td>
                                                                <td>
                                                                    <InputGroup size="sm" style={{ minWidth: '95px' }}>
                                                                        <Form.Control
                                                                            type="number"
                                                                            step="0.0001"
                                                                            value={row.current_delivery_price_with_vat || ''}
                                                                            size="sm"
                                                                            className={`price-input `}
                                                                            disabled
                                                                        />

                                                                        {state.data?.currency &&
                                                                            <InputGroup.Text>
                                                                                {state.data.currency.prefix || state.data.currency.sufix || '-'}/{state.data?.rows[index]?.article?.amount_type_short || '-'}
                                                                            </InputGroup.Text>
                                                                        }
                                                                    </InputGroup>
                                                                </td>
                                                            </>
                                                        }
                                                        <td>
                                                            <InputGroup size="sm" style={{ minWidth: '95px' }}>
                                                                <Form.Control
                                                                    type="number"
                                                                    step="0.0001"
                                                                    value={row.actual_price || ''}
                                                                    size="sm"
                                                                    className={`price-input `}
                                                                    disabled
                                                                />

                                                                {state.data?.currency &&
                                                                    <InputGroup.Text>
                                                                        {state.data.currency.prefix || state.data.currency.sufix || '-'}/{state.data?.rows[index]?.article?.amount_type_short || '-'}
                                                                    </InputGroup.Text>
                                                                }
                                                            </InputGroup>
                                                        </td>
                                                        <td>
                                                            <InputGroup size="sm" style={{ minWidth: '95px' }}>
                                                                <Form.Control
                                                                    type="text"
                                                                    name={`rows[${index}][price_ratio]`}
                                                                    value={getRowPriceRatio(row)}
                                                                    onChange={e => handleRowPriceRatioChange(e, index)}
                                                                    isInvalid={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].price_ratio && (validations.rows[index].price_ratio[0] || validations.rows[index].price_ratio))}
                                                                    size="sm"
                                                                    className={`price-input ${getRowPriceRatio(row) < 0 && 'negative-value'}`}
                                                                    readOnly={row.is_special ? true : false}
                                                                />
                                                                <InputGroup.Text>
                                                                    %
                                                                </InputGroup.Text>
                                                            </InputGroup>
                                                        </td>
                                                        <td className="options">
                                                            <div style={{ display: 'flex', gap: '5px' }}>
                                                                <Button variant="danger" size="sm" onClick={e => handleRemoveRow(e, index)}>
                                                                    <TrashFill />
                                                                </Button>
                                                                {(row.price_rules || []).length > 0 &&
                                                                    <Button variant="primary" size="sm" onClick={e => handleShowPriceRules(row)}>
                                                                        <CurrencyDollar />
                                                                    </Button>
                                                                }
                                                            </div>
                                                        </td>

                                                    </tr>
                                                )}
                                                {/* <tr>
                                                    <td colSpan={4}>
                                                        Общо
                                                    </td>
                                                    <td className="text-right">
                                                        {getTotalQty().toFixed(4)}
                                                    </td>
                                                </tr> */}

                                            </tbody>
                                        </Table>

                                        <Button variant="outline-dark" className="mt-3" size="sm" onClick={handleAddRow}>
                                            Нов ред
                                        </Button>
                                        <Button variant="outline-dark" className="mt-3" size="sm" onClick={handleImport} disabled={auth.getUser().permission('import_index') === false} style={{ marginLeft: '5px' }}>
                                            <Upload /> Импорт
                                        </Button>
                                        <Button variant="outline-dark" className="mt-3" style={{ marginLeft: '5px' }} size="sm" onClick={handleAddCard}>
                                            <Percent /> Приложи карта
                                        </Button>
                                    </div>


                                    <Row>
                                        <Col sm={4} className="mt-3">
                                            <div className="form-panel full-height">
                                                <div className="head">
                                                    Описание
                                                </div>
                                                <Form.Control
                                                    type="text"
                                                    as="textarea"
                                                    // placeholder="Бележки"
                                                    name="description"
                                                    value={state.data?.description || ''}
                                                    onChange={handleInputChange}
                                                    isInvalid={Boolean(validations?.description)}
                                                    rows={5}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {validations?.description && (validations.description[0] || validations.description)}
                                                </Form.Control.Feedback>
                                            </div>

                                        </Col>
                                        <Col sm={4} className="mt-3">
                                            <div className="form-panel full-height">
                                                <div className="head">
                                                    Бележки
                                                </div>
                                                <Form.Control
                                                    type="text"
                                                    as="textarea"
                                                    // placeholder="Бележки"
                                                    name="extra_details"
                                                    value={state.data?.extra_details || ''}
                                                    onChange={handleInputChange}
                                                    isInvalid={Boolean(validations?.extra_details)}
                                                    rows={5}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {validations?.extra_details && (validations.extra_details[0] || validations.extra_details)}
                                                </Form.Control.Feedback>
                                            </div>

                                        </Col>
                                        <Col sm={4} className="mt-3">
                                            <div className="form-panel full-height">
                                                <div className="head">
                                                    Общо
                                                </div>

                                                <Row>
                                                    <Col className="mb-3">
                                                        <Form.Group as={Row}>
                                                            <Form.Label column="sm" sm={6} className="text-left">
                                                                Общо
                                                            </Form.Label>
                                                            <Col sm={6}>
                                                                <InputGroup size="sm">
                                                                    <Form.Control
                                                                        type="number"
                                                                        step="0.01"
                                                                        readOnly
                                                                        value={getTotalSum().toFixed(4)}
                                                                        size="sm"
                                                                        className="price-input"
                                                                    />
                                                                    {state.data?.currency &&
                                                                        <InputGroup.Text>
                                                                            {state.data.currency.prefix || state.data.currency.sufix || '-'}
                                                                        </InputGroup.Text>
                                                                    }
                                                                </InputGroup>
                                                            </Col>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className="mb-3">
                                                        <Form.Group as={Row}>
                                                            <Form.Label column="sm" sm={6} className="text-left">
                                                                {isDiscount() ? 'Отстъпка' : 'Надценка'} ({state.data.discount}%)
                                                            </Form.Label>
                                                            <Col sm={6}>
                                                                <InputGroup size="sm">
                                                                    <Form.Control
                                                                        type="number"
                                                                        step="0.01"
                                                                        readOnly
                                                                        value={getTotalDiscount().toFixed(4)}
                                                                        size="sm"
                                                                        className="price-input"
                                                                    />
                                                                    {state.data?.currency &&
                                                                        <InputGroup.Text>
                                                                            {state.data.currency.prefix || state.data.currency.sufix || '-'}
                                                                        </InputGroup.Text>
                                                                    }
                                                                </InputGroup>
                                                            </Col>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className="mb-3">
                                                        <Form.Group as={Row}>
                                                            <Form.Label column="sm" sm={6} className="text-left">
                                                                Сума
                                                            </Form.Label>
                                                            <Col sm={6}>
                                                                <InputGroup size="sm">
                                                                    <Form.Control
                                                                        type="number"
                                                                        step="0.01"
                                                                        readOnly
                                                                        value={getTotalReal().toFixed(4)}
                                                                        size="sm"
                                                                        className="price-input"
                                                                    />
                                                                    {state.data?.currency &&
                                                                        <InputGroup.Text>
                                                                            {state.data.currency.prefix || state.data.currency.sufix || '-'}
                                                                        </InputGroup.Text>
                                                                    }
                                                                </InputGroup>
                                                            </Col>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>


                                            </div>
                                        </Col>
                                    </Row>

                                    <div className="buttons space-between mt-3 ml-auto">
                                        <Button
                                            variant="secondary"
                                            onClick={e => navigate(-1)}
                                        >
                                            Отказ
                                        </Button>
                                        <SaveButton
                                            loading={state.loadingExit}
                                            disabled={state.loading || getSelectedCount() === 0}
                                            className="save"
                                            onClick={handleSaveAndExit}
                                        >
                                            Запази данните
                                        </SaveButton>
                                        <SaveButton
                                            loading={state.loadingBase}
                                            disabled={state.loading || getSelectedCount() === 0}
                                            className="save"
                                            onClick={handleSave}
                                        >
                                            Запази и продължи
                                        </SaveButton>
                                        {state.data.allow_close &&
                                            <SaveButton
                                                // loading={state.loadingClose}
                                                disabled={state.loading || getSelectedCount() === 0}
                                                className="save"
                                                onClick={handleSaveAndClose}
                                            >
                                                Запази и приключи сметката
                                            </SaveButton>
                                        }
                                    </div>
                                    {/* </Container> */}

                                </Form>
                            </>
                        }

                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Index;