import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import { Modal, Button, Row, Col, FloatingLabel, Form } from 'react-bootstrap';

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//providers
import { useCurrencyContext } from 'providers/Currency';

//misc
import SaveButton from 'components/misc/Button'
import Info from 'components/modals/Info';

function AddOrEdit(props, ref) {

    const currencies = useCurrencyContext();

    const defaultData = {
        langs: {},
        file: null,
    }

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        edit: false,
        data: defaultData,
        initialData: defaultData,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null
    });

    const [validations, setValidations] = useValidation();

    const validationModalRef = useRef(null);
    const inputFileRef = useRef(null);

    useImperativeHandle(ref, () => ({
        add: () => {
            handleShow();
        },
        edit: (id) => {
            edit(id);
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        }
    }));

    useEffect(() => {
        loadData();
    }, [state.edit]);

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const handleShow = () => {
        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
        }));

        show();
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            initialData: defaultData,
            id: null,
        }));

        setValidations(null);

        hide();
    }

    const edit = id => {
        setState(prev => ({
            ...prev,
            id: id,
            edit: new Date().getTime()
        }));

        show();
    }

    const loadData = () => {
        if (!state.id) {
            return;
        }

        Api.get('admin/languages/edit?id=' + state.id)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data,
                    initialData: res.data,
                    id: res.data.id
                }))
            });
    }

    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = state.id ? 'admin/languages/update' : 'admin/languages/add';

        let data = new FormData(e.target);

        if (state.id) {
            data.append('id', state.id)
        }

        if (state.data.image) {
            data.append('image', state.data.image);
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (typeof state.onSuccess === 'function') {
                state.onSuccess(res.data)
            }

            hide()
        }).catch(error => {
            const _err = error.response;
            if (_err && _err.status && _err.status === 422) {
                hide(() => {
                    let modal = validationModalRef.current;

                    if (modal) {
                        modal.open();

                        modal.onClose(() => {
                            setValidations(_err.data.errors)
                            show();
                        });
                    }
                });
            }
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }));
        });
    }

    const handleCheckboxChange = e => {
        const name = e.target.name;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: prev.data[name] ? false : true
            }
        }));
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleDateChange = e => {
        const name = e.target.name + '_ymd'
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleShowAttach = e => {
        e.preventDefault();

        inputFileRef.current.click();
    }

    const handleAttach = e => {
        let file = e.target.files[0];
        let url = URL.createObjectURL(file);

        // file.id = Math.random().toString(7).substring(2);
        // file.was_recently_attached = true;
        // file.url = url;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                image: file,
                image_url: url,
            }
        }));
    }

    const handleDetach = e => {
        let file = state.data.file;

        if (!file.was_recently_attached) {
            Api.post('languages/remove-file', {
                id: state.data.id
            }).then(res => {
                if (res.data.success) {
                    removeFile();
                }
            });
        } else {
            removeFile();
        }

        if (validations?.image) {
            delete validations.image;
        }

        setValidations(validations);
    }

    const removeFile = id => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                file: null
            }
        }));
    }

    return (
        <>
            <Info
                ref={validationModalRef}
                mainMessage="Моля, попълнете всички задължителни полета, означени с червен цвят!"
            />


            <Modal centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <form onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Език
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <FloatingLabel label="Име*" className="mb-3">
                            <Form.Control
                                type="text"
                                name={`name`}
                                value={state.data.name || ''}
                                placeholder="Име"
                                onChange={(e) => handleInputChange(e)}
                                isInvalid={Boolean(validations?.name)}
                            />
                            <Form.Control.Feedback type="invalid">
                                {validations?.name}
                            </Form.Control.Feedback>
                        </FloatingLabel>

                        <FloatingLabel label="Код*" className="mb-3">
                            <Form.Control
                                type="text"
                                name={`locale`}
                                value={state.data.locale || ''}
                                placeholder="Код"
                                onChange={(e) => handleInputChange(e)}
                                isInvalid={Boolean(validations?.locale)}
                            />
                            <Form.Control.Feedback type="invalid">
                                {validations?.locale}
                            </Form.Control.Feedback>
                        </FloatingLabel>

                        {Number(state.initialData?.default) !== 1 ? <Form.Check
                            type="switch"
                            name="default"
                            label="По подразбиране"
                            className="mb-3 mt-3"
                            checked={Number(state.data?.default) === 1}
                            onChange={handleCheckboxChange}
                        /> : ''}

                        <Row>
                            <Col>
                                {state.data.image_url &&
                                    <div className="mb-3">
                                        <>
                                            <div className="file">
                                                <img style={{ maxHeight: '100px', objectFit: 'contain' }} src={state.data.image_url} onError={e => e.target.classList.add('hidden')} />
                                            </div>

                                            <div className="validate">
                                                {validations?.image}
                                            </div>
                                        </>
                                    </div>
                                }

                                <Button variant="outline-dark" size="sm" onClick={handleShowAttach}>
                                    Прикачете изображение
                                </Button>

                                {/* {state.data.file &&
                                    <Button variant="outline-danger" size="sm" onClick={handleDetach} style={{ marginLeft: '5px' }}>
                                        Премахнете изображението
                                    </Button>
                                } */}

                                <input ref={inputFileRef} type="file" accept="image/*" onChange={handleAttach} hidden />
                            </Col>
                        </Row>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            className="save"
                        />
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default forwardRef(AddOrEdit);
