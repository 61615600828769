import { useState, useRef } from 'react';

import { Button } from 'react-bootstrap';
import uploadIcon from 'assets/img/icons/file-upload-2@256.png';

import 'assets/scss/dragdropfile.scss';

function DragDropFile(props) {
    // drag state
    const [dragActive, setDragActive] = useState(false);
    // ref
    const inputRef = useRef(null);

    const accept = props.accept || "*";
    const onChange = props.onChange;

    // handle drag events
    const handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();

        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    // triggers when file is dropped
    const handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();

        setDragActive(false);

        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleFiles(e.dataTransfer.files);
        }
    };

    // triggers when file is selected with click
    const handleChange = function (e) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            handleFiles(e.target.files);
        }
    };

    // triggers the input when the button is clicked
    const onButtonClick = () => {
        inputRef.current.click();
    };

    const handleFiles = files => {
        onChange(files[0]);
    }

    return (
        <div className="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
            <label className={dragActive ? "drag-active" : ""}>
                <input ref={inputRef} type="file" className="input-file-upload" multiple={false} accept={accept} onChange={handleChange} />

                <div>
                    <img src={uploadIcon} alt="" />
                    <p>Пуснете файл тук</p>
                    <p>или</p>
                    <Button
                        variant="dark"
                        onClick={onButtonClick}
                    >
                        Изберете файл
                    </Button>
                </div>
            </label>
            {dragActive && <div className="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}
        </div>
    );
};

export default DragDropFile;