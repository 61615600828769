import React, { useState, useEffect, useRef } from 'react'
import { Button, Table } from 'react-bootstrap';
import Api from 'helpers/Api';
import Loader from 'components/misc/Loader';
import moment from 'moment';
import NoDataFound from 'components/misc/NoDataFound';
import Pagination from 'components/misc/Pagination';
import TableHeader from 'components/misc/TableHeader';
import TableCol from 'components/misc/TableCol';
import { MODELS } from 'constants/models';
import Options from 'components/partials/notes/Options';
import MenuOptions from 'components/misc/MenuOptions';
import { useAuthDataContext } from 'providers/Auth';

function Index(props) {

    const auth = useAuthDataContext();

    const defaultData = [];

    const tableRef = useRef(null);

    const [state, setState] = useState({
        data: defaultData,
        pages: 0,
        total: 0,
        loading: false,
        filter: {
            page: 1,
        },
        refresh: false,
    });

    useEffect(() => {

        loading(true);

        Api.get('notes/all', {
            params: {
                subject_id: props.id,
                subject_type: MODELS.CLIENT,
                ...state.filter,
            }
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data.items,
                total: res.data.total,
                pages: res.data.pages,
                filter: {
                    ...prev.filter,
                    ...res.data.filter,
                },
            }));

            if (res.data.pages > 0 && state.filter.page > res.data.pages) {
                return handlePage({ selected: res.data.pages - 1 });
            }

            loading(false);
            sorting(false);
        });
    }, [props.id, state.refresh]);

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }));
    }

    const sorting = (sorting) => {
        setState(prev => ({
            ...prev,
            sorting: Boolean(sorting)
        }));
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            refresh: new Date()
        }));
    }

    const handleSort = (sort, order) => {
        setState(prev => ({
            ...prev,
            sorting: true,
            filter: {
                ...prev.filter,
                sort: sort,
                order: order,
                page: 1,
            },
            refresh: new Date().getTime()
        }));
    }

    const handleAdd = () => {
        props.addNote();
    }

    const handleEdit = id => {
        props.editNote(id);
    }

    const handleDelete = id => {
        props.deleteNote(id);
    }

    return (

        state.loading && !state.sorting
            ?
            <Loader />
            :
            state.data.length === 0 ?
                <NoDataFound>
                    <div>
                        Няма намерена информация!
                    </div>
                    <br />
                    <Button variant="outline-dark" onClick={e => handleAdd()} disabled={auth.getUser().permission('notes_edit_client') === false}>
                        Създай нова бележка
                    </Button>
                </NoDataFound>
                :
                <>
                    <Button variant="outline-dark" onClick={e => handleAdd()} disabled={auth.getUser().permission('notes_edit_client') === false}>
                        Създай нова бележка
                    </Button>

                    <Table className="mt-3" size="sm" striped ref={tableRef}>
                        <TableHeader
                            tableRef={tableRef}
                            activeSortKey={state.filter.sort}
                            activeSortDir={state.filter.order}
                            onSort={(col, dir) => handleSort(col, dir)}
                        >
                            <TableCol sortable sortKey="id" style={{ width: '5%' }}>ID</TableCol>
                            <TableCol sortable sortKey="created_at">Създадена</TableCol>
                            <TableCol sortable sortKey="title">Заглавие</TableCol>
                            <TableCol sortable sortKey="user">Потребител</TableCol>
                            <TableCol className="options">Опции</TableCol>
                        </TableHeader>
                        <tbody>
                            {state.data.map((r, i) =>
                                <React.Fragment key={i}>
                                    <tr >
                                        <td>
                                            {r.id}
                                        </td>
                                        <td>
                                            {r.created_at ? moment(r.created_at).format('DD.MM.YYYY HH:mm') : ''}
                                        </td>
                                        <td>
                                            {r.title}
                                        </td>
                                        <td>
                                            {r.user?.username}
                                        </td>
                                        <td className="options">
                                            <MenuOptions>
                                                <Options
                                                    data={r}
                                                    allow_edit={auth.getUser().permission('notes_edit_client') === false}
                                                    allow_delete={auth.getUser().permission('notes_edit_client') === false}
                                                    handleEdit={handleEdit}
                                                    handleDelete={handleDelete}
                                                />
                                            </MenuOptions>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={5}>
                                            {r.text}
                                        </td>
                                    </tr>
                                </React.Fragment>
                            )}
                        </tbody>
                    </Table>

                    <Pagination
                        page={state.filter.page}
                        pages={state.pages}
                        handlePage={handlePage}
                    />
                </>
    )
}

export default Index;