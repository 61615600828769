import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import { Modal, Button, Col, Row, FloatingLabel, Form, Tab, Nav, Alert, Card, Table } from 'react-bootstrap';
import { Check, X } from 'react-bootstrap-icons';

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//misc
import SaveButton from 'components/misc/Button'
import Info from 'components/modals/Info';
import Refs from 'Refs';

function AddOrEdit(props, ref) {

    const defaultData = {}

    const [state, setState] = useNestedState({
        mainTabId: 'main',
        show: false,
        id: null,
        selectedId: null,
        edit: false,
        data: defaultData,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null,
        onEasySelection: null,
        onClose: null
    });

    const [devices, setDevices] = useState([]);
    const [cashdesks, setCashdesks] = useState([]);
    const [validations, setValidations] = useValidation();

    const selectModalRef = Refs.getInstance().getRef('deviceSelection');
    const validationModalRef = useRef(null);

    useImperativeHandle(ref, () => ({
        add: (device) => {
            handleShow(device);
        },
        edit: (id) => {
            edit(id);
        },
        hide: () => {
            hide();
        },
        onEasySelection: fn => {
            setState(prev => ({
                ...prev,
                onEasySelection: fn
            }));
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        }
    }));

    useEffect(() => {
        if (!state.show) {
            return;
        }

        if (devices.length === 0) {
            Api.get('devices/get-all')
                .then(res => {
                    if (Array.isArray(res.data)) {
                        setDevices(res.data);
                    }
                })
        }

        if (cashdesks.length === 0) {
            Api.get('devices/cashdesks')
                .then(res => {
                    if (Array.isArray(res.data)) {
                        setCashdesks(res.data);
                    }
                })
        }
    }, [state.show])

    useEffect(() => {
        loadData();
    }, [state.edit]);

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const handleShow = (device) => {
        if (!device?.id) return;

        device.fullname = device.category.name + ' [ ' + device.brand?.name + ' ' + device.name + ' ] '
        device.device_id = device.id

        setState(prev => ({
            ...prev,
            mainTabId: 'main',
            data: device,
        }));

        show();
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            mainTab: 'preview',
            id: null,
        }));

        setValidations(null);

        if (typeof state.onClose === 'function') {
            state.onClose();
        }

        hide();
    }

    const edit = id => {
        setState(prev => ({
            ...prev,
            id: id,
            mainTabId: 'preview',
            edit: new Date().getTime()
        }));

        show();
    }

    const loadData = () => {
        if (!state.id) {
            return;
        }

        Api.get('stores/devices/show?id=' + state.id)
            .then(res => {
                let data = res.data;
                data.fullname = res.data.name;
                setState(prev => ({
                    ...prev,
                    data: res.data
                }))
            });
    }

    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = state.id ? 'stores/devices/update' : 'stores/devices/add';

        let data = new FormData(e.target);

        if (state.id) {
            data.append('id', state.id)
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (typeof state.onSuccess === 'function') {
                state.onSuccess();
            }

            hide(state.onClose);

            if (typeof props.refresh === 'function') {
                props.refresh();
            }
        }).catch(error => {
            const _err = error.response;

            if (_err && _err.status && _err.status === 422) {
                hide(() => {
                    let modal = validationModalRef.current;

                    if (modal) {
                        modal.open();

                        modal.onClose(() => {
                            setValidations(_err.data.errors)
                            show();
                        });
                    }
                });
            }

        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }));
            // if (typeof state.onSuccess === 'function') {
            //     state.onSuccess();
            // }
            // hide()
        });
    }

    const handleInputChange = e => {
        const value = e.target.value
        let name = e.target.name

        if (name === 'name') {
            name = 'fullname'
        }

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: value
            }
        }))

    }

    const handleDeviceChange = e => {
        const id = e.target.value
        let dev = devices.filter(d => {
            if (d.id == id) {
                d.device_id = id
                d.fullname = d.category.name + ' [ ' + d.brand?.name + ' ' + d.name + ']'
                return d;
            }
        });

        if (!dev.length) return;

        setState(prev => ({
            ...prev,
            data: dev[0]
        }))
    }

    const setMainTab = key => {
        setState(prev => ({
            ...prev,
            mainTabId: key
        }));
    }

    const handleEasySelect = () => {
        hide(() => {
            let modal = selectModalRef;

            if (modal) {
                modal.open();

                modal.onClose(() => {
                    show();
                });
            }
        });
    }

    const handleCheckboxChange = e => {
        const name = e.target.name;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: prev.data[name] ? false : true
            }
        }));
    }

    const handleSpeed = (speed) => {
        switch (speed) {
            case 1:
                return '115200'
                break;
            case 2:
                return '57600'
                break;
            case 3:
                return '38400'
                break;
            case 4:
                return '19200'
                break;
            case 5:
                return '9600'
                break;
            case 6:
                return '4800'
                break;
            case 7:
                return '2400'
                break;
            case 8:
                return '1200'
                break;
        }
        return ''
    }

    const handleFlowControl = (flowctrl) => {
        switch (flowctrl) {
            case 1:
                return 'None'
                break;
            case 2:
                return 'Hardware (RTS/CTS)'
                break;
            case 3:
                return 'Hardware (DTR/DSR)'
                break;
            case 4:
                return 'Software'
                break;

        }
        return 'None'
    }

    const handleParity = (parity) => {
        switch (parity) {
            case 1:
                return 'None'
                break;
            case 2:
                return 'Odd'
                break;
            case 3:
                return 'Even'
                break;

        }
        return 'None'
    }

    const handleDataBit = (databits) => {
        switch (databits) {
            case 1:
                return '8'
                break;
            case 2:
                return '7'
                break;
        }
        return '7'
    }

    const handleURL = () => {
        if (!state.data?.ip_suffix || !state.data?.conn_proto) {
            return '';
        }

        let port = state.data.tcp_port ? ':' + state.data.tcp_port : ''
        let protocol = '';

        switch (state.data.conn_proto) {
            case 1:
                protocol = 'tcp://'
                break;
            case 2:
                protocol = 'udp://'
                break;
            case 3:
                protocol = 'ipp://'
                break;
            case 4:
                protocol = 'http://'
                break;
            case 5:
                protocol = 'https://'
                break;
            default:
                break;
        }

        return protocol + state.data.ip_suffix + port

    }

    return (
        <>
            <Info
                ref={validationModalRef}
                mainMessage="Моля, попълнете всички задължителни полета, означени с червен цвят!"
            />

            <Modal size="xl" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <form onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Устройство
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Tab.Container activeKey={state.mainTabId} onSelect={key => setMainTab(key)}>
                            <Row>
                                <Col xl={2} sm={2}>
                                    <Nav variant="pills" className="flex-column">
                                        {state.edit ? <Nav.Item>
                                            <Nav.Link eventKey="preview">Преглед</Nav.Link>
                                        </Nav.Item> : ''}
                                        <Nav.Item>
                                            <Nav.Link eventKey="main">Основни данни</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="extra">Допълнителни</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="more">Още настройки</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                <Col xl={10} sm={10}>
                                    <Tab.Content>
                                        {state.edit ? <Tab.Pane eventKey="preview">
                                            <Card className="mb-3">
                                                <Card.Header>
                                                    Основни данни
                                                </Card.Header>
                                                <Card.Body>
                                                    <Row>
                                                        <Col sm={3}>
                                                            <img src={state.data?.picture_url || state.data?.base_device?.picture_url || ''} style={{ width: '200px' }} loading="lazy" alt="" />
                                                        </Col>
                                                        <Col sm={9}>
                                                            <Table>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className='th'>Име</td>
                                                                        <td colSpan={3}>{state.data?.fullname || ''}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='th'>Модел</td>
                                                                        <td>{state.data?.base_device?.name || state.data?.name || ''}</td>
                                                                        <td className='th'>Производител</td>
                                                                        <td>{state.data.brand?.name || state.data?.base_device?.brand?.name || ''}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='th'>URL</td>
                                                                        <td colSpan={3} style={{ textTransform: 'lowercase' }}>{handleURL()}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='th'>Ком.Порт	</td>
                                                                        <td>{state.data?.device || ''}</td>
                                                                        <td className='th'>Скорост</td>
                                                                        <td>{handleSpeed(state.data?.speed)}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='th'>Flow Control</td>
                                                                        <td>{handleFlowControl(state.data?.flowctrl)}</td>
                                                                        <td className='th'>Stop Bit</td>
                                                                        <td>{state.data?.stopbit || '1'}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='th'>Parity</td>
                                                                        <td>{handleParity(state.data.parity)}</td>
                                                                        <td className='th'>Data Bits</td>
                                                                        <td>{handleDataBit(state.data.databits)}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                            <Card className="mb-3">
                                                <Card.Header>
                                                    Допълнителни данни
                                                </Card.Header>
                                                <Card.Body>
                                                    <Table>
                                                        <tbody>
                                                            <tr>
                                                                <td className='th'>REF_ID</td>
                                                                <td>{state.data?.ref_id || ''}</td>
                                                                <td className='th'>Оператор номер</td>
                                                                <td>{state.data?.op_num || ''}</td>
                                                                <td className='th'>Оператор код</td>
                                                                <td>{state.data?.op_pass || ''}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='th'>Символа на ред</td>
                                                                <td>{state.data?.row_length || ''}</td>
                                                                <td className='th'>Описание</td>
                                                                <td>{state.data?.description || ''}</td>
                                                                <td className='th'>Максимален брой артикули</td>
                                                                <td>{''}</td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </Card.Body>
                                            </Card>
                                            <Card className="mb-3">
                                                <Card.Header>
                                                    Комуникация
                                                </Card.Header>
                                                <Card.Body>
                                                    <Table>
                                                        <tbody>
                                                            <tr>
                                                                <td className='th'>RS232</td>
                                                                <td>
                                                                    {state.data?.base_device?.rs232 == 1 ? <Check style={{ fontSize: '26px', color: '#1E8C45' }} /> : state.data?.base_device?.rs232 == 2 ? 'Не е тестван' : <X style={{ fontSize: '32px', color: '#ff2727d6' }} />}
                                                                </td>
                                                                <td className='th'>USB</td>
                                                                <td>
                                                                    {state.data?.base_device?.usb == 1 ? <Check style={{ fontSize: '26px', color: '#1E8C45' }} /> : state.data?.base_device?.usb == 2 ? 'Не е тестван' : <X style={{ fontSize: '32px', color: '#ff2727d6' }} />}
                                                                </td>
                                                                <td className='th'>LAN</td>
                                                                <td>
                                                                    {state.data?.base_device?.lan == 1 ? <Check style={{ fontSize: '26px', color: '#1E8C45' }} /> : state.data?.base_device?.lan == 2 ? 'Не е тестван' : <X style={{ fontSize: '32px', color: '#ff2727d6' }} />}
                                                                </td>
                                                                <td className='th'>WIFI</td>
                                                                <td>
                                                                    {state.data?.base_device?.wifi == 1 ? <Check style={{ fontSize: '26px', color: '#1E8C45' }} /> : state.data?.base_device?.wifi == 2 ? 'Не е тестван' : <X style={{ fontSize: '32px', color: '#ff2727d6' }} />}
                                                                </td>
                                                                <td className='th'>BLUETOOTH</td>
                                                                <td>
                                                                    {state.data?.base_device?.bluetooth == 1 ? <Check style={{ fontSize: '26px', color: '#1E8C45' }} /> : state.data?.base_device?.bluetooth == 2 ? 'Не е тестван' : <X style={{ fontSize: '32px', color: '#ff2727d6' }} />}
                                                                </td>

                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </Card.Body>
                                            </Card>
                                        </Tab.Pane> : ''}
                                        <Tab.Pane eventKey="main">
                                            <Row style={{ alignItems: 'center' }} className='mb-3'>
                                                <Col>
                                                    <FloatingLabel label="Име">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Име*"
                                                            name="name"
                                                            value={state.data.fullname || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.name)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.name && (validations.name[0] || validations.name)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel label="Модел*">
                                                        <Form.Select
                                                            name="device_id"
                                                            value={state.data.device_id || ''}
                                                            onChange={handleDeviceChange}
                                                            isInvalid={Boolean(validations && validations.device_id)}
                                                        >
                                                            {devices.map(g =>
                                                                <option key={`device_id-${g.id}`} value={g.id}>{g.category?.name + ' [ ' + g.brand?.name + ' ' + g.name + ' ]'}</option>
                                                            )}

                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.device_id && (validations.device_id[0] || validations.device_id)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>

                                                <Col>
                                                    <Button variant="primary" onClick={() => handleEasySelect()}>
                                                        Лесен избор
                                                    </Button>
                                                </Col>

                                            </Row>

                                            <Col className="mb-3">
                                                <FloatingLabel label="Тип връзка*">
                                                    <Form.Select
                                                        name="conn_type"
                                                        value={state.data.conn_type || ''}
                                                        onChange={handleInputChange}
                                                        isInvalid={Boolean(validations && validations.conn_type)}
                                                    >
                                                        <option value="1">Физическо</option>
                                                        <option value="2">Мрежов сървър</option>
                                                        <option value="3">Мрежов клиент</option>

                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">
                                                        {validations && validations.conn_type && (validations.conn_type[0] || validations.conn_type)}
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>
                                            </Col>

                                            {state.data.conn_type == 1 || state.data.conn_type == 2 ? <Row className="mb-3">
                                                <Col>
                                                    <FloatingLabel label="Протокол*">
                                                        <Form.Select
                                                            name="conn_proto"
                                                            value={state.data.conn_proto || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.conn_proto)}
                                                        >
                                                            <option value="1">TCP</option>
                                                            <option value="2">UDP</option>
                                                            <option value="3">IPP</option>
                                                            <option value="4">HTTP</option>
                                                            <option value="5">HTTPS</option>

                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.conn_type && (validations.conn_type[0] || validations.conn_type)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel label="IP">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="IP"
                                                            name="ip_suffix"
                                                            value={state.data.ip_suffix || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.ip_suffix)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.ip_suffix && (validations.ip_suffix[0] || validations.ip_suffix)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel label="Мрежов Порт">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Мрежов Порт"
                                                            name="tcp_port"
                                                            value={state.data.tcp_port || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.tcp_port)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.tcp_port && (validations.tcp_port[0] || validations.tcp_port)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row> : ''}

                                            {state.data.conn_type == 1 ?
                                                <>
                                                    <Col className="mb-3">
                                                        <FloatingLabel label="Ком.Порт">
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Ком.Порт"
                                                                name="device"
                                                                value={state.data.device || ''}
                                                                onChange={handleInputChange}
                                                                isInvalid={Boolean(validations && validations.device)}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {validations && validations.device && (validations.device[0] || validations.device)}
                                                            </Form.Control.Feedback>
                                                        </FloatingLabel>
                                                    </Col>

                                                    <Row className="mb-3">
                                                        <Col>
                                                            <FloatingLabel label="Скорост*">
                                                                <Form.Select
                                                                    name="speed"
                                                                    value={state.data.speed || ''}
                                                                    onChange={handleInputChange}
                                                                    isInvalid={Boolean(validations && validations.speed)}
                                                                >
                                                                    <option value="1">115200</option>
                                                                    <option value="2">57600</option>
                                                                    <option value="3">38400</option>
                                                                    <option value="4">19200</option>
                                                                    <option value="5">9600</option>
                                                                    <option value="6">4800</option>
                                                                    <option value="7">2400</option>
                                                                    <option value="8">1200</option>

                                                                </Form.Select>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {validations && validations.conn_type && (validations.conn_type[0] || validations.conn_type)}
                                                                </Form.Control.Feedback>
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col>
                                                            <FloatingLabel label="Flow Control*">
                                                                <Form.Select
                                                                    name="flowctrl"
                                                                    value={state.data.flowctrl || ''}
                                                                    onChange={handleInputChange}
                                                                    isInvalid={Boolean(validations && validations.flowctrl)}
                                                                >
                                                                    <option value="1">None</option>
                                                                    <option value="2">Hardware (RTS/CTS)</option>
                                                                    <option value="3">Hardware (DTR/DSR)</option>
                                                                    <option value="4">Software</option>

                                                                </Form.Select>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {validations && validations.flowctrl && (validations.flowctrl[0] || validations.flowctrl)}
                                                                </Form.Control.Feedback>
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col>
                                                            <FloatingLabel label="Parity*">
                                                                <Form.Select
                                                                    name="parity"
                                                                    value={state.data.parity || ''}
                                                                    onChange={handleInputChange}
                                                                    isInvalid={Boolean(validations && validations.parity)}
                                                                >
                                                                    <option value="1">None</option>
                                                                    <option value="2">Odd</option>
                                                                    <option value="3">Even</option>

                                                                </Form.Select>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {validations && validations.parity && (validations.parity[0] || validations.parity)}
                                                                </Form.Control.Feedback>
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col>
                                                            <FloatingLabel label="Data Bits*">
                                                                <Form.Select
                                                                    name="databits"
                                                                    value={state.data.databits || ''}
                                                                    onChange={handleInputChange}
                                                                    isInvalid={Boolean(validations && validations.databits)}
                                                                >
                                                                    <option value="1">8</option>
                                                                    <option value="2">7</option>

                                                                </Form.Select>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {validations && validations.databits && (validations.databits[0] || validations.databits)}
                                                                </Form.Control.Feedback>
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col>
                                                            <FloatingLabel label="Stop Bit*">
                                                                <Form.Select
                                                                    name="stopbit"
                                                                    value={state.data.stopbit || ''}
                                                                    onChange={handleInputChange}
                                                                    isInvalid={Boolean(validations && validations.stopbit)}
                                                                >
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>

                                                                </Form.Select>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {validations && validations.stopbit && (validations.stopbit[0] || validations.stopbit)}
                                                                </Form.Control.Feedback>
                                                            </FloatingLabel>
                                                        </Col>
                                                    </Row>
                                                </>
                                                : ''}

                                            {state.data.conn_type == 3 ? <Col>
                                                <FloatingLabel label="Каса*">
                                                    <Form.Select
                                                        name="cashdesk_id"
                                                        value={state.data.cashdesk_id || ''}
                                                        onChange={handleInputChange}
                                                        isInvalid={Boolean(validations && validations.cashdesk_id)}
                                                    >
                                                        {cashdesks.map(g =>
                                                            <option key={`cashdesk_id-${g.id}`} value={g.id}>{g.name || ''}</option>
                                                        )}


                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">
                                                        {validations && validations.cashdesk_id && (validations.cashdesk_id[0] || validations.cashdesk_id)}
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>
                                            </Col> : ''}
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="extra">
                                            <Row className="mb-3">
                                                <Col>
                                                    <FloatingLabel label="Оператор номер">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Оператор номер"
                                                            name="op_num"
                                                            value={state.data.op_num || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.op_num)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.op_num && (validations.op_num[0] || validations.op_num)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel label="Оператор код">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Оператор код"
                                                            name="op_pass"
                                                            value={state.data.op_pass || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.op_pass)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.op_pass && (validations.op_pass[0] || validations.op_pass)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col>
                                                    <FloatingLabel label="Символа на ред*">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Символа на ред*"
                                                            name="row_length"
                                                            value={state.data.row_length || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.row_length)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.row_length && (validations.row_length[0] || validations.row_length)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel label="Символа на ред за продажба">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Символа на ред за продажба"
                                                            name="sale_row_length"
                                                            value={state.data.sale_row_length || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.sale_row_length)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.sale_row_length && (validations.sale_row_length[0] || validations.sale_row_length)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>

                                                <Col>
                                                    <FloatingLabel label="Кодова таблица">
                                                        <Form.Select
                                                            name="code_page"
                                                            value={state.data.code_page || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.code_page)}
                                                        >
                                                            <option value=""></option>
                                                            <option value="1">cp856-bul</option>
                                                            <option value="2">cp866</option>
                                                            <option value="3">cp1251</option>
                                                            <option value="4">utf-8</option>
                                                            <option value="5">windows-1251</option>

                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.code_page && (validations.code_page[0] || validations.code_page)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col>
                                                    <FloatingLabel label="Импорт на артикули*">
                                                        <Form.Select
                                                            name="can_import_articles"
                                                            value={state.data.can_import_articles || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.can_import_articles)}
                                                        >
                                                            <option value="1">Не</option>
                                                            <option value="2">Да</option>

                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.can_import_articles && (validations.can_import_articles[0] || validations.can_import_articles)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel label="Режим логване*">
                                                        <Form.Select
                                                            name="debug_mode"
                                                            value={state.data.debug_mode || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.debug_mode)}
                                                        >
                                                            <option value="1">Изключен</option>
                                                            <option value="2">Основен</option>
                                                            <option value="3">Нормален</option>
                                                            <option value="4">Разширен</option>

                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.debug_mode && (validations.debug_mode[0] || validations.debug_mode)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>

                                            <Col>
                                                <FloatingLabel label="Описание">
                                                    <Form.Control
                                                        as={'textarea'}
                                                        placeholder="Описание"
                                                        style={{ height: '100px' }}
                                                        name="description"
                                                        value={state.data.description || ''}
                                                        onChange={handleInputChange}
                                                        isInvalid={Boolean(validations && validations.description)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {validations && validations.description && (validations.description[0] || validations.description)}
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>
                                            </Col>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="more">
                                            <Col>
                                                <FloatingLabel label="LAN парола">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="LAN парола"
                                                        name="dynamic_setting_lan_password"
                                                        value={state.data.dynamic_setting_lan_password || ''}
                                                        onChange={handleInputChange}
                                                        isInvalid={Boolean(validations && validations.dynamic_setting_lan_password)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {validations && validations.dynamic_setting_lan_password && (validations.dynamic_setting_lan_password[0] || validations.dynamic_setting_lan_password)}
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>
                                            </Col>
                                        </Tab.Pane>
                                        {state.data.DISABLED ? <Tab.Pane eventKey="head">
                                            <Card className="mb-3">
                                                <Card.Header>
                                                    Глава
                                                </Card.Header>
                                                <Card.Body>
                                                    <Col className="mb-3">
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="il"
                                                            label="Печат графично лого"
                                                            checked={Number(state.data?.il) === 1}
                                                            onChange={handleCheckboxChange}
                                                        />
                                                    </Col>
                                                    <Col className="mb-3">
                                                        <FloatingLabel label="0">
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="0"
                                                                name="i0"
                                                                value={state.data.i0 || ''}
                                                                onChange={handleInputChange}
                                                                isInvalid={Boolean(validations && validations.i0)}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {validations && validations.i0 && (validations.i0[0] || validations.i0)}
                                                            </Form.Control.Feedback>
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col className="mb-3">
                                                        <FloatingLabel label="1">
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="1"
                                                                name="i1"
                                                                value={state.data.i1 || ''}
                                                                onChange={handleInputChange}
                                                                isInvalid={Boolean(validations && validations.i1)}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {validations && validations.i1 && (validations.i1[0] || validations.i1)}
                                                            </Form.Control.Feedback>
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col className="mb-3">
                                                        БУЛСТАТ:XXXXXXXXX
                                                    </Col>
                                                    <Col className="mb-3">
                                                        <FloatingLabel label="2">
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="2"
                                                                name="i2"
                                                                value={state.data.i2 || ''}
                                                                onChange={handleInputChange}
                                                                isInvalid={Boolean(validations && validations.i2)}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {validations && validations.i2 && (validations.i2[0] || validations.i2)}
                                                            </Form.Control.Feedback>
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col className="mb-3">
                                                        <FloatingLabel label="3">
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="3"
                                                                name="i3"
                                                                value={state.data.i3 || ''}
                                                                onChange={handleInputChange}
                                                                isInvalid={Boolean(validations && validations.i3)}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {validations && validations.i3 && (validations.i3[0] || validations.i3)}
                                                            </Form.Control.Feedback>
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col className="mb-3">
                                                        <FloatingLabel label="4">
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="4"
                                                                name="i4"
                                                                value={state.data.i4 || ''}
                                                                onChange={handleInputChange}
                                                                isInvalid={Boolean(validations && validations.i4)}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {validations && validations.i4 && (validations.i4[0] || validations.i4)}
                                                            </Form.Control.Feedback>
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col className="mb-3">
                                                        <FloatingLabel label="5">
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="5"
                                                                name="i5"
                                                                value={state.data.i5 || ''}
                                                                onChange={handleInputChange}
                                                                isInvalid={Boolean(validations && validations.i4)}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {validations && validations.i5 && (validations.i5[0] || validations.i5)}
                                                            </Form.Control.Feedback>
                                                        </FloatingLabel>
                                                    </Col>
                                                </Card.Body>
                                            </Card>
                                            <Card>
                                                <Card.Header>
                                                    Поздрав
                                                </Card.Header>
                                                <Card.Body>
                                                    <Col className="mb-3">
                                                        <FloatingLabel label="6">
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="6"
                                                                name="i6"
                                                                value={state.data.i6 || ''}
                                                                onChange={handleInputChange}
                                                                isInvalid={Boolean(validations && validations.i6)}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {validations && validations.i6 && (validations.i6[0] || validations.i6)}
                                                            </Form.Control.Feedback>
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col className="mb-3">
                                                        <FloatingLabel label="7">
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="7"
                                                                name="i7"
                                                                value={state.data.i7 || ''}
                                                                onChange={handleInputChange}
                                                                isInvalid={Boolean(validations && validations.i7)}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {validations && validations.i7 && (validations.i7[0] || validations.i7)}
                                                            </Form.Control.Feedback>
                                                        </FloatingLabel>
                                                    </Col>
                                                </Card.Body>
                                            </Card>
                                        </Tab.Pane> : ''}
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            className="save"
                        />
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default forwardRef(AddOrEdit);
