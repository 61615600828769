import { useState, useImperativeHandle, forwardRef } from 'react'
import { Modal, Button } from 'react-bootstrap';
import pic from 'assets/img/modals/question.png';
import { QuestionCircle } from 'react-bootstrap-icons';

function Question(props, ref) {
    const [state, setState] = useState({
        show: false,
        params: null,
        msg: null,
        onSuccess: null,
        onClose: null,
        onCancel: null
    });

    useImperativeHandle(ref, () => ({
        open: (msg = null) => {
            handleShow(msg);
        },
        close: () => {
            handleClose();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        },
        onCancel: fn => {
            setState(prev => ({
                ...prev,
                onCancel: fn
            }));
        }
    }));

    const show = () => {
        setState(prev => ({
            ...prev,
            show: true,
        }));
    }

    const hide = () => {
        setState(prev => ({
            ...prev,
            show: false,
        }));

        if (typeof state.onClose === 'function') {
            state.onClose();
        }
    }

    const handleShow = (msg) => {
        setState(prev => ({
            ...prev,
            msg: msg
        }));

        show();
    }

    const handleAccept = () => {
        hide();

        if (typeof state.onSuccess === 'function') {
            state.onSuccess();
        }

        setState(prev => ({
            ...prev,
            onSuccess: null,
        }));
    }

    const handleClose = () => {
        hide();

        if (typeof state.onCancel === 'function') {
            state.onCancel();
        }

        setState(prev => ({
            ...prev,
            onCancel: null,
        }));
    }

    const style = {
        button: {
            margin: '0 10px',
            minWidth: '120px'
        }
    }

    return (
        <Modal centered show={state.show} onHide={handleClose}>
            <Modal.Body>
                {/* <div style={{ textAlign: 'center', marginTop: '10px', marginBottom: '20px' }}> */}
                {/* <QuestionCircle fontWeight={400} size={80} style={{ color: '#444' }} /> */}
                {/* <img src={pic} alt="" style={{ width: '90px' }} /> */}
                {/* </div> */}

                {props.children
                    ?
                    <div style={{ fontSize: '1.0rem', textAlign: 'center', fontWeight: '300' }}>
                        {props.children}
                    </div>
                    :
                    <>
                        <p style={{ fontSize: '1.25rem', textAlign: 'center', fontWeight: '300' }}>
                            {state.msg || props.mainMessage}
                        </p>
                        <p style={{ textAlign: 'center', fontWeight: '300' }}>
                            Моля, потвърдете вашият избор!
                        </p>
                    </>
                }

                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <Button variant="outline-secondary" onClick={handleClose} style={style.button}>
                        {props.cancelBtnText || 'Отказ'}
                    </Button>
                    <Button variant="danger" onClick={handleAccept} style={style.button}>
                        {props.agreeBtnText || 'Да'}
                    </Button>
                </div>
            </Modal.Body>

        </Modal>
    )
}

export default forwardRef(Question);