import { Button, Spinner } from 'react-bootstrap';

function Index(props) {

    const text = props.children || props.text || 'Запази';
    const variant = props.variant || 'dark';
    const type = props.type || 'submit';
    const size = props.size || '';
    const onClick = props.onClick || function () { };
    const loading = props.loading || false;
    const disabled = props.disabled || false
    const className = props.className || '';

    return (
        <Button
            type={type}
            variant={variant}
            size={size}
            className={className}
            onClick={onClick}
            disabled={loading || disabled}
            style={{ position: 'relative', ...props.style || {} }}
            title={props.title}
        >

            {loading
                ?
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />
                :
                text
            }

        </Button>
    );
}

export default Index;