import { cloneElement, createElement, useEffect, useState } from 'react';
import PageLoader from './misc/PageLoader';
import Pipeline from 'middlewares/Pipeline';
import Container from 'Container';
import Access from 'middlewares/Access';

function Controller({ component: Component, ...routeProps }) {

    const app = Container.getInstance();
    // const app = useAppContext();

    const auth = app.getAuth();

    const location = app.getLocation();

    let middlewares = routeProps.middleware || [];

    if (!Array.isArray(middlewares)) {
        middlewares = [middlewares];
    }

    const [state, setState] = useState({
        processedMiddleware: false,
    });

    const processMiddleware = () => {

        middlewares.push(Access);

        (new Pipeline())
            .setPipes(middlewares)
            .setRouteProps(routeProps)
            .process(app, () => {
                setState(prev => ({
                    ...prev,
                    processedMiddleware: true
                }));
            });
    }

    useEffect(() => {
        console.log('PROCESS MIDDLEWARE');

        processMiddleware();

        return () => {
            setState(prev => ({
                ...prev,
                processedMiddleware: false
            }));
        }
    }, [location.pathname, auth.getUser()?.getStoreId()]);

    return (
        state.processedMiddleware
            ?
            routeProps.children
            :
            <PageLoader show />

    )
}

export default Controller;
