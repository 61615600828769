import { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import moment from "moment"
import { FloatingLabel, Form, Dropdown, Row, Col } from 'react-bootstrap'

//providers
// import { useAuthDataContext } from "providers/Auth"

//helpers
import { useQuery } from 'helpers/Url'
import Api from 'helpers/Api'
import Loader from 'components/misc/Loader'
import NoDataFound from 'components/misc/NoDataFound'
import Category from 'components/partials/filter/Category'
import DatePicker from 'components/partials/filter/DatePicker'
import ArticleDetail from 'components/partials/filter/ArticleDetail'
import Autocomplete from 'components/misc/Autocomplete'

function Filter(props) {
    const history = useNavigate()
    const query = useQuery()

    // const auth = useAuthDataContext();

    const [filters, setFilters] = useState({
        show: false,
    });


    const handleDateChange = (date, name) => {
        props.handleSearch(name, moment(date).format('YYYY-MM-DD'));
    }

    const handleSearch = e => {
        const name = e.target.name
        const value = e.target.value

        props.handleSearch(name, value)
    }

    const handleCheckboxChange = e => {
        const name = e.target.name
        const value = e.target.checked ? 1 : 0

        props.handleSearch(name, value)
    }

    const handleFromDateChange = e => {
        const name = e.target.name
        const value = e.target.value

        let date = value ? moment(value).format('YYYY-MM-DD') : '';

        props.handleSearch({
            created_from_date: date,
            created_to_date: date,
        });
    }

    const handleToDateChange = e => {
        const value = e.target.value

        let date = value ? moment(value).format('YYYY-MM-DD') : '';

        if (date && props.filter.created_from_date > date) {
            return;
        }

        props.handleSearch({
            created_to_date: date,
        });
    }

    const handleUpdatedFromDateChange = e => {
        const name = e.target.name
        const value = e.target.value

        let date = value ? moment(value).format('YYYY-MM-DD') : '';

        props.handleSearch({
            updated_from_date: date,
        });
    }

    const handleUpdatedToDateChange = e => {
        const value = e.target.value

        let date = value ? moment(value).format('YYYY-MM-DD') : '';

        if (date && props.filter.updated_from_date > date) {
            return;
        }

        props.handleSearch({
            updated_to_date: date,
        });
    }

    const handleCategoryChange = selected => {
        props.handleSearch('category_id', selected)
    }

    const handleArticleDetailChange = selected => {
        props.handleSearch('detail_value_id', JSON.stringify(selected))
    }

    const handlePricesChange = data => {
        props.handleSearch('prices', data.map(c => c.id));
    }

    const toggleFilters = () => {
        setFilters(prev => ({
            ...prev,
            show: !prev.show,
        }))
    }

    const getMoreFiltersValue = () => {
        let data = [];

        if (props.filter.created_from_date) {
            data.push(1);
        }

        if (props.filter.created_to_date) {
            data.push(1);
        }

        if (props.filter.updated_from_date) {
            data.push(1);
        }

        if (props.filter.updated_to_date) {
            data.push(1);
        }

        if (props.filter.barcode) {
            data.push(1);
        }

        if (props.filter.file) {
            data.push(1);
        }

        if (props.filter.deleted) {
            data.push(1);
        }

        if (Number(props.filter.current_price_less_than_avg_delivery_price) === 1) {
            data.push(1);
        }

        if (data.length === 0) {
            return 'Няма избрани';
        }

        return data.length + ' избрани';
    }

    return (
        <div className="row page-filter">
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <FloatingLabel label="Търсене">
                    <Form.Control
                        type="text"
                        placeholder="Търсене"
                        name="search"
                        value={props.filter.search || ''}
                        onChange={handleSearch}
                        autoFocus
                    />
                </FloatingLabel>
            </div>
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <FloatingLabel label="Реф. №">
                    <Form.Control
                        type="text"
                        placeholder="Реф. №"
                        name="ref"
                        value={props.filter.ref || ''}
                        onChange={handleSearch}
                    />
                </FloatingLabel>
            </div>
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <Category
                    selected={props.filter.category_id || []}
                    onChange={handleCategoryChange}
                />
            </div>
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <ArticleDetail
                    selected={props.filter.detail_value_id}
                    onChange={handleArticleDetailChange}
                />
            </div>
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <Autocomplete
                    inputPlaceholder="Цени"
                    inputIdName="prices[]"
                    selectedIds={props.prices || []}
                    data={[
                        {
                            id: 'equal',
                            name: 'Текуща продажна цена = Основна продажна цена'
                        },
                        {
                            id: 'greater',
                            name: 'Текуща продажна цена > Основна продажна цена'
                        },
                        {
                            id: 'lower',
                            name: 'Текуща продажна цена < Основна продажна цена'
                        },
                    ]}
                    multiple
                    onChange={handlePricesChange}
                />
            </div>
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <Dropdown drop="down" onToggle={toggleFilters} show={filters.show}>
                    <FloatingLabel label="Още филтри">
                        <Form.Control
                            type="text"
                            placeholder="Още филтри"
                            value={getMoreFiltersValue()}
                            onClick={toggleFilters}
                            readOnly
                            style={{
                                cursor: 'pointer'
                            }}
                        />
                    </FloatingLabel>
                    <Dropdown.Menu align="start" style={{ padding: '15px', width: '450px', right: 0 }}>
                        <Row>
                            <Col className="mb-3">
                                <DatePicker
                                    placeholder="Създаден от"
                                    name="created_from_date"
                                    value={props.filter.created_from_date || ''}
                                    onChange={handleFromDateChange}
                                />
                            </Col>
                            <Col className="mb-3">
                                <DatePicker
                                    placeholder="Създаден до"
                                    name="created_to_date"
                                    value={props.filter.created_to_date || ''}
                                    onChange={handleToDateChange}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-3">
                                <DatePicker
                                    placeholder="Актуализиран от"
                                    name="updated_from_date"
                                    value={props.filter.updated_from_date || ''}
                                    onChange={handleUpdatedFromDateChange}
                                />
                            </Col>
                            <Col className="mb-3">
                                <DatePicker
                                    placeholder="Актуализиран до"
                                    name="updated_to_date"
                                    value={props.filter.updated_to_date || ''}
                                    onChange={handleUpdatedToDateChange}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FloatingLabel label="Баркод" className="mb-3">
                                    <Form.Select
                                        name="barcode"
                                        value={props.filter.barcode || ''}
                                        onChange={handleSearch}
                                    >
                                        <option value="">По подразбиране</option>
                                        <option value="yes">С баркод</option>
                                        <option value="no">Без баркод</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                            <Col>
                                <FloatingLabel label="Изображение" className="mb-3">
                                    <Form.Select
                                        name="file"
                                        value={props.filter.file || ''}
                                        onChange={handleSearch}
                                    >
                                        <option value="">По подразбиране</option>
                                        <option value="yes">С изображение</option>
                                        <option value="no">Без изображение</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <FloatingLabel label="Изтрити">
                                    <Form.Select
                                        name="deleted"
                                        value={props.filter.deleted || ''}
                                        onChange={handleSearch}
                                    >
                                        <option value="">По подразбиране</option>
                                        <option value="with-deleted">Покажи изтрити</option>
                                        <option value="only-deleted">Покажи само изтрити</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row >
                            <Col>
                                <Form.Check
                                    type="switch"
                                    name="current_price_less_than_avg_delivery_price"
                                    label="Продажна цена < от себестойност (+ДДС)"
                                    checked={Number(props.filter.current_price_less_than_avg_delivery_price) === 1}
                                    onChange={handleCheckboxChange}
                                />
                            </Col>
                        </Row>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    )
}

export default Filter;
