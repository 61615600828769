import axios from "axios";
import { useEffect, useState } from "react";
import { Column } from '@ant-design/plots';
import Api from "helpers/Api";

function Users(props) {
    const [state, setState] = useState({
        loading: true,
        refresh: false,
        setFilter: false,
        data: [

        ],
        filter: {

        },
        chart: null,
        request: null,
    });

    const [data, setData] = useState([]);

    useEffect(() => {
        if (props.refresh) {
            loadData();
        }
    }, [state.refresh, props.refresh]);

    useEffect(() => {
        if (state.loading) {
            return;
        }

        let data = [];

        state.data.map(c => {
            let group = {
                xField: c.user,
                yField: c.stats.accounts_count || 0,
                seriesField: 'Сметки'
            };

            data.push(group);

            let group_2 = {
                xField: c.user,
                yField: c.stats.total_real || 0,
                seriesField: 'Оборот'
            };

            data.push(group_2);

            let group_3 = {
                xField: c.user,
                yField: c.stats.total_paid_sum || 0,
                seriesField: 'Получено'
            };

            data.push(group_3);
        });

        setData(data);
    }, [state.data])

    const loadData = () => {
        if (state.request) {
            state.request.cancel();
        }

        let request = axios.CancelToken.source();

        setState(prev => ({
            ...prev,
            request: request,
            loading: true,
        }));

        let url = 'dashboard/totals-by-users';

        Api.get(url, {
            params: state.filter,
            cancelToken: request.token
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data.items,
                currency: res.data.currency,
                loading: false,
                sorting: false,
                filter: {
                    ...prev.filter,
                    ...res.data.filter,
                }
            }));



        });
    }

    const setChart = chart => {
        setState(prev => ({
            ...prev,
            chart: chart
        }));
    }

    const config = {
        data,
        height: 200,
        xField: 'xField',
        yField: 'yField',
        seriesField: 'seriesField',
        // color: ['l(270) 0:#C871F3 0.5:#C871F3 1:#F871CF', 'l(270) 0:#0DCFF5 0.5:#40E0D4 1:#83F8A9'],
        isGroup: true,
        columnStyle: {
            radius: [20, 20, 0, 0],
        },
        maxColumnWidth: 15,
        // xAxis: {
        //     type: 'time',
        //     mask: state.filter.group === 'day' ? 'DD-MM-YYYY' : 'MM-YYYY',
        // },
        yAxis: {
            label: {
                formatter: (v) => Number(v).toFixed(0),
            },
        },
        tooltip: {
            formatter: (datum) => {
                return {
                    name: datum.seriesField,
                    value: datum.yField.toFixed(2),
                };
            },
        },
        legend: false,
    };

    return (
        <div className="card card-h-100">
            <div className="d-flex card-header justify-content-between align-items-center">
                <h4 className="header-title">Оборот по персонал за деня</h4>

            </div>
            <div className="card-body chart pt-0">
                <div className="graph">
                    <Column {...config} onReady={e => setChart(e)} />
                </div>
            </div>
        </div>
    )
}

export default Users;