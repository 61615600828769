import { useState, useRef, useEffect } from 'react';
import { useAuthDataContext } from 'providers/Auth';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useQuery } from 'helpers/Url';
import Api from 'helpers/Api';
import PageLoader from 'components/misc/PageLoader';
import SaveButton from 'components/misc/Button';
import './partials/style.scss';
import logo from 'assets/img/logo.svg';
import { Button } from 'react-bootstrap';

let timeout;

function Index() {

    const auth = useAuthDataContext();
    const navigate = useNavigate();
    const params = useParams();
    const query = useQuery();
    const location = useLocation();

    const redirectUrl = query.get('redirect');

    const [loading, setLoading] = useState(false);

    const [validation, setValidation] = useState(false);

    useEffect(() => {
        if (auth.isLogged()) {
            navigate('/');
        }

    }, []);

    const handleLogin = e => {

        if (e) {
            e.preventDefault();
        }

        setValidation(false);
        setLoading(true);

        clearTimeout(timeout);

        let data = new FormData(e.target);

        data.append('username', query.get('username'));

        timeout = setTimeout(() => {
            let url = 'admin/auth/password/set';

            Api.post(url, data)
                .then(res => {

                    if (res.status === 200 && res.data.user) {
                        auth.login(res.data);

                        if (redirectUrl) {
                            navigate(redirectUrl);
                        } else {
                            navigate('/');
                        }
                    }

                }).catch(err => {

                    let _err = err.response;

                    if (_err && _err.status) {
                        if (_err.status === 422) {
                            for (let [input, err] of Object.entries(_err.data.errors)) {
                                if (input === 'password_reset') {
                                    return navigate('/set-password');
                                }

                                setValidation(prev => {
                                    return { ...prev, [input]: err }
                                });
                            }
                        }

                        if (_err.status == 401) {
                            setValidation(true);
                        }
                    }

                }).finally(() => {
                    setLoading(false);
                });
        }, 500);

    }

    const handleBack = e => {
        e.preventDefault();

        navigate('/login');
    }

    return (
        <>
            <main className="login">

                <form className="text-center form-signin needs-validation" noValidate onSubmit={handleLogin}>

                    <img className="mb-4" src={logo} alt="" width="72" height="57" />

                    <h2 className="h3 mb-3 fw-normal">Моля, въведете вашите данни за вход</h2>

                    <div className="alert alert-info" role="alert">
                        Трябва да зададете парола за вход!
                    </div>

                    {/* {validation &&
                        <div className="alert alert-danger" role="alert">
                            Неуспешен вход!
                        </div>
                    } */}

                    <div className="form-floating">
                        <input name="password" type="password" className={`top form-control ${validation && 'is-invalid'}`} placeholder="Парола" required />
                        <label>Парола</label>
                        {validation.password &&
                            <div class="invalid-feedback">
                                {validation.password[0] || validation.password}
                            </div>
                        }
                    </div>

                    <div className="form-floating">
                        <input name="password_confirmation" type="password" className={`bottom form-control ${validation && 'is-invalid'}`} placeholder="Повторете паролата" required />
                        <label>Повторете паролата</label>
                    </div>

                    <SaveButton
                        className="w-100 btn btn-lg btn-dark"
                        loading={loading}
                    >
                        Запази
                    </SaveButton>
                    <Button
                        variant="outline-dark"
                        className="w-100 mt-2 btn btn-lg"
                        onClick={handleBack}
                    >
                        Отказ
                    </Button>


                </form>
            </main>
        </>
    )
}

export default Index;
