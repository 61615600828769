import { forwardRef, useImperativeHandle } from "react";
import { Form } from "react-bootstrap";

function LoadPrice(props, ref) {

    useImperativeHandle(ref, () => ({
        getName: getName,
        getType: getType,
        getTitle: getTitle,
        hasSettings: hasSettings,
        getSettings: getSettings,
        getDefaultOptions: ({ createOrUpdate }) => ({ action: 'ignore' })
    }));

    const getName = () => {
        return 'Цена';
    }

    const getType = () => {
        return 'load_price';
    }

    const getTitle = () => {
        return 'Единична цена на всеки артикул в операцията';
    }

    const hasSettings = () => true;

    const getSettings = ({ state, setConfig }) => {

        return (
            <div>
                <label>
                    При наличие на доставчик:
                </label>
                <div>
                    <Form.Check
                        id={'cb_ignore'}
                        type="radio"
                        label="Без допълнително действие"
                        checked={state.options?.action === 'ignore'}
                        onChange={e => setConfig('action', 'ignore')}
                    />
                    <Form.Check
                        id={'cb-save'}
                        type="radio"
                        label="Запази като договорена цена"
                        checked={state.options?.action === 'save'}
                        onChange={e => setConfig('action', 'save')}
                    />
                </div>
            </div>
        )
    }

    return getName();
}

export default forwardRef(LoadPrice)