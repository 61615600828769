import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import moment from 'moment'
import { Modal, Button, Tabs, Tab, FloatingLabel, Form, Row, Col, Table } from 'react-bootstrap';

//helpers
import Api from 'helpers/Api'
import { useNestedState } from 'helpers/NestedState'

//misc
import Autocomplete from 'components/misc/Autocomplete';
import SaveButton from 'components/misc/Button'
import Info from 'components/modals/Info';
import HelpInfo from 'components/misc/Info'

import Success from 'components/modals/Success';
import Error from 'components/modals/Error';
import Loader from 'components/misc/Loader';

let timeout;

function SmartUcfStatus(props, ref) {

    const defaultData = {

    }

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        data: defaultData,
        loading: false,
        dataLoading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null,
        onClose: null,
    });

    const successModalRef = useRef(null);
    const errorModalRef = useRef(null);

    useImperativeHandle(ref, () => ({
        show: (id) => {
            open(id);
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        }
    }));

    useEffect(() => {
        loadData();
    }, [state.refresh]);

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const open = (id) => {
        setState(prev => ({
            ...prev,
            data: defaultData,
            id: id,
            refresh: new Date()
        }));

        show();
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
        }));

        if (typeof state.onClose === 'function') {
            hide(state.onClose);
        } else {
            hide();
        }
    }

    const loadData = () => {
        if (!state.id) {
            return;
        }

        setState(prev => ({
            ...prev,
            dataLoading: true,
        }));

        Api.get('/orders/smart-ucf-status', {
            params: {
                id: state.id,
            }
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data,
                dataLoading: false
            }));
        }).catch(err => {
            let modal = errorModalRef.current;

            if (modal) {
                hide(() => {
                    modal.open(err.response.data.message);
                });
            }
        })
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleCheckboxChange = e => {
        const name = e.target.name;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: prev.data[name] ? false : true
            }
        }));
    }

    return (
        <>
            <Success
                ref={successModalRef}
            />

            <Error
                ref={errorModalRef}
            />

            <Modal size="md" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <form>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Статус на заявката за кредитиране
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        {state.dataLoading
                            ?
                            <Loader />
                            :
                            <>
                                <Table size="sm" bordered>
                                    <tbody>
                                        <tr>
                                            <td className="th">
                                                Статус
                                            </td>
                                            <td>
                                                {state.data.reqStatusText || 'неизвестно'}
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>

                                {state.data.onlineRequestInfo &&
                                    <Table size="sm" bordered>
                                        <tbody>
                                            <tr>
                                                <td className="th">
                                                    Сума
                                                </td>
                                                <td>
                                                    {state.data.onlineRequestInfo.totalPrice}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="th">
                                                    Първоначална вноска
                                                </td>
                                                <td>
                                                    {state.data.onlineRequestInfo.initialPayment}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="th">
                                                    Срок
                                                </td>
                                                <td>
                                                    {state.data.onlineRequestInfo.installmentCount}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="th">
                                                    Месечна вноска
                                                </td>
                                                <td>
                                                    {state.data.onlineRequestInfo.monthlyPayment}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="th">
                                                    ЕГН
                                                </td>
                                                <td>
                                                    {state.data.onlineRequestInfo.clientEGN}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="th">
                                                    Име
                                                </td>
                                                <td>
                                                    {state.data.onlineRequestInfo.clientFirstName}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="th">
                                                    Презиме
                                                </td>
                                                <td>
                                                    {state.data.onlineRequestInfo.clientSurName}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="th">
                                                    Фамилия
                                                </td>
                                                <td>
                                                    {state.data.onlineRequestInfo.clientLastName}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="th">
                                                    Телефон
                                                </td>
                                                <td>
                                                    {state.data.onlineRequestInfo.clientPhone}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="th">
                                                    Е-поща
                                                </td>
                                                <td>
                                                    {state.data.onlineRequestInfo.clientEmail}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                }

                                {state.data.contract &&
                                    <Table size="sm" bordered>
                                        <tbody>
                                            <tr>
                                                <td className="th">
                                                    Договор № / Дата
                                                </td>
                                                <td>
                                                    {state.data.contract.no} / {state.data.contact.date}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="th">
                                                    Дата на подписване
                                                </td>
                                                <td>
                                                    {state.data.contract.signDate}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                }
                            </>
                        }

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Затвори
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default forwardRef(SmartUcfStatus);
