import TableCol from "components/misc/TableCol";
import Title from "components/misc/Title";

function DynamicTableCol(props) {
    const name = props.name || '';
    const sortKey = props.sortKey || '';
    const type = props.type || '';
    const sortable = typeof props.sortable !== 'undefined' ? props.sortable : true;
    const title = props.title;

    const classes = {
        price: 'text-right',
        number: 'text-right',
        percent: 'text-right',
        preview: 'picture',
        left: 'text-left',
        center: 'text-center',
        right: 'text-right',
        article_quantity: 'text-right',
    };

    const getClassName = () => {
        return classes[type] || '';
    }

    return (
        <TableCol style={props.name === 'ID' ? { width: '70px' } : { whiteSpace: 'nowrap' }} {...props} className={getClassName()} sortable={sortable} sortKey={sortKey}>
            {title
                ?
                <Title title={title}>
                    {name}
                </Title>
                :
                name
            }
        </TableCol>
    )
}

export default DynamicTableCol;