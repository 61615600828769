import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import moment from 'moment'
import { Modal, Button, Col, Row, Nav, FloatingLabel, Form, TabContainer, Tab, Alert, Card } from 'react-bootstrap';

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//providers
import { useLanguageContext } from 'providers/Language'
import { useAuthDataContext } from 'providers/Auth';

//misc
import Question from 'components/modals/Question';
import Addresses from 'components/pages/clients/partials/Addresses';
import SaveButton from 'components/misc/Button'
import Info from 'components/modals/Info';
import Autocomplete from 'components/misc/Autocomplete';
import HelpInfo from 'components/misc/Info';
import Item from './Item';

function AddOrEdit(props, ref) {

    const auth = useAuthDataContext();

    const defaultData = {
        langs: {},
        file: null
    }
    const [adminRoles, setAdminRoles] = useState([]);

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        edit: false,
        refresh: false,
        data: defaultData,
        initialData: defaultData,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null,
        mainTabId: 'main',
    });

    const [validations, setValidations] = useValidation();

    const inputFileRef = useRef(null);
    const validationModalRef = useRef(null);
    const deleteModalRef = useRef(null);

    useImperativeHandle(ref, () => ({
        add: () => {
            handleShow();
        },
        edit: (id) => {
            edit(id);
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        }
    }));

    useEffect(() => {
        loadData();
    }, [state.edit]);

    useEffect(() => {
        Api.get('admin/admins/roles')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setAdminRoles(res.data);
                }
            });

    }, []);

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const handleShow = () => {
        setState(prev => ({
            ...prev,
            mainTabId: 'main',
            data: defaultData,
            initialData: defaultData,
            id: null,
        }));

        show();
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            initialData: defaultData,
            id: null,
        }));

        setValidations(null);

        hide();
    }

    const edit = id => {
        setState(prev => ({
            ...prev,
            id: id,
            edit: new Date().getTime()
        }));

        show();
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            refresh: new Date().getTime(),
        }));
    }

    useEffect(() => {
        if (state.refresh) {
            loadData();
        }
    }, [state.refresh]);

    const loadData = () => {
        if (!state.id) {
            return;
        }

        Api.get('admin/admins/show?id=' + state.id)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data,
                    initialData: res.data,
                    mainTabId: 'main',
                }))
            });
    }

    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = state.id ? 'admin/admins/update' : 'admin/admins/add';

        let data = new FormData(e.target);
        if (state.data.file?.was_recently_attached) {
            data.append('image', state.data.file);
        }

        if (state.id) {
            data.append('id', state.id)
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (typeof state.onSuccess === 'function') {
                state.onSuccess(res.data)
            }

            hide()
        }).catch(error => {
            const _err = error.response;
            if (_err && _err.status && _err.status === 422) {
                hide(() => {
                    let modal = validationModalRef.current;

                    if (modal) {
                        modal.open();

                        modal.onClose(() => {
                            setValidations(_err.data.errors)
                            show();
                        });
                    }
                });
            }
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }));
        });
    }

    const setMainTab = key => {
        setState(prev => ({
            ...prev,
            mainTabId: key
        }));
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleDiscountChange = e => {
        const name = e.target.name
        let value = e.target.value

        if (!value.includes('+') && !value.includes('-') && value.length > 0) {
            value = '-' + value;
        }

        if (value.includes('+')) {
            value = value.replace('-', '')
        }

        setState(name, value, 'data')
    }

    const handleDateChange = e => {
        const name = e.target.name + '_ymd'
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleCheckboxChange = e => {
        const name = e.target.name;
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: prev.data[name] ? false : true
            }
        }));
    }

    const handleMainTabValidate = () => {
        let valid = false;
        let main = ['username', 'password', 'card_code', 'pin', 'role_id', 'max_discount'];

        if (validations) {
            main.map(t => {
                if (validations[t] && state.mainTabId !== 'main') {
                    valid = true
                }
            })
        }

        return valid;
    }

    const handlePrivateTabValidate = (tab) => {
        let valid = false;
        let main = ['firstname', 'surname', 'lastname', 'email', 'phone', 'phone_private', 'phone_internal', 'description'];

        if (validations) {
            main.map(t => {
                if (validations[t] && state.mainTabId !== 'private') {
                    valid = true
                }
            })
        }

        return valid;
    }

    const handleSettingsTabValidate = (tab) => {
        let valid = false;
        let main = ['store_id', 'remote_access', 'session_timeout', 'sales_auto_logout'];

        if (validations) {
            main.map(t => {
                if (validations[t] && state.mainTabId !== 'settings') {
                    valid = true
                }
            })
        }

        return valid;
    }

    const handleAttach = e => {
        let file = e.target.files[0];
        let url = URL.createObjectURL(file);

        file.id = Math.random().toString(7).substring(2);
        file.was_recently_attached = true;
        file.url = url;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                file: file
            }
        }));
    }

    const handleShowAttach = e => {
        e.preventDefault();

        inputFileRef.current.click();
    }

    const handleStoreChange = (data) => {
        // if (data?.length) return
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                stores: data,
            }
        }));
    }

    console.log(state.data)

    return (
        <>
            <Info
                ref={validationModalRef}
                mainMessage="Моля, попълнете всички задължителни полета, означени с червен цвят!"
            />

            <Question
                ref={deleteModalRef}
                mainMessage="Сигурни ли сте?"
                agreeBtnText="Изтриване"
                agreeBtnClass="remove"
            />

            <Modal size="lg" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <form onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Потребител
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Tab.Container activeKey={state.mainTabId} onSelect={key => setMainTab(key)}>
                            <Row>
                                <Col xl={3} sm={3}>
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link style={{ color: handleMainTabValidate() ? 'red' : '' }} eventKey="main">Основни данни</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link style={{ color: handlePrivateTabValidate() ? 'red' : '' }} eventKey="private">Лични данни</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link style={{ color: handleSettingsTabValidate() ? 'red' : '' }} eventKey="settings">Настройки за достъп</Nav.Link>
                                        </Nav.Item>

                                        <Nav.Item>
                                            <Nav.Link eventKey="image">Изображение</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                <Col xl={9} sm={9}>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="main">

                                            <FloatingLabel label="Потребителско име" className="mb-3">
                                                <Form.Control
                                                    type="text"
                                                    name={`username`}
                                                    autoComplete="new-password"
                                                    value={state.data.username || ''}
                                                    placeholder="Потребителско име"
                                                    onChange={(e) => handleInputChange(e)}
                                                    isInvalid={Boolean(validations?.username)}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {validations?.username && (validations?.username[0] || validations?.username)}
                                                </Form.Control.Feedback>
                                            </FloatingLabel>

                                            <Row>
                                                <Col>
                                                    <FloatingLabel label="Парола" className="mb-3">
                                                        <Form.Control
                                                            type="password"
                                                            name={`password`}
                                                            autoComplete="new-password"
                                                            value={state.data.password || ''}
                                                            placeholder="Парола"
                                                            onChange={(e) => handleInputChange(e)}
                                                            isInvalid={Boolean(validations?.password)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations?.password && (validations?.password[0] || validations?.password)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel label="Повтори парола" className="mb-3">
                                                        <Form.Control
                                                            type="password"
                                                            name={`password_confirmation`}
                                                            autoComplete="new-password"
                                                            value={state.data.password_confirmation || ''}
                                                            placeholder="Повтори парола"
                                                            onChange={(e) => handleInputChange(e)}
                                                            isInvalid={Boolean(validations?.password)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations?.password && (validations?.password[0] || validations?.password)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col>
                                                    <FloatingLabel label="Пин" className="mb-3">
                                                        <Form.Control
                                                            type="text"
                                                            name={`pin`}
                                                            autoComplete="new-password"
                                                            value={state.data.pin || ''}
                                                            placeholder="Пин"
                                                            onChange={(e) => handleInputChange(e)}
                                                            isInvalid={Boolean(validations?.pin)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations?.pin && (validations?.pin[0] || validations?.pin)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel label="Код на карта" className="mb-3">
                                                        <Form.Control
                                                            type="text"
                                                            name={`card_code`}
                                                            autoComplete="new-password"
                                                            value={state.data.card_code || ''}
                                                            placeholder="Код на карта"
                                                            onChange={(e) => handleInputChange(e)}
                                                            isInvalid={Boolean(validations?.card_code)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations?.card_code && (validations?.card_code[0] || validations?.card_code)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col className="mb-3">
                                                    <FloatingLabel label="Роля">
                                                        <Form.Select
                                                            name="role_id"
                                                            value={state.data.role_id || ''}
                                                            onChange={handleInputChange}
                                                            disabled={auth.getUser().permission('users_edit_role') === false}
                                                        >
                                                            {adminRoles.map((item, index) =>
                                                                <Item
                                                                    onChange={handleInputChange}
                                                                    key={index}
                                                                    index={index}
                                                                    level={1}
                                                                    {...item}

                                                                />
                                                            )}
                                                        </Form.Select>
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel label="Отстъпка %" className="mb-3">
                                                        <Form.Control
                                                            type="text"
                                                            name={`max_discount`}
                                                            autoComplete="new-password"
                                                            value={state.data.max_discount || ''}
                                                            placeholder="Отстъпка %"
                                                            onChange={(e) => handleInputChange(e)}
                                                            isInvalid={Boolean(validations?.max_discount)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations?.max_discount && (validations?.max_discount[0] || validations?.max_discount)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>

                                        </Tab.Pane>

                                        <Tab.Pane eventKey="private">
                                            <Row>
                                                <Col>
                                                    <FloatingLabel label="Име" className="mb-3">
                                                        <Form.Control
                                                            type="text"
                                                            name={`firstname`}
                                                            autoComplete="new-password"
                                                            value={state.data.firstname || ''}
                                                            placeholder="Име"
                                                            onChange={(e) => handleInputChange(e)}
                                                            isInvalid={Boolean(validations?.firstname)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations?.firstname && (validations?.firstname[0] || validations?.firstname)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>

                                                <Col>
                                                    <FloatingLabel label="Презиме" className="mb-3">
                                                        <Form.Control
                                                            type="text"
                                                            name={`surname`}
                                                            autoComplete="new-password"
                                                            value={state.data.surname || ''}
                                                            placeholder="Презиме"
                                                            onChange={(e) => handleInputChange(e)}
                                                            isInvalid={Boolean(validations?.surname)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations?.surname && (validations?.surname[0] || validations?.surname)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>

                                                <Col>
                                                    <FloatingLabel label="Фамилия" className="mb-3">
                                                        <Form.Control
                                                            type="text"
                                                            name={`lastname`}
                                                            autoComplete="new-password"
                                                            value={state.data.lastname || ''}
                                                            placeholder="Потребителско име"
                                                            onChange={(e) => handleInputChange(e)}
                                                            isInvalid={Boolean(validations?.lastname)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations?.lastname && (validations?.lastname[0] || validations?.lastname)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col>
                                                    <FloatingLabel label="Е-поща" className="mb-3">
                                                        <Form.Control
                                                            type="text"
                                                            name={`email`}
                                                            autoComplete="new-password"
                                                            value={state.data.email || ''}
                                                            placeholder="Е-поща"
                                                            onChange={(e) => handleInputChange(e)}
                                                            isInvalid={Boolean(validations?.email)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations?.email && (validations?.email[0] || validations?.email)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel label="Телефон" className="mb-3">
                                                        <Form.Control
                                                            type="text"
                                                            name={`phone`}
                                                            autoComplete="new-password"
                                                            value={state.data.phone || ''}
                                                            placeholder="Телефон"
                                                            onChange={(e) => handleInputChange(e)}
                                                            isInvalid={Boolean(validations?.phone)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations?.phone && (validations?.phone[0] || validations?.phone)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col>
                                                    <FloatingLabel label="Телефон личен" className="mb-3">
                                                        <Form.Control
                                                            type="text"
                                                            name={`phone_private`}
                                                            autoComplete="new-password"
                                                            value={state.data.phone_private || ''}
                                                            placeholder="Телефон личен"
                                                            onChange={(e) => handleInputChange(e)}
                                                            isInvalid={Boolean(validations?.phone_private)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations?.phone_private && (validations?.phone_private[0] || validations?.phone_private)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel label="Телефон вътрешен" className="mb-3">
                                                        <Form.Control
                                                            type="text"
                                                            name={`phone_internal`}
                                                            autoComplete="new-password"
                                                            value={state.data.phone_internal || ''}
                                                            placeholder="Телефон вътрешен"
                                                            onChange={(e) => handleInputChange(e)}
                                                            isInvalid={Boolean(validations?.phone_internal)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations?.phone_internal && (validations?.phone_internal[0] || validations?.phone_internal)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>

                                            <Col>
                                                <FloatingLabel label="Бележки" className="mb-3">
                                                    <Form.Control
                                                        as="textarea"
                                                        style={{ height: '100px' }}
                                                        name={`description`}
                                                        autoComplete="new-password"
                                                        value={state.data.description || ''}
                                                        placeholder="Бележки"
                                                        onChange={(e) => handleInputChange(e)}
                                                        isInvalid={Boolean(validations?.description)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {validations?.description && (validations?.description[0] || validations?.description)}
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>
                                            </Col>
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="settings">
                                            <Col className="mb-3">
                                                <Autocomplete
                                                    multiple
                                                    url="autocomplete/stores"
                                                    params={{
                                                        all: 1
                                                    }}
                                                    inputPlaceholder="Търговски обекти"
                                                    inputIdName={`store_id`}
                                                    selectedValues={state.data.stores}
                                                    renderText={data => data?.name}
                                                    renderInputText={data => data?.name}
                                                    // inputIdValue={state.data.store_id || ''}
                                                    error={Boolean(validations?.store_id)}
                                                    helperText={validations?.store_id && (validations?.store_id[0] || validations?.store_id)}
                                                    onChange={data => handleStoreChange(data)}
                                                />
                                            </Col>
                                            <Row>
                                                <Col>
                                                    <FloatingLabel label="Сесия" className="mb-3">
                                                        <Form.Control
                                                            type="number"
                                                            name={`session_timeout`}
                                                            autoComplete="new-password"
                                                            value={state.data.session_timeout || ''}
                                                            placeholder="Бележки"
                                                            onChange={(e) => handleInputChange(e)}
                                                            isInvalid={Boolean(validations?.session_timeout)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations?.session_timeout && (validations?.session_timeout[0] || validations?.session_timeout)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>

                                                <Col>
                                                    <FloatingLabel label="Автоматичен изход" className="mb-3">
                                                        <Form.Control
                                                            type="number"
                                                            name={`sales_auto_logout`}
                                                            autoComplete="new-password"
                                                            value={state.data.sales_auto_logout || ''}
                                                            placeholder="Бележки"
                                                            onChange={(e) => handleInputChange(e)}
                                                            isInvalid={Boolean(validations?.sales_auto_logout)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations?.sales_auto_logout && (validations?.sales_auto_logout[0] || validations?.session_timeout)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>

                                            <Form.Check
                                                type="switch"
                                                name="remote_access"
                                                label="Отдалечен достъп"
                                                className="mb-3 mt-3"
                                                checked={Number(state.data?.remote_access) === 1}
                                                onChange={handleCheckboxChange}
                                            />

                                        </Tab.Pane>

                                        <Tab.Pane eventKey="image">
                                            {!state.data.file?.url ? <Alert variant="info">
                                                Няма добавено изображение!
                                            </Alert> :

                                                <div className="mb-3">
                                                    <>
                                                        <div className="file">
                                                            <img style={{ maxHeight: '100px' }} src={state.data.file?.url} onError={e => e.target.classList.add('hidden')} />
                                                        </div>

                                                        <div className="validate">
                                                            {validations?.image}
                                                        </div>
                                                    </>
                                                </div>
                                            }

                                            <Button variant="outline-dark" size="sm" onClick={handleShowAttach}>
                                                Прикачете изображение
                                            </Button>

                                            <input ref={inputFileRef} type="file" accept=".png,.jpeg,.jpg" onChange={handleAttach} hidden />
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            className="save"
                        />
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default forwardRef(AddOrEdit);
