import { Button } from 'react-bootstrap';
import MenuOptions from 'components/misc/MenuOptions'
import Options from './Options';
import { useEffect } from 'react';

function Item(props) {
    const data = props;

    const getSpacing = (level) => {
        return '&nbsp;'.repeat((level - 1) * 2)
    }

    const handleInputChange = () => {
        props.handleInputChange()
    }

    const createMarkup = html => {
        return {
            __html: html
        }
    }

    return (
        <>
            {props.role_id != data.id
                ?
                <option
                    onChange={handleInputChange}
                    value={data.id}
                    level={props.level}
                    key={data.id}
                    data-parent={data.parent_id}
                    dangerouslySetInnerHTML={createMarkup(getSpacing(props.level) + ' - ' + data.name)}
                />
                :
                ''
            }
            {data.children && data.children.map((item, index) =>
                <Item
                    onChange={handleInputChange}
                    key={item.id}
                    role_id={props.role_id}
                    index={index}
                    level={props.level + 1}
                    {...item}
                />
            )}
        </>
    )
}

export default Item;
