import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'

//plugins
import { Modal, Button } from 'react-bootstrap';

//helpers
import { useNestedState } from 'helpers/NestedState'

//misc
import Form from './Form';
import SaveButton from 'components/misc/Button'

import { useArticleContext } from '../Provider';
import { useAppContext } from 'providers/App';
import { useAuthDataContext } from 'providers/Auth';
import Refs from 'Refs';

const defaultParams = {
    resource: true,
}

function AddOrEdit(props, ref) {


    const app = useAppContext();
    const auth = useAuthDataContext();

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        edit: false,
        params: defaultParams,
        refresh: false,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null,
        onClose: null,
    });

    const context = useArticleContext();

    useImperativeHandle(ref, () => ({
        add: (params) => {
            add(params);
        },
        edit: (id, params) => {
            edit(id, params);
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        }
    }));

    const formRef = useRef(null);

    useEffect(() => {
        if (!state.show) {
            return;
        }

        if (auth.getUser().permission('articles_edit') === false) {
            app.showError('Нямате право да редактирате артикул!');

            hide(state.onClose);
        }

    }, [state.show]);

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting,
        }));
    }

    const add = (params) => {

        if (typeof params !== 'object') {
            params = defaultParams;
        }

        show();
    }

    const edit = (id, params) => {

        if (typeof params !== 'object') {
            params = defaultParams;
        }

        setState(prev => ({
            ...prev,
            id: id,
            edit: new Date().getTime()
        }));

        show();
    }

    const refresh = (id) => {
        setState(prev => ({
            ...prev,
            id: id,
            edit: new Date().getTime(),
        }));
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            id: null,
        }));

        context.resetData();

        if (typeof state.onClose === 'function') {
            hide(state.onClose);
        } else {
            hide();
        }

        setState(prev => ({
            ...prev,
            onClose: null,
        }));
    }

    const onSuccess = (data) => {
        if (typeof state.onSuccess === 'function') {
            state.onSuccess(data)
        }

        if (state.edit === false) {
            let modal = Refs.getInstance().getRef('article');

            if (!modal) {
                return;
            }

            modal.open(data.id);
        }

        hide(state.onClose);
        // edit(data.id);
    }

    return (
        <>

            <Modal enforceFocus={false} size="xl" centered={true} backdrop="static" show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Артикул {state.data?.id && '(ID: ' + state.data.id + ')'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        ref={formRef}
                        id={state.id}
                        params={state.params}
                        show={show}
                        hide={hide}
                        edit={edit}
                        refresh={refresh}
                        onSuccess={onSuccess}
                        category={props.category}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Отказ
                    </Button>
                    <SaveButton
                        loading={context.state?.loading}
                        className="save"
                        onClick={e => formRef.current?.save(e)}
                    />
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default forwardRef(AddOrEdit);
