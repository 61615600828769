import { useState, useRef, useEffect } from 'react';
import { useAuthDataContext } from 'providers/Auth';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { buildUrl, useQuery } from 'helpers/Url';
import Api from 'helpers/Api';
import PageLoader from 'components/misc/PageLoader';
import SaveButton from 'components/misc/Button';
import './partials/style.scss';
import logo from 'assets/img/logo.svg';

let timeout;

function Index() {

    const auth = useAuthDataContext();
    const navigate = useNavigate();
    const params = useParams();
    const query = useQuery();
    const location = useLocation();

    const loginToken = query.get('token');
    const redirectUrl = query.get('redirect');

    const [loading, setLoading] = useState(false);

    const [validation, setValidation] = useState(false);

    useEffect(() => {
        if (auth.isLogged() && !loginToken) {
            navigate('/');
        }

        if (loginToken) {
            loginViaToken();
        }
    }, []);

    const handleLogin = e => {

        if (e) {
            e.preventDefault();
        }

        setValidation(false);
        setLoading(true);

        clearTimeout(timeout);

        let data = new FormData(e.target);

        timeout = setTimeout(() => {
            let url = 'admin/auth/login';

            Api.post(url, data)
                .then(res => {

                    if (res.status === 200 && res.data.user) {
                        auth.login(res.data);

                        if (redirectUrl) {
                            navigate(redirectUrl);
                        } else {
                            navigate('/');
                        }
                    }

                }).catch(err => {

                    let _err = err.response;

                    if (_err && _err.status) {
                        if (_err.status === 422) {
                            for (let [input, err] of Object.entries(_err.data.errors)) {
                                if (input === 'password_reset') {

                                    let username = document.querySelector('#floatingInput').value;

                                    return navigate(buildUrl('/set-password', {
                                        username: username,
                                        redirect: redirectUrl || '',
                                    }));
                                }

                                setValidation(prev => {
                                    return { ...prev, [input]: err }
                                });
                            }
                        }

                        if (_err.status == 401) {
                            setValidation(true);
                        }
                    }

                }).finally(() => {
                    setLoading(false);
                });
        }, 500);

    }

    // todo
    const loginViaToken = () => {

        auth.logout(false);

        let url = 'admin/auth/login-via-token';

        Api.post(url, {
            token: loginToken
        })
            .then(res => {

                if (res.status === 200 && res.data.user) {
                    auth.login(res.data);
                    navigate('/');
                }

            }).catch(err => {

                if (err.response && err.response.status) {
                    if (err.response.status === 403) {

                    }
                }

            })
    }

    return (
        <>
            {loginToken
                ?
                <PageLoader />
                :
                <main className="login">

                    <form className="text-center form-signin needs-validation" noValidate onSubmit={handleLogin}>

                        <img className="mb-4" src={logo} alt="" width="120" height="70" />

                        <h2 className="h3 mb-3 fw-normal">Моля, въведете вашите данни за вход</h2>


                        {validation &&
                            <div className="alert alert-danger" role="alert">
                                Неуспешен вход!
                            </div>
                        }

                        <div className="form-floating">
                            <input name="username" type="text" className={`top form-control ${validation && 'is-invalid'}`} id="floatingInput" placeholder="Потребителско име" required />
                            <label htmlFor="floatingInput">Потребителско име</label>
                        </div>

                        <div className="form-floating">
                            <input name="password" type="password" className={`bottom form-control ${validation && 'is-invalid'}`} id="floatingPassword" placeholder="Парола" required />
                            <label htmlFor="floatingPassword">Парола</label>
                        </div>

                        <div className="checkbox mb-3">
                            <label>
                                <input name="remember" type="checkbox" value="remember-me" /> Запомни ме
                            </label>
                        </div>

                        <SaveButton
                            className="w-100 btn btn-lg btn-dark"
                            loading={loading}
                        >
                            Вход
                        </SaveButton>

                    </form>
                </main>
            }
        </>
    )
}

export default Index;
