import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import moment from "moment"
import { FloatingLabel, Form, Row, Col } from 'react-bootstrap'

//providers
// import { useAuthDataContext } from "providers/Auth"

//helpers
import { useQuery } from 'helpers/Url'

//misc
import Autocomplete from "components/misc/Autocomplete"
import Api from 'helpers/Api'

function Filter(props) {
    const history = useNavigate()
    const query = useQuery()

    const [groups, setGroups] = useState([]);

    useEffect(() => {
        Api.get('price_rules/groups')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setGroups(res.data);
                }
            });
    }, [])

    const handleDateChange = (date, name) => {
        props.handleSearch(name, moment(date).format('YYYY-MM-DD'));
    }

    const handleSearch = e => {
        const name = e.target.name
        const value = e.target.value

        props.handleSearch(name, value)
    }

    const handleStoreChange = (data) => {
        let checked = []
        data.map(t => {
            checked.push(t.id)
        })

        props.handleSearch('store_id', checked)
    }

    return (
        <div className="row page-filter">
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <FloatingLabel label="Търсене">
                    <Form.Control
                        type="text"
                        placeholder="Търсене"
                        name="search"
                        value={props.filter.search || ''}
                        onChange={handleSearch}
                        autoFocus
                    />
                </FloatingLabel>
            </div>
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <FloatingLabel label="Стойност">
                    <Form.Control
                        type="text"
                        placeholder="Стойност"
                        name="modificator"
                        value={props.filter.modificator || ''}
                        onChange={handleSearch}
                    />
                </FloatingLabel>
            </div>
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <FloatingLabel label="Група">
                    <Form.Select
                        name="group_id"
                        value={props.filter.group_id || ''}
                        onChange={handleSearch}
                    >
                        <option value="">Всички</option>
                        {groups.map(g =>
                            <option key={`group_id-${g.id}`} value={g.id}>{g.name}</option>
                        )}
                    </Form.Select>
                </FloatingLabel>
            </div>

            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <Col >
                    <Autocomplete
                        url="autocomplete/stores"
                        params={{
                            all: 1
                        }}
                        inputPlaceholder="Търговски обекти"
                        inputIdName={`store_id[]`}
                        selectedIds={props.filter.store_id || []}
                        renderText={data => data?.translation?.name || data.name}
                        onChange={data => handleStoreChange(data)}
                        multiple
                    />
                </Col>
            </div>

            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <FloatingLabel label="Статус">
                    <Form.Select
                        name="current_status"
                        value={props.filter.current_status || ''}
                        onChange={handleSearch}
                    >
                        <option value="">Всички</option>
                        <option value={1}>Чакащи</option>
                        <option value={2}>Активни</option>
                        <option value={3}>Неактивни</option>
                        <option value={4}>Архивирани</option>
                    </Form.Select>
                </FloatingLabel>
            </div>

        </div>
    )
}

export default Filter;
