export const TYPES = [
    {
        id: 1,
        name: 'Фактура'
    },
    {
        id: 2,
        name: 'Заявка към доставчик'
    },
    {
        id: 3,
        name: 'Клиентска заявка'
    },
    {
        id: 4,
        name: 'Сметка'
    },
    {
        id: 5,
        name: 'Пакетна карта'
    },
    {
        id: 6,
        name: 'Клиентски код'
    },
    {
        id: 7,
        name: 'Гаранция'
    },
    {
        id: 8,
        name: 'Договор'
    },
    {
        id: 9,
        name: 'Оферта'
    },
    {
        id: 10,
        name: 'Приемо-Предавателен Протокол'
    },
    {
        id: 11,
        name: 'Приветствие за новорегистриран клиент',
    },
    {
        id: 12,
        name: 'Информация за възстановяване на парола на клиент',
    },
    {
        id: 13,
        name: 'Напомняне за финализиране на поръчка',
    },
    {
        id: 14,
        name: 'Напомняне за наличен артикул',
    },
    {
        id: 15,
        name: 'Заявка за връщане',
    },
    {
        id: 999,
        name: 'Друг'
    },
];

export const TYPE = {
    INVOICE: 1,
    ORDER: 3,
    ACCOUNT: 4,
    WARRANTY: 7,
    CONTRACT: 8,
    OFFER: 9,
    PPP: 10,
};