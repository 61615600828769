import { useState, useImperativeHandle, forwardRef, useEffect } from 'react'
import { Modal, Button, Row, Col, FloatingLabel, Form } from 'react-bootstrap';
import { createPortal } from 'react-dom';

import SaveButton from 'components/misc/Button'
import { useAppContext } from 'providers/App';
import Api from 'helpers/Api';
import { useNavigate } from 'react-router-dom';
import { useValidation } from 'helpers/Validation';

function PrintCopyOptions(props, ref) {

    const app = useAppContext();
    const navigate = useNavigate();

    const defaultData = {
        quantity: 1
    }

    const [state, setState] = useState({
        show: false,
        params: null,
        data: defaultData,
        onSuccess: null,
        onClose: null,
        onEntering: null,
        onExiting: null,
    });

    const [validations, setValidations] = useValidation();

    useImperativeHandle(ref, () => ({
        open: () => {
            handleShow();
        },
        close: () => {
            handleClose();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        },
        onCancel: fn => {
            setState(prev => ({
                ...prev,
                onCancel: fn
            }));
        }
    }));

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const handleShow = () => {
        show();

        setState(prev => ({
            ...prev,
            data: defaultData,
        }));
    }

    const handleClose = () => {
        hide();
    }

    const handleSave = e => {
        e.preventDefault();

        // setState(prev => ({
        //     ...prev,
        //     loading: true,
        // }));

        hide();

        if (typeof state.onSuccess === 'function') {
            state.onSuccess({
                quantity: state.data.quantity || ''
            });
        }
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: value
            }
        }))
    }


    return createPortal(
        <Modal size="sm" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
            <form onSubmit={handleSave}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Брой копия за всички
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <FloatingLabel label="Брой копия">
                                <Form.Control
                                    placeholder="Брой копия"
                                    name="quantity"
                                    value={state.data.quantity || 0}
                                    onChange={handleInputChange}
                                    isInvalid={Boolean(validations && validations.quantity)}
                                />

                                <Form.Control.Feedback type="invalid">
                                    {validations && validations.quantity && (validations.quantity[0] || validations.quantity)}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Отказ
                    </Button>
                    <SaveButton
                        loading={state.loading}
                        className="save"
                    >
                        Задай
                    </SaveButton>
                </Modal.Footer>
            </form>
        </Modal>,
        document.body
    )

}

export default forwardRef(PrintCopyOptions);