import { useState, useEffect, useRef } from 'react'
import { Alert, Card, Table } from 'react-bootstrap';
import Api from 'helpers/Api';
import Loader from 'components/misc/Loader';
import moment from 'moment';
import NoDataFound from 'components/misc/NoDataFound';
import Pagination from 'components/misc/Pagination';
import TableHeader from 'components/misc/TableHeader';
import TableCol from 'components/misc/TableCol';
import PriceValue from 'components/partials/PriceValue';
import { diff, intersect, unique } from 'helpers/Arrays';

function Index(props) {

    const defaultData = [];

    const tableRef = useRef(null);

    const [state, setState] = useState({
        data: defaultData,
        pages: 0,
        total: 0,
        loading: false,
        filter: {
            page: 1,
        },
        refresh: false,
    });

    const [selected, setSelected] = useState([]);

    const validations = props.validations;

    useEffect(() => {

        loading(true);

        Api.get('payments/clients/unpaid-accounts', {
            params: {
                client_id: props.clientId,
                ...state.filter,
            }
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data.items,
                total: res.data.total,
                pages: res.data.pages,
                filter: {
                    ...prev.filter,
                    ...res.data.filter,
                },
            }));

            if (res.data.pages > 0 && state.filter.page > res.data.pages) {
                return handlePage({ selected: res.data.pages - 1 });
            }

            loading(false);
            sorting(false);
        });
    }, [props.clientId, state.refresh]);

    useEffect(() => {
        // let total = 0;

        // selected.map(r => {
        //     total += r.price;
        // });

        // console.log(total);

        props.onChange(selected);

    }, [selected]);

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }));
    }

    const sorting = (sorting) => {
        setState(prev => ({
            ...prev,
            sorting: Boolean(sorting)
        }));
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            refresh: new Date()
        }));
    }

    const handleSort = (sort, order) => {
        setState(prev => ({
            ...prev,
            sorting: true,
            filter: {
                ...prev.filter,
                sort: sort,
                order: order,
                page: 1,
            },
            refresh: new Date().getTime()
        }));
    }

    const handleSelect = account => {
        // let value = Number(e.target.value);

        if (isSelected(account)) {
            setSelected(prev => prev.filter(acc => Number(acc.id) !== Number(account.id)));
        } else {
            setSelected(prev => prev.concat(toSelectObj(account)));
        }
    }

    const isSelected = account => {
        return Boolean(selected.find(acc => Number(acc.id) === Number(account.id)));
    }

    const isAllSelected = () => {
        // return state.data.length === selected.length;

        let all = state.data.map(r => Number(r.id));
        let sel = selected.map(r => Number(r.id));

        state.data.forEach(r => {
            if (!isSelected(r)) {
                return false;
            }
        });

        // console.log(all);
        // console.log(sel);

        let res = diff(all, sel);

        // console.log(res);

        return res.length === 0;
    }

    const selectAll = () => {
        if (isAllSelected()) {
            setSelected([]);
        } else {
            setSelected(prev => prev.concat(state.data.map(r => {
                if (!isSelected(r)) {
                    return toSelectObj(r);
                }
            })));
        }
    }

    const toSelectObj = account => {
        return {
            id: Number(account.id),
            price: account.total_remain_sum,
        }
    }

    // console.log(selected)

    return (
        <Card className="">
            <Card.Header>
                Оставащи плащания на клиента
            </Card.Header>
            <Card.Body>

                {validations?.accounts &&
                    <Alert variant="danger">{validations?.accounts}</Alert>
                }

                {state.loading && !state.sorting
                    ?
                    <Loader />
                    :
                    state.data.length === 0 ?
                        <Alert style={{ margin: 0 }}>Няма намерени записи</Alert>
                        :
                        <>

                            <Table className="xxl" striped bordered ref={tableRef}>
                                <TableHeader
                                    tableRef={tableRef}
                                    activeSortKey={state.filter.sort}
                                    activeSortDir={state.filter.order}
                                    onSort={(col, dir) => handleSort(col, dir)}
                                >
                                    <TableCol className="text-center" style={{ width: '5%' }}>
                                        <input
                                            type="checkbox"
                                            onChange={selectAll}
                                            checked={isAllSelected()}
                                        />
                                    </TableCol>
                                    <TableCol sortable sortKey="id">Сметка №</TableCol>
                                    <TableCol className="text-right" sortable sortKey="total_real">Сума</TableCol>
                                    <TableCol className="text-right" sortable sortKey="total_paid_sum">Платени</TableCol>
                                    <TableCol className="text-right" sortable sortKey="total_remain_sum">Остават</TableCol>
                                    <TableCol className="text-center" sortable sortKey="">Фактури</TableCol>
                                    <TableCol className="text-left" sortable sortKey="alias">Заглавие</TableCol>
                                </TableHeader>
                                <tbody>
                                    {state.data.map((r, i) =>
                                        <tr key={r.id}>
                                            <td className="text-center">
                                                <input
                                                    type="checkbox"
                                                    name="accounts[]"
                                                    value={r.id}
                                                    onChange={e => handleSelect(r)}
                                                    checked={isSelected(r)}
                                                />
                                            </td>
                                            <td>
                                                {r.id}
                                            </td>
                                            <td className={`text-right`}>
                                                <PriceValue prefix={r.currency?.prefix} sufix={r.currency?.sufix}>{r.total_real}</PriceValue>
                                            </td>
                                            <td className={`text-right ${r.total_paid_sum < 0 && 'negative-value'}`}>
                                                <PriceValue prefix={r.currency?.prefix} sufix={r.currency?.sufix}>{r.total_paid_sum}</PriceValue>
                                            </td>
                                            <td className={`text-right ${r.total_remain_sum > 0 && 'negative-value'}`}>
                                                <PriceValue prefix={r.currency?.prefix} sufix={r.currency?.sufix}>{r.total_remain_sum}</PriceValue>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>

                            <Pagination
                                page={state.filter.page}
                                pages={state.pages}
                                handlePage={handlePage}
                            />
                        </>
                }
            </Card.Body>
        </Card>
    )
}

export default Index;