class Pipeline {
    pipes = [];
    props = null;

    constructor(pipes = []) {
        this.pipes = pipes;
    }

    setPipes(pipes) {

        if (pipes instanceof Pipeline) {
            pipes = pipes.toArray();
        }

        if (!pipes instanceof Array) {
            // 
        }

        return new Pipeline([...this.pipes, ...pipes]);
    }

    setRouteProps(props) {
        this.props = props;

        return this;
    }

    /**
     * Run middleware around core function and pass an object through it
     */
    process(object, core) {
        let coreFunction = this.createCoreFunction(core);

        let pipes = this.pipes.reverse();

        let completePipeline = pipes.reduce((nextPipe, pipe) => {

            let params = [];

            // todo: da se prihvanat parametri

            return this.createPipe(nextPipe, pipe, params);
        }, coreFunction);

        return completePipeline(object);
    }

    toArray() {
        return this.pipes;
    }

    createCoreFunction(core) {
        return (object) => {
            return core(object);
        };
    }

    createPipe(nextPipe, pipe, params = []) {
        return (object) => {
            return (new pipe(object)).run(this.props, nextPipe, params);
        };
    }
}

export default Pipeline;