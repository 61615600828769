import { useState, useEffect } from 'react'
import { Button, Dropdown, Table } from 'react-bootstrap';
import Api from 'helpers/Api';
import Loader from 'components/misc/Loader';
import moment from 'moment';
import { Pencil, Printer, Upc } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import { buildUrl } from 'helpers/Url';
import { useAuthDataContext } from 'providers/Auth';
import PriceValue from 'components/partials/PriceValue';
import Rows from './Rows';

function Index(props) {

    const { show, hide } = props;

    const auth = useAuthDataContext();
    const navigate = useNavigate();

    const defaultData = {
        rows: []
    };

    const [state, setState] = useState({
        data: defaultData,
        loading: false,
    });

    useEffect(() => {
        setState(prev => ({
            ...prev,
            loading: true
        }));

        Api.get('storemoves/show', {
            params: {
                id: props.id
            }
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data,
                loading: false,
            }));
        });
    }, [props.id]);

    const showArticle = id => {
        props.showArticle(id);
    }

    const handleEdit = () => {
        navigate('/storage/moves/' + state.data.id + '/edit');
    }

    const handlePrint = () => {
        window.open(buildUrl(state.data.stream_url, {
            token: auth.getToken()
        }));
    }

    const handleCreateAccount = () => {
        props.hide();

        navigate('/accounts/new?from_move_id=' + state.data.id);
    }

    const handleCreateStoreMove = () => {
        props.addStoreMove();
    }

    const handleCreateInvoice = () => {
        props.hide();

        navigate('/invoices/new?from_move_id=' + state.data.id);
    }

    const handleCreateDocument = () => {
        props.addDocument();
    }

    const handlePrintLabels = () => {
        props.hide();

        navigate('/articles-print-list-free?from_move_id=' + state.data.id);
    }

    return (

        state.loading
            ?
            <Loader />
            :
            <>
                <Table size="sm" bordered>
                    <tbody>
                        <tr>
                            <td className="th">
                                ID
                            </td>
                            <td>
                                {state.data.id}
                            </td>
                            <td className="th">
                                Създал
                            </td>
                            <td>
                                {state.data?.creator?.username}
                            </td>
                            <td className="th">
                                Създадено на
                            </td>
                            <td>
                                {state.data.created_at ? moment(state.data.created_at).format('DD.MM.YYYY HH:mm') : ''}
                            </td>
                        </tr>
                        <tr>
                            <td className="th">
                                От склад
                            </td>
                            <td>
                                {state.data?.fromdepot?.store?.translation?.name || state.data?.fromdepot?.store?.name} - {state.data?.fromdepot?.name}
                            </td>
                            <td className="th">
                                В склад
                            </td>
                            <td>
                                {state.data?.todepot?.store?.translation?.name || state.data?.todepot?.store?.name} - {state.data?.todepot?.name}
                            </td>
                            <td className="th">
                                Док. дата
                            </td>
                            <td>
                                {state.data.document_date ? moment(state.data.document_date).format('DD.MM.YYYY HH:mm') : ''}
                            </td>
                        </tr>
                        <tr>
                            <td className="th">
                                Статус
                            </td>
                            <td>
                                {state.data?.user_status?.translation?.name || state.data?.user_status?.name}
                            </td>
                            <td className="th">
                                Заредил
                            </td>
                            <td>
                                {state.data?.acceptor?.username}
                            </td>
                            <td className="th">
                                Дата на промяна на статуса
                            </td>
                            <td>
                                {state.data.status_updated_at ? moment(state.data.status_updated_at).format('DD.MM.YYYY HH:mm') : ''}
                            </td>
                        </tr>
                        <tr>
                            <td className="th">
                                Бележки
                            </td>
                            <td colspan={5}>
                                {state.data.description}
                            </td>
                        </tr>
                    </tbody>
                </Table>

                <div className="mt-3">
                    <Button size="sm" variant="primary" onClick={() => handleEdit()} disabled={state.data.allow_edit === false}>
                        <Pencil /> Редактиране
                    </Button>
                    <Button size="sm" variant="secondary" onClick={() => handlePrint()} style={{ marginLeft: '10px' }}>
                        <Printer /> Печат бланка
                    </Button>
                    <Dropdown drop="down" style={{ display: 'inline' }}>
                        <Dropdown.Toggle size="sm" style={{ marginLeft: '10px' }}>
                            Направи...
                        </Dropdown.Toggle>
                        <Dropdown.Menu align="start" style={{ padding: '15px', width: '150px' }}>
                            <Button size="sm" variant="primary" onClick={() => handleCreateAccount()} style={{ width: '100%' }}>
                                Сметка
                            </Button>
                            <br />
                            <Button size="sm" variant="primary" onClick={() => handleCreateStoreMove()} style={{ width: '100%', marginTop: '10px' }}>
                                Прехвърляне
                            </Button>
                            <br />
                            <Button size="sm" variant="primary" onClick={() => handleCreateInvoice()} style={{ width: '100%', marginTop: '10px' }}>
                                Фактура
                            </Button>
                            <br />
                            <Button size="sm" variant="primary" onClick={() => handleCreateDocument()} style={{ width: '100%', marginTop: '10px' }}>
                                Документ
                            </Button>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Button size="sm" variant="secondary" onClick={() => handlePrintLabels()} style={{ marginLeft: '10px' }}>
                        <Upc /> Печат етикети
                    </Button>
                </div>

                <Rows
                    id={state.data.id}
                    show={show}
                    hide={hide}
                />

            </>
    )
}

export default Index;