import React, { useState, useEffect } from 'react';
import { Toast as BootstrapToast } from 'react-bootstrap';


function Toast(props) {
    const [show, setShow] = useState(false);

    const variant = props.variant || 'light';
    const icon = props.icon;
    const title = props.title;
    const body = props.body || props.children;
    const dismissible = props.dismissible || false;

    useEffect(() => {
        setShow(props.show);
    }, [props.show]);

    const hasBoxShadow = () => {
        // ако има модал да няма сянка
        let el = document?.querySelector('.modal-backdrop:not([style="display:none"])');

        if (el) {
            return false;
        }

        return true;
    }

    return (
        <BootstrapToast bg={variant} show={show} className={props.className} style={{ boxShadow: hasBoxShadow() ? '0 0 10px #d1d1d1' : 'none', ...props.style }} onClose={() => setShow(false)} dismissible={dismissible}>
            <BootstrapToast.Header>
                {icon &&
                    <span style={{ marginRight: '5px', fontSize: '15px' }}>
                        {icon}
                    </span>
                }
                <strong className="me-auto" >{title}</strong>
                {/* <small>11 mins ago</small> */}
            </BootstrapToast.Header>
            <BootstrapToast.Body>{body}</BootstrapToast.Body>
        </BootstrapToast>
    );

}

export default Toast;