import { Form } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { usePermissionsContext } from 'providers/Permissions';

import {
    Plus,
    Dash
} from 'react-bootstrap-icons';

import { highlight } from 'helpers/Strings';

function Item(props) {

    const permission = usePermissionsContext();

    const data = props.data;
    const roles = props.roles;
    const permissionsRoles = props.permissionsRoles;

    const initialState = {
        treeWrap: true,
        checkedGroup: permission.state.checkedGroup,
        checkedPermission: permission.state.checkedPermission,
        refresh: false
    }

    // - role
    //      - module
    //          - permission

    const [state, setState] = useState(initialState)


    const handleTreeWrap = () => {
        setState(prev => ({
            ...prev,
            treeWrap: !prev.treeWrap
        }));
    }

    const handlePermissionChange = (permission_id, role_id) => {
        let name = 'permission-' + permission_id + '-' + role_id
        let groupName = 'group-' + data.id + '-' + role_id
        let obj = state.checkedPermission
        let groupObj = state.checkedGroup

        obj[name] = !state.checkedPermission[name]

        roles.map(r => {
            let count = 0
            data.permissions.map(p => {
                let name = 'permission-' + p.id + '-' + r.id

                if (role_id == r.id) {
                    if (obj[name]) {
                        count++
                    }

                    groupObj[groupName] = false;
                    if (count == data.permissions.length) {
                        groupObj[groupName] = true;
                    }
                }

            })
        })

        const modules = props.modules;
        let roleObj = permission.state.checkedRole;

        roles.map(r => {
            if (r.id == role_id) {
                let roleName = 'role-' + role_id
                let count = 0;
                modules.map(data => {
                    let groupName = 'group-' + data.id + '-' + role_id
                    if (groupObj[groupName]) count++
                })
                roleObj[roleName] = false;
                if (modules.length == count) {
                    roleObj[roleName] = true;
                }
            }
        })

        let updateData = ({
            checkedPermission: obj,
            checkedGroup: groupObj,
            checkedRole: permission.state.checkedRole,
        })

        permission.updateState(updateData)

    }

    const handleGroupChange = (role_id) => {
        let groupName = 'group-' + data.id + '-' + role_id

        let obj = state.checkedPermission
        let groupObj = state.checkedGroup

        groupObj[groupName] = !state.checkedGroup[groupName]

        data.permissions.map(p => {
            let name = 'permission-' + p.id + '-' + role_id
            obj[name] = false;
            if (groupObj[groupName]) {
                obj[name] = true;
            }
        })

        const modules = props.modules;
        let roleObj = permission.state.checkedRole;

        roles.map(r => {
            if (r.id == role_id) {
                let roleName = 'role-' + role_id
                let count = 0;
                modules.map(data => {
                    let groupName = 'group-' + data.id + '-' + role_id
                    if (groupObj[groupName]) count++
                })
                roleObj[roleName] = false;
                if (modules.length == count) {
                    roleObj[roleName] = true;
                }
            }
        })

        let updateData = ({
            checkedPermission: obj,
            checkedGroup: groupObj,
            checkedRole: roleObj,
        })

        permission.updateState(updateData)
    }

    const isModuleHidden = () => {
        if (!props.filter.search) {
            return false;
        }

        for (let i = 0; i < data.permissions.length; i++) {
            if (!isHidden(data.permissions[i])) {
                return false;
            }
        }

        return true;
    }

    const isHidden = (permission) => {
        let name = permission.translation?.name || permission.name;

        if (props.filter.search) {
            if (name.toLowerCase().includes(props.filter.search.toLowerCase()) === false) {
                return true;
            }
        }

        return false;
    }

    const createMarkup = str => {
        return { __html: str };
    }

    return (
        <>
            <tr
                key={'data-' + data.id}
                style={{ background: 'cornsilk' }}
                className={isModuleHidden() ? 'hidden' : ''}
            >
                <td>
                    <div style={{ display: 'flex' }}>
                        <div style={{ paddingRight: '10px', cursor: 'pointer' }}>
                            {state.treeWrap ? <Dash
                                onClick={handleTreeWrap}
                            /> :
                                <Plus
                                    onClick={handleTreeWrap}
                                />
                            }
                        </div>
                        {data.translation?.name || data.name}
                    </div>
                </td>
                {roles.map((r, key) =>
                    <td>
                        <Form.Check
                            key={r.id}
                            type="switch"
                            name={`group-${data.id}-${r.id}`}
                            data-module-id={data.id}
                            data-role-id={r.id}
                            checked={state.checkedGroup['group-' + data.id + '-' + r.id] || permission.state.checkedGroup['group-' + data.id + '-' + r.id]}
                            onChange={() => handleGroupChange(r.id)}
                            className="yellow"
                        />
                    </td>
                )}
            </tr>
            {data.permissions.map(p =>
                <>
                    <tr className={!state.treeWrap || isHidden(p) ? 'hidden' : ''} key={p.id}>
                        <td style={{ paddingLeft: '60px' }} title={p.key}>
                            <span dangerouslySetInnerHTML={createMarkup(highlight(props.filter.search, p.translation?.name || p.name))} />
                        </td>
                        {roles.map((r, key) =>
                            <td key={r.id}>
                                <Form.Check
                                    key={state.checkedPermission['permission-' + p.id + '-' + r.id]}
                                    name={`permission[${p.id}][]`}
                                    value={r.id}
                                    type="switch"
                                    data-module-id={data.id}
                                    data-role-id={r.id}
                                    data-permission-id={p.id}
                                    checked={state.checkedPermission['permission-' + p.id + '-' + r.id] || permission.state.checkedPermission['permission-' + p.id + '-' + r.id]}
                                    onChange={() => handlePermissionChange(p.id, r.id)}
                                />
                            </td>
                        )}

                    </tr>
                </>

            )}
        </>
    )
}

export default Item;
