import { useEffect, useRef, useState } from 'react';
import { buildUrl, useQuery } from 'helpers/Url';
import { useLocation, useNavigate, Link, useParams } from 'react-router-dom';
import { Button, Table, Form, Row, Col, Container, InputGroup } from 'react-bootstrap';
import { ArrowLeftCircle, Upload, XCircle } from 'react-bootstrap-icons';
import { useNestedState } from 'helpers/NestedState';
import { useCurrencyContext } from 'providers/Currency';
import { useValidation } from 'helpers/Validation';
import { useAuthDataContext } from 'providers/Auth';
import { cloneDeep } from 'lodash';

import Question from 'components/modals/Question';
import Layout from 'components/layout/Layout';
import Api from 'helpers/Api';

//misc
import Loader from 'components/misc/Loader';
import Alert from 'components/misc/Alert';
import moment from 'moment';
import Autocomplete from 'components/misc/Autocomplete';
import SaveButton from 'components/misc/Button';
import Pagination from 'components/misc/Pagination'
import Info from 'components/misc/Info';
import Error from 'components/modals/Error';
import TableRow from './partials/TableRow';
import TableCategoryRow from './partials/TableCategoryRow';
import Article from 'components/pages/articles/partials/AddOrEdit';
import DatePicker from 'components/partials/filter/DatePicker';
import Import from 'components/modals/Import';
import { TYPES } from 'constants/imports';
import Refs from 'Refs';
import { useAppContext } from 'providers/App';

let timeout;

function Index() {

    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const query = useQuery();

    const app = useAppContext();
    const auth = useAuthDataContext();
    const currencies = useCurrencyContext();

    const user = auth.getUser();

    const [state, setState] = useNestedState({
        loading: false,
        loadingBase: false,
        loadingClose: false,
        loadingChanges: false,
        dataLoading: false,
        rowsLoading: false,
        data: {
            allow_close: user.permission('revisions_close') ? true : false,
        },
        rows: {
            rows: [{}],
            pages: 0,
            total: 0,
            filter: {
                page: 1,
            },
            refresh: false,
        },
        original: {},
        timeout: null
    });

    const [hiddenCategories, setHiddenCategories] = useState([]);

    const [validations, setValidations] = useValidation();

    const formRef = useRef(null);
    const saveAndCloseModalRef = useRef(null);
    const errorModalRef = useRef(null);
    const articleModalRef = useRef(null);
    const importModalRef = useRef(null);

    useEffect(() => {
        if (params.id) {
            loadFullData();
        }
    }, [params.id]);

    useEffect(() => {
        if (state.data.view_type === 'selected' && state.rows.rows.length === 0) {
            setState(prev => ({
                ...prev,
                rows: {
                    ...prev.rows,
                    rows: [{}]
                }
            }));
        }
    }, [state.rows.rows]);

    useEffect(() => {
        setState(prev => ({
            ...prev,
            rows: {
                ...prev.rows,
                filter: {
                    ...prev.rows.filter,
                    page: query.get('page') || 1,
                    search: query.get('search') || '',
                },
                refresh: new Date().getTime()
            },
        }))
    }, [location.search]);

    useEffect(() => {
        if (state.rows.refresh) {

            setState(prev => ({
                ...prev,
                rowsLoading: true
            }));

            loadRows().then(() => {
                setState(prev => ({
                    ...prev,
                    rowsLoading: false
                }));
            });
        }
    }, [state.rows.refresh]);

    useEffect(() => {
        if (!state.data.id) {
            return;
        }

        // при тест с laravel dev server
        // заявката блокира всички останали, тъй като
        // уеб сървъра може да обработва само 1 заявка
        let base = process.env.REACT_APP_URL;
        // let base = 'http://localhost:8001';

        let url = buildUrl(base + '/api/revisions/calculation-progress', {
            id: state.data.id,
            token: auth.getToken()
        });

        const eventSource = new EventSource(url);

        eventSource.addEventListener('ping', event => {
            let data = JSON.parse(event.data);

            // console.log(data);

            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    calculating: data.calculating,
                    calculationPercent: data.percent || 0,
                },
            }));

            if (data.ready && data.percent === 100) {
                eventSource.close();

                loadRows();
            }

        });

        eventSource.onerror = err => {
            app.showError('EventSource failed');
        }

        return () => {
            eventSource.close();
        }

    }, [state.data.id]);

    const loadFullData = () => {
        loading(true);

        let p1 = loadData();
        let p2 = loadRows();

        Promise.all([p1, p2]).then(() => {
            loading(false);
        });
    }

    const loadData = async () => {
        let url = 'revisions/show';

        return Api.get(url, {
            params: {
                id: params.id
            }
        }).then(res => {
            setDataResponse(res.data);
        }).catch(err => {
            console.log(err);
        })
    }

    const loadRows = async () => {
        let url = 'revisions/rows';

        return Api.get(url, {
            params: {
                ...state.rows.filter,
                id: params.id,
            }
        }).then(res => {
            setRowsResponse(res.data);
        }).catch(err => {
            console.log(err);
        })
    }

    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout);

        if (typeof key === 'object') {
            setState(prev => ({
                ...prev,
                rows: {
                    ...prev.rows,
                    filter: {
                        ...prev.rows.filter,
                        ...key
                    },
                }
            }));
        } else {
            setState(prev => ({
                ...prev,
                rows: {
                    ...prev.rows,
                    filter: {
                        ...prev.rows.filter,
                        [key]: val,
                    },
                }
            }));
        }

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                rows: {
                    ...prev.rows,
                    filter: {
                        ...prev.rows.filter,
                        page: 1
                    },
                    refresh: new Date().getTime()
                }
            }));
        }, delay);
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            rows: {
                ...prev.rows,
                filter: {
                    ...prev.rows.filter,
                    page: page.selected + 1,
                },
                refresh: new Date().getTime()
            }
        }));
    }

    const setDataResponse = data => {
        setState(prev => ({
            ...prev,
            data: data,
            original: cloneDeep(data),
        }));
    }

    const setRowsResponse = data => {
        let rows = data.items;

        rows = rows.map(row => {
            //

            return row;
        });

        setState(prev => ({
            ...prev,
            rows: {
                ...prev.rows,
                rows: rows,
                pages: data.pages,
                page: data.page,
                total: data.total,
            }
        }));
    }

    const isDirty = (key, modificator = null) => {
        if (modificator) {
            return modificator(state.data[key]) !== modificator(state.original[key]);
        }

        return String(state.data[key]) !== String(state.original[key]);
    }

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            dataLoading: Boolean(loading)
        }));
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleRowChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'rows')
    }

    const handleToDateChange = e => {
        const value = e.target.value

        setState('to_date_local', value, 'data')
    }

    const handleCheckboxChange = e => {
        const name = e.target.name;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: prev.data[name] ? false : true
            }
        }));
    }

    const save = (close = false) => {
        if (state.loading) {
            return;
        }

        setState(prev => ({
            ...prev,
            loading: true,
            loadingBase: close ? false : true,
            loadingClose: close ? true : false,
        }));

        setValidations(null);

        let url = params.id ? 'revisions/edit' : 'revisions/add';

        let form = formRef.current;

        let data = new FormData(form);

        if (params.id) {
            data.append('id', params.id)
        }

        if (close) {
            data.append('close', 1);
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            navigate('/storage/revisions');
        }).catch(error => {
            const _err = error.response;

            if (_err && _err.status) {
                if (_err.status === 422) {
                    setValidations(_err.data.errors);
                } else {
                    let modal = errorModalRef.current;

                    if (modal) {
                        modal.open(_err.data.error || _err.data.message);
                    }
                }
            }

        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
                loadingBase: false,
                loadingClose: false,
            }));
        });
    }

    const handleSave = e => {
        e.preventDefault();

        save(false);
    }

    const handleSaveAndClose = e => {
        e.preventDefault();

        let modal = saveAndCloseModalRef.current;

        if (modal) {
            modal.open();
            modal.onSuccess(() => {
                save(true);
            });
        }

    }

    const applyChanges = () => {
        if (!params.id) {
            return;
        }

        if (state.loading) {
            return;
        }

        setState(prev => ({
            ...prev,
            loading: true,
            loadingChanges: true,
        }));

        setValidations(null);

        let url = 'revisions/change';

        let form = formRef.current;

        let data = new FormData(form);

        data.append('id', params.id)

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            setDataResponse(res.data.data);
            setRowsResponse(res.data.rows);
        }).catch(error => {
            const _err = error.response;

            if (_err && _err.status) {
                if (_err.status === 422) {
                    setValidations(_err.data.errors);
                } else {
                    let modal = errorModalRef.current;

                    if (modal) {
                        modal.open(_err.data.error || _err.data.message);
                    }
                }
            }

        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
                loadingChanges: false,
            }));
        });
    }

    const handleApplyChanges = e => {
        e.preventDefault();

        applyChanges();
    }

    const handleAddRow = e => {
        e.preventDefault()

        setState(prev => ({
            ...prev,
            rows: {
                ...prev.rows,
                rows: prev.rows.rows.concat({})
            }
        }))
    }

    const handleRemoveRow = (e, index) => {
        e.preventDefault()

        setState(prev => ({
            ...prev,
            rows: {
                ...prev.rows,
                rows: prev.rows.rows.filter((row, i) => i !== index)
            }
        }))
    }

    const handleChangeArticle = (data, index) => {

        let row = state.rows.rows[index];

        if (data.id) {
            // взимаме данни за наличността...
            getRow(data.id, row => {
                setState(prev => ({
                    ...prev,
                    rows: {
                        ...prev.rows,
                        rows: Object.values({
                            ...prev.rows.rows,
                            [index]: {
                                ...prev.rows.rows[index],
                                ...row,
                                article: data,
                                article_id: data.id,
                            }
                        })
                    }
                }));
            });
        } else {
            setState(prev => ({
                ...prev,
                rows: {
                    ...prev.rows,
                    rows: Object.values({
                        ...prev.rows.rows,
                        [index]: {
                            ...prev.rows.rows[index],
                            amount_unit: null,
                            storage_quantity: null,
                            actual_quantity: null,
                            quantity_diff: null,
                            amount_unit_diff: null,
                            article_actual_price: null,
                            article_delivery_price: null,
                            article: data,
                            article_id: null,
                        }
                    })
                }
            }));
        }

    }

    const handleChangeReason = (data, index) => {

        let row = state.rows.rows[index];

        // да обновим за всички еднакви артикули причината
        setState(prev => ({
            ...prev,
            rows: {
                ...prev.rows,
                rows: prev.rows.rows.map(r => {
                    if (r.article_id === row.article_id) {
                        r.reason = data;
                        r.reason_id = data?.id || null
                    }

                    return r;
                })
            }
        }));
    }

    const getRow = (articleId, callback) => {
        Api.get('revisions/row', {
            params: {
                revision_id: params.id,
                article_id: articleId
            }
        }).then(res => {
            callback(res.data);
        });
    }

    // при смяна на количеството 
    const handleQtyChange = (e, index) => {
        let row = state.rows.rows[index];

        // handleRowChange(e);

        let value = e.target.value;

        // да обновим за всички еднакви артикули количеството
        setState(prev => ({
            ...prev,
            rows: {
                ...prev.rows,
                rows: prev.rows.rows.map(r => {
                    if (r.article_id === row.article_id) {
                        r.actual_quantity = value;
                    }

                    return r;
                })
            }
        }))
    }

    const getTotalQty = () => {
        let total = 0;

        state.rows.rows.map(row => {
            total += parseFloat(row.actual_quantity || 0);
        });

        return total;
    }

    const getRowStorageQuantity = row => {
        if (typeof row.storage_quantity !== 'undefined') {
            return parseFloat(row.storage_quantity || 0);
        }

        return null;
    }

    const getRowQuantityDiff = row => {
        if (row.actual_quantity) {
            return parseFloat(row.actual_quantity || 0) - parseFloat(row.storage_quantity || 0);
        }

        return null;
    }

    const getRowAmountUnitDiff = row => {
        if (!row.amount_unit || !row.actual_quantity) {
            return null;
        }

        // let isAmountFloat = isFloat(row.amount_unit);
        // let isQuantityFloat = isFloat(row.actual_quantity);

        // if (isAmountFloat || isQuantityFloat) {
        return getRowQuantityDiff(row) / parseFloat(row.amount_unit || 0);
        // }

        // return getRowQuantityDiff(row) - parseFloat(row.storage_quantity || 0);
    }

    const getRowPriceDiff = row => {
        switch (Number(state.data.price_mode_id)) {
            case 1:
                return getRowAmountUnitDiff(row) * parseFloat(row.article_actual_price || 0);
            case 2:
                return getRowQuantityDiff(row) * parseFloat(row.article_delivery_price || 0);
        }
    }

    const expandCategory = id => {
        if (isCategoryHidden(id)) {
            setHiddenCategories(prev => prev.filter(c => c !== id));
        } else {
            setHiddenCategories(prev => prev.concat(id));
        }
    }

    const isCategoryHidden = id => {
        return hiddenCategories.findIndex(c => c === id) > -1;
    }

    const renderRows = () => {
        let output = [];

        let category = null;
        let visible = true;

        state.rows.rows.map((row, index) => {

            if (state.data.view_type === 'category') {
                if (row.category) {
                    if (row.category.id !== category) {

                        let total = state.rows.rows.filter(c => c?.category?.id === row.category.id).length;
                        let expanded = !isCategoryHidden(row.category.id);

                        visible = expanded;

                        output.push(
                            <TableCategoryRow
                                category={row.category}
                                total={total}
                                expanded={expanded}
                                expand={expandCategory}
                            />
                        );
                    }
                }
            }

            output.push(
                <TableRow
                    key={index}
                    state={state}
                    validations={validations}
                    row={row}
                    index={index}
                    handleChangeArticle={handleChangeArticle}
                    getRowStorageQuantity={getRowStorageQuantity}
                    handleQtyChange={handleQtyChange}
                    getRowQuantityDiff={getRowQuantityDiff}
                    getRowAmountUnitDiff={getRowAmountUnitDiff}
                    getRowPriceDiff={getRowPriceDiff}
                    handleChangeReason={handleChangeReason}
                    handleRemoveRow={handleRemoveRow}
                    handleShowArticle={handleShowArticle}
                    visible={visible}
                />
            );

            category = row.category?.id || null;
        })

        return output;
    }

    const handleShowArticle = id => {
        let modal = Refs.getInstance().getRef('article');

        if (modal) {
            modal.open(id);
        }
    }

    const handleImport = () => {
        let modal = importModalRef.current;

        if (!modal) {
            return;
        }

        modal.open();

        modal.setParams({
            id: state.data.id
        });

        modal.onSuccess(() => {
            setState(prev => ({
                ...prev,
                rows: {
                    ...prev.rows,
                    filter: {
                        ...prev.rows.filter,
                        page: 1,
                        search: '',
                    },
                    refresh: new Date().getTime()
                },
            }))
        });
    }

    const handleDelete = data => {
        let modal = Refs.getInstance().getRef('delete');

        modal.open();
        modal.onSuccess(() => {
            handleDestroy(state.data.id);
        })
    }

    const handleDestroy = id => {
        Api.post('revisions/delete', {
            id: id
        }).then(res => {
            if (res.data.success) {
                navigate('/storage/revisions');
            }
        });
    }

    return (
        <>

            <Question
                ref={saveAndCloseModalRef}
                mainMessage="Сигурни ли сте, че искате да затворите тази ревизия?"
            />

            <Error
                ref={errorModalRef}
            />

            <Import
                ref={importModalRef}
                type={TYPES.REVISION}
                createText="Замяна"
                updateText="Добавяне"
            />

            <Layout>
                <div className="container-fluid">

                    <div className="page-head">
                        <h1 className="display-6">
                            <Link to={-1} className="link-dark text-decoration-none">
                                <ArrowLeftCircle />
                            </Link> {params.id ? 'Редактиране на ревизия' : 'Нова ревизия'}
                        </h1>

                        <div className="buttons">

                        </div>
                    </div>

                    <br />

                    <div className="panel">

                        {state.dataLoading
                            ?
                            <Loader />
                            :
                            <>
                                {state.data.calculating === 1 &&
                                    <Alert variant="warning" style={{ position: 'fixed', bottom: '10px', zIndex: 1 }}>
                                        Внимание! Наличността на артикулите в тази ревизия все още не е напълно изчислена. ({state.data.calculationPercent || 0}%)
                                    </Alert>
                                }

                                <Form ref={formRef}>
                                    {/* <Container> */}
                                    <div className="form-panel">
                                        <div className="head">
                                            Данни за ревизията
                                        </div>
                                        <Row>
                                            <Col className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Ревизия №
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder=""
                                                            name="ref"
                                                            value={state.data.id || ''}
                                                            readOnly
                                                            size="sm"
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Създал
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder=""
                                                            name="ref"
                                                            value={state.data?.creator?.username || ''}
                                                            readOnly
                                                            size="sm"
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Създадено на
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder=""
                                                            name="ref"
                                                            value={state.data.created_at ? moment(state.data.created_at).utc().format('DD.MM.YYYY HH:mm') : ''}
                                                            readOnly
                                                            size="sm"
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={4} className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Търговски обект
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder=""
                                                            name="ref"
                                                            value={state.data?.depot?.store?.translation?.name || state.data?.depot?.store?.name || ''}
                                                            readOnly
                                                            size="sm"
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col sm={4} className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Склад
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder=""
                                                            name="ref"
                                                            value={state.data?.depot?.translation?.name || state.data?.depot?.name || ''}
                                                            readOnly
                                                            size="sm"
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col sm={4} className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Статус
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder=""
                                                            name="ref"
                                                            value={state.data?.status?.translation?.name || state.data?.status?.name || ''}
                                                            readOnly
                                                            size="sm"
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={4}>
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Вид
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Select
                                                            name="view_type_id"
                                                            value={state.data.view_type_id || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.view_type_id)}
                                                            size="sm"
                                                        >
                                                            <option value={1}>Всички артикули по категории</option>
                                                            <option value={2}>Избрани артикули</option>
                                                            <option value={3}>Всички артикули с наличност</option>
                                                            <option value={4}>Всички артикули</option>
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.view_type_id && (validations.view_type_id[0] || validations.view_type_id)}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col sm={4}>
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Калкулация по
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Select
                                                            name="price_mode_id"
                                                            value={state.data.price_mode_id || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.price_mode_id)}
                                                            size="sm"
                                                        >
                                                            <option value={1}>Продажни цени</option>
                                                            <option value={2}>Доставни цени</option>
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.price_mode_id && (validations.price_mode_id[0] || validations.price_mode_id)}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col sm={4}>
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Наличност към <Info placement="top">Изберете дата, към която искате да бъдат количествата в ревизията. Избраната дата трябва да бъде между датата на последната затворена ревизия за избрания склад и текущия момент.</Info>
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <DatePicker
                                                            variant="basic"
                                                            type="datetime-local"
                                                            name="to_date"
                                                            value={state.data?.to_date_local ? moment(state.data.to_date_local).format('YYYY-MM-DDTHH:mm') : ''}
                                                            onChange={handleToDateChange}
                                                            isInvalid={Boolean(validations?.to_date)}
                                                            size="sm"
                                                            error={validations?.to_date && (validations.to_date[0] || validations.to_date)}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </div>

                                    {(isDirty('view_type_id') || isDirty('price_mode_id') || isDirty('to_date', value => moment(value).toString()) || isDirty('to_date_local', value => moment(value).toString())) &&
                                        <Row className="mt-3" style={{ justifyContent: 'center' }}>
                                            <Col xs="auto">
                                                <SaveButton
                                                    loading={state.loadingChanges}
                                                    disabled={state.loading}
                                                    className="save"
                                                    onClick={handleApplyChanges}
                                                >
                                                    Приложи промените
                                                </SaveButton>
                                            </Col>
                                        </Row>
                                    }

                                    <div className="form-panel mt-3">
                                        <div className="head">
                                            Артикули
                                        </div>

                                        <div style={{ marginBottom: '20px' }}>
                                            <InputGroup size="sm">
                                                <Form.Control
                                                    type="search"
                                                    name="search"
                                                    value={state.rows.filter.search || ''}
                                                    size="sm"
                                                    onChange={e => handleSearch(e.target.name, e.target.value)}
                                                    placeholder="Търсене"
                                                />
                                            </InputGroup>
                                        </div>

                                        <div style={{ position: 'relative' }}>
                                            <Table className="valign-top xxl">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: '3%' }}>
                                                            #
                                                        </th>
                                                        <th style={{ width: '20%' }}>
                                                            Артикул
                                                        </th>
                                                        <th style={{ width: '10%' }}>
                                                            Единица
                                                        </th>
                                                        <th style={{ width: '10%' }}>
                                                            К-во в склада
                                                        </th>
                                                        <th style={{ width: '10%' }}>
                                                            Отчетено к-во
                                                        </th>
                                                        <th style={{ width: '10%' }}>
                                                            Разлика(к-во)
                                                        </th>
                                                        <th style={{ width: '10%' }}>
                                                            Разлика(ед)
                                                        </th>
                                                        <th style={{ width: '10%' }}>
                                                            Разлика(цена)
                                                        </th>
                                                        <th style={{ width: '10%' }}>
                                                            Причина
                                                        </th>
                                                        <th className="text-center" style={{ width: '5%' }}>

                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {renderRows()}
                                                    {/* <tr>
                                                    <td colSpan={4}>
                                                        Общо
                                                    </td>
                                                    <td className="text-right">
                                                        {getTotalQty().toFixed(4)}
                                                    </td>
                                                </tr> */}

                                                </tbody>
                                            </Table>

                                            <div style={{ display: 'flex', gap: '5px' }}>
                                                {state.data.view_type === 'selected' &&
                                                    <Button variant="outline-dark" className="mt-3" size="sm" onClick={handleAddRow}>
                                                        Нов ред
                                                    </Button>
                                                }
                                                <Button variant="outline-dark" className="mt-3" size="sm" onClick={handleImport} disabled={auth.getUser().permission('import_index') === false}>
                                                    <Upload /> Импорт
                                                </Button>
                                            </div>

                                            <div style={{ width: '100%' }}>
                                                <Pagination
                                                    className="mt-3"
                                                    page={state.rows.filter.page}
                                                    pages={state.rows.pages}
                                                    total={state.rows.total}
                                                    handlePage={handlePage}
                                                />
                                            </div>

                                            <div style={{
                                                position: 'absolute',
                                                top: 0,
                                                bottom: 0,
                                                left: 0,
                                                right: 0,
                                                background: 'rgba(255,255,255,.7)',
                                                display: state.rowsLoading ? 'flex' : 'none',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                zIndex: 3
                                            }}>
                                                {/* <Loader /> */}
                                            </div>
                                        </div>
                                    </div>

                                    <Row>
                                        <Col sm={12}>
                                            <div className="form-panel mt-3">
                                                <div className="head">
                                                    Бележки
                                                </div>
                                                <Form.Control
                                                    type="text"
                                                    as="textarea"
                                                    // placeholder="Бележки"
                                                    name="description"
                                                    value={state.data?.description || ''}
                                                    onChange={handleInputChange}
                                                    isInvalid={Boolean(validations?.description)}
                                                    rows={6}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {validations?.description && (validations.description[0] || validations.description)}
                                                </Form.Control.Feedback>
                                            </div>

                                        </Col>

                                    </Row>

                                    <div className="buttons mt-3">
                                        {state.data.allow_delete &&
                                            <Button
                                                variant="danger"
                                                onClick={handleDelete}
                                                style={{ marginRight: 'auto' }}>
                                                <XCircle /> Изтрий
                                            </Button>
                                        }
                                        <Button
                                            variant="secondary"
                                            onClick={e => navigate(-1)}
                                        >
                                            Отказ
                                        </Button>
                                        <SaveButton
                                            loading={state.loadingBase}
                                            disabled={state.loading}
                                            className="save"
                                            onClick={handleSave}
                                        >
                                            Запази данните
                                        </SaveButton>
                                        {state.data.allow_close &&
                                            <SaveButton
                                                loading={state.loadingClose}
                                                disabled={state.loading}
                                                className="save"
                                                onClick={handleSaveAndClose}
                                            >
                                                Запази и приключи
                                            </SaveButton>
                                        }

                                    </div>
                                    {/* </Container> */}

                                </Form>
                            </>
                        }

                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Index;