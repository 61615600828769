import { useEffect, useRef, useState } from 'react';
import Api from 'helpers/Api';
import { buildSearchParams, buildUrl, useQuery } from 'helpers/Url';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Alert, Button, Table, Form, FloatingLabel } from 'react-bootstrap';
import Layout from 'components/layout/Layout';
import Success from 'components/modals/Success';
import Filter from './partials/Filter';

//misc
import Item from './partials/Item';
import Loader from 'components/misc/Loader';
import SaveButton from 'components/misc/Button';
import NoDataFound from 'components/misc/NoDataFound';

let timeout;

function Index() {
    const successModalRed = useRef(null);
    const formRef = useRef(null);

    const location = useLocation();
    const history = useNavigate();
    const query = useQuery();

    const has_role = query.get('role_id') || null

    const [state, setState] = useState({
        loading: true,
        loadingButton: false,
        refresh: false,
        data: [],
        roles: [],
        checkedRole: {},
        checkedGroup: {},
        checkedPermission: {},
        loadData: false,
        setFilter: false,
        filter: {
            page: 1,
            role_id: null
        },
    });

    const [stores, setStores] = useState([]);
    const [roles, setRoles] = useState([]);
    const [permissionsStores, setPermissionsStores] = useState([]);

    useEffect(() => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: query.get('page') || 1,
                role_id: query.get('role_id') || ''
            },
            refresh: new Date().getTime()
        }))
    }, [location.search]);

    useEffect(() => {
        if (state.setFilter) {
            history('?' + buildSearchParams(query, state.filter));
        }

    }, [state.setFilter]);

    useEffect(() => {
        if (state.refresh) {
            loadData();
        }
    }, [state.refresh]);

    useEffect(() => {

        Api.get('permissions/get-roles')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setRoles(res.data)
                    if (res.data.length && !has_role) {
                        setState(prev => ({
                            ...prev,
                            filter: {
                                ...prev.filter,
                                role_id: res.data[0].id
                            }
                        }))
                        refresh()
                    }
                }
            })

        Api.get('permissions/stores')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setStores(res.data);
                }
            });

        // loadData()
    }, [])

    useEffect(() => {
        loadData()
        loadChecks()
    }, [state.refresh])

    const loadData = () => {
        loading(true);


        let url = `permissions/get`;

        Api.get(url)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data.items,
                    loadData: new Date().getTime()
                }));

                loading(false);
            });
    }

    const loadChecks = () => {
        if (!state.filter.role_id) return

        Api.get(`permissions/roles?role_id=${state.filter.role_id}`)
            .then(res => {
                if (Array.isArray(res.data)) {
                    setPermissionsStores(res.data);
                }
            })
    }

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }));
    }

    const refresh = () => {
        setState(prev => ({
            ...prev,
            refresh: new Date().getTime(),
        }));
    }

    const handleSave = e => {
        e.preventDefault()
        loadingButton(true)

        if (!state.filter.role_id) return

        if (state.loading) {
            return;
        }

        let form = formRef.current;

        let data = new FormData(form);

        if (state.filter.role_id) {
            data.append('role_id', state.filter.role_id)
        }

        Api.post('permissions/set-stores', data)
            .then(res => {
                let modal = successModalRed.current;
                modal.open();
                modal.onSuccess(() => {
                    loadingButton(false)
                })
            }).catch(error => {
                alert('ERROR')
            })
    }

    // Search
    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout);

        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [key]: val,
            },
        }));

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: 1
                },
                setFilter: new Date().getTime()
            }));
        }, delay);

        refresh()

    }

    const loadingButton = (loading) => {
        setState(prev => ({
            ...prev,
            loadingButton: Boolean(loading)
        }));
    }

    return (
        <>

            <Success
                ref={successModalRed}
                mainMessage="Данните бяха запазени успешно"
            />

            <Layout>
                <div className="container-fluid">

                    <div className="page-head">
                        <h1 className="display-6">
                            Права за търговски обекти
                        </h1>

                        <div className="buttons">

                            <SaveButton
                                loading={state.loadingButton}
                                className="save"
                                onClick={handleSave}
                            >
                                Запази данните
                            </SaveButton>

                        </div>
                    </div>

                    <br />

                    <div className="panel">

                        <Alert>
                            Настройка на права по търговски обекти, надписваща настройката на глобалните права. Ако искате да зададете едни и същи права за всички търговски обект, използвате "Права по роли"
                        </Alert>

                        <Filter
                            filter={state.filter}
                            handleSearch={handleSearch}
                            roles={roles}
                        />

                        {state.loading
                            ?
                            <Loader />
                            :
                            state.data.length === 0 ?
                                <NoDataFound />
                                :
                                <>
                                    <Form ref={formRef}>
                                        <Table striped responsive hover>
                                            <thead>
                                                <tr>
                                                    <th style={{ width: '60%' }}>Име</th>
                                                    {stores.map(r =>
                                                        <th key={r.id}>
                                                            <td>{r.translation?.name || r.name}</td>
                                                        </th>
                                                    )}

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {state.data.map((c, key) =>
                                                    <Item
                                                        key={key}
                                                        stores={stores}
                                                        modules={state.data}
                                                        checkedGroup={state.checkedGroup}
                                                        permissionsStores={permissionsStores}
                                                        checkedPermission={state.checkedPermission}
                                                        data={c}
                                                    />
                                                )}
                                            </tbody>
                                        </Table>

                                        <div className="buttons mt-3 ">
                                            <SaveButton
                                                loading={state.loadingButton}
                                                className="save"
                                                onClick={handleSave}
                                            >
                                                Запази данните
                                            </SaveButton>
                                        </div>
                                    </Form>
                                </>
                        }
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Index;
