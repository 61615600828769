import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import moment from 'moment'
import { Modal, Button, Tabs, Tab, FloatingLabel, Form, Row, Col, Nav, Alert } from 'react-bootstrap';
import { TrashFill } from 'react-bootstrap-icons';

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//providers
import { useLanguageContext } from 'providers/Language'

//misc
import Autocomplete from 'components/misc/Autocomplete';
import SaveButton from 'components/misc/Button'
import HelpInfo from 'components/misc/Info'
import Info from 'components/modals/Info'

function AddOrEdit(props, ref) {

    const services = {
        speedy: 'danielstandard\\store\\services\\speditors\\SpeedyRestService',
        leoexpress: 'danielstandard\\store\\services\\speditors\\LeoExpresService'
    }

    const defaultData = {
        service_class: services.speedy,
        is_active: 0,
        api_url: 'https://api.speedy.bg/v1/',
        username: '',
        password: '',
        has_test: 0,
        service_payer: 0,
        paper_size: 0,
        clients: []
    }

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        edit: false,
        data: defaultData,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null,
        mainTabId: 'main',

    });

    const [clients, setClients] = useNestedState({
        clients: [],
        connection: true,
    })

    const [validations, setValidations] = useValidation();

    const validationModalRef = useRef(null);

    useImperativeHandle(ref, () => ({
        add: () => {
            handleShow();
        },
        edit: (id) => {
            edit(id);
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        }
    }));

    useEffect(() => {
        loadData();
        loadSpeditor();
    }, [state.edit]);

    const loadSpeditor = () => {
        if (!state.id) {
            return;
        }

        Api.get('speditors/clients?id=' + state.id)
            .then(res => {
                let clients = []

                if (res.data) {
                    if (res.data.clients) {
                        if (Array.isArray(res.data.clients)) {
                            res.data.clients.map((c, index) => {
                                clients[index] = {
                                    id: c.clientId,
                                    name: c.clientName + ' / ' + c.clientId,
                                }
                            })

                        }
                    }
                }

                setClients(prev => ({
                    ...prev,
                    clients,
                    connection: true
                }))
            })
            .catch(() => {
                setClients(prev => ({
                    ...prev,
                    connection: false
                }))
            })
    }

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const handleShow = () => {
        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
            mainTabId: 'main',
        }));

        show();
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
            mainTabId: 'main',
        }));

        setValidations(null);

        hide();
    }

    const edit = id => {
        setState(prev => ({
            ...prev,
            id: id,
            edit: new Date().getTime()
        }));

        show();
    }

    const loadData = () => {
        if (!state.id) {
            return;
        }

        Api.get('speditors/show?id=' + state.id)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    mainTabId: 'main',
                    data: res.data
                }))
            });
    }

    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = state.id ? 'speditors/update' : 'speditors/add';

        let data = new FormData(e.target);

        if (state.id) {
            data.append('id', state.id)
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (typeof state.onSuccess === 'function') {
                state.onSuccess(res.data)
            }
            hide()
        }).catch(error => {
            const _err = error.response;
            if (_err && _err.status && _err.status === 422) {
                hide(() => {
                    let modal = validationModalRef.current;

                    if (modal) {
                        modal.open();

                        modal.onClose(() => {
                            setValidations(_err.data.errors)
                            show();
                        });
                    }
                });
            }

        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }));
        });
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleServiceClassChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        const url = value === services.speedy ? 'https://api.speedy.bg/v1/' : 'websvc.leoexpres.bg/'

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: value,
                api_url: url,
                username: defaultData.username,
                password: defaultData.password,
                has_test: defaultData.has_test,
                service_payer: defaultData.service_payer,
                paper_size: defaultData.paper_size,
            }
        }))
    }

    const handleCheckboxChange = e => {
        const name = e.target.name;
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: prev.data[name] ? false : true
            }
        }));
    }

    const setMainTab = key => {
        setState(prev => ({
            ...prev,
            mainTabId: key
        }));
    }

    const removeClient = (index) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                clients: prev.data.clients.filter((d, i) => Number(i) !== Number(index))
            }
        }));
    }

    const addClient = () => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                clients: prev.data.clients.concat({
                    id: null
                })
            }
        }));
    }

    const handleClient = (data, index) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                clients: Object.values({
                    ...prev.data.clients,
                    [index]: {
                        ...prev.data.clients[index],
                        client_id: data.id,
                        name: data.name,
                    }
                })
            }
        }))
    }

    const handleStore = (data, index) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                clients: Object.values({
                    ...prev.data.clients,
                    [index]: {
                        ...prev.data.clients[index],
                        store_id: data.id,
                    }
                })
            }
        }))
    }


    return (
        <>
            <Info
                ref={validationModalRef}
                mainMessage="Моля, попълнете всички задължителни полета, означени с червен цвят!"
            />

            <Modal size="lg" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <form onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Спедитор
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {state.id && !clients.connection ?
                            <Alert variant="danger">
                                Не е установена връзка с {state.data.service_class === services.speedy ? 'Speedy' : 'Лео Експрес'}
                            </Alert> : ''}
                        <Tab.Container activeKey={state.mainTabId} onSelect={key => setMainTab(key)}>
                            <Row>
                                <Col xl={3} sm={3}>
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link eventKey="main">Основни данни</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="properties">Настройки</Nav.Link>
                                        </Nav.Item>
                                        {state.id ?
                                            <Nav.Item>
                                                <Nav.Link eventKey="stores">
                                                    Спедитори по ТО
                                                </Nav.Link>
                                            </Nav.Item>
                                            : ''}
                                    </Nav>
                                </Col>
                                <Col xl={9} sm={9}>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="main">
                                            <Row className="mb-3">
                                                <Col>
                                                    <FloatingLabel label="Име*">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Име*"
                                                            name="name"
                                                            value={state.data.name || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.name)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.name && (validations.name[0] || validations.name)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>

                                                <Col>
                                                    <FloatingLabel label="Клас*">
                                                        <Form.Select
                                                            name="service_class"
                                                            value={state.data.service_class || ''}
                                                            onChange={handleServiceClassChange}
                                                            isInvalid={Boolean(validations && validations.service_class)}
                                                        >
                                                            <option value={services.speedy}>Speedy</option>
                                                            <option value={services.leoexpress}>LeoExpres</option>
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.service_class && (validations.service_class[0] || validations.service_class)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>

                                            <Row className="mb-3">
                                                <Col>
                                                    <FloatingLabel label="Доставя до адрес">
                                                        <Form.Select
                                                            name="has_delivery_to_address"
                                                            value={state.data.has_delivery_to_address || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.has_delivery_to_address)}
                                                        >
                                                            <option value={0}>Не</option>
                                                            <option value={1}>Да</option>
                                                            <option value={2}>Да, с видимост в публични модули</option>
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.has_delivery_to_address && (validations.has_delivery_to_address[0] || validations.has_delivery_to_address)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel label="Доставя до офис">
                                                        <Form.Select
                                                            name="has_delivery_to_office"
                                                            value={state.data.has_delivery_to_office || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.has_delivery_to_office)}
                                                        >
                                                            <option value={0}>Не</option>
                                                            <option value={1}>Да</option>
                                                            <option value={2}>Да, с видимост в публични модули</option>
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.has_delivery_to_office && (validations.has_delivery_to_office[0] || validations.has_delivery_to_office)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>

                                            <Col>
                                                <Form.Check
                                                    type="switch"
                                                    name={`is_active`}
                                                    label="Активен"
                                                    checked={Number(state.data.is_active) === 1}
                                                    onChange={handleCheckboxChange}
                                                />
                                            </Col>

                                        </Tab.Pane>
                                        <Tab.Pane eventKey="properties">
                                            <Col className="mb-3">
                                                <Col>
                                                    <FloatingLabel label="API адрес">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="API адрес"
                                                            name="api_url"
                                                            value={state.data.api_url || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.api_url)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.api_url && (validations.api_url[0] || validations.api_url)}
                                                        </Form.Control.Feedback>
                                                        <HelpInfo placement="top">Адрес за комуникация {state.data.service_class === services.speedy ? 'със Спиди' : 'с ЛЕО Експрес'}</HelpInfo>
                                                    </FloatingLabel>
                                                </Col>
                                            </Col>

                                            <Col className="mb-3">
                                                <FloatingLabel label="Потребител">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Потребител"
                                                        name="username"
                                                        autoComplete='off'
                                                        value={state.data.username || ''}
                                                        onChange={handleInputChange}
                                                        isInvalid={Boolean(validations && validations.username)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {validations && validations.username && (validations.username[0] || validations.username)}
                                                    </Form.Control.Feedback>
                                                    <HelpInfo placement="top">За идентификация пред {state.data.service_class === services.speedy ? 'Спиди' : 'ЛЕО Експрес'}</HelpInfo>
                                                </FloatingLabel>
                                            </Col>

                                            <Row className="mb-3">
                                                <Col>
                                                    <FloatingLabel label="Парола">
                                                        <Form.Control
                                                            type="password"
                                                            placeholder="Парола"
                                                            name="password"
                                                            autoComplete='off'
                                                            value={state.data.password || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.password)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.password && (validations.password[0] || validations.password)}
                                                        </Form.Control.Feedback>
                                                        <HelpInfo placement="top">За идентификация пред {state.data.service_class === services.speedy ? 'Спиди' : 'ЛЕО Експрес'}</HelpInfo>
                                                    </FloatingLabel>
                                                </Col>

                                                <Col>
                                                    <FloatingLabel label="Повтори парола">
                                                        <Form.Control
                                                            type="password"
                                                            placeholder="Повтори парола"
                                                            name="password_confirmation"
                                                            autoComplete='off'
                                                            // value={state.data.password || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.password)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.password && (validations.password[0] || validations.password)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>

                                            <Col className='mb-3'>
                                                <Form.Check
                                                    type="switch"
                                                    name={`has_test`}
                                                    label="Опция тест"
                                                    checked={Number(state.data.has_test) === 1}
                                                    onChange={handleCheckboxChange}
                                                />
                                            </Col>

                                            {state.data.service_class === services.speedy ? <Row>
                                                <Col>
                                                    <FloatingLabel label="Връщане*">
                                                        <Form.Select
                                                            name="service_payer"
                                                            value={state.data.service_payer || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.service_payer)}
                                                        >
                                                            <option value={0}>Подател</option>
                                                            <option value={1}>Получател</option>
                                                            <option value={2}>Подател фирма</option>
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.service_payer && (validations.service_payer[0] || validations.service_payer)}
                                                        </Form.Control.Feedback>
                                                        <HelpInfo placement="top">Връщане на пратката за сметка на</HelpInfo>
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel label="Размер*">
                                                        <Form.Select
                                                            name="paper_size"
                                                            value={state.data.paper_size || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.paper_size)}
                                                        >
                                                            <option value={0}>Нормален (А4)</option>
                                                            <option value={1}>Среден</option>
                                                            <option value={2}>Малък (А6)</option>
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.paper_size && (validations.paper_size[0] || validations.paper_size)}
                                                        </Form.Control.Feedback>
                                                        <HelpInfo placement="top">Размер на получения етикет</HelpInfo>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row> : ''}

                                            {state.data.service_class === services.leoexpress ?
                                                <>
                                                    <Row className="mb-3">
                                                        <Col>
                                                            <FloatingLabel label="Връщане*">
                                                                <Form.Select
                                                                    name="service_payer"
                                                                    value={state.data.service_payer || ''}
                                                                    onChange={handleInputChange}
                                                                    isInvalid={Boolean(validations && validations.service_payer)}
                                                                >
                                                                    <option value={0}>Подател</option>
                                                                    <option value={1}>Получател</option>
                                                                </Form.Select>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {validations && validations.service_payer && (validations.service_payer[0] || validations.service_payer)}
                                                                </Form.Control.Feedback>
                                                                <HelpInfo placement="top">Връщане на пратката за сметка на</HelpInfo>
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col>
                                                            <FloatingLabel label="Клиентски код">
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Клиентски код"
                                                                    name="client_num"
                                                                    value={state.data.client_num || ''}
                                                                    onChange={handleInputChange}
                                                                    isInvalid={Boolean(validations && validations.client_num)}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {validations && validations.client_num && (validations.client_num[0] || validations.client_num)}
                                                                </Form.Control.Feedback>
                                                            </FloatingLabel>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col>
                                                            <FloatingLabel label="От адрес до адрес">
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="От адрес до адрес"
                                                                    name="address_to_address"
                                                                    value={state.data.address_to_address || ''}
                                                                    onChange={handleInputChange}
                                                                    isInvalid={Boolean(validations && validations.address_to_address)}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {validations && validations.address_to_address && (validations.address_to_address[0] || validations.address_to_address)}
                                                                </Form.Control.Feedback>
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col>
                                                            <FloatingLabel label="От адрес до офис">
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="От адрес до офис"
                                                                    name="address_to_office"
                                                                    value={state.data.address_to_office || ''}
                                                                    onChange={handleInputChange}
                                                                    isInvalid={Boolean(validations && validations.address_to_office)}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {validations && validations.address_to_office && (validations.address_to_office[0] || validations.address_to_office)}
                                                                </Form.Control.Feedback>
                                                            </FloatingLabel>
                                                        </Col>
                                                    </Row>
                                                </>
                                                : ''}

                                        </Tab.Pane>
                                        <Tab.Pane eventKey="stores">

                                            {!state.data.clients.length ?
                                                <Alert>
                                                    Няма информация за спедитори по търговски обекти
                                                </Alert>
                                                : ''}

                                            {state.data.clients?.map((c, index) =>
                                                <Row key={`client-${index}`} className="mb-3">
                                                    <Col>
                                                        <Autocomplete
                                                            url="autocomplete/stores"
                                                            params={{
                                                                all: 1
                                                            }}
                                                            inputPlaceholder="Търговски обект"
                                                            inputIdName={`store_id[]`}
                                                            inputIdValue={state.data.clients[index].store_id || ''}
                                                            onChange={data => handleStore(data, index)}
                                                            renderText={data => data?.translation?.name || data.name}
                                                            error={Boolean(validations?.store_id && validations.store_id[index])}
                                                            helperText={validations?.store_id && validations.store_id[index]}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Autocomplete
                                                            inputPlaceholder="Клиентски номер"
                                                            renderText={data => data?.name}
                                                            data={clients.clients}
                                                            inputIdName={`client_id[]`}
                                                            inputIdValue={state.data.clients[index].client_id || ''}
                                                            error={Boolean(validations?.client_id && validations.client_id[index])}
                                                            helperText={validations?.client_id && validations.client_id[index]}
                                                            onChange={data => handleClient(data, index)}
                                                            filter
                                                        />
                                                    </Col>
                                                    <Col className="mb-3" style={{ display: 'flex', alignItems: 'center' }} xs="auto">
                                                        <Button variant="danger" size="sm" onClick={e => removeClient(index)}>
                                                            <TrashFill />
                                                        </Button>
                                                    </Col>

                                                    <Form.Control
                                                        key={`client-hidden-${c.client_id}-${index}`}
                                                        type="hidden"
                                                        name={`store_client_id[]`}
                                                        value={state.data.clients[index].id || ''}
                                                    />

                                                </Row>)}
                                            <Button variant="outline-dark" size="sm" onClick={addClient}>
                                                Добави търговски обект
                                            </Button>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            className="save"
                        />
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default forwardRef(AddOrEdit);
