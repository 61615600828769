import { forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from 'react'

//plugins
import moment from 'moment'
import { Modal, Button, Tabs, Tab, FloatingLabel, Form, Row, Col, Alert } from 'react-bootstrap';

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//providers
import { useLanguageContext } from 'providers/Language'

//misc
import SaveButton from 'components/misc/Button'
import Info from 'components/modals/Info';

// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from 'assets/js/ckeditor/src/ckeditor';
// import ClassicEditor from 'assets/js/ckeditor/build/ckeditor';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';

import { TYPES } from 'constants/email-markers';

import IFrame from 'components/partials/Iframe';
import Code from 'components/misc/Code';
// import Editor from 'components/misc/Editor';

const ClassicEditor = window.ClassicEditor;

let timeout;

function AddOrEdit(props, ref) {

    const langs = useLanguageContext();

    const defaultData = {
        langs: {},
        type_id: TYPES[0].id
    }

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        edit: false,
        data: defaultData,
        tabId: langs.data[0]?.id,
        contentTabId: 'text',
        refreshPreview: false,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null
    });

    const [markers, setMarkers] = useState([]);
    const [validations, setValidations] = useValidation();

    const validationModalRef = useRef(null);

    useImperativeHandle(ref, () => ({
        add: () => {
            handleShow();
        },
        edit: (id) => {
            edit(id);
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        }
    }));

    useEffect(() => {
        Api.get(`email_templates/types`)
            .then(res => {
                if (Array.isArray(res.data)) {
                    setMarkers(res.data);
                }
            });
    }, []);

    useEffect(() => {
        loadData();
    }, [state.edit]);

    useEffect(() => {
        clearTimeout(timeout);

        timeout = setTimeout(() => {
            loadPreview();
        }, 1300);

    }, [state.refreshPreview]);

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const handleShow = () => {
        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
            tabId: langs.data[0]?.id,
            contentTabId: 'text',
            preview: null,
        }));

        show();
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
            tabId: langs.data[0]?.id,
            contentTabId: 'text',
            preview: null,
        }));

        setValidations(null);

        hide();
    }

    const edit = id => {
        setState(prev => ({
            ...prev,
            id: id,
            edit: new Date().getTime()
        }));

        show();
    }

    const loadData = () => {
        if (!state.id) {
            return;
        }

        Api.get('email_templates/show?id=' + state.id)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data
                }))

                refreshPreview();
            });
    }

    const loadPreview = () => {
        if (!state.show) {
            return;
        }

        Api.post('/email_templates/preview', {
            type_id: state.data.type_id,
            langs: state.data.langs,
        }).then(res => {
            setState(prev => ({
                ...prev,
                preview: res.data.data
            }))
        });
    }

    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = state.id ? 'email_templates/update' : 'email_templates/add';

        let data = new FormData(e.target);

        if (state.id) {
            data.append('id', state.id)
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (typeof state.onSuccess === 'function') {
                state.onSuccess(res.data)
            }

            hide()
        }).catch(error => {
            const _err = error.response;
            if (_err && _err.status && _err.status === 422) {
                hide(() => {
                    let modal = validationModalRef.current;

                    if (modal) {
                        modal.open();

                        modal.onClose(() => {
                            setValidations(_err.data.errors)
                            show();
                        });
                    }
                });
            }

        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }));
        });
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')

        refreshPreview();
    }

    const handleEditorChange = (data, langId) => {
        const name = `langs[${langId}][message]`

        setState(name, data, 'data');

        refreshPreview();
    }

    const refreshPreview = () => {
        setState(prev => ({
            ...prev,
            refreshPreview: new Date()
        }));
    }

    const handleTabChange = id => {
        setState(prev => ({
            ...prev,
            tabId: Number(id)
        }));
    }

    const handleCheckboxChange = e => {
        const name = e.target.name;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: prev.data[name] ? false : true
            }
        }));
    }

    const handleTypeChange = e => {
        const name = e.target.name
        const value = e.target.value

        // const type = TYPES.filter(t => t.id == value)

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: value,
            }
        }))
    }

    const handleMarker = (marker) => {
        const str = state.data.langs[state.tabId]?.message || '';

        // let updateData = str.substring(0, str.length - 4) + ` ${marker} ` + str.substring(str.length - 4, str.length);
        let updateData = `${str} ${marker}`

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                langs: {
                    ...prev.data.langs,
                    [state.tabId]: {
                        ...prev.data.langs[state.tabId],
                        message: updateData
                    }
                }
            }
        }));

        refreshPreview();
    }

    const handleContentTabChange = id => {
        setState(prev => ({
            ...prev,
            contentTabId: id
        }));
    }

    return (
        <>
            <Info
                ref={validationModalRef}
                mainMessage="Моля, попълнете всички задължителни полета, означени с червен цвят!"
            />

            <Modal enforceFocus={false} size="lg" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <form onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Шаблон
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                <FloatingLabel label="Тип">
                                    <Form.Select
                                        name="type_id"
                                        value={state.data.type_id || ''}
                                        onChange={handleTypeChange}
                                    >
                                        {/* <option value=""></option> */}
                                        {TYPES.map(c =>
                                            <option key={c.id} value={c.id}>{c.name}</option>
                                        )}
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                            <Col className="mb-3">
                                <FloatingLabel label="Име*">
                                    <Form.Control
                                        type="text"
                                        placeholder="Име*"
                                        min={1}
                                        name="name"
                                        value={state.data.name || ''}
                                        onChange={handleInputChange}
                                        isInvalid={Boolean(validations && validations.name)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {validations && validations.name && (validations.name[0] || validations.name)}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>

                        <Col className="mb-3">
                            <Form.Check
                                type="switch"
                                name="default"
                                label="По подразбиране"
                                className="mb-3 mt-3"
                                checked={Number(state.data?.default) === 1}
                                onChange={handleCheckboxChange}
                            />
                        </Col>

                        <Tabs
                            activeKey={state.tabId}
                            onSelect={handleTabChange}
                            className="mb-3"
                        >
                            {langs.data.map(lang =>
                                <Tab key={lang.id} eventKey={lang.id} title={lang.name} tabClassName={validations && validations.langs && validations.langs[lang.id] ? 'validate' : ''} />
                            )}
                        </Tabs>
                        {langs.data.map(lang =>
                            <div key={lang.id} className="mb-3" style={{ display: state.tabId === lang.id ? 'block' : 'none' }}>
                                <Col>
                                    <FloatingLabel label="Тема*" className="mb-3">
                                        <Form.Control
                                            type="text"
                                            name={`langs[${lang.id}][title]`}
                                            placeholder="Тема*"
                                            value={state.data.langs[lang.id]?.title || ''}
                                            onChange={handleInputChange}
                                            isInvalid={Boolean(validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].title)}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].title && (validations.langs[lang.id].title[0] || validations.langs[lang.id].title)}
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>

                                <Col>
                                    <Tabs
                                        activeKey={state.contentTabId}
                                        onSelect={handleContentTabChange}
                                        className="mb-3"
                                    >
                                        <Tab eventKey="text" title="Съдържание" tabClassName={validations && validations.message ? 'validate' : ''} />
                                        <Tab eventKey="preview" title="Визуализация" tabClassName={''} />

                                    </Tabs>
                                    <div className="mb-3" style={{ display: state.contentTabId === 'text' ? 'block' : 'none' }}>
                                        <FloatingLabel label="Съдържание*" hidden>
                                            <Form.Control
                                                as="textarea"
                                                name={`langs[${lang.id}][message]`}
                                                placeholder="Съдържание*"
                                                value={state.data.langs[lang.id]?.message || ''}
                                                onChange={handleInputChange}
                                                style={{
                                                    height: '300px'
                                                }}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].message && (validations.langs[lang.id].message[0] || validations.langs[lang.id].message)}
                                            </Form.Control.Feedback>
                                        </FloatingLabel>

                                        {/* <Editor
                                            id={`editorjs-` + lang.id}
                                            onChange={data => handleEditorChange(data, lang.id)}
                                        /> */}

                                        <Code
                                            code={state.data.langs[lang.id]?.message}
                                            onChange={data => handleEditorChange(data, lang.id)}
                                        />

                                        <br />

                                        <p><strong>Етикети, които се заместват:</strong></p>
                                        {markers.map(m =>
                                            m.type_id == null ?
                                                <>
                                                    <p key={m.marker} className="markers"><span onClick={() => handleMarker(m.marker)}>{m.marker}</span> - {m.marker_note}</p>
                                                </>
                                                : ''
                                        )}
                                        {state.data.type_id != 999 ? <p style={{ marginTop: '1rem' }}><strong>Маркери към този тип:</strong></p> : ''}
                                        {markers.map(m =>
                                            state.data.type_id == m.type_id ?
                                                <>
                                                    <p key={m.marker} className="markers"><span onClick={() => handleMarker(m.marker)}>{m.marker}</span> - {m.marker_note}</p>
                                                </>
                                                : ''
                                        )}
                                    </div>

                                    <div className="mb-3" style={{ display: state.contentTabId === 'preview' ? 'block' : 'none' }}>
                                        <Alert>
                                            Данните са примерни!
                                        </Alert>

                                        <IFrame style={{ height: '400px' }}>
                                            <div dangerouslySetInnerHTML={{ __html: state.preview && state.preview[lang.id] && state.preview[lang.id].message }} />
                                        </IFrame>
                                    </div>

                                </Col>


                                {/* <Col>
                                    <Form.Label>Съобщение*</Form.Label>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        // config={
                                        //     {
                                        //         extraAllowedContent: 'data'
                                        //     }
                                        // }
                                        data={state.data.langs[lang.id]?.message || ''}
                                        onChange={(event, editor) => {
                                            const name = `langs[${lang.id}][message]`
                                            const value = editor.getData();
                                            setState(name, value, 'data')
                                        }}
                                    />
                                    <div className='validate'>
                                        {validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].message && (validations.langs[lang.id].message[0] || validations.langs[lang.id].message)}
                                    </div>
                                </Col> */}

                            </div>
                        )}

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            className="save"
                        />
                    </Modal.Footer>
                </form>
            </Modal >
        </>
    )
}

export default forwardRef(AddOrEdit);
