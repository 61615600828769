import { ReactCodeJar } from "react-codejar";

import hljs from 'highlight.js';

import 'highlight.js/styles/github.css';

function Code(props) {
    const code = props.code || '';
    const onChange = props.onChange || (() => { });

    return (
        <div style={{
            border: '1px solid #ced4da',
            borderRadius: '6px',
            fontFamily: "'Source Code Pro', monospace",
            fontSize: '14px',
            fontWeight: '400',
            // height: '340px',
            letterSpacing: 'normal',
            lineHeight: '20px',
            padding: '10px',
            tabSize: 4
        }}
        >
            <ReactCodeJar
                code={code} // Initial code value
                onUpdate={onChange} // Update the text
                options={{
                    indentOn: /[(\[]$/,
                }}
                lineNumbers={false} // Show line numbers
                highlight={editor => {
                    editor.innerHTML = hljs.highlight(editor.textContent, { language: 'html' }).value;
                }}
            />
        </div>
    )
}

export default Code;