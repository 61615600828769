import { useState, useEffect, useRef } from 'react'
import { Table, InputGroup, Form } from 'react-bootstrap';
import Api from 'helpers/Api';
import Loader from 'components/misc/Loader';
import moment from 'moment';
import TableCategoryRow from './TableCategoryRow';
import TableRow from './TableRow';
import Pagination from 'components/misc/Pagination';
import Alert from 'components/misc/Alert';
import { useAppContext } from 'providers/App';
import { useAuthDataContext } from 'providers/Auth';
import { buildUrl } from 'helpers/Url';
import Rows from './Rows';

let timeout;

function Index(props) {

    const { show, hide } = props;

    const app = useAppContext();
    const auth = useAuthDataContext();

    const { handleShowArticle } = props;

    const defaultData = {
        rows: []
    };

    const [state, setState] = useState({
        data: defaultData,
        loading: false,
        dataLoading: false,
        rowsKey: null,
        onEntering: null,
        onExiting: null
    });

    useEffect(() => {
        loadFullData();
    }, [props.id]);

    useEffect(() => {
        if (!state.data.id) {
            return;
        }

        // при тест с laravel dev server
        // заявката блокира всички останали, тъй като
        // уеб сървъра може да обработва само 1 заявка
        let base = process.env.REACT_APP_URL;
        // let base = 'http://localhost:8001';

        let url = buildUrl(base + '/api/revisions/calculation-progress', {
            id: state.data.id,
            token: auth.getToken()
        });

        const eventSource = new EventSource(url);

        eventSource.addEventListener('ping', event => {
            let data = JSON.parse(event.data);

            // console.log(data);

            let calculating = state.data.calculating;

            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    calculating: data.calculating,
                    calculationPercent: data.percent || 0,
                },
            }));

            if (data.ready && data.percent === 100) {
                eventSource.close();

                if (calculating) {
                    setState(prev => ({
                        ...prev,
                        rowsKey: new Date()
                    }));
                }
            }

        });

        eventSource.onerror = err => {
            app.showError('EventSource failed');
        }

        return () => {
            eventSource.close();
        }

    }, [state.data.id]);

    const loadFullData = () => {
        loading(true);

        let p1 = loadData();
        // let p2 = loadRows();

        Promise.all([p1]).then(() => {
            loading(false);
        });
    }

    const loadData = async () => {
        let url = 'revisions/show';

        return Api.get(url, {
            params: {
                id: props.id
            }
        }).then(res => {
            setDataResponse(res.data);
        }).catch(err => {
            console.log(err);
        })
    }

    const setDataResponse = data => {
        setState(prev => ({
            ...prev,
            data: data,
        }));
    }

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }));
    }

    return (

        state.loading
            ?
            <Loader />
            :
            <>
                <Table size="sm" bordered>
                    <tbody>
                        <tr>
                            <td className="th">
                                ID
                            </td>
                            <td>
                                {state.data.id}
                            </td>
                            <td className="th">
                                Създал
                            </td>
                            <td>
                                {state.data?.creator?.username}
                            </td>
                            <td className="th">
                                Създадено на
                            </td>
                            <td>
                                {state.data.created_at ? moment(state.data.created_at).utc().format('DD.MM.YYYY HH:mm') : ''}
                            </td>
                        </tr>
                        <tr>
                            <td className="th">
                                Статус
                            </td>
                            <td>
                                {state.data?.status?.translation?.name || state.data?.status?.name}
                            </td>
                            <td className="th">
                                Приключил
                            </td>
                            <td>
                                {state.data?.acceptor?.name}
                            </td>
                            <td className="th">
                                Дата на промяна на статуса
                            </td>
                            <td>
                                {state.data.status_updated_at ? moment(state.data.status_updated_at).format('DD.MM.YYYY HH:mm') : ''}
                            </td>

                        </tr>
                        <tr>
                            <td className="th">
                                Търговски обект
                            </td>
                            <td>
                                {state.data?.depot?.store?.translation?.name || state.data?.depot?.store?.name}
                            </td>
                            <td className="th">
                                Склад
                            </td>
                            <td>
                                {state.data?.depot?.name}
                            </td>
                            <td className="th">
                                Наличност към дата
                            </td>
                            <td>
                                {state.data.to_date ? moment(state.data.to_date).utc().format('DD.MM.YYYY HH:mm') : ''}
                            </td>
                        </tr>
                        <tr>
                            <td className="th">
                                Вид
                            </td>
                            <td>
                                {state.data?.view_type === 'category'
                                    ? 'Всички артикули по категории'
                                    : state.data?.view_type === 'selected'
                                        ? 'Избрани артикули'
                                        : state.data?.view_type === 'in_stock'
                                            ? 'Всички артикули в наличност'
                                            : state.data?.view_type === 'all'
                                                ? 'Всички артикули'
                                                : ''
                                }
                            </td>
                            <td className="th">
                                Калкулация по
                            </td>
                            <td>
                                {state.data?.price_mode === 'sell'
                                    ? 'Продажни цени'
                                    : state.data?.price_mode === 'delivery'
                                        ? 'Доставни цени'
                                        : ''
                                }
                            </td>
                        </tr>
                        <tr>
                            <td className="th">
                                Бележки
                            </td>
                            <td colSpan={5}>
                                {state.data.description}
                            </td>
                        </tr>
                    </tbody>
                </Table>

                {state.data.calculating === 1 &&
                    <Alert variant="warning" className="mb-3 mt-3">
                        Внимание! Наличността на артикулите в тази ревизия все още не е напълно изчислена. ({state.data.calculationPercent || 0}%)
                    </Alert>
                }

                <Rows
                    key={state.rowsKey}
                    id={state.data.id}
                    show={show}
                    hide={hide}
                />
            </>
    )
}

export default Index;