import { useNavigate } from "react-router-dom"
import { FloatingLabel, Form } from 'react-bootstrap'

//providers
// import { useAuthDataContext } from "providers/Auth"

//helpers
import { useQuery } from 'helpers/Url'

let timeout;

function Filter(props) {
    const history = useNavigate()
    const query = useQuery()

    // const auth = useAuthDataContext();

    const handleSearch = e => {
        const name = e.target.name
        const value = e.target.value

        clearTimeout(timeout);

        timeout = setTimeout(() => {
            props.handleSearch(name, value)
        }, 300);
    }


    return (
        <div className="row page-filter">
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <FloatingLabel label="Търсене">
                    <Form.Control
                        type="text"
                        placeholder="Търсене"
                        name="search"
                        defaultValue={props.filter.search || ''}
                        onChange={handleSearch}
                    />
                </FloatingLabel>
            </div>
        </div>
    )
}

export default Filter;
