import { Button, Card } from 'react-bootstrap';
import Invoices from './partials/Invoices';
import Documents from './partials/Documents';

function Index(props) {

    return (
        <>
            <Button variant="outline-dark" onClick={e => props.linkDocument()}>
                Прикачи документ
            </Button>
            <Button variant="outline-dark" style={{ marginLeft: '5px' }} onClick={e => props.addDocument()}>
                Нов документ
            </Button>
            <Card className="mt-3 mb-3">
                <Card.Header>
                    Финансови документи
                </Card.Header>
                <Card.Body>
                    <Invoices
                        id={props.id}
                        showInvoice={props.showInvoice}
                        deleteInvoice={props.deleteInvoice}
                    />
                </Card.Body>
            </Card>

            <Card className="">
                <Card.Header>
                    Други документи
                </Card.Header>
                <Card.Body>
                    <Documents
                        id={props.id}
                        showDocument={props.showDocument}
                        deleteDocument={props.deleteDocument}
                    />
                </Card.Body>
            </Card>
        </>
    )
}

export default Index;