import Refs from 'Refs';
import CurrentPrices from './CurrentPrices';
import PriceRules from './PriceRules';

function Prices(props) {

    const { show, hide } = props;

    const showPriceRule = (id) => {
        let modal = Refs.getInstance().getRef('priceRule');

        if (!modal) {
            return;
        }

        hide(() => {
            modal.edit(id);
        });

        modal.onClose(() => {
            show();
        });
    }

    const showClient = (id) => {
        let modal = Refs.getInstance().getRef('client');

        if (!modal) {
            return;
        }

        hide(() => {
            modal.open(id);
        });

        modal.onClose(() => {
            show();
        });
    }

    return (
        <>
            <CurrentPrices
                id={props.id}
                showPriceRule={showPriceRule}
                showClient={showClient}
            />
            <PriceRules
                id={props.id}
                showPriceRule={showPriceRule}
            />
        </>
    )
}

export default Prices;