import { forwardRef, useImperativeHandle } from "react";
import { Form } from "react-bootstrap";

function Barcode(props, ref) {

    useImperativeHandle(ref, () => ({
        getName: getName,
        getType: getType,
        getTitle: getTitle,
        hasSettings: hasSettings,
        getSettings: getSettings,
        getDefaultOptions: ({ createOrUpdate }) => {
            if (createOrUpdate === 'create') {
                return { action: 'abort' };
            } else if (createOrUpdate === 'update') {
                return { action: 'abort' };
            }
        }
    }));

    const getName = () => {
        return 'Баркод';
    }

    const getType = () => {
        return 'barcode';
    }

    const getTitle = () => {
        return null;
    }

    const hasSettings = () => true;

    const getSettings = ({ state, setConfig, createOrUpdate }) => {
        return (
            <div>
                <label>
                    Действия при дублиране:
                </label>
                <div>
                    <Form.Check
                        id={'cb-skip'}
                        type="radio"
                        label="Не записвай новия баркод"
                        checked={state.options?.action === 'skip'}
                        onChange={e => setConfig('action', 'skip')}
                    />
                    <Form.Check
                        id={'cb-update'}
                        type="radio"
                        label="Премахни стария баркод"
                        checked={state.options?.action === 'update'}
                        onChange={e => setConfig('action', 'update')}
                    />
                    <Form.Check
                        id={'cb-new'}
                        type="radio"
                        label="Генерирай нов"
                        checked={state.options?.action === 'new'}
                        onChange={e => setConfig('action', 'new')}
                    />
                    <Form.Check
                        id={'cb-abort'}
                        type="radio"
                        label="Прекрати импорта"
                        checked={state.options?.action === 'abort'}
                        onChange={e => setConfig('action', 'abort')}
                    />
                </div>
            </div>
        )
    }

    return getName();
}

export default forwardRef(Barcode)