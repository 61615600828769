import { useNavigate } from "react-router-dom"
import moment from "moment"
import { FloatingLabel, Form } from 'react-bootstrap'

//providers
// import { useAuthDataContext } from "providers/Auth"

//helpers
import { useQuery } from 'helpers/Url'
import Autocomplete from "components/misc/Autocomplete"
import DatePicker from "components/partials/filter/DatePicker"

function Filter(props) {
    const history = useNavigate()
    const query = useQuery()

    // const auth = useAuthDataContext();


    const handleDateChange = (date, name) => {
        props.handleSearch(name, moment(date).format('YYYY-MM-DD'));
    }

    const handleSearch = e => {
        const name = e.target.name
        const value = e.target.value

        props.handleSearch(name, value)
    }

    const handleChangeSupplier = data => {
        props.handleSearch({
            supplier_id: data?.id || '',
        });
    }

    const handleFromDateChange = e => {
        const name = e.target.name
        const value = e.target.value

        let date = value ? moment(value).format('YYYY-MM-DD') : '';

        props.handleSearch({
            created_from_date: date,
            created_to_date: date,
        });
    }

    const handleToDateChange = e => {
        const value = e.target.value

        let date = value ? moment(value).format('YYYY-MM-DD') : '';

        if (date && props.filter.created_from_date > date) {
            return;
        }

        props.handleSearch({
            created_to_date: date,
        });
    }

    return (
        <div className="row page-filter">
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <FloatingLabel label="Търсене">
                    <Form.Control
                        type="text"
                        placeholder="Търсене"
                        name="search"
                        value={props.filter.search || ''}
                        onChange={handleSearch}
                    />
                </FloatingLabel>
            </div>
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <Autocomplete
                    inputPlaceholder="Доставчик"
                    url="autocomplete/suppliers"
                    inputIdName="supplier_id"
                    // inputValue={state.data?.supplier?.name}
                    inputIdValue={props.filter.supplier_id || ''}
                    onChange={handleChangeSupplier}
                    onInputChange={handleChangeSupplier}
                />
            </div>
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <FloatingLabel label="ЕИК">
                    <Form.Control
                        type="text"
                        placeholder="ЕИК"
                        name="bulstat"
                        value={props.filter.bulstat || ''}
                        onChange={handleSearch}
                    />
                </FloatingLabel>
            </div>
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <FloatingLabel label="ДДС №">
                    <Form.Control
                        type="text"
                        placeholder="ДДС №"
                        name="vat_num"
                        value={props.filter.vat_num || ''}
                        onChange={handleSearch}
                    />
                </FloatingLabel>
            </div>
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <DatePicker
                    placeholder="Създаден от"
                    name="created_from_date"
                    value={props.filter.created_from_date || ''}
                    onChange={handleFromDateChange}
                />
            </div>

            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <DatePicker
                    placeholder="Създаден до"
                    name="created_to_date"
                    value={props.filter.created_to_date || ''}
                    onChange={handleToDateChange}
                />
            </div>
        </div>
    )
}

export default Filter;
