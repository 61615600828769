import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import { Modal, Button, Col, Row, FloatingLabel, Form, Table, Alert, } from 'react-bootstrap';

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//providers
import { useLanguageContext } from 'providers/Language'
import { useNavigate } from 'react-router-dom';

//misc
import Question from 'components/modals/Question';
import SaveButton from 'components/misc/Button'
import Info from 'components/modals/Info';
import Autocomplete from 'components/misc/Autocomplete';
import HelpInfo from 'components/misc/Info';
import moment from 'moment';
import PriceValue from 'components/partials/PriceValue';
import { useAppContext } from 'providers/App';
import Loader from 'components/misc/Loader';

function New(props, ref) {

    const app = useAppContext();
    const navigate = useNavigate();

    const defaultData = {

    }

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        refresh: false,
        data: defaultData,
        loading: false,
        loadingBase: false,
        loadingData: false,
        loadingApply: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null,
        onClose: null,
    });

    const [reasons, setReasons] = useState([]);

    const [validations, setValidations] = useValidation();

    const formRef = useRef(null);

    useImperativeHandle(ref, () => ({
        open: (id) => {
            open(id);
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        }
    }));

    useEffect(() => {
        Api.get('returns/reasons')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setReasons(res.data);
                }
            })
    }, []);

    useEffect(() => {
        if (state.id) {
            loadData(state.id);
        }
    }, [state.id]);

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const open = (id) => {
        setState(prev => ({
            ...prev,
            data: {
                num: id
            },
            id: id,
        }));

        show();
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
            subjectId: null,
            subjectType: null
        }));

        setValidations(null);

        if (typeof state.onClose === 'function') {
            hide(state.onClose);
        } else {
            hide();
        }
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            refresh: new Date().getTime(),
        }));
    }

    const loadData = (orderId) => {

        setState(prev => ({
            ...prev,
            loadingData: true,
        }));

        Api.get('returns/find-order-for-return', {
            params: {
                num: orderId,
            }
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    order: res.data.order,
                    rows: res.data.rows
                }
            }));
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loadingData: false,
            }));
        });
    }

    const handleSave = e => {
        e.preventDefault();

        if (state.loading) {
            return;
        }

        setState(prev => ({
            ...prev,
            loading: true,
            loadingBase: true,
            data: {
                ...prev.data,
                order: null,
                error: null,
            }
        }));

        setValidations(null);

        let url = 'returns/find-order-for-return';

        let data = new FormData(e.target);

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    order: res.data.order,
                    rows: res.data.rows
                }
            }));
        }).catch(error => {
            const _err = error.response;

            if (_err && _err.status && _err.status === 422) {
                setValidations(_err.data.errors);
            }
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
                loadingBase: false,
                loadingApply: false,
            }));
        });
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleDateChange = e => {
        const name = e.target.name + '_ymd'
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleApply = e => {
        e.preventDefault();

        // let url = '/returns/new?from_order_id=' + state.data.order.id;

        // let form = formRef.current;

        // form.querySelectorAll('input').forEach(el => {
        //     if (el.checked) {
        //         url += '&' + el.getAttribute('name') + '=' + el.getAttribute('value');
        //     }
        // });

        // navigate(url);

        let form = formRef.current;

        let data = new FormData(form);

        data.append('order_id', state.data.order.id);

        setState(prev => ({
            ...prev,
            loadingApply: true,
            loading: true,
        }))

        Api.post('returns/create-from-order', data)
            .then(res => {
                navigate('/returns/' + res.data.id + '/edit');
                hide();
            }).catch(error => {
                const _err = error.response;

                if (_err && _err.status && _err.status === 422) {
                    setValidations(_err.data.errors);
                } else {
                    app.showError();
                }
            }).finally(() => {
                setState(prev => ({
                    ...prev,
                    loading: false,
                    loadingBase: false,
                    loadingApply: false,
                }));
            });
    }

    const toggleAll = (e) => {
        let form = formRef.current;

        let checked = e.target.checked;

        form.querySelectorAll('input.row-cb').forEach(el => {
            el.checked = checked;
        });
    }

    return (
        <>

            <Modal size="lg" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <form ref={formRef} onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Ново връщане
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Row>
                            <Col>
                                <FloatingLabel label="Номер на поръчка">
                                    <Form.Control
                                        type="text"
                                        name={`num`}
                                        autoComplete="new-password"
                                        value={state.data.num || ''}
                                        placeholder="Номер на поръчка"
                                        onChange={(e) => handleInputChange(e)}
                                        isInvalid={Boolean(validations?.num)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {validations?.num && (validations?.num[0] || validations?.num)}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>

                        {state.loadingData &&
                            <Loader className="mt-3" />
                        }

                        {state.data.order &&
                            <Table className="mt-3" size="sm" bordered>
                                <tbody>
                                    <tr>
                                        <td className="th">
                                            Клиент
                                        </td>
                                        <td>
                                            {state.data.order?.client_name}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="th">
                                            Дата
                                        </td>
                                        <td>
                                            {state.data.order.created_at ? moment(state.data.order.created_at).format('DD.MM.YYYY HH:mm') : ''}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="th">
                                            Начин на плащане
                                        </td>
                                        <td>
                                            {state.data.order?.paymentmethod?.name}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="th">
                                            Сума за плащане
                                        </td>
                                        <td>
                                            <PriceValue prefix={state.data.order.currency?.prefix} sufix={state.data.order.currency?.sufix}>{state.data.order?.total_real}</PriceValue>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="th">
                                            Платена сума
                                        </td>
                                        <td>
                                            <PriceValue prefix={state.data.order.currency?.prefix} sufix={state.data.order.currency?.sufix}>{state.data.order?.total_paid_sum}</PriceValue>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="th">
                                            Статус
                                        </td>
                                        <td>
                                            {state.data.order?.status?.name}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        }

                        {validations?.rows &&
                            <Alert className="mt-3" variant="danger">
                                {validations.rows[0] || validations.rows}
                            </Alert>
                        }

                        {state.data.order &&
                            <>
                                {state.data.rows.length === 0
                                    ?
                                    <Alert className="mt-3">
                                        Няма артикули за връщане.
                                    </Alert>
                                    :
                                    <Table className="mt-3 xxl" size="sm" bordered>
                                        <thead>
                                            <tr>
                                                <th className="text-center">
                                                    <input
                                                        type="checkbox"
                                                        onClick={e => toggleAll(e)}
                                                    />
                                                </th>
                                                <th>
                                                    Артикул
                                                </th>
                                                <th>
                                                    Количество
                                                </th>
                                                <th>
                                                    Върнато к-во
                                                </th>
                                                <th>
                                                    Продажна цена
                                                </th>
                                                <th className="text-center">
                                                    Причина
                                                </th>
                                                <th className="text-center">
                                                    Отварян
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {state.data.rows.map((r, index) =>
                                                <tr key={r.id}>
                                                    <td className="text-center">
                                                        <input
                                                            className="row-cb"
                                                            type="checkbox"
                                                            name="rows[]"
                                                            value={r.id}
                                                        />
                                                    </td>
                                                    <td>
                                                        {r.article?.article_name}
                                                    </td>
                                                    <td>
                                                        {r.amount || 0} {r.article?.amount_type_short}
                                                    </td>
                                                    <td>
                                                        {r.return_amount || 0} {r.article?.amount_type_short}
                                                    </td>
                                                    <td>
                                                        <PriceValue prefix={state.data.order?.currency?.prefix} sufix={state.data.order?.currency?.sufix}>{r.total_sum}</PriceValue>
                                                    </td>
                                                    <td className="text-center">
                                                        <select
                                                            name="reasons[]"
                                                            defaultValue={''}
                                                        >
                                                            <option value="" disabled></option>
                                                            {reasons.map(reason =>
                                                                <option key={reason.id} value={reason.id}>{reason?.translation?.name || reason?.name}</option>
                                                            )}
                                                        </select>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="custom-radio">
                                                            <label>
                                                                <input type="radio" name={`opened[${index}]`} value={0} />
                                                                <span>
                                                                    НЕ
                                                                </span>
                                                            </label>
                                                            <label>
                                                                <input type="radio" name={`opened[${index}]`} value={1} />
                                                                <span>
                                                                    ДА
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}

                                        </tbody>
                                    </Table>
                                }
                            </>
                        }


                        {state.data.order && state.data.rows.length > 0 &&
                            <div className="mt-3" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <SaveButton
                                    loading={state.loadingApply}
                                    disabled={state.loading}
                                    className="save"
                                    text="Ново връщане"
                                    onClick={handleApply}
                                />
                            </div>
                        }

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loadingBase}
                            disabled={state.loading}
                            className="save"
                            text="Провери"
                        />
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default forwardRef(New);
