import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState, Fragment } from 'react'

//plugins
import moment from 'moment'
import { Modal, Button, Tabs, Tab, FloatingLabel, Form, Row, Col, Nav, Dropdown, Alert } from 'react-bootstrap';

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//providers
import { useLanguageContext } from 'providers/Language'
import { useCurrencyContext } from 'providers/Currency';

//misc
import SaveButton from 'components/misc/Button'
import Autocomplete from 'components/misc/Autocomplete';

import Client from 'components/pages/clients/partials/AddOrEdit';
import ClientUnpaidAccounts from './ClientUnpaidAccounts';
import ClientPaymentsForStorno from './ClientPaymentsForStorno';
import Refs from 'Refs';
import DatePicker from 'components/partials/filter/DatePicker';

function AddOrEdit(props, ref) {

    const currencies = useCurrencyContext();

    const defaultData = {
        type_id: 1,
        currency_id: currencies.getDefault()?.id || '',
        currency: currencies.getDefault(),
    };

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        edit: false,
        data: defaultData,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null
    });

    const [validations, setValidations] = useValidation();

    const [paymentMethods, setPaymentMethods] = useState([]);

    const validationModalRef = useRef(null);

    useImperativeHandle(ref, () => ({
        add: () => {
            add();
        },
        forClient: client => {
            forClient(client);
        },
        edit: (id) => {
            edit(id);
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        }
    }));

    useEffect(() => {
        if (!state.show) {
            return;
        }

        Api.get('payments/payment-methods')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setPaymentMethods(res.data);
                }
            });
    }, [state.show]);

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const add = () => {
        setState(prev => ({
            ...prev,
            id: null,
        }));

        show();
    }

    const forClient = client => {
        setState(prev => ({
            ...prev,
            id: null,
            data: {
                ...prev.data,
                client: client,
                client_id: client.id,
            }
        }));

        show();
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
        }));

        setValidations(null);

        if (typeof state.onClose === 'function') {
            hide(state.onClose);
        } else {
            hide();
        }

        setState(prev => ({
            ...prev,
            onClose: null,
        }));
    }

    const edit = id => {
        setState(prev => ({
            ...prev,
            id: id,
            edit: new Date().getTime()
        }));

        show();
    }

    const handleSave = e => {
        e.preventDefault();

        if (state.loading) {
            return;
        }

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = state.id ? 'payments/edit' : 'payments/add';

        let data = new FormData(e.target);

        if (state.id) {
            data.append('id', state.id)
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (typeof state.onSuccess === 'function') {
                state.onSuccess(res.data)
            }

            handleClose();
        }).catch(error => {
            const _err = error.response;

            if (_err && _err.status && _err.status === 422) {
                // hide(() => {
                //     let modal = validationModalRef.current;

                //     if (modal) {
                //         modal.open();

                //         modal.onClose(() => {
                //             setValidations(_err.data.errors)
                //             show();
                //         });
                //     }
                // });

                setValidations(_err.data.errors)
            }

        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }));
        });
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleCheckboxChange = e => {
        const name = e.target.name;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: prev.data[name] ? false : true
            }
        }));
    }

    const handleDateChange = e => {
        const name = e.target.name + '_ymd'
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleTabChange = id => {
        setState(prev => ({
            ...prev,
            tabId: Number(id)
        }));
    }

    const handleChangeClient = data => {

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                client: data,
                client_id: data?.id,
                paymethod_id: data?.method_id,
            }
        }));

    }

    const handleAddClient = () => {
        let modal = Refs.getInstance().getRef('newClient');

        if (modal) {

            hide(() => {
                modal.add();
            });

            modal.onSuccess(client => {
                if (client) {
                    handleChangeClient(client);
                }

                show();
            });

            modal.onClose(() => {
                show();
            });
        }
    }

    const handleAccountChange = data => {
        // console.log(data);

        let total = 0;

        data.map(r => {
            total += r.price;
        });

        // console.log(total);

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                amount: total,
            }
        }));
    }

    const handleStornoPaymentChange = data => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                amount: data?.price || '',
            }
        }));
    }

    const handleCurrencyChange = e => {
        let id = e.target.value;

        let currency = currencies.data.find(c => Number(c.id) === Number(id));

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                currency_id: id,
                currency: currency
            }
        }));
    }

    return (
        <>
            <Modal size="lg" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <Form noValidate onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Ново плащане
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Row className="mb-3">
                            <Col>
                                <Autocomplete
                                    url="payments/clients"
                                    inputName="client_name"
                                    inputIdName="client_id"
                                    inputPlaceholder="Клиент"
                                    inputValue={state.data?.client?.name}
                                    inputIdValue={state.data?.client_id || ''}
                                    onChange={handleChangeClient}
                                    // onClickAddButton={handleAddClient}
                                    error={Boolean(validations?.client_id)}
                                    helperText={validations?.client_id && (validations.client_id[0] || validations.client_id)}
                                    renderText={data => {
                                        return (
                                            <div style={{ display: 'flex', width: '100%' }}>
                                                <div style={{ width: '10%' }}>
                                                    {data.id}
                                                </div>
                                                <div style={{ width: '90%' }}>
                                                    {data.name}
                                                    <br />
                                                    {data.email}
                                                </div>
                                            </div>
                                        )
                                    }}
                                    renderInputText={data => data.name}
                                    size="sm"
                                />
                                <Button className="mt-2" variant="outline-dark" size="sm" onClick={e => handleAddClient()}>
                                    Добави нов клиент
                                </Button>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col style={{ display: 'flex', justifyContent: 'center' }}>
                                <div className="custom-radio">
                                    <label>
                                        <input type="radio" name="type_id" value={1} checked={Number(state.data.type_id) === 1} onChange={handleInputChange} />
                                        <span>
                                            По сметка/и
                                        </span>
                                    </label>
                                    <label>
                                        <input type="radio" name="type_id" value={2} checked={Number(state.data.type_id) === 2} onChange={handleInputChange} />
                                        <span>
                                            Ново авансово
                                        </span>
                                    </label>
                                    <label>
                                        <input type="radio" name="type_id" value={3} checked={Number(state.data.type_id) === 3} onChange={handleInputChange} />
                                        <span>
                                            Сторно плащане
                                        </span>
                                    </label>
                                </div>
                            </Col>
                        </Row>

                        {state.data.client_id && state.data.type_id == 1 &&
                            <Row className="mb-3">
                                <Col>
                                    <ClientUnpaidAccounts
                                        clientId={state.data.client_id}
                                        onChange={handleAccountChange}
                                        validations={validations}
                                    />
                                </Col>
                            </Row>
                        }

                        {state.data.client_id && state.data.type_id == 3 &&
                            <Row className="mb-3">
                                <Col>
                                    <ClientPaymentsForStorno
                                        clientId={state.data.client_id}
                                        onChange={handleStornoPaymentChange}
                                        show={show}
                                        hide={hide}
                                        validations={validations}
                                    />
                                </Col>
                            </Row>
                        }

                        <Row className="mb-3">
                            <Col>
                                <FloatingLabel label="Валута">
                                    <Form.Select
                                        name="currency_id"
                                        value={state.data.currency_id || ''}
                                        onChange={handleCurrencyChange}
                                    >
                                        {currencies.data.map(c =>
                                            <option key={c.id} value={c.id}>{c.name}</option>
                                        )}
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                            <Col>
                                <FloatingLabel label="Сума">
                                    <Form.Control
                                        type="number"
                                        min="0"
                                        step="0.01"
                                        name="amount"
                                        placeholder="Сума"
                                        value={state.data.amount || ''}
                                        onChange={handleInputChange}
                                        isInvalid={Boolean(validations && validations.amount)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {validations && validations.amount && (validations.amount[0] || validations.amount)}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col>
                                <FloatingLabel label="Начин на плащане">
                                    <Form.Select
                                        name="paymethod_id"
                                        value={state.data.paymethod_id || ''}
                                        onChange={handleInputChange}
                                    >
                                        {/* <option></option> */}
                                        {(paymentMethods || []).map(method =>
                                            <option key={method.id} value={method.id}>{method?.translation?.name || method.name}</option>
                                        )}
                                    </Form.Select>
                                </FloatingLabel>


                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col>
                                <FloatingLabel label="Референция" >
                                    <Form.Control
                                        type="text"
                                        name="ref"
                                        placeholder="Референция"
                                        value={state.data.ref || ''}
                                        onChange={handleInputChange}
                                        isInvalid={Boolean(validations && validations.ref)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {validations && validations.ref && (validations.ref[0] || validations.ref)}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col>
                                <DatePicker
                                    placeholder="Вальор"
                                    type="datetime-local"
                                    name="value_date"
                                    value={state.data?.value_date ? moment(state.data.value_date).format('YYYY-MM-DDTHH:mm') : ''}
                                    onChange={handleInputChange}
                                    isInvalid={Boolean(validations?.value_date)}
                                    error={validations?.value_date && (validations.value_date[0] || validations.value_date)}
                                />
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col>
                                <FloatingLabel label="Описание" >
                                    <Form.Control
                                        as="textarea"
                                        type="text"
                                        name="description"
                                        placeholder="Описание"
                                        value={state.data.description || ''}
                                        onChange={handleInputChange}
                                        isInvalid={Boolean(validations && validations.description)}
                                        style={{
                                            height: '100px'
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {validations && validations.description && (validations.description[0] || validations.description)}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button variant="secondary" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            className="save"
                        />
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

export default forwardRef(AddOrEdit);
