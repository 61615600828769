import React from 'react';
import NavBar from './NavBar';
import SideBar from './SideBar';

function Layout(props) {
    return (
        <>
            <NavBar />

            <div className="container-fluid">
                <div className="row">
                    {/* <SideBar /> */}

                    <main className="col-xs-12">
                        {props.children}
                    </main>
                </div>
            </div>
        </>
    )
}

export default Layout