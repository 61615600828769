import { useState, useEffect, useRef } from 'react'
import { Table, Button, Dropdown } from 'react-bootstrap';
import { Pencil, Envelope } from 'react-bootstrap-icons';
import Api from 'helpers/Api';
import Loader from 'components/misc/Loader';
import moment from 'moment';
import PriceValue from 'components/partials/PriceValue';
import { useNavigate } from 'react-router-dom';
import { isNumeric } from 'helpers/Price';
import { useAppContext } from 'providers/App';
import SaveButton from 'components/misc/Button';
import PrintButton from 'components/misc/PrintButton';
import Refs from 'Refs';
import { buildUrl } from 'helpers/Url';
import { useAuthDataContext } from 'providers/Auth';
import Rows from './Rows';

function Index(props) {

    const app = useAppContext();
    const auth = useAuthDataContext();
    const navigate = useNavigate();

    const { show, hide } = props;

    const defaultData = {
        rows: [],
        tracking_numbers: []
    };

    const [state, setState] = useState({
        data: defaultData,
        loading: false,
        loadingShipmentRequests: false,
    });

    const printButtonRef = useRef();

    useEffect(() => {
        setState(prev => ({
            ...prev,
            loading: state.data.id ? false : true
        }));

        Api.get('accounts/show', {
            params: {
                id: props.id
            }
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data,
                loading: false,
            }));
        });
    }, [props.id]);

    const getAddressName = address => {
        if (!address) {
            return '';
        }

        let name = [];

        if (address.delivery_type === 'address') {
            name = [
                address.dest_name_full,
                address.address,
                address.description,
                address.country_name,
            ];
        }

        if (address.delivery_type === 'office') {
            name = [
                address.dest_name_full,
                address.office_name,
            ];
        }

        if (address.delivery_type === 'store') {
            name = [
                address?.store?.translation?.name || address?.store?.name,
            ];
        }

        return name.filter(p => p).join(', ');
    }

    const getExtendedAddressName = address => {
        if (!address) {
            return '';
        }

        let map = {
            address: 'Адрес на клиент',
            office: 'Офис на куриер',
            store: 'Търговски обект'
        };

        let type = map[address.delivery_type];

        return type + ': ' + getAddressName(address);
    }

    const showClient = () => {
        props.showClient(state.data.client_id);
    }

    const showArticle = id => {
        props.showArticle(id);
    }

    const handleEdit = () => {
        navigate('/accounts/' + state.data.id + '/edit');
    }

    const sendMail = () => {
        props.sendMail(state.data);
    }

    const editDeliveryData = () => {
        props.editDeliveryData();
    }

    const handleCreateInvoice = () => {
        props.hide();

        navigate('/invoices/new?from_account_id=' + state.data.id);
    }

    const handleCreateCreditNote = () => {
        props.hide();

        navigate('/invoices/new?from_account_id=' + state.data.id + '&cn=1');
    }

    const handleCreateStorno = () => {
        props.hide();

        navigate('/accounts/new?from_account_id=' + state.data.id + '&storno=1');
    }

    const handleCreateClone = () => {
        props.hide();

        navigate('/accounts/new?from_account_id=' + state.data.id);
    }


    const handleCreateShipmentRequests = () => {
        setState(prev => ({
            ...prev,
            loadingShipmentRequests: true,
        }));

        Api.post('accounts/create-shipment-requests', {
            id: state.data.id,
        }).then(res => {
            if (res.data.error) {
                app.showError(res.data.error);
            } else {
                setState(prev => ({
                    ...prev,
                    data: res.data
                }));
            }
        }).catch(err => {
            app.showError();
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loadingShipmentRequests: false,
            }));
        });
    }

    const handlePrintShipmentRequest = number => {
        if (number) {
            printShipmentRequest(number);
        } else {
            printShipmentRequest(state.data.tracking_numbers[0]);
        }
    }

    const trackOrder = number => {
        window.open(
            process.env.REACT_APP_URL + '/check-order?id=' + number,
            '_blank',
            'resizable=1,width=500,height=700'
        )
    }

    const printShipmentRequest = number => {
        printButtonRef.current.print(
            getPrintShipmentRequestUrl(number)
        );
    }

    const getPrintShipmentRequestUrl = number => {
        let url = buildUrl(process.env.REACT_APP_URL + '/api/accounts/print-shipment-request', {
            id: state.data.id,
            tracking_number: number,
            token: auth.getToken()
        });

        return url;
    }

    return (
        <>
            {state.loading
                ?
                <Loader />
                :
                <>
                    <Table size="sm" bordered>
                        <tbody>
                            <tr>
                                <td className="th" style={{ width: '13.66666%' }}>
                                    ID
                                </td>
                                <td style={{ width: '19.66666%' }}>
                                    {state.data.id}
                                </td>
                                <td className="th" style={{ width: '13.66666%' }}>
                                    Обслужва
                                </td>
                                <td style={{ width: '19.66666%' }}>
                                    {state.data?.creator?.username}
                                </td>
                                <td className="th" style={{ width: '13.66666%' }}>
                                    Отворена на
                                </td>
                                <td style={{ width: '19.66666%' }}>
                                    {state.data.created_at ? moment(state.data.created_at).format('DD.MM.YYYY HH:mm') : ''}
                                </td>
                            </tr>
                            <tr>
                                <td className="th">
                                    Търговски обект
                                </td>
                                <td>
                                    {state.data.store?.translation?.name || state.data.store?.name}
                                </td>
                                <td className="th">
                                    Каса
                                </td>
                                <td>
                                    {state.data.cashdesk?.translation?.name || state.data.cashdesk?.name}
                                </td>
                                <td className="th">
                                    Актуализирано на
                                </td>
                                <td>
                                    {state.data.updated_at ? moment(state.data.updated_at).format('DD.MM.YYYY HH:mm') : ''}
                                </td>
                            </tr>
                            <tr>
                                <td className="th">
                                    Документ дата
                                </td>
                                <td>
                                    {state.data.date ? moment(state.data.date).format('DD.MM.YYYY') : ''}
                                </td>
                                <td className="th">
                                    Плащане
                                </td>
                                <td>
                                    {state.data?.paymentmethod?.name}
                                </td>
                                <td className="th">
                                    Закрита на
                                </td>
                                <td>
                                    {state.data.closed_at ? moment(state.data.closed_at).format('DD.MM.YYYY HH:mm') : ''}
                                </td>
                            </tr>
                            <tr>
                                <td className="th">
                                    Клиент
                                </td>
                                <td colSpan={3} style={{ cursor: 'pointer' }} onClick={showClient}>
                                    {state.data.client_name || state.data?.client?.name} {isNumeric(state.data?.client?.discount) ? '(' + state.data?.client?.discount + '%)' : ''}
                                </td>
                                <td className="th">
                                    Телефон
                                </td>
                                <td>
                                    {state.data.tel}
                                </td>
                            </tr>

                            <tr>
                                <td className="th">
                                    Доставка до
                                </td>
                                <td>
                                    Адрес на клиента
                                </td>
                                <td className="th">
                                    Товарителница №
                                </td>
                                <td>
                                    {state.data.tracking_numbers.map(number =>
                                        <span key={number} className="link" onClick={e => trackOrder(number)}>
                                            {number}
                                        </span>
                                    )}
                                </td>
                                <td className="th">
                                    Спедитор
                                </td>
                                <td>
                                    {state.data.speditor?.name}
                                </td>
                            </tr>
                            <tr>
                                <td className="th">
                                    Данни за доставка
                                </td>
                                <td colSpan={5}>
                                    {getExtendedAddressName(state.data.deliveryaddress)}
                                </td>
                            </tr>
                            <tr>
                                <td className="th">
                                    Заглавие
                                </td>
                                <td colspan={5}>
                                    {state.data.alias}
                                </td>
                            </tr>
                            <tr>
                                <td className="th">
                                    Описание
                                </td>
                                <td colspan={5}>
                                    {state.data.description}
                                </td>
                            </tr>
                            <tr>
                                <td className="th">
                                    Допълнителни
                                </td>
                                <td colspan={5}>
                                    {state.data.extra_details}
                                </td>
                            </tr>
                        </tbody>
                    </Table>

                    <div className="mt-3">
                        <Button size="sm" variant="primary" onClick={() => handleEdit()} disabled={state.data.allow_edit === false}>
                            <Pencil /> Редактиране
                        </Button>
                        <Button size="sm" variant="secondary" onClick={() => sendMail()} style={{ marginLeft: '10px' }}>
                            <Envelope /> Изпращане
                        </Button>

                        <Dropdown drop="down" style={{ display: 'inline' }}>
                            <Dropdown.Toggle size="sm" style={{ marginLeft: '10px' }}>
                                Направи...
                            </Dropdown.Toggle>
                            <Dropdown.Menu align="start" style={{ padding: '15px', width: '150px' }}>
                                <Button size="sm" variant="primary" onClick={() => handleCreateInvoice()} style={{ width: '100%' }}>
                                    Фактура
                                </Button>
                                {state.data.allow_credit_note &&
                                    <>
                                        <br />
                                        <Button size="sm" variant="danger" onClick={() => handleCreateCreditNote()} style={{ width: '100%', marginTop: '10px' }}>
                                            Кредитно известие
                                        </Button>
                                    </>
                                }
                                {state.data.allow_storno &&
                                    <>
                                        <br />
                                        <Button size="sm" variant="secondary" onClick={() => handleCreateStorno()} style={{ width: '100%', marginTop: '10px' }}>
                                            Сторно сметка
                                        </Button>
                                    </>
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                        {state.data.allow_clone &&
                            <Button size="sm" variant="secondary" onClick={() => handleCreateClone()} style={{ marginLeft: '10px' }}>
                                Клонирай
                            </Button>
                        }
                        {state.data.allow_shipment_request &&
                            <SaveButton
                                size="sm"
                                variant="secondary"
                                onClick={() => handleCreateShipmentRequests()}
                                loading={state.loadingShipmentRequests}
                                style={{ marginLeft: '10px' }}
                            >
                                Създай товарителница
                            </SaveButton>
                        }
                        {state.data.allow_print_shipment_request
                            ?
                            state.data.tracking_numbers.length > 1
                                ?
                                <Dropdown drop="down" style={{ display: 'inline' }}>
                                    <Dropdown.Toggle variant="secondary" size="sm" style={{ marginLeft: '10px' }}>
                                        Покажи товарителница...
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu align="start" style={{ padding: '15px', width: '150px' }}>
                                        {state.data.tracking_numbers.map(tracking_number =>
                                            <PrintButton
                                                key={tracking_number}
                                                size="sm"
                                                variant="primary"
                                                style={{ width: '100%' }}
                                                url={getPrintShipmentRequestUrl(tracking_number)}
                                            >
                                                {tracking_number}
                                            </PrintButton>
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>
                                :
                                <PrintButton
                                    ref={printButtonRef}
                                    size="sm"
                                    variant="secondary"
                                    onClick={() => handlePrintShipmentRequest()}
                                    style={{ marginLeft: '10px' }}
                                >
                                    Покажи товарителница
                                </PrintButton>
                            :
                            ''
                        }
                        <Button size="sm" variant="secondary" onClick={() => editDeliveryData()} style={{ marginLeft: '10px' }}>
                            Данни доставка
                        </Button>
                    </div>

                    <Rows
                        id={state.data.id}
                        show={show}
                        hide={hide}
                    />
                </>
            }
        </>
    )
}

export default Index;