import React, { useEffect, useState } from 'react'
import { Form, Pagination as Pages } from 'react-bootstrap';

function Pagination(props) {

    let items = [];

    let totalResults = parseInt(props.total || 0);
    let totalItems = parseInt(props.pages || 0);
    let itemsPerPage = 10;
    // let numPages = (itemsPerPage === 0 ? 0 : Math.ceil(totalItems / itemsPerPage));
    let numPages = parseInt(props.pages || 0);
    let currentPage = parseInt(props.page || 1);
    let maxPagesToShow = 10;

    const [state, setState] = useState({
        limit: ''
    });

    useEffect(() => {
        setState(prev => ({
            ...prev,
            limit: Number(props.limit)
        }));
    }, [props.limit]);

    const getPages = () => {
        let items = [];

        if (numPages < 1) {
            return items;
        }

        if (numPages <= maxPagesToShow) {
            for (let i = 1; i <= numPages; i++) {
                items.push(createPage(i));
            }
        } else {

            // Determine the sliding range, centered around the current page.
            let numAdjacents = Math.floor((maxPagesToShow - 3) / 2);
            let slidingStart;
            let slidingEnd;

            if (currentPage + numAdjacents > numPages) {
                slidingStart = numPages - maxPagesToShow + 2;
            } else {
                slidingStart = currentPage - numAdjacents;
            }

            if (slidingStart < 2) slidingStart = 2;

            slidingEnd = slidingStart + maxPagesToShow - 3;

            if (slidingEnd >= numPages) slidingEnd = numPages - 1;

            // Build the list of pages.
            items.push(createPage(1));

            if (slidingStart > 2) {
                items.push(createPageEllipsis());
            }

            for (let i = slidingStart; i <= slidingEnd; i++) {
                items.push(createPage(i));
            }

            if (slidingEnd < numPages - 1) {
                items.push(createPageEllipsis());
            }

            items.push(createPage(numPages));
        }


        return items;
    }

    // for (let number = 1; number <= props.pages; number++) {
    //     items.push(
    //         <Pages.Item
    //             key={number}
    //             active={Number(number) === Number(props.page)}
    //             className="link-dark "
    //             onClick={e => props.handlePage({
    //                 selected: number - 1
    //             })}>
    //             {number}
    //         </Pages.Item>
    //     );
    // }

    const createPage = (number) => {
        return (
            <Pages.Item
                key={number}
                active={number === currentPage}
                className="link-dark "
                onClick={e => props.handlePage({
                    selected: number - 1
                })}>
                {number}
            </Pages.Item>
        )
    }

    const createPageEllipsis = () => {
        return (
            <Pages.Item
                active={false}
                className="link-dark ">
                ...
            </Pages.Item>
        )
    }

    const handleLimitChange = e => {
        let value = e.target.value;

        setState(prev => ({
            ...prev,
            limit: value,
        }));

        if (typeof props.onLimitChange === 'function') {
            props.onLimitChange(value);
        }
    }

    return (
        totalItems > 1 &&
        <div className={props.className} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Pages style={{ marginBottom: 0 }}>
                    {getPages().map((item, i) => React.cloneElement(item, {
                        key: i
                    }))}
                </Pages>

                {typeof props.limit !== 'undefined' &&
                    <Form.Select
                        value={state.limit || ''}
                        onChange={handleLimitChange}
                        style={{
                            marginLeft: '5px'
                        }}
                    >
                        <option value="" disabled></option>
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={200}>200</option>
                        <option value={500}>500</option>
                    </Form.Select>
                }
            </div>

            {typeof props.total !== 'undefined' &&
                <div style={{ marginLeft: 'auto', fontSize: '13px' }}>
                    Резултати: {totalResults}
                </div>
            }
        </div>
    )
}

export default Pagination