import Axios from 'axios';
import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef, useMemo } from 'react';
import Api from 'helpers/Api'
import { trans } from 'providers/Translation';
import { Spinner, FloatingLabel, Form, InputGroup, Button, ButtonGroup } from 'react-bootstrap';
import { CheckAll, Info, Plus, PlusCircleFill, PlusLg, X } from 'react-bootstrap-icons';
import HelpInfo from 'components/misc/Info'

import 'assets/scss/dropdown.scss';
import LockedFilter from './LockedFIlter';

function useOnClickOutside(ref, handler) {
    useEffect(
        () => {
            const listener = (event) => {
                // Do nothing if clicking ref's element or descendent elements
                if (!ref.current || ref.current.contains(event.target)) {
                    return;
                }
                handler(event);
            };
            document.addEventListener("mousedown", listener);
            document.addEventListener("touchstart", listener);
            return () => {
                document.removeEventListener("mousedown", listener);
                document.removeEventListener("touchstart", listener);
            };
        },
        // Add ref and handler to effect dependencies
        // It's worth noting that because passed in handler is a new ...
        // ... function on every render that will cause this effect ...
        // ... callback/cleanup to run every render. It's not a big deal ...
        // ... but to optimize you can wrap handler in useCallback before ...
        // ... passing it into this hook.
        [ref, handler]
    );
}

function Index(props, ref) {
    const dropdownRef = useRef(null);
    const listRef = useRef(null);

    const variant = props.variant || 'floatingLabel';
    const size = props.size || '';
    const filter = Boolean(props.filter) || false;
    const filterTimeout = props.timeout || 300;
    const inputName = props.inputName || 'text-value';
    const inputValue = props.inputValue || '';
    const inputId = props.inputId || '';
    const inputClassName = props.inputClassName || '';
    const inputIdName = props.inputIdName || 'id-value';
    const inputIdValue = props.inputIdValue || '';
    const inputPlaceholder = props.inputPlaceholder || '';
    const inputReadOnly = props.inputReadOnly || false;
    const inputDisabled = props.inputDisabled || false;
    const dropdownData = Array.isArray(props.data) ? props.data : [];
    const dataUrl = props.url || null;
    const dataParams = props.params || {};
    const loadDataOnFocus = props.loadDataOnFocus || false;
    const requiredParams = Array.isArray(props.requiredParams) ? props.requiredParams : [];
    const renderText = props.renderText || '';
    const renderInputText = props.renderInputText || '';
    const filterable = typeof props.filterable !== 'undefined' ? Boolean(props.filterable) : true;
    const multiple = typeof props.multiple !== 'undefined' ? Boolean(props.multiple) : false;
    const selectedValues = Array.isArray(props.selectedValues) ? props.selectedValues : [];
    const selectedIds = Array.isArray(props.selectedIds) ? props.selectedIds : [];
    const addButtonDisabled = Boolean(props.addButtonDisabled) || false;
    const onClickAddButton = typeof props.onClickAddButton === 'function' ? props.onClickAddButton : null;
    const onClickShowButton = typeof props.onClickShowButton === 'function' ? props.onClickShowButton : null;
    const onChange = typeof props.onChange === 'function' ? props.onChange : () => { };
    const onInputChange = typeof props.onInputChange === 'function' ? props.onInputChange : () => { };
    const onClick = typeof props.onClick === 'function' ? props.onClick : () => { };
    const autoFocus = Boolean(props.autoFocus) || false;
    const helpInfo = props.helpInfo;
    const lock = props.lock;

    const initialData = {
        data: dropdownData,
        original: dropdownData,
        showResults: false,
        loading: false,
        dataLoaded: false,
        inputValue: inputValue,
        timeout: null,
        request: null,
    };

    const [data, setData] = useState(initialData);

    const [selected, setSelected] = useState({
        name: inputValue,
        id: inputIdValue
    });

    const [multiSelected, setMultiSelected] = useState({});

    const [focused, setFocused] = useState(0);

    useOnClickOutside(dropdownRef, () => hideResults());

    useImperativeHandle(ref, () => ({
        reset: () => {
            resetData();
        }
    }));

    useEffect(() => {
        if (typeof props.inputValue !== 'undefined') {
            setData(prev => ({
                ...prev,
                inputValue: inputValue
            }));
        }
    }, [props.inputValue, props.inputIdValue]);


    useEffect(() => {

        // експериментално, същото го има малко по-надолу
        if (typeof props.inputValue !== 'undefined') {

            setSelected(prev => ({
                ...prev,
                name: inputValue,
                id: inputIdValue
            }));

            setData(prev => ({
                ...prev,
                inputValue: inputValue
            }));

        }

        if (!filter) {
            if (typeof inputIdValue !== 'undefined') {

                setSelected(prev => ({
                    ...prev,
                    id: inputIdValue
                }));

                // console.log(inputIdName, inputIdValue, inputValue)

                let exists = data.original.find(e => {
                    return String(e.id) === String(inputIdValue);
                });

                if (!exists) {
                    // clearTimeout(data.timeout);

                    let timeout = setTimeout(() => {
                        loadData(inputValue, inputIdValue, rows => {
                            let active = rows.filter((e, i) => {
                                if (String(e.id) === String(inputIdValue)) {
                                    setFocused(i);
                                    return true;
                                }

                                return false;
                            })[0];

                            if (active) {
                                let el = listRef.current.querySelector("li[data-id='" + active.id + "']");

                                if (el) {
                                    el.click();
                                }
                            }
                        });
                    }, filterTimeout);
                } else {
                    let el = listRef.current.querySelector("li[data-id='" + exists.id + "']");

                    if (el) {
                        el.click();
                    }
                }

            }

            // else {
            //     if (typeof props.inputValue !== 'undefined') {
            //         setSelected(prev => ({
            //             ...prev,
            //             name: inputValue,
            //             id: inputIdValue
            //         }));


            //         setData(prev => ({
            //             ...prev,
            //             inputValue: inputValue
            //         }));

            //     }
            // }
        }

    }, [props.inputValue, props.inputIdValue, props.url]);

    // при прост филтър на резултати
    useEffect(() => {
        if (filter) {
            let exists = data.original.filter(e => {
                return e.id == inputIdValue;
            })[0];

            if (exists) {

                let el = listRef.current.querySelector("li[data-id='" + exists.id + "']");

                if (el) {
                    el.click();
                }

                // setSelected(prev => ({
                //     ...prev,
                //     name: exists.name
                // }))
            } else {
                if (props.inputValue) {
                    setSelected(prev => ({
                        ...prev,
                        name: props.inputValue
                    }));
                }
            }
        }
    }, [props.inputValue, props.inputIdValue, data.original]);

    useEffect(() => {
        if (Array.isArray(props.data)) {
            setData(prev => ({
                ...prev,
                data: props.data,
                original: props.data
            }))
        }
    }, [props.data]);

    // за multiple
    useEffect(() => {
        if (multiple && typeof props.selectedValues !== 'undefined') {
            let ids = {};

            selectedValues.map(obj => {
                ids[String(obj.id)] = obj;
            });

            setMultiSelected(ids);
        }
    }, [props.selectedValues]);

    useEffect(() => {
        if (multiple && data.data.length && typeof props.selectedIds !== 'undefined') {
            let ids = {};

            selectedIds.map(id => {
                let found = data.data.find(el => String(el.id) === String(id));

                if (found) {
                    ids[String(id)] = found;
                }
            });

            setMultiSelected(ids);
        }
    }, [props.selectedIds, data.data]);


    useEffect(() => {
        if (props.url && multiple && selectedValues.length === 0) {
            setData(prev => ({
                ...prev,
                data: [],
                original: []
            }))
        }
    }, [props.url]);

    useEffect(() => {
        return () => clearTimeout(data.timeout);
    }, []);

    useEffect(() => {
        if (typeof props.params !== 'undefined') {
            loadData(selected.name, selected.id);
        }
    }, [props.params]);

    const loadData = (name, id = null, callback = null) => {

        if (!dataUrl) {
            return;
        }

        if (typeof name === 'undefined') {
            name = '';
        }

        // console.log(dataParams)

        if (requiredParams.length) {
            let params = Object.keys(dataParams);

            let missingParams = [];

            // console.log(params)
            // console.log(dataParams)

            let hasMissingParams = requiredParams.filter(p => {

                if (params.includes(p) && !dataParams[p]) {
                    missingParams.push(p);

                    return true;
                }

                return false;
            }).length;

            // console.log(hasMissingParams);

            if (hasMissingParams) {
                console.warn(`[Dropdown]: Missing params: ${missingParams.join(', ')}`);
                return false;
            }
        }

        if (data.request) {
            data.request.cancel();
        }

        let request = Axios.CancelToken.source();

        setData(prev => ({
            ...prev,
            request: request
        }))

        Api.get(dataUrl, {
            params: {
                ...dataParams,
                id: id,
                name: name,
            },
            cancelToken: request.token
        }).then(res => {

            let items = typeof res.data.items !== 'undefined' ? res.data.items : res.data;

            items = items.map(i => {

                if (!i.id) {
                    i.id = Math.random().toString().substr(3);
                }

                return i;
            });

            setData(prev => ({
                ...prev,
                data: items,
                original: items,
                loading: false,
                dataLoaded: true
            }));

            if (selected.id) {
                items.map((e, i) => {
                    if (e.id == selected.id) {
                        setFocused(i);
                    }
                });
            }

            if (typeof callback === 'function') {
                return callback(items);
            }

        }).catch(err => {
            setData(prev => ({
                ...prev,
                data: [],
                original: [],
                loading: false,
                // showResults: false
            }));
        });

    }

    const handleClickAway = () => {
        hideResults();
    }

    const handleKeyPress = e => {
        e.preventDefault();
        e.stopPropagation();

        let val = e.target.value;

        showResults();

        let selectedData = {
            name: val,
            id: ''
        };

        setSelected(selectedData);

        let action;

        if (e.keyCode == 40) {
            action = 'down';
        } else if (e.keyCode == 38) {
            action = 'up';
        } else if (e.keyCode == 13) {
            action = 'enter';
        }

        if (action) {
            if (action === 'down' || action === 'up') {

                let min = 0;
                let max = data.original.length - 1;

                let position;

                if (action === 'down') {
                    position = focused + 1;
                } else {
                    position = focused - 1;
                }

                if (position < min || position > max) {
                    return;
                }

                setFocused(position);

                setTimeout(() => {
                    const selectedFocus = listRef.current.querySelector('.focus');

                    if (selectedFocus) {
                        const offsetHeight = selectedFocus.offsetHeight;
                        const offsetTop = selectedFocus.offsetTop;

                        const scroll = listRef.current.scrollTop;
                        const height = listRef.current.offsetHeight;

                        if (offsetTop + offsetHeight > scroll + height) {
                            listRef.current.scrollTop = scroll + offsetHeight;
                        } else if (offsetTop < scroll) {
                            listRef.current.scrollTop = scroll - offsetHeight;
                        }
                    }

                }, 50);

            } else if (action === 'enter') {

                // let selectedFocus = data.original[focused];

                const selectedFocus = listRef.current.querySelector('.focus');

                if (selectedFocus) {
                    selectedFocus.click();
                }

                // if (selectedFocus) {
                //     setSelected({
                //         id: selectedFocus.id,
                //         name: selectedFocus.name
                //     })

                //     if (props.onChange && typeof props.onChange === 'function') {
                //         props.onChange(selectedFocus);
                //     }

                //     hideResults();
                // }

            }
        }
    }

    const handleChange = e => {

        let val = e.target.value;

        onInputChange(val);

        if (!filterable) {
            return;
        }


        let selectedData = {
            name: val,
            id: ''
        };

        setSelected(selectedData);

        clearTimeout(data.timeout);

        let timeout;

        // просто филтриране на данните
        if (filter) {
            timeout = setTimeout(() => {

                let filtered = data.original.filter(e => {
                    return e.name ? e.name.indexOf(val) > -1 : true;
                });

                setData(prev => ({
                    ...prev,
                    data: filtered,
                    inputValue: val
                }));

                // ако в списъкът има само 1 елемент се избира автоматично
                // if (filtered.length == 1) {
                //     setSelected({
                //         name: filtered[0] ? filtered[0].name : '',
                //         id: filtered[0] ? filtered[0].id : ''
                //     });

                //     hideResults();
                // }


                // if (!multiple && onChange && typeof onChange === 'function') {
                //     onChange(selectedData);
                // }

            }, 50);
        }
        // филтриране чрез заявка към база данни
        else {

            if (!dataUrl) {
                return;
            }

            // console.log(val)
            // console.log(data.inputValue);

            if (String(val) !== String(data.inputValue)) {

                setData(prev => ({
                    ...prev,
                    loading: true,
                }));

                timeout = setTimeout(() => {
                    loadData(val, null, data => {
                        // ако в списъкът има само 1 елемент се избира автоматично
                        // if (data.length == 1) {
                        //     setSelected({
                        //         name: data[0] ? data[0].name : '',
                        //         id: data[0] ? data[0].id : ''
                        //     });

                        //     hideResults();
                        // }
                    });

                }, filterTimeout);

                // if (!multiple && onChange && typeof onChange === 'function') {
                //     onChange(selectedData);
                // }

            }

            setTimeout(() => {
                setData(prev => ({
                    ...prev,
                    inputValue: val
                }));
            }, 100)
        }

        if (timeout) {
            setData(prev => ({
                ...prev,
                timeout: timeout,
            }));
        }
    }

    const handleResult = e => {
        e.preventDefault();

        let target = e.currentTarget;

        // let val = e.target.getAttribute('data-name');

        // let val = e.target.innerHTML;
        let span = target.querySelector('.display-name');

        // if (span) val = span.innerHTML
        let val = span.innerHTML;

        let id = target.getAttribute('data-id');

        // console.log('clicked', val)

        let shouldCallCallback = false;

        let selectedData = {
            name: val,
            id: id
        };

        // пълна информация за избрания елемент от базата данни
        let selectedExtended = data.data.filter((e, i) => {
            if (String(e.id) === String(id)) {
                setFocused(i);
                return true;
            }
            return false;
        })[0];

        let selectedList;

        if (multiple) {
            selectedList = Object.assign({}, multiSelected);

            id = String(id);

            if (selectedList[id]) {
                delete selectedList[id];
            } else {
                if (selectedExtended) {
                    selectedList[id] = selectedExtended
                } else {
                    selectedList[id] = selectedData;
                }

            }

            shouldCallCallback = true;
            setMultiSelected(selectedList);

        } else {
            shouldCallCallback = String(selected.id) !== String(id);
            setSelected(selectedData);
        }

        setData(prev => ({
            ...prev,
            inputValue: val
        }));

        if (shouldCallCallback && onChange && typeof onChange === 'function') {
            if (multiple) {
                // console.log(selectedList)
                onChange(Object.values(selectedList));
            } else {
                if (selectedExtended) {
                    onChange(selectedExtended);
                } else {
                    onChange(selectedData);
                }
            }
        }

        if (!multiple) {
            hideResults();
        }

    }

    const handleBlur = e => {
        if (multiple) {
            return;
        }

        if (e.nativeEvent.explicitOriginalTarget &&
            e.nativeEvent.explicitOriginalTarget === e.nativeEvent.originalTarget) {
            return;
        }

        setTimeout(() => {
            hideResults();
        }, 200);
    }

    const showResults = () => {

        // първоначално зареждане на данните след фокус на полето
        // при последващ фокус не се изпълнява
        if (!data.dataLoaded || loadDataOnFocus) {
            if (dataUrl) {
                clearTimeout(data.timeout);

                let timeout = setTimeout(() => {
                    loadData(selected.name, selected.id);
                }, filterTimeout);
            }
        }

        setData(prev => ({
            ...prev,
            showResults: true
        }));
    }

    const hideResults = () => {
        setData(prev => ({
            ...prev,
            showResults: false
        }));
    }

    const resetData = () => {
        setSelected({
            name: '',
            id: ''
        });

        setData(initialData);
    }

    const selectAll = () => {
        let selected = {};

        data.data.map(item => {
            selected[String(item.id)] = item;
        })

        setMultiSelected(selected);

        if (onChange && typeof onChange === 'function') {
            onChange(Object.values(selected));
        }
    }

    const removeAll = () => {
        let selected = {};

        setMultiSelected(selected);

        if (onChange && typeof onChange === 'function') {
            onChange(Object.values(selected));
        }
    }

    const getDisplayName = el => {

        if (typeof renderInputText === 'function') {
            return renderInputText(el);
        }

        if (typeof renderText === 'function') {
            return renderText(el);
        }

        return el.name ? el.name : '';
    }

    const getInputValue = () => {
        if (multiple && !filterable) {
            let data = [];

            Object.values(multiSelected).map(selected => {
                data.push(
                    getDisplayName(selected)
                );
            });

            return data.join(', ');
        }

        return selected.name;
    }

    const getInput = () => {

        return (
            <>
                {onClickAddButton &&
                    <Button
                        size={size}
                        className="add"
                        // variant="dark"
                        onClick={onClickAddButton}
                        style={{ display: 'flex', alignItems: 'center', borderTopLeftRadius: '.25rem', borderBottomLeftRadius: '.25rem' }}
                        disabled={inputDisabled || inputReadOnly || addButtonDisabled}
                        title="Нов"
                    >
                        <Plus />
                    </Button>
                }
                <Form.Control
                    name={inputName}
                    id={inputId}
                    className={inputClassName}
                    value={getInputValue()}
                    placeholder={inputPlaceholder}
                    readOnly={inputReadOnly}
                    disabled={inputDisabled}
                    onFocus={showResults}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onClick={onClick}
                    isInvalid={Boolean(props.error)}
                    size={size}
                    autoComplete="off"
                    autoFocus={autoFocus}
                />
                {onClickShowButton && selected?.id > 0 &&
                    <Button
                        size={size}
                        className="show"
                        onClick={e => onClickShowButton(selected.id)}
                        style={{ display: 'flex', alignItems: 'center', borderTopRightRadius: '.25rem', borderBottomRightRadius: '.25rem' }}
                        // disabled={}
                        title="Информация"
                    >
                        <Info />
                    </Button>
                }

                {Boolean(props.helperText) &&
                    <Form.Control.Feedback type="invalid">
                        {props.helperText}
                    </Form.Control.Feedback>
                }

                {Boolean(props.lock) &&
                    <LockedFilter lock={props.lock} />
                }

                {helpInfo ?
                    <HelpInfo>{helpInfo}</HelpInfo>
                    :
                    ''
                }
            </>
        )
    }



    return (
        <div className="dropdown-input" onKeyUp={handleKeyPress}>

            {variant === 'floatingLabel'
                ?
                <FloatingLabel label={inputPlaceholder}>
                    {getInput()}
                </FloatingLabel>
                :
                (onClickAddButton || onClickShowButton)
                    ?
                    <InputGroup>
                        {getInput()}
                    </InputGroup>
                    :
                    getInput()
            }

            <div ref={dropdownRef} style={props.listStyle} className={['dropdown', data.showResults && (data.data.length || data.loading) ? 'show' : ''].join(' ')}>

                <ul ref={listRef}>
                    {
                        data.loading
                            ?
                            <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
                                <Spinner animation="border" variant="secondary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </div>
                            :
                            data.data.map((e, i) =>
                                <React.Fragment key={e.id}>
                                    <li
                                        data-id={e.id}
                                        data-name={e.name}
                                        onClick={e => handleResult(e)}
                                        className={[e.id && (String(selected.id) === String(e.id) || multiSelected[e.id]) ? 'active' : '', focused == i ? 'focus' : '', multiple ? 'multiple' : ''].join(' ')}
                                    >
                                        {multiple
                                            ?
                                            <>
                                                <svg className="selected" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                                    <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path>
                                                </svg>
                                                <svg className="not-selected" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                                    <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path>
                                                </svg>
                                            </>
                                            :
                                            ''
                                        }

                                        {typeof renderText === 'function' ? renderText(e) : e.name}

                                        <div className="display-name" hidden>{getDisplayName(e)}</div>
                                    </li>
                                </React.Fragment>
                            )
                    }
                </ul>

                {multiple && data.data.length > 1 &&
                    <div className="all-options">
                        {/* <ButtonGroup style={{ width: '100%' }}> */}
                        <Button variant="link" size="sm" onClick={selectAll}>
                            <CheckAll /> Избери всички
                        </Button>
                        <Button variant="link" size="sm" onClick={removeAll}>
                            Изчисти
                        </Button>
                        {/* </ButtonGroup> */}
                    </div>
                }
            </div>

            {multiple && filterable
                ?
                <div className="multiple-selected-label">
                    {Object.values(multiSelected).length} избрани
                </div>
                :
                ''
            }

            {multiple
                ?
                Object.keys(multiSelected).map(id => (
                    <input
                        key={'input-id-value-' + id}
                        type="hidden"
                        name={`${inputIdName}[]`}
                        value={id}
                    // readOnly={inputDisabled}
                    />
                ))
                :
                <input
                    type="hidden"
                    name={inputIdName}
                    value={selected.id}
                // readOnly={inputDisabled}
                />
            }

        </div>
    )

}

// export default forwardRef(Index);

export default React.memo(React.forwardRef(Index), (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next)
});
