import { ArrowDown, ArrowUp } from 'react-bootstrap-icons'

function TableCol(props) {

    const sortable = Boolean(props.sortable) || false;
    const sortKey = props.sortKey || null;
    const activeSortKey = props.activeSortKey || null;
    const activeSortDir = props.activeSortDir || null;
    const onSort = typeof props.onSort === 'function' ? props.onSort : null;

    // console.log(props)

    const handleSort = () => {
        if (!sortable) {
            return;
        }

        if (onSort) {
            if (activeSortKey === sortKey) {
                onSort(sortKey, activeSortDir === 'asc' ? 'desc' : 'asc');
            } else {
                onSort(sortKey, 'asc');
            }
        }
    }

    // console.log(props)

    return (
        <th key={props.key} style={props.style} ref={props.propRef} className={[sortable && 'sortable', activeSortKey === sortKey ? 'active' : ''].join(' ')} onClick={handleSort}>
            <span style={{ display: 'flex', alignItems: 'center' }}>
                <span className={props.className} style={{ display: 'block', width: '100%' }}>
                    {props.children}


                </span>
                {sortable &&
                    <span style={{ marginLeft: '5px', fontSize: '12px', opacity: 1 }}>
                        {activeSortKey === sortKey
                            ?
                            <span style={{ background: '#0088ff', color: '#fff', padding: '1px', display: 'inline-flex', borderRadius: '2px', fontSize: '12px' }}>
                                {activeSortDir === 'asc'
                                    ?
                                    <ArrowUp />
                                    :
                                    <ArrowDown />
                                }
                            </span>
                            :
                            ''
                            // <ArrowDownUp />
                        }
                    </span>
                }
            </span>
        </th>
    )
}

export default TableCol;