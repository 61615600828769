import React, { useState, useImperativeHandle, forwardRef, useRef, useEffect } from 'react'
import { Modal, Button, Tabs, Tab } from 'react-bootstrap';
import { ChevronLeft, ChevronRight, Clipboard, ClipboardPlus, Trash } from 'react-bootstrap-icons';
import SaveButton from 'components/misc/Button';
import Loader from 'components/misc/Loader';
import Home from './Home';
import Edit from './Edit';
import Categorisation from './Categorisation';
import Prices from './Prices';
import Details from './Details';
import Suppliers from './Suppliers';
import Related from './Related';
import Reviews from './Reviews';

import { useArticleContext } from '../Provider';
import { useAuthDataContext } from 'providers/Auth';
import { useAppContext } from 'providers/App';

import Api from 'helpers/Api';
import Clone from '../partials/Clone';
import Question from 'components/modals/Question';

import Refs from 'Refs';

function View(props, ref) {

    const tabs = [
        {
            id: 'home',
            name: 'Преглед',
            component: Home
        },
        {
            id: 'edit',
            name: 'Редактиране',
            component: Edit
        },
        {
            id: 'categorisation',
            name: 'Категоризация',
            component: Categorisation
        },
        {
            id: 'price_rules',
            name: 'Ценови правила',
            component: Prices
        },
        {
            id: 'details',
            name: 'Характеристики',
            component: Details
        },
        // {
        //     id: 'suppliers',
        //     name: 'Доставчици',
        //     component: Suppliers
        // },
        {
            id: 'related',
            name: 'Свързани артикули',
            component: Related
        },
        // {
        //     id: 'reviews',
        //     name: 'Ревюта',
        //     component: Reviews
        // },
    ];

    const app = useAppContext();
    const auth = useAuthDataContext();

    const [state, setState] = useState({
        show: false,
        params: null,
        id: null,
        tabId: null,
        key: 1,
        onClose: null,
        onSuccess: null,
        onDelete: null,
    });

    const context = useArticleContext();

    const cloneModalRef = useRef(null);
    const deleteModalRef = useRef(null);

    const leftArrowRef = useRef(null);
    const rightArrowRef = useRef(null);

    useImperativeHandle(ref, () => ({
        open: (id, resetTab = true) => {
            handleShow(id, resetTab);
        },
        close: () => {
            hide();
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onDelete: fn => {
            setState(prev => ({
                ...prev,
                onDelete: fn
            }));
        }
    }));

    useEffect(() => {
        if (!state.show) {
            return;
        }

        if (auth.getUser().permission('articles_view') === false) {
            app.showError('Нямате право да преглеждате артикул!');

            hide(state.onClose);
        }

    }, [state.show]);

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const edit = (id) => {
        setState(prev => ({
            ...prev,
            id: id,
        }));
    }

    const handleShow = (id, resetTab = true) => {
        setState(prev => ({
            ...prev,
            id: id,
            tabId: resetTab || !prev.tabId ? getDefaultActiveTab()?.id : prev.tabId,
        }));

        show();
    }

    const handleClose = () => {
        setState(prev => ({
            ...prev,
            show: false,
            tabId: tabs[0].id
        }));

        context.resetData();

        if (typeof state.onClose === 'function') {
            hide(state.onClose);
        } else {
            hide();
        }

        setState(prev => ({
            ...prev,
            onClose: null,
        }));
    }

    const setKey = id => {
        setState(prev => ({
            ...prev,
            tabId: id,
        }));
    }

    const hasTabPermission = key => {
        let map = {
            home: 'articles_view',
            edit: 'articles_edit',
            categorisation: 'articles_cats',
            price_rules: 'articles_pricelists',
            files: 'articles_edit',
            details: 'articles_edit_details',
            suppliers: 'articles_suppliers',
            related: 'articles_edit',
            // reviews: 'articles_edit'
        };

        let permission = map[key];

        if (permission && auth.getUser().permission(permission) === false) {
            return false;
        }

        return true;
    }

    const getDefaultActiveTab = () => {
        return tabs.find(tab => {
            return hasTabPermission(tab.id);
        });
    }

    const handleAddVariation = e => {
        e.preventDefault();

        Api.post('articles/add-variation', {
            id: state.id
        }).then(res => {
            if (res.data) {
                edit(res.data.id);

                app.showSuccess();

                if (typeof state.onSuccess === 'function') {
                    state.onSuccess(res.data);
                }
            }
        });
    }

    const handleClone = e => {
        e.preventDefault();

        let modal = cloneModalRef.current;

        if (modal) {
            hide(() => {
                modal.open(state.id);

                modal.onClose(() => {
                    show();
                });

                modal.onSuccess(article => {
                    edit(article.id);

                    app.showSuccess();

                    if (typeof state.onSuccess === 'function') {
                        state.onSuccess();
                    }
                });

            });
        }
    }

    const handleDelete = e => {
        e.preventDefault();

        let modal = deleteModalRef.current;

        if (modal) {
            hide(() => {
                modal.open();

                modal.onSuccess(() => {
                    handleDestroy(state.id);
                });

                modal.onCancel(() => {
                    show();
                });
            });
        }

    }

    const handleDestroy = id => {
        Api.post('articles/delete', {
            id: id
        }).then(res => {
            if (res.data.success) {
                app.showSuccess();

                if (typeof state.onDelete === 'function') {
                    state.onDelete();
                } else if (typeof state.onSuccess === 'function') {
                    state.onSuccess();
                }
            } else {
                app.showError();
            }
        }).catch(err => {
            app.showError();
        });
    }

    const refresh = () => {
        setState(prev => ({
            ...prev,
            key: new Date().getTime(),
        }));
    }

    const onSuccess = (data) => {
        refresh();

        if (typeof state.onSuccess === 'function') {
            state.onSuccess(data);
        }
    }

    useEffect(() => {
        if (!state.show) {
            return;
        }

        const onKeyPress = (e) => {
            if (e.key === 'ArrowRight') {
                rightArrowRef.current?.click();
            }

            if (e.key === 'ArrowLeft') {
                leftArrowRef.current?.click();
            }
        }

        window.addEventListener('keyup', onKeyPress);

        return () => {
            window.removeEventListener('keyup', onKeyPress);
        }
    }, [state.show]);

    const handleShowNextItem = e => {
        e.preventDefault();

        if (props.showNextItem) {
            props.showNextItem(state.id);
        }
    }

    const handleShowPrevItem = e => {
        e.preventDefault();

        if (props.showPrevItem) {
            props.showPrevItem(state.id);
        }
    }

    const handleRefresh = e => {
        e.preventDefault();

        refresh();
    }

    return (
        <>

            <Clone
                ref={cloneModalRef}
            />

            <Question
                ref={deleteModalRef}
                mainMessage="Сигурни ли сте?"
                agreeBtnText="Изтриване"
                agreeBtnClass="remove"
            />

            <Modal enforceFocus={false} size="xl" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                {props.showPrevItem &&
                    <span ref={leftArrowRef} className="arrow left" onClick={handleShowPrevItem}>
                        <ChevronLeft />
                    </span>
                }
                {props.showNextItem &&
                    <span ref={rightArrowRef} className="arrow right" onClick={handleShowNextItem}>
                        <ChevronRight />
                    </span>
                }
                <Modal.Header>
                    <Modal.Title>
                        Преглед на артикул
                    </Modal.Title>
                    <div className="buttons">
                        <button className="btn-reload" onClick={handleRefresh}>

                        </button>
                        <button className="btn-close" onClick={handleClose}>

                        </button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Tabs
                        key={state.key}
                        activeKey={state.tabId}
                        onSelect={(k) => setKey(k)}
                        className="mb-3"
                        mountOnEnter
                    // unmountOnExit
                    >
                        {tabs.map(tab =>
                            <Tab key={tab.id} eventKey={tab.id} title={tab.name} disabled={hasTabPermission(tab.id) === false}>
                                {React.createElement(tab.component, {
                                    id: state.id,
                                    show: show,
                                    hide: hide,
                                    onSuccess: onSuccess,
                                })}
                            </Tab>
                        )}
                    </Tabs>
                </Modal.Body>
                <Modal.Footer style={{ justifyContent: 'space-between' }}>
                    <div>
                        <Button variant="primary" size="sm" onClick={handleAddVariation} disabled={auth.getUser().permission('articles_edit') === false} style={{ marginRight: '5px' }}>
                            <ClipboardPlus /> Създай разновидност
                        </Button>
                        <Button variant="secondary" size="sm" onClick={handleClone} disabled={auth.getUser().permission('articles_edit') === false} style={{ marginRight: '5px' }}>
                            <Clipboard /> Клонирай
                        </Button>
                        <Button variant="danger" size="sm" onClick={handleDelete} disabled={auth.getUser().permission('articles_delete') === false}>
                            <Trash /> Изтрий
                        </Button>
                    </div>

                    <Button variant="secondary" onClick={handleClose}>
                        Затвори
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default forwardRef(View);