import { forwardRef, useImperativeHandle } from "react";
import { Form } from "react-bootstrap";

function RefNum(props, ref) {

    useImperativeHandle(ref, () => ({
        getName: getName,
        getType: getType,
        getTitle: getTitle,
        hasSettings: hasSettings,
        getSettings: getSettings,
        getDefaultOptions: () => ({ action: 'skip_row' })
    }));

    const getName = () => {
        return 'Референтен номер';
    }

    const getType = () => {
        return 'ref_num';
    }

    const getTitle = () => {
        return null;
    }

    const hasSettings = ({ createOrUpdate }) => {
        if (createOrUpdate === 'update') {
            return true;
        }

        return false;
    };

    const getSettings = ({ state, setConfig, createOrUpdate }) => {

        if (createOrUpdate === 'create') {
            return null;
        }

        if (createOrUpdate === 'update') {
            return (
                <div>
                    <label>
                        Действия при несъществуващ артикул:
                    </label>
                    <div>
                        <Form.Check
                            id={'cb_skip_row'}
                            type="radio"
                            label="Прескочи реда"
                            checked={state.options?.action === 'skip_row'}
                            onChange={e => setConfig('action', 'skip_row')}
                        />
                        <Form.Check
                            id={'cb-abort'}
                            type="radio"
                            label="Прекрати импорта"
                            checked={state.options?.action === 'abort'}
                            onChange={e => setConfig('action', 'abort')}
                        />
                    </div>
                </div>
            )
        }
    }

    return getName();
}

export default forwardRef(RefNum)