import React, { useState, useEffect, useRef } from 'react'
import { Form } from 'react-bootstrap';
import { HouseDoorFill, CaretDownFill, CaretRight } from 'react-bootstrap-icons';
import Api from 'helpers/Api';
import Loader from 'components/misc/Loader';
import NoDataFound from 'components/misc/NoDataFound';
import SaveButton from 'components/misc/Button';
import Alert from 'components/misc/Alert';
import { useAppContext } from 'providers/App';
import { useValidation } from 'helpers/Validation';

let timeout;

function Index(props) {

    const app = useAppContext();

    const formRef = useRef(null);

    const [state, setState] = useState({
        data: null,
        pages: 0,
        total: 0,
        totals: {},
        loading: true,
        filter: {
            page: 1,
        },
        refresh: false,
        loadingSave: false
    });

    const [validations, setValidations] = useValidation();

    const [categories, setCategories] = useState([]);
    const [expandedCategories, setExpandedCategories] = useState([]);

    useEffect(() => {

        if (!state.data) {
            loading(true);
        }

        Api.get('articles/categorisation', {
            params: {
                id: props.id,
                ...state.filter,
            }
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data,
                filter: {
                    ...prev.filter,
                    ...res.data.filter,
                },
            }));

            if (res.data.pages > 0 && state.filter.page > res.data.pages) {
                return handlePage({ selected: res.data.pages - 1 });
            }

            loading(false);
            sorting(false);
        });
    }, [props.id, state.refresh]);

    useEffect(() => {
        Api.get('articles/categories')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setCategories(res.data);
                }
            });
    }, []);

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }));
    }

    const sorting = (sorting) => {
        setState(prev => ({
            ...prev,
            sorting: Boolean(sorting)
        }));
    }

    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout);

        if (typeof key === 'object') {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    ...key
                },
            }));
        } else {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    [key]: val,
                },
            }));
        }

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: 1
                },
                refresh: new Date().getTime()
            }));
        }, delay);
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            refresh: new Date()
        }));
    }

    const handleSort = (sort, order) => {
        setState(prev => ({
            ...prev,
            sorting: true,
            filter: {
                ...prev.filter,
                sort: sort,
                order: order,
                page: 1,
            },
            refresh: new Date().getTime()
        }));
    }

    const handleSave = (e) => {
        e.preventDefault();

        if (state.loadingSave) {
            return;
        }

        setState(prev => ({
            ...prev,
            loadingSave: true,
        }));

        let data = new FormData(formRef.current);

        data.append('id', props.id);
        data.append('master_cat_id', state.data.master_cat_id || '');

        Api.post('articles/categorisation', data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {

            if (typeof props.onSuccess === 'function') {
                props.onSuccess(res.data);
            }

            app.showSuccess();
        }).catch(error => {
            const _err = error.response;

            if (_err && _err.status && _err.status === 422) {
                setValidations(_err.data.errors)
            } else {
                app.showError();
            }

        }).finally(() => {
            setState(prev => ({
                ...prev,
                loadingSave: false,
            }));
        });
    }

    // const renderCategories = (categories, level = 0) => {
    //     return categories.map(category =>
    //         <Fragment key={category.id}>
    //             <Row>
    //                 <Col style={{ paddingLeft: (level * 20) + 'px', display: 'flex', alignItems: 'center' }}>
    //                     <Form.Check
    //                         type="checkbox"
    //                         id={'acbc-' + category.id}
    //                         name="categories[]"
    //                         value={category.id}
    //                         label={category?.translation?.name || category.name}
    //                         disabled={category.children.length > 0}
    //                         checked={isCategorySelected(category.id)}
    //                         onChange={e => toggleCategory(category)}
    //                     />
    //                     {isCategorySelected(category.id) &&
    //                         <span className="master" style={{ marginLeft: '10px', }} onClick={e => setMasterCategory(category)}>
    //                             {isCategoryMaster(category.id) ? <HouseDoorFill /> : ''}
    //                         </span>
    //                     }
    //                 </Col>
    //             </Row>
    //             {renderCategories(category.children, level + 1)}
    //         </Fragment>
    //     );
    // }

    const renderCategories = (categories, level = 0) => {
        let output = [];

        let store = null;

        categories.map(category => {
            if (level === 0 && category.store_id !== store && category.store_name) {
                output.push(
                    <div style={{
                        background: 'cornsilk',
                        color: '#505050',
                        padding: '3px 10px',
                        margin: '3px 0'
                    }}>
                        {category.store_name}
                    </div>
                );
            }

            output.push(
                <React.Fragment key={category.id}>
                    <div style={{ display: level === 0 || isCategoryExpanded(category.parent) ? 'flex' : 'none', alignItems: 'center', paddingLeft: (level * 20) + 'px', }}>

                        <span
                            style={{ marginRight: '3px', marginBottom: '3px', cursor: 'pointer', width: '16px' }}
                            onClick={e => expandCategory(category)}
                        >
                            {category.children.length > 0
                                ?
                                isCategoryExpanded(category) ? <CaretDownFill size="13" /> : <CaretRight size="13" />
                                :
                                ''
                            }
                        </span>

                        <Form.Check
                            type="checkbox"
                            id={Math.random().toString() + category.id}
                            name="categories[]"
                            value={category.id}
                            label={category?.translation?.name || category.name}
                            checked={isCategorySelected(category.id)}
                            disabled={isCategorySelected(category.parent_id)}
                            onChange={e => toggleCategory(category)}
                            style={{ color: hasChildCategorySelected(category) ? '#084298' : 'black' }}
                        />

                        {isCategorySelected(category.id) &&
                            <span className="master" style={{ marginLeft: '10px', }} onClick={e => setMasterCategory(category)}>
                                {isCategoryMaster(category.id) ? <HouseDoorFill /> : ''}
                            </span>
                        }
                    </div>
                    <div style={{ display: isCategoryExpanded(category) ? 'initial' : 'none' }}>
                        {renderCategories(category.children, level + 1)}
                    </div>
                </React.Fragment>
            );

            store = category.store_id;
        });

        return output;
    }

    const toggleCategory = category => {
        if (isCategorySelected(category.id)) {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    categories: prev.data.categories.filter(c => Number(c.id) !== Number(category.id)),
                    master_cat_id: isCategoryMaster(category.id) ? null : prev.data.master_cat_id,
                }
            }));
        } else {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    categories: prev.data.categories.concat(category),
                    master_cat_id: prev.data.categories.length === 0 ? category.id : prev.data.master_cat_id,
                }
            }));
        }
    }

    const isCategorySelected = id => {
        return Boolean(state.data.categories.find(c => Number(c.id) === Number(id)));
    }

    const isCategoryMaster = id => {
        return Number(state.data.master_cat_id) === Number(id);
    }

    const hasChildCategorySelected = category => {
        for (let i = 0; i < category.children.length; i++) {
            let child = category.children[i];

            if (isCategorySelected(child.id)) {
                return true;
            }

            if (hasChildCategorySelected(child)) {
                return true;
            }
        }

        return false;
    }

    const setMasterCategory = category => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                master_cat_id: category.id,
            }
        }));
    }

    const expandCategory = (category, check = true) => {

        if (!category) {
            return;
        }

        if (check && isCategoryExpanded(category)) {
            setExpandedCategories(prev => prev.filter(i => Number(i) !== Number(category.id)))
        } else {
            setExpandedCategories(prev => prev.concat(Number(category.id)))
        }
    }

    const isCategoryExpanded = category => {

        if (!category) {
            return false;
        }

        let expanded = expandedCategories.indexOf(Number(category.id)) > -1;

        if (expanded) {
            return true;
        }

        return false;
    }

    return (

        <>
            {state.loading
                ?
                <Loader />
                :
                <>
                    {categories.length === 0
                        ?
                        <NoDataFound size="sm">
                            Няма създадени категории!
                            <div style={{ fontSize: '0.85rem' }}>
                                Създайте категории и добавете артикула към тях.
                            </div>
                        </NoDataFound>
                        :
                        <div className="categories">
                            <Alert>
                                Категорията маркирана с <span className="master" >
                                    <HouseDoorFill />
                                </span> е главна.
                            </Alert>
                            <form ref={formRef}>
                                {renderCategories(categories)}
                            </form>
                        </div>
                    }
                    <div
                        className="mt-3"
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <SaveButton
                            loading={state.loadingSave}
                            className="save"
                            onClick={handleSave}
                        />
                    </div>
                </>
            }
        </>
    )
}

export default Index;