import { Spinner } from 'react-bootstrap';

function Loader(props) {
    return (
        <div className={props.className} style={{ textAlign: 'center' }}>
            <Spinner
                as="span"
                animation="border"
                size="md"
                role="status"
                aria-hidden="true"
            />
        </div>
    )
}

export default Loader;