import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import moment from 'moment'
import { Modal, Button, Tabs, Tab, FloatingLabel, Form, Row, Col } from 'react-bootstrap';

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//providers
import { useLanguageContext } from 'providers/Language'

//misc
import SaveButton from 'components/misc/Button'
import Info from 'components/modals/Info';

function AddOrEdit(props, ref) {

    const langs = useLanguageContext();

    const defaultData = {
        langs: {}
    }

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        edit: false,
        data: defaultData,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null
    });

    const [companies, setCompanies] = useState([]);

    const [validations, setValidations] = useValidation();

    const validationModalRef = useRef(null);

    useImperativeHandle(ref, () => ({
        add: () => {
            handleShow();
        },
        edit: (id) => {
            edit(id);
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        }
    }));

    useEffect(() => {
        loadData();
    }, [state.edit]);

    useEffect(() => {

        Api.get('stores/companies')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setCompanies(res.data);
                }
            });

    }, []);

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const handleShow = () => {
        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
        }));
        show();
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
        }));

        setValidations(null);

        hide();
    }

    const edit = id => {
        setState(prev => ({
            ...prev,
            id: id,
            edit: new Date().getTime()
        }));

        show();
    }

    const loadData = () => {
        if (!state.id) {
            return;
        }

        Api.get('stores/edit?id=' + state.id)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data
                }))
            });
    }


    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = state.id ? 'stores/update' : 'stores/add';

        let data = new FormData(e.target);

        if (state.id) {
            data.append('id', state.id)
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (typeof state.onSuccess === 'function') {
                state.onSuccess(res.data)
            }

            hide()
        }).catch(error => {
            const _err = error.response;
            if (_err && _err.status && _err.status === 422) {
                hide(() => {
                    let modal = validationModalRef.current;

                    if (modal) {
                        modal.open();

                        modal.onClose(() => {
                            setValidations(_err.data.errors)
                            show();
                        });
                    }
                });
            }

        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }));
        });
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleDateChange = e => {
        const name = e.target.name + '_ymd'
        const value = e.target.value

        setState(name, value, 'data')
    }

    return (
        <>
            <Info
                ref={validationModalRef}
                mainMessage="Моля, попълнете всички задължителни полета, означени с червен цвят!"
            />

            <Modal size="lg" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <form onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Търговски обект
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Tabs
                            defaultActiveKey={langs.data[0].id}
                            className="mb-3"
                            transition={false}
                        >
                            {langs.data.map(lang =>
                                <Tab key={lang.id} eventKey={lang.id} title={lang.name} tabClassName={validations && validations.langs && validations.langs[lang.id] ? 'validate' : ''}>
                                    <Row>
                                        <Col>
                                            <FloatingLabel label="Вътрешно наименование" className="mb-3">
                                                <Form.Control
                                                    type="text"
                                                    name={`langs[${lang.id}][name]`}
                                                    value={state.data.langs[lang.id]?.name || ''}
                                                    placeholder="Вътрешно наименование"
                                                    onChange={(e) => handleInputChange(e)}
                                                    isInvalid={Boolean(validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].name)}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].name && (validations.langs[lang.id].name[0] || validations.langs[lang.id].name)}
                                                </Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                        <Col>
                                            <FloatingLabel label="Публично наименование" className="mb-3">
                                                <Form.Control
                                                    type="text"
                                                    name={`langs[${lang.id}][public_name]`}
                                                    placeholder="Публично наименование"
                                                    onChange={(e) => handleInputChange(e)}
                                                    value={state.data.langs[lang.id]?.public_name || ''}
                                                    isInvalid={Boolean(validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].public_name)}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].public_name && (validations.langs[lang.id].public_name[0] || validations.langs[lang.id].public_name)}
                                                </Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <FloatingLabel label="Адрес" className="mb-3">
                                                <Form.Control
                                                    type="text"
                                                    name={`langs[${lang.id}][public_address]`}
                                                    placeholder="Адрес"
                                                    onChange={(e) => handleInputChange(e)}
                                                    value={state.data.langs[lang.id]?.public_address || ''}
                                                    isInvalid={Boolean(validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].public_address)}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].public_address && (validations.langs[lang.id].public_address[0] || validations.langs[lang.id].public_address)}
                                                </Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                        <Col>
                                            <FloatingLabel label="Лице за контакти" className="mb-3">
                                                <Form.Control
                                                    type="text"
                                                    name={`langs[${lang.id}][public_contact]`}
                                                    placeholder="Лице за контакти"
                                                    onChange={(e) => handleInputChange(e)}
                                                    value={state.data.langs[lang.id]?.public_contact || ''}
                                                    isInvalid={Boolean(validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].public_contact)}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].public_contact && (validations.langs[lang.id].public_contact[0] || validations.langs[lang.id].public_contact)}
                                                </Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <FloatingLabel label="Е-поща" className="mb-3">
                                                <Form.Control
                                                    name="public_email"
                                                    value={state.data.public_email || ''}
                                                    onChange={handleInputChange}
                                                    isInvalid={Boolean(validations && validations.public_email)}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {validations && validations.public_email && (validations.public_email[0] || validations.public_email)}
                                                </Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                        <Col>
                                            <FloatingLabel label="Фирма за фактури*">
                                                <Form.Select
                                                    name="company_id"
                                                    value={state.data.company_id || ''}
                                                    onChange={handleInputChange}
                                                    isInvalid={Boolean(validations && validations.company_id)}
                                                >
                                                    {companies.map(c =>
                                                        <option key={c.id} value={c.id}>{c.pop_name}</option>
                                                    )}
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    {validations && validations.company_id && (validations.company_id[0] || validations.company_id)}
                                                </Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                    </Row>

                                </Tab>
                            )}
                        </Tabs>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            className="save"
                        />
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default forwardRef(AddOrEdit);
