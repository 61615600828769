import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import { Modal, Button, Col, Row, FloatingLabel, Form } from 'react-bootstrap';

//helpers
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//providers
import { useNavigate } from 'react-router-dom';
import { useAppContext } from 'providers/App';

//misc
import SaveButton from 'components/misc/Button'

function SendDirect(props, ref) {

    const app = useAppContext();
    const navigate = useNavigate();

    const defaultData = {

    }

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        refresh: false,
        data: defaultData,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null,
        onClose: null,
    });

    const [validations, setValidations] = useValidation();

    const formRef = useRef(null);

    useImperativeHandle(ref, () => ({
        open: (email) => {
            open(email);
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        }
    }));

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const open = (email) => {
        setState(prev => ({
            ...prev,
            data: {
                email: email
            },
        }));

        show();
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
        }));

        setValidations(null);

        if (typeof state.onClose === 'function') {
            hide(state.onClose);
        } else {
            hide();
        }
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            refresh: new Date().getTime(),
        }));
    }

    const handleSave = e => {
        e.preventDefault();

        if (state.loading) {
            return;
        }

        state.onSuccess({
            email: state.data.email,
        });

        hide(state.onClose);

    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    return (
        <>

            <Modal size="md" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <form ref={formRef} onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Изпращане
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Row>
                            <Col>
                                <FloatingLabel label="Получател">
                                    <Form.Control
                                        type="text"
                                        name={`email`}
                                        autoComplete="new-password"
                                        value={state.data.email || ''}
                                        placeholder="Получател"
                                        onChange={(e) => handleInputChange(e)}
                                        isInvalid={Boolean(validations?.email)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {validations?.email && (validations?.email[0] || validations?.email)}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loadingBase}
                            disabled={state.loading}
                            className="save"
                            text="Продължи"
                        />
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default forwardRef(SendDirect);
