import { Button } from 'react-bootstrap';
import MenuOptions from 'components/misc/MenuOptions'
import Options from './Options';
import { useEffect } from 'react';

function Item(props) {
    const data = props;

    const handleAdd = id => {
        props.handleAdd(id)
    }

    const handleEdit = id => {
        props.handleEdit(id)
    }

    return (
        <>
            <tr
                key={data.id}>

                <td data-id={data.id} data-parent={data.parent_id} style={{ paddingLeft: 30 * props.level + 'px' }}>
                    {data.translation?.name}
                </td>

                <td className="text-center">
                    <MenuOptions>
                        <Options
                            data={data}
                            handleEdit={handleEdit}
                        />
                    </MenuOptions>
                </td>
            </tr>

            {data.children && data.children.map((item, index) =>
                <Item
                    handleEdit={handleEdit}
                    handleAdd={handleAdd}
                    key={index}
                    index={index}
                    level={props.level + 1}
                    {...item}
                />)}
        </>
    )
}

export default Item;
