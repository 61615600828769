import { useEffect, useRef, useState } from "react";
import { Alert, Button, Col, Form, InputGroup, Table, Card } from "react-bootstrap";
import { InfoCircleFill } from "react-bootstrap-icons";
import Api from "helpers/Api";
import Loader from "components/misc/Loader";
import TableHeader from "components/misc/TableHeader";
import TableCol from "components/misc/TableCol";
import PriceValue from "components/partials/PriceValue";
import NoDataFound from "components/misc/NoDataFound";
import Pagination from "components/misc/Pagination";
import SaveButton from "components/misc/Button";
import { calcExtraPrice } from "helpers/Price";
import { useValidation } from "helpers/Validation";
import { useAppContext } from "providers/App";

let timeout;

function Index(props) {

    const app = useAppContext();

    const tableRef = useRef(null);

    const [state, setState] = useState({
        sorting: false,
        loading: true,
        loadingSave: false,
        refresh: false,
        setFilter: false,
        data: [],
        pages: 0,
        total: 0,
        filter: {
            page: 1,
        },
    });

    const [attributes, setAttributes] = useState({});

    // console.log(attributes);

    const [validations, setValidations] = useValidation();

    useEffect(() => {
        loadData();
    }, [props.id]);

    useEffect(() => {
        if (state.refresh) {
            loadData();
        }
    }, [state.refresh]);

    const loadData = () => {

        loading(true);

        let url = 'articles/price-rules';

        Api.post(url, {
            id: props.id,
            ...state.filter
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data.items,
                total: res.data.total,
                pages: res.data.pages,
                filter: {
                    ...prev.filter,
                    ...res.data.filter,
                }
            }));

            if (res.data.pages > 0 && state.filter.page > res.data.pages) {
                return handlePage({ selected: res.data.pages - 1 });
            }

            loading(false);
            sorting(false);
        });
    }

    const handleInputChange = (key, val, index) => {
        let row = getRow(index);

        let checked = getAttribute(row, 'selected');

        if (!checked) {
            return;
        }

        setState(prev => ({
            ...prev,
            data: Object.values({
                ...prev.data,
                [index]: {
                    ...prev.data[index],
                    row: {
                        ...prev.data[index].row,
                        [key]: val
                    }
                }
            })
        }));


        // да запазим въведената цена за артикула
        // дори и при смяна на филтъра или страницата

        if (row) {
            setAttribute(row.id, key, val);
        }
    }

    const handleCheckboxChange = (key, val, index) => {
        setState(prev => ({
            ...prev,
            data: Object.values({
                ...prev.data,
                [index]: {
                    ...prev.data[index],
                    [key]: val
                }
            })
        }));

        let row = getRow(index);

        if (row) {
            setAttribute(row.id, key, val);
        }
    }

    const getRow = index => {
        return state.data.find((k, i) => Number(i) === Number(index));
    }

    const getAttribute = (obj, key) => {
        let attrs = attributes[obj.id];

        if (attrs && attrs[key]) {
            return attrs[key];
        }

        let val = obj[key];

        return val;
    }

    const setAttribute = (id, key, val) => {
        setAttributes(prev => ({
            ...prev,
            [id]: prev[id] ? {
                ...prev[id],
                [key]: val
            } : {
                [key]: val
            }
        }));
    }

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }));
    }

    const sorting = (sorting) => {
        setState(prev => ({
            ...prev,
            sorting: Boolean(sorting)
        }));
    }

    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout);

        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [key]: val,
            },
        }));

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: 1
                },
                refresh: new Date().getTime()
            }));
        }, delay);
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            refresh: new Date().getTime()
        }));
    }

    const handleSort = (sort, order) => {
        setState(prev => ({
            ...prev,
            sorting: true,
            filter: {
                ...prev.filter,
                sort: sort,
                order: order,
                page: 1,
            },
            refresh: new Date().getTime()
        }));
    }

    const getPriceDiff = (row) => {
        let prefered = getAttribute(row, 'prefered_price');

        let price = prefered;

        if (!price) {
            price = getAttribute(row, 'price');
        }

        let base = getAttribute(row, 'sell_price');

        return price - base;
    }

    const getPriceDiffPerc = (row) => {
        let prefered = getAttribute(row, 'prefered_price');

        let price = prefered;

        if (!price) {
            price = getAttribute(row, 'price');
        }

        let base = getAttribute(row, 'sell_price');

        return calcExtraPrice(base, price);
    }

    const showPriceRule = id => {
        props.showPriceRule(id);
    }

    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loadingSave: true,
        }));

        let url = 'articles/set-price-rules';

        let data = {
            id: props.id,
            rules: JSON.stringify(attributes)
        };

        Api.post(url, data, {
            headers: {
                'content-type': 'application/json'
            }
        }).then(res => {
            app.showSuccess();
        }).catch(error => {
            const _err = error.response;

            if (_err && _err.status && _err.status === 422) {
                setValidations(_err.data.errors)
            } else {
                app.showError();
            }

        }).finally(() => {
            setState(prev => ({
                ...prev,
                loadingSave: false,
            }));
        });
    }

    return (
        <>
            <Card >
                <Card.Header>
                    Ценови правила
                </Card.Header>
                <Card.Body>
                    {!props.id
                        ?
                        <Alert style={{ margin: 0 }}>
                            Ще можете да изберете ценови правила след като създадете артикула
                        </Alert>
                        :
                        <>
                            <div style={{ marginBottom: '20px' }}>
                                <InputGroup size="sm">
                                    <Form.Control
                                        type="search"
                                        name="search"
                                        value={state.filter.search || ''}
                                        size="sm"
                                        onChange={e => handleSearch(e.target.name, e.target.value)}
                                        placeholder="Търсене"
                                    />
                                </InputGroup>
                            </div>

                            {state.loading && !state.sorting ?
                                <div style={{ padding: '100px 0' }}>
                                    <Loader />
                                </div>
                                :
                                <>
                                    {state.data.length === 0 &&
                                        <Alert style={{ margin: 0 }}>
                                            Няма намерена информация
                                        </Alert>
                                    }

                                    {state.data.length > 0 &&
                                        <>
                                            <Table className="xxl" striped hover ref={tableRef}>
                                                <TableHeader
                                                    tableRef={tableRef}
                                                    activeSortKey={state.filter.sort}
                                                    activeSortDir={state.filter.order}
                                                    onSort={(col, dir) => handleSort(col, dir)}
                                                >
                                                    <TableCol className="text-center" sortKey="" style={{ width: '3%' }}></TableCol>
                                                    <TableCol sortKey="" style={{ width: '3%' }}>ID</TableCol>
                                                    <TableCol sortKey="" style={{ width: '15%' }}>Име</TableCol>
                                                    <TableCol className="text-center" sortKey="" style={{ width: '10%' }}>Продажна <br /> цена</TableCol>
                                                    <TableCol className="text-center" sortKey="" style={{ width: '10%' }}>Изчислена <br /> цена</TableCol>
                                                    <TableCol className="text-center" sortKey="" style={{ width: '13%' }}>Предпочитана <br /> цена</TableCol>
                                                    <TableCol className="text-center" sortKey="" style={{ width: '10%' }}>Разлика</TableCol>
                                                    <TableCol className="text-center" sortKey="" style={{ width: '10%' }}>Разлика(%)</TableCol>
                                                    <TableCol className="text-center" sortKey="" style={{ width: '10%' }}>Статус</TableCol>
                                                    <TableCol className="text-center" sortKey="" style={{ width: '10%' }}>Включен</TableCol>
                                                    <TableCol className="options" style={{ width: '5%' }}>Опции</TableCol>
                                                </TableHeader>
                                                <tbody>
                                                    {state.data.map((c, index) =>
                                                        <tr
                                                            key={'c-' + c.id}
                                                            style={{ opacity: getAttribute(c, 'selected') ? 1 : 0.4 }}
                                                        >
                                                            <td className="text-center">
                                                                <input
                                                                    type="checkbox"
                                                                    value={1}
                                                                    checked={getAttribute(c, 'selected') ? true : false}
                                                                    onChange={e => handleCheckboxChange('selected', e.target.checked, index)}
                                                                />
                                                            </td>
                                                            <td>
                                                                {c.id || ''}
                                                            </td>
                                                            <td>
                                                                <span className="link" onClick={e => showPriceRule(c.id)}>
                                                                    {c.name}
                                                                </span>
                                                            </td>
                                                            <td className="text-center">
                                                                {c.row?.sell_price !== null &&
                                                                    <>
                                                                        {c.row.article?.currency?.prefix} <PriceValue>{c.row.sell_price}</PriceValue> {c.row.article?.currency?.sufix}
                                                                    </>
                                                                }
                                                            </td>
                                                            <td className="text-center">
                                                                {c.row?.price !== null &&
                                                                    <>
                                                                        {c.row.article?.currency?.prefix} <PriceValue pad={2}>{c.row?.price}</PriceValue> {c.row.article?.currency?.sufix}
                                                                    </>
                                                                }
                                                            </td>
                                                            <td className="text-center">
                                                                <InputGroup size="sm" style={{ minWidth: '55px' }}>
                                                                    <Form.Control
                                                                        type="number"
                                                                        step="0.0001"
                                                                        value={getAttribute(c.row, 'prefered_price') || ''}
                                                                        onChange={e => handleInputChange('prefered_price', e.target.value, index)}
                                                                        isInvalid={Boolean(validations && validations.rules && validations.rules[index] && validations.rules[index].prefered_price && (validations.rules[index].prefered_price[0] || validations.rules[index].prefered_price))}
                                                                        size="sm"
                                                                        className="price-input"
                                                                    />

                                                                    {(c.row?.article?.currency) &&
                                                                        <InputGroup.Text>
                                                                            {c.row.article?.currency?.prefix || c.row.article?.currency?.sufix || '-'}
                                                                        </InputGroup.Text>
                                                                    }

                                                                    {/* <Form.Control.Feedback type="invalid">
                                                                        {validations && validations.rows && validations.rows[index] && validations.rows[index].amount && (validations.rows[index].amount[0] || validations.rows[index].amount)}
                                                                    </Form.Control.Feedback> */}
                                                                </InputGroup>
                                                            </td>
                                                            <td className={`text-center ${getPriceDiff(c.row) < 0 && 'negative-value'}`}>
                                                                {getPriceDiff(c.row) !== null &&
                                                                    <>
                                                                        {c.row.article?.currency?.prefix} <PriceValue pad={2}>{getPriceDiff(c.row)}</PriceValue> {c.row.article?.currency?.sufix}
                                                                    </>
                                                                }
                                                            </td>
                                                            <td className={`text-center ${getPriceDiffPerc(c.row) < 0 && 'negative-value'}`}>
                                                                {getPriceDiffPerc(c.row) !== null &&
                                                                    <>
                                                                        <PriceValue pad={2}>{getPriceDiffPerc(c.row)}</PriceValue>%
                                                                    </>
                                                                }
                                                            </td>
                                                            <td className={`text-center ${c.status === 'active' ? 'positive-value' : c.status === 'inactive' ? 'negative-value' : ''}`}>
                                                                {c.status_name}
                                                            </td>
                                                            <td className={`text-center ${c.selected && 'positive-value'}`}>
                                                                {c.selected ? 'ДА' : 'НЕ'}
                                                            </td>
                                                            <td className="options">
                                                                <Button variant="secondary" size="sm" onClick={e => showPriceRule(c.id)}>
                                                                    <InfoCircleFill />
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </Table>

                                            <Pagination
                                                className="mt-3"
                                                page={state.filter.page}
                                                pages={state.pages}
                                                total={state.total}
                                                handlePage={handlePage}
                                            />

                                            <div
                                                className="mt-3"
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <SaveButton
                                                    loading={state.loadingSave}
                                                    className="save"
                                                    onClick={e => handleSave(e)}
                                                />
                                            </div>
                                        </>
                                    }

                                </>
                            }
                        </>
                    }
                </Card.Body>
            </Card>
        </>
    )
}

export default Index;
