import { useEffect, useRef, useState } from "react";
import { Table, OverlayTrigger, Tooltip, Card } from "react-bootstrap";
import Api from "helpers/Api";
import Loader from "components/misc/Loader";
import TableHeader from "components/misc/TableHeader";
import TableCol from "components/misc/TableCol";
import PriceValue from "components/partials/PriceValue";
import NoDataFound from "components/misc/NoDataFound";
import Pagination from "components/misc/Pagination";
import Alert from "components/misc/Alert";
import FilePreview from '../../../partials/FilePreview';
import Title from 'components/misc/Title';
import SaveButton from 'components/misc/Button';
import { buildUrl } from "helpers/Url";
import { Check } from "react-bootstrap-icons";

let timeout;

function Index(props) {

    const tableRef = useRef(null);

    const [state, setState] = useState({
        sorting: false,
        loading: true,
        loadingButton: false,
        refresh: false,
        setFilter: false,
        data: [],
        pages: 0,
        total: 0,
        filter: {
            page: 1,
        },
    });

    useEffect(() => {
        loadData();
    }, [props.id]);

    useEffect(() => {
        if (state.refresh) {
            loadData();
        }
    }, [state.refresh]);

    const loadData = () => {

        loading(true);

        let url = 'articles/related-articles';

        Api.post(url, {
            id: props.id,
            ...state.filter
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data.items,
                total: res.data.total,
                pages: res.data.pages,
                filter: {
                    ...prev.filter,
                    ...res.data.filter,
                }
            }));

            if (res.data.pages > 0 && state.filter.page > res.data.pages) {
                return handlePage({ selected: res.data.pages - 1 });
            }

            loading(false);
            sorting(false);
        });
    }

    const handleInputChange = (key, val, index) => {


    }

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }));
    }

    const sorting = (sorting) => {
        setState(prev => ({
            ...prev,
            sorting: Boolean(sorting)
        }));
    }

    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout);

        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [key]: val,
            },
        }));

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: 1
                },
                refresh: new Date().getTime()
            }));
        }, delay);
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            refresh: new Date().getTime()
        }));
    }

    const handleSort = (sort, order) => {
        setState(prev => ({
            ...prev,
            sorting: true,
            filter: {
                ...prev.filter,
                sort: sort,
                order: order,
                page: 1,
            },
            refresh: new Date().getTime()
        }));
    }

    const refresh = () => {
        setState(prev => ({
            ...prev,
            refresh: new Date().getTime()
        }));
    }

    const setAsMaster = id => {
        setState(prev => ({
            ...prev,
            loadingButton: id
        }));

        Api.post('articles/set-as-master', {
            id: id
        }).then(res => {
            refresh();
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loadingButton: false
            }));
        });
    }

    return (
        <>
            <Alert>
                Тук се показват артикули, които са свързани по общ номер и групирани по референтен номер.
            </Alert>

            {/* <div style={{ marginBottom: '20px' }}>
                        <InputGroup size="sm">
                            <Form.Control
                                type="search"
                                name="search"
                                value={state.filter.search || ''}
                                size="sm"
                                onChange={e => handleSearch(e.target.name, e.target.value)}
                                placeholder="Търсене"
                            />
                        </InputGroup>
                    </div> */}

            {state.loading && !state.sorting
                ?
                <div style={{ padding: '100px 0' }}>
                    <Loader />
                </div>
                :
                <>
                    {Object.values(state.data).length === 0 &&
                        <NoDataFound />
                    }

                    {Object.values(state.data).length > 0 &&
                        <>
                            {Object.entries(state.data).map(group =>
                                <Card className="mb-3">
                                    <Card.Header>{group[0]}</Card.Header>
                                    <Card.Body>
                                        <Table className="xxl" striped hover ref={tableRef}>
                                            <TableHeader
                                                tableRef={tableRef}
                                                activeSortKey={state.filter.sort}
                                                activeSortDir={state.filter.order}
                                                onSort={(col, dir) => handleSort(col, dir)}
                                            >
                                                <TableCol sortable sortKey="id" style={{ width: '3%' }}>ID</TableCol>
                                                <TableCol>Снимка</TableCol>
                                                <TableCol sortable sortKey="article_name">Артикул</TableCol>
                                                <TableCol className="text-center" sortKey="">Главен</TableCol>
                                                <TableCol className="text-right" sortable sortKey="actual_price">
                                                    <Title title="Основна продажна цена">
                                                        ПЦ
                                                    </Title>
                                                </TableCol>
                                                <TableCol className="text-right" sortable sortKey="current_price">
                                                    <Title title="Текуща продажна цена за текущия търговски обект">
                                                        ТПЦ
                                                    </Title>
                                                </TableCol>
                                                <TableCol className="options" style={{ width: '15%' }}>Опции</TableCol>
                                            </TableHeader>
                                            <tbody>
                                                {group[1].map((c, index) =>
                                                    <tr
                                                        key={'c-' + c.id}
                                                    >

                                                        <td>
                                                            {c.id}
                                                        </td>
                                                        <td className="picture">
                                                            <OverlayTrigger
                                                                placement="right-start"
                                                                overlay={
                                                                    <Tooltip className="file-preview" id={'tooltip-img-' + c.id}>
                                                                        {/* <img src={buildUrl(c.thumbnail, { width: 200 })} loading="lazy" alt="" /> */}
                                                                        <FilePreview
                                                                            file={{
                                                                                url: buildUrl(c?.preview?.thumbnail_url, { width: 200 }),
                                                                                // type: c?.preview?.mime_type
                                                                            }}
                                                                        />
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                {/* <img src={buildUrl(c.thumbnail, { width: 40 })} loading="lazy" alt="" /> */}
                                                                <div>
                                                                    <FilePreview
                                                                        file={{
                                                                            url: buildUrl(c?.preview?.thumbnail_url, { width: 40 }),
                                                                            // type: c?.preview?.mime_type
                                                                        }}
                                                                        width={40}
                                                                    />
                                                                </div>
                                                            </OverlayTrigger>
                                                        </td>
                                                        <td>
                                                            {c.article_name}
                                                        </td>
                                                        <td className="text-center">
                                                            {c.is_master
                                                                ?
                                                                <Check />
                                                                :
                                                                ''
                                                            }
                                                        </td>
                                                        <td className="text-right">
                                                            {c?.currency?.prefix} <PriceValue>{parseFloat(c.actual_price || 0)}</PriceValue> {c?.currency?.sufix}
                                                        </td>
                                                        <td className="text-right">
                                                            {c?.currency?.prefix} <PriceValue>{parseFloat(c.current_price || 0)}</PriceValue> {c?.currency?.sufix}
                                                        </td>
                                                        <td className="options">
                                                            <SaveButton
                                                                variant="primary"
                                                                size="sm"
                                                                loading={state.loadingButton === c.id}
                                                                disabled={state.loadingButton === c.id}
                                                                className="save"
                                                                onClick={e => setAsMaster(c.id)}
                                                            >
                                                                Направи главен
                                                            </SaveButton>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            )}

                            <Pagination
                                className="mt-3"
                                page={state.filter.page}
                                pages={state.pages}
                                total={state.total}
                                handlePage={handlePage}
                            />
                        </>
                    }

                </>
            }
        </>
    )
}

export default Index;
