import React, { useEffect, useRef, useState } from 'react';
import Api from 'helpers/Api';
import { useQuery } from 'helpers/Url';
import { useLocation, useNavigate, Link, useParams } from 'react-router-dom';
import { Button, Table, FloatingLabel, Form, Row, Col, Container, InputGroup, Card, Dropdown } from 'react-bootstrap';
import { ArrowLeftCircle, TrashFill, ArrowsMove, Printer, Envelope, InfoCircle, Files, XCircle, FileArrowDownFill } from 'react-bootstrap-icons';
import { useNestedState } from 'helpers/NestedState';
import { useCurrencyContext } from 'providers/Currency';
import { useValidation } from 'helpers/Validation';
import { useAuthDataContext } from 'providers/Auth';
import { sortable } from 'react-sortable';

import Layout from 'components/layout/Layout';
import SendDirect from './partials/SendDirect';
import SendMail from './partials/SendMail';
import View from './view/View';

//misc
import Loader from 'components/misc/Loader';
import moment from 'moment';
import Alert from 'components/misc/Alert';
import Autocomplete from 'components/misc/Autocomplete';
import SaveButton from 'components/misc/Button';
import { addExtraPrice, applyDiscountOrMarkupPrice, calcDiscount, calcExtraPrice, convertFromRateToRate, isNumeric, removeExtraPrice, vatPrice } from 'helpers/Price';
import Info from 'components/misc/Info';
import Error from 'components/modals/Error';
import Question from 'components/modals/Question';
import Refs from 'Refs';
import PriceValue from 'components/partials/PriceValue';
import DatePicker from 'components/partials/filter/DatePicker';

import { CREDIT_NOTE, DEBIT_NOTE } from 'constants/invoices';
import Vies from '../clients/partials/Vies';
import { useAppContext } from 'providers/App';

function SortableItem(props) {
    return (
        <tr {...props}>
            {props.children}
        </tr>
    )
}

const SortItem = sortable(SortableItem);

let timeout;

function Index() {

    const app = useAppContext();
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const query = useQuery();

    const auth = useAuthDataContext();
    const currencies = useCurrencyContext();

    const defaultData = {
        with_tax: 0,
        currency_id: currencies.getDefault()?.id || '',
        currency_rate: currencies.getDefault()?.rate || '',
        currency: currencies.getDefault(),
        date: moment().format('YYYY-MM-DD'),
        term_date: moment().format('YYYY-MM-DD'),
        payment_date: moment().format('YYYY-MM-DD'),
        rows: [{}],
        receiver_identity_num: 9999999999,
        allow_close: true,
    };

    const [state, setState] = useNestedState({
        loading: false,
        loadingBase: false,
        loadingExit: false,
        loadingSend: false,
        dataLoading: false,
        data: defaultData,
        timeout: null
    });

    const [types, setTypes] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [taxGroups, setTaxGroups] = useState([]);

    const [vies, setVies] = useState({
        loading: false,
    });

    const [validations, setValidations] = useValidation();

    const formRef = useRef(null);
    const saveAndSendModalRef = useRef(null);
    const sendMailModalRef = useRef(null);
    const viewModalRef = useRef(null);
    const errorModalRef = useRef(null);
    const deleteModalRef = useRef(null);
    const viesModalRef = useRef(null);

    useEffect(() => {
        if (params.id) {
            loadData();
        }
    }, [params.id]);

    // зареждане на данни от поръчка/сметка
    useEffect(() => {
        if (params.id) {
            return;
        }

        // от сметка
        if (query.get('from_account_id')) {
            // кредитно от сметка
            if (query.get('cn')) {
                loadCreditNoteFromAccount();
            } else {
                loadDataFromAccount();
            }
        }

        if (query.getAll('from_account_id[]').length) {
            loadDataFromAccount();
        }

        // от фактура
        if (query.get('from_invoice_id')) {
            // кредитно от фактура
            if (query.get('cn')) {
                loadCreditNoteFromInvoice();
            }
            // фактура от фактура (клонирана)
            else {
                loadInvoiceFromInvoice();
            }
        }

        // от клиентска заявка
        if (query.get('from_order_id')) {
            loadDataFromOrder();
        }

        // от зареждане
        if (query.get('from_load_id')) {
            loadDataFromStoreLoad();
        }

        // от прехвърляне
        if (query.get('from_move_id')) {
            loadDataFromStoreMove();
        }

    }, [location]);

    useEffect(() => {
        if (!params.id) {
            let company = auth.getUser().getStore()?.company;

            if (company) {
                handleChangeCompany(company);
            }
        }
    }, []);

    useEffect(() => {
        Api.get('invoices/types')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setTypes(res.data);
                }
            });

        Api.get('invoices/tax-groups')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setTaxGroups(res.data);
                }
            });

        Api.get('invoices/payment-methods')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setPaymentMethods(res.data);
                }
            });
    }, []);

    useEffect(() => {
        if (state.data.rows.length === 0) {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    rows: [{}]
                }
            }));
        }
    }, [state.data.rows]);

    const loadData = () => {

        loading(true);

        let url = 'invoices/show';

        Api.get(url, {
            params: {
                id: params.id
            }
        }).then(res => {
            handleResponse(res);
        }).catch(err => {
            handleError(err);
        }).finally(() => {
            loading(false);
        })
    }

    const loadDataFromAccount = () => {
        loading(true);

        let url = 'invoices/from-account';

        Api.get(url, {
            params: {
                account_id: query.get('from_account_id') || query.getAll('from_account_id[]'),
                rows: query.getAll('rows[]'),
                type_id: query.get('type_id')
            }
        }).then(res => {
            handleResponse(res);
        }).catch(err => {
            handleError(err);
        }).finally(() => {
            loading(false);
        })
    }

    const loadCreditNoteFromInvoice = () => {
        loading(true);

        let url = 'invoices/credit-note-from-invoice';

        Api.get(url, {
            params: {
                invoice_id: query.get('from_invoice_id'),
                rows: query.getAll('rows[]')
            }
        }).then(res => {
            handleResponse(res);
        }).catch(err => {
            handleError(err);
        }).finally(() => {
            loading(false);
        })
    }

    const loadInvoiceFromInvoice = () => {
        loading(true);

        let url = 'invoices/invoice-from-invoice';

        Api.get(url, {
            params: {
                invoice_id: query.get('from_invoice_id'),
                rows: query.getAll('rows[]')
            }
        }).then(res => {
            handleResponse(res);
        }).catch(err => {
            handleError(err);
        }).finally(() => {
            loading(false);
        })
    }

    const loadDataFromOrder = () => {
        loading(true);

        let url = 'invoices/from-order';

        Api.get(url, {
            params: {
                order_id: query.get('from_order_id'),
                rows: query.getAll('rows[]')
            }
        }).then(res => {
            handleResponse(res);
        }).catch(err => {
            handleError(err);
        }).finally(() => {
            loading(false);
        })
    }

    const loadDataFromStoreLoad = () => {
        loading(true);

        let url = 'invoices/from-storeload';

        Api.get(url, {
            params: {
                load_id: query.get('from_load_id'),
                rows: query.getAll('rows[]')
            }
        }).then(res => {
            handleResponse(res);
        }).catch(err => {
            handleError(err);
        }).finally(() => {
            loading(false);
        })
    }

    const loadDataFromStoreMove = () => {
        loading(true);

        let url = 'invoices/from-storemove';

        Api.get(url, {
            params: {
                move_id: query.get('from_move_id'),
                rows: query.getAll('rows[]')
            }
        }).then(res => {
            handleResponse(res);
        }).catch(err => {
            handleError(err);
        }).finally(() => {
            loading(false);
        })
    }

    const loadCreditNoteFromAccount = () => {
        loading(true);

        let url = 'invoices/credit-note-from-account';

        Api.get(url, {
            params: {
                account_id: query.get('from_account_id'),
                rows: query.getAll('rows[]')
            }
        }).then(res => {
            handleResponse(res);
        }).catch(err => {
            handleError(err);
        }).finally(() => {
            loading(false);
        })
    }

    const handleResponse = res => {
        let data = res.data;

        if (data.receiver_identity_num === null) {
            data.receiver_identity_num = defaultData.receiver_identity_num;
        }

        let rows = data.rows || [];

        // data.currency_rate = data.currency?.rate;

        rows = rows.map(row => {

            return row;
        });

        data.rows = rows;

        setState(prev => ({
            ...prev,
            data: data,
        }));
    }

    const handleError = error => {
        console.log(error);

        const _err = error.response;

        if (_err && _err.status) {
            if (_err.status === 422) {
                setValidations(_err.data.errors);
            } else {
                let modal = errorModalRef.current;

                if (modal) {
                    modal.open(_err.data.error || _err.data.message);

                    modal.onClose(() => {
                        navigate(-1, {
                            replace: true
                        });
                    });
                }
            }
        }
    }

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            dataLoading: Boolean(loading)
        }));
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleCheckboxChange = e => {
        const name = e.target.name;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: prev.data[name] ? false : true
            }
        }));
    }

    const handleDiscountChange = e => {
        let value = e.target.value;

        if (!value.includes('+') && !value.includes('-') && value.length > 0) {
            value = '-' + value;
        }

        if (value.includes('+')) {
            value = value.replace('-', '');
        }

        if (value < -100) {
            value = -100;
        }

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                discount: value,
            }
        }));
    }

    const handleChangeClient = data => {

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                client: data,
                client_id: data?.id || '',
                receiver_company_name: data.company_name || data.name,
                receiver_address: data.address,
                receiver_town: data.town,
                receiver_identity_num: data.bulstat || defaultData.receiver_identity_num,
                receiver_vat_num: data.vat_num,
                receiver_mol: data.mol,
                discount: Number(data?.discount),
                paymethod_id: data?.method_id,
            }
        }));
    }

    const handleAddClient = () => {
        let modal = Refs.getInstance().getRef('newClient');

        if (modal) {
            modal.add();
            modal.onSuccess(client => {
                if (client) {
                    handleChangeClient(client);
                }
            });
        }
    }

    const handleChangeCompany = data => {

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                seller: data,
                seller_company_id: data?.id || '',
                seller_company_name: data.company_name,
                seller_address: data.address,
                seller_town: data.town,
                seller_identity_num: data.bulstat,
                seller_vat_num: data.vat_num,
                seller_mol: data.mol
            }
        }));

        loadNumber(data?.id || '');
    }

    const handleAddCompany = () => {
        let modal = Refs.getInstance().getRef('newCompany');

        if (modal) {
            modal.add();
            modal.onSuccess(company => {
                if (company) {
                    handleChangeCompany(company);
                }
            });
        }
    }

    const handleTypeChange = e => {
        let id = e.target.value;

        let type = types.find(type => Number(type.id) === Number(id));

        setType(type);
    }

    const setType = type => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                type_id: type.id,
                type: type,
            }
        }));

        if (!state.data.id) {
            setNumber(type);
        }
    }

    const setNumber = type => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                no: type.next_number
            }
        }));
    }

    const loadNumber = (companyId = null) => {
        if (state.data.id) {
            return;
        }

        Api.get('invoices/next-numbers', {
            params: {
                company_id: companyId,
            }
        }).then(res => {
            if (Array.isArray(res.data)) {
                setTypes(res.data);

                let type = res.data[0];

                if (type) {
                    setNumber(type);
                }

            }
        });
    }

    const isCreditNote = () => {
        return (Number(state.data.type_id) === CREDIT_NOTE);
    }

    const isDebitNote = () => {
        return (Number(state.data.type_id) === DEBIT_NOTE);
    }

    const save = (send = false, exit = false) => {
        if (state.loading) {
            return;
        }

        setState(prev => ({
            ...prev,
            loading: true
        }));

        setValidations(null);

        let url = params.id ? 'invoices/edit' : 'invoices/add';

        let form = formRef.current;

        let data = new FormData(form);

        if (params.id) {
            data.append('id', params.id)
        }

        if (query.get('from_account_id')) {
            data.append('from_account_id', query.get('from_account_id'));
        }

        let accounts = query.getAll('from_account_id[]');

        if (accounts) {
            accounts.map(acc => {
                data.append('from_account_id[]', acc);
            });
        }

        if (query.get('from_invoice_id')) {
            data.append('from_invoice_id', query.get('from_invoice_id'));

            if (state.data.accountsdata) {
                state.data.accountsdata.map(link => {
                    if (!link.disabled) {
                        data.append('from_account_id[]', link.account_id);
                    }
                });
            }
        }

        if (query.get('from_order_id')) {
            data.append('from_order_id', query.get('from_order_id'));
        }

        if (send) {
            data.append('send', send);
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (exit) {
                navigate('/invoices');
            } else {
                navigate('/invoices/' + res.data.id + '/edit', {
                    replace: true
                });
            }

            app.showSuccess('Действието е успешно!')
        }).catch(error => {
            const _err = error.response;

            if (_err && _err.status) {
                if (_err.status === 422) {
                    setValidations(_err.data.errors);
                } else {
                    let modal = errorModalRef.current;

                    if (modal) {
                        modal.open(_err.data.error || _err.data.message);
                    }
                }
            }

        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
                loadingBase: false,
                loadingExit: false,
                loadingSend: false,
            }));
        });
    }

    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
            loadingBase: true,
            loadingExit: false,
            loadingSend: false,
        }));

        save(false, false);
    }

    // const handleSaveAndClose = e => {
    //     e.preventDefault();

    //     let modal = saveAndCloseModalRef.current;

    //     if (modal) {
    //         modal.open();
    //         modal.onSuccess(() => {
    //             save(true);
    //         });
    //     }

    // }

    const handleSaveAndExit = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
            loadingBase: false,
            loadingExit: true,
            loadingSend: false,
        }));

        save(false, true);
    }

    const handleSaveAndSend = e => {
        e.preventDefault();

        let modal = saveAndSendModalRef.current;

        if (modal) {
            modal.open(state.data.client?.email_invoices || state.data.client?.email);

            modal.onSuccess(({ email }) => {
                setState(prev => ({
                    ...prev,
                    loading: true,
                    loadingBase: false,
                    loadingExit: false,
                    loadingSend: true,
                }));

                save(email, false);
            });
        }
    }

    const handleAddRow = e => {
        e.preventDefault()

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: prev.data.rows.concat({})
            }
        }))
    }

    const handleRemoveRow = (e, index) => {
        e.preventDefault()

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: prev.data.rows.filter((row, i) => i !== index)
            }
        }))
    }

    const handleChangeArticle = (data, index) => {

        let row = state.data.rows[index];

        let qty = row.quantity || 1;

        if (isCreditNote()) {
            qty = Math.abs(qty) * -1;
        }

        // ед. цена с ддс
        let currentPrice = convertPrice(data.current_price, data.currency);

        let netPrice = calcNetPrice(currentPrice, data.tax_group);
        let grossPrice = currentPrice;
        let vatPrice = calcVatPrice(netPrice, data.tax_group);
        let totalNetPrice = netPrice * qty;
        let totalGrossPrice = grossPrice * qty;
        let totalVatPrice = calcVatPrice(totalNetPrice, data.tax_group);
        let totalSum = applyDiscountOrMarkupPrice(totalGrossPrice, row.discount);
        let discountPrice = calcDiscount(totalGrossPrice, row.discount);

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: Object.values({
                    ...prev.data.rows,
                    [index]: {
                        ...prev.data.rows[index],
                        article: data,
                        article_id: data.id,
                        inv_ref_num: data.id,
                        name: data.article_name,
                        quantity: qty,
                        unit: data.amount_type_short,
                        tax_id: data.tax_id,
                        taxgroup: data.tax_group,
                        discount_price: discountPrice,
                        net_price: netPrice,
                        gross_price: grossPrice,
                        vat_price: vatPrice,
                        total_net_price: totalNetPrice,
                        total_gross_price: totalGrossPrice,
                        total_vat_price: totalVatPrice,
                        total_sum: totalSum,
                    }
                })
            }
        }));

    }

    const handleChangeArticleName = (data, index) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: Object.values({
                    ...prev.data.rows,
                    [index]: {
                        ...prev.data.rows[index],
                        name: data,
                    }
                })
            }
        }));
    }

    const calcNetPrice = (price, tax) => {
        return removeExtraPrice(price, tax?.value);
    }

    const calcGrossPrice = (price, tax) => {
        return addExtraPrice(price, tax?.value);
    }

    const calcVatPrice = (price, tax) => {
        return vatPrice(price, tax?.value);
    }

    const convertPrice = (price, currency) => {
        return convertFromRateToRate(price, currency.rate, state.data.currency.rate)
    }

    // при смяна на количеството 
    const handleQtyChange = (e, index) => {

        let row = state.data.rows[index];

        let value = parseFloat(e.target.value);

        let qty = value;

        let totalNetPrice = row.net_price * qty;
        let totalGrossPrice = row.gross_price * qty;
        let totalVatPrice = calcVatPrice(totalNetPrice, row.taxgroup);
        let totalSum = applyDiscountOrMarkupPrice(totalGrossPrice, row.discount);
        let discountPrice = calcDiscount(totalGrossPrice, row.discount);

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: Object.values({
                    ...prev.data.rows,
                    [index]: {
                        ...prev.data.rows[index],
                        quantity: value,
                        total_net_price: totalNetPrice,
                        total_gross_price: totalGrossPrice,
                        total_vat_price: totalVatPrice,
                        total_sum: totalSum,
                        discount_price: discountPrice
                    }
                })
            }
        }));

    }

    // при смяна на нетна цена
    const handlePriceChange = (e, index) => {

        let row = state.data.rows[index];

        let value = parseFloat(e.target.value);

        let qty = row.quantity || 1;

        let netPrice = value;
        let grossPrice = calcGrossPrice(netPrice, row.taxgroup);
        let vatPrice = calcVatPrice(netPrice, row.taxgroup);
        let totalNetPrice = netPrice * qty;
        let totalGrossPrice = grossPrice * qty;
        let totalVatPrice = calcVatPrice(totalNetPrice, row.taxgroup);
        let totalSum = applyDiscountOrMarkupPrice(totalGrossPrice, row.discount);
        let discountPrice = calcDiscount(totalGrossPrice, row.discount);

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: Object.values({
                    ...prev.data.rows,
                    [index]: {
                        ...prev.data.rows[index],
                        discount_price: discountPrice,
                        net_price: netPrice,
                        gross_price: grossPrice,
                        vat_price: vatPrice,
                        total_net_price: totalNetPrice,
                        total_gross_price: totalGrossPrice,
                        total_vat_price: totalVatPrice,
                        total_sum: totalSum
                    }
                })
            }
        }));

    }

    // при смяна на брутна цена
    const handleGrossPriceChange = (e, index) => {

        let row = state.data.rows[index];

        let value = parseFloat(e.target.value);

        let qty = row.quantity || 1;

        let grossPrice = value;
        let netPrice = calcNetPrice(grossPrice, row.taxgroup);
        let vatPrice = calcVatPrice(netPrice, row.taxgroup);
        let totalNetPrice = netPrice * qty;
        let totalGrossPrice = grossPrice * qty;
        let totalVatPrice = calcVatPrice(totalNetPrice, row.taxgroup);
        let totalSum = applyDiscountOrMarkupPrice(totalGrossPrice, row.discount);
        let discountPrice = calcDiscount(totalGrossPrice, row.discount);

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: Object.values({
                    ...prev.data.rows,
                    [index]: {
                        ...prev.data.rows[index],
                        discount_price: discountPrice,
                        net_price: netPrice,
                        gross_price: grossPrice,
                        vat_price: vatPrice,
                        total_net_price: totalNetPrice,
                        total_gross_price: totalGrossPrice,
                        total_vat_price: totalVatPrice,
                        total_sum: totalSum
                    }
                })
            }
        }));

    }


    // при смяна на отстъка
    const handleRowDiscountChange = (e, index) => {

        let row = state.data.rows[index];

        let value = e.target.value;

        if (!value.includes('+') && !value.includes('-') && value.length > 0) {
            value = '-' + value;
        }

        if (value.includes('+')) {
            value = value.replace('-', '');
        }

        if (value < -100) {
            value = -100;
        }

        // value = parseFloat(e.target.value);

        let discount = value;

        let totalSum = applyDiscountOrMarkupPrice(row.total_gross_price, discount);
        let discountPrice = calcDiscount(row.total_gross_price, discount);

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: Object.values({
                    ...prev.data.rows,
                    [index]: {
                        ...prev.data.rows[index],
                        discount: discount,
                        discount_price: discountPrice,
                        total_sum: totalSum
                    }
                })
            }
        }));

    }

    // при смяна на ддс
    const handleTaxGroupChange = (e, index) => {

        let value = e.target.value;

        if (state.data.rows.length > 1) {
            let modal = Refs.getInstance().getRef('question');

            modal.open('Да се зададе ли избраната група за всички редове?');

            modal.onSuccess(() => {
                setTaxGroup(value, null);
            });

            // modal.onCancel(() => {
            //     setTaxGroup(value, index);
            // });
        }

        setTaxGroup(value, index);
    }

    const setTaxGroup = (id, index = null) => {
        let taxgroup = taxGroups.find(t => Number(t.id) === Number(id));

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: prev.data.rows.map((row, i) => {

                    let qty = row.quantity || 1;

                    let grossPrice = row.gross_price;
                    let netPrice = calcNetPrice(grossPrice, taxgroup);
                    let vatPrice = calcVatPrice(netPrice, taxgroup);
                    let totalNetPrice = netPrice * qty;
                    let totalGrossPrice = grossPrice * qty;
                    let totalVatPrice = calcVatPrice(totalNetPrice, taxgroup);
                    let totalSum = applyDiscountOrMarkupPrice(totalGrossPrice, row.discount);
                    let discountPrice = calcDiscount(totalGrossPrice, row.discount);

                    const update = () => {
                        row.tax_id = id;
                        row.taxgroup = taxgroup;
                        row.discount_price = discountPrice;
                        row.net_price = netPrice;
                        row.gross_price = grossPrice;
                        row.vat_price = vatPrice;
                        row.total_net_price = totalNetPrice;
                        row.total_gross_price = totalGrossPrice;
                        row.total_vat_price = totalVatPrice;
                        row.total_sum = totalSum;
                    }

                    // ако е за всички
                    if (index === null) {
                        update();
                    }

                    else if (i === index) {
                        update();
                    }

                    return row;
                })
            }
        }));
    }

    const getTotalSum = () => {
        let total = 0;

        state.data.rows.map(row => {
            total += parseFloat(row.total_sum || 0);
        });

        return total;
    }

    const getTotalDiscount = () => {
        return calcDiscount(getTotalSum(), parseFloat(state.data.discount));
    }

    const getTotalNet = () => {
        let total = 0;

        state.data.rows.map(row => {
            let netPrice = applyDiscountOrMarkupPrice(parseFloat(row.total_net_price || 0), parseFloat(row.discount || 0));

            total += netPrice
        });

        total = applyDiscountOrMarkupPrice(total, state.data.discount);

        return total;
    }

    const getTotalVat = () => {
        let total = 0;

        state.data.rows.map(row => {
            let netPrice = applyDiscountOrMarkupPrice(parseFloat(row.total_net_price || 0), parseFloat(row.discount || 0));
            let vatPrice = calcVatPrice(netPrice, row.taxgroup);

            total += vatPrice
        });

        total = applyDiscountOrMarkupPrice(total, state.data.discount);

        return total;
    }

    const getTotalGross = () => {
        return getTotalNet() + getTotalVat();
    }

    const isDiscount = () => {
        return parseFloat(state.data.discount || 0) <= 0;
    }

    const getTotalQty = () => {
        let total = 0;

        state.data.rows.map(row => {
            total += parseFloat(row.quantity || 0);
        });

        return total;
    }

    const showAccount = id => {
        let modal = Refs.getInstance().getRef('account');

        if (!modal) {
            return;
        }

        modal.open(id);
    }

    const handleSortRows = items => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: items
            }
        }));
    }

    const toggleAccountLink = index => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                accountsdata: Object.values({
                    ...prev.data.accountsdata,
                    [index]: {
                        ...prev.data.accountsdata[index],
                        disabled: !prev.data.accountsdata[index].disabled
                    }
                })
            }
        }));
    }

    const handlePrint = (copy = false, withCopy = false) => {
        let params = new URLSearchParams();

        params.append('ids[]', state.data.id);

        params.set('copy', Number(copy));
        params.set('withcopy', Number(withCopy));

        const url = process.env.REACT_APP_URL + '/api/invoices/bulk-stream?' + params.toString();

        window.open(url);
    }

    const handleSend = () => {
        let modal = sendMailModalRef.current;

        if (!modal) {
            return;
        }

        modal.show(state.data.id, state.data.client_email);

        modal.onClose(() => {
            // 
        });
    }

    const handleShow = () => {
        let modal = viewModalRef.current;

        modal.open(state.data.id);
    }

    const handleClone = () => {
        window.location = '/invoices/new?from_invoice_id=' + state.data.id;
    }

    const handleDelete = () => {
        let modal = deleteModalRef.current;

        modal.open();
        modal.onSuccess(() => {
            handleDestroy(state.data.id);
        })
    }

    const handleDestroy = id => {
        Api.post('invoices/delete', {
            id: id
        }).then(res => {
            if (res.data.success) {
                navigate('/invoices');
            }
        });
    }

    const checkVatNumber = e => {
        e.preventDefault();

        let num = state.data.receiver_vat_num;

        if (!num) {
            return;
        }

        setVies(prev => ({
            ...prev,
            loading: true,
        }));

        Api.get('vies/search', {
            params: {
                vat_number: num
            }
        }).then(res => {
            if (res.data.valid) {
                let modal = viesModalRef.current;

                if (!modal) {
                    return;
                }

                let data = res.data;

                modal.open(data);

                modal.onSuccess(() => {
                    let address = parseViesAddress(data);

                    setState(prev => ({
                        ...prev,
                        data: {
                            ...prev.data,
                            receiver_company_name: data.name,
                            receiver_town: address.town,
                            receiver_address: address.address,
                            // zip: address.zip
                        }
                    }))
                });
            }
        }).finally(() => {
            setVies(prev => ({
                ...prev,
                loading: false,
            }));
        })
    }

    const parseViesAddress = data => {
        let full = data.address;

        let town = (full.split(',').pop().split(/(\d)/)[0] || '').trim();

        ['гр.', 'с.',].forEach(el => {
            if (town.startsWith(el)) {
                town = town.replace(el, '');
            }
        });

        let zip = full.split(' ').pop();

        let address = (full.split('обл')[0] || '').trim();

        return {
            town: town,
            zip: zip,
            address: address,
        }
    }

    return (
        <>
            <Question
                ref={deleteModalRef}
                mainMessage="Сигурни ли сте?"
                agreeBtnText="Изтриване"
                agreeBtnClass="remove"
            />

            <Error
                ref={errorModalRef}
            />

            <SendDirect
                ref={saveAndSendModalRef}
            />

            <SendMail
                ref={sendMailModalRef}
            />

            <View
                ref={viewModalRef}
            />

            <Vies
                ref={viesModalRef}
            />

            <Layout>
                <div className="container-fluid">

                    <div className="page-head">
                        <h1 className="display-6">
                            <Link to={-1} className="link-dark text-decoration-none">
                                <ArrowLeftCircle />
                            </Link> {params.id ? 'Редактиране на платежен документ' : 'Издаване на платежен документ'}
                        </h1>

                        <div className="buttons">

                        </div>
                    </div>

                    <br />

                    <div className="panel">

                        {state.dataLoading
                            ?
                            <Loader />
                            :
                            <>
                                {!state.data.clone && state.data.accountsdata &&
                                    <Alert>
                                        Документът е съставен по сметка №: {state.data.accountsdata.map((a, i) =>
                                            <React.Fragment key={a.account_id}>
                                                <span className="link" onClick={e => showAccount(a.account_id)}>
                                                    {a.account_id}
                                                </span>
                                                <span>
                                                    {i !== state.data.accountsdata.length - 1 && ', '}
                                                </span>
                                            </React.Fragment>
                                        )}
                                    </Alert>
                                }

                                {state.data.clone &&
                                    <Alert>
                                        Документът е съставен като клонинг на №: <span className="link" onClick={e => showAccount(state.data.clone.id)}>
                                            {state.data.clone.no} / {moment(state.data.clone.date).format('DD.MM.YYYY')}
                                        </span>

                                        {state.data.accountsdata && state.data.accountsdata.length > 0 &&
                                            <>
                                                <div>Ще бъде закачен към сметка(и): {state.data.accountsdata.map((a, i) =>
                                                    <React.Fragment key={a.account_id}>
                                                        <span className="link" onClick={e => showAccount(a.account_id)} style={{
                                                            textDecoration: a.disabled ? 'line-through' : 'none'
                                                        }}>
                                                            {a.account_id}
                                                        </span> <Button size="sm" onClick={e => toggleAccountLink(i)}>
                                                            {a.disabled
                                                                ?
                                                                'Възстанови'
                                                                :
                                                                'Премахни връзката'
                                                            }
                                                        </Button>
                                                        <span>
                                                            {i !== state.data.accountsdata.length - 1 && ', '}
                                                        </span>
                                                    </React.Fragment>
                                                )}
                                                </div>
                                            </>
                                        }
                                    </Alert>
                                }

                                {state.data.id &&
                                    <div className="mb-3">
                                        <Dropdown drop="down" style={{ display: 'inline' }}>
                                            <Dropdown.Toggle size="sm" variant="primary" disabled={auth.getUser().permission('invoices_print') === false}>
                                                Печат
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu align="start" style={{ padding: '15px', width: '320px' }}>
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Button size="sm" variant="primary" onClick={e => handlePrint(false, false)}>
                                                        Оригинал
                                                    </Button>
                                                    <Button size="sm" variant="primary" style={{ marginLeft: '5px' }} onClick={e => handlePrint(true, false)}>
                                                        Копие
                                                    </Button>
                                                    <Button size="sm" variant="primary" style={{ marginLeft: '5px' }} onClick={e => handlePrint(false, true)}>
                                                        Оригинал+Копие
                                                    </Button>
                                                </div>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <Button size="sm" variant="primary" onClick={handleSend} disabled={auth.getUser().permission('invoices_mail') === false} style={{ marginLeft: '10px' }}>
                                            <Envelope /> Изпращане
                                        </Button>
                                        <Button size="sm" variant="primary" onClick={handleShow} disabled={auth.getUser().permission('invoices_view') === false} style={{ marginLeft: '10px' }}>
                                            <InfoCircle /> Преглед
                                        </Button>
                                        <Button size="sm" variant="primary" onClick={handleClone} disabled={auth.getUser().permission('invoices_create') === false} style={{ marginLeft: '10px' }}>
                                            <Files /> Клонирай
                                        </Button>
                                        {state.data.allow_delete &&
                                            <Button size="sm" variant="danger" onClick={handleDelete} disabled={auth.getUser().permission('invoices_anulate') === false} style={{ marginLeft: '10px' }}>
                                                <XCircle /> Анулирай
                                            </Button>
                                        }
                                    </div>
                                }

                                <Form ref={formRef}>
                                    {/* <Container> */}

                                    <Row>
                                        <Col>
                                            <div className="form-panel">
                                                <div className="head">
                                                    Получател
                                                </div>
                                                <Row>
                                                    <Col className="mb-2">
                                                        <Form.Group as={Row}>
                                                            <Form.Label column="sm" sm={4} className="text-left">
                                                                Избери клиент
                                                            </Form.Label>
                                                            <Col sm={8}>
                                                                <Autocomplete
                                                                    variant="basic"
                                                                    url="autocomplete/clients"
                                                                    inputName="client_name"
                                                                    inputIdName="client_id"
                                                                    inputValue={state.data?.client?.name}
                                                                    inputIdValue={state.data?.client_id || ''}
                                                                    onChange={handleChangeClient}
                                                                    onClickAddButton={handleAddClient}
                                                                    error={Boolean(validations?.client_id)}
                                                                    helperText={validations?.client_id && (validations.client_id[0] || validations.client_id)}
                                                                    renderText={data => {
                                                                        return (
                                                                            <div style={{ display: 'flex', width: '100%' }}>
                                                                                <div style={{ width: '10%' }}>
                                                                                    {data.id}
                                                                                </div>
                                                                                <div style={{ width: '90%' }}>
                                                                                    {data.name}
                                                                                    <br />
                                                                                    {data.email}
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }}
                                                                    renderInputText={data => data.name}
                                                                    size="sm"
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className="mb-2">
                                                        <Form.Group as={Row}>
                                                            <Form.Label column="sm" sm={4} className="text-left">
                                                                Фирма
                                                            </Form.Label>
                                                            <Col sm={8}>
                                                                <Form.Control
                                                                    placeholder=""
                                                                    name="receiver_company_name"
                                                                    value={state.data?.receiver_company_name || ''}
                                                                    onChange={handleInputChange}
                                                                    size="sm"
                                                                    isInvalid={Boolean(validations?.receiver_company_name)}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {validations?.receiver_company_name && (validations.receiver_company_name[0] || validations.receiver_company_name)}
                                                                </Form.Control.Feedback>
                                                            </Col>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className="mb-2">
                                                        <Form.Group as={Row}>
                                                            <Form.Label column="sm" sm={4} className="text-left">
                                                                Адрес
                                                            </Form.Label>
                                                            <Col sm={8}>
                                                                <Form.Control
                                                                    placeholder=""
                                                                    name="receiver_address"
                                                                    value={state.data?.receiver_address || ''}
                                                                    onChange={handleInputChange}
                                                                    size="sm"
                                                                    isInvalid={Boolean(validations?.receiver_address)}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {validations?.receiver_address && (validations.receiver_address[0] || validations.receiver_address)}
                                                                </Form.Control.Feedback>
                                                            </Col>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className="mb-2">
                                                        <Form.Group as={Row}>
                                                            <Form.Label column="sm" sm={4} className="text-left">
                                                                Град
                                                            </Form.Label>
                                                            <Col sm={8}>
                                                                <Form.Control
                                                                    placeholder=""
                                                                    name="receiver_town"
                                                                    value={state.data?.receiver_town || ''}
                                                                    onChange={handleInputChange}
                                                                    size="sm"
                                                                    isInvalid={Boolean(validations?.receiver_town)}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {validations?.receiver_town && (validations.receiver_town[0] || validations.receiver_town)}
                                                                </Form.Control.Feedback>
                                                            </Col>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className="mb-2">
                                                        <Form.Group as={Row}>
                                                            <Form.Label column="sm" sm={4} className="text-left">
                                                                ЕИК/ЕГН
                                                            </Form.Label>
                                                            <Col sm={8}>
                                                                <Form.Control
                                                                    placeholder=""
                                                                    name="receiver_identity_num"
                                                                    value={state.data?.receiver_identity_num || ''}
                                                                    onChange={handleInputChange}
                                                                    size="sm"
                                                                    isInvalid={Boolean(validations?.receiver_identity_num)}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {validations?.receiver_identity_num && (validations.receiver_identity_num[0] || validations.receiver_identity_num)}
                                                                </Form.Control.Feedback>
                                                            </Col>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className="mb-2">
                                                        <Form.Group as={Row}>
                                                            <Form.Label column="sm" sm={4} className="text-left">
                                                                ДДС №
                                                            </Form.Label>
                                                            <Col sm={8}>
                                                                <InputGroup>
                                                                    <Form.Control
                                                                        placeholder=""
                                                                        name="receiver_vat_num"
                                                                        value={state.data?.receiver_vat_num || ''}
                                                                        onChange={handleInputChange}
                                                                        size="sm"
                                                                        isInvalid={Boolean(validations?.receiver_vat_num)}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {validations?.receiver_vat_num && (validations.receiver_vat_num[0] || validations.receiver_vat_num)}
                                                                    </Form.Control.Feedback>

                                                                    {!Boolean(validations?.receiver_vat_num) &&
                                                                        <SaveButton
                                                                            size="sm"
                                                                            variant="primary"
                                                                            title="Изтегли данните от VIES"
                                                                            onClick={checkVatNumber}
                                                                            loading={vies.loading}
                                                                        >
                                                                            <FileArrowDownFill />
                                                                        </SaveButton>
                                                                    }
                                                                </InputGroup>
                                                            </Col>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col>
                                                        <Form.Group as={Row}>
                                                            <Form.Label column="sm" sm={4} className="text-left">
                                                                МОЛ
                                                            </Form.Label>
                                                            <Col sm={8}>
                                                                <Form.Control
                                                                    placeholder=""
                                                                    name="receiver_mol"
                                                                    value={state.data?.receiver_mol || ''}
                                                                    onChange={handleInputChange}
                                                                    size="sm"
                                                                    isInvalid={Boolean(validations?.receiver_mol)}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {validations?.receiver_mol && (validations.receiver_mol[0] || validations.receiver_mol)}
                                                                </Form.Control.Feedback>
                                                            </Col>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="form-panel">
                                                <div className="head">
                                                    Данни за документа
                                                </div>
                                                <Row>
                                                    <Col className="mb-2">
                                                        <Form.Group as={Row}>
                                                            <Form.Label column="sm" sm={4} className="text-left">
                                                                Тип
                                                            </Form.Label>
                                                            <Col sm={8}>
                                                                <Form.Select
                                                                    name="type_id"
                                                                    value={state.data?.type_id || ''}
                                                                    onChange={handleTypeChange}
                                                                    isInvalid={Boolean(validations?.type_id)}
                                                                    size="sm"
                                                                    disabled={state.data?.id ? true : false}
                                                                >
                                                                    {types.map(o =>
                                                                        <option key={o.id} value={o.id}>{o?.translation?.name || o.name}</option>
                                                                    )}

                                                                </Form.Select>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {validations?.type_id && (validations.type_id[0] || validations.type_id)}
                                                                </Form.Control.Feedback>
                                                            </Col>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="mb-2">
                                                        <Form.Group as={Row}>
                                                            <Form.Label column="sm" sm={4} className="text-left">
                                                                Номер <Info>Предполагаем номер, който ще вземе документа след запис</Info>
                                                            </Form.Label>
                                                            <Col sm={8}>
                                                                <Form.Control
                                                                    placeholder=""
                                                                    name="no"
                                                                    value={state.data?.no || ''}
                                                                    onChange={handleInputChange}
                                                                    size="sm"
                                                                    disabled
                                                                    isInvalid={Boolean(validations?.no)}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {validations?.no && (validations.no[0] || validations.no)}
                                                                </Form.Control.Feedback>
                                                            </Col>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                {(isCreditNote() || isDebitNote()) &&
                                                    <Row>
                                                        <Col className="mb-2">
                                                            <Form.Group as={Row}>
                                                                <Form.Label column="sm" sm={4} className="text-left">
                                                                    Към фактура
                                                                </Form.Label>
                                                                <Col sm={8}>
                                                                    <Form.Control
                                                                        placeholder=""
                                                                        name="parent_num"
                                                                        value={state.data?.parent_num || ''}
                                                                        onChange={handleInputChange}
                                                                        size="sm"
                                                                        isInvalid={Boolean(validations?.parent_num)}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {validations?.parent_num && (validations.parent_num[0] || validations.parent_num)}
                                                                    </Form.Control.Feedback>
                                                                </Col>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                }
                                                <Row>
                                                    <Col className="mb-2">
                                                        <Form.Group as={Row}>
                                                            <Form.Label column="sm" sm={4} className="text-left">
                                                                Документ дата
                                                            </Form.Label>
                                                            <Col sm={8}>
                                                                <DatePicker
                                                                    variant="basic"
                                                                    name="date"
                                                                    value={state.data?.date ? moment(state.data.date).format('YYYY-MM-DD') : ''}
                                                                    onChange={handleInputChange}
                                                                    isInvalid={Boolean(validations?.date)}
                                                                    size="sm"
                                                                    error={validations?.date && (validations.date[0] || validations.date)}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="mb-2">
                                                        <Form.Group as={Row}>
                                                            <Form.Label column="sm" sm={4} className="text-left">
                                                                Срок
                                                            </Form.Label>
                                                            <Col sm={8}>
                                                                <DatePicker
                                                                    variant="basic"
                                                                    name="term_date"
                                                                    value={state.data?.term_date ? moment(state.data.term_date).format('YYYY-MM-DD') : ''}
                                                                    onChange={handleInputChange}
                                                                    isInvalid={Boolean(validations?.term_date)}
                                                                    size="sm"
                                                                    error={validations?.term_date && (validations.term_date[0] || validations.term_date)}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="mb-2">
                                                        <Form.Group as={Row}>
                                                            <Form.Label column="sm" sm={4} className="text-left">
                                                                Събитие
                                                            </Form.Label>
                                                            <Col sm={8}>
                                                                <DatePicker
                                                                    variant="basic"
                                                                    name="payment_date"
                                                                    value={state.data?.payment_date ? moment(state.data.payment_date).format('YYYY-MM-DD') : ''}
                                                                    onChange={handleInputChange}
                                                                    isInvalid={Boolean(validations?.payment_date)}
                                                                    size="sm"
                                                                    error={validations?.payment_date && (validations.payment_date[0] || validations.payment_date)}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Form.Group as={Row}>
                                                            <Form.Label column="sm" sm={4} className="text-left">
                                                                Отстъпка/Надценка <Info>минус - отстъпка в проценти <br />плюс - надценка в проценти</Info>
                                                            </Form.Label>
                                                            <Col sm={8}>
                                                                <Form.Control
                                                                    type="text"
                                                                    step="0.01"
                                                                    placeholder=""
                                                                    name="discount"
                                                                    value={state.data?.discount || ''}
                                                                    onChange={handleDiscountChange}
                                                                    size="sm"
                                                                    isInvalid={Boolean(validations?.discount)}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {validations?.discount && (validations.discount[0] || validations.discount)}
                                                                </Form.Control.Feedback>
                                                            </Col>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="form-panel">
                                                <div className="head">
                                                    Доставчик
                                                </div>
                                                <Row>
                                                    <Col className="mb-2">
                                                        <Form.Group as={Row}>
                                                            <Form.Label column="sm" sm={4} className="text-left">
                                                                Избери фирма
                                                            </Form.Label>
                                                            <Col sm={8}>
                                                                <Autocomplete
                                                                    variant="basic"
                                                                    url="autocomplete/companies"
                                                                    inputName="seller_name"
                                                                    inputIdName="seller_company_id"
                                                                    inputValue={state.data?.seller?.pop_name || state.data?.seller_company_name || ''}
                                                                    inputIdValue={state.data?.seller_company_id || ''}
                                                                    onChange={handleChangeCompany}
                                                                    onClickAddButton={handleAddCompany}
                                                                    error={Boolean(validations?.seller_company_id)}
                                                                    helperText={validations?.seller_company_id && (validations.seller_company_id[0] || validations.seller_company_id)}
                                                                    renderText={data => data.pop_name}
                                                                    renderInputText={data => data.pop_name}
                                                                    size="sm"
                                                                    inputDisabled={state.data?.id ? true : false}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className="mb-2">
                                                        <Form.Group as={Row}>
                                                            <Form.Label column="sm" sm={4} className="text-left">
                                                                Фирма
                                                            </Form.Label>
                                                            <Col sm={8}>
                                                                <Form.Control
                                                                    placeholder=""
                                                                    name="seller_company_name"
                                                                    value={state.data?.seller_company_name || ''}
                                                                    onChange={handleInputChange}
                                                                    size="sm"
                                                                    isInvalid={Boolean(validations?.seller_company_name)}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {validations?.seller_company_name && (validations.seller_company_name[0] || validations.seller_company_name)}
                                                                </Form.Control.Feedback>
                                                            </Col>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className="mb-2">
                                                        <Form.Group as={Row}>
                                                            <Form.Label column="sm" sm={4} className="text-left">
                                                                Адрес
                                                            </Form.Label>
                                                            <Col sm={8}>
                                                                <Form.Control
                                                                    placeholder=""
                                                                    name="seller_address"
                                                                    value={state.data?.seller_address || ''}
                                                                    onChange={handleInputChange}
                                                                    size="sm"
                                                                    isInvalid={Boolean(validations?.seller_address)}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {validations?.seller_address && (validations.seller_address[0] || validations.seller_address)}
                                                                </Form.Control.Feedback>
                                                            </Col>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className="mb-2">
                                                        <Form.Group as={Row}>
                                                            <Form.Label column="sm" sm={4} className="text-left">
                                                                Град
                                                            </Form.Label>
                                                            <Col sm={8}>
                                                                <Form.Control
                                                                    placeholder=""
                                                                    name="seller_town"
                                                                    value={state.data?.seller_town || ''}
                                                                    onChange={handleInputChange}
                                                                    size="sm"
                                                                    isInvalid={Boolean(validations?.seller_town)}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {validations?.seller_town && (validations.seller_town[0] || validations.seller_town)}
                                                                </Form.Control.Feedback>
                                                            </Col>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className="mb-2">
                                                        <Form.Group as={Row}>
                                                            <Form.Label column="sm" sm={4} className="text-left">
                                                                ЕИК/ЕГН
                                                            </Form.Label>
                                                            <Col sm={8}>
                                                                <Form.Control
                                                                    placeholder=""
                                                                    name="seller_identity_num"
                                                                    value={state.data?.seller_identity_num || ''}
                                                                    onChange={handleInputChange}
                                                                    size="sm"
                                                                    isInvalid={Boolean(validations?.seller_identity_num)}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {validations?.seller_identity_num && (validations.seller_identity_num[0] || validations.seller_identity_num)}
                                                                </Form.Control.Feedback>
                                                            </Col>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className="mb-2">
                                                        <Form.Group as={Row}>
                                                            <Form.Label column="sm" sm={4} className="text-left">
                                                                ДДС №
                                                            </Form.Label>
                                                            <Col sm={8}>
                                                                <Form.Control
                                                                    placeholder=""
                                                                    name="seller_vat_num"
                                                                    value={state.data?.seller_vat_num || ''}
                                                                    onChange={handleInputChange}
                                                                    size="sm"
                                                                    isInvalid={Boolean(validations?.seller_vat_num)}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {validations?.seller_vat_num && (validations.seller_vat_num[0] || validations.seller_vat_num)}
                                                                </Form.Control.Feedback>
                                                            </Col>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col>
                                                        <Form.Group as={Row}>
                                                            <Form.Label column="sm" sm={4} className="text-left">
                                                                МОЛ
                                                            </Form.Label>
                                                            <Col sm={8}>
                                                                <Form.Control
                                                                    placeholder=""
                                                                    name="seller_mol"
                                                                    value={state.data?.seller_mol || ''}
                                                                    onChange={handleInputChange}
                                                                    size="sm"
                                                                    isInvalid={Boolean(validations?.seller_mol)}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {validations?.seller_mol && (validations.seller_mol[0] || validations.seller_mol)}
                                                                </Form.Control.Feedback>
                                                            </Col>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>

                                    <div className="form-panel mt-3">
                                        <div className="head">
                                            Артикули
                                        </div>
                                        <Table className="valign-top big">
                                            <thead>
                                                <tr>
                                                    <th style={{ width: '5%' }}>
                                                        #
                                                    </th>
                                                    <th style={{ width: '5%' }}>
                                                        Реф. №
                                                    </th>
                                                    <th style={{ width: '20%' }}>
                                                        Наименование на стоките и услугите
                                                    </th>
                                                    <th style={{ width: '7%' }}>
                                                        Количество
                                                    </th>
                                                    <th style={{ width: '7%' }}>
                                                        Мярка
                                                    </th>
                                                    <th style={{ width: '10%' }}>
                                                        Ед. цена без ДДС
                                                    </th>
                                                    <th style={{ width: '10%' }}>
                                                        Ед. цена с ДДС
                                                    </th>
                                                    <th style={{ width: '7%' }}>
                                                        Отстъпка
                                                    </th>
                                                    <th style={{ width: '10%' }}>
                                                        ДДС група
                                                    </th>
                                                    <th style={{ width: '10%' }}>
                                                        Основание
                                                    </th>
                                                    <th style={{ width: '10%' }}>
                                                        Тотал
                                                    </th>
                                                    <th className="options">

                                                    </th>
                                                </tr>

                                            </thead>
                                            <tbody>
                                                {state.data.rows.map((row, index) =>
                                                    <SortItem
                                                        key={index}
                                                        items={state.data.rows}
                                                        onSortItems={handleSortRows}
                                                        sortId={index}
                                                        className=""
                                                    >
                                                        <td title={row.id || '-'} style={{ width: '5%' }}>
                                                            {index + 1}

                                                            <input type="hidden" name={`rows[${index}][id]`} value={state.data.rows[index].id || ''} />
                                                            <input type="hidden" name={`rows[${index}][position]`} value={index} />
                                                        </td>
                                                        <td style={{ width: '5%' }}>
                                                            <InputGroup size="sm" style={{ minWidth: '95px' }}>
                                                                <Form.Control
                                                                    type="text"
                                                                    name={`rows[${index}][inv_ref_num]`}
                                                                    value={row.inv_ref_num || ''}
                                                                    onChange={handleInputChange}
                                                                    isInvalid={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].inv_ref_num && (validations.rows[index].inv_ref_num[0] || validations.rows[index].inv_ref_num))}
                                                                    size="sm"
                                                                />

                                                                {/* <Form.Control.Feedback type="invalid">
                                                                        {validations && validations.rows && validations.rows[index] && validations.rows[index].quantity && (validations.rows[index].quantity[0] || validations.rows[index].quantity)}
                                                                    </Form.Control.Feedback> */}
                                                            </InputGroup>

                                                        </td>
                                                        <td style={{ width: '20%' }}>
                                                            <Autocomplete
                                                                variant="basic"
                                                                url="autocomplete/articles"
                                                                inputName={`rows[${index}][name]`}
                                                                inputIdName={`rows[${index}][article_id]`}
                                                                inputValue={row.name || ''}
                                                                inputIdValue={row.article_id || ''}
                                                                onChange={data => handleChangeArticle(data, index)}
                                                                onInputChange={data => handleChangeArticleName(data, index)}
                                                                error={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].name && (validations.rows[index].name[0] || validations.rows[index].name))}
                                                                helperText={validations && validations.rows && validations.rows[index] && validations.rows[index].name && (validations.rows[index].name[0] || validations.rows[index].name)}
                                                                renderText={data => {
                                                                    return (
                                                                        <div style={{ display: 'flex', width: '100%' }}>
                                                                            <div style={{ width: 'max-content', marginRight: '10px' }}>
                                                                                {data.id}
                                                                            </div>
                                                                            <div>
                                                                                {data.name}
                                                                                <br />
                                                                                {data.article_name}
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }}
                                                                renderInputText={data => data.article_name}
                                                                size="sm"
                                                                listStyle={{
                                                                    minWidth: 'max-content'
                                                                }}
                                                                autoFocus
                                                            />
                                                        </td>

                                                        <td style={{ width: '7%' }}>
                                                            <InputGroup size="sm">
                                                                <Form.Control
                                                                    type="number"
                                                                    step="0.0001"
                                                                    name={`rows[${index}][quantity]`}
                                                                    value={row.quantity || ''}
                                                                    onChange={e => handleQtyChange(e, index)}
                                                                    isInvalid={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].quantity && (validations.rows[index].quantity[0] || validations.rows[index].quantity))}
                                                                    size="sm"
                                                                    className="price-input"
                                                                />

                                                                {/* <Form.Control.Feedback type="invalid">
                                                                        {validations && validations.rows && validations.rows[index] && validations.rows[index].quantity && (validations.rows[index].quantity[0] || validations.rows[index].quantity)}
                                                                    </Form.Control.Feedback> */}
                                                            </InputGroup>

                                                        </td>

                                                        <td style={{ width: '7%' }}>
                                                            <InputGroup size="sm">
                                                                <Form.Control
                                                                    type="text"
                                                                    name={`rows[${index}][unit]`}
                                                                    value={row.unit || ''}
                                                                    onChange={handleInputChange}
                                                                    isInvalid={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].unit && (validations.rows[index].unit[0] || validations.rows[index].inv_ref_num))}
                                                                    size="sm"
                                                                />

                                                                {/* <Form.Control.Feedback type="invalid">
                                                                        {validations && validations.rows && validations.rows[index] && validations.rows[index].quantity && (validations.rows[index].quantity[0] || validations.rows[index].quantity)}
                                                                    </Form.Control.Feedback> */}
                                                            </InputGroup>
                                                        </td>

                                                        <td style={{ width: '10%' }}>
                                                            <InputGroup size="sm" style={{ minWidth: '95px' }}>
                                                                <Form.Control
                                                                    type="number"
                                                                    step="0.01"
                                                                    name={`rows[${index}][net_price]`}
                                                                    value={row.net_price || ''}
                                                                    onChange={e => handlePriceChange(e, index)}
                                                                    isInvalid={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].net_price && (validations.rows[index].net_price[0] || validations.rows[index].net_price))}
                                                                    size="sm"
                                                                />
                                                                {state.data?.currency &&
                                                                    <InputGroup.Text>
                                                                        {state.data.currency.prefix || state.data.currency.sufix || '-'}
                                                                    </InputGroup.Text>
                                                                }

                                                                {/* <Form.Control.Feedback type="invalid">
                                                                        {validations && validations.rows && validations.rows[index] && validations.rows[index].quantity && (validations.rows[index].quantity[0] || validations.rows[index].quantity)}
                                                                    </Form.Control.Feedback> */}
                                                            </InputGroup>
                                                        </td>

                                                        <td style={{ width: '10%' }}>
                                                            <InputGroup size="sm" style={{ minWidth: '95px' }}>
                                                                <Form.Control
                                                                    type="number"
                                                                    step="0.01"
                                                                    name={`rows[${index}][gross_price]`}
                                                                    value={row.gross_price || ''}
                                                                    onChange={e => handleGrossPriceChange(e, index)}
                                                                    isInvalid={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].gross_price && (validations.rows[index].gross_price[0] || validations.rows[index].price))}
                                                                    size="sm"
                                                                />
                                                                {state.data?.currency &&
                                                                    <InputGroup.Text>
                                                                        {state.data.currency.prefix || state.data.currency.sufix || '-'}
                                                                    </InputGroup.Text>
                                                                }

                                                                {/* <Form.Control.Feedback type="invalid">
                                                                        {validations && validations.rows && validations.rows[index] && validations.rows[index].quantity && (validations.rows[index].quantity[0] || validations.rows[index].quantity)}
                                                                    </Form.Control.Feedback> */}
                                                            </InputGroup>
                                                        </td>

                                                        <td style={{ width: '7%' }}>
                                                            <InputGroup size="sm" style={{ minWidth: '95px' }}>
                                                                <Form.Control
                                                                    type="text"
                                                                    name={`rows[${index}][discount]`}
                                                                    value={row.discount || ''}
                                                                    onChange={e => handleRowDiscountChange(e, index)}
                                                                    isInvalid={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].discount && (validations.rows[index].discount[0] || validations.rows[index].price))}
                                                                    size="sm"
                                                                />
                                                                <InputGroup.Text>
                                                                    %
                                                                </InputGroup.Text>

                                                                {/* <Form.Control.Feedback type="invalid">
                                                                        {validations && validations.rows && validations.rows[index] && validations.rows[index].quantity && (validations.rows[index].quantity[0] || validations.rows[index].quantity)}
                                                                    </Form.Control.Feedback> */}
                                                            </InputGroup>
                                                        </td>

                                                        <td style={{ width: '10%' }}>
                                                            <Form.Select
                                                                name={`rows[${index}][tax_id]`}
                                                                value={row.tax_id || ''}
                                                                onChange={e => handleTaxGroupChange(e, index)}
                                                                isInvalid={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].tax_id && (validations.rows[index].tax_id[0] || validations.rows[index].delivery_total))}
                                                                size="sm"
                                                            >
                                                                <option value=""></option>
                                                                {taxGroups.map(o =>
                                                                    <option key={o.id} value={o.id}>{o?.translation?.name || o.name}</option>
                                                                )}

                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">
                                                                {validations && validations.rows && validations.rows[index] && validations.rows[index].tax_id && (validations.rows[index].tax_id[0] || validations.rows[index].tax_id)}
                                                            </Form.Control.Feedback>
                                                        </td>

                                                        <td style={{ width: '10%' }}>
                                                            <InputGroup size="sm" style={{ minWidth: '95px' }}>
                                                                <Form.Control
                                                                    type="text"
                                                                    name={`rows[${index}][tax_reason]`}
                                                                    value={row.tax_reason || ''}
                                                                    onChange={handleInputChange}
                                                                    isInvalid={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].tax_reason && (validations.rows[index].tax_reason[0] || validations.rows[index].tax_reason))}
                                                                    size="sm"
                                                                />

                                                                {/* <Form.Control.Feedback type="invalid">
                                                                        {validations && validations.rows && validations.rows[index] && validations.rows[index].quantity && (validations.rows[index].quantity[0] || validations.rows[index].quantity)}
                                                                    </Form.Control.Feedback> */}
                                                            </InputGroup>
                                                        </td>

                                                        <td style={{ width: '10%' }}>
                                                            <InputGroup size="sm" style={{ minWidth: '95px' }}>
                                                                <Form.Control
                                                                    type="number"
                                                                    step="0.01"
                                                                    value={row.total_sum || ''}
                                                                    size="sm"
                                                                    disabled
                                                                />
                                                                {state.data?.currency &&
                                                                    <InputGroup.Text>
                                                                        {state.data.currency.prefix || state.data.currency.sufix || '-'}
                                                                    </InputGroup.Text>
                                                                }
                                                            </InputGroup>
                                                        </td>

                                                        <td className="options">
                                                            <div style={{ display: 'flex' }}>
                                                                <Button variant="danger" size="sm" onClick={e => handleRemoveRow(e, index)}>
                                                                    <TrashFill />
                                                                </Button>
                                                                <Button variant="outline" size="sm" style={{ marginLeft: '5px', cursor: 'move' }} onClick={e => e.preventDefault()}>
                                                                    <ArrowsMove />
                                                                </Button>
                                                            </div>
                                                        </td>

                                                    </SortItem>
                                                )}
                                                {/* <tr>
                                                    <td colSpan={4}>
                                                        Общо
                                                    </td>
                                                    <td className="text-right">
                                                        {getTotalQty().toFixed(4)}
                                                    </td>
                                                </tr> */}

                                            </tbody>
                                        </Table>

                                        <Button variant="outline-dark" className="mt-3" size="sm" onClick={handleAddRow}>
                                            Нов ред
                                        </Button>
                                    </div>


                                    <Row>
                                        <Col sm={6} className="mt-3">
                                            <div className="form-panel full-height">
                                                <div className="head">
                                                    Допълнителен текст
                                                </div>
                                                <Form.Control
                                                    type="text"
                                                    as="textarea"
                                                    // placeholder="Бележки"
                                                    name="additional_text"
                                                    value={state.data?.additional_text || ''}
                                                    onChange={handleInputChange}
                                                    isInvalid={Boolean(validations?.additional_text)}
                                                    rows={6}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {validations?.additional_text && (validations.additional_text[0] || validations.additional_text)}
                                                </Form.Control.Feedback>
                                            </div>

                                        </Col>

                                        <Col sm={3} className="mt-3">
                                            <div className="form-panel full-height">
                                                <div className="head">
                                                    Плащане
                                                </div>
                                                <Row className="mb-3">
                                                    <Col>
                                                        <Form.Group as={Row}>
                                                            <Form.Label column="sm" sm={4} className="text-left">
                                                                Начин
                                                            </Form.Label>
                                                            <Col sm={8}>
                                                                <Form.Select
                                                                    name="paymethod_id"
                                                                    value={state.data?.paymethod_id || ''}
                                                                    onChange={handleInputChange}
                                                                    isInvalid={Boolean(validations?.paymethod_id)}
                                                                    size="sm"
                                                                >
                                                                    <option value=""></option>
                                                                    {paymentMethods.map(o =>
                                                                        <option key={o.id} value={o.id}>{o?.translation?.name || o.name}</option>
                                                                    )}

                                                                </Form.Select>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {validations?.paymethod_id && (validations.paymethod_id[0] || validations.paymethod_id)}
                                                                </Form.Control.Feedback>
                                                            </Col>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col>
                                                        <Form.Group as={Row}>
                                                            <Form.Label column="sm" sm={4} className="text-left">
                                                                Получател
                                                            </Form.Label>
                                                            <Col sm={8}>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder=""
                                                                    name="receiver_name"
                                                                    value={state.data?.receiver_name || ''}
                                                                    onChange={handleInputChange}
                                                                    size="sm"
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {validations?.receiver_name && (validations.receiver_name[0] || validations.receiver_name)}
                                                                </Form.Control.Feedback>
                                                            </Col>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col>
                                                        <Form.Group as={Row}>
                                                            <Form.Label column="sm" sm={4} className="text-left">
                                                                Съставил
                                                            </Form.Label>
                                                            <Col sm={8}>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder=""
                                                                    name="seller_name"
                                                                    value={state.data?.seller_name || ''}
                                                                    onChange={handleInputChange}
                                                                    size="sm"
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {validations?.seller_name && (validations.seller_name[0] || validations.seller_name)}
                                                                </Form.Control.Feedback>
                                                            </Col>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="">
                                                        <Form.Group as={Row}>
                                                            <Form.Label column="sm" sm={4} className="text-left">
                                                                Печат с ДДС <Info>При печат цените на артикулите да са с включено ДДС.</Info>
                                                            </Form.Label>
                                                            <Col sm={8}>
                                                                <div className="custom-radio">
                                                                    <label>
                                                                        <input type="radio" name="with_tax" value={0} checked={Number(state.data.with_tax) === 0} onChange={handleInputChange} />
                                                                        <span>
                                                                            НЕ
                                                                        </span>
                                                                    </label>
                                                                    <label>
                                                                        <input type="radio" name="with_tax" value={1} checked={Number(state.data.with_tax) === 1} onChange={handleInputChange} />
                                                                        <span>
                                                                            ДА
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>

                                        <Col sm={3} className="mt-3">
                                            <div className="form-panel full-height">
                                                <div className="head">
                                                    Общо
                                                </div>

                                                {/* <Row>
                                                    <Col className="mb-3">
                                                        <Form.Group as={Row}>
                                                            <Form.Label column="sm" sm={6} className="text-left">
                                                                Общо
                                                            </Form.Label>
                                                            <Col sm={6}>
                                                                <InputGroup size="sm">
                                                                    <Form.Control
                                                                        type="number"
                                                                        step="0.01"
                                                                        readOnly
                                                                        value={getTotalSum().toFixed(4)}
                                                                        size="sm"
                                                                        className="price-input"
                                                                    />
                                                                    {state.data?.currency &&
                                                                        <InputGroup.Text>
                                                                            {state.data.currency.prefix || state.data.currency.sufix || '-'}
                                                                        </InputGroup.Text>
                                                                    }
                                                                </InputGroup>
                                                            </Col>
                                                        </Form.Group>
                                                    </Col>
                                                </Row> */}

                                                <Row>
                                                    <Col className="mb-3">
                                                        <Form.Group as={Row}>
                                                            <Form.Label column="sm" sm={6} className="text-left">
                                                                {isDiscount() ? 'Отстъпка' : 'Надценка'} ({state.data.discount}%)
                                                            </Form.Label>
                                                            <Col sm={6}>
                                                                <InputGroup size="sm">
                                                                    <Form.Control
                                                                        type="number"
                                                                        step="0.01"
                                                                        readOnly
                                                                        value={getTotalDiscount().toFixed(2)}
                                                                        size="sm"
                                                                        className="price-input"
                                                                    />
                                                                    {state.data?.currency &&
                                                                        <InputGroup.Text>
                                                                            {state.data.currency.prefix || state.data.currency.sufix || '-'}
                                                                        </InputGroup.Text>
                                                                    }
                                                                </InputGroup>
                                                            </Col>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className="mb-3">
                                                        <Form.Group as={Row}>
                                                            <Form.Label column="sm" sm={6} className="text-left">
                                                                Данъчна основа
                                                            </Form.Label>
                                                            <Col sm={6}>
                                                                <InputGroup size="sm">
                                                                    <Form.Control
                                                                        type="number"
                                                                        step="0.01"
                                                                        readOnly
                                                                        value={getTotalNet().toFixed(2)}
                                                                        size="sm"
                                                                        className="price-input"
                                                                    />
                                                                    {state.data?.currency &&
                                                                        <InputGroup.Text>
                                                                            {state.data.currency.prefix || state.data.currency.sufix || '-'}
                                                                        </InputGroup.Text>
                                                                    }
                                                                </InputGroup>
                                                            </Col>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className="mb-3">
                                                        <Form.Group as={Row}>
                                                            <Form.Label column="sm" sm={6} className="text-left">
                                                                Начислен ДДС, основа
                                                            </Form.Label>
                                                            <Col sm={6}>
                                                                <InputGroup size="sm">
                                                                    <Form.Control
                                                                        type="number"
                                                                        step="0.01"
                                                                        readOnly
                                                                        value={getTotalVat().toFixed(2)}
                                                                        size="sm"
                                                                        className="price-input"
                                                                    />
                                                                    {state.data?.currency &&
                                                                        <InputGroup.Text>
                                                                            {state.data.currency.prefix || state.data.currency.sufix || '-'}
                                                                        </InputGroup.Text>
                                                                    }
                                                                </InputGroup>
                                                            </Col>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col>
                                                        <Form.Group as={Row}>
                                                            <Form.Label column="sm" sm={6} className="text-left">
                                                                Сума за плащане
                                                            </Form.Label>
                                                            <Col sm={6}>
                                                                <InputGroup size="sm">
                                                                    <Form.Control
                                                                        type="number"
                                                                        step="0.01"
                                                                        readOnly
                                                                        value={getTotalGross().toFixed(2)}
                                                                        size="sm"
                                                                        className="price-input"
                                                                    />
                                                                    {state.data?.currency &&
                                                                        <InputGroup.Text>
                                                                            {state.data.currency.prefix || state.data.currency.sufix || '-'}
                                                                        </InputGroup.Text>
                                                                    }
                                                                </InputGroup>
                                                            </Col>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                            </div>
                                        </Col>

                                    </Row>

                                    <div className="buttons space-between mt-3">
                                        <Button
                                            variant="secondary"
                                            onClick={e => navigate(-1)}
                                        >
                                            Отказ
                                        </Button>
                                        <SaveButton
                                            loading={state.loadingExit}
                                            disabled={state.loading}
                                            className="save"
                                            onClick={handleSaveAndExit}
                                        >
                                            Запази данните
                                        </SaveButton>
                                        <SaveButton
                                            loading={state.loadingBase}
                                            disabled={state.loading}
                                            className="save"
                                            onClick={handleSave}
                                        >
                                            Запази и продължи
                                        </SaveButton>
                                        <SaveButton
                                            loading={state.loadingSend}
                                            disabled={state.loading}
                                            className="save"
                                            onClick={handleSaveAndSend}
                                        >
                                            Запази и изпрати
                                        </SaveButton>
                                    </div>
                                    {/* </Container> */}

                                </Form>

                                {(state.data.accounts || []).length > 0
                                    ?
                                    <Card className="mt-3" style={{ maxWidth: '800px' }}>
                                        <Card.Header>
                                            Свързани сметки
                                        </Card.Header>
                                        <Card.Body>
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            Номер
                                                        </th>
                                                        <th>
                                                            Дата
                                                        </th>
                                                        <th>
                                                            Клиент
                                                        </th>
                                                        <th>
                                                            Начин на плащане
                                                        </th>
                                                        <th className="text-right">
                                                            Сума
                                                        </th>
                                                        <th>
                                                            Статус
                                                        </th>
                                                        <th>
                                                            Свързани на
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {state.data.accounts.map(a =>
                                                        <tr key={a.id}>
                                                            <td>
                                                                {a.id}
                                                            </td>
                                                            <td>
                                                                {a.created_at ? moment(a.created_at).format('DD.MM.YYYY HH:mm') : ''}
                                                            </td>
                                                            <td>
                                                                {a.client?.name}
                                                            </td>
                                                            <td>
                                                                {a.paymentmethod?.name}
                                                            </td>
                                                            <td className="text-right">
                                                                <PriceValue prefix={a.currency?.prefix} sufix={a.currency?.sufix}>{a.total_real}</PriceValue>
                                                            </td>
                                                            <td>
                                                                {a.status?.name}
                                                            </td>
                                                            <td>
                                                                {a.linked_at ? moment(a.linked_at).format('DD.MM.YYYY HH:mm') : ''}
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </Table>
                                        </Card.Body>
                                    </Card>
                                    :
                                    ''
                                }
                            </>
                        }

                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Index;