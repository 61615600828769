import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import { Modal, Button, Tabs, Tab, FloatingLabel, Form, Row, Col } from 'react-bootstrap';
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons';

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//providers
import { useLanguageContext } from 'providers/Language'
import { useAppContext } from 'providers/App';

//misc
import SaveButton from 'components/misc/Button'
import Info from 'components/modals/Info';
import HelpInfo from 'components/misc/Info';


function AddOrEdit(props, ref) {

    const app = useAppContext();
    const langs = useLanguageContext();

    const defaultData = {
        langs: {},
        file: null,
    }

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        type: null,
        edit: false,
        data: defaultData,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null,
    });

    const [validations, setValidations] = useValidation();

    const validationModalRef = useRef(null);
    const leftArrowRef = useRef(null);
    const rightArrowRef = useRef(null);
    const inputFileRef = useRef(null);

    useImperativeHandle(ref, () => ({
        edit: (id, type) => {
            edit(id, type);
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        }
    }));

    useEffect(() => {
        loadData();
    }, [state.edit]);

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
        }));

        setValidations(null);

        hide();
    }

    const edit = (id, type) => {
        if (!type || ['category', 'article', 'page'].indexOf(type) === -1) {
            app.showError('Няма зададен тип: category, article, page');
        }

        setState(prev => ({
            ...prev,
            id: id,
            type: type,
            edit: new Date().getTime(),
            data: defaultData,
        }));

        show();
    }

    const refresh = () => {
        setState(prev => ({
            ...prev,
            edit: new Date().getTime(),
        }));
    }

    const loadData = () => {
        if (!state.id || !state.type) {
            return;
        }

        let url = {
            category: 'admin/seo/edit/category',
            article: 'admin/seo/edit/article',
            page: 'admin/seo/edit/page',
        }[state.type];

        Api.get(url, {
            params: {
                id: state.id,
            }
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data
            }))
        });
    }


    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = {
            category: 'admin/seo/update/category',
            article: 'admin/seo/update/article',
            page: 'admin/seo/update/page',
        }[state.type];

        let data = new FormData(e.target);

        data.append('id', state.id)

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (typeof state.onSuccess === 'function') {
                state.onSuccess(res.data)
            }

            hide()
        }).catch(error => {
            const _err = error.response;
            if (_err && _err.status && _err.status === 422) {
                hide(() => {
                    let modal = validationModalRef.current;

                    if (modal) {
                        modal.open();

                        modal.onClose(() => {
                            setValidations(_err.data.errors)
                            show();
                        });
                    }
                });
            }
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }));
        });
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const getLength = string => {
        if (string) {
            return String(string).length;
        }

        return 0;
    }

    useEffect(() => {
        if (!state.show) {
            return;
        }

        const onKeyPress = (e) => {
            if (e.key === 'ArrowRight') {
                rightArrowRef.current?.click();
            }

            if (e.key === 'ArrowLeft') {
                leftArrowRef.current?.click();
            }
        }

        window.addEventListener('keyup', onKeyPress);

        return () => {
            window.removeEventListener('keyup', onKeyPress);
        }
    }, [state.show]);

    const handleShowNextItem = e => {
        e.preventDefault();

        if (props.showNextItem) {
            props.showNextItem(state.id);
        }
    }

    const handleShowPrevItem = e => {
        e.preventDefault();

        if (props.showPrevItem) {
            props.showPrevItem(state.id);
        }
    }

    const handleRefresh = e => {
        e.preventDefault();

        refresh();
    }

    return (
        <>
            <Info
                ref={validationModalRef}
                mainMessage="Моля, попълнете всички задължителни полета, означени с червен цвят!"
            />

            <Modal centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                {props.showPrevItem &&
                    <span ref={leftArrowRef} className="arrow left" onClick={handleShowPrevItem}>
                        <ChevronLeft />
                    </span>
                }
                {props.showNextItem &&
                    <span ref={rightArrowRef} className="arrow right" onClick={handleShowNextItem}>
                        <ChevronRight />
                    </span>
                }
                <form onSubmit={handleSave}>
                    <Modal.Header>
                        <Modal.Title>
                            Редактиране на SEO съдържание
                        </Modal.Title>
                        <div className="buttons">
                            <button className="btn-reload" onClick={handleRefresh}>

                            </button>
                            <button className="btn-close" onClick={handleClose}>

                            </button>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <FloatingLabel label="Страница" className="mb-3">
                            <Form.Control
                                type="text"
                                placeholder="Страница"
                                value={state.data.url || '-'}
                                disabled
                            />
                        </FloatingLabel>
                        <FloatingLabel label="Име" className="mb-3">
                            <Form.Control
                                type="text"
                                placeholder="Име"
                                value={state.data.name || ''}
                                disabled
                            />
                        </FloatingLabel>
                        <Tabs
                            defaultActiveKey={langs.data[0].id}
                            className="mb-3"
                            transition={false}
                        >
                            {langs.data.map(lang =>
                                <Tab key={lang.id} eventKey={lang.id} title={lang.name} tabClassName={validations && validations.langs && validations.langs[lang.id] ? 'validate' : ''}>
                                    <FloatingLabel label="TITLE" className="mb-3">
                                        <Form.Control
                                            type="text"
                                            name={`langs[${lang.id}][title]`}
                                            placeholder="TITLE"
                                            value={state.data.langs[lang.id]?.title || ''}
                                            onChange={(e) => handleInputChange(e)}
                                            isInvalid={Boolean(validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].title)}
                                        />
                                        <HelpInfo>Препоръчителна дължина от 35 до 65 символа</HelpInfo>

                                        <Form.Control.Feedback type={getLength(state.data.langs[lang.id]?.title) >= 35 && getLength(state.data.langs[lang.id]?.title) <= 65 ? 'valid' : 'invalid'} style={{ display: 'block' }}>
                                            {getLength(state.data.langs[lang.id]?.title)}
                                        </Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            {validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].title && (validations.langs[lang.id].title[0] || validations.langs[lang.id].title)}
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                    <FloatingLabel label="META DESCRIPTION">
                                        <Form.Control
                                            as="textarea"
                                            name={`langs[${lang.id}][meta_description]`}
                                            placeholder="META DESCRIPTION"
                                            onChange={(e) => handleInputChange(e)}
                                            value={state.data.langs[lang.id]?.meta_description || ''}
                                            style={{ height: '100px' }}
                                            isInvalid={Boolean(validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].meta_description)}
                                        />
                                        <HelpInfo>Препоръчителна дължина от 70 до 250 символа</HelpInfo>

                                        <Form.Control.Feedback type={getLength(state.data.langs[lang.id]?.meta_description) >= 70 && getLength(state.data.langs[lang.id]?.meta_description) <= 250 ? 'valid' : 'invalid'} style={{ display: 'block' }}>
                                            {getLength(state.data.langs[lang.id]?.meta_description)}
                                        </Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            {validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].meta_description && (validations.langs[lang.id].meta_description[0] || validations.langs[lang.id].meta_description)}
                                        </Form.Control.Feedback>
                                    </FloatingLabel>

                                </Tab>
                            )}
                        </Tabs>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            className="save"
                        />
                    </Modal.Footer>
                </form>
            </Modal>
        </>

    )
}

export default forwardRef(AddOrEdit);
