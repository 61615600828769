import axios from "axios";
import { useEffect, useState } from "react";
import { Column } from '@ant-design/plots';
import Api from "helpers/Api";
import { ArrowDownShort, ArrowUpShort } from "react-bootstrap-icons";

function Summary(props) {
    const [state, setState] = useState({
        loading: true,
        refresh: false,
        setFilter: false,
        data: {
            "total_real": {
                "prev": 0,
                "curr": 0,
                "diff": 0,
                "operator": "="
            },
            "accounts_count": {
                "prev": 0,
                "curr": 0,
                "diff": 0,
                "operator": "="
            },
            "articles_count": {
                "prev": 0,
                "curr": 0,
                "diff": 0,
                "operator": "="
            },
            "clients_count": {
                "prev": 0,
                "curr": 0,
                "diff": 0,
                "operator": "="
            }
        },
        filter: {

        },
        request: null,
    });

    useEffect(() => {
        if (props.refresh) {
            loadData();
        }
    }, [state.refresh, props.refresh]);

    const loadData = () => {
        if (state.request) {
            state.request.cancel();
        }

        let request = axios.CancelToken.source();

        setState(prev => ({
            ...prev,
            request: request,
            loading: true,
        }));

        let url = 'dashboard/summary';

        Api.get(url, {
            params: state.filter,
            cancelToken: request.token
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data.items,
                currency: res.data.currency,
                loading: false,
                sorting: false,
                filter: {
                    ...prev.filter,
                    ...res.data.filter,
                }
            }));
        });
    }

    const getArrow = operator => {
        return {
            '>': <ArrowUpShort />,
            '<': <ArrowDownShort />,
            '=': ''
        }[operator];
    }

    const getColor = operator => {
        return {
            '>': 'text-success',
            '<': 'text-danger',
            '=': ''
        }[operator];
    }

    return (
        <>
            <div className="row">
                <div className="col-sm-6">
                    <div className="card widget-flat">
                        <div className="card-body">
                            <h5 className="text-muted fw-normal mt-0">Оборот</h5>
                            <h3 className="mt-3 mb-3">{state.data.total_real?.curr.toFixed(2)} {state.currency?.sufix}</h3>
                            <p className="mb-0 text-muted text-nowrap">
                                <span className={`${getColor(state.data.total_real?.operator)} text-nowrap me-2`}>
                                    {getArrow(state.data.total_real?.operator)}
                                    {state.data.total_real?.diff}%
                                </span>
                                <span className="text-nowrap">Спрямо вчера</span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-sm-6">
                    <div className="card widget-flat">
                        <div className="card-body">
                            <h5 className="text-muted fw-normal mt-0">Продажби</h5>
                            <h3 className="mt-3 mb-3">{state.data.accounts_count?.curr}</h3>
                            <p className="mb-0 text-muted text-nowrap">
                                <span className={`${getColor(state.data.accounts_count?.operator)} text-nowrap me-2`}>
                                    {getArrow(state.data.accounts_count?.operator)}
                                    {state.data.accounts_count?.diff}%
                                </span>
                                <span className="text-nowrap">Спрямо вчера</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-6">
                    <div className="card widget-flat">
                        <div className="card-body">
                            <h5 className="text-muted fw-normal mt-0">Поръчки</h5>
                            <h3 className="mt-3 mb-3">{state.data.articles_count?.curr}</h3>
                            <p className="mb-0 text-muted text-nowrap">
                                <span className={`${getColor(state.data.articles_count?.operator)} text-nowrap me-2`}>
                                    {getArrow(state.data.articles_count?.operator)}
                                    {state.data.articles_count?.diff}%
                                </span>
                                <span className="text-nowrap">Спрямо вчера</span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-sm-6">
                    <div className="card widget-flat">
                        <div className="card-body">
                            <h5 className="text-muted fw-normal mt-0">Клиенти</h5>
                            <h3 className="mt-3 mb-3">{state.data.clients_count?.curr}</h3>
                            <p className="mb-0 text-muted text-nowrap">
                                <span className={`${getColor(state.data.clients_count?.operator)} text-nowrap me-2`}>
                                    {getArrow(state.data.clients_count?.operator)}
                                    {state.data.clients_count?.diff}%
                                </span>
                                <span className="text-nowrap">Спрямо вчера</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Summary;