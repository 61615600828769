import React, { useState, useImperativeHandle, forwardRef, useEffect } from 'react'
import { Modal, Button, Form } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import Alert from 'components/misc/Alert';
import SaveButton from 'components/misc/Button'
import Api from 'helpers/Api';
import NoDataFound from 'components/misc/NoDataFound';
import { CaretDownFill, CaretRight, HouseDoorFill } from 'react-bootstrap-icons';

function Categorisation(props, ref) {

    const defaultData = {
        categories: [],
        master_cat_id: null,
    }

    const [state, setState] = useState({
        show: false,
        params: null,
        data: defaultData,
        onSuccess: null,
        onClose: null,
        onEntering: null,
        onExiting: null,
    });

    const [categories, setCategories] = useState([]);
    const [expandedCategories, setExpandedCategories] = useState([]);

    useImperativeHandle(ref, () => ({
        open: (data) => {
            // console.log(data);

            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    categories: data.categories,
                    master_cat_id: data.master_cat_id,
                }
            }));

            handleShow();
        },
        close: () => {
            handleClose();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        },
        onCancel: fn => {
            setState(prev => ({
                ...prev,
                onCancel: fn
            }));
        }
    }));

    useEffect(() => {
        if (!state.show) {
            return;
        }

        if (categories.length) {
            return;
        }

        Api.get('articles/categories')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setCategories(res.data);
                }
            });
    }, [state.show]);

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const handleShow = () => {
        show();
    }


    const handleClose = () => {
        hide();
    }

    const handleSave = e => {
        e.preventDefault();

        // setState(prev => ({
        //     ...prev,
        //     loading: true,
        // }));

        hide();

        if (typeof state.onSuccess === 'function') {
            state.onSuccess({
                categories: state.data.categories,
                master_cat_id: state.data.master_cat_id,
            });
        }
    }

    const renderCategories = (categories, level = 0) => {
        let output = [];

        let store = null;

        categories.map(category => {
            if (level === 0 && category.store_id !== store && category.store_name) {
                output.push(
                    <div style={{
                        background: 'cornsilk',
                        color: '#505050',
                        padding: '3px 10px',
                        margin: '3px 0'
                    }}>
                        {category.store_name}
                    </div>
                );
            }

            output.push(
                <React.Fragment key={category.id}>
                    <div style={{ display: level === 0 || isCategoryExpanded(category.parent) ? 'flex' : 'none', alignItems: 'center', paddingLeft: (level * 20) + 'px', }}>

                        <span
                            style={{ marginRight: '3px', marginBottom: '3px', cursor: 'pointer', width: '16px' }}
                            onClick={e => expandCategory(category)}
                        >
                            {category.children.length > 0
                                ?
                                isCategoryExpanded(category) ? <CaretDownFill size="13" /> : <CaretRight size="13" />
                                :
                                ''
                            }
                        </span>

                        <Form.Check
                            type="checkbox"
                            id={Math.random().toString() + category.id}
                            name="categories[]"
                            value={category.id}
                            label={category?.translation?.name || category.name}
                            checked={isCategorySelected(category.id)}
                            disabled={isCategorySelected(category.parent_id)}
                            onChange={e => toggleCategory(category)}
                            style={{ color: hasChildCategorySelected(category) ? '#084298' : 'black' }}
                        />

                        {isCategorySelected(category.id) &&
                            <span className="master" style={{ marginLeft: '10px', }} onClick={e => setMasterCategory(category)}>
                                {isCategoryMaster(category.id) ? <HouseDoorFill /> : ''}
                            </span>
                        }
                    </div>
                    <div style={{ display: isCategoryExpanded(category) ? 'initial' : 'none' }}>
                        {renderCategories(category.children, level + 1)}
                    </div>
                </React.Fragment>
            );

            store = category.store_id;
        });

        return output;
    }

    const toggleCategory = category => {
        if (isCategorySelected(category.id)) {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    categories: prev.data.categories.filter(c => Number(c.id) !== Number(category.id)),
                    master_cat_id: isCategoryMaster(category.id) ? null : prev.data.master_cat_id,
                }
            }));
        } else {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    categories: prev.data.categories.concat(category),
                    master_cat_id: prev.data.categories.length === 0 ? category.id : prev.data.master_cat_id,
                }
            }));
        }
    }

    const isCategorySelected = id => {
        return Boolean(state.data.categories.find(c => Number(c.id) === Number(id)));
    }

    const isCategoryMaster = id => {
        return Number(state.data.master_cat_id) === Number(id);
    }

    const hasChildCategorySelected = category => {
        for (let i = 0; i < category.children.length; i++) {
            let child = category.children[i];

            if (isCategorySelected(child.id)) {
                return true;
            }

            if (hasChildCategorySelected(child)) {
                return true;
            }
        }

        return false;
    }

    const setMasterCategory = category => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                master_cat_id: category.id,
            }
        }));
    }

    const expandCategory = (category, check = true) => {

        if (!category) {
            return;
        }

        if (check && isCategoryExpanded(category)) {
            setExpandedCategories(prev => prev.filter(i => Number(i) !== Number(category.id)))
        } else {
            setExpandedCategories(prev => prev.concat(Number(category.id)))
        }
    }

    const isCategoryExpanded = category => {

        if (!category) {
            return false;
        }

        let expanded = expandedCategories.indexOf(Number(category.id)) > -1;

        if (expanded) {
            return true;
        }

        return false;
    }

    // console.log(state.data)

    return createPortal(
        <Modal size="md" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
            <form onSubmit={handleSave}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Категоризация
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {categories.length === 0
                        ?
                        <NoDataFound size="sm">
                            Няма създадени категории!
                            <div style={{ fontSize: '0.85rem' }}>
                                Създайте категории и добавете артикула към тях.
                            </div>
                        </NoDataFound>
                        :
                        <div className="categories">
                            <Alert>
                                Категорията маркирана с <span className="master" >
                                    <HouseDoorFill />
                                </span> е главна.
                            </Alert>
                            {renderCategories(categories)}
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Отказ
                    </Button>
                    <SaveButton
                        loading={state.loading}
                        className="save"
                    />
                </Modal.Footer>
            </form>
        </Modal>,
        document.body
    )

}

export default forwardRef(Categorisation);