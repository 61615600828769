export const TYPES = [
    {
        id: 1,
        type: 'invoice',
        name: 'Фактура'
    },
    {
        id: 2,
        type: 'creditnote',
        name: 'Кредитно известие'
    },
    {
        id: 3,
        type: 'debitnote',
        name: 'Дебитно известие'
    },
    {
        id: 4,
        type: 'proforma',
        name: 'Проформа'
    },
    {
        id: 5,
        type: 'stock',
        name: 'Стокова разписка'
    },
];

export const CREDIT_NOTE = 2;
export const DEBIT_NOTE = 3;