import { useNavigate } from "react-router-dom"
import moment from "moment"
import { Col, Dropdown, FloatingLabel, Form, Row } from 'react-bootstrap'

//providers
// import { useAuthDataContext } from "providers/Auth"

//helpers
import { useQuery } from 'helpers/Url'
import Autocomplete from "components/misc/Autocomplete"
import DatePicker from "components/partials/filter/DatePicker"
import { useState } from "react"

function Filter(props) {
    const history = useNavigate()
    const query = useQuery()

    // const auth = useAuthDataContext();

    const [filters, setFilters] = useState({
        show: false,
    });

    const handleDateChange = (date, name) => {
        props.handleSearch(name, date ? moment(date).format('YYYY-MM-DD') : '');
    }

    const handleSearch = e => {
        const name = e.target.name
        const value = e.target.value

        props.handleSearch(name, value)
    }

    const handleChangeClient = data => {
        props.handleSearch({
            client_id: data?.id || '',
        });
    }

    const handleChangeClientGroup = data => {
        let checked = data.map(d => d.id);

        props.handleSearch({
            group_id: checked
        });
    }

    const handleChangeClientSource = data => {
        let checked = data.map(d => d.id);

        props.handleSearch({
            source_id: checked
        });
    }

    const handleChangeClientSubscription = data => {
        let checked = data.map(d => d.id);

        props.handleSearch({
            ml_subscribed: checked
        });
    }

    const handleFromDateChange = e => {
        const name = e.target.name
        const value = e.target.value

        let date = value ? moment(value).format('YYYY-MM-DD') : '';

        props.handleSearch({
            created_from_date: date,
            created_to_date: date,
        });
    }

    const handleToDateChange = e => {
        const value = e.target.value

        let date = value ? moment(value).format('YYYY-MM-DD') : '';

        if (date && props.filter.created_from_date > date) {
            return;
        }

        props.handleSearch({
            created_to_date: date,
        });
    }

    const toggleFilters = () => {
        setFilters(prev => ({
            ...prev,
            show: !prev.show,
        }))
    }

    const getMoreFiltersValue = () => {
        let data = [];


        if (props.filter.last_order_from_date) {
            data.push(1);
        }

        if (props.filter.last_order_to_date) {
            data.push(1);
        }

        if ((props.filter.source_id || []).length > 0) {
            data.push(1);
        }

        if ((props.filter.ml_subscribed || []).length > 0) {
            data.push(1);
        }

        if (props.filter.deleted) {
            data.push(1);
        }

        if (data.length === 0) {
            return 'Няма избрани';
        }

        return data.length + ' избрани';
    }

    return (
        <div className="row page-filter">
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <FloatingLabel label="Търсене">
                    <Form.Control
                        type="text"
                        placeholder="Търсене"
                        name="search"
                        value={props.filter.search || ''}
                        onChange={handleSearch}
                    />
                </FloatingLabel>
            </div>
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <Autocomplete
                    inputPlaceholder="Клиент"
                    url="autocomplete/clients"
                    inputIdName="client_id"
                    // inputValue={state.data?.supplier?.name}
                    inputIdValue={props.filter.client_id || ''}
                    onChange={handleChangeClient}
                    onInputChange={handleChangeClient}
                />
            </div>
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <Autocomplete
                    inputPlaceholder="Клиентска група"
                    url="autocomplete/client-groups"
                    inputIdName="group_id[]"
                    selectedIds={props.filter.group_id || []}
                    onChange={handleChangeClientGroup}
                    onInputChange={handleChangeClientGroup}
                    multiple
                    filterable={false}
                />
            </div>
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <DatePicker
                    placeholder="Създаден от"
                    name="created_from_date"
                    value={props.filter.created_from_date || ''}
                    onChange={handleFromDateChange}
                />
            </div>

            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <DatePicker
                    placeholder="Създаден до"
                    name="created_to_date"
                    value={props.filter.created_to_date || ''}
                    onChange={handleToDateChange}
                />
            </div>
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <Dropdown drop="down" onToggle={toggleFilters} show={filters.show}>
                    <FloatingLabel label="Още филтри">
                        <Form.Control
                            type="text"
                            placeholder="Още филтри"
                            value={getMoreFiltersValue()}
                            onClick={toggleFilters}
                            readOnly
                            style={{ cursor: 'pointer' }}
                        />
                    </FloatingLabel>
                    <Dropdown.Menu align="start" style={{ padding: '15px', width: '450px', right: 0 }}>
                        <Row className="mb-3">
                            <Col>
                                <DatePicker
                                    placeholder="Последна поръчка от"
                                    name="last_order_from_date"
                                    value={props.filter.last_order_from_date || ''}
                                    onChange={e => handleDateChange(e.target.value, e.target.name)}
                                />
                            </Col>
                            <Col>
                                <DatePicker
                                    placeholder="Последна поръчка до"
                                    name="last_order_to_date"
                                    value={props.filter.last_order_to_date || ''}
                                    onChange={e => handleDateChange(e.target.value, e.target.name)}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <Autocomplete
                                    inputPlaceholder="Източник"
                                    url="autocomplete/client-sources"
                                    inputIdName="source_id[]"
                                    selectedIds={props.filter.source_id || []}
                                    onChange={handleChangeClientSource}
                                    onInputChange={handleChangeClientSource}
                                    renderInputText={data => data.translation?.name || data?.name}
                                    renderText={data => data.translation?.name || data?.name}
                                    multiple
                                    filterable={false}
                                />
                            </Col>
                            <Col>
                                <Autocomplete
                                    inputPlaceholder="Maillist абонамент"
                                    data={[
                                        {
                                            id: -2,
                                            name: 'Според групата'
                                        },
                                        {
                                            id: 1,
                                            name: 'Синхронизирай'
                                        },
                                        {
                                            id: '0',
                                            name: 'Несихнронизирай'
                                        },
                                        {
                                            id: -1,
                                            name: 'Проблемен клиент - Не'
                                        }
                                    ]}
                                    inputIdName="ml_subscribed[]"
                                    selectedIds={props.filter.ml_subscribed || []}
                                    onChange={handleChangeClientSubscription}
                                    onInputChange={handleChangeClientSubscription}
                                    multiple
                                    filterable={false}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FloatingLabel label="Изтрити">
                                    <Form.Select
                                        name="deleted"
                                        value={props.filter.deleted || ''}
                                        onChange={handleSearch}
                                    >
                                        <option value="">По подразбиране</option>
                                        <option value="with-deleted">Покажи изтрити</option>
                                        <option value="only-deleted">Покажи само изтрити</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    )
}

export default Filter;
