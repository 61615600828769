import { forwardRef, useImperativeHandle } from "react";
import { Form } from "react-bootstrap";

function ArticleID(props, ref) {

    useImperativeHandle(ref, () => ({
        getName: getName,
        getType: getType,
        getTitle: getTitle,
        hasSettings: hasSettings,
        getSettings: getSettings,
        getDefaultOptions: () => ({ action: 'skip' })
    }));

    const getName = () => {
        return 'Артикул ID';
    }

    const getType = () => {
        return 'article_id';
    }

    const getTitle = () => {
        return 'Идентификатор на артикула';
    }

    const hasSettings = () => true;

    const getSettings = ({ state, setConfig }) => {
        return (
            <div>
                <label>
                    Действия при несъществуващ артикул:
                </label>
                <div>
                    <Form.Check
                        id={'cb-skip'}
                        type="radio"
                        label="Прескочи реда"
                        checked={state.options?.action === 'skip'}
                        onChange={e => setConfig('action', 'skip')}
                    />
                    <Form.Check
                        id={'cb-abort'}
                        type="radio"
                        label="Прекрати импорта"
                        checked={state.options?.action === 'abort'}
                        onChange={e => setConfig('action', 'abort')}
                    />
                </div>
            </div>
        )

    }

    return getName();
}

export default forwardRef(ArticleID)