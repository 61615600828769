import { Form } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { usePermissionsContext } from 'providers/Permissions';

import {
    Plus,
    Dash
} from 'react-bootstrap-icons';

function Item(props) {

    const data = props.data;
    const stores = props.stores;
    const permissionsStores = props.permissionsStores;
    const modules = props.modules;

    const initialState = {
        treeWrap: true,
        checkedGroup: {},
        checkedPermission: {},
        checkedStore: {},
        refresh: false
    }

    const [state, setState] = useState(initialState)

    useEffect(() => {
        handlePermissionChecked(modules, stores, permissionsStores)
    }, [permissionsStores])

    const handleTreeWrap = () => {
        setState(prev => ({
            ...prev,
            treeWrap: !prev.treeWrap
        }));
    }

    const handlePermissionChange = (permission_id, store_id) => {
        let name = 'permission-' + permission_id + '-' + store_id
        let groupName = 'group-' + data.id + '-' + store_id
        let obj = state.checkedPermission
        let groupObj = state.checkedGroup

        obj[name] = !state.checkedPermission[name]

        stores.map(r => {
            let count = 0
            data.permissions.map(p => {
                let name = 'permission-' + p.id + '-' + r.id

                if (store_id == r.id) {
                    if (obj[name]) {
                        count++
                    }

                    groupObj[groupName] = false;
                    if (count == data.permissions.length) {
                        groupObj[groupName] = true;
                    }
                }

            })
        })

        let storeObj = state.checkedStore;

        stores.map(r => {
            if (r.id == store_id) {
                let storeName = 'store-' + store_id
                let count = 0;
                modules.map(data => {
                    let groupName = 'group-' + data.id + '-' + store_id
                    if (groupObj[groupName]) count++
                })
                storeObj[storeName] = false;
                if (modules.length == count) {
                    storeObj[storeName] = true;
                }
            }
        })

        let updateData = ({
            checkedPermission: obj,
            checkedGroup: groupObj,
            checkedStore: state.checkedStore,
        })

        updateState(updateData)
    }

    const handleGroupChange = (store_id) => {
        let groupName = 'group-' + data.id + '-' + store_id

        let obj = state.checkedPermission
        let groupObj = state.checkedGroup

        groupObj[groupName] = !state.checkedGroup[groupName]

        data.permissions.map(p => {
            let name = 'permission-' + p.id + '-' + store_id
            obj[name] = false;
            if (groupObj[groupName]) {
                obj[name] = true;
            }
        })

        const modules = props.modules;
        let storeObj = state.checkedStore;

        stores.map(r => {
            if (r.id == store_id) {
                let storeName = 'store-' + store_id
                let count = 0;
                modules.map(data => {
                    let groupName = 'group-' + data.id + '-' + store_id
                    if (groupObj[groupName]) count++
                })
                storeObj[storeName] = false;
                if (modules.length == count) {
                    storeObj[storeName] = true;
                }
            }
        })

        let updateData = ({
            checkedPermission: obj,
            checkedGroup: groupObj,
            checkedStore: storeObj,
        })

        updateState(updateData)
    }

    const handlePermissionChecked = (modules, stores, permissionsStores) => {
        let permissionObject = {}
        let permissionStoreObject = {}
        let permissionGroupObject = {}

        modules.map(data => {
            data.permissions.map(p => {
                stores.map(r => {

                    let checked = false;
                    permissionsStores.map(pr => {
                        if (p.id == pr.permission_id && pr.store_id == r.id) {
                            checked = true;
                        }
                    })

                    let name = 'permission-' + p.id + '-' + r.id
                    permissionObject[name] = checked ? true : false
                })
            })

            stores.map(r => {
                let count = 0;
                data.permissions.map(p => {
                    let name = 'permission-' + p.id + '-' + r.id
                    let groupName = 'group-' + data.id + '-' + r.id

                    if (permissionObject[name]) {
                        count++
                    }

                    if (count == data.permissions.length) {
                        permissionGroupObject[groupName] = true;
                    } else {
                        permissionGroupObject[groupName] = false;
                    }
                })
            })
        });

        stores.map(r => {
            let checkedGroup = 0;
            modules.map(data => {
                let count = 0;
                data.permissions.map(p => {
                    let name = 'permission-' + p.id + '-' + r.id
                    let groupName = 'group-' + data.id + '-' + r.id
                    if (permissionObject[name]) {
                        count++
                    }

                    if (count == data.permissions.length) {
                        checkedGroup++
                    }
                })
            })

            let storeName = 'store-' + r.id
            if (checkedGroup == modules.length) {
                permissionStoreObject[storeName] = true;
            } else {
                permissionStoreObject[storeName] = false;
            }

        })

        let updateData = ({
            checkedPermission: permissionObject,
            checkedGroup: permissionGroupObject,
            checkedStore: permissionStoreObject
        })

        console.log(updateData)


        updateState(updateData)
    }

    const updateState = (data) => {
        setState(prev => ({
            ...prev,
            checkedStore: data.checkedStore,
            checkedGroup: data.checkedGroup,
            checkedPermission: data.checkedPermission
        }))
    }

    return (
        <>
            <tr
                key={'data-' + data.id}
                style={{ background: 'cornsilk' }}
            >
                <td>
                    <div style={{ display: 'flex' }}>
                        <div style={{ paddingRight: '10px', cursor: 'pointer' }}>
                            {state.treeWrap ? <Dash
                                onClick={handleTreeWrap}
                            /> :
                                <Plus
                                    onClick={handleTreeWrap}
                                />
                            }
                        </div>
                        {data.translation?.name || data.name}
                    </div>
                </td>
                {stores.map((r, key) =>
                    <td key={r.id} className="text-center">
                        <Form.Check
                            type="switch"
                            name={`group-${data.id}-${r.id}`}
                            data-module-id={data.id}
                            data-store-id={r.id}
                            checked={state.checkedGroup['group-' + data.id + '-' + r.id] || false}
                            onChange={() => handleGroupChange(r.id)}
                            className="yellow"
                        />
                    </td>
                )}
            </tr>
            {data.permissions.map(p =>
                <tr key={p.id} className={!state.treeWrap ? 'hidden' : ''} >
                    <td style={{ paddingLeft: '60px' }}>{p.translation?.name || p.name}</td>
                    {stores.map((r, key) =>
                        <td key={r.id} className="text-center">
                            <Form.Check
                                key={state.checkedPermission['permission-' + p.id + '-' + r.id]}
                                name={`permission[${p.id}][]`}
                                value={r.id}
                                type="switch"
                                data-module-id={data.id}
                                data-store-id={r.id}
                                data-permission-id={p.id}
                                checked={state.checkedPermission['permission-' + p.id + '-' + r.id] || false}
                                onChange={() => handlePermissionChange(p.id, r.id)}
                            />
                        </td>
                    )}
                </tr>
            )}
        </>
    )
}

export default Item;
