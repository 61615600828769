import { useEffect, useRef, useState } from 'react';
import Api from 'helpers/Api';
import { useQuery } from 'helpers/Url';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Col, ListGroup, Placeholder, Row } from 'react-bootstrap';

import Options from './partials/Options';

import Question from 'components/modals/Question';
import AddOrEdit from './partials/AddOrEdit';
import Layout from 'components/layout/Layout';

//misc
import Loader from 'components/misc/Loader';
import { TrashFill } from 'react-bootstrap-icons';
import IFrame from 'components/partials/Iframe';
import NoDataFound from 'components/misc/NoDataFound';
import axios from 'axios';
import { sortable } from 'react-sortable';

function SortableItem(props) {
    return (
        <div {...props}>
            {props.children}
        </div>
    )
}

const SortItem = sortable(SortableItem);

let timeout;

function Index() {

    const location = useLocation();
    const history = useNavigate();
    const query = useQuery();
    // const auth = useAuthDataContext();

    const addOrEditModalRef = useRef(null);
    const deleteModalRef = useRef(null);

    const [state, setState] = useState({
        loading: true,
        refresh: false,
        data: [],
        filter: {

        },
    });

    const [iframeLoaded, setIframeLoaded] = useState(false);

    useEffect(() => {
        loadData();
        loadIframe();
    }, [state.refresh]);

    const loadData = () => {

        loading(true);

        let url = 'navigation/get';

        Api.get(url, {
            params: state.filter
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data.navigation,
            }));

            loading(false);
        });
    }

    const loadIframe = () => {
        setIframeLoaded(false);

        axios.get(process.env.REACT_APP_URL + '/cms/preview/header')
            .then(res => {
                setIframeLoaded(res.data);
            });
    }

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }));
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
            },
            refresh: new Date().getTime(),
        }));
    }

    const handleAdd = () => {
        let modal = addOrEditModalRef.current;

        modal.add();

        modal.onSuccess(() => {
            refresh(true);
        });
    }

    // update
    const handleEdit = id => {
        let modal = addOrEditModalRef.current;

        modal.edit(id);

        modal.onSuccess(() => {
            refresh(false);
        });
    }

    // delete
    const handleDelete = id => {
        let modal = deleteModalRef.current;

        modal.open();
        modal.onSuccess(() => {
            handleDestroy(id);
        })
    }

    const handleDestroy = id => {
        Api.post('/navigation/delete', {
            id: id
        }).then(res => {
            refresh(false);
        });
    }

    const handleSort = items => {
        setState(prev => ({
            ...prev,
            data: items
        }));

        let ids = items.map(item => item.id);

        // console.log(ids);

        Api.post('/navigation/order', {
            ids: ids
        }).then(res => {
            loadIframe();
        });
    }

    const createMarkup = html => {
        return { __html: html };
    }

    return (
        <>

            <AddOrEdit
                ref={addOrEditModalRef}
            />

            <Question
                ref={deleteModalRef}
                mainMessage="Искате ли тази категория да бъде скрита от навигацията?"
                agreeBtnText="Да"
                agreeBtnClass="remove"
            />

            <Layout>
                <div className="container-fluid">

                    <div className="page-head">
                        <h1 className="display-6">
                            Навигация
                        </h1>

                        <div className="buttons">
                            <Button variant="outline-dark" onClick={handleAdd}>
                                Добави
                            </Button>
                        </div>
                    </div>

                    <br />

                    <div className="panel">
                        {/* <Filter
                            filter={state.filter}
                            handleSearch={handleSearch}
                        /> */}


                        <>
                            <Row>
                                <Col sm={2}>
                                    {state.loading
                                        ?
                                        <>
                                            <Placeholder animation="glow">
                                                <Placeholder xs={12} style={{ height: '40px', margin: '2px 0' }} />
                                                <Placeholder xs={12} style={{ height: '40px', margin: '2px 0' }} />
                                                <Placeholder xs={12} style={{ height: '40px', margin: '2px 0' }} />
                                                <Placeholder xs={12} style={{ height: '40px', margin: '2px 0' }} />
                                                <Placeholder xs={12} style={{ height: '40px', margin: '2px 0' }} />
                                                <Placeholder xs={12} style={{ height: '40px', margin: '2px 0' }} />
                                                <Placeholder xs={12} style={{ height: '40px', margin: '2px 0' }} />
                                                <Placeholder xs={12} style={{ height: '40px', margin: '2px 0' }} />
                                            </Placeholder>
                                        </>
                                        :
                                        state.data.length === 0 ?
                                            <NoDataFound></NoDataFound>
                                            :
                                            <ListGroup>
                                                {state.data.map((nav, index) =>
                                                    <SortItem
                                                        key={index}
                                                        items={state.data}
                                                        onSortItems={handleSort}
                                                        sortId={index}
                                                    >
                                                        <ListGroup.Item key={nav.id} style={{
                                                            cursor: 'move',
                                                            display: 'flex',
                                                            alignItems: 'center'
                                                        }}>
                                                            <span>
                                                                {nav.category?.translation?.name || nav.category?.name}
                                                            </span>
                                                            <Button
                                                                size="sm"
                                                                variant="danger"
                                                                style={{ marginLeft: 'auto' }}
                                                                onClick={e => handleDelete(nav.id)}
                                                            >
                                                                <TrashFill />
                                                            </Button>
                                                        </ListGroup.Item>
                                                    </SortItem>
                                                )}
                                            </ListGroup>
                                    }
                                </Col>
                                <Col sm={10}>
                                    {iframeLoaded &&
                                        <IFrame
                                            // src={'http://localhost/plmd/public/cms/preview/header'}
                                            // onPageLoad={e => setIframeLoaded(true)}
                                            style={{
                                                minHeight: '800px',
                                                height: '100%',
                                                display: iframeLoaded ? 'block' : 'none'
                                            }}
                                        >
                                            <div dangerouslySetInnerHTML={createMarkup(iframeLoaded)} />
                                        </IFrame>
                                    }

                                    {!iframeLoaded &&
                                        <Placeholder animation="glow">
                                            <Placeholder xs={12} style={{ height: '120px' }} />
                                        </Placeholder>
                                    }
                                </Col>
                            </Row>
                        </>

                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Index;
