import { useNavigate } from "react-router-dom"
import moment from "moment"
import { FloatingLabel, Form } from 'react-bootstrap'

//providers
// import { useAuthDataContext } from "providers/Auth"

//helpers
import { useQuery } from 'helpers/Url'

function Filter(props) {
    const history = useNavigate()
    const query = useQuery()

    // const auth = useAuthDataContext();


    const handleDateChange = (date, name) => {
        props.handleSearch(name, moment(date).format('YYYY-MM-DD'));
    }

    const handleSearch = e => {
        const name = e.target.name
        const value = e.target.value

        props.handleSearch(name, value)
    }

    const handleCheck = e => {
        const name = e.target.name
        const value = e.target.checked

        let check = 0;
        if (Boolean(value)) {
            check = 1;
        }

        props.handleSearch(name, check)
    }



    return (
        <div className="row page-filter">
            <div className="col col-auto col-md-2 col-12 mb-2">
                <FloatingLabel label="Търсене">
                    <Form.Control
                        type="text"
                        placeholder="Търсене"
                        name="search"
                        value={props.filter.search || ''}
                        onChange={handleSearch}
                    />
                </FloatingLabel>
            </div>

            <div className="col col-auto col-md-2 col-12 mb-2">
                <Form.Check
                    type="switch"
                    name={`eu_countries`}
                    label="Европейски държави"
                    checked={props.filter.eu_countries == 1 ? true : false}
                    onChange={handleCheck}
                />
            </div>

            <div className="col col-auto col-md-2 col-12 mb-2">
                <Form.Check
                    type="switch"
                    name={`balkan_countries`}
                    label="Балкански държави"
                    onChange={handleCheck}
                    checked={props.filter.balkan_countries == 1 ? true : false}
                />
            </div>

            <div className="col col-auto col-md-2 col-12 mb-2">
                <Form.Check
                    type="switch"
                    name={`has_delivery`}
                    label="Доставка"
                    onChange={handleCheck}
                    checked={props.filter.has_delivery == 1 ? true : false}
                />
            </div>
        </div>
    )
}

export default Filter;
