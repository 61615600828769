import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import moment from 'moment'
import { Modal, Button, Tabs, Tab, FloatingLabel, Form, Col } from 'react-bootstrap';
import { sortable } from 'react-sortable';

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//providers
import { useLanguageContext } from 'providers/Language'

//misc
import SaveButton from 'components/misc/Button'
import Info from 'components/modals/Info';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import Alert from 'components/misc/Alert';
import { TrashFill } from 'react-bootstrap-icons';

const ClassicEditor = window.ClassicEditor;

function SortableItem(props) {
    return (
        <div {...props}>
            {props.children}
        </div>
    )
}

const SortItem = sortable(SortableItem);

function AddOrEdit(props, ref) {

    const langs = useLanguageContext();

    const defaultData = {
        langs: {},
        images: []
    }

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        edit: false,
        data: defaultData,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null
    });

    const [validations, setValidations] = useValidation();

    const inputFileRef = useRef(null);
    const validationModalRef = useRef(null);

    useImperativeHandle(ref, () => ({
        add: () => {
            handleShow();
        },
        edit: (id) => {
            edit(id);
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        }
    }));

    useEffect(() => {
        loadData();
    }, [state.edit]);

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }


    const handleShow = () => {
        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
        }));

        show();
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
        }));

        setValidations(null);

        hide();
    }

    const edit = id => {
        setState(prev => ({
            ...prev,
            id: id,
            edit: new Date().getTime()
        }));

        show();
    }

    const loadData = () => {
        if (!state.id) {
            return;
        }

        Api.get('projects/edit?id=' + state.id)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data
                }))
            });
    }


    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = state.id ? 'projects/update' : 'projects/add';

        let data = new FormData(e.target);

        if (state.id) {
            data.append('id', state.id)
        }

        state.data.images.map((file, i) => {
            if (file.was_recently_attached) {
                data.append('images[' + i + ']', file);
            }
        });

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (typeof state.onSuccess === 'function') {
                state.onSuccess(res.data)
            }

            hide()
        }).catch(error => {
            const _err = error.response;
            if (_err && _err.status && _err.status === 422) {
                hide(() => {
                    let modal = validationModalRef.current;

                    if (modal) {
                        modal.open();

                        modal.onClose(() => {
                            setValidations(_err.data.errors)
                            show();
                        });
                    }
                });
            }
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }));
        });
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleDateChange = e => {
        const name = e.target.name + '_ymd'
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleShowAttach = e => {
        e.preventDefault();

        inputFileRef.current.click();
    }

    const handleAttach = e => {
        let files = [...e.target.files];

        files.forEach((f, i) => {
            let url = URL.createObjectURL(f);

            files[i].id = Math.random().toString(7).substring(2);
            files[i].was_recently_attached = true;
            files[i].url = url;
        });

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                images: prev.data.images.concat(files)
            }
        }));
    }

    const handleDetach = (id, index) => {

        let file = state.data.images.filter((f, i) => {
            return Number(f.id) === Number(id);
        })[0];

        if (!file.was_recently_attached) {
            Api.post('projects/delete-file', {
                id: id
            }).then(res => {
                if (res.data.success) {
                    removeFile(id);
                }
            });
        } else {
            removeFile(id);
        }

        if (validations?.images && validations.images[index]) {
            delete validations.images[index];
        }

        setValidations(validations);
    }

    const removeFile = id => {
        let files = state.data.images.filter((f, i) => {
            return Number(f.id) !== Number(id);
        });

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                images: files
            }
        }));
    }

    const handleSortFiles = items => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                images: items
            }
        }));
    }


    return (
        <>
            <Info
                ref={validationModalRef}
                mainMessage="Моля, попълнете всички задължителни полета, означени с червен цвят!"
            />

            <Modal centered size="lg" show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <form onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Проект
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Tabs
                            defaultActiveKey={langs.data[0].id}
                            className="mb-3"
                            transition={false}
                        >
                            {langs.data.map(lang =>
                                <Tab key={lang.id} eventKey={lang.id} title={lang.name} tabClassName={validations && validations.langs && validations.langs[lang.id] ? 'validate' : ''}>
                                    <FloatingLabel label="Заглавие" className="mb-3">
                                        <Form.Control
                                            type="text"
                                            name={`langs[${lang.id}][title]`}
                                            value={state.data.langs[lang.id]?.title || ''}
                                            placeholder="Заглавие"
                                            onChange={(e) => handleInputChange(e)}
                                            isInvalid={Boolean(validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].title)}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].title && (validations.langs[lang.id].title[0] || validations.langs[lang.id].title)}
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                    {/* <FloatingLabel label="Въведение">
                                        <Form.Control
                                            as="textarea"
                                            type="text"
                                            name={`langs[${lang.id}][intro]`}
                                            placeholder="Въведение"
                                            onChange={(e) => handleInputChange(e)}
                                            value={state.data.langs[lang.id]?.intro || ''}
                                            isInvalid={Boolean(validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].intro)}
                                            style={{
                                                height: '100px'
                                            }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].intro && (validations.langs[lang.id].intro[0] || validations.langs[lang.id].intro)}
                                        </Form.Control.Feedback>
                                    </FloatingLabel> */}

                                    <Col className="mt-2 mb-3">
                                        <Form.Label>Текст</Form.Label>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={state.data.langs[lang.id]?.text || ''}
                                            onChange={(event, editor) => {
                                                const name = `langs[${lang.id}][text]`
                                                const value = editor.getData();
                                                setState(name, value, 'data')
                                            }}
                                        />
                                        <input type="hidden" name={`langs[${lang.id}][text]`} value={state.data.langs[lang.id]?.text || ''} />
                                        <div className='validate'>
                                            {validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].text && (validations.langs[lang.id].text[0] || validations.langs[lang.id].text)}
                                        </div>
                                    </Col>

                                    <FloatingLabel label="Локация" className="mb-3">
                                        <Form.Control
                                            type="text"
                                            name={`langs[${lang.id}][location]`}
                                            value={state.data.langs[lang.id]?.location || ''}
                                            placeholder="Локация"
                                            onChange={(e) => handleInputChange(e)}
                                            isInvalid={Boolean(validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].location)}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].location && (validations.langs[lang.id].location[0] || validations.langs[lang.id].location)}
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Tab>
                            )}
                        </Tabs>

                        <div className="mt-3">
                            {state.data.images.length === 0
                                ?
                                <Alert variant="primary">
                                    Няма прикачени изображения
                                </Alert>
                                :
                                <>
                                    <Alert variant="primary">
                                        Можете да размествате подредбата на изображенията като придърпвате дадено изображение измежду останалите.
                                        <br />
                                        Първото изображение е главно и ще бъде показвано по подразбиране.
                                    </Alert>
                                    <div className="files mb-3 sortable-list">
                                        {state.data.images.map((file, index) =>
                                            <SortItem
                                                key={index}
                                                items={state.data.images}
                                                onSortItems={handleSortFiles}
                                                sortId={index}
                                                className="file-container"
                                            >
                                                <div className="file">

                                                    {file.type.includes('video')
                                                        ?
                                                        <video src={file.url} onError={e => e.target.classList.add('hidden')} />
                                                        :
                                                        <img src={file.url} onError={e => e.target.classList.add('hidden')} />
                                                    }

                                                    <div className="buttons">
                                                        <Button variant="danger" size="sm" onClick={e => handleDetach(file.id, index)}>
                                                            <TrashFill />
                                                        </Button>
                                                    </div>
                                                </div>

                                                <div className="validate">
                                                    {validations?.images && validations.images[index] && (validations.images[index][0] || validations.images[index])}
                                                </div>

                                                <input name={`files_position[${index}]`} value={file.was_recently_attached ? 0 : file.id} hidden readOnly />
                                            </SortItem>

                                        )}
                                    </div>
                                </>
                            }

                            <Button variant="outline-dark" size="sm" onClick={handleShowAttach}>
                                Прикачете изображения
                            </Button>

                            <input ref={inputFileRef} type="file" accept=".png,.jpeg,.jpg,.gif,.webp,.mp4" onChange={handleAttach} hidden multiple />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            className="save"
                        />
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default forwardRef(AddOrEdit);
