import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import moment from 'moment'
import { Modal, Button, Tabs, Tab, FloatingLabel, Form, Col, Row } from 'react-bootstrap';

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//providers
import { useLanguageContext } from 'providers/Language'

//misc
import SaveButton from 'components/misc/Button'
import Info from 'components/modals/Info';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import Alert from 'components/misc/Alert';
import { TrashFill } from 'react-bootstrap-icons';

const ClassicEditor = window.ClassicEditor;

function AddOrEdit(props, ref) {

    const langs = useLanguageContext();

    const defaultData = {
        langs: {},
    }

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        edit: false,
        data: defaultData,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null
    });

    const [validations, setValidations] = useValidation();

    const inputFileRef = useRef(null);
    const validationModalRef = useRef(null);

    useImperativeHandle(ref, () => ({
        add: () => {
            handleShow();
        },
        edit: (id) => {
            edit(id);
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        }
    }));

    useEffect(() => {
        loadData();
    }, [state.edit]);

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }


    const handleShow = () => {
        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
        }));

        show();
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
        }));

        setValidations(null);

        hide();
    }

    const edit = id => {
        setState(prev => ({
            ...prev,
            id: id,
            edit: new Date().getTime()
        }));

        show();
    }

    const loadData = () => {
        if (!state.id) {
            return;
        }

        Api.get('catalogues/edit?id=' + state.id)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data
                }))
            });
    }


    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = state.id ? 'catalogues/update' : 'catalogues/add';

        let data = new FormData(e.target);

        if (state.id) {
            data.append('id', state.id)
        }

        for (let [langId, langData] of Object.entries(state.data.langs)) {
            // console.log(langId);
            // console.log(langData)

            if (langData.file) {
                data.append(`langs[${langId}][file]`, langData.file);
            }

            if (langData.preview) {
                data.append(`langs[${langId}][preview]`, langData.preview);
            }
        }

        if (state.data.image) {
            data.append('image', state.data.image);
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (typeof state.onSuccess === 'function') {
                state.onSuccess(res.data)
            }

            hide()
        }).catch(error => {
            const _err = error.response;
            if (_err && _err.status && _err.status === 422) {
                hide(() => {
                    let modal = validationModalRef.current;

                    if (modal) {
                        modal.open();

                        modal.onClose(() => {
                            setValidations(_err.data.errors)
                            show();
                        });
                    }
                });
            }
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }));
        });
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleDateChange = e => {
        const name = e.target.name + '_ymd'
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleShowAttach = e => {
        e.preventDefault();

        // inputFileRef.current.click();

        e.currentTarget.parentNode.querySelector('input').click();
    }

    const handleAttachPreview = (e, langId) => {
        let file = e.target.files[0];

        let url = URL.createObjectURL(file);

        // file.id = Math.random().toString(7).substring(2);
        // file.was_recently_attached = true;
        // file.url = url;

        let obj = {
            preview: file,
            preview_url: url,
        }

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                langs: {
                    ...prev.data.langs,
                    [langId]: prev.data.langs[langId] ? {
                        ...prev.data.langs[langId],
                        ...obj
                    } : obj
                }
            }
        }));

        if (validations.langs) {
            delete validations.langs[langId]?.preview;
        }
    }

    const handleAttachFile = (e, langId) => {
        let file = e.target.files[0];

        let url = URL.createObjectURL(file);

        // file.id = Math.random().toString(7).substring(2);
        // file.was_recently_attached = true;
        // file.url = url;

        let obj = {
            file: file,
            file_url: url,
        }

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                langs: {
                    ...prev.data.langs,
                    [langId]: prev.data.langs[langId] ? {
                        ...prev.data.langs[langId],
                        ...obj
                    } : obj
                }
            }
        }));

        if (validations.langs) {
            delete validations.langs[langId]?.file;
        }
    }

    const handleDetach = e => {
        let file = state.data.file;

        if (!file.was_recently_attached) {
            Api.post('languages/remove-file', {
                id: state.data.id
            }).then(res => {
                if (res.data.success) {
                    removeFile();
                }
            });
        } else {
            removeFile();
        }

        if (validations?.image) {
            delete validations.image;
        }

        setValidations(validations);
    }

    const removeFile = id => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                file: null
            }
        }));
    }

    return (
        <>
            <Info
                ref={validationModalRef}
                mainMessage="Моля, попълнете всички задължителни полета, означени с червен цвят!"
            />

            <Modal centered size="md" show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <form onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Каталог
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Tabs
                            defaultActiveKey={langs.data[0].id}
                            className="mb-3"
                            transition={false}
                        >
                            {langs.data.map(lang =>
                                <Tab key={lang.id} eventKey={lang.id} title={lang.name} tabClassName={validations && validations.langs && validations.langs[lang.id] ? 'validate' : ''}>
                                    <FloatingLabel label="Име" className="mb-3">
                                        <Form.Control
                                            type="text"
                                            name={`langs[${lang.id}][name]`}
                                            value={state.data.langs[lang.id]?.name || ''}
                                            placeholder="Клиент"
                                            onChange={(e) => handleInputChange(e)}
                                            isInvalid={Boolean(validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].name)}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].name && (validations.langs[lang.id].name[0] || validations.langs[lang.id].name)}
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                    <Row className="mt-3">
                                        <Col className="text-center">
                                            <div>
                                                <>
                                                    <div className="file">
                                                        <img
                                                            style={{
                                                                width: '120px',
                                                                height: '150px',
                                                                objectFit: 'contain',
                                                                border: '1px solid #eee'
                                                            }}
                                                            src={state.data.langs[lang.id]?.preview_url}
                                                            onError={e => e.target.classList.add('hidden')}
                                                        />
                                                    </div>
                                                </>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className="mt-3">
                                        <Col className="text-center">

                                            <Button variant="outline-dark" size="sm" onClick={handleShowAttach}>
                                                Прикачете корица
                                            </Button>

                                            <div className="validate">
                                                {validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].preview && (validations.langs[lang.id].preview[0] || validations.langs[lang.id].preview)}
                                            </div>

                                            <input type="file" accept="image/*" onChange={e => handleAttachPreview(e, lang.id)} hidden />

                                            {/* {state.data.file &&
                                                <Button variant="outline-danger" size="sm" onClick={handleDetach} style={{ marginLeft: '5px' }}>
                                                    Премахнете изображението
                                                </Button>
                                            } */}

                                        </Col>
                                        <Col className="text-center">

                                            <Button variant="outline-dark" size="sm" onClick={handleShowAttach}>
                                                Прикачете каталог (PDF)
                                            </Button>

                                            <div className="validate">
                                                {validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].file && (validations.langs[lang.id].file[0] || validations.langs[lang.id].file)}
                                            </div>

                                            <input type="file" accept=".pdf" onChange={e => handleAttachFile(e, lang.id)} hidden />

                                            {/* {state.data.file &&
                                                <Button variant="outline-danger" size="sm" onClick={handleDetach} style={{ marginLeft: '5px' }}>
                                                    Премахнете изображението
                                                </Button>
                                            } */}

                                        </Col>
                                    </Row>

                                </Tab>
                            )}
                        </Tabs>



                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            className="save"
                        />
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default forwardRef(AddOrEdit);
