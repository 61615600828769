import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import { Tabs, Modal, Button, Row, Col, FloatingLabel, Form, Tab, Nav, InputGroup, Table } from 'react-bootstrap';

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//providers
import { useLanguageContext } from 'providers/Language'

//misc
import Autocomplete from "components/misc/Autocomplete"
import SaveButton from 'components/misc/Button'
import HelpInfo from 'components/misc/Info'
import Info from 'components/modals/Info'
import EditDevice from '../../devices/partials/AddOrEdit';
import BoricaSettings from './BoricaSettings';
import SmartUCFSettings from './SmartUCFSettings';

function AddOrEdit(props, ref) {

    const langs = useLanguageContext();

    const defaultData = {
        time: 1,
        has_settings: 0,
        langs: {},
        settings: {},
        codes: {}
    }

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        edit: false,
        data: defaultData,
        settings: {},
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null,
        mainTabId: 'main',
        tabId: langs.data[0]?.id,
    });

    const [categories, setCategories] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [groups, setGroups] = useState([]);

    const [validations, setValidations] = useValidation();

    const validationModalRef = useRef(null);
    const boricaSettingsModalRef = useRef(null);
    const smartUcfSettingsModalRef = useRef(null);
    const editDeviceModalRef = useRef(null);

    useImperativeHandle(ref, () => ({
        add: () => {
            handleShow();
        },
        edit: (id) => {
            edit(id);
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        }
    }));

    useEffect(() => {
        Api.get('payment-methods/categories')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setCategories(res.data);
                }
            })

        Api.get('payment-methods/currencies')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setCurrencies(res.data);
                }
            })

        Api.get('payment-methods/code-groups')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setGroups(res.data);
                }
            })

    }, [])

    useEffect(() => {
        loadData();
    }, [state.edit]);

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const handleShow = () => {
        setState(prev => ({
            ...prev,
            data: defaultData,
            settings: {},
            mainTabId: 'main',
            id: null,
        }));

        show();
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            settings: {},
            id: null,
        }));

        setValidations(null);

        hide();
    }

    const edit = id => {
        setState(prev => ({
            ...prev,
            id: id,
            edit: new Date().getTime()
        }));

        show();
    }

    const loadData = () => {
        if (!state.id) {
            return;
        }

        Api.get('payment-methods/show?id=' + state.id)
            .then(res => {
                let data = res.data;

                data.has_settings = data?.category?.has_settings ? 1 : 0;

                let settings = {}
                if (data.provider_settings) {
                    settings = data.provider_settings
                }

                let codes = {};
                data.selected_codes.map(s =>
                    codes[`brand-${s.brand_id}`] = s.code_id
                )

                data.codes = codes

                setState(prev => ({
                    ...prev,
                    data,
                    settings,
                    mainTabId: 'main'
                }))
            });
    }

    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = state.id ? 'payment-methods/update' : 'payment-methods/add';

        let data = new FormData(e.target);

        if (state.id) {
            data.append('id', state.id)
        }

        if (state.settings) {
            let keys = Object.keys(state.settings)
            if (keys.length) {
                keys.map(t => {
                    if (t !== 'id') {
                        data.append(t, state.settings[t])
                    }
                })
            }
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (typeof state.onSuccess === 'function') {
                state.onSuccess(res.data)
            }

            hide()
        }).catch(error => {
            const _err = error.response;

            if (_err && _err.status && _err.status === 422) {
                hide(() => {
                    let modal = validationModalRef.current;

                    if (modal) {
                        modal.open();

                        modal.onClose(() => {
                            setValidations(_err.data.errors)
                            show();
                        });
                    }
                });
            }

        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }));
        });
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const setMainTab = key => {
        setState(prev => ({
            ...prev,
            mainTabId: key
        }));
    }

    const handleTabChange = id => {
        setState(prev => ({
            ...prev,
            tabId: Number(id)
        }));
    }

    const handleCategoryChange = e => {
        const name = e.target.name
        const value = e.target.value

        let category = categories.filter(f => f.id == value)

        if (!category.length) {
            return;
        }

        category = category[0];

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: value,
                has_settings: category.has_settings
            }
        }))
    }

    const handleCheckboxChange = e => {
        const name = e.target.name;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: prev.data[name] ? false : true
            }
        }));
    }

    const handleRoleChange = (data) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                roles: data,
            }
        }));
    }

    const handleStoreChange = (data) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                stores: data,
            }
        }));
    }

    const handleCountryChange = (data) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                countries: data,
            }
        }));
    }

    const handleCurrencyChange = (data) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                currencies: data,
            }
        }));
    }

    const handleShowSettings = () => {

        let modal;

        let providerId = Number(state.data.provider_id);

        if (providerId === 1) {
            modal = boricaSettingsModalRef.current;
        } else if (providerId === 2) {
            modal = smartUcfSettingsModalRef.current;
        }

        if (!modal) {
            return;
        }

        hide(() => {
            modal.show(state.settings);
        });

        modal.onSuccess((data) => {
            setState(prev => ({
                ...prev,
                settings: data
            }))
            show();
        });

        modal.onClose(() => {
            show();
        });
    }

    const handleCodeFu = (e) => {
        const value = e.target.value
        const brand = e.target.getAttribute('data-brand')

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                codes: {
                    ...prev.data.codes,
                    ['brand-' + brand]: value
                }
            }
        }))
    }

    const handleEditDevice = (e, id) => {
        e.preventDefault()

        hide(() => {
            let modal = editDeviceModalRef.current;

            if (modal) {
                modal.edit(id);
                modal.onSuccess(() => {
                    show();
                });

                modal.onClose(() => {
                    show();
                });

            }
        });

    }

    return (
        <>
            <Info
                ref={validationModalRef}
                mainMessage="Моля, попълнете всички задължителни полета, означени с червен цвят!"
            />

            <BoricaSettings
                ref={boricaSettingsModalRef}
            />

            <SmartUCFSettings
                ref={smartUcfSettingsModalRef}
            />

            <EditDevice
                ref={editDeviceModalRef}
            />

            <Modal size="xl" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <form onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Начин на плащане
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Tab.Container activeKey={state.mainTabId} onSelect={key => setMainTab(key)}>
                            <Row>
                                <Col xl={3} sm={3}>
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link eventKey="main">Основни данни</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="extra">Допълнителна информация</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="public">Публични настройки</Nav.Link>
                                        </Nav.Item>
                                        {state.data?.has_settings ? <Nav.Item>
                                            <Nav.Link eventKey="more">Още настройки</Nav.Link>
                                        </Nav.Item> : ''}
                                    </Nav>
                                </Col>
                                <Col xl={9} sm={9}>
                                    <Tab.Content>
                                        <Tab.Pane eventKey={'main'}>

                                            <Row className="mb-3">
                                                <Col>
                                                    <FloatingLabel label="Име">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Име*"
                                                            name="name"
                                                            value={state.data.name || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.name)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.name && (validations.name[0] || validations.name)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel label="Кратко име">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Кратко име*"
                                                            name="name_short"
                                                            value={state.data.name_short || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.name_short)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.name_short && (validations.name_short[0] || validations.name_short)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>

                                            <Col className="mb-3">
                                                <FloatingLabel label="Тип*">
                                                    <Form.Select
                                                        name="category_id"
                                                        value={state.data.category_id || ''}
                                                        onChange={handleCategoryChange}
                                                        isInvalid={Boolean(validations && validations.category_id)}
                                                    >
                                                        {categories.map(g =>
                                                            <option key={`category_id-${g.id}`} value={g.id}>{g.name}</option>
                                                        )}

                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">
                                                        {validations && validations.category_id && (validations.category_id[0] || validations.category_id)}
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>
                                            </Col>

                                            {groups.length &&
                                                <div className="mb-3">
                                                    <Table responsive style={{ minWidth: 'auto' }} striped hover className="">
                                                        <thead>
                                                            <tr>
                                                                <th style={{ width: '70%' }}>Група</th>
                                                                <th>Код ФУ</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {groups.map(g =>
                                                                g.devices.length ?
                                                                    <tr key={g.id}>
                                                                        <td>
                                                                            {g.devices.map(t =>
                                                                                <a onClick={(e) => handleEditDevice(e, t.id)} style={{ marginLeft: '5px' }} href=''>{t.base_device.name}</a>
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            <input name="brand_id[]" hidden value={g.id} />
                                                                            <FloatingLabel label="Код ФУ">
                                                                                <Form.Select
                                                                                    name="code_id[]"
                                                                                    value={state.data.codes[`brand-${g.id}`] || ''}
                                                                                    data-brand={g.id}
                                                                                    onChange={handleCodeFu}
                                                                                >
                                                                                    <option></option>
                                                                                    {g.settings.map(s =>
                                                                                        <option key={`setting-${s.id}`} value={s.id}>{s.code}</option>
                                                                                    )}

                                                                                </Form.Select>
                                                                            </FloatingLabel>
                                                                        </td>
                                                                    </tr> : ''
                                                            )}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            }

                                            <Row className="mb-3">
                                                <Col >
                                                    <Autocomplete
                                                        url="autocomplete/roles"
                                                        inputPlaceholder="Роли"
                                                        inputIdName={`role_id`}
                                                        inputIdValue={`role_id`}
                                                        renderText={data => data?.name}
                                                        selectedValues={state.data.roles}
                                                        onChange={data => handleRoleChange(data)}
                                                        multiple
                                                    />
                                                </Col>
                                                <Col >
                                                    <Autocomplete
                                                        url="autocomplete/stores"
                                                        params={{
                                                            all: 1
                                                        }}
                                                        inputPlaceholder="Търговски обекти"
                                                        inputIdName={`store_id`}
                                                        renderText={data => data?.translation?.name}
                                                        selectedValues={state.data.stores}
                                                        onChange={data => handleStoreChange(data)}
                                                        multiple
                                                    />
                                                </Col>
                                            </Row>

                                            <Row className="mb-3">
                                                <Col>
                                                    <Autocomplete
                                                        url="autocomplete/countries"
                                                        inputPlaceholder="Държави"
                                                        inputIdName={`country_id`}
                                                        renderText={data => data?.translation?.name}
                                                        onChange={data => handleCountryChange(data)}
                                                        selectedValues={state.data.countries}
                                                        multiple
                                                    />
                                                </Col>

                                                <Col>
                                                    <Autocomplete
                                                        url="autocomplete/saved-currencies"
                                                        inputPlaceholder="Валути"
                                                        inputIdName={`currency_id`}
                                                        renderText={data => data?.name}
                                                        onChange={data => handleCurrencyChange(data)}
                                                        selectedValues={state.data.currencies}
                                                        multiple
                                                    />
                                                </Col>
                                            </Row>

                                            <Row className="mb-3">
                                                <Col style={{ display: 'flex' }}>
                                                    <Form.Check
                                                        type="switch"
                                                        name="print_receipe"
                                                        label="Печат бележка"
                                                        value="1"
                                                        checked={Number(state.data?.print_receipe) === 1}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                    <HelpInfo marginLeft="10px" placement="top">Отпечатване на бележка при използване на този начин на плащане.</HelpInfo>
                                                </Col>

                                                <Col style={{ display: 'flex' }}>
                                                    <Form.Check
                                                        type="switch"
                                                        name="print_copy"
                                                        value="1"
                                                        label="Печат бележка копие"
                                                        checked={Number(state.data?.print_copy) === 1}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                    <HelpInfo marginLeft="10px" placement="top">Отпечатване на копие на бележката при използване на този начин на плащане.</HelpInfo>
                                                </Col>

                                                <Col style={{ display: 'flex' }}>
                                                    <Form.Check
                                                        type="switch"
                                                        name="show_in_invoice"
                                                        value="1"
                                                        label="Покажи във фактура"
                                                        checked={Number(state.data?.show_in_invoice) === 1}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                    <HelpInfo marginLeft="10px" placement="top">Дали да показва начина на плащане в печат на фактура, като възможен начин на плащане.</HelpInfo>
                                                </Col>
                                            </Row>

                                            <Row className='mb-3' style={{ alignItems: 'center' }}>
                                                <Col>
                                                    <FloatingLabel label="Тежест">
                                                        <Form.Control
                                                            type="number"
                                                            placeholder="Тежест"
                                                            name="weight"
                                                            value={state.data.weight || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.weight)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.weight && (validations.weight[0] || validations.weight)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel label="Външна система">
                                                        <Form.Select
                                                            name="provider_id"
                                                            value={state.data.provider_id || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.provider_id)}
                                                        >
                                                            <option></option>
                                                            <option value={1}>Борика виртуален ПОС</option>
                                                            <option value={2}>SmartUCF</option>
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.provider_id && (validations.provider_id[0] || validations.provider_id)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    {state.data.provider_id ?
                                                        <Button onClick={() => handleShowSettings()}>Настройки</Button>
                                                        : ''}
                                                </Col>
                                            </Row>

                                            <Col className="mb-3" style={{ display: 'flex' }}>
                                                <Form.Check
                                                    type="switch"
                                                    name="rest_back"
                                                    label="Връщане на ресто"
                                                    value="1"
                                                    checked={Number(state.data?.rest_back) === 1}
                                                    onChange={handleCheckboxChange}
                                                />
                                                <HelpInfo marginLeft="10px" placement="top">Начина на плащане, от който може да се приспада ресто, в случай на смесено плащане, надвишаващо тотала на сметката.</HelpInfo>
                                            </Col>

                                            <Col className="mb-3" style={{ display: 'flex' }}>
                                                <Form.Check
                                                    type="switch"
                                                    name="pay_from_client_balance"
                                                    label="Плащане през клиентски портфейл"
                                                    value="1"
                                                    checked={Number(state.data?.pay_from_client_balance) === 1}
                                                    onChange={handleCheckboxChange}
                                                />
                                                <HelpInfo marginLeft="10px" placement="top">Плащане през клиентски портфейл по подразбиране.</HelpInfo>
                                            </Col>

                                            <Col className="mb-3">
                                                <Form.Label column="sm" className="text-left">
                                                    Време за плащане
                                                </Form.Label>
                                                <InputGroup>
                                                    <Form.Control
                                                        style={{ width: '80%' }}
                                                        type="text"
                                                        placeholder="Време за плащане"
                                                        name="payment_paid_period"
                                                        value={state.data.payment_paid_period || ''}
                                                        onChange={handleInputChange}
                                                        isInvalid={Boolean(validations && validations.payment_paid_period)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {validations && validations.payment_paid_period && (validations.payment_paid_period[0] || validations.payment_paid_period)}
                                                    </Form.Control.Feedback>

                                                    <Form.Select
                                                        name="time"
                                                        value={state.data.time || ''}
                                                        onChange={handleInputChange}
                                                        isInvalid={Boolean(validations && validations.time)}
                                                    >
                                                        <option value={1}>Дни</option>
                                                        <option value={2}>Часа</option>
                                                        <option value={3}>Минути</option>

                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">
                                                        {validations && validations.time && (validations.time[0] || validations.time)}
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Col>

                                            <Col>
                                                <Tabs
                                                    activeKey={state.tabId}
                                                    onSelect={handleTabChange}
                                                    className="mb-3"
                                                >
                                                    {langs.data.map(lang =>
                                                        <Tab key={lang.id} eventKey={lang.id} title={lang.name} tabClassName={validations && validations.langs && validations.langs[lang.id] ? 'validate' : ''} />
                                                    )}
                                                </Tabs>

                                                {langs.data.map(lang =>
                                                    <div key={lang.id} className="mb-3" style={{ display: state.tabId === lang.id ? 'block' : 'none' }}>
                                                        <FloatingLabel label="Описание" className="mb-3">
                                                            <Form.Control
                                                                as="textarea"
                                                                name={`langs[${lang.id}][description]`}
                                                                placeholder="Описание"
                                                                value={state.data.langs[lang.id]?.description || ''}
                                                                onChange={handleInputChange}
                                                                isInvalid={Boolean(validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].description)}
                                                                style={{ height: '100px' }}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].description && (validations.langs[lang.id].description[0] || validations.langs[lang.id].description)}
                                                            </Form.Control.Feedback>
                                                        </FloatingLabel>
                                                    </div>
                                                )}
                                            </Col>

                                        </Tab.Pane>
                                        <Tab.Pane eventKey={'extra'}>
                                            <Row className="mb-3">
                                                <Col>
                                                    <FloatingLabel label="Заглавие">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Заглавие"
                                                            name="require_info_title"
                                                            value={state.data.require_info_title || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.require_info_title)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.require_info_title && (validations.require_info_title[0] || validations.require_info_title)}
                                                        </Form.Control.Feedback>
                                                        <HelpInfo location="top">Текстът, който ще се покаже на оператора при избирането на този начин на плащане.</HelpInfo>
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel label="Валидация">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Валидация"
                                                            name="require_info"
                                                            value={state.data.require_info || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.require_info)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.require_info && (validations.require_info[0] || validations.require_info)}
                                                        </Form.Control.Feedback>
                                                        <HelpInfo location="top">Въведената стойност ще бъде изпозлвана за проверка на въведеното от оператора (регулярен израз).</HelpInfo>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>

                                            <Col>
                                                <FloatingLabel label="Източник на списък с данни">
                                                    <Form.Select
                                                        name="require_info_list_data_source"
                                                        value={state.data.require_info_list_data_source || ''}
                                                        onChange={handleInputChange}
                                                        isInvalid={Boolean(validations && validations.require_info_list_data_source)}
                                                    >
                                                        <option></option>
                                                        <option value={1}>От външна система</option>

                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">
                                                        {validations && validations.require_info_list_data_source && (validations.require_info_list_data_source[0] || validations.require_info_list_data_source)}
                                                    </Form.Control.Feedback>
                                                    <HelpInfo location="top">Възможност за търсене и извеждане на списък, от който да бъде избран обект.</HelpInfo>
                                                </FloatingLabel>
                                            </Col>

                                        </Tab.Pane>
                                        <Tab.Pane eventKey={'public'}>
                                            <Col className="mb-3" style={{ display: 'flex' }}>
                                                <Form.Check
                                                    type="switch"
                                                    name="is_public"
                                                    label="Публичен"
                                                    value="1"
                                                    checked={Number(state.data?.is_public) === 1}
                                                    onChange={handleCheckboxChange}
                                                />
                                            </Col>
                                            <Col>
                                                <Tabs
                                                    activeKey={state.tabId}
                                                    onSelect={handleTabChange}
                                                    className="mb-3"
                                                >
                                                    {langs.data.map(lang =>
                                                        <Tab key={lang.id} eventKey={lang.id} title={lang.name} tabClassName={validations && validations.langs && validations.langs[lang.id] ? 'validate' : ''} />
                                                    )}
                                                </Tabs>

                                                {langs.data.map(lang =>
                                                    <div key={lang.id} className="mb-3" style={{ display: state.tabId === lang.id ? 'block' : 'none' }}>
                                                        <FloatingLabel label="Публично име" className="mb-3">
                                                            <Form.Control
                                                                type="text"
                                                                name={`langs[${lang.id}][public_name]`}
                                                                placeholder="Публично име"
                                                                value={state.data.langs[lang.id]?.public_name || ''}
                                                                onChange={handleInputChange}
                                                                isInvalid={Boolean(validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].public_name)}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].public_name && (validations.langs[lang.id].public_name[0] || validations.langs[lang.id].public_name)}
                                                            </Form.Control.Feedback>
                                                            <HelpInfo placement="top">Ако не бъде въведено ще се използва основното име на артикула</HelpInfo>
                                                        </FloatingLabel>
                                                    </div>
                                                )}
                                            </Col>
                                        </Tab.Pane>
                                        {state.data.has_settings ? <Tab.Pane eventKey={'more'}>
                                            <Row className="mb-3">
                                                <Col>
                                                    <FloatingLabel label="Сметка">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Сметка"
                                                            name="dynamic_setting_account_name"
                                                            value={state.data.dynamic_setting_account_name || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.dynamic_setting_account_name)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.dynamic_setting_account_name && (validations.dynamic_setting_account_name[0] || validations.dynamic_setting_account_name)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel label="Банка">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Банка"
                                                            name="dynamic_setting_bank_name"
                                                            value={state.data.dynamic_setting_bank_name || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.dynamic_setting_bank_name)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.dynamic_setting_bank_name && (validations.dynamic_setting_bank_name[0] || validations.dynamic_setting_bank_name)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>

                                            <Col className="mb-3">
                                                <FloatingLabel label="IBAN">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="IBAN"
                                                        name="dynamic_setting_iban"
                                                        value={state.data.dynamic_setting_iban || ''}
                                                        onChange={handleInputChange}
                                                        isInvalid={Boolean(validations && validations.dynamic_setting_iban)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {validations && validations.dynamic_setting_iban && (validations.dynamic_setting_iban[0] || validations.dynamic_setting_iban)}
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>
                                            </Col>

                                            <Row >
                                                <Col>
                                                    <FloatingLabel label="BIC">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="BIC"
                                                            name="dynamic_setting_bic"
                                                            value={state.data.dynamic_setting_bic || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.dynamic_setting_bic)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.dynamic_setting_bic && (validations.dynamic_setting_bic[0] || validations.dynamic_setting_bic)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel label="Валута">
                                                        <Form.Select
                                                            name="dynamic_setting_currency"
                                                            value={state.data.dynamic_setting_currency || state.data.currency_id || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.dynamic_setting_currency)}
                                                        >
                                                            {currencies.map(g =>
                                                                <option key={`dynamic_setting_currency-${g.id}`} value={g.id}>{g.name}</option>
                                                            )}

                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.dynamic_setting_currency && (validations.dynamic_setting_currency[0] || validations.dynamic_setting_currency)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>

                                        </Tab.Pane> : ''}
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            className="save"
                        />
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default forwardRef(AddOrEdit);
