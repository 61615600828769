import { useEffect, useRef, useState } from "react";
import { Alert, Button, Col, Form, InputGroup, Table, Card } from "react-bootstrap";
import { InfoCircleFill } from "react-bootstrap-icons";
import Api from "helpers/Api";
import Loader from "components/misc/Loader";
import TableHeader from "components/misc/TableHeader";
import TableCol from "components/misc/TableCol";
import PriceValue from "components/partials/PriceValue";
import NoDataFound from "components/misc/NoDataFound";
import Pagination from "components/misc/Pagination";
import { calcExtraPrice } from "helpers/Price";

let timeout;

function Index(props) {

    const tableRef = useRef(null);

    const [state, setState] = useState({
        sorting: false,
        loading: true,
        refresh: false,
        setFilter: false,
        data: [],
        pages: 0,
        total: 0,
        filter: {
            page: 1,
        },
    });

    useEffect(() => {
        loadData();
    }, [props.id]);

    useEffect(() => {
        if (state.refresh) {
            loadData();
        }
    }, [state.refresh]);

    const loadData = () => {

        loading(true);

        let url = 'articles/current-prices';

        Api.post(url, {
            id: props.id,
            ...state.filter
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data.items,
                total: res.data.total,
                pages: res.data.pages,
                article: res.data.article,
                filter: {
                    ...prev.filter,
                    ...res.data.filter,
                }
            }));

            if (res.data.pages > 0 && state.filter.page > res.data.pages) {
                return handlePage({ selected: res.data.pages - 1 });
            }

            loading(false);
            sorting(false);
        });
    }

    const handleInputChange = (key, val, index) => {
        setState(prev => ({
            ...prev,
            data: Object.values({
                ...prev.data,
                [index]: {
                    ...prev.data[index],
                    row: {
                        ...prev.data[index].row,
                        [key]: val
                    }
                }
            })
        }));

    }
    const getRow = index => {
        return state.data.find((k, i) => Number(i) === Number(index));
    }

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }));
    }

    const sorting = (sorting) => {
        setState(prev => ({
            ...prev,
            sorting: Boolean(sorting)
        }));
    }

    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout);

        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [key]: val,
            },
        }));

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: 1
                },
                refresh: new Date().getTime()
            }));
        }, delay);
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            refresh: new Date().getTime()
        }));
    }

    const handleSort = (sort, order) => {
        setState(prev => ({
            ...prev,
            sorting: true,
            filter: {
                ...prev.filter,
                sort: sort,
                order: order,
                page: 1,
            },
            refresh: new Date().getTime()
        }));
    }

    const getPriceDiff = (row) => {
        let price = row.new_price;
        let base = row.base_price;

        return price - base;
    }

    const getPriceDiffPerc = (row) => {
        let price = row.new_price;
        let base = row.base_price;

        return calcExtraPrice(base, price);
    }

    const showPriceRule = id => {
        props.showPriceRule(id);
    }

    const showClient = id => {
        props.showClient(id);
    }

    return (
        <>
            <Card className="mb-3">
                <Card.Header>
                    Ефективни цени
                </Card.Header>
                <Card.Body>
                    {!props.id
                        ?
                        <Alert style={{ margin: 0 }}>
                            Няма намерена информация
                        </Alert>
                        :
                        <>
                            <div style={{ marginBottom: '20px' }}>
                                <InputGroup size="sm">
                                    <Form.Control
                                        type="search"
                                        name="search"
                                        value={state.filter.search || ''}
                                        size="sm"
                                        onChange={e => handleSearch(e.target.name, e.target.value)}
                                        placeholder="Търсене"
                                    />
                                </InputGroup>
                            </div>

                            {state.loading && !state.sorting ?
                                <div style={{ padding: '100px 0' }}>
                                    <Loader />
                                </div>
                                :
                                <>
                                    {state.data.length === 0 &&
                                        <Alert style={{ margin: 0 }}>
                                            Няма намерена информация
                                        </Alert>
                                    }

                                    {state.data.length > 0 &&
                                        <>
                                            <Table className="xxl" striped hover ref={tableRef}>
                                                <TableHeader
                                                    tableRef={tableRef}
                                                    activeSortKey={state.filter.sort}
                                                    activeSortDir={state.filter.order}
                                                    onSort={(col, dir) => handleSort(col, dir)}
                                                >
                                                    <TableCol sortKey="" style={{ width: '3%' }}>ID</TableCol>
                                                    <TableCol className="text-center" sortKey="">ТО</TableCol>
                                                    <TableCol className="text-center" sortKey="">Каса</TableCol>
                                                    <TableCol className="text-center" sortKey="">Клиент</TableCol>
                                                    <TableCol className="text-center" sortKey="">Клиентска <br /> група</TableCol>
                                                    <TableCol className="text-center" sortKey="">Продажна <br /> цена</TableCol>
                                                    <TableCol className="text-center" sortKey="">Изчислена <br /> цена</TableCol>
                                                    <TableCol className="text-center" sortKey="">Разлика</TableCol>
                                                    <TableCol className="text-center" sortKey="">Разлика(%)</TableCol>
                                                    <TableCol className="text-center" sortKey="">Ценово <br /> правило</TableCol>
                                                    <TableCol className="options" style={{ width: '5%' }}>Опции</TableCol>
                                                </TableHeader>
                                                <tbody>
                                                    {state.data.map((c, index) =>
                                                        <tr
                                                            key={'c-' + c.id}
                                                        >

                                                            <td>
                                                                {c.id || ''}
                                                            </td>
                                                            <td className="text-center">
                                                                {c?.store?.translation?.name || c?.store?.name}
                                                            </td>
                                                            <td className="text-center">
                                                                {c?.cashdesk?.translation?.name || c?.cashdesk?.name}
                                                            </td>
                                                            <td className="text-center">
                                                                {c.client &&
                                                                    <span className="link" onClick={e => showClient(c.client.id)}>
                                                                        {c?.client?.name}
                                                                    </span>
                                                                }
                                                            </td>
                                                            <td className="text-center">
                                                                {c?.clientgroup?.name}
                                                            </td>
                                                            <td className="text-center">
                                                                {c.base_price !== null &&
                                                                    <>
                                                                        {state?.article?.currency?.prefix} <PriceValue>{c.base_price}</PriceValue> {state?.article?.currency?.sufix}
                                                                    </>
                                                                }
                                                            </td>
                                                            <td className="text-center">
                                                                {c.new_price !== null &&
                                                                    <>
                                                                        {state?.article?.currency?.prefix} <PriceValue>{c.new_price}</PriceValue> {state?.article?.currency?.sufix}
                                                                    </>
                                                                }
                                                            </td>
                                                            <td className={`text-center ${getPriceDiff(c) < 0 && 'negative-value'}`}>
                                                                {getPriceDiff(c) !== null &&
                                                                    <>
                                                                        {state?.article?.currency?.prefix} <PriceValue>{getPriceDiff(c)}</PriceValue> {state?.article?.currency?.sufix}
                                                                    </>
                                                                }
                                                            </td>
                                                            <td className={`text-center ${getPriceDiffPerc(c) < 0 && 'negative-value'}`}>
                                                                {getPriceDiffPerc(c) !== null &&
                                                                    <>
                                                                        <PriceValue>{getPriceDiffPerc(c)}</PriceValue>%
                                                                    </>
                                                                }
                                                            </td>
                                                            <td className="text-center">
                                                                {(c.rules || []).map(rule =>
                                                                    <span key={rule.id} className="link" onClick={e => showPriceRule(rule.id)}>
                                                                        {rule.name}
                                                                    </span>
                                                                )}
                                                            </td>
                                                            <td className="options">
                                                                <Button variant="secondary" size="sm" onClick={e => showPriceRule(c.price_rules[0])}>
                                                                    <InfoCircleFill />
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </Table>

                                            <Pagination
                                                className="mt-3"
                                                page={state.filter.page}
                                                pages={state.pages}
                                                total={state.total}
                                                handlePage={handlePage}
                                            />
                                        </>
                                    }

                                </>
                            }
                        </>
                    }
                </Card.Body>
            </Card>
        </>
    )
}

export default Index;
