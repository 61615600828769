import { useEffect, useRef, useState } from 'react';
import Api from 'helpers/Api';
import { useQuery, buildSearchParams } from 'helpers/Url';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Alert, Button, Table, Form } from 'react-bootstrap';

import Options from './partials/Options';

import Success from 'components/modals/Success';
import Question from 'components/modals/Question';
import Filter from './partials/Filter';
import AddOrEdit from './partials/AddOrEdit';
import Layout from 'components/layout/Layout';

//misc
import MenuOptions from 'components/misc/MenuOptions'
import Pagination from 'components/misc/Pagination'
import Loader from 'components/misc/Loader';
import SaveButton from 'components/misc/Button';
import TableHeader from 'components/misc/TableHeader';
import TableCol from 'components/misc/TableCol';
import NoDataFound from 'components/misc/NoDataFound';

let timeout;

function Index() {

    const location = useLocation();
    const history = useNavigate();
    const query = useQuery();
    // const auth = useAuthDataContext();

    const tableRef = useRef(null);
    const successModalRed = useRef(null);
    const addOrEditModalRef = useRef(null);
    const deleteModalRef = useRef(null);

    const [state, setState] = useState({
        sorting: false,
        loadingButton: false,
        loading: true,
        refresh: false,
        setFilter: false,
        data: [],
        checked: {},
        pages: 0,
        total: 0,
        filter: {
            page: 1,
        },
    });

    useEffect(() => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: query.get('page') || 1,
                search: query.get('search') || '',
                eu_countries: query.get('eu_countries') || 0,
                balkan_countries: query.get('balkan_countries') || 0,
                has_delivery: query.get('has_delivery') || 0,
                sort: query.get('sort') || '',
                order: query.get('order') || '',
            },
            refresh: new Date().getTime()
        }))
    }, [location.search]);

    useEffect(() => {
        if (state.setFilter) {
            history('?' + buildSearchParams(query, state.filter));
        }

    }, [state.setFilter]);

    useEffect(() => {
        if (state.refresh) {
            loadData();
        }
    }, [state.refresh]);

    const loadData = () => {

        loading(true);

        let url = 'countries/get';

        Api.get(url, {
            params: state.filter
        }).then(res => {
            let checked = {}
            res.data.items.map(t => {
                let name = t.id
                if (t.has_delivery) {
                    checked[name] = true
                } else {
                    checked[name] = false
                }
            })

            setState(prev => ({
                ...prev,
                data: res.data.items,
                total: res.data.total,
                pages: res.data.pages,
                totals: res.data.totals,
                checked,
                filter: {
                    ...prev.filter,
                    ...res.data.filter,
                }
            }));

            if (res.data.pages > 0 && state.filter.page > res.data.pages) {
                return handlePage({ selected: res.data.pages - 1 });
            }

            loading(false);
            sorting(false)
        });
    }

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }));
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: reset ? 1 : prev.filter.page
            },
            setFilter: new Date().getTime(),
            refresh: new Date().getTime(),
        }));
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            setFilter: new Date().getTime()
        }));
    }

    const handleSave = (e) => {
        e.preventDefault()
        loadingButton(true)
        if (state.loading) {
            return;
        }

        Api.post('countries/update', state.checked)
            .then(res => {
                let modal = successModalRed.current;
                modal.open();
                modal.onSuccess(() => {
                    loadingButton(false)
                })
            }).catch(error => {
                loadingButton(false)
                alert('ERROR')
            })
    }

    const handleChecked = (id) => {
        let checked = state.checked
        let name = id

        if (checked[name]) {
            checked[name] = false
        } else {
            checked[name] = true
        }

        setState(prev => ({
            ...prev,
            checked
        }))
    }

    const loadingButton = (loading) => {
        setState(prev => ({
            ...prev,
            loadingButton: Boolean(loading)
        }));
    }

    // Search
    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout);

        if (typeof key === 'object') {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    ...key
                },
            }));
        } else {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    [key]: val,
                },
            }));
        }

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: 1
                },
                setFilter: new Date().getTime()
            }));
        }, delay);
    }

    const sorting = (sorting) => {
        setState(prev => ({
            ...prev,
            sorting: Boolean(sorting)
        }));
    }

    const handleSort = (sort, order) => {
        setState(prev => ({
            ...prev,
            sorting: true,
            filter: {
                ...prev.filter,
                sort: sort,
                order: order,
                page: 1,
            },
            setFilter: new Date().getTime()
        }));
    }


    return (
        <>

            <Success
                ref={successModalRed}
                mainMessage="Данните бяха запазени успешно"
            />

            <Layout>
                <div className="container-fluid">

                    <div className="page-head">
                        <h1 className="display-6">
                            Списък с държави
                        </h1>

                    </div>

                    <br />

                    <div className="panel">
                        <Filter
                            filter={state.filter}
                            handleSearch={handleSearch}
                        />

                        {state.loading && !state.sorting
                            ?
                            <Loader />
                            :
                            state.data.length === 0 ?
                                <NoDataFound />
                                :
                                <>
                                    <Form onSubmit={handleSave}>
                                        <Table responsive striped hover ref={tableRef}>
                                            <TableHeader
                                                tableRef={tableRef}
                                                activeSortKey={state.filter.sort}
                                                activeSortDir={state.filter.order}
                                                onSort={(col, dir) => handleSort(col, dir)}
                                            >
                                                <TableCol sortable sortKey="id">ID</TableCol>
                                                <TableCol sortable sortKey="name">Име</TableCol>
                                                <TableCol className="text-center" sortable sortKey="alpha_3">Код</TableCol>
                                                <TableCol className="text-center" sortable sortKey="currency">Валута</TableCol>
                                                <TableCol className="text-center" sortable sortKey="has_delivery">Доставка</TableCol>
                                            </TableHeader>
                                            <tbody>
                                                {state.data.map(c =>
                                                    <tr
                                                        key={'c-' + c.id}
                                                    >
                                                        <td>{c.alpha_2}</td>
                                                        <td>
                                                            {c.translation?.name || c.name || ''}
                                                        </td>
                                                        <td className="text-center">{c.alpha_3}</td>
                                                        <td className="text-center">
                                                            {c.currency?.name}
                                                        </td>
                                                        <td className="text-center">

                                                            <Form.Check
                                                                key={c.id}
                                                                type="switch"
                                                                name={`country_id[${c.id}]`}
                                                                value={1}
                                                                checked={state.checked[c.id] || false}
                                                                onChange={() => handleChecked(c.id)}
                                                            />

                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>

                                        <Pagination
                                            page={state.filter.page}
                                            pages={state.pages}
                                            handlePage={handlePage}
                                        />

                                        <div className="buttons mt-3 ">
                                            <SaveButton
                                                loading={state.loadingButton}
                                                className="save"
                                            >
                                                Запази данните
                                            </SaveButton>
                                        </div>
                                    </Form>
                                </>
                        }


                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Index;
