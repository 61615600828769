import React, { useState, useImperativeHandle, forwardRef, useRef } from 'react'
import { Modal, Button, Tabs, Tab } from 'react-bootstrap';
import SaveButton from 'components/misc/Button';
import Loader from 'components/misc/Loader';
import Home from './Home';
import StorageMovements from './StorageMovements';
import Article from 'components/pages/articles/partials/AddOrEdit';
import Refs from 'Refs';

function View(props, ref) {

    const tabs = [
        {
            id: 'home',
            name: 'Преглед',
            component: Home
        },
        {
            id: 'storage',
            name: 'Складови корекции',
            component: StorageMovements
        }
    ];

    const [state, setState] = useState({
        show: false,
        params: null,
        id: null,
        tabId: tabs[0].id,
        onClose: null,
    });

    const articleModalRef = useRef(null);

    useImperativeHandle(ref, () => ({
        open: (id) => {
            handleShow(id);
        },
        close: () => {
            handleClose();
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        }
    }));

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const handleShow = (id) => {
        setState(prev => ({
            ...prev,
            id: id,
        }));

        show();
    }

    const handleClose = () => {
        setState(prev => ({
            ...prev,
            tabId: tabs[0].id
        }));

        if (typeof state.onClose === 'function') {
            hide(state.onClose);
        } else {
            hide();
        }

        setState(prev => ({
            ...prev,
            onClose: null,
        }));
    }

    const setKey = id => {
        setState(prev => ({
            ...prev,
            tabId: id,
        }));
    }

    const handleShowArticle = id => {
        let modal = Refs.getInstance().getRef('article');

        if (modal) {
            // hide(() => {
            //     modal.edit(id);
            // });

            // modal.onClose(() => {
            //     show();
            // });

            modal.open(id);
        }
    }

    return (
        <>
            <Modal size="xl" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Преглед на ревизия
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tabs
                        activeKey={state.tabId}
                        onSelect={(k) => setKey(k)}
                        className="mb-3"
                        mountOnEnter
                    >
                        {tabs.map(tab =>
                            <Tab key={tab.id} eventKey={tab.id} title={tab.name}>
                                {React.createElement(tab.component, {
                                    id: state.id,
                                    show: show,
                                    hide: hide,
                                    handleShowArticle: handleShowArticle,
                                })}
                            </Tab>
                        )}
                    </Tabs>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Затвори
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default forwardRef(View);