import { useState, useEffect } from 'react'
import { Button, Table } from 'react-bootstrap';
import Api from 'helpers/Api';
import Loader from 'components/misc/Loader';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Envelope, Pencil } from 'react-bootstrap-icons';
import PriceValue from 'components/partials/PriceValue';
import { useAppContext } from 'providers/App';
import SaveButton from 'components/misc/Button';
import Refs from 'Refs';

function Index(props) {

    const app = useAppContext();
    const navigate = useNavigate();

    const defaultData = {
        rows: []
    };

    const [state, setState] = useState({
        data: defaultData,
        loading: false,
        loadingShipmentRequests: false,
    });

    const [rowStatuses, setRowStatuses] = useState([]);

    useEffect(() => {
        setState(prev => ({
            ...prev,
            loading: true,
        }));

        Api.get('returns/show', {
            params: {
                id: props.id
            }
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data,
                loading: false,
            }));
        });
    }, [props.id]);

    useEffect(() => {
        Api.get('returns/row-statuses')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setRowStatuses(res.data);
                }
            });
    }, []);

    const getAddressName = address => {
        if (!address) {
            return '';
        }

        let name = [];

        if (address.delivery_type === 'address') {
            name = [
                address.dest_name_full,
                address.address,
                address.description,
                address.country_name,
            ];
        }

        if (address.delivery_type === 'office') {
            name = [
                address.dest_name_full,
                address.office_name,
            ];
        }

        if (address.delivery_type === 'store') {
            name = [
                address?.store?.translation?.name || address?.store?.name,
            ];
        }

        return name.filter(p => p).join(', ');
    }

    const getExtendedAddressName = address => {
        if (!address) {
            return '';
        }

        let map = {
            address: 'Адрес на клиент',
            office: 'Офис на куриер',
            store: 'Търговски обект'
        };

        let type = map[address.delivery_type];

        return type + ': ' + getAddressName(address);
    }

    const showClient = () => {
        props.showClient(state.data.client_id);
    }

    const showArticle = id => {
        props.showArticle(id);
    }

    const handleEdit = () => {
        navigate('/returns/' + state.data.id + '/edit');
    }

    const sendMail = () => {
        props.sendMail(state.data);
    }

    const editDeliveryData = () => {
        props.editDeliveryData();
    }

    const handleChangeRowStatus = (e, rowId) => {
        let value = e.target.value;

        Api.post('returns/set-row-status', {
            id: state.data.id,
            row_id: rowId,
            status_id: value
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data
            }));
        }).catch(err => {
            app.showError();
        });
    }

    const createShipmentRequests = () => {
        setState(prev => ({
            ...prev,
            loadingShipmentRequests: true,
        }));

        Api.post('returns/create-shipment-requests', {
            id: state.data.id,
        }).then(res => {
            if (res.data.error) {
                app.showError(res.data.error);
            } else {
                setState(prev => ({
                    ...prev,
                    data: res.data
                }));
            }
        }).catch(err => {
            app.showError();
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loadingShipmentRequests: false,
            }));
        });
    }

    const trackOrder = number => {
        window.open(
            process.env.REACT_APP_URL + '/check-order?id=' + number,
            '_blank',
            'resizable=1,width=500,height=700'
        )
    }

    const handleNewAccount = () => {
        let modal = Refs.getInstance().getRef('question');

        if (!modal) {
            return;
        }

        props.hide(() => {
            modal.open('Само артикули, които отговарят на условията ще бъдат включени в сторно сметката. Продължаване?');
        });

        modal.onSuccess(() => {
            createNewAccount();
        })

        modal.onClose(() => {
            props.show();
        });
    }

    const createNewAccount = () => {
        navigate('/accounts/new?from_return_id=' + state.data.id);
    }

    return (

        state.loading
            ?
            <Loader />
            :
            <>
                <Table size="sm" bordered>
                    <tbody>
                        <tr>
                            <td className="th">
                                ID
                            </td>
                            <td>
                                {state.data.id}
                            </td>
                            <td className="th">
                                Създал
                            </td>
                            <td>
                                {state.data?.creator?.username}
                            </td>
                            <td className="th">
                                Създадено на
                            </td>
                            <td>
                                {state.data.created_at ? moment(state.data.created_at).format('DD.MM.YYYY HH:mm') : ''}
                            </td>
                        </tr>
                        <tr>
                            <td className="th">
                                Статус
                            </td>
                            <td colSpan={3}>
                                {state.data?.status?.translation?.name || state.data?.status?.name}
                            </td>
                            <td className="th">
                                Последна промяна на статус
                            </td>
                            <td>
                                {state.data.status_changed_at ? moment(state.data.status_changed_at).format('DD.MM.YYYY HH:mm') : ''}
                            </td>
                        </tr>
                        <tr>
                            <td className="th">
                                Клиент
                            </td>
                            <td colSpan={3} style={{ cursor: 'pointer' }} onClick={showClient}>
                                {state.data.client_name || state.data?.client?.name}
                            </td>
                            <td className="th">
                                Телефон
                            </td>
                            <td>
                                {state.data.client_tel}
                            </td>
                        </tr>
                        <tr>
                            <td className="th">
                                Взимане от адрес
                            </td>
                            <td colSpan={3}>
                                {getExtendedAddressName(state.data.fromaddress)}
                            </td>
                            <td className="th">
                                Спедитор
                            </td>
                            <td>
                                {state.data.speditor?.name}
                            </td>
                        </tr>
                        <tr>
                            <td className="th">
                                Начин на плащане
                            </td>
                            <td>
                                {state.data.paymethod?.name || 'не е посочено'}
                            </td>
                            <td className="th">
                                Върната сума
                            </td>
                            <td>
                                {(state.data.total_paid_sum || 0).toFixed(2)}  {state.data?.paymentmethod && ' / ' + state.data?.paymentmethod?.translation?.name || state.data?.paymentmethod?.name}
                            </td>
                            <td className="th">
                                Валута
                            </td>
                            <td>
                                {state.data?.currency?.name || '-'}
                            </td>
                        </tr>
                        <tr>
                            <td className="th">
                                IBAN
                            </td>
                            <td colSpan={5}>
                                {state.data.iban}
                            </td>
                        </tr>
                        <tr>
                            <td className="th">
                                Описание
                            </td>
                            <td colspan={5}>
                                {state.data.description}
                            </td>
                        </tr>
                        <tr>
                            <td className="th">
                                Допълнителни
                            </td>
                            <td colspan={5}>
                                {state.data.extra_details}
                            </td>
                        </tr>
                    </tbody>
                </Table>

                <div className="mt-3">
                    <Button size="sm" variant="primary" onClick={() => handleEdit()}>
                        <Pencil /> Редактиране
                    </Button>
                    <Button size="sm" variant="secondary" onClick={() => sendMail()} style={{ marginLeft: '10px' }}>
                        <Envelope /> Изпращане
                    </Button>

                    <Button size="sm" variant="primary" onClick={() => handleNewAccount()} style={{ marginLeft: '10px' }}>
                        Сторно сметка
                    </Button>

                    {/* <Button size="sm" variant="primary" onClick={() => { }} style={{ marginLeft: '10px' }}>
                        Кредитно известие (TODO)
                    </Button> */}

                    <SaveButton
                        size="sm"
                        variant="secondary"
                        onClick={() => createShipmentRequests()}
                        loading={state.loadingShipmentRequests}
                        style={{ marginLeft: '10px' }}
                    >
                        Създай товарителница
                    </SaveButton>

                    {/* <Button size="sm" variant="secondary" onClick={() => editDeliveryData()} style={{ marginLeft: '10px' }}>
                        <Pencil /> Взимане от адрес
                    </Button> */}
                </div>

                <Table className="mt-3 xxl" bordered>
                    <thead>
                        <tr>
                            <th style={{ width: '5%' }}>
                                ID
                            </th>
                            <th>
                                Артикул
                            </th>
                            <th className="text-right">
                                Върнато к-во
                            </th>
                            <th className="text-right">
                                Продажна цена
                            </th>
                            <th>
                                Бележки
                            </th>
                            <th>
                                Причина
                            </th>
                            <th className="text-center">
                                Отварян
                            </th>
                            <th className="text-center">
                                Статус
                            </th>
                            <th>
                                Товарителница
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {state.data.rows.map(r =>
                            <tr key={r.id}>
                                <td>
                                    {r.article_id}
                                </td>
                                <td>
                                    <span className="link" onClick={e => showArticle(r.article_id)}>
                                        {r?.article?.article_name}
                                    </span>
                                </td>
                                <td className="text-right">
                                    {r.amount} {r?.article?.amount_type_short}
                                </td>
                                <td className="text-right">
                                    <PriceValue prefix={state.data.currency?.prefix} sufix={state.data.currency?.sufix}>{r.total_sum}</PriceValue>
                                </td>
                                <td>
                                    {r.notes}
                                </td>
                                <td>
                                    {r.reason?.name}
                                </td>
                                <td className="text-center">
                                    {r.opened ? 'ДА' : 'НЕ'}
                                </td>
                                <td className="text-center">
                                    <select
                                        value={r.status_id || ''}
                                        onChange={e => handleChangeRowStatus(e, r.id)}
                                    >
                                        <option value=""></option>
                                        {rowStatuses.map(s =>
                                            <option key={s.id} value={s.id}>{s.name}</option>
                                        )}
                                    </select>
                                </td>
                                <td>
                                    <span className="link" onClick={e => trackOrder(r.tracking_number)} style={{ display: 'block' }}>{r.tracking_number}</span>
                                </td>

                            </tr>
                        )}
                    </tbody>
                </Table>

            </>
    )
}

export default Index;