import React, { useState, useEffect } from 'react'
import { CaretLeft, CaretDown, CaretRight, CaretDownFill } from 'react-bootstrap-icons'
import { FloatingLabel, Form, Dropdown, Row, Col, Card } from 'react-bootstrap'

import Api from 'helpers/Api'
import Loader from 'components/misc/Loader'
import NoDataFound from 'components/misc/NoDataFound'
import { diff, unique } from 'helpers/Arrays'

function Stores(props) {

    const [state, setState] = useState({
        data: [],
        show: false,
        selected: [],
        active: null,
        loading: true,
    });

    useEffect(() => {
        Api.get('price_rules/stores')
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: Array.isArray(res.data) ? res.data : [],
                    active: res.data[0] ? res.data[0].id : null,
                    loading: false,
                }));
            });
    }, []);

    useEffect(() => {
        if (Array.isArray(props.selected)) {
            setState(prev => ({
                ...prev,
                selected: props.selected.map(s => Number(s))
            }));
        }
    }, [props.selected]);

    const toggleStore = store => {
        setState(prev => ({
            ...prev,
            active: store.id
        }));
    }

    const isStoreActive = store => {
        return Number(state.active) === Number(store.id);
    }

    const selectStore = store => {
        let selected = [...state.selected];
        let cashdesks = store.cashdesks.map(cashdesk => Number(cashdesk.id));

        if (isStoreSelected(store)) {
            selected = diff(selected, cashdesks);
        } else {
            selected = unique(selected.concat(cashdesks));
        }

        setState(prev => ({
            ...prev,
            selected: selected
        }))

        if (typeof props.onChange === 'function') {
            props.onChange(selected);
        }
    }

    const isStoreSelected = store => {

        let selected = 0;

        store.cashdesks.map(cashdesk => {
            if (isCashdeskSelected(cashdesk)) {
                selected++;
            }
        });

        if (store.cashdesks.length > 0 && selected === store.cashdesks.length) {
            return true;
        }

        return false;
    }

    const hasStoreSelectedCashdesk = store => {

        let selected = 0;

        store.cashdesks.map(cashdesk => {
            if (isCashdeskSelected(cashdesk)) {
                selected++;
            }
        });

        if (selected > 0) {
            return true;
        }

        return false;
    }

    const selectCashdesk = cashdesk => {
        let selected = [...state.selected];

        if (isCashdeskSelected(cashdesk)) {
            selected = selected.filter(id => Number(id) !== Number(cashdesk.id));
        } else {
            selected = selected.concat(cashdesk.id);
        }

        setState(prev => ({
            ...prev,
            selected: selected
        }))

        if (typeof props.onChange === 'function') {
            props.onChange(selected);
        }
    }

    const isCashdeskSelected = cashdesk => {
        return state.selected.findIndex(id => Number(id) === Number(cashdesk.id)) > -1;
    }

    return (
        <Card>
            <Card.Header>
                Търговски обекти
            </Card.Header>
            <Card.Body>
                {state.loading
                    ?
                    <Loader />
                    :
                    state.data.length === 0
                        ?
                        <NoDataFound size="sm">
                            Няма складове за показване!
                        </NoDataFound>
                        :
                        <>
                            <div className="depots-filter">
                                {/* <div className="header">
                                    <div>
                                        Магазин
                                    </div>
                                    <div>
                                        Складове
                                    </div>
                                </div> */}
                                <div className="body">
                                    <div className="stores">
                                        {state.data.map((store, i) =>
                                            <React.Fragment key={store.id}>
                                                <div className={`store ${isStoreActive(store) ? 'active' : ''}`} onClick={e => toggleStore(store)}>
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            onChange={e => selectStore(store)}
                                                            checked={isStoreSelected(store)}
                                                        />
                                                        <span className={hasStoreSelectedCashdesk(store) ? 'has-selected-depot' : ''}>
                                                            {store?.translation?.name || store?.name}
                                                        </span>
                                                    </label>
                                                </div>
                                            </React.Fragment>
                                        )}
                                    </div>

                                    {state.data.map((store, i) =>
                                        <div key={'cashdesks-' + i} className={`depots  ${isStoreActive(store) ? 'active' : ''}`}>
                                            {store.cashdesks.map(cashdesk =>
                                                <div key={cashdesk.id} className="depot">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name="cashdesk_id[]"
                                                            value={cashdesk.id}
                                                            onChange={e => selectCashdesk(cashdesk)}
                                                            checked={isCashdeskSelected(cashdesk)}
                                                        />
                                                        <span>{cashdesk?.translation?.name || cashdesk?.name}</span>
                                                    </label>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </>
                }
            </Card.Body>
        </Card>
    )
}

export default Stores;