import { Dropdown } from 'react-bootstrap';

function Options(props) {
    return (
        <>
            {props.data.deleted
                ?
                <Dropdown.Item onClick={e => props.handleRestore(props.data)}>
                    Възстанови
                </Dropdown.Item>
                :
                <>
                    <Dropdown.Item onClick={e => props.handleView(props.data)}>
                        Преглед
                    </Dropdown.Item>
                    {props.data.allow_edit &&
                        <Dropdown.Item onClick={e => props.handleEdit(props.data)}>
                            Редакция
                        </Dropdown.Item>
                    }
                    {props.data.allow_delete &&
                        <Dropdown.Item onClick={e => props.handleDelete(props.data)}>
                            Изтриване
                        </Dropdown.Item>
                    }
                </>
            }
        </>
    )
}

export default Options;