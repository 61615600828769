import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import { Modal, Button, Table, OverlayTrigger, Tooltip, Alert } from 'react-bootstrap';
import { Check, X } from 'react-bootstrap-icons';
import DevicePreview from './DevicePreview';
import DeviceOptions from './DeviceOptions';
import DeviceSelectionFilter from './DeviceSelectionFilter';

//helpers
import Api from 'helpers/Api'
import { useNestedState } from 'helpers/NestedState'

//misc
import Loader from 'components/misc/Loader';
import SaveButton from 'components/misc/Button'
import Pagination from 'components/misc/Pagination'
import MenuOptions from 'components/misc/MenuOptions'
import AddOrEdit from './AddOrEdit';
import Refs from 'Refs';

let timeout;

function DeviceSelection(props, ref) {

    const defaultData = {}
    const addModalRef = Refs.getInstance().getRef('device');
    const devicePreviewRef = useRef(null);

    const [state, setState] = useNestedState({
        id: null,
        refresh: false,
        data: defaultData,
        categories: defaultData,
        loading: false,
        pages: 0,
        total: 0,
        onSuccess: null,
        onClose: null,
        onEntering: null,
        onExiting: null,
        category_id: null,
        isEmpty: false,
        filter: {
            page: 1
        }
    });

    useImperativeHandle(ref, () => ({
        open: () => {
            handleShow();
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        }
    }));

    useEffect(() => {
        if (state.refresh) {
            loadData();
        }
    }, [state.refresh]);

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const handleShow = () => {
        loadData();
        show();
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
        }));

        if (typeof state.onClose === 'function') {
            state.onClose();
        }

        hide();
    }

    const loadData = () => {
        // if (!state.id) {
        //     return;
        // }

        // Api.get('devices/get?id=' + state.id)
        loading(true)
        Api.get('devices/get', {
            params: state.filter
        })
            .then(res => {
                let isEmpty = false
                let data = res.data.categories.map(t => {
                    t.items = []
                    res.data.items.map(c => {
                        if (t.id == c.category_id) {
                            t.items.push(c)
                        }
                    })
                    return t;
                })

                if (!res.data.total) {
                    isEmpty = true;
                }

                setState(prev => ({
                    ...prev,
                    data,
                    isEmpty,
                    categories: res.data.categories,
                    total: res.data.total,
                    pages: res.data.pages
                }))
                loading(false)
            });
    }

    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout);

        if (typeof key === 'object') {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    ...key
                },
            }));
        } else {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    [key]: val,
                },
            }));
        }

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: 1
                },
                refresh: new Date().getTime()
            }));
        }, delay);
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            refresh: new Date().getTime(),
        }));
    }

    const loading = loading => {
        setState(prev => ({
            ...prev,
            loading
        }))
    }

    const handleSelect = (device) => {
        hide(() => {
            let modal = addModalRef;

            if (modal) {
                modal.add(device);
            }
        });
    }

    const handlePreview = (device) => {
        hide(() => {
            let modal = devicePreviewRef.current;

            if (modal) {
                modal.open(device);
            }

        });
    }

    return (
        <>
            <DevicePreview
                ref={devicePreviewRef}
            />

            <Modal dialogClassName="modal-90w" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Избор на модел
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DeviceSelectionFilter
                        filter={state.filter}
                        handleSearch={handleSearch}
                        categories={state.categories}
                    />
                    {state.loading ?
                        <Loader />
                        : !state.isEmpty ?
                            <>
                                <Table style={{ minWidth: 'auto' }} responsive striped hover>
                                    <thead>
                                        <tr>
                                            <th>Снимка</th>
                                            <th>Производител</th>
                                            <th>Име</th>
                                            <th className="text-center">Статус</th>
                                            <th className="text-center">RS232</th>
                                            <th className="text-center">USB</th>
                                            <th className="text-center">LAN</th>
                                            <th className="text-center">WIFI</th>
                                            <th className="text-center">Bluetooth</th>
                                            <th>Произведен</th>
                                            <th>Тип</th>
                                            <th className="options">
                                                Опции
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <>
                                            {
                                                state.data.length ?
                                                    state.data.map(t => {
                                                        return t.items.length ? <>
                                                            <tr>
                                                                <td colSpan={12}>{t.name}</td>
                                                            </tr>
                                                            {
                                                                t.items.map(c =>
                                                                    <tr key={'c-' + c.id}>
                                                                        <td className="picture">
                                                                            <OverlayTrigger
                                                                                placement="right-start"
                                                                                overlay={
                                                                                    <Tooltip className="transparent" id={'tooltip-img-' + c.id}>
                                                                                        <img src={c.picture_url} style={{ width: '200px' }} loading="lazy" alt="" />
                                                                                    </Tooltip>
                                                                                }
                                                                            >
                                                                                <img src={c.picture_url} style={{ width: '40px' }} loading="lazy" alt="" />
                                                                            </OverlayTrigger>
                                                                        </td>
                                                                        <td>{c.brand?.name || ''}</td>
                                                                        <td>{c.name}</td>
                                                                        <td className="text-center">
                                                                            {c.status == 1 ? <Check style={{ fontSize: '26px', color: '#1E8C45' }} /> : c.status == 2 ? 'Не е тестван' : <X style={{ fontSize: '32px', color: '#ff2727d6' }} />}
                                                                        </td>
                                                                        <td className="text-center">
                                                                            {c.rs232 == 1 ? <Check style={{ fontSize: '26px', color: '#1E8C45' }} /> : c.rs232 == 2 ? 'Не е тестван' : <X style={{ fontSize: '32px', color: '#ff2727d6' }} />}
                                                                        </td>
                                                                        <td className="text-center">
                                                                            {c.usb == 1 ? <Check style={{ fontSize: '26px', color: '#1E8C45' }} /> : c.usb == 2 ? 'Не е тестван' : <X style={{ fontSize: '32px', color: '#ff2727d6' }} />}
                                                                        </td>
                                                                        <td className="text-center">
                                                                            {c.lan == 1 ? <Check style={{ fontSize: '26px', color: '#1E8C45' }} /> : c.lan == 2 ? 'Не е тестван' : <X style={{ fontSize: '32px', color: '#ff2727d6' }} />}
                                                                        </td>
                                                                        <td className="text-center">
                                                                            {c.wifi == 1 ? <Check style={{ fontSize: '26px', color: '#1E8C45' }} /> : c.wifi == 2 ? 'Не е тестван' : <X style={{ fontSize: '32px', color: '#ff2727d6' }} />}
                                                                        </td>
                                                                        <td className="text-center">
                                                                            {c.bluetooth == 1 ? <Check style={{ fontSize: '26px', color: '#1E8C45' }} /> : c.bluetooth == 2 ? 'Не е тестван' : <X style={{ fontSize: '32px', color: '#ff2727d6' }} />}
                                                                        </td>
                                                                        <td>{c.relase_date || ''}</td>
                                                                        <td >{c.category?.name || ''}</td>

                                                                        <td className="options">
                                                                            <MenuOptions>
                                                                                <DeviceOptions
                                                                                    data={c}
                                                                                    handleSelect={handleSelect}
                                                                                    handlePreview={handlePreview}
                                                                                />
                                                                            </MenuOptions>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </> : ''
                                                    }
                                                    )
                                                    : ''
                                            }
                                        </>
                                    </tbody>
                                </Table>

                                <Pagination
                                    page={state.filter.page}
                                    pages={state.pages}
                                    handlePage={handlePage}
                                />
                            </>
                            : <Alert variant="info">
                                Няма намерена информация!
                            </Alert>}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Отказ
                    </Button>
                    <SaveButton
                        loading={state.loading}
                        className="save"
                    />
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default forwardRef(DeviceSelection);
