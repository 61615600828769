export const unique = array => {
    let a = array.concat();

    for (let i = 0; i < a.length; ++i) {
        for (let j = i + 1; j < a.length; ++j) {
            if (a[i] === a[j])
                a.splice(j--, 1);
        }
    }

    return a;
}

export const intersect = (array1, array2) => {
    return array1.filter(x => array2.map(y => String(y)).includes(String(x)));
}

export const diff = (array1, array2) => {
    return array1.filter(x => !array2.map(y => String(y)).includes(String(x)));
}