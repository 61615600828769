import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import { Modal, Button, Col, Row, FloatingLabel, Form, } from 'react-bootstrap';

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//providers
import { useLanguageContext } from 'providers/Language'

//misc
import Question from 'components/modals/Question';
import SaveButton from 'components/misc/Button'
import Info from 'components/modals/Info';
import Autocomplete from 'components/misc/Autocomplete';
import HelpInfo from 'components/misc/Info';

import Address from 'components/pages/clients/partials/Addresses';

function DeliveryData(props, ref) {

    const defaultData = {

    }

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        refresh: false,
        data: defaultData,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null,
        onClose: null,
    });

    const [addresses, setAddresses] = useState([]);
    const [speditors, setSpeditors] = useState([]);

    const [validations, setValidations] = useValidation();

    const addressModalRef = useRef(null);

    useImperativeHandle(ref, () => ({
        open: (id) => {
            open(id);
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        }
    }));

    useEffect(() => {
        if (!state.id) {
            return;
        }

        Api.get('accounts/delivery-data', {
            params: {
                id: state.id,
            }
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data,
            }));

            if (Array.isArray(res.data.client?.addresses)) {
                setAddresses(res.data.client.addresses);
            }
        });
    }, [state.id, state.refresh]);

    useEffect(() => {
        if (!state.show) {
            return;
        }

        if (speditors.length) {
            return;
        }

        Api.get('accounts/speditors')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setSpeditors(res.data);
                }
            });
    }, [state.show]);

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const open = (id) => {
        setState(prev => ({
            ...prev,
            data: defaultData,
            id: id,
            refresh: new Date()
        }));

        show();
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
        }));

        setValidations(null);

        if (typeof state.onClose === 'function') {
            hide(state.onClose);
        } else {
            hide();
        }
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            refresh: new Date().getTime(),
        }));
    }

    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = 'accounts/set-delivery-data';

        let data = new FormData(e.target);

        if (state.id) {
            data.append('id', state.id)
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (typeof state.onSuccess === 'function') {
                state.onSuccess(res.data);
            }

            hide(state.onClose);
        }).catch(error => {
            const _err = error.response;

            if (_err && _err.status && _err.status === 422) {
                setValidations(_err.data.errors);
            }
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }));
        });
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleDateChange = e => {
        const name = e.target.name + '_ymd'
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleChangeAddress = address => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                deliveryaddress: address,
                delivery_address_id: address?.id,
                speditor_id: address?.speditor_id,
            }
        }));
    }

    const handleEditAddress = () => {
        if (!state.data.delivery_address_id) {
            return;
        }

        let modal = addressModalRef.current;

        if (modal) {
            hide();

            modal.edit(state.data.delivery_address_id);

            modal.onSuccess(address => {
                if (address) {
                    handleChangeAddress(address);

                    // ако го има в списъка да се обнови...
                    setAddresses(prev => prev.map(a => {
                        if (Number(a.id) === Number(address.id)) {
                            a = address;
                        }

                        return a;
                    }));
                }

                show();
            });

            modal.onClose(() => {
                show();
            });
        }
    }

    const handleAddAddress = () => {
        let modal = addressModalRef.current;

        if (modal) {

            hide();

            modal.add(state.data.client_id);

            modal.onSuccess(address => {
                if (address) {
                    handleChangeAddress(address);

                    // да го добавим в списъка
                    setAddresses(prev => prev.concat(address));
                }

                show();
            });

            modal.onClose(() => {
                show();
            });
        }
    }

    const handleClickAddress = () => {
        if (addresses.length === 0) {
            handleAddAddress();
        }
    }

    const getAddressName = address => {
        if (!address) {
            return '';
        }

        let name = [];

        if (address.delivery_type === 'address') {
            name = [
                address.dest_name_full,
                address.address,
                address.description,
                address.country_name,
            ];
        }

        if (address.delivery_type === 'office') {
            name = [
                address.dest_name_full,
                address.office_name,
            ];
        }

        if (address.delivery_type === 'store') {
            name = [
                address?.store?.translation?.name || address?.store?.name,
            ];
        }

        return name.filter(p => p).join(', ');
    }

    const getExtendedAddressName = address => {
        if (!address) {
            return '';
        }

        let map = {
            address: 'Адрес на клиент',
            office: 'Офис на куриер',
            store: 'Търговски обект'
        };

        let type = map[address.delivery_type];

        return type + ': ' + getAddressName(address);
    }

    return (
        <>
            <Address
                ref={addressModalRef}
            />

            <Modal size="md" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <form onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Данни за доставка
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Row className="mb-3">
                            <Col>
                                <FloatingLabel label="Телефон">
                                    <Form.Control
                                        type="text"
                                        name={`tel`}
                                        autoComplete="new-password"
                                        value={state.data.tel || ''}
                                        placeholder="Телефон"
                                        onChange={(e) => handleInputChange(e)}
                                        isInvalid={Boolean(validations?.tel)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {validations?.tel && (validations?.tel[0] || validations?.tel)}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col>
                                <Autocomplete
                                    data={addresses}
                                    filterable={false}
                                    inputPlaceholder="Адрес"
                                    inputValue={getAddressName(state.data?.deliveryaddress)}
                                    inputIdName={`delivery_address_id`}
                                    inputIdValue={state.data?.delivery_address_id || ''}
                                    onChange={data => handleChangeAddress(data)}
                                    onClick={e => handleClickAddress()}
                                    error={Boolean(validations && validations.delivery_address_id && (validations.delivery_address_id[0] || validations.delivery_address_id))}
                                    helperText={validations && validations.delivery_address_id && (validations.delivery_address_id[0] || validations.delivery_address_id)}
                                    renderText={data => getExtendedAddressName(data)}
                                    renderInputText={data => getAddressName(data)}
                                />
                                <Button className="mt-2" variant="primary" size="sm" onClick={e => handleAddAddress()}>
                                    Въведи нов адрес
                                </Button>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <FloatingLabel label="Спедитор">
                                    <Form.Select
                                        name="speditor_id"
                                        value={state.data.speditor_id || ''}
                                        onChange={handleInputChange}
                                        isInvalid={Boolean(validations?.speditor_id)}
                                    >
                                        <option value="">Няма</option>
                                        {speditors.map(o =>
                                            <option key={o.id} value={o.id}>{o?.translation?.name || o.name}</option>
                                        )}

                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {validations?.speditor_id && (validations.speditor_id[0] || validations.speditor_id)}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FloatingLabel label="Товарителница">
                                    <Form.Control
                                        type="text"
                                        name={`tracking_number`}
                                        autoComplete="new-password"
                                        value={state.data.tracking_number || ''}
                                        placeholder="Товарителница"
                                        onChange={(e) => handleInputChange(e)}
                                        isInvalid={Boolean(validations?.tracking_number)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {validations?.tracking_number && (validations?.tracking_number[0] || validations?.tracking_number)}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            className="save"
                        />
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default forwardRef(DeliveryData);
