import Layout from 'components/layout/Layout';
import PageLoader from 'components/misc/PageLoader';
import { useAuthDataContext } from 'providers/Auth';
import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import Turnover from './partials/Turnover';
import Users from './partials/Users';
import Summary from './partials/Summary';
import Paymethods from './partials/Paymethods';
import Orders from './partials/Orders';
import Accounts from './partials/Accounts';

import 'assets/scss/dashboard.scss';

function Index() {
    const auth = useAuthDataContext();
    const navigate = useNavigate();


    const [state, setState] = useState({
        refresh: false,
    });

    useEffect(() => {
        setState(prev => ({
            ...prev,
            refresh: new Date()
        }));
    }, [auth.getUser().getStoreId()]);

    return <Navigate replace to="/articles" />

    return (
        <Layout>
            <div className="container-fluid">

                <div className="page-head">
                    <h1 className="display-6">
                        Добре дошли отново, {auth.getUser().getName()}!
                    </h1>
                </div>

                <br />

                <div id="dashboard" className="panel">
                    <div className="row">
                        <div className="col-xl-4 col-lg-6">
                            <Summary refresh={state.refresh} />
                        </div>

                        <div className="col-xl-8 col-lg-6">
                            <Users refresh={state.refresh} />
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-xl-8 col-lg-6">
                            <Turnover refresh={state.refresh} />
                        </div>

                        <div className="col-xl-4 col-lg-6">
                            <Paymethods refresh={state.refresh} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6 col-lg-6">
                            <Orders refresh={state.refresh} />
                        </div>
                        <div className="col-xl-6 col-lg-6">
                            <Accounts refresh={state.refresh} />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
};

export default Index;