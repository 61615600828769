import Api from "helpers/Api";
import { useEffect, useState } from "react";
import { FloatingLabel, Form } from "react-bootstrap";

function ClientGroup(props) {

    const { onChange } = props;

    const [state, setState] = useState([]);

    useEffect(() => {
        Api.get('admin/config/client-groups')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setState(res.data);
                }
            })
    }, []);

    return (
        <FloatingLabel label="Стойност">
            <Form.Select
                name="value"
                value={props.value || ''}
                onChange={e => onChange(e.target.value)}
            >
                <option value=""></option>
                {state.map(g =>
                    <option key={g.id} value={g.id}>{g.name}</option>
                )}
            </Form.Select>
        </FloatingLabel>
    )
}

export default ClientGroup;