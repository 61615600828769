import { useNavigate } from "react-router-dom"
import moment from "moment"
import { FloatingLabel, Form } from 'react-bootstrap'

//providers
// import { useAuthDataContext } from "providers/Auth"

//helpers
import { useQuery } from 'helpers/Url'
import Autocomplete from "components/misc/Autocomplete"

function Filter(props) {
    const history = useNavigate()
    const query = useQuery()

    // const auth = useAuthDataContext();


    const handleDateChange = (date, name) => {
        props.handleSearch(name, moment(date).format('YYYY-MM-DD'));
    }

    const handleSearch = e => {
        const name = e.target.name
        const value = e.target.value

        props.handleSearch(name, value)
    }

    const handleChangeUser = data => {
        props.handleSearch({
            user_id: data?.id || '',
        });
    }

    const handleChangeRoles = data => {
        let checked = data.map(d => d.id);

        props.handleSearch({
            role_id: checked
        });
    }

    const handleChangeStore = data => {
        let checked = data.map(d => d.id);

        props.handleSearch('store_id', checked);
    }

    return (
        <div className="row page-filter">
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <FloatingLabel label="Търсене">
                    <Form.Control
                        type="text"
                        placeholder="Търсене"
                        name="search"
                        value={props.filter.search || ''}
                        onChange={handleSearch}
                    />
                </FloatingLabel>
            </div>
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <Autocomplete
                    inputPlaceholder="Потребител"
                    url="autocomplete/admins"
                    inputIdName="user_id"
                    // inputValue={state.data?.supplier?.name}
                    inputIdValue={props.filter.user_id || ''}
                    onChange={handleChangeUser}
                    onInputChange={handleChangeUser}
                />
            </div>
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <Autocomplete
                    url="autocomplete/roles"
                    inputPlaceholder="Роля"
                    selectedIds={props.filter.role_id || []}
                    inputIdName={`role_id`}
                    renderText={data => data?.name || ''}
                    onChange={handleChangeRoles}
                    multiple
                    filterable={false}
                />
            </div>
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <Autocomplete
                    url="autocomplete/stores"
                    params={{
                        all: 1
                    }}
                    inputPlaceholder="Търговски обект"
                    selectedIds={props.filter.store_id || []}
                    renderText={data => data?.translation?.name || data?.name}
                    onChange={data => handleChangeStore(data)}
                    multiple
                    filterable={false}
                />
            </div>
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <FloatingLabel label="Изтрити">
                    <Form.Select
                        name="deleted"
                        value={props.filter.deleted || ''}
                        onChange={handleSearch}
                    >
                        <option value="">По подразбиране</option>
                        <option value="with-deleted">Покажи изтрити</option>
                        <option value="only-deleted">Покажи само изтрити</option>
                    </Form.Select>
                </FloatingLabel>
            </div>
        </div>
    )
}

export default Filter;
