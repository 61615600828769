import { useState, useEffect } from 'react'
import { Table } from 'react-bootstrap';
import Api from 'helpers/Api';
import Loader from 'components/misc/Loader';
import moment from 'moment';
import NoDataFound from 'components/misc/NoDataFound';
import Pagination from 'components/misc/Pagination';

function Index(props) {

    const defaultData = [];

    const [state, setState] = useState({
        data: defaultData,
        pages: 0,
        total: 0,
        loading: false,
        filter: {
            page: 1,
        },
        refresh: false,
    });

    useEffect(() => {
        setState(prev => ({
            ...prev,
            loading: true
        }));

        Api.get('storemoves/movements', {
            params: {
                id: props.id,
                ...state.filter
            }
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data.items,
                total: res.data.total,
                pages: res.data.pages,
                loading: false,
            }));
        });
    }, [props.id, state.refresh]);

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            refresh: new Date()
        }));
    }

    const showArticle = id => {
        props.showArticle(id);
    }

    return (

        state.loading
            ?
            <Loader />
            :
            state.data.length === 0 ?
                <NoDataFound />
                :
                <>

                    <Table className="mt-3 big" size="sm" striped>
                        <thead>
                            <tr>
                                <th style={{ width: '5%' }}>
                                    ID
                                </th>
                                <th>
                                    Дата
                                </th>
                                <th className="text-right">
                                    Артикул ID
                                </th>
                                <th>
                                    Артикул
                                </th>
                                <th className="text-right">
                                    Преди
                                </th>
                                <th className="text-right">
                                    Количество
                                </th>
                                <th className="text-right">
                                    След
                                </th>
                                <th>
                                    Обект
                                </th>
                                <th>
                                    Склад
                                </th>
                                <th>
                                    Потребител
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {state.data.map(r =>
                                <tr key={r.id}>
                                    <td>
                                        {r.id}
                                    </td>
                                    <td>
                                        {r.created_at ? moment(r.created_at).format('DD.MM.YYYY HH:mm') : ''}
                                    </td>
                                    <td className="text-right">
                                        {r.article_id}
                                    </td>
                                    <td>
                                        <span className="link" onClick={e => showArticle(r.article_id)}>
                                            {r?.article?.article_name}
                                        </span>
                                    </td>
                                    <td className="text-right">
                                        {r.old_quantity} {r?.article?.amount_type_short}
                                    </td>
                                    <td className={`text-right ${r.quantity > 0 ? 'positive-value' : 'negative-value'}`}>
                                        {r.quantity} {r?.article?.amount_type_short}
                                    </td>
                                    <td className="text-right">
                                        {r.new_quantity} {r?.article?.amount_type_short}
                                    </td>
                                    <td>
                                        {r?.depot?.store?.translation?.name || r?.depot?.store?.name}
                                    </td>
                                    <td>
                                        {r?.depot?.name}
                                    </td>
                                    <td>
                                        {r?.user?.username}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>

                    <Pagination
                        className="mt-3"
                        page={state.filter.page}
                        pages={state.pages}
                        total={state.total}
                        handlePage={handlePage}
                    />
                </>
    )
}

export default Index;