import { useState, useEffect } from 'react'
import { Button, Dropdown, Table } from 'react-bootstrap';
import Api from 'helpers/Api';
import Loader from 'components/misc/Loader';
import moment from 'moment';
import PriceValue from 'components/partials/PriceValue';
import { useNavigate } from 'react-router-dom';
import { Envelope, Files, FileText, Pencil, Printer } from 'react-bootstrap-icons';
import { useAuthDataContext } from 'providers/Auth';

function Index(props) {

    const auth = useAuthDataContext();

    const navigate = useNavigate();

    const defaultData = {
        rows: []
    };

    const [state, setState] = useState({
        data: defaultData,
        loading: false,
    });

    useEffect(() => {
        setState(prev => ({
            ...prev,
            loading: state.data.id ? false : true
        }));

        Api.get('invoices/show', {
            params: {
                id: props.id
            }
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data,
                loading: false,
            }));
        });
    }, [props.id]);

    const handleEdit = () => {
        navigate('/invoices/' + state.data.id + '/edit');
    }

    const handlePrint = (copy = false, withCopy = false) => {
        let params = new URLSearchParams();

        params.append('ids[]', state.data.id);

        params.set('copy', Number(copy));
        params.set('withcopy', Number(withCopy));

        const url = process.env.REACT_APP_URL + '/api/invoices/bulk-stream?' + params.toString();

        window.open(url);
    }

    const sendMail = () => {
        props.sendMail(state.data);
    }

    const newCreditNote = () => {
        navigate('/invoices/new?from_invoice_id=' + state.data.id + '&cn=1');
    }

    const newClone = () => {
        window.location = '/invoices/new?from_invoice_id=' + state.data.id;
    }

    return (

        state.loading
            ?
            <Loader />
            :
            <>
                <Table size="sm" bordered>
                    <tbody>
                        <tr>
                            <td className="th" style={{ width: '11.66666%' }}>
                                ID
                            </td>
                            <td style={{ width: '25%' }}>
                                {state.data.id}
                            </td>
                            <td className="th" style={{ width: '11.66666%' }}>
                                Създал
                            </td>
                            <td style={{ width: '15%' }}>
                                {state.data?.creator?.username}
                            </td>
                            <td className="th" style={{ width: '11.66666%' }}>
                                Създадено на
                            </td>
                            <td style={{ width: '25%' }}>
                                {state.data.created_at ? moment(state.data.created_at).format('DD.MM.YYYY HH:mm') : ''}
                            </td>
                        </tr>
                        <tr>
                            <td className="th">
                                Клиент
                            </td>
                            <td>
                                {state.data?.client?.name}
                            </td>
                            <td className="th">
                                ТО
                            </td>
                            <td>
                                {state.data?.store?.name}
                            </td>
                            <td className="th">
                                Издател
                            </td>
                            <td>
                                {state.data?.company?.pop_name}
                            </td>
                        </tr>
                        <tr>
                            <td className="th">
                                Фирма
                            </td>
                            <td>
                                {state.data?.receiver_company_name}
                            </td>
                            <td className="th">
                                Тип
                            </td>
                            <td>
                                {state.data?.type?.name}
                            </td>
                            <td className="th">
                                Фирма
                            </td>
                            <td>
                                {state.data?.seller_company_name}
                            </td>
                        </tr>
                        <tr>
                            <td className="th">
                                Адрес
                            </td>
                            <td>
                                {state.data?.receiver_address}
                            </td>
                            <td className="th">
                                Номер
                            </td>
                            <td>
                                {state.data?.no}
                            </td>
                            <td className="th">
                                Адрес
                            </td>
                            <td>
                                {state.data?.seller_address}
                            </td>
                        </tr>
                        <tr>
                            <td className="th">
                                Град
                            </td>
                            <td>
                                {state.data?.receiver_town}
                            </td>
                            <td className="th">
                                Към фактура
                            </td>
                            <td>
                                {state.data?.parent_num}
                            </td>
                            <td className="th">
                                Град
                            </td>
                            <td>
                                {state.data?.seller_town}
                            </td>
                        </tr>
                        <tr>
                            <td className="th">
                                ЕИК/ЕГН
                            </td>
                            <td>
                                {state.data?.receiver_identity_num}
                            </td>
                            <td className="th">
                                Дата
                            </td>
                            <td>
                                {state.data?.date ? moment(state.data.date).format('DD.MM.YYYY') : ''}
                            </td>
                            <td className="th">
                                ЕИК/ЕГН
                            </td>
                            <td>
                                {state.data?.seller_identity_num}
                            </td>
                        </tr>
                        <tr>
                            <td className="th">
                                ДДС №
                            </td>
                            <td>
                                {state.data?.receiver_vat_num}
                            </td>
                            <td className="th">
                                Срок
                            </td>
                            <td>
                                {state.data?.term_date ? moment(state.data.term_date).format('DD.MM.YYYY') : ''}
                            </td>
                            <td className="th">
                                ДДС №
                            </td>
                            <td>
                                {state.data?.seller_vat_num}
                            </td>
                        </tr>
                        <tr>
                            <td className="th">
                                МОЛ
                            </td>
                            <td>
                                {state.data?.receiver_mol}
                            </td>
                            <td className="th">
                                Срок
                            </td>
                            <td>
                                {state.data?.payment_date ? moment(state.data.payment_date).format('DD.MM.YYYY') : ''}
                            </td>
                            <td className="th">
                                МОЛ
                            </td>
                            <td>
                                {state.data?.seller_mol}
                            </td>
                        </tr>
                        <tr>
                            <td className="th">
                                Начин
                            </td>
                            <td>
                                {state.data?.paymethod?.name}
                            </td>
                            <td className="th">
                                Отстъпка
                            </td>
                            <td>
                                {state.data?.discount || 0}%
                            </td>
                            <td className="th">

                            </td>
                            <td>

                            </td>
                        </tr>

                    </tbody>
                </Table>

                <div className="mt-3">
                    <Button size="sm" variant="primary" disabled={auth.getUser().permission('invoices_edit') === false} onClick={() => handleEdit()}>
                        <Pencil /> Редактиране
                    </Button>
                    <Dropdown drop="down" style={{ marginLeft: '10px', display: 'inline' }}>
                        <Dropdown.Toggle size="sm" variant="secondary" disabled={auth.getUser().permission('invoices_print') === false}>
                            <Printer /> Печат
                        </Dropdown.Toggle>
                        <Dropdown.Menu align="start" style={{ padding: '15px', width: '320px' }}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Button size="sm" variant="primary" onClick={e => handlePrint(false, false)}>
                                    Оригинал
                                </Button>
                                <Button size="sm" variant="primary" style={{ marginLeft: '5px' }} onClick={e => handlePrint(true, false)}>
                                    Копие
                                </Button>
                                <Button size="sm" variant="primary" style={{ marginLeft: '5px' }} onClick={e => handlePrint(false, true)}>
                                    Оригинал+Копие
                                </Button>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Button size="sm" variant="secondary" onClick={() => sendMail()} disabled={auth.getUser().permission('invoices_mail') === false} style={{ marginLeft: '10px' }}>
                        <Envelope /> Изпращане
                    </Button>
                    <Button size="sm" variant="secondary" onClick={() => newClone()} disabled={auth.getUser().permission('invoices_create') === false} style={{ marginLeft: '10px' }}>
                        <Files /> Клонирай
                    </Button>
                    {state.data.allow_credit_note &&
                        <Button size="sm" variant="danger" onClick={() => newCreditNote()} disabled={auth.getUser().permission('invoices_create') === false} style={{ marginLeft: '10px' }}>
                            <FileText /> Кредитно известие
                        </Button>
                    }
                </div>

                <Table className="mt-3 xxl" size="sm" bordered>
                    <thead>
                        <tr>
                            <th style={{ width: '5%' }}>
                                ID
                            </th>
                            <th>
                                Реф. №
                            </th>
                            <th>
                                Артикул
                            </th>
                            <th className="text-right">
                                Количество
                            </th>
                            <th>
                                Мярка
                            </th>
                            <th>
                                ДДС група
                            </th>
                            <th className="text-right">
                                Отстъпка
                            </th>
                            <th className="text-right">
                                Ед. цена без ДДС
                            </th>
                            <th className="text-right">
                                Ед. цена с ДДС
                            </th>
                            <th className="text-right">
                                Тотал
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {state.data.rows.map(r =>
                            <tr key={r.id}>
                                <td>
                                    {r.id}
                                </td>
                                <td>
                                    {r.inv_ref_num}
                                </td>
                                <td>
                                    {r?.name}
                                </td>
                                <td className="text-right">
                                    {r.quantity}
                                </td>
                                <td>
                                    {r.unit}
                                </td>
                                <td>
                                    {r.taxgroup?.name}
                                </td>
                                <td className="text-right">
                                    {r.discount || 0}%
                                </td>
                                <td className="text-right">
                                    <PriceValue prefix={state.data.currency?.prefix} sufix={state.data.currency?.sufix}>{r.net_price}</PriceValue>
                                </td>
                                <td className="text-right">
                                    <PriceValue prefix={state.data.currency?.prefix} sufix={state.data.currency?.sufix}>{r.gross_price}</PriceValue>
                                </td>
                                <td className="text-right">
                                    <PriceValue prefix={state.data.currency?.prefix} sufix={state.data.currency?.sufix}>{r.total_sum}</PriceValue>
                                </td>
                            </tr>
                        )}
                        <tr className="total">
                            <td colSpan={9}>
                                Всичко
                            </td>
                            <td className="text-right">
                                <PriceValue prefix={state.data.currency?.prefix} sufix={state.data.currency?.sufix}>{state.data.price}</PriceValue>
                            </td>
                        </tr>
                        <tr className="total">
                            <td colSpan={9}>
                                Отстъпка
                            </td>
                            <td className="text-right">
                                <PriceValue prefix={state.data.currency?.prefix} sufix={state.data.currency?.sufix}>{state.data.discount_price}</PriceValue>
                            </td>
                        </tr>
                        <tr className="total">
                            <td colSpan={9}>
                                Данъчна основа
                            </td>
                            <td className="text-right">
                                <PriceValue prefix={state.data.currency?.prefix} sufix={state.data.currency?.sufix}>{state.data.net_price}</PriceValue>
                            </td>
                        </tr>
                        <tr className="total">
                            <td colSpan={9}>
                                Начислен ДДС, основа
                            </td>
                            <td className="text-right">
                                <PriceValue prefix={state.data.currency?.prefix} sufix={state.data.currency?.sufix}>{state.data.vat_price}</PriceValue>
                            </td>
                        </tr>
                        <tr className="total">
                            <td colSpan={9}>
                                Сума за плащане
                            </td>
                            <td className="text-right">
                                <PriceValue prefix={state.data.currency?.prefix} sufix={state.data.currency?.sufix}>{state.data.gross_price}</PriceValue>
                            </td>
                        </tr>
                    </tbody>
                </Table>

            </>
    )
}

export default Index;