import { useEffect, useRef, useState } from 'react';
import Api from 'helpers/Api';
import { buildSearchParams, buildUrl, useQuery } from 'helpers/Url';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Alert, Button, Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';

import Options from './partials/Options';

import Question from 'components/modals/Question';
import Filter from './partials/Filter';
import Layout from 'components/layout/Layout';
import View from './view/View';
import New from './partials/New';

//misc
import MenuOptions from 'components/misc/MenuOptions'
import Pagination from 'components/misc/Pagination'
import Loader from 'components/misc/Loader';
import NoDataFound from 'components/misc/NoDataFound';
import { useAuthDataContext } from 'providers/Auth';
import TableHeader from 'components/misc/TableHeader';
import TableCol from 'components/misc/TableCol';
import { PPP } from 'constants/documents';
import { useAppContext } from 'providers/App';
import SendMail from './partials/SendMail';

let timeout;

function Index() {

    const location = useLocation();
    const navigate = useNavigate();
    const query = useQuery();
    const auth = useAuthDataContext();
    const app = useAppContext();

    const tableRef = useRef(null);
    const deleteModalRef = useRef(null);
    const viewModalRef = useRef(null);
    const newModalRef = useRef(null);
    const sendMailModalRef = useRef(null);

    const [state, setState] = useState({
        sorting: false,
        loading: true,
        refresh: false,
        setFilter: false,
        data: [],
        pages: 0,
        total: 0,
        totals: {},
        filter: {
            page: 1,
            type_id: PPP,
        },
    });

    const [selected, setSelected] = useState([]);

    useEffect(() => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: query.get('page') || 1,
                search: query.get('search') || '',
                status_id: query.get('status_id') || '',
                client_id: query.get('client_id') || '',
                article_id: query.get('article_id') || '',
                owner_id: query.get('owner_id') || '',
                created_from_date: query.get('created_from_date') || '',
                created_to_date: query.get('created_to_date') || '',
                creator_id: query.get('creator_id') || '',
                value_date: query.get('value_date') || '',
                is_template: query.get('is_template') || '',
                deleted: query.get('deleted') || '',
                sort: query.get('sort') || '',
                order: query.get('order') || '',
            },
            refresh: new Date().getTime()
        }))
    }, [location.search]);

    useEffect(() => {
        if (state.setFilter) {
            navigate('?' + buildSearchParams(query, state.filter));
        }

    }, [state.setFilter]);

    useEffect(() => {
        if (state.refresh) {
            loadData();
        }
    }, [state.refresh]);

    useEffect(() => {
        refresh();
    }, [auth.getUser()?.getStoreId()]);

    const loadData = () => {

        loading(true);

        let url = 'documents/all';

        Api.get(url, {
            params: state.filter
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data.items,
                total: res.data.total,
                pages: res.data.pages,
                totals: res.data.totals,
                filter: {
                    ...prev.filter,
                    ...res.data.filter,
                }
            }));

            if (res.data.pages > 0 && state.filter.page > res.data.pages) {
                return handlePage({ selected: res.data.pages - 1 });
            }

            loading(false);
            sorting(false);
        });
    }

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }));
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: reset ? 1 : prev.filter.page
            },
            setFilter: new Date().getTime(),
            refresh: new Date().getTime(),
        }));
    }

    const handleAdd = () => {
        let modal = newModalRef.current;

        modal.open();
    }

    // update
    const handleEdit = data => {
        if (data.deleted) {
            return;
        }

        navigate('/protocols/' + data.id + '/edit');
    }

    // delete
    const handleDelete = data => {
        let modal = deleteModalRef.current;

        modal.open();
        modal.onSuccess(() => {
            handleDestroy(data.id);
        })
    }

    const handleDestroy = id => {
        Api.post('documents/delete', {
            id: id
        }).then(res => {
            if (res.data.success) {
                refresh(false);
            }
        });
    }

    const handleRestore = data => {
        Api.post('documents/restore', {
            id: data.id
        }).then(res => {
            if (res.data.success) {
                refresh(false);
            }
        });
    }

    const handleView = data => {
        let modal = viewModalRef.current;

        modal.open(data.id);
    }

    // Search
    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout);

        if (typeof key === 'object') {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    ...key
                },
            }));
        } else {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    [key]: val,
                },
            }));
        }

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: 1
                },
                setFilter: new Date().getTime()
            }));
        }, delay);
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            setFilter: new Date().getTime()
        }));
    }

    const sorting = (sorting) => {
        setState(prev => ({
            ...prev,
            sorting: Boolean(sorting)
        }));
    }

    const handleSort = (sort, order) => {
        setState(prev => ({
            ...prev,
            sorting: true,
            filter: {
                ...prev.filter,
                sort: sort,
                order: order,
                page: 1,
            },
            setFilter: new Date().getTime()
        }));
    }

    const handleSend = () => {
        if (selected.length === 0) {
            return app.showWarning('Изберете поне 1 документ');
        }

        let modal = sendMailModalRef.current;

        if (!modal) {
            return;
        }

        modal.show(selected);
    }

    const handlePrint = () => {
        if (selected.length === 0) {
            return app.showWarning('Изберете поне 1 документ');
        }

        const url = process.env.REACT_APP_URL + '/api/documents/bulk-stream';

        window.open(url);
    }

    const toggleRow = (id) => {
        if (isSelected(id)) {
            setSelected(prev => prev.filter(i => Number(i) !== Number(id)));
        } else {
            setSelected(prev => prev.concat(Number(id)));
        }
    }

    const isSelected = id => {
        return selected.indexOf(Number(id)) > -1;
    }

    const toggleAll = () => {
        if (isAllSelected()) {
            setSelected([]);
        } else {
            setSelected(state.data.map(c => Number(c.id)));
        }
    }

    const isAllSelected = () => {
        let selected = true;

        state.data.map(c => {
            if (!isSelected(c.id)) {
                selected = false;
            }
        });

        return selected;
    }



    return (
        <>

            <Question
                ref={deleteModalRef}
                mainMessage="Сигурни ли сте?"
                agreeBtnText="Изтриване"
                agreeBtnClass="remove"
            />

            <View
                ref={viewModalRef}
            />

            <New
                ref={newModalRef}
            />

            <SendMail
                ref={sendMailModalRef}
            />

            <Layout>
                <div className="container-fluid">

                    <div className="page-head">
                        <h1 className="display-6">
                            Приемо-предавателни протоколи
                        </h1>

                        <div className="buttons">
                            <Button variant="outline-dark" onClick={handleAdd} disabled={auth.getUser().permission('document_acceptance_protocol_edit') === false}>
                                Нов приемо-предавателен протокол
                            </Button>
                        </div>
                    </div>

                    <br />

                    <div className="panel">
                        <Filter
                            filter={state.filter}
                            handleSearch={handleSearch}
                        />

                        {state.loading && !state.sorting
                            ?
                            <Loader />
                            :
                            state.data.length === 0 ?
                                <NoDataFound />
                                :
                                <>
                                    <div className="mb-3">
                                        {/* <Button variant="outline-dark" onClick={handlePrint}>
                                            Печат
                                        </Button> */}
                                        <Button variant="outline-dark" onClick={handleSend}>
                                            Изпращане
                                        </Button>
                                    </div>

                                    <Table className="big" responsive striped hover ref={tableRef}>
                                        <TableHeader
                                            tableRef={tableRef}
                                            activeSortKey={state.filter.sort}
                                            activeSortDir={state.filter.order}
                                            onSort={(col, dir) => handleSort(col, dir)}
                                        >
                                            <TableCol className="text-center">
                                                <input
                                                    type="checkbox"
                                                    checked={isAllSelected()}
                                                    onChange={e => toggleAll()}
                                                />
                                            </TableCol>
                                            <TableCol sortable sortKey="id">ID</TableCol>
                                            <TableCol sortable sortKey="document_name">Име</TableCol>
                                            <TableCol sortable sortKey="no">Док. номер</TableCol>
                                            <TableCol sortable sortKey="client">Клиент</TableCol>
                                            <TableCol sortable sortKey="creator">Съставил</TableCol>
                                            <TableCol sortable sortKey="owner">Отговорник</TableCol>
                                            <TableCol className="text-center" sortable sortKey="status">Статус</TableCol>
                                            <TableCol sortable sortKey="created_at">Създаден</TableCol>
                                            <TableCol sortable sortKey="updated_at">Актуализиран</TableCol>
                                            <TableCol className="text-right" sortable sortKey="articles_count">Стоки бр</TableCol>
                                            <TableCol className="options">Опции</TableCol>
                                        </TableHeader>
                                        <tbody>
                                            {state.data.map((c, index) =>
                                                <tr
                                                    key={'c-' + c.id + 'index-' + index}
                                                    className={c.deleted ? 'deleted' : ''}
                                                >
                                                    <td className="text-center">
                                                        <input
                                                            type="checkbox"
                                                            value={1}
                                                            checked={isSelected(c.id)}
                                                            onChange={e => toggleRow(c.id)}
                                                        />
                                                    </td>
                                                    <td>
                                                        {c.id}
                                                    </td>
                                                    <td>
                                                        {c.document_name}
                                                    </td>
                                                    <td>
                                                        {c.no}
                                                    </td>
                                                    <td>
                                                        {c.client?.name}
                                                    </td>
                                                    <td>
                                                        {c?.creator?.username}
                                                    </td>
                                                    <td>
                                                        {c?.owner?.username}
                                                    </td>
                                                    <td className={`text-center`} style={{ backgroundColor: c.status?.background }}>
                                                        {c?.status?.translation?.name || c?.status?.name}
                                                    </td>
                                                    <td>
                                                        {c.created_at ? moment(c.created_at).format('DD.MM.YYYY HH:mm') : ''}
                                                    </td>
                                                    <td>
                                                        {c.updated_at ? moment(c.updated_at).format('DD.MM.YYYY HH:mm') : ''}
                                                    </td>
                                                    <td className="text-right">
                                                        {c.articles_count}
                                                    </td>
                                                    <td className="options">
                                                        <MenuOptions>
                                                            <Options
                                                                data={c}
                                                                handleEdit={handleEdit}
                                                                handleDelete={handleDelete}
                                                                handleRestore={handleRestore}
                                                                handleView={handleView}
                                                            />
                                                        </MenuOptions>
                                                    </td>
                                                </tr>
                                            )}

                                        </tbody>
                                    </Table>

                                    <Pagination
                                        className="mt-3"
                                        page={state.filter.page}
                                        pages={state.pages}
                                        total={state.total}
                                        handlePage={handlePage}
                                    />
                                </>
                        }

                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Index;
