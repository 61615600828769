import { useState, useImperativeHandle, forwardRef } from 'react'
import { Modal, Button, Row, Col, FloatingLabel, Form } from 'react-bootstrap';
import { createPortal } from 'react-dom';

import SaveButton from 'components/misc/Button'
import { buildUrl } from 'helpers/Url';
import { useAuthDataContext } from 'providers/Auth';
import { useAppContext } from 'providers/App';

function Export(props, ref) {

    const auth = useAuthDataContext();
    const app = useAppContext();

    const defaultData = {
        type: 'excel'
    }

    const [state, setState] = useState({
        show: false,
        params: null,
        data: defaultData,
        onSuccess: null,
        onClose: null,
        onEntering: null,
        onExiting: null,
    });

    useImperativeHandle(ref, () => ({
        open: () => {
            handleShow();
        },
        close: () => {
            handleClose();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        },
        onCancel: fn => {
            setState(prev => ({
                ...prev,
                onCancel: fn
            }));
        }
    }));

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const handleShow = () => {
        show();
    }


    const handleClose = () => {
        hide();
    }

    const handleSave = e => {
        e.preventDefault();

        // setState(prev => ({
        //     ...prev,
        //     loading: true,
        // }));

        let url = process.env.REACT_APP_URL + '/api/' + props.url;
        let filter = props.filter;

        let full = buildUrl(url, {
            ...filter,
            type: state.data.type,
            export: 1,
            token: auth.getToken()
        });

        // console.log(full);

        app.showWarning('Моля, изчакайте...');

        // const w = window.open();

        // setTimeout(() => {
        // w.location = full;
        // }, 1000);

        download(full);

        hide();
    }

    const download = url => {
        const link = document.createElement("a");
        link.download = 'Справка';
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        // delete link;
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: value
            }
        }))
    }

    const style = {
        button: {
            margin: '0 10px',
            minWidth: '120px'
        }
    }

    return createPortal(
        <Modal size="sm" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
            <form onSubmit={handleSave}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Експорт
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <FloatingLabel label="Тип">
                                <Form.Select
                                    name="type"
                                    value={state.data.type || 'excel'}
                                    onChange={handleInputChange}
                                >
                                    <option value="excel">MS Excel (.xlsx)</option>
                                    <option value="csv">Comma-Separated Values (.csv)</option>
                                    <option value="html">HTML (.html)</option>
                                    <option value="pdf">Print format (.pdf)</option>
                                </Form.Select>
                            </FloatingLabel>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Отказ
                    </Button>
                    <SaveButton
                        loading={state.loading}
                        className="save"
                    />
                </Modal.Footer>
            </form>
        </Modal>,
        document.body
    )

}

export default forwardRef(Export);