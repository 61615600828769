import { useEffect, useRef, useState } from 'react';
import { buildSearchParams, useQuery } from 'helpers/Url';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Button, Row, Col, Card } from 'react-bootstrap';

import Api from 'helpers/Api';

import Options from './partials/Options';

import Question from 'components/modals/Question';
import Success from 'components/modals/Success';
import Filter from './partials/Filter';
import AddOrEdit from './partials/AddOrEdit';
import Layout from 'components/layout/Layout';

//misc
import SaveButton from 'components/misc/Button';
import MenuOptions from 'components/misc/MenuOptions'
import Pagination from 'components/misc/Pagination'
import Loader from 'components/misc/Loader';
import NoDataFound from 'components/misc/NoDataFound';
import Alert from 'components/misc/Alert';
import SortableTree from 'react-sortable-tree';

import { useAuthDataContext } from 'providers/Auth';
import { useAppContext } from 'providers/App';

import CategoryArticles from './partials/CategoryArticles';
import ArticleList from './partials/ArticleList';

import Refs from 'Refs';

import 'react-sortable-tree/style.css';
import './partials/style.scss';

let timeout;

function Index() {

    const app = useAppContext();
    const auth = useAuthDataContext();
    const location = useLocation();
    const history = useNavigate();
    const query = useQuery();

    const addOrEditModalRef = useRef(null);
    const successModalRef = useRef(null);
    const deleteModalRef = useRef(null);
    const categoryList = useRef(null);

    const [state, setState] = useState({
        loading: true,
        loadingButton: false,
        refresh: false,
        setFilter: false,
        data: [],
        pages: 0,
        total: 0,
        tree: [],
        sortedList: [],
        selected: null,
        filter: {
            page: 1,
        },
        message: 1,
    });

    useEffect(() => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: query.get('page') || 1,
                search: query.get('search') || ''
            },
            refresh: new Date().getTime()
        }))
    }, [location.search]);

    useEffect(() => {
        if (state.setFilter) {
            history('?' + buildSearchParams(query, state.filter));
        }
    }, [state.setFilter]);

    useEffect(() => {
        if (state.refresh) {
            loadData();
        }
    }, [state.refresh, auth.getUser().getStoreId()]);

    const loadData = () => {

        loading(true);

        let url = 'categories/get';

        Api.get(url, {
            params: state.filter
        }).then(res => {
            let tree = handleCategories(res.data.items)

            setState(prev => ({
                ...prev,
                tree,
                data: res.data.items,
                total: res.data.total,
                pages: res.data.pages
            }));

            if (res.data.pages > 0 && state.filter.page > res.data.pages) {
                return handlePage({ selected: res.data.pages - 1 });
            }

            loading(false);
        });
    }

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }));
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: reset ? 1 : prev.filter.page
            },
            setFilter: new Date().getTime(),
            refresh: new Date().getTime(),
        }));
    }

    const handleAdd = id => {
        let modal = addOrEditModalRef.current;

        if (id) {
            modal.add(id)
        } else {
            modal.add()
        }

        modal.onSuccess(() => {
            refresh(true);
        });
    }

    // update
    const handleEdit = id => {
        let modal = addOrEditModalRef.current;

        modal.edit(id);

        modal.onSuccess(() => {
            refresh(false);
        });
    }

    // delete
    const handleDelete = id => {
        let modal = deleteModalRef.current;

        modal.open();
        modal.onSuccess(() => {
            handleDestroy(id);
        })
    }

    const handleDestroy = id => {
        Api.post('/categories/delete', {
            id: id
        }).then(res => {
            if (res.data.success) {
                refresh(false);
            }
        });
    }

    // Search
    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout);

        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [key]: val,
            },
        }));

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: 1
                },
                setFilter: new Date().getTime()
            }));
        }, delay);
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            setFilter: new Date().getTime()
        }));
    }

    const handleCategories = (items, level = 0) => {
        let arr = []
        if (items.length) {
            items.map(i => {
                i.title = i.translation?.name
                if (i.children.length) {
                    i.children = handleCategories(i.children, level + 1)
                }
                i.expanded = level === 0 && items.length < 10
                arr.push(i)
            })
        }
        return arr
    }

    const hasDragPermission = () => {
        return auth.getUser().permission('categories_sort');
    }

    const changeTree = (treeData) => {
        let tree = treeData['treeData']

        //Make a sorted list from categories tree
        const sortedList = handleSortItems(tree)

        setState(prev => ({
            ...prev,
            tree,
            sortedList
        }))
    }

    const handleSortItems = (items, arr = [], parent_id = null) => {
        if (items.length) {
            items.map((t, index) => {
                let newItem = {
                    id: t.id,
                    parent_id: parent_id,
                    weight: index + 1
                }
                arr.push(newItem)
                if (t.children.length) {
                    handleSortItems(t.children, arr, t.id)
                }
            })
        }
        return arr
    }

    const handleSaveChanges = () => {
        loadingButton(true)
        //Sort categories
        Api.post('categories/sort-categories', state.sortedList).then(res => {
            let modal = successModalRef.current;

            modal.open();
            modal.onSuccess(() => {
                loadingButton(false)
            })

        }).catch(error => {
            loadingButton(false)
        })
    }

    const loadingButton = (loading) => {
        setState(prev => ({
            ...prev,
            loadingButton: Boolean(loading)
        }));
    }

    const setSelectedCategory = category => {
        setState(prev => ({
            ...prev,
            selected: category
        }))
    }

    const handleSelected = (id) => {
        return Number(id) === Number(state.selected?.id);
    }

    return (
        <>

            <AddOrEdit
                ref={addOrEditModalRef}
            />

            <Question
                ref={deleteModalRef}
                mainMessage="Сигурни ли сте?"
                agreeBtnText="Изтриване"
                agreeBtnClass="remove"
            />

            <Success
                ref={successModalRef}
                mainMessage="Данните бяха запазени успешно"
            />

            <Layout>
                <div className="container-fluid">

                    <div className="page-head">
                        <h1 className="display-6">
                            Категории
                        </h1>

                        <div className="buttons">
                            <Button variant="outline-dark" onClick={() => handleAdd(null)} disabled={auth.getUser().permission('categories_edit') === false}>
                                Добави
                            </Button>
                        </div>
                    </div>

                    <br />

                    <div id="category" className="panel">
                        {/* <Filter
                            filter={state.filter}
                            handleSearch={handleSearch}
                        /> */}

                        {state.loading
                            ?
                            <Loader />
                            :
                            state.data.length === 0 ?
                                <NoDataFound>
                                    <div>
                                        Няма намерена информация!
                                    </div>
                                    <br />
                                    <Button variant="outline-dark" onClick={e => handleAdd()} disabled={auth.getUser().permission('categories_edit') === false}>
                                        Създай нова категория
                                    </Button>
                                </NoDataFound>
                                :
                                <>
                                    <Row>
                                        <Col sm={3}>
                                            <Card>
                                                <Card.Header>
                                                    Списък с категории
                                                </Card.Header>
                                                <Card.Body className="categories-tree">
                                                    <SortableTree
                                                        treeData={state.tree}
                                                        isVirtualized={false}
                                                        canDrag={hasDragPermission}
                                                        canDrop={hasDragPermission}
                                                        onChange={treeData => changeTree({ treeData })}
                                                        rowDirection="ltr" //left to right and right to left(ltr)
                                                        // getNodeKey={({ node }) => console.log(node)} //get each element
                                                        // onVisibilityToggle={treeData => treeVisibility(treeData)} //Expanding categories
                                                        generateNodeProps={({ node, path }) => ({
                                                            title: (
                                                                <Row style={{ backgroundColor: handleSelected(node.id) ? 'rgba(187, 193, 245,0.6)' : '' }} onClick={() => setSelectedCategory(node)}>
                                                                    <Col>
                                                                        <a href={node.url}>{node.title}</a>
                                                                    </Col>
                                                                    <Col className="category-options">
                                                                        {node.allow_edit &&
                                                                            <MenuOptions>
                                                                                <Options
                                                                                    data={node}
                                                                                    handleAdd={handleAdd}
                                                                                    handleEdit={handleEdit}
                                                                                    handleDelete={handleDelete}
                                                                                />
                                                                            </MenuOptions>
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                            ),
                                                            className: 'selectable',
                                                        })}
                                                    />
                                                    <div className="buttons mt-3">
                                                        <SaveButton
                                                            loading={state.loadingButton}
                                                            disabled={hasDragPermission() === false}
                                                            className="save"
                                                            onClick={handleSaveChanges}
                                                        >
                                                            Запази данните
                                                        </SaveButton>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col sm={9}>
                                            <Row>
                                                <Col sm={8}>
                                                    <CategoryArticles
                                                        ref={categoryList}
                                                        category={state.selected}
                                                    />
                                                </Col>
                                                <Col sm={4}>
                                                    <ArticleList
                                                        category={state.selected}
                                                        onAdd={article => {
                                                            categoryList.current.add(article);
                                                        }}
                                                        deleteMode={() => {
                                                            return categoryList.current?.isDeleteMode() || false
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>

                                    </Row>
                                </>
                        }
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Index;
