import { createContext, useContext, useMemo, useState } from 'react';
import Api from 'helpers/Api';

export const LanguageContext = createContext({});

const LanguageProvider = props => {

    const [languages, setLanguages] = useState([]);

    const loadData = async () => {
        return await Api.get('languages')
            .then(res => {
                setLanguages(res.data);
            });
    }

    const data = useMemo(() => (languages), [languages]);

    const value = {
        data,
        loadData
    }

    return <LanguageContext.Provider value={value} {...props} />;
}

export const useLanguageContext = () => useContext(LanguageContext);

export default LanguageProvider;