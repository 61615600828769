import { useEffect, useRef, useState } from 'react';
import Api from 'helpers/Api';
import { buildSearchParams, buildUrl, useQuery } from 'helpers/Url';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Alert, Button, Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';

import Options from './partials/Options';

import Question from 'components/modals/Question';
import Filter from './partials/Filter';
import Layout from 'components/layout/Layout';

//misc
import MenuOptions from 'components/misc/MenuOptions'
import Pagination from 'components/misc/Pagination'
import Loader from 'components/misc/Loader';
import NoDataFound from 'components/misc/NoDataFound';
import { useAuthDataContext } from 'providers/Auth';
import View from './view/View';
import New from './partials/New';

import TableHeader from 'components/misc/TableHeader';
import TableCol from 'components/misc/TableCol';
import Refs from 'Refs';
import PriceValue from 'components/partials/PriceValue';

let timeout;

function Index() {

    const location = useLocation();
    const navigate = useNavigate();
    const query = useQuery();
    const auth = useAuthDataContext();

    const tableRef = useRef(null);
    const deleteModalRef = useRef(null);
    const closeModalRef = useRef(null);
    const viewModalRef = useRef(null);
    const newModalRef = useRef(null);

    const [state, setState] = useState({
        sorting: false,
        loading: true,
        refresh: false,
        setFilter: false,
        data: [],
        pages: 0,
        total: 0,
        totals: {},
        filter: {
            page: 1,
        },
    });

    useEffect(() => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: query.get('page') || 1,
                search: query.get('search') || '',
                status_id: query.get('status_id') || '',
                speditor_id: query.get('speditor_id') || '',
                client_id: query.get('client_id') || '',
                article_id: query.get('article_id') || '',
                created_from_date: query.get('created_from_date') || '',
                created_to_date: query.get('created_to_date') || '',
                user_id: query.get('user_id') || '',
                deleted: query.get('deleted') || '',
                sort: query.get('sort') || '',
                order: query.get('order') || '',
            },
            refresh: new Date().getTime()
        }))
    }, [location.search]);

    useEffect(() => {
        if (state.setFilter) {
            navigate('?' + buildSearchParams(query, state.filter));
        }

    }, [state.setFilter]);

    useEffect(() => {
        if (state.refresh) {
            loadData();
        }
    }, [state.refresh]);

    const loadData = () => {

        loading(true);

        let url = 'returns/all';

        Api.get(url, {
            params: state.filter
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data.items,
                total: res.data.total,
                pages: res.data.pages,
                totals: res.data.totals,
                filter: {
                    ...prev.filter,
                    ...res.data.filter,
                }
            }));

            if (res.data.pages > 0 && state.filter.page > res.data.pages) {
                return handlePage({ selected: res.data.pages - 1 });
            }

            loading(false);
            sorting(false);
        });
    }

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }));
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: reset ? 1 : prev.filter.page
            },
            setFilter: new Date().getTime(),
            refresh: new Date().getTime(),
        }));
    }

    const handleAdd = () => {
        let modal = newModalRef.current;

        modal.open();
        modal.onSuccess(data => {
            navigate('/returns/' + data.id + '/edit');
        });
    }


    // update
    const handleEdit = data => {
        if (data.deleted) {
            return;
        }

        navigate('/returns/' + data.id + '/edit');
    }

    // delete
    const handleDelete = data => {
        let modal = deleteModalRef.current;

        modal.open();
        modal.onSuccess(() => {
            handleDestroy(data.id);
        })
    }

    const handleDestroy = id => {
        Api.post('returns/delete', {
            id: id
        }).then(res => {
            if (res.data.success) {
                refresh(false);
            }
        });
    }

    const handleRestore = data => {
        Api.post('returns/restore', {
            id: data.id
        }).then(res => {
            if (res.data.success) {
                refresh(false);
            }
        });
    }

    const handleView = data => {
        let modal = viewModalRef.current;

        modal.open(data.id);
    }

    // close
    const handleClose = data => {
        let modal = closeModalRef.current;

        modal.open();
        modal.onSuccess(() => {
            close(data.id);
        })
    }

    const close = id => {
        Api.post('returns/close', {
            id: id
        }).then(res => {
            if (res.data.success) {
                refresh(false);
            }
        });
    }

    // Search
    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout);

        if (typeof key === 'object') {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    ...key
                },
            }));
        } else {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    [key]: val,
                },
            }));
        }

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: 1
                },
                setFilter: new Date().getTime()
            }));
        }, delay);
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            setFilter: new Date().getTime()
        }));
    }

    const sorting = (sorting) => {
        setState(prev => ({
            ...prev,
            sorting: Boolean(sorting)
        }));
    }

    const handleSort = (sort, order) => {
        setState(prev => ({
            ...prev,
            sorting: true,
            filter: {
                ...prev.filter,
                sort: sort,
                order: order,
                page: 1,
            },
            setFilter: new Date().getTime()
        }));
    }

    const getAddressName = address => {
        if (!address) {
            return '';
        }

        let name = [];

        if (address.delivery_type === 'address') {
            name = [
                address.dest_name_full,
                address.address,
                address.description,
                address.country_name,
            ];
        }

        if (address.delivery_type === 'office') {
            name = [
                address.dest_name_full,
                address.office_name,
            ];
        }

        if (address.delivery_type === 'store') {
            name = [
                address?.store?.translation?.name || address?.store?.name,
            ];
        }

        return name.filter(p => p).join(', ');
    }

    const getExtendedAddressName = address => {
        if (!address) {
            return '';
        }

        let map = {
            address: 'Адрес на клиент',
            office: 'Офис на куриер',
            store: 'Търговски обект'
        };

        let type = map[address.delivery_type];

        return type + ': ' + getAddressName(address);
    }

    const showClient = id => {
        let modal = Refs.getInstance().getRef('client');

        if (!modal) {
            return;
        }

        modal.open(id);
    }

    const showOrder = id => {
        let modal = Refs.getInstance().getRef('order');

        if (!modal) {
            return;
        }

        modal.open(id);
    }

    const trackOrder = number => {
        window.open(
            process.env.REACT_APP_URL + '/check-order?id=' + number,
            '_blank',
            'resizable=1,width=500,height=700'
        )
    }

    const showAccount = id => {
        let modal = Refs.getInstance().getRef('account');

        if (!modal) {
            return;
        }

        modal.open(id);
    }

    return (
        <>

            <Question
                ref={deleteModalRef}
                mainMessage="Сигурни ли сте?"
                agreeBtnText="Изтриване"
                agreeBtnClass="remove"
            />

            <Question
                ref={closeModalRef}
                mainMessage="Сигурни ли сте, че искате да приключите това връщане?"
                agreeBtnText="Да"
            />

            <View
                ref={viewModalRef}
            />

            <New
                ref={newModalRef}
            />

            <Layout>
                <div className="container-fluid">

                    <div className="page-head">
                        <h1 className="display-6">
                            Заявки за връщане
                        </h1>

                        <div className="buttons">
                            <Button variant="outline-dark" onClick={handleAdd}>
                                Ново връщане
                            </Button>
                        </div>
                    </div>

                    <br />

                    <div className="panel">
                        <Filter
                            filter={state.filter}
                            handleSearch={handleSearch}
                        />

                        {state.loading && !state.sorting
                            ?
                            <Loader />
                            :
                            state.data.length === 0 ?
                                <NoDataFound />
                                :
                                <>
                                    <Table className="big" responsive striped hover ref={tableRef}>
                                        <TableHeader
                                            tableRef={tableRef}
                                            activeSortKey={state.filter.sort}
                                            activeSortDir={state.filter.order}
                                            onSort={(col, dir) => handleSort(col, dir)}
                                        >
                                            <TableCol sortable sortKey="id">ID</TableCol>
                                            <TableCol className="text-center" sortable sortKey="status">Статус</TableCol>
                                            <TableCol sortable sortKey="from_address" style={{ width: '15%' }}>Адрес</TableCol>
                                            <TableCol sortable sortKey="client">Клиент</TableCol>
                                            <TableCol sortable sortKey="client_tel">Телефон</TableCol>
                                            <TableCol sortable sortKey="created_at">Дата</TableCol>
                                            <TableCol sortable sortKey="order_id">Поръчка</TableCol>
                                            <TableCol sortable sortKey="account">Сметки</TableCol>
                                            <TableCol sortable sortKey="speditor">Спедитор</TableCol>
                                            <TableCol className="text-right" sortKey="">Стоки бр</TableCol>
                                            <TableCol sortKey="tracking_numbers">Товарителница(и)</TableCol>
                                            <TableCol className="text-right" sortable sortKey="total_real">Сума</TableCol>
                                            <TableCol className="options">Опции</TableCol>
                                        </TableHeader>
                                        <tbody>
                                            {state.data.map((c, index) =>
                                                <tr
                                                    key={'c-' + c.id + 'index-' + index}
                                                    className={c.deleted ? 'deleted' : ''}
                                                >
                                                    <td>
                                                        {c.id}
                                                    </td>
                                                    <td className={`text-center ${c.status?.code === 'open' ? 'blue-value' : ''}`}>
                                                        {c?.status?.translation?.name || c?.status?.name}
                                                    </td>
                                                    <td>
                                                        {getExtendedAddressName(c.fromaddress)}
                                                    </td>
                                                    <td>
                                                        <span className="link" onClick={e => showClient(c.client_id)}>
                                                            {c.client_name || c?.client?.name}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        {c.client_tel}
                                                    </td>
                                                    <td>
                                                        {c.created_at ? moment(c.created_at).format('DD.MM.YYYY HH:mm') : ''}
                                                    </td>
                                                    <td>
                                                        <span className="link" onClick={e => showOrder(c.order_id)}>
                                                            {c?.order_id}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        {c.accounts.map(account =>
                                                            <span key={account.id} className="link" onClick={e => showAccount(account.id)}>{account.id}</span>
                                                        )}
                                                    </td>
                                                    <td>
                                                        {c?.speditor?.name}
                                                    </td>
                                                    <td className="text-right">
                                                        {c.rows_count}
                                                    </td>
                                                    <td>
                                                        {(c?.tracking_numbers || []).map(number =>
                                                            <span className="link" key={number} onClick={e => trackOrder(number)} style={{ display: 'block' }}>{number}</span>
                                                        )}
                                                    </td>
                                                    <td className="text-right">
                                                        <PriceValue prefix={c.currency?.prefix} sufix={c.currency?.sufix}>{c.total_real}</PriceValue>
                                                    </td>
                                                    <td className="options">
                                                        <MenuOptions>
                                                            <Options
                                                                data={c}
                                                                handleEdit={handleEdit}
                                                                handleDelete={handleDelete}
                                                                handleRestore={handleRestore}
                                                                handleView={handleView}
                                                            />
                                                        </MenuOptions>
                                                    </td>
                                                </tr>
                                            )}
                                            <tr className="total">
                                                <td colSpan={11}>
                                                    Общо
                                                </td>
                                                <td className="text-right">
                                                    <b>
                                                        <PriceValue prefix={state.totals?.currency?.prefix} sufix={state.totals?.currency?.sufix}>{state.totals?.total_real}</PriceValue>
                                                    </b>
                                                </td>

                                            </tr>
                                        </tbody>
                                    </Table>

                                    <Pagination
                                        className="mt-3"
                                        page={state.filter.page}
                                        pages={state.pages}
                                        total={state.total}
                                        handlePage={handlePage}
                                    />
                                </>
                        }

                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Index;
