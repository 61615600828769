import { createContext, useMemo } from 'react';
import { useAuthDataContext } from './Auth';

export const TranslationContext = createContext({});

let translations = {};
let lang = 'bg';

const TranslationProvider = props => {

    const auth = useAuthDataContext();

    useMemo(() => {
        // if (auth.lang()) {
        //     lang = auth.lang();
        // }

        // loadTranslationFile(lang);
    }, [auth]);

    return <TranslationContext.Provider value={lang} {...props} />;
}

const loadTranslationFile = (lang, file) => {

    if (!translations[lang]) {
        translations[lang] = {};
    }

    if (!translations[lang][file]) {
        translations[lang][file] = require('../langs/' + lang + '/' + file + '.json');
    }
}

const getTranslation = (lang, string, obj, level = 1) => {

    // console.log(string);
    // console.log(obj);
    // console.log(level);

    let parts = string.split('.');

    if (!obj) {
        let file = parts[0];

        loadTranslationFile(lang, file);

        return getTranslation(lang, string, translations[lang]);
    }

    let newObj = obj[parts[0]];

    if (!newObj) {
        return null;
    }

    if (parts[1]) {
        parts.splice(0, 1);

        let newString = parts.join('.');

        return getTranslation(lang, newString, newObj, level + 1);
    }

    return newObj;
}

export const trans = (key, params = {}) => {

    let trans = getTranslation(lang, key);

    if (!trans) {
        return key;
    }

    let placeholders = Object.entries(params);

    if (placeholders.length) {
        placeholders.forEach(data => {
            trans = trans.replaceAll('{' + data[0] + '}', data[1]);
        });
    }


    return trans;
}

export const plural = (key, qty = 0, params = {}) => {
    let string = trans(key, params);
    let strings = string.split('|');
    let array = {};

    for (let i = 0; i < strings.length; i++) {
        let str = strings[i];

        array[i] = {
            min: Infinity,
            max: Infinity,
            str: str
        };

        str = str.replace(/\[.*?\]/g, (match) => {
            let range = match.replace('[', '').replace(']', '').split(',');
            let min = range[0] ? Number(range[0]) : 0;
            let max = range[1] ? Number(range[1]) : min;

            array[i] = {
                min: min,
                max: max
            };

            return '';
        });

        str = str.trim();

        array[i]['str'] = str

    }

    array = Object.values(array);

    let match;

    for (let i = 0; i < array.length; i++) {
        let el = array[i];

        if (qty >= el['min'] && qty <= el['max']) {
            match = el['str'];
            break;
        }
    }

    if (match) {
        return match;
    }

    return array[array.length - 1]['str'];
}

export const validate = (attribute, rule) => {
    let attributeName = trans('validation.attributes.' + attribute);

    if (!attributeName) {
        attributeName = attribute;
    }

    let text = trans(rule, {
        attribute: attributeName
    });

    return text;
}

// export const useTranslationContext = () => useContext(TranslationContext);

export default TranslationProvider;