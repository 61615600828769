import Autocomplete from "components/misc/Autocomplete";
import { forwardRef, useImperativeHandle } from "react";
import { Form } from "react-bootstrap";

function ArticleDetail(props, ref) {

    useImperativeHandle(ref, () => ({
        getName: getName,
        getType: getType,
        getTitle: getTitle,
        hasSettings: hasSettings,
        getSettings: getSettings,
        getDefaultOptions: ({ createOrUpdate }) => ({})
    }));

    const getName = () => {
        return 'Характеристика';
    }

    const getType = () => {
        return 'article_detail';
    }

    const getTitle = () => {
        return null;
    }

    const hasSettings = () => true;

    const getSettings = ({ state, setConfig }) => {
        return (
            <div>
                <label>
                    Име на характеристиката:
                </label>
                <Autocomplete
                    variant="basic"
                    size="sm"
                    url="autocomplete/article-details"
                    inputIdValue={state.options?.detail_id || ''}
                    inputValue={state.options?.detail_name || ''}
                    onChange={data => setConfig({
                        detail_id: data.id,
                        detail_name: data.name,
                    })}
                    onInputChange={data => setConfig({
                        detail_name: data,
                        detail_id: null
                    })}
                    renderInputText={data => data?.translation?.name || data.name}
                    renderText={data => data?.translation?.name || data.name}
                />
            </div>
        );
    }

    return getName();
}

export default forwardRef(ArticleDetail)