import { Button } from 'react-bootstrap';
import { InfoCircleFill } from 'react-bootstrap-icons';

function TableRow(props) {

    const { row, index, visible } = props;
    const { state } = props;
    const { handleShowArticle } = props;

    const getCategoryTitle = (category) => {
        return getCategoryArray(category).reverse().join(' > ');
    }

    const getCategoryArray = category => {
        let name = [category.name];

        if (category.parent) {
            name = name.concat(
                getCategoryArray(category.parent)
            );
        }

        return name;
    }

    const getStoragePrice = () => {
        switch (state.data.price_mode) {
            case 'sell':
                return parseFloat(row.total_storage_actual_price || 0).toFixed(2);
            case 'delivery':
                return parseFloat(row.total_storage_delivery_price || 0).toFixed(2);
        }
    }

    const getActualPrice = () => {
        switch (state.data.price_mode) {
            case 'sell':
                return parseFloat(row.total_actual_price || 0).toFixed(2);
            case 'delivery':
                return parseFloat(row.total_delivery_price || 0).toFixed(2);
        }
    }

    const getPriceDiff = () => {
        switch (state.data.price_mode) {
            case 'sell':
                return parseFloat(row.total_actual_price_diff || 0).toFixed(2);
            case 'delivery':
                return parseFloat(row.total_delivery_price_diff || 0).toFixed(2);
        }
    }

    return (
        <tr key={'r-' + index} style={{ display: visible ? 'table-row' : 'none' }}>
            <td title={row.id || '-'}>
                {row.article_id}
            </td>
            <td title={row?.article?.name}>
                {row?.article?.article_name}
            </td>
            {state.data.view_type !== 'category' &&
                <td>
                    {row.category ? getCategoryTitle(row.category) : '-'}
                </td>
            }
            <td className="text-right">
                {row.amount_unit !== null ? row.amount_unit : '-'} {row?.article?.amount_type_short}
            </td>
            <td className="text-right">
                {row.storage_quantity !== null ? row.storage_quantity : '-'} {row?.article?.amount_type_short}
            </td>
            <td className="text-right">
                {row.actual_quantity !== null ? row.actual_quantity : '-'} {row?.article?.amount_type_short}
            </td>
            <td className={`text-right ${row.quantity_diff < 0 && 'negative-value'}`}>
                {row.quantity_diff !== null ? row.quantity_diff : '-'} {row?.article?.amount_type_short}
            </td>
            <td className={`text-right`}>
                {row.amount_unit_diff !== null ? row.amount_unit_diff : '-'} ед
            </td>
            <td className="text-right">
                {state.rows?.currency?.prefix} {getStoragePrice()} {state.rows?.currency?.sufix}
            </td>
            <td className="text-right">
                {state.rows?.currency?.prefix} {getActualPrice()} {state.rows?.currency?.sufix}
            </td>
            <td className={`text-right ${getPriceDiff() < 0 && 'negative-value'}`}>
                {state.rows?.currency?.prefix} {getPriceDiff()} {state.rows?.currency?.sufix}
            </td>
            <td className="text-center">
                {row?.reason?.translation?.name || row?.reason?.name || ''}
            </td>
            <td className="text-center">
                {row.article &&
                    <Button variant="secondary" size="sm" onClick={e => handleShowArticle(row.article?.id)}>
                        <InfoCircleFill />
                    </Button>
                }

            </td>

        </tr>
    )
}

export default TableRow;