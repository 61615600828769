import { useAppContext } from "providers/App";
import { LockFill, UnlockFill } from "react-bootstrap-icons";

function LockedFilter(props) {
    const app = useAppContext();

    const filter = app.getLockedFilter(props.lock);

    const getIcon = () => {
        switch (filter.storage) {
            case null:
                return <UnlockFill />
            case 'session':
                return <LockFill />
            case 'local':
                return <LockFill />
        }
    }

    const getClass = () => {
        return {
            'session': 'session',
            'local': 'local',
        }[filter.storage] || '';
    }

    const getTitle = () => {
        return {
            null: 'Запомняне на филтъра глобално в рамките на сесията или за постоянно',
            'session': 'Филтърът е запомнен в рамките на сесията',
            'local': 'Филтърът е запомнен за постоянно',
        }[filter.storage];
    }

    return (
        <div
            className={`locked-filter ${getClass()}`}
            title={getTitle()}
            onClick={e => app.lockFilter(props.lock)}
        >
            {getIcon()}
        </div>
    )
}

export default LockedFilter;