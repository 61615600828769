import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

export default function IFrame({
    children,
    ...props
}) {
    const [contentRef, setContentRef] = useState(null)

    const mountNode = contentRef?.contentWindow?.document?.body;

    useEffect(() => {
        if (!contentRef) {
            return;
        }

        const onLoad = e => {
            console.log('loaded');

            if (typeof props.onPageLoad === 'function') {
                props.onPageLoad(e);
            }
        }

        contentRef.addEventListener('load', onLoad, { once: true });

        return () => {
            contentRef.removeEventListener('load', onLoad);
        }
    }, [contentRef])

    return (
        <iframe {...props} ref={setContentRef} style={{ ...props.style, width: '100%' }}>
            {mountNode && createPortal(children, mountNode)}
        </iframe>
    )
}