import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import { Modal, Button } from 'react-bootstrap';

//helpers
import { useNestedState } from 'helpers/NestedState'

import Form from './Form';
import SaveButton from 'components/misc/Button'

import { useClientContext } from '../Provider';
import Vies from './Vies';

function AddOrEdit(props, ref) {

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        edit: false,
        refresh: false,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null,
        onClose: null,
    });

    const context = useClientContext();

    useImperativeHandle(ref, () => ({
        add: () => {
            handleShow();
        },
        edit: (id) => {
            edit(id);
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        }
    }));

    const formRef = useRef(null);
    const viesModalRef = useRef(null);

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const handleShow = () => {
        show();
    }

    const close = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            id: null,
        }));

        context.resetData();

        if (typeof state.onClose === 'function') {
            hide(state.onClose);
        } else {
            hide();
        }
    }

    const edit = id => {
        setState(prev => ({
            ...prev,
            id: id,
            edit: new Date().getTime()
        }));

        show();
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            refresh: new Date().getTime(),
        }));
    }

    const onSuccess = (data) => {
        if (typeof state.onSuccess === 'function') {
            state.onSuccess(data)
        }

        hide();
    }

    return (
        <>
            <Vies
                ref={viesModalRef}
            />

            <Modal size="lg" centered show={state.show} onHide={close} onEntering={state.onEntering} onExiting={state.onExiting}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Клиент
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        ref={formRef}
                        id={state.id}
                        show={show}
                        hide={hide}
                        onSuccess={onSuccess}
                        viesModalRef={viesModalRef}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={close}>
                        Отказ
                    </Button>
                    <SaveButton
                        loading={context.state?.loading}
                        className="save"
                        onClick={e => formRef.current?.save(e)}
                    />
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default forwardRef(AddOrEdit);
