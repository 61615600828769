import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import moment from 'moment'
import { Modal, Button, Col, FloatingLabel, Form } from 'react-bootstrap';

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//providers
import { useLanguageContext } from 'providers/Language'

//misc
import SaveButton from 'components/misc/Button'
import Info from 'components/modals/Info';
import Autocomplete from 'components/misc/Autocomplete';

function AddOrEdit(props, ref) {

    const langs = useLanguageContext();

    const defaultData = {
        langs: {}
    }

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        edit: false,
        data: defaultData,
        initialData: defaultData,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null,
        onClose: null
    });

    const [validations, setValidations] = useValidation();
    const validationModalRef = useRef(null);

    useImperativeHandle(ref, () => ({
        add: () => {
            handleShow();
        },
        edit: (id) => {
            edit(id);
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        },
    }));

    useEffect(() => {
        loadData();
    }, [state.edit]);

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }


    const handleShow = () => {
        setState(prev => ({
            ...prev,
            data: defaultData,
            initialData: defaultData,
            id: null,
        }));

        show();
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            initialData: defaultData,
            id: null,
        }));

        if (typeof state.onClose === 'function') {
            state.onClose();
        }

        setValidations(null);

        hide();
    }

    const edit = id => {
        setState(prev => ({
            ...prev,
            id: id,
            edit: new Date().getTime()
        }));

        show();
    }

    const loadData = () => {
        if (!state.id) {
            return;
        }

        Api.get('clients/groups/show?id=' + state.id)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data,
                    initialData: res.data
                }))
            });
    }


    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = state.id ? 'clients/groups/update' : 'clients/groups/add';

        let data = new FormData(e.target);

        if (state.id) {
            data.append('id', state.id)
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (typeof state.onSuccess === 'function') {
                state.onSuccess(res.data)
            }

            hide()
        }).catch(error => {
            const _err = error.response;
            if (_err && _err.status && _err.status === 422) {
                hide(() => {
                    let modal = validationModalRef.current;

                    if (modal) {
                        modal.open();

                        modal.onClose(() => {
                            setValidations(_err.data.errors)
                            show();
                        });
                    }
                });
            }
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }));
        });
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleDateChange = e => {
        const name = e.target.name + '_ymd'
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleCheckboxChange = e => {
        const name = e.target.name;
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: prev.data[name] ? false : true
            }
        }));
    }

    const handleMethodChange = (data) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                method_id: data?.id || '',
                method: data
            }
        }));
    }

    return (
        <>
            <Info
                ref={validationModalRef}
                mainMessage="Моля, попълнете всички задължителни полета, означени с червен цвят!"
            />

            <Modal centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <form onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Група клиенти
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>


                        <FloatingLabel label="Име" className="mb-3">
                            <Form.Control
                                type="text"
                                name={`name`}
                                value={state.data.name}
                                placeholder="Име"
                                onChange={(e) => handleInputChange(e)}
                                isInvalid={Boolean(validations?.name)}
                            />
                            <Form.Control.Feedback type="invalid">
                                {validations?.name}
                            </Form.Control.Feedback>
                        </FloatingLabel>

                        <FloatingLabel label="Описание" className="mb-3">
                            <Form.Control
                                as={'textarea'}
                                style={{ height: '100px' }}
                                name={`description`}
                                placeholder="Описание"
                                onChange={(e) => handleInputChange(e)}
                                value={state.data.description || ''}
                                isInvalid={Boolean(validations?.description)}
                            />
                            <Form.Control.Feedback type="invalid">
                                {validations?.description}
                            </Form.Control.Feedback>
                        </FloatingLabel>

                        <FloatingLabel label="Кредитен лимит" className="mb-3">
                            <Form.Control
                                type="number"
                                name={`credit_limit`}
                                value={state.data.credit_limit}
                                placeholder="Кредитен лимит"
                                onChange={(e) => handleInputChange(e)}
                                isInvalid={Boolean(validations?.credit_limit)}
                            />
                            <Form.Control.Feedback type="invalid">
                                {validations?.credit_limit}
                            </Form.Control.Feedback>
                        </FloatingLabel>

                        <Col className="mb-3">
                            <Autocomplete
                                url="autocomplete/payment-methods"
                                inputPlaceholder="Методи на плащане"
                                inputIdName={`method_id`}
                                renderText={data => data?.translation?.name || data.name}
                                renderInputText={data => data?.translation?.name || data.name}
                                inputValue={state.data.method?.name || ''}
                                inputIdValue={state.data.method_id || ''}
                                error={Boolean(validations?.method_id)}
                                helperText={validations?.method_id}
                                onChange={data => handleMethodChange(data)}
                            />
                        </Col>

                        {Number(state.initialData?.default) !== 1 ? <Form.Check
                            type="switch"
                            name="default"
                            label="По подразбиране"
                            className="mb-3 mt-3"
                            checked={Number(state.data?.default) === 1}
                            onChange={handleCheckboxChange}
                        /> : ''}

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            className="save"
                        />
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default forwardRef(AddOrEdit);
