import Loader from "components/misc/Loader";
import SaveButton from "components/misc/Button";
import Success from "components/modals/Success";
import { buildUrl, useQuery } from "helpers/Url";
import { useAuthDataContext } from "providers/Auth";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Button, Card, Col, Row, Form } from "react-bootstrap";
import { CaretDownFill, CaretUpFill, Trash } from "react-bootstrap-icons";
import { useLocation, useNavigate } from "react-router-dom";
import { sortable } from 'react-sortable';
import ArticleProvider from 'components/pages/articles/Provider';
import Article from 'components/pages/articles/partials/AddOrEdit';
import Refs from "Refs";
import Api from "helpers/Api";
import Alert from "components/misc/Alert";

function SortableItem(props) {
    return (
        <div {...props}>
            {props.children}
        </div>
    )
}

const SortItem = sortable(SortableItem);

function CategoryArticles(props, ref) {

    const auth = useAuthDataContext();
    const location = useLocation();
    const history = useNavigate();
    const query = useQuery();

    const addOrEditModalRef = useRef(null);
    const articleModalRef = useRef(null);
    const successModalRef = useRef(null);
    const deleteModalRef = useRef(null);

    const category = props.category;

    const [state, setState] = useState({
        loading: true,
        loadingMoreButton: false,
        loadingDeleteButton: false,
        loadingSaveButton: false,
        refresh: false,
        setFilter: false,
        data: [],
        pages: 0,
        total: 0,
        filter: {
            page: 1,
        },
        deleteMode: false,
        sortValue: 0,
    });

    useImperativeHandle(ref, () => ({
        add: (article) => {
            handleAddArticle(article);
        },
        isDeleteMode: () => {
            return state.deleteMode
        }
    }));

    useEffect(() => {
        setState(prev => ({
            ...prev,
            loading: true
        }));

        loadData();
    }, [props.category])

    const loadData = (page = 1, reset = true) => {
        if (!category?.id) return;

        setState(prev => ({
            ...prev,
            deleteMode: false,
            checked: [],
            filter: {
                ...prev.filter,
                page: page
            }
        }))

        Api.get(`categories/articles`, {
            params: {
                category_id: category.id,
                page: page
            }
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: reset ? res.data.items : prev.data.concat(res.data.items),
                pages: res.data.pages,
                total: res.data.total,
                has_more: res.data.has_more,
                loading: false,
                loadingMoreButton: false
            }));
        })
    }

    const loadMore = () => {
        setState(prev => ({
            ...prev,
            loadingMoreButton: true,
        }));

        loadData(state.filter.page + 1, false);
    }

    const handleCreateArticle = () => {
        let modal = articleModalRef.current;

        modal.add({ resource: false });

        modal.onSuccess((article) => {
            handleAddArticleCategoryList(article)

            // todo: da se izvika zaqvkata za spisuka otdqsno
            // props.onCreate();
        });
    }

    const handleEditArticle = data => {
        if (data.deleted) {
            return;
        }

        // let modal = articleModalRef.current;
        let modal = Refs.getInstance().getRef('article');

        modal.open(data.id, { resource: false });

        modal.onSuccess((article) => {
            handleEditArticleCategoryList(article)


            // handleSearchArticles()
        });

        modal.onDelete(() => {
            handleRemoveArticleCategoryList(data);


            // handleSearchArticles()
        });
    }

    const handleAddArticleCategoryList = (article) => {
        setState(prev => ({
            ...prev,
            data: [
                ...prev.data,
                article
            ],
        }))
    }

    const handleEditArticleCategoryList = (article) => {
        setState(prev => ({
            ...prev,
            data: prev.data.map(obj => {
                if (obj.id === article.id) {
                    obj = article;
                }

                return obj;
            })
        }))
    }

    const handleRemoveArticleCategoryList = (article) => {
        setState(prev => ({
            ...prev,
            data: prev.data.filter(a => a.id !== article.id),
        }))
    }

    const handleAddArticle = article => {
        let article_contains = state.data.filter(t => t.id === article.id).length > 0;

        if (article_contains) {
            let modal = Refs.getInstance().getRef('error');

            modal.open("Този артикул вече е добавен!");

            return;
        }

        handleAddArticleCategoryList(article);
    }

    const handleSaveArticles = (e) => {
        e.preventDefault();

        if (!category) {
            return;
        }

        loadingSaveButton(true);

        let data = new FormData(e.target);

        data.append('category_id', category.id);

        Api.post('categories/articles/save', data)
            .then(res => {
                let modal = successModalRef.current;

                modal.open();

                modal.onSuccess(() => {
                    loadingSaveButton(false);
                });
            }).catch(error => {
                loadingSaveButton(false);
            });

    }

    const loadingSaveButton = (loading) => {
        setState(prev => ({
            ...prev,
            loadingSaveButton: Boolean(loading)
        }));
    }

    const handleSortArticles = (items) => {
        setState(prev => ({
            ...prev,
            data: items
        }))
    }

    const handleChecked = (id) => {
        let checked = [...state.checked]

        if (checked.includes(id)) {
            checked = checked.filter(t => t !== id)
        } else {
            checked.push(id)
        }

        setState(prev => ({
            ...prev,
            checked: checked
        }))
    }

    const handleDeleteMode = () => {
        let statement = !state.deleteMode
        let checked = []

        if (statement) {
            checked = state.checked
        }

        setState(prev => ({
            ...prev,
            deleteMode: statement,
            checked: checked
        }))
    }

    const handleDeleteArticles = (e) => {
        e.preventDefault();

        if (!category) {
            return;
        }

        let checked = [...state.checked]

        if (!checked.length) {
            let errorModal = Refs.getInstance().getRef('error');

            errorModal.open("Моля изберете артикул, който искате да изтриете!");

            return;
        }

        loadingDeleteButton(true);

        Api.post('categories/articles/delete', {
            article_id: checked,
            category_id: category.id
        }).then(res => {
            let modal = successModalRef.current;

            modal.open();

            modal.onSuccess(() => {

                setState(prev => ({
                    ...prev,
                    data: prev.data.filter(obj => {
                        return checked.indexOf(obj.id) === -1;
                    }),
                    deleteMode: false,
                    checked: []
                }));

                loadingDeleteButton(false);
            })
        }).catch(error => {
            loadingDeleteButton(false)
        })

    }

    const loadingDeleteButton = (loading) => {
        setState(prev => ({
            ...prev,
            loadingDeleteButton: Boolean(loading)
        }));
    }

    const sortItems = (sortVal = 0) => {
        // Sort by string
        // 0 - АSC
        // 1 - DESC

        let items = [...state.data];

        if (sortVal === 1) {
            items = items.sort(function (a, b) {
                return a.article_name.localeCompare(b.article_name, undefined, {
                    numeric: true,
                    sensitivity: 'base'
                });
            });
        } else {
            items = items.sort(function (a, b) {
                return b.article_name.localeCompare(a.article_name, undefined, {
                    numeric: true,
                    sensitivity: 'base'
                });
            });
        }

        setState(prev => ({
            ...prev,
            data: items,
            sortValue: sortVal
        }));
    }

    return (
        <>
            <ArticleProvider>
                <Article
                    ref={articleModalRef}
                    category={category}
                    fromCategory={true}
                />
            </ArticleProvider>

            <Success
                ref={successModalRef}
                mainMessage="Данните бяха запазени успешно"
            />

            <Card className="middle-card">
                <Card.Header>
                    {category?.id ?
                        <>
                            <span>
                                {`Категория ${category.translation?.name || ''}`}
                            </span>
                            {!state.deleteMode ?
                                <div style={{ display: 'flex', gap: '5px' }}>
                                    <Button onClick={() => sortItems(state.sortValue === 1 ? 0 : 1)} size="sm" variant="outline-dark" className="sort-button">
                                        {state.sortValue === 1 ? <CaretUpFill /> : <CaretDownFill />} Подреди (А-Я)
                                    </Button>
                                    <Button size="sm" variant="outline-dark" onClick={handleCreateArticle} disabled={auth.getUser().permission('articles_edit') === false}>
                                        Добави
                                    </Button>

                                </div>
                                : ''}
                        </>
                        : 'Не е избрана категория'}

                </Card.Header>
                <Card.Body className={state.deleteMode ? "category-card-list delete" : "category-card-list"}>
                    {!category
                        ?
                        <Alert>Изберете категория, за да видите списък с артикули</Alert>
                        :
                        state.loading
                            ?
                            <Loader />
                            :
                            state.data.length ?
                                <Form onSubmit={(e) => handleSaveArticles(e)}>
                                    <ul className='category-article-list'>
                                        {state.data.map((t, index) =>
                                            <SortItem
                                                key={index}
                                                items={state.data}
                                                onSortItems={handleSortArticles}
                                                sortId={index}
                                                className="file-container"
                                            >
                                                <li
                                                    className={state.checked.indexOf(t.id) > -1 ? "delete-checked" : ""}
                                                    onClick={state.deleteMode ? () => handleChecked(t.id) : () => handleEditArticle(t)}
                                                >
                                                    <input type="hidden" name="article_id[]" value={t.id} />
                                                    <input type="hidden" name="weight[]" value={index} />
                                                    <div className="picture">
                                                        <img src={buildUrl(t.preview?.thumbnail_url, { width: 80 })} />
                                                    </div>
                                                    <p>{t.article_name || ''}</p>
                                                </li>
                                            </SortItem>
                                        )}
                                    </ul>

                                    {state.has_more &&
                                        <SaveButton
                                            variant="primary"
                                            size="sm"
                                            className="mt-3"
                                            style={{
                                                display: 'block',
                                                margin: 'auto',
                                            }}
                                            loading={state.loadingMoreButton}
                                            onClick={loadMore}
                                        >
                                            Зареди още
                                        </SaveButton>
                                    }

                                    {!state.deleteMode
                                        ?
                                        <div className='middle-col-buttons mt-3'>
                                            <div>
                                                <Button onClick={() => handleDeleteMode()} variant="danger" disabled={auth.getUser().permission('category_articles') === false}>
                                                    <Trash /> Режим изтриване
                                                </Button>
                                            </div>

                                            <SaveButton
                                                loading={state.loadingSaveButton}
                                                disabled={auth.getUser().permission('category_articles') === false}
                                                className="save"
                                            >
                                                Запази данните
                                            </SaveButton>
                                        </div>
                                        :
                                        <div className='middle-col-buttons mt-3'>
                                            <div>
                                                <Button onClick={() => handleDeleteMode()} variant="outline-secondary" style={{ marginRight: '5px' }}>
                                                    Отказ
                                                </Button>
                                                <SaveButton onClick={(e) => handleDeleteArticles(e)} loading={state.loadingDeleteButton} variant="danger" >
                                                    <Trash /> Изтриване ({state.checked.length})
                                                </SaveButton>
                                            </div>
                                        </div>
                                    }
                                </Form>
                                :
                                <Alert variant="danger">
                                    Няма намерени артикули!
                                </Alert>
                    }

                </Card.Body>

            </Card>

        </>
    )
}

export default forwardRef(CategoryArticles);