import React, { useState, useImperativeHandle, forwardRef, useEffect, useRef } from 'react'
import { Modal, Button, Tabs, Tab } from 'react-bootstrap';
import SaveButton from 'components/misc/Button';
import Loader from 'components/misc/Loader';
import Home from './Home';
import Related from './Related';
import Notes from './Notes';

import SendMail from 'components/pages/protocols/partials/SendMail';
import Note from 'components/partials/notes/AddOrEdit';

import Question from 'components/modals/Question';
import Info from 'components/modals/Info';

import Api from 'helpers/Api';

import { MODELS } from 'constants/models';
import Refs from 'Refs';
import LinkAccount from '../partials/LinkAccount';
import { useNavigate } from 'react-router-dom';

function View(props, ref) {

    const navigate = useNavigate();

    const tabs = [
        {
            id: 'home',
            name: 'Преглед',
            component: Home
        },
        {
            id: 'documents',
            name: 'Свързани документи',
            component: Related
        },
        {
            id: 'notes',
            name: 'Бележки',
            component: Notes
        },
    ];

    const [state, setState] = useState({
        show: false,
        params: null,
        id: null,
        tabId: tabs[0].id,
        onClose: null,
    });

    const sendMailModalRef = useRef(null);
    const noteModalRef = useRef(null);
    const questionModalRef = useRef(null);
    const infoModalRef = useRef(null);
    const linkAccountModalRef = useRef(null);

    useImperativeHandle(ref, () => ({
        open: (id) => {
            handleShow(id);
        },
        close: () => {
            handleClose();
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        }
    }));

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const handleShow = (id) => {
        setState(prev => ({
            ...prev,
            id: id,
        }));

        show();
    }

    const handleClose = () => {
        setState(prev => ({
            ...prev,
            show: false,
            tabId: tabs[0].id
        }));

        if (typeof state.onClose === 'function') {
            hide(state.onClose);
        } else {
            hide();
        }

        setState(prev => ({
            ...prev,
            onClose: null,
        }));
    }

    const setKey = id => {
        setState(prev => ({
            ...prev,
            tabId: id,
        }));
    }

    const showClient = id => {
        if (!id) {
            return;
        }

        let modal = Refs.getInstance().getRef('client');

        if (!modal) {
            return;
        }

        hide(() => {
            modal.open(id);
        });

        modal.onClose(() => {
            show();
        });
    }

    const sendMail = data => {
        if (!data) {
            return;
        }

        let modal = sendMailModalRef.current;

        if (!modal) {
            return;
        }

        hide(() => {
            modal.show(data.id, data.client_email);
        });

        modal.onClose(() => {
            show();
        });
    }

    const addNote = () => {

        let modal = noteModalRef.current;

        if (!modal) {
            return;
        }

        hide(() => {
            modal.add(state.id, MODELS.DOCUMENT);
        });

        modal.onClose(() => {
            show();
        });
    }

    const editNote = (id) => {

        let modal = noteModalRef.current;

        if (!modal) {
            return;
        }

        hide(() => {
            modal.edit(id);
        });

        modal.onClose(() => {
            show();
        });
    }

    const deleteNote = (id) => {

        let modal = questionModalRef.current;

        if (!modal) {
            return;
        }

        hide(() => {
            modal.open('Сигурни ли сте, че искате да изтриете тази бележка?');
        });

        modal.onCancel(() => {
            show();
        });

        modal.onSuccess(() => {
            destroyNote(id, () => {
                show();
            });
        });
    }

    const destroyNote = (id, cb) => {
        Api.post('notes/delete', {
            id: id
        }).then(res => {
            cb();
        });
    }

    const showAccount = (id) => {
        let modal = Refs.getInstance().getRef('account');

        if (!modal) {
            return;
        }

        hide(() => {
            modal.open(id);
        });

        modal.onClose(() => {
            show();
        });

    }

    const deleteAccount = (id) => {

        let modal = questionModalRef.current;

        if (!modal) {
            return;
        }

        hide(() => {
            modal.open('Сигурни ли сте, че искате да премахнете връзката между избрания документ и тази сметка?');
        });

        modal.onCancel(() => {
            show();
        });

        modal.onSuccess(() => {
            unlinkAccount(id, () => {
                show();
            });
        });
    }

    const unlinkAccount = (id, cb) => {
        Api.post('accounts/unlink-document', {
            id: id,
            document_id: state.id,
        }).finally(res => {
            cb();
        });
    }

    const linkAccount = (cb) => {
        let modal = linkAccountModalRef.current;

        if (!modal) {
            return;
        }

        hide(() => {
            modal.open(state.id);
        });

        modal.onClose(() => {
            show();
        });

        modal.onSuccess(() => {
            if (typeof cb === 'function') {
                cb();
            }
        });
    }

    const newVersion = () => {

        let modal = questionModalRef.current;

        if (!modal) {
            return;
        }

        hide(() => {
            modal.open('Сигурни ли сте, че искате да направите нова версия от този документ?');
        });

        modal.onCancel(() => {
            show();
        });

        modal.onSuccess(() => {
            navigate('/protocols/new?from_document_id=' + state.id);
        });
    }

    const showDocument = (id) => {
        let modal = Refs.getInstance().getRef('protocol');

        if (!modal) {
            return;
        }

        hide(() => {
            modal.open(id);
        });

        modal.onClose(() => {
            show();
        });

    }

    return (
        <>

            <SendMail
                ref={sendMailModalRef}
            />

            <Note
                ref={noteModalRef}
            />

            <LinkAccount
                ref={linkAccountModalRef}
            />

            <Question
                ref={questionModalRef}
            />

            <Info
                ref={infoModalRef}
                showCancel
            />

            <Modal size="xl" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Преглед на документ
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tabs
                        activeKey={state.tabId}
                        onSelect={(k) => setKey(k)}
                        className="mb-3"
                        mountOnEnter
                    >
                        {tabs.map(tab =>
                            <Tab key={tab.id} eventKey={tab.id} title={tab.name}>
                                {React.createElement(tab.component, {
                                    id: state.id,
                                    show: show,
                                    hide: hide,
                                    showClient: showClient,
                                    sendMail: sendMail,
                                    showAccount: showAccount,
                                    deleteAccount: deleteAccount,
                                    linkAccount: linkAccount,
                                    addNote: addNote,
                                    editNote: editNote,
                                    deleteNote: deleteNote,
                                    newVersion: newVersion,
                                    showDocument: showDocument
                                })}
                            </Tab>
                        )}
                    </Tabs>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Затвори
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default forwardRef(View);