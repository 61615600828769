import { useEffect, useRef, useState } from 'react';
import Api from 'helpers/Api';
import { useQuery } from 'helpers/Url';
import { useLocation, useNavigate, Link, useParams } from 'react-router-dom';
import { Button, Table, Form, Row, Col, Container, InputGroup } from 'react-bootstrap';
import { ArrowLeftCircle, TrashFill } from 'react-bootstrap-icons';
import { useNestedState } from 'helpers/NestedState';
import { useCurrencyContext } from 'providers/Currency';
import { useValidation } from 'helpers/Validation';
import { useAuthDataContext } from 'providers/Auth';
import { cloneDeep } from 'lodash';

import Question from 'components/modals/Question';
import Layout from 'components/layout/Layout';
import Address from 'components/pages/clients/partials/Addresses';

//misc
import Loader from 'components/misc/Loader';
import moment from 'moment';
import Autocomplete from 'components/misc/Autocomplete';
import SaveButton from 'components/misc/Button';
import Error from 'components/modals/Error';
import Info from 'components/modals/Error';
import Refs from 'Refs';

let timeout;

function Index() {

    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const query = useQuery();

    const auth = useAuthDataContext();
    const currencies = useCurrencyContext();

    const [state, setState] = useNestedState({
        loading: false,
        loadingBase: false,
        loadingClose: false,
        dataLoading: false,
        data: {
            currency_id: currencies.getDefault()?.id || '',
            currency_rate: currencies.getDefault()?.rate || '',
            currency: currencies.getDefault(),
            rows: [{}],
            allow_close: true,
        },
    });

    const [statuses, setStatuses] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [speditors, setSpeditors] = useState([]);
    const [addresses, setAddresses] = useState([]);
    const [rowStatuses, setRowStatuses] = useState([]);
    const [reasons, setReasons] = useState([]);
    const [depots, setDepots] = useState([]);

    const [validations, setValidations] = useValidation();

    const formRef = useRef(null);
    const addressModalRef = useRef(null);
    const saveAndCloseModalRef = useRef(null);
    const errorModalRef = useRef(null);
    const infoModalRef = useRef(null);

    useEffect(() => {
        Api.get('returns/payment-methods')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setPaymentMethods(res.data);
                }
            });

        Api.get('returns/speditors')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setSpeditors(res.data);
                }
            });

        Api.get('returns/statuses')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setStatuses(res.data);
                }
            });

        Api.get('returns/row-statuses')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setRowStatuses(res.data);
                }
            });

        Api.get('returns/reasons')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setReasons(res.data);
                }
            });

        Api.get('returns/depots')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setDepots(res.data);
                }
            });
    }, []);

    useEffect(() => {
        if (params.id) {
            loadData();
        }
    }, [params.id]);

    useEffect(() => {
        if (state.data.rows.length === 0) {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    rows: [{}]
                }
            }));
        }
    }, [state.data.rows]);

    const loadData = () => {

        loading(true);

        let url = 'returns/show';

        Api.get(url, {
            params: {
                id: params.id
            }
        }).then(res => {

            let data = res.data;

            let rows = data.rows;

            rows = rows.map(row => {

                return row;
            });

            data.rows = rows;

            setState(prev => ({
                ...prev,
                data: data,
            }));
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            loading(false);
        })
    }

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            dataLoading: Boolean(loading)
        }));
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleCheckboxChange = e => {
        const name = e.target.name;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: prev.data[name] ? false : true
            }
        }));
    }

    const handleCurrencyChange = e => {
        const name = e.target.name
        const value = e.target.value

        setCurrency(value);
    }

    const setCurrency = id => {
        let currency = currencies.data.find(c => Number(c.id) === Number(id));

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                currency_id: id,
                currency: currency,
                currency_rate: currency ? currency.rate : prev.data.currency_rate,
            }
        }));
    }

    const handleChangeAddress = address => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                deliveryaddress: address,
                delivery_address_id: address?.id,
                speditor_id: address?.speditor_id,
            }
        }));
    }

    const handleEditAddress = () => {
        if (!state.data.delivery_address_id) {
            return;
        }

        let modal = addressModalRef.current;

        if (modal) {
            modal.edit(state.data.delivery_address_id);

            modal.onSuccess(address => {
                if (address) {
                    handleChangeAddress(address);

                    // ако го има в списъка да се обнови...
                    setAddresses(prev => prev.map(a => {
                        if (Number(a.id) === Number(address.id)) {
                            a = address;
                        }

                        return a;
                    }));
                }
            });
        }
    }

    const handleAddAddress = () => {

        let modal = addressModalRef.current;

        if (modal) {

            modal.add(state.data.client_id);

            modal.onSuccess(address => {
                if (address) {
                    handleChangeAddress(address);

                    // да го добавим в списъка
                    setAddresses(prev => prev.concat(address));
                }
            });
        }
    }

    const handleClickAddress = () => {
        if (addresses.length === 0) {
            handleAddAddress(false);
        }
    }


    const save = (close = false) => {
        if (state.loading) {
            return;
        }

        setState(prev => ({
            ...prev,
            loading: true,
            loadingBase: close ? false : true,
            loadingClose: close ? true : false,
        }));

        setValidations(null);

        let url = params.id ? 'returns/edit' : 'returns/add';

        let form = formRef.current;

        let data = new FormData(form);

        if (params.id) {
            data.append('id', params.id)
        }

        if (close) {
            data.append('close', 1);
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            navigate('/returns');
        }).catch(error => {
            const _err = error.response;

            if (_err && _err.status) {
                if (_err.status === 422) {
                    setValidations(_err.data.errors);
                } else {
                    let modal = errorModalRef.current;

                    if (modal) {
                        modal.open(_err.data.error || _err.data.message);
                    }
                }
            }

        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
                loadingBase: false,
                loadingClose: false,
            }));
        });
    }

    const handleSave = e => {
        e.preventDefault();

        save(false);
    }

    const handleSaveAndClose = e => {
        e.preventDefault();

        let modal = saveAndCloseModalRef.current;

        if (modal) {
            modal.open();
            modal.onSuccess(() => {
                save(true);
            });
        }

    }

    const handleAddRow = e => {
        e.preventDefault()

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: prev.data.rows.concat({})
            }
        }))
    }

    const handleRemoveRow = (e, index, row) => {
        e.preventDefault()

        let modal = Refs.getInstance().getRef('question');

        if (!modal) {
            return;
        }

        modal.open('Сигурни ли сте, че искате да премахнете този ред?');

        modal.onSuccess(() => {
            removeRow(row, index);
        });
    }

    const removeRow = (row, index) => {
        Api.post('returns/delete-row', {
            id: state.data.id,
            row_id: row.id,
        }).then(res => {
            if (res.data.success) {
                setState(prev => ({
                    ...prev,
                    data: {
                        ...prev.data,
                        rows: prev.data.rows.filter((row, i) => i !== index)
                    }
                }))
            }
        }).catch(err => {
            //
        });
    }

    const handleChangeArticle = (data, index) => {

        let row = state.data.rows[index];

        let actualPrice = data.actual_price || row.actual_price || 0;
        let currentPrice = row.current_price || data.current_price || 0;


        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: Object.values({
                    ...prev.data.rows,
                    [index]: {
                        ...prev.data.rows[index],
                        article: data,
                        article_id: data.id,
                        current_price: currentPrice,
                        actual_price: actualPrice,
                    }
                })
            }
        }));

    }

    const handleAddArticle = (index) => {
        let modal = Refs.getInstance().getRef('newArticle');

        if (modal) {
            modal.add();
            modal.onSuccess(article => {

                console.log(article);

                if (article) {
                    handleChangeArticle(article, index);
                }
            });
        }
    }

    // при смяна на количеството 
    const handleQtyChange = (e, index) => {
        handleInputChange(e);

        let value = e.target.value;
    }

    const getRowTotalPrice = row => {
        return (parseFloat(row.current_price || 0) * parseFloat(row.amount || 0));
    }

    const getTotalQty = () => {
        let total = 0;

        state.data.rows.map(row => {
            total += parseFloat(row.amount || 0);
        });

        return total;
    }

    const getAddressName = address => {
        if (!address) {
            return '';
        }

        let name = [];

        if (address.delivery_type === 'address') {
            name = [
                address.dest_name_full,
                address.address,
                address.description,
                address.country_name,
            ];
        }

        if (address.delivery_type === 'office') {
            name = [
                address.dest_name_full,
                address.office_name,
            ];
        }

        if (address.delivery_type === 'store') {
            name = [
                address?.store?.translation?.name || address?.store?.name,
            ];
        }

        return name.filter(p => p).join(', ');
    }

    const getExtendedAddressName = address => {
        if (!address) {
            return '';
        }

        let map = {
            address: 'Адрес на клиент',
            office: 'Офис на куриер',
            store: 'Търговски обект'
        };

        let type = map[address.delivery_type];

        return type + ': ' + getAddressName(address);
    }

    const showArticle = id => {
        if (!id) {
            return;
        }

        let modal = Refs.getInstance().getRef('article');

        if (!modal) {
            return;
        }

        modal.edit(id);
    }

    return (
        <>
            <Address
                ref={addressModalRef}
            />

            <Question
                ref={saveAndCloseModalRef}
                mainMessage="Сигурни ли сте, че искате да затворите тази заявка?"
            />

            <Error
                ref={errorModalRef}
            />

            <Info
                ref={infoModalRef}
            />

            <Layout>
                <div className="container-fluid">

                    <div className="page-head">
                        <h1 className="display-6">
                            <Link to={-1} className="link-dark text-decoration-none">
                                <ArrowLeftCircle />
                            </Link> {params.id ? 'Редактиране на връщане' : 'Ново връщане'}
                        </h1>

                        <div className="buttons">

                        </div>
                    </div>

                    <br />

                    <div className="panel">

                        {state.dataLoading
                            ?
                            <Loader />
                            :
                            <>
                                <Form ref={formRef}>
                                    {/* <Container> */}
                                    <div className="form-panel">
                                        <div className="head">
                                            Данни за заявката
                                        </div>
                                        <Row>
                                            <Col className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Връщане №
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder=""
                                                            name="ref"
                                                            value={state.data.id || ''}
                                                            readOnly
                                                            size="sm"
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Създал
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder=""
                                                            name="ref"
                                                            value={state.data?.user?.username || ''}
                                                            readOnly
                                                            size="sm"
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Създадено на
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder=""
                                                            name="ref"
                                                            value={state.data.created_at ? moment(state.data.created_at).format('DD.MM.YYYY HH:mm') : ''}
                                                            readOnly
                                                            size="sm"
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Поръчка №
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder=""
                                                            name="ref"
                                                            value={state.data.order_id}
                                                            readOnly
                                                            size="sm"
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Клиент
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder=""
                                                            name="ref"
                                                            value={state.data.client_name}
                                                            readOnly
                                                            size="sm"
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Затворено на
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder=""
                                                            name="ref"
                                                            value={''}
                                                            readOnly
                                                            size="sm"
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Телефон
                                                    </Form.Label>
                                                    <Col>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder=""
                                                            name="client_tel"
                                                            value={state.data?.client_tel || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations?.client_tel)}
                                                            size="sm"
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations?.client_tel && (validations.client_tel[0] || validations.client_tel)}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Е-поща
                                                    </Form.Label>
                                                    <Col>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder=""
                                                            name="client_email"
                                                            value={state.data?.client_email || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations?.client_email)}
                                                            size="sm"
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations?.client_email && (validations.client_email[0] || validations.client_email)}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col className="mb-3"></Col>
                                        </Row>

                                        <Row>
                                            <Col className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Върната сума / Начин
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Row>
                                                            <Col xs={6}>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder=""
                                                                    name="total_paid_sum"
                                                                    value={state.data?.total_paid_sum || ''}
                                                                    onChange={handleInputChange}
                                                                    isInvalid={Boolean(validations?.total_paid_sum)}
                                                                    size="sm"
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {validations?.total_paid_sum && (validations.total_paid_sum[0] || validations.total_paid_sum)}
                                                                </Form.Control.Feedback>
                                                            </Col>
                                                            <Col xs={6}>
                                                                <Form.Select
                                                                    name="paymethod_id"
                                                                    value={state.data?.paymethod_id || ''}
                                                                    onChange={handleInputChange}
                                                                    isInvalid={Boolean(validations?.paymethod_id)}
                                                                    size="sm"
                                                                >
                                                                    <option value=""></option>
                                                                    {paymentMethods.map(o =>
                                                                        <option key={o.id} value={o.id}>{o?.translation?.name || o.name}</option>
                                                                    )}

                                                                </Form.Select>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {validations?.paymethod_id && (validations.paymethod_id[0] || validations.paymethod_id)}
                                                                </Form.Control.Feedback>
                                                            </Col>

                                                        </Row>
                                                    </Col>
                                                </Form.Group>
                                            </Col>

                                            <Col className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Валута
                                                    </Form.Label>
                                                    <Col>
                                                        <Form.Select
                                                            name="currency_id"
                                                            value={state.data?.currency_id || ''}
                                                            onChange={handleCurrencyChange}
                                                            isInvalid={Boolean(validations?.currency_id)}
                                                            size="sm"
                                                        >
                                                            {currencies.data.map(o =>
                                                                <option key={o.id} value={o.id}>{o?.translation?.name || o.name}</option>
                                                            )}

                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations?.currency_id && (validations.currency_id[0] || validations.currency_id)}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group>
                                            </Col>

                                            <Col className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        IBAN
                                                    </Form.Label>
                                                    <Col>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder=""
                                                            name="iban"
                                                            value={state.data?.iban || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations?.iban)}
                                                            size="sm"
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations?.iban && (validations.iban[0] || validations.iban)}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Статус
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Select
                                                            name="status_id"
                                                            value={state.data?.status_id || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations?.status_id)}
                                                            size="sm"
                                                        >
                                                            <option value=""></option>
                                                            {statuses.map(o =>
                                                                <option key={o.id} value={o.id}>{o?.translation?.name || o.name}</option>
                                                            )}

                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations?.status_id && (validations.status_id[0] || validations.status_id)}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col sm={4} className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Спедитор
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Select
                                                            name="speditor_id"
                                                            value={state.data?.speditor_id || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations?.speditor_id)}
                                                            size="sm"
                                                        >
                                                            <option value="">Няма</option>
                                                            {speditors.map(o =>
                                                                <option key={o.id} value={o.id}>{o?.translation?.name || o.name}</option>
                                                            )}

                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations?.speditor_id && (validations.speditor_id[0] || validations.speditor_id)}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Взимане от адрес
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Autocomplete
                                                            variant="basic"
                                                            data={addresses}
                                                            filterable={false}
                                                            inputValue={getAddressName(state.data?.fromaddress)}
                                                            inputIdName={`from_address_id`}
                                                            inputIdValue={state.data?.from_address_id || ''}
                                                            onChange={data => handleChangeAddress(data)}
                                                            onClickAddButton={e => handleAddAddress()}
                                                            onClick={e => handleClickAddress()}
                                                            error={Boolean(validations && validations.from_address_id && (validations.from_address_id[0] || validations.from_address_id))}
                                                            helperText={validations && validations.from_address_id && (validations.from_address_id[0] || validations.from_address_id)}
                                                            renderText={data => getExtendedAddressName(data)}
                                                            renderInputText={data => getAddressName(data)}
                                                            size="sm"
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                    </div>

                                    <div className="form-panel mt-3">
                                        <div className="head">
                                            Артикули
                                        </div>
                                        <Table className="valign-top big">
                                            <thead>
                                                <tr>
                                                    <th style={{ width: '5%' }}>
                                                        #
                                                    </th>
                                                    <th style={{ width: '10%' }}>
                                                        Артикул
                                                    </th>
                                                    <th style={{ width: '10%' }}>
                                                        Върнато к-во
                                                    </th>
                                                    <th style={{ width: '10%' }}>
                                                        Единична цена
                                                    </th>
                                                    <th style={{ width: '10%' }}>
                                                        Сума
                                                    </th>
                                                    <th style={{ width: '15%' }}>
                                                        Статус
                                                    </th>
                                                    <th style={{ width: '10%' }}>
                                                        Причина
                                                    </th>
                                                    <th style={{ width: '10%' }}>
                                                        Склад
                                                    </th>
                                                    <th style={{ width: '5%' }}>
                                                        Отварян
                                                    </th>
                                                    <th style={{ width: '20%' }}>
                                                        Бележки
                                                    </th>
                                                    <th className="options">

                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {state.data.rows.map((row, index) =>
                                                    <tr key={'r-' + index}>
                                                        <td title={row.id || '-'} style={{ width: '5%' }}>
                                                            {index + 1}

                                                            <input type="hidden" name={`rows[${index}][id]`} value={row.id || ''} />
                                                        </td>
                                                        <td style={{ width: '10%' }}>
                                                            <span className="link" onClick={e => showArticle(row.article_id)}>
                                                                {row.article?.article_name}
                                                            </span>
                                                        </td>

                                                        <td style={{ width: '10%' }}>
                                                            <InputGroup size="sm" style={{ minWidth: '95px' }}>
                                                                <Form.Control
                                                                    type="number"
                                                                    step="0.0001"
                                                                    name={`rows[${index}][amount]`}
                                                                    value={row.amount || ''}
                                                                    onChange={handleInputChange}
                                                                    isInvalid={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].amount && (validations.rows[index].amount[0] || validations.rows[index].amount))}
                                                                    size="sm"
                                                                    className="price-input"
                                                                />

                                                                <InputGroup.Text>
                                                                    {row?.article?.amount_type_short || '-'}
                                                                </InputGroup.Text>

                                                                {/* <Form.Control.Feedback type="invalid">
                                                                        {validations && validations.rows && validations.rows[index] && validations.rows[index].amount && (validations.rows[index].amount[0] || validations.rows[index].amount)}
                                                                    </Form.Control.Feedback> */}
                                                            </InputGroup>

                                                        </td>
                                                        <td style={{ width: '10%' }}>
                                                            <InputGroup size="sm" style={{ minWidth: '95px' }}>
                                                                <Form.Control
                                                                    type="number"
                                                                    step="0.0001"
                                                                    name={`rows[${index}][current_price]`}
                                                                    value={row.current_price}
                                                                    onChange={handleInputChange}
                                                                    isInvalid={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].current_price && (validations.rows[index].current_price[0] || validations.rows[index].current_price))}
                                                                    size="sm"
                                                                    className={`price-input`}
                                                                />

                                                                {state.data?.currency &&
                                                                    <InputGroup.Text>
                                                                        {state.data.currency.prefix || state.data.currency.sufix || '-'}/{row?.article?.amount_type_short || '-'}
                                                                    </InputGroup.Text>
                                                                }
                                                            </InputGroup>
                                                        </td>
                                                        <td style={{ width: '10%' }}>
                                                            <InputGroup size="sm" style={{ minWidth: '95px' }}>
                                                                <Form.Control
                                                                    type="number"
                                                                    step="0.0001"
                                                                    value={getRowTotalPrice(row)}
                                                                    size="sm"
                                                                    className={`price-input  ${getRowTotalPrice(row) < 0 && 'negative-value'}`}
                                                                    disabled
                                                                />

                                                                {state.data?.currency &&
                                                                    <InputGroup.Text>
                                                                        {state.data.currency.prefix || state.data.currency.sufix || '-'}
                                                                    </InputGroup.Text>
                                                                }
                                                            </InputGroup>
                                                        </td>
                                                        <td style={{ width: '15%' }}>
                                                            <Form.Select
                                                                name={`rows[${index}][status_id]`}
                                                                value={row.status_id || ''}
                                                                onChange={handleInputChange}
                                                                isInvalid={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].status_id && (validations.rows[index].status_id[0] || validations.rows[index].status_id))}
                                                                size="sm"
                                                            >
                                                                {rowStatuses.map(o =>
                                                                    <option key={o.id} value={o.id}>{o?.translation?.name || o.name}</option>
                                                                )}

                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">
                                                                {validations && validations.rows && validations.rows[index] && validations.rows[index].status_id && (validations.rows[index].status_id[0] || validations.rows[index].status_id)}
                                                            </Form.Control.Feedback>
                                                        </td>
                                                        <td style={{ width: '10%' }}>
                                                            <Form.Select
                                                                name={`rows[${index}][reason_id]`}
                                                                value={row.reason_id || ''}
                                                                onChange={handleInputChange}
                                                                isInvalid={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].reason_id && (validations.rows[index].reason_id[0] || validations.rows[index].reason_id))}
                                                                size="sm"
                                                            >
                                                                {reasons.map(o =>
                                                                    <option key={o.id} value={o.id}>{o?.translation?.name || o.name}</option>
                                                                )}

                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">
                                                                {validations && validations.rows && validations.rows[index] && validations.rows[index].reason_id && (validations.rows[index].reason_id[0] || validations.rows[index].reason_id)}
                                                            </Form.Control.Feedback>
                                                        </td>
                                                        <td style={{ width: '10%' }}>
                                                            <Form.Select
                                                                name={`rows[${index}][depot_id]`}
                                                                value={row.depot_id || ''}
                                                                onChange={handleInputChange}
                                                                isInvalid={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].depot_id && (validations.rows[index].depot_id[0] || validations.rows[index].depot_id))}
                                                                size="sm"
                                                            >
                                                                {depots.map(o =>
                                                                    <optgroup key={o.id} label={o.store?.name}>
                                                                        {o.depots.map(d =>
                                                                            <option key={d.id} value={d.id}>{d?.translation?.name || d.name}</option>
                                                                        )}
                                                                    </optgroup>
                                                                )}

                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">
                                                                {validations && validations.rows && validations.rows[index] && validations.rows[index].reason_id && (validations.rows[index].reason_id[0] || validations.rows[index].reason_id)}
                                                            </Form.Control.Feedback>
                                                        </td>
                                                        <td style={{ width: '5%', verticalAlign: 'middle' }}>
                                                            <div className="custom-radio">
                                                                <label>
                                                                    <input type="radio" name={`rows[${index}][opened]`} value={0} checked={Number(row.opened) === 0} onChange={handleInputChange} />
                                                                    <span>
                                                                        НЕ
                                                                    </span>
                                                                </label>
                                                                <label>
                                                                    <input type="radio" name={`rows[${index}][opened]`} value={1} checked={Number(row.opened) === 1} onChange={handleInputChange} />
                                                                    <span>
                                                                        ДА
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td style={{ width: '20%' }}>
                                                            <Form.Control
                                                                type="text"
                                                                name={`rows[${index}][notes]`}
                                                                value={row.notes || ''}
                                                                onChange={handleInputChange}
                                                                isInvalid={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].notes && (validations.rows[index].notes[0] || validations.rows[index].notes))}
                                                                size="sm"
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {validations && validations.rows && validations.rows[index] && validations.rows[index].notes && (validations.rows[index].notes[0] || validations.rows[index].notes)}
                                                            </Form.Control.Feedback>
                                                        </td>
                                                        <td className="options">
                                                            <Button variant="danger" size="sm" onClick={e => handleRemoveRow(e, index, row)} disabled={state.data.rows.length === 1}>
                                                                <TrashFill />
                                                            </Button>
                                                        </td>

                                                    </tr>
                                                )}

                                            </tbody>
                                        </Table>


                                    </div>

                                    <Row>
                                        <Col sm={6}>
                                            <div className="form-panel mt-3">
                                                <div className="head">
                                                    Описание
                                                </div>
                                                <Form.Control
                                                    type="text"
                                                    as="textarea"
                                                    // placeholder="Бележки"
                                                    name="description"
                                                    value={state.data?.description || ''}
                                                    onChange={handleInputChange}
                                                    isInvalid={Boolean(validations?.description)}
                                                    rows={6}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {validations?.description && (validations.description[0] || validations.description)}
                                                </Form.Control.Feedback>
                                            </div>
                                        </Col>
                                        <Col sm={6}>
                                            <div className="form-panel mt-3">
                                                <div className="head">
                                                    Допълнителни данни
                                                </div>
                                                <Form.Control
                                                    type="text"
                                                    as="textarea"
                                                    // placeholder="Бележки"
                                                    name="extra_details"
                                                    value={state.data?.extra_details || ''}
                                                    onChange={handleInputChange}
                                                    isInvalid={Boolean(validations?.extra_details)}
                                                    rows={6}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {validations?.extra_details && (validations.extra_details[0] || validations.extra_details)}
                                                </Form.Control.Feedback>
                                            </div>
                                        </Col>
                                    </Row>

                                    <div className="buttons mt-3">
                                        <SaveButton
                                            loading={state.loadingBase}
                                            disabled={state.loading}
                                            className="save"
                                            onClick={handleSave}
                                        >
                                            Запази данните
                                        </SaveButton>
                                        {state.data.allow_close &&
                                            <SaveButton
                                                loading={state.loadingClose}
                                                disabled={state.loading}
                                                className="save"
                                                onClick={handleSaveAndClose}
                                            >
                                                Запази и вкарай в склада
                                            </SaveButton>
                                        }

                                    </div>
                                    {/* </Container> */}

                                </Form>
                            </>
                        }

                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Index;