import { forwardRef, useImperativeHandle } from "react";
import { Form } from "react-bootstrap";

function ArtNum(props, ref) {

    useImperativeHandle(ref, () => ({
        getName: getName,
        getType: getType,
        getTitle: getTitle,
        hasSettings: hasSettings,
        getSettings: getSettings,
        getDefaultOptions: () => ({})
    }));

    const getName = () => {
        return 'Общ номер';
    }

    const getType = () => {
        return 'art_num';
    }

    const getTitle = () => {
        return 'Използва се за обединяване на артикулите по размер и цвят';
    }

    const hasSettings = ({ createOrUpdate }) => {
        return false;
    };

    const getSettings = ({ state, setConfig, createOrUpdate }) => {
        return null;
    }

    return getName();
}

export default forwardRef(ArtNum)