import { useState, useImperativeHandle, forwardRef } from 'react'
import { Modal, Button } from 'react-bootstrap';

function Success(props, ref) {
    const [state, setState] = useState({
        show: false,
        params: null,
        msg: null,
        onSuccess: null,
        onClose: null,
    });

    useImperativeHandle(ref, () => ({
        open: (msg = null) => {
            handleShow(msg);
        },
        close: () => {
            handleClose();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        }
    }));

    const show = () => {
        setState(prev => ({
            ...prev,
            show: true,
        }));
    }

    const hide = () => {
        setState(prev => ({
            ...prev,
            show: false,
        }));

        if (typeof state.onClose === 'function') {
            state.onClose();
        }
    }

    const handleShow = (msg) => {
        setState(prev => ({
            ...prev,
            msg: msg
        }));

        show();
    }

    const handleAccept = () => {
        hide();

        if (state.onSuccess && typeof state.onSuccess === 'function') {
            return state.onSuccess();
        }
    }

    const handleClose = () => {
        hide();

        if (typeof state.onClose === 'function') {
            state.onClose();
        }
    }

    return (
        <Modal centered show={state.show} onHide={handleClose}>
            <Modal.Body>
                {state.msg || props.mainMessage}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="dark" onClick={handleAccept}>
                    {props.agreeBtnText || 'ОК'}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default forwardRef(Success);