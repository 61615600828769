import { useEffect, useRef, useState } from 'react';
import Api from 'helpers/Api';
import { buildSearchParams, useQuery } from 'helpers/Url';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Alert, Button, Table } from 'react-bootstrap';

import Options from './partials/Options';

import Question from 'components/modals/Question';
import Filter from './partials/Filter';
import AddOrEdit from './partials/AddOrEdit';
import Layout from 'components/layout/Layout';

//misc
import MenuOptions from 'components/misc/MenuOptions'
import Pagination from 'components/misc/Pagination'
import Loader from 'components/misc/Loader';
import TableHeader from 'components/misc/TableHeader';
import TableCol from 'components/misc/TableCol';
import NoDataFound from 'components/misc/NoDataFound';

let timeout;

function Index() {

    const location = useLocation();
    const navigate = useNavigate();
    const query = useQuery();
    // const auth = useAuthDataContext();

    const tableRef = useRef(null);
    const addOrEditModalRef = useRef(null);
    const deleteModalRef = useRef(null);

    const [state, setState] = useState({
        sorting: false,
        loading: true,
        refresh: false,
        setFilter: false,
        data: [],
        pages: 0,
        total: 0,
        filter: {
            page: 1,
        },
    });

    useEffect(() => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: query.get('page') || 1,
                search: query.get('search') || '',
                sort: query.get('sort') || '',
                order: query.get('order') || '',
            },
            refresh: new Date().getTime()
        }))
    }, [location.search]);

    useEffect(() => {
        if (state.setFilter) {
            navigate('?' + buildSearchParams(query, state.filter));
        }

    }, [state.setFilter]);

    useEffect(() => {
        if (state.refresh) {
            loadData();
        }
    }, [state.refresh]);

    const loadData = () => {

        loading(true);

        let url = 'labels/all';

        Api.get(url, {
            params: state.filter
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data.items,
                total: res.data.total,
                pages: res.data.pages,
                totals: res.data.totals,
                filter: {
                    ...prev.filter,
                    ...res.data.filter,
                }
            }));

            if (res.data.pages > 0 && state.filter.page > res.data.pages) {
                return handlePage({ selected: res.data.pages - 1 });
            }

            loading(false);
            sorting(false);
        });
    }

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }));
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: reset ? 1 : prev.filter.page
            },
            setFilter: new Date().getTime(),
            refresh: new Date().getTime(),
        }));
    }

    const handleAdd = () => {
        let modal = addOrEditModalRef.current;

        modal.add();

        modal.onSuccess(() => {
            refresh(true);
        });
    }

    // update
    const handleEdit = id => {
        let modal = addOrEditModalRef.current;

        modal.edit(id);

        modal.onSuccess(() => {
            refresh(false);
        });
    }

    // delete
    const handleDelete = id => {
        let modal = deleteModalRef.current;

        modal.open();
        modal.onSuccess(() => {
            handleDestroy(id);
        })
    }

    const handleDestroy = id => {
        Api.post('/labels/delete', {
            id: id
        }).then(res => {
            if (res.data.success) {
                refresh(false);
            }
        });
    }

    // Search
    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout);

        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [key]: val,
            },
        }));

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: 1
                },
                setFilter: new Date().getTime()
            }));
        }, delay);
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            setFilter: new Date().getTime()
        }));
    }

    const sorting = (sorting) => {
        setState(prev => ({
            ...prev,
            sorting: Boolean(sorting)
        }));
    }

    const handleSort = (sort, order) => {
        setState(prev => ({
            ...prev,
            sorting: true,
            filter: {
                ...prev.filter,
                sort: sort,
                order: order,
                page: 1,
            },
            setFilter: new Date().getTime()
        }));
    }

    return (
        <>

            <AddOrEdit
                ref={addOrEditModalRef}
            />

            <Question
                ref={deleteModalRef}
                mainMessage="Сигурни ли сте?"
                agreeBtnText="Изтриване"
                agreeBtnClass="remove"
            />

            <Layout>
                <div className="container-fluid">

                    <div className="page-head">
                        <h1 className="display-6">
                            Етикети
                        </h1>

                        <div className="buttons">
                            <Button variant="outline-dark" onClick={handleAdd}>
                                Добави
                            </Button>
                        </div>
                    </div>

                    <br />

                    <div className="panel">
                        <Filter
                            filter={state.filter}
                            handleSearch={handleSearch}
                        />

                        {state.loading && !state.sorting
                            ?
                            <Loader />
                            :
                            state.data.length === 0 ?
                                <NoDataFound />
                                :
                                <>
                                    <Table responsive striped hover ref={tableRef}>
                                        <TableHeader
                                            tableRef={tableRef}
                                            activeSortKey={state.filter.sort}
                                            activeSortDir={state.filter.order}
                                            onSort={(col, dir) => handleSort(col, dir)}
                                        >
                                            <TableCol sortable sortKey="id" style={{ width: '70px' }}>ID</TableCol>
                                            <TableCol className="text-left" sortable sortKey="name">Име</TableCol>
                                            <TableCol className="options">Опции</TableCol>
                                        </TableHeader>
                                        <tbody>
                                            {state.data.map(c =>
                                                <tr
                                                    key={'c-' + c.id}
                                                >
                                                    <td>
                                                        {c.id}
                                                    </td>
                                                    <td>
                                                        {c.name}
                                                    </td>
                                                    <td className="options">
                                                        <MenuOptions>
                                                            <Options
                                                                data={c}
                                                                handleEdit={handleEdit}
                                                                handleDelete={handleDelete}
                                                            />
                                                        </MenuOptions>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>

                                    <Pagination
                                        className="mt-3"
                                        page={state.filter.page}
                                        pages={state.pages}
                                        total={state.total}
                                        handlePage={handlePage}
                                    />
                                </>
                        }


                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Index;
