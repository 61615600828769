import Load from "components/pages/storeloads/view/View";
import Move from "components/pages/storemoves/view/View";
import Revision from "components/pages/revisions/view/View";
import Account from "components/pages/accounts/view/View";
import { forwardRef, useImperativeHandle, useRef } from "react";

function StorageOperationViewGroup(props, ref) {
    const loadModalRef = useRef(null);
    const moveModalRef = useRef(null);
    const revisionModalRef = useRef(null);
    const accountModalRef = useRef(null);

    useImperativeHandle(ref, () => ({
        open: (operationId, operationType) => {
            let ref = null;

            switch (operationType) {
                case 'load':
                case 'return':
                    ref = loadModalRef;
                    break;
                case 'move':
                    ref = moveModalRef;
                    break;
                case 'revision':
                    ref = revisionModalRef;
                    break;
                case 'account':
                    ref = accountModalRef;
                    break;
            }

            if (ref && ref.current) {
                ref.current.open(operationId);
            }
        },
    }));

    return (
        <>
            <Load
                ref={loadModalRef}
            />
            <Move
                ref={moveModalRef}
            />
            <Revision
                ref={revisionModalRef}
            />
            <Account
                ref={accountModalRef}
            />
        </>
    )
}

export default forwardRef(StorageOperationViewGroup);