import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import { Modal, Button, Tabs, Tab, FloatingLabel, Form, Row, Col, ButtonGroup, InputGroup } from 'react-bootstrap';

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//providers
import { useLanguageContext } from 'providers/Language'

//misc
import Autocomplete from 'components/misc/Autocomplete';
import SaveButton from 'components/misc/Button'
import Info from 'components/modals/Info';
import HelpInfo from 'components/misc/Info'

import Success from 'components/modals/Success';
import Error from 'components/modals/Error';
import SelectPrepaidPayments from './SelectPrepaidPayments';
import { calcDiscount } from 'helpers/Price';
import Refs from 'Refs';
import Loader from 'components/misc/Loader';

let timeout;

function Close(props, ref) {

    const langs = useLanguageContext();

    const defaultData = {
        mix: false,
        fromClientBalance: null, // дали от портфейла ще се вади
        usages: {}, // от кои авансови плащания ще се вади и каква сума
        usedSum: null, // каква сума ще се вади от портфейла/авансовите плащания
    }

    const defaultStornoPaymentTabId = 'i-0d-0';

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        data: defaultData,
        paymentsForStorno: [],
        stornoPaymentTabId: defaultStornoPaymentTabId,
        prepaidPayments: [],
        balance: null,
        currency: null,
        dataLoading: false,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null,
        onClose: null,
        onSave: null,
    });

    const [validations, setValidations] = useValidation();

    const [paymentMethods, setPaymentMethods] = useState([]);

    const successModalRef = useRef(null);
    const errorModalRef = useRef(null);
    const selectPrepaidPaymentsModalRef = useRef(null);

    useImperativeHandle(ref, () => ({
        open: (id) => {
            open(id);
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        },
        onSave: fn => {
            setState(prev => ({
                ...prev,
                onSave: fn
            }));
        }
    }));

    useEffect(() => {
        loadData();
    }, [state.refresh]);

    useEffect(() => {
        Api.get('accounts/payment-methods')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setPaymentMethods(res.data);
                }
            });
    }, []);

    useEffect(() => {
        setState(prev => ({
            ...prev,
            loading: Boolean(props.loading)
        }));
    }, [props.loading]);

    useEffect(() => {
        setValidations(props.validations);
    }, [props.validations]);

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const open = (id) => {
        setState(prev => ({
            ...prev,
            data: defaultData,
            id: id,
            stornoPaymentTabId: defaultStornoPaymentTabId,
            refresh: new Date()
        }));

        show();
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
            stornoPaymentTabId: defaultStornoPaymentTabId,
        }));

        setValidations(null);

        if (typeof state.onClose === 'function') {
            hide(state.onClose);
        } else {
            hide();
        }
    }

    const loadData = () => {
        if (!state.id) {
            return;
        }

        setState(prev => ({
            ...prev,
            dataLoading: true,
        }));

        let request;

        if (typeof state.id === 'object') {
            request = Api.post('/accounts/close-by-data', state.id);
        } else {
            request = Api.get('/accounts/close-by-id', {
                params: {
                    id: state.id,
                }
            });
        }

        request.then(res => {
            let account = res.data.account;

            account.paymethod_id = 1;
            account.pay_total = account.total_remain_sum;

            let payments = res.data.payments_for_storno;

            setState(prev => ({
                ...prev,
                data: account,
                paymentsForStorno: payments,
                prepaidPayments: res.data.prepaid_payments,
                balance: res.data.balance,
                currency: res.data.currency,
                dataLoading: false,
            }));
        });
    }

    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = '/accounts/close';

        let data = new FormData(e.target);

        if (state.id) {
            data.append('id', state.id)
        }

        data.append('is_mix', isMix() ? 1 : 0);

        data.append('paymethod_id', state.data.paymethod_id);

        // портфейл/авансови
        if (getUsedSum() > 0) {
            data.append('used_sum', getUsedSum());

            data.append('from_client_balance', Number(state.data.fromClientBalance));

            if (state.data.usages) {
                for (let [paymentId, usedSum] of Object.entries(state.data.usages)) {
                    data.append('usages[' + paymentId + ']', Number(usedSum));
                }
            }
        }

        if (typeof state.onSave === 'function') {
            return state.onSave(data);
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (res.data.success === true) {
                if (typeof state.onSuccess === 'function') {
                    state.onSuccess(res.data)
                }

                let modal = successModalRef.current;

                if (modal) {
                    hide(() => {
                        modal.open('Сметката беше приключена!');
                    });

                    modal.onClose(state.onClose);
                }

            } else {
                let modal = errorModalRef.current;

                if (modal) {
                    hide(() => {
                        modal.open('Възникна грешка при опита да бъде приключена сметката!');
                    });

                    modal.onClose(state.onClose);
                }
            }
        }).catch(error => {
            const _err = error.response;

            if (_err && _err.status && _err.status === 422) {
                setValidations(_err.data.errors)
            }

        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }));
        });
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleNumberInputChange = e => {
        const name = e.target.name
        const value = Number(e.target.value)

        setState(name, value, 'data')
    }

    const handleStornoInputChange = (name, value, aIndex, pIndex) => {

        setState(prev => ({
            ...prev,
            paymentsForStorno: Object.values({
                ...prev.paymentsForStorno,
                [aIndex]: {
                    ...prev.paymentsForStorno[aIndex],
                    payments: Object.values({
                        ...prev.paymentsForStorno[aIndex].payments,
                        [pIndex]: {
                            ...prev.paymentsForStorno[aIndex].payments[pIndex],
                            [name]: value
                        }
                    })
                }
            })
        }));
    }

    const handleCheckboxChange = e => {
        const name = e.target.name;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: prev.data[name] ? false : true
            }
        }));
    }

    const handleDiscountChange = e => {
        let name = e.target.name
        let value = e.target.value

        if (!value.includes('+') && !value.includes('-') && value.length > 0) {
            value = '-' + value;
        }

        if (value.includes('+')) {
            value = value.replace('-', '');
        }

        if (value < -100) {
            value = -100;
        }

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                discount: value,
            }
        }));

    }

    useEffect(() => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                pay_total: getTotalReal() - getUsedSum()
            }
        }));
    }, [state.data.discount]);

    const getTotalSum = () => {
        let price = parseFloat(state.data.total_sum || 0);

        if (price < 0) {
            return 0;
        }

        return price;
    }

    const getTotalDiscount = () => {
        return calcDiscount(getTotalSum() - getUsedSum(), state.data.discount);
    }

    const getTotalReal = () => {
        return getTotalSum() + getTotalDiscount();
    }

    const isDiscount = () => {
        return parseFloat(state.data.discount || 0) <= 0;
    }

    const getPayTotal = () => {
        if (isMix()) {
            let total = 0;

            let methods = state.data.methods;

            if (methods) {
                Object.values(methods).map(price => {
                    total += parseFloat(price || 0);
                });
            }

            return total;
        }

        let total = state.data.pay_total;

        if (total < 0) {
            return 0;
        }

        return total;
    }

    const getUsedSum = () => {
        return parseFloat(state.data.usedSum || 0);
    }

    const getBalance = () => {
        let balance = getPayTotal() + getUsedSum() - getTotalReal();

        // if (balance > 0) {
        // return balance;
        // }

        return Math.abs(balance);

        return 0;
    }

    const getPayTotalFromMethod = (methodId) => {
        let methods = state.data.methods;

        if (methods) {
            if (typeof methods[methodId] !== 'undefined') {
                return methods[methodId];
            }
        }

        return '';
    }

    // при фокус да попълва автоматично дължимата сума
    // ако вече е попълнено за 1 начин
    // *При смесено плащане, когато се маркира единия начин на плащане,  автоматично при маркирането на втория начин се смята колко клиентът има да доплати
    const handleMixPaymentFocus = e => {
        let name = e.target.name;

        // console.log(state.data.methods)

        if (state.data.methods) {
            let value = getBalance();

            if (value > 0) {
                setState(name, value, 'data');
            }
        }
    }

    const handlePayMethodChange = (methodId) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                paymethod_id: methodId
            }
        }));
    }

    const handleMixChange = () => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                mix: !prev.data.mix
            }
        }));
    }

    const isMix = () => {
        return Boolean(state.data.mix);
    }

    const setStornoPaymentTabId = id => {
        setState(prev => ({
            ...prev,
            stornoPaymentTabId: id,
        }));
    }

    // console.log(state.stornoPaymentTabId)

    const showAccount = accountId => {
        let modal = Refs.getInstance().getRef('account');

        if (!modal) {
            return;
        }

        hide(() => {
            modal.open(accountId);
        });

        modal.onClose(() => {
            show();
        });
    }

    const showPrepaidPayments = (e) => {
        e.preventDefault();

        let modal = selectPrepaidPaymentsModalRef.current;

        if (!modal) {
            return;
        }

        hide(() => {
            modal.open({
                payments: state.prepaidPayments,
                balance: state.balance,
                currency: state.currency,
                totalSum: getTotalSum(),
            });
        });

        modal.onSave(data => {
            console.log(data);

            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    fromClientBalance: data.fromClientBalance,
                    usages: data.usages,
                    usedSum: data.usedSum,
                    pay_total: prev.data.total_remain_sum - data.usedSum
                }
            }));

            show();
        });

        modal.onClose(() => {
            show();
        });
    }

    const clearPrepaidPayments = (e) => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                fromClientBalance: null,
                usages: {},
                usedSum: null,
                pay_total: prev.data.total_remain_sum
            }
        }));
    }

    // console.log(validations)

    return (
        <>
            <Success
                ref={successModalRef}
            />

            <Error
                ref={errorModalRef}
            />

            <SelectPrepaidPayments
                ref={selectPrepaidPaymentsModalRef}
            />

            <Modal size="md" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <form onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Приключване на сметка
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        {state.dataLoading && <Loader />}

                        {!state.dataLoading &&
                            <>
                                <Row>
                                    <Col className="mb-3">
                                        <FloatingLabel label="Клиент">
                                            <Form.Control
                                                type="text"
                                                placeholder="Клиент"
                                                // name=""
                                                value={state.data.client?.name || ''}
                                                onChange={handleInputChange}
                                                // isInvalid={Boolean(validations && validations.email)}
                                                readOnly
                                            />
                                            {/* <Form.Control.Feedback type="invalid">
                                        {validations && validations.email && (validations.email[0] || validations.email)}
                                    </Form.Control.Feedback> */}
                                        </FloatingLabel>
                                    </Col>
                                </Row>

                                <Row className="mb-3" style={{ alignItems: 'center' }}>
                                    <Col>
                                        <FloatingLabel label={'Общо' + ('(' + (state.data.currency?.prefix || state.data.currency?.sufix || '-') + ')')}>
                                            <Form.Control
                                                type="text"
                                                placeholder={'Общо' + ('(' + (state.data.currency?.prefix || state.data.currency?.sufix || '-') + ')')}
                                                name="total_sum"
                                                value={getTotalSum()}
                                                onChange={handleInputChange}
                                                isInvalid={Boolean(validations && validations.total_sum)}
                                                disabled
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {validations && validations.total_sum && (validations.total_sum[0] || validations.total_sum)}
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col xs="auto" style={{ fontSize: '20px' }}>
                                        {/* {isDiscount() ? '-' : '+'} */}
                                        +
                                    </Col>
                                    <Col >
                                        <FloatingLabel label={isDiscount() ? 'Отстъпка(%)' : 'Надценка(%)'}>
                                            <Form.Control
                                                type="text"
                                                placeholder={isDiscount() ? 'Отстъпка(%)' : 'Надценка(%)'}
                                                name="discount"
                                                value={state.data.discount || 0}
                                                onChange={handleDiscountChange}
                                                isInvalid={Boolean(validations && validations.discount)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {validations && validations.discount && (validations.discount[0] || validations.discount)}
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col xs="auto" style={{ fontSize: '20px' }}>
                                        =
                                    </Col>
                                    <Col>
                                        <FloatingLabel label={'Сума' + ('(' + (state.data.currency?.prefix || state.data.currency?.sufix || '-') + ')')}>
                                            <Form.Control
                                                type="text"
                                                placeholder={'Сума' + ('(' + (state.data.currency?.prefix || state.data.currency?.sufix || '-') + ')')}
                                                name="total_real"
                                                value={getTotalReal()}
                                                onChange={handleInputChange}
                                                isInvalid={Boolean(validations && validations.total_real)}
                                                disabled
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {validations && validations.total_real && (validations.total_real[0] || validations.total_real)}
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                </Row>

                                <Row className="mb-3">
                                    <Col xs={8}>
                                        <FloatingLabel label={'Платени сега' + ('(' + (state.data.currency?.prefix || state.data.currency?.sufix || '-') + ')')}>
                                            <Form.Control
                                                type="number"
                                                // min="0"
                                                step="0.01"
                                                placeholder={'Платени сега' + ('(' + (state.data.currency?.prefix || state.data.currency?.sufix || '-') + ')')}
                                                name="pay_total"
                                                value={getPayTotal()}
                                                onChange={handleNumberInputChange}
                                                isInvalid={Boolean(validations && validations.pay_total)}
                                                autoFocus
                                                disabled={isMix() ? true : false}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {validations && validations.pay_total && (validations.pay_total[0] || validations.pay_total)}
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col xs={4}>
                                        {state.data.usedSum
                                            ?
                                            <Button size="sm" onClick={clearPrepaidPayments}>
                                                Без портфейл
                                            </Button>
                                            :
                                            <Button size="sm" onClick={showPrepaidPayments}>
                                                От портфейл
                                            </Button>
                                        }
                                    </Col>
                                </Row>

                                {state.data.usedSum > 0 &&
                                    <Row className="mb-3">
                                        <Col xs={12}>
                                            <FloatingLabel label={(state.data.fromClientBalance ? 'От клиентски портфейл' : 'От авансови плащания') + ('(' + (state.data.currency?.prefix || state.data.currency?.sufix || '-') + ')')}>
                                                <Form.Control
                                                    type="number"
                                                    // min="0"
                                                    step="0.01"
                                                    placeholder={(state.data.fromClientBalance ? 'От клиентски портфейл' : 'От авансови плащания') + ('(' + (state.data.currency?.prefix || state.data.currency?.sufix || '-') + ')')}
                                                    value={state.data.usedSum || ''}
                                                    disabled
                                                />
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                }

                                <Row className="mb-3">
                                    <Col>
                                        <FloatingLabel label={(getTotalReal() - getUsedSum() <= getPayTotal() ? 'Ресто' : 'Дължими') + ('(' + (state.data.currency?.prefix || state.data.currency?.sufix || '-') + ')')}>
                                            <Form.Control
                                                type="number"
                                                placeholder={(getTotalReal() - getUsedSum() <= getPayTotal() ? 'Ресто' : 'Дължими') + ('(' + (state.data.currency?.prefix || state.data.currency?.sufix || '-') + ')')}
                                                value={getBalance()}
                                                disabled
                                            />
                                        </FloatingLabel>
                                    </Col>
                                </Row>

                                {state.data.mix
                                    ?
                                    (paymentMethods || []).map(method =>
                                        <Row key={method.id} className="mb-2">
                                            <Col>

                                                <InputGroup size="sm" onClick={e => e.currentTarget.querySelector('input').focus()}>
                                                    <InputGroup.Text style={{ width: '70%' }}>
                                                        {method?.translation?.name || method.name}
                                                    </InputGroup.Text>
                                                    <Form.Control
                                                        type="number"
                                                        step="0.0001"
                                                        name={`methods[${method.id}]`}
                                                        value={getPayTotalFromMethod(method.id)}
                                                        onChange={handleNumberInputChange}
                                                        onFocus={handleMixPaymentFocus}
                                                        // isInvalid={Boolean(validations && validations)}
                                                        size="sm"
                                                        className="price-input"
                                                    />
                                                    <InputGroup.Text>
                                                        {state.data.currency?.prefix || state.data.currency?.sufix || '-'}
                                                    </InputGroup.Text>

                                                    {/* <Form.Control.Feedback type="invalid">
                                                {validations && validations.rows && validations.rows[index] && validations.rows[index].amount && (validations.rows[index].amount[0] || validations.rows[index].amount)}
                                            </Form.Control.Feedback> */}
                                                </InputGroup>

                                                {/* <FloatingLabel label={method?.translation?.name || method.name}>
                                            <Form.Control
                                                type="number"
                                                placeholder={method?.translation?.name || method.name}
                                                name={`methods[${method.id}]`}
                                                value={getPayTotalFromMethod(method.id)}
                                                onChange={handleInputChange}
                                            />
                                        </FloatingLabel> */}
                                            </Col>
                                        </Row>
                                    )
                                    :
                                    <Row>
                                        <Col>
                                            <label style={{ opacity: .65, fontSize: '0.9rem' }} >
                                                Начин на плащане
                                            </label>
                                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '3px' }}>
                                                {paymentMethods.map(method =>
                                                    <Button
                                                        key={method.id}
                                                        size="sm"
                                                        onClick={e => handlePayMethodChange(method.id)}
                                                        variant={state.data.paymethod_id === method.id ? 'dark' : 'outline-secondary'}
                                                    >
                                                        {method?.translation?.name || method.name}
                                                    </Button>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                }

                                <Row className="mt-2">
                                    <Col>
                                        <Button size="sm" variant="outline-dark" onClick={handleMixChange}>
                                            {isMix() ? 'Единично' : 'Смесено'}
                                        </Button>
                                    </Col>
                                </Row>

                                {state.paymentsForStorno.length
                                    ?
                                    <>
                                        <div className="mt-3">
                                            {state.paymentsForStorno.map((p, i) =>
                                                <div key={i}>
                                                    Плащания за сторниране по сметка <span className="link" onClick={e => showAccount(p.account_id)}>{p.account_id}</span>: <b>{parseFloat(p.calc_amount || 0).toFixed(2)}</b> {state.data.currency?.prefix || state.data.currency?.sufix || '-'}
                                                </div>
                                            )}
                                        </div>
                                        <Tabs
                                            activeKey={state.stornoPaymentTabId}
                                            onSelect={(k) => setStornoPaymentTabId(k)}
                                            className="mt-3 mb-3"
                                        >
                                            {state.paymentsForStorno.map((account, i) =>
                                                account.payments.map((payment, d) =>
                                                    <Tab key={'i-' + i + 'd-' + d} eventKey={'i-' + i + 'd-' + d} title={`${payment.paymentmethod?.name} (${parseFloat(payment.amount || 0).toFixed(2)} ${state.data.currency?.prefix || state.data.currency?.sufix || '-'})`}>
                                                        <Row className="mb-3">
                                                            <Col>
                                                                <FloatingLabel label={'Сума' + ('(' + (state.data.currency?.prefix || state.data.currency?.sufix || '-') + ')')}>
                                                                    <Form.Control
                                                                        type="number"
                                                                        // min="0"
                                                                        step="0.01"
                                                                        placeholder={'Сума' + ('(' + (state.data.currency?.prefix || state.data.currency?.sufix || '-') + ')')}
                                                                        name={`storno_payments[${payment.id}][storno_amount]`}
                                                                        defaultValue={payment.calc_amount || 0}
                                                                        onChange={e => handleStornoInputChange('calc_amount', e.target.value, i, d)}
                                                                        isInvalid={Boolean(validations && validations.storno_payments && validations.storno_payments[payment.id] && validations.storno_payments[payment.id].storno_amount && (validations.storno_payments[payment.id].storno_amount[0] || validations.storno_payments[payment.id].storno_amount))}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {validations && validations.storno_payments && validations.storno_payments[payment.id] && validations.storno_payments[payment.id].storno_amount && (validations.storno_payments[payment.id].storno_amount[0] || validations.storno_payments[payment.id].storno_amount)}
                                                                    </Form.Control.Feedback>
                                                                </FloatingLabel>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col>
                                                                <FloatingLabel label="Начин на плащане">
                                                                    <Form.Select
                                                                        name={`storno_payments[${payment.id}][paymethod_id]`}
                                                                        value={payment.paymethod_id || ''}
                                                                        onChange={e => handleStornoInputChange('paymethod_id', e.target.value, i, d)}
                                                                        isInvalid={Boolean(validations && validations.storno_payments && validations.storno_payments[payment.id] && validations.storno_payments[payment.id].paymethod_id && (validations.storno_payments[payment.id].paymethod_id[0] || validations.storno_payments[payment.id].paymethod_id))}

                                                                    >
                                                                        {/* <option></option> */}
                                                                        {(paymentMethods || []).map(method =>
                                                                            <option key={method.id} value={method.id}>{method?.translation?.name || method.name}</option>
                                                                        )}
                                                                    </Form.Select>
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {validations && validations.storno_payments && validations.storno_payments[payment.id] && validations.storno_payments[payment.id].paymethod_id && (validations.storno_payments[payment.id].paymethod_id[0] || validations.storno_payments[payment.id].paymethod_id)}
                                                                    </Form.Control.Feedback>
                                                                </FloatingLabel>
                                                            </Col>
                                                        </Row>
                                                    </Tab>
                                                ))}
                                        </Tabs>
                                    </>
                                    :
                                    ''
                                }
                            </>
                        }

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            className="save"
                        >
                            Потвърди
                        </SaveButton>
                    </Modal.Footer>
                </form>
            </Modal >
        </>
    )
}

export default forwardRef(Close);
