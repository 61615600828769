import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import { Modal, Button, Col, Row, FloatingLabel, Form, Table, } from 'react-bootstrap';

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//providers
import { useLanguageContext } from 'providers/Language'
import { useNavigate } from 'react-router-dom';

//misc
import Question from 'components/modals/Question';
import SaveButton from 'components/misc/Button'
import Info from 'components/modals/Info';
import Autocomplete from 'components/misc/Autocomplete';
import HelpInfo from 'components/misc/Info';
import moment from 'moment';
import PriceValue from 'components/partials/PriceValue';

function NewStorno(props, ref) {

    const navigate = useNavigate();

    const defaultData = {

    }

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        refresh: false,
        data: defaultData,
        loading: false,
        loadingBase: false,
        loadingApply: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null,
        onClose: null,
    });

    const [validations, setValidations] = useValidation();

    const formRef = useRef(null);

    useImperativeHandle(ref, () => ({
        open: (id) => {
            open(id);
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        }
    }));


    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const open = (id) => {
        setState(prev => ({
            ...prev,
            data: defaultData,
            id: id,
        }));

        show();
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
            subjectId: null,
            subjectType: null
        }));

        setValidations(null);

        if (typeof state.onClose === 'function') {
            hide(state.onClose);
        } else {
            hide();
        }
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            refresh: new Date().getTime(),
        }));
    }

    const handleSave = e => {
        e.preventDefault();

        if (state.loading) {
            return;
        }

        setState(prev => ({
            ...prev,
            loading: true,
            loadingBase: true,
            data: {
                ...prev.data,
                card: null,
                error: null,
            }
        }));

        setValidations(null);

        let url = 'accounts/find-account-for-storno';

        let data = new FormData(e.target);

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    account: res.data.account,
                }
            }));
        }).catch(error => {
            const _err = error.response;

            if (_err && _err.status && _err.status === 422) {
                setValidations(_err.data.errors);
            }
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
                loadingBase: false,
                loadingApply: false,
            }));
        });
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleDateChange = e => {
        const name = e.target.name + '_ymd'
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleApply = e => {
        e.preventDefault();

        let url = '/accounts/new?from_account_id=' + state.data.account.id + '&storno=1';

        let form = formRef.current;

        form.querySelectorAll('input').forEach(el => {
            if (el.checked) {
                url += '&' + el.getAttribute('name') + '=' + el.getAttribute('value');
            }
        });

        navigate(url);
    }

    const toggleAll = (e) => {
        let form = formRef.current;

        let checked = e.target.checked;

        form.querySelectorAll('input').forEach(el => {
            el.checked = checked;
        });
    }

    return (
        <>

            <Modal size="lg" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <form ref={formRef} onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Сторно по сметка
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Row>
                            <Col>
                                <FloatingLabel label="Номер">
                                    <Form.Control
                                        type="text"
                                        name={`num`}
                                        autoComplete="new-password"
                                        value={state.data.num || ''}
                                        placeholder="Номер"
                                        onChange={(e) => handleInputChange(e)}
                                        isInvalid={Boolean(validations?.num)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {validations?.num && (validations?.num[0] || validations?.num)}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>

                        {state.data.account &&
                            <Table className="mt-3" size="sm" bordered>
                                <tbody>
                                    <tr>
                                        <td className="th">
                                            Клиент
                                        </td>
                                        <td>
                                            {state.data.account?.client?.name}
                                        </td>
                                        <td className="th">
                                            Дата
                                        </td>
                                        <td>
                                            {state.data.account.created_at ? moment(state.data.account.created_at).format('DD.MM.YYYY HH:mm') : ''}
                                        </td>
                                        <td className="th">
                                            Потребител
                                        </td>
                                        <td>
                                            {state.data.account?.creator?.username}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="th">
                                            Търговски обект
                                        </td>
                                        <td>
                                            {state.data.account?.store?.name}
                                        </td>
                                        <td className="th">
                                            Каса
                                        </td>
                                        <td>
                                            {state.data.account?.cashdesk?.name}
                                        </td>
                                        <td className="th">
                                            Начин на плащане
                                        </td>
                                        <td>
                                            {state.data.account?.paymentmethod?.name}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        }

                        {state.data.account &&
                            <Table className="mt-3 xxl" size="sm" bordered>
                                <thead>
                                    <tr>
                                        <th>
                                            <input
                                                type="checkbox"
                                                onClick={e => toggleAll(e)}
                                            />
                                        </th>
                                        <th>
                                            Артикул
                                        </th>
                                        <th>
                                            Количество
                                        </th>
                                        <th>
                                            Сторнирано
                                        </th>
                                        <th>
                                            Продажна цена
                                        </th>
                                        <th>
                                            Отстъпка
                                        </th>
                                        <th>
                                            Общо
                                        </th>
                                        <th>
                                            Поръчано на
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {state.data.account.rows.map(r =>
                                        <tr key={r.id}>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    name="rows[]"
                                                    value={r.id}
                                                />
                                            </td>
                                            <td>
                                                {r.article?.article_name}
                                            </td>
                                            <td>
                                                {r.amount || 0} {r.article?.amount_type_short}
                                            </td>
                                            <td>
                                                {r.storno_amount || 0} {r.article?.amount_type_short}
                                            </td>
                                            <td>
                                                <PriceValue prefix={state.data.account?.currency?.prefix} sufix={state.data.account?.currency?.sufix}>{r.total_sum}</PriceValue>
                                            </td>
                                            <td>
                                                {r.discount || 0}%
                                            </td>
                                            <td>
                                                <PriceValue prefix={state.data.account?.currency?.prefix} sufix={state.data.account?.currency?.sufix}>{r.total_real}</PriceValue>
                                            </td>
                                            <td>
                                                {r.created_at ? moment(r.created_at).format('DD.MM.YYYY HH:mm') : ''}
                                            </td>
                                        </tr>
                                    )}
                                    <tr>
                                        <td colSpan={6}>
                                            Общо
                                        </td>
                                        <td>
                                            <PriceValue prefix={state.data.account?.currency?.prefix} sufix={state.data.account?.currency?.sufix}>{state.data.account.rows.map(r => r.total_real).reduce((prev, curr) => prev + curr, 0)}</PriceValue>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        }

                        {state.data.account &&
                            <div className="mt-3" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <SaveButton
                                    loading={state.loadingApply}
                                    disabled={state.loading}
                                    className="save"
                                    text="Сторнирай"
                                    onClick={handleApply}
                                />
                            </div>
                        }

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loadingBase}
                            disabled={state.loading}
                            className="save"
                            text="Провери"
                        />
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default forwardRef(NewStorno);
