import { createContext, useContext, useEffect, useState } from "react"

export const PermissionsContext = createContext({});

const PermissionsProvider = props => {

    const [state, setState] = useState({
        checkedRole: {},
        checkedGroup: {},
        checkedPermission: {},
    })

    const updateState = (data) => {
        setState(prev => ({
            ...prev,
            checkedRole: data.checkedRole,
            checkedGroup: data.checkedGroup,
            checkedPermission: data.checkedPermission
        }))
    }

    const handlePermissionChecked = (modules, roles, permissionsRoles) => {
        let permissionObject = {}
        let permissionRoleObject = {}
        let permissionGroupObject = {}
        modules.map(data => {
            data.permissions.map(p => {
                roles.map(r => {

                    let checked = false;
                    permissionsRoles.map(pr => {
                        if (p.id == pr.permission_id && pr.role_id == r.id) {
                            checked = true;
                        }
                    })

                    let name = 'permission-' + p.id + '-' + r.id
                    permissionObject[name] = checked ? true : false
                })
            })

            roles.map(r => {
                let count = 0;
                data.permissions.map(p => {
                    let name = 'permission-' + p.id + '-' + r.id
                    let groupName = 'group-' + data.id + '-' + r.id

                    if (permissionObject[name]) {
                        count++
                    }

                    if (count == data.permissions.length) {
                        permissionGroupObject[groupName] = true;
                    } else {
                        permissionGroupObject[groupName] = false;
                    }
                })
            })
        });

        roles.map(r => {
            let checkedGroup = 0;
            modules.map(data => {
                let count = 0;
                data.permissions.map(p => {
                    let name = 'permission-' + p.id + '-' + r.id
                    let groupName = 'group-' + data.id + '-' + r.id

                    if (permissionObject[name]) {
                        count++
                    }

                    if (count == data.permissions.length) {
                        checkedGroup++
                    }
                })
            })

            let roleName = 'role-' + r.id
            if (checkedGroup == modules.length) {
                permissionRoleObject[roleName] = true;
            } else {
                permissionRoleObject[roleName] = false;
            }

        })

        setState(prev => ({
            ...prev,
            checkedPermission: permissionObject,
            checkedGroup: permissionGroupObject,
            checkedRole: permissionRoleObject
        }))
    }

    const exportedData = {
        handlePermissionChecked,
        updateState,
        state,
    }

    return <PermissionsContext.Provider value={exportedData} {...props} />;
};

export const usePermissionsContext = () => useContext(PermissionsContext);

export default PermissionsProvider;
