import { Card } from 'react-bootstrap';
import Versions from './partials/Versions';
import Accounts from './partials/Accounts';

function Index(props) {

    return (
        <>
            <Card className="mb-3">
                <Card.Header>
                    Свързани сметки
                </Card.Header>
                <Card.Body>
                    <Accounts
                        id={props.id}
                        showAccount={props.showAccount}
                        linkAccount={props.linkAccount}
                        deleteAccount={props.deleteAccount}
                        showClient={props.showClient}
                    />
                </Card.Body>
            </Card>

            <Card className="">
                <Card.Header>
                    Нови версии от този документ
                </Card.Header>
                <Card.Body>
                    <Versions
                        id={props.id}
                        showDocument={props.showDocument}
                    />
                </Card.Body>
            </Card>
        </>
    )
}

export default Index;