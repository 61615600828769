import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter as Router } from "react-router-dom";
import AppProvider from './providers/App';
import AuthDataProvider from './providers/Auth';
import LanguageProvider from './providers/Language';
import CurrencyProvider from './providers/Currency';
import TranslationProvider from 'providers/Translation';
import reportWebVitals from './reportWebVitals';

const history = require("history").createLocation;

ReactDOM.render(
    <Router history={history}>
        <AuthDataProvider>
            <LanguageProvider>
                <CurrencyProvider>
                    <TranslationProvider>
                        <AppProvider>
                            <App />
                        </AppProvider>
                    </TranslationProvider>
                </CurrencyProvider>
            </LanguageProvider>
        </AuthDataProvider>
    </Router>
    ,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
