import { createContext, useContext, useEffect, useState } from "react";
import FlashMessage from "components/layout/FlashMessage";
import Alert from "components/misc/Alert";
import Toast from "components/misc/Toast";
import LockedFilter from "models/LockedFilter";
import { cloneDeep } from "lodash";
import LockedFilters from "models/LockedFilters";
import { Wifi, WifiOff } from "react-bootstrap-icons";
import { Button } from "react-bootstrap";
import { isMac } from "helpers/Window";

export const AppContext = createContext({});

const message = {
    component: Alert,
    title: 'title',
    body: 'test',
    variant: 'success'
};

const toast = {
    component: Toast,
    title: 'title',
    body: 'test',
    icon: null,
    variant: 'light'
};

const AppProvider = props => {

    const [state, setState] = useState({
        messages: [],
        lockedFilters: {},
        onLine: null
    });

    useEffect(() => {
        let filters = {}

        LockedFilters.getFilters().map(filter => {
            filters[filter.key] = filter;
        });

        setState(prev => ({
            ...prev,
            lockedFilters: filters
        }));
    }, []);

    useEffect(() => {
        setState(prev => ({
            ...prev,
            onLine: window.navigator.onLine
        }));

        const online = e => {
            setIsOnline(true);

            removeMessage('no-connection');

            showToast(
                'Интернет връзката е възстановена!',
                <Button variant="dark" onClick={e => window.location.reload()}>Презареди</Button>,
                <Wifi />,
                'connected'
            );
        }

        const offline = e => {
            setIsOnline(false);

            removeMessage('connected');

            showToast(
                'Няма интернет връзка!',
                'Възможно е някои функции да не работят. Проверете връзката и обновете страницата!',
                <WifiOff />,
                'no-connection'
            );

        }

        if (!isMac()) {
            window.addEventListener('online', online);
            window.addEventListener('offline', offline);
        }

        return () => {
            if (!isMac()) {
                window.removeEventListener('online', online);
                window.removeEventListener('offline', offline);
            }
        }

    }, []);

    const setIsOnline = onLine => {
        setState(prev => ({
            ...prev,
            onLine: onLine
        }));
    }

    const isOnline = () => {
        return state.onLine;
    }

    const showSuccess = (text = 'Действието е успешно!') => {
        let msg = Object.assign({}, message);

        msg.variant = 'success';
        msg.title = null;
        msg.body = text;

        setState(prev => ({
            ...prev,
            messages: prev.messages.concat(msg)
        }));

        clearMessage();
    }

    const showError = (text = 'Нещо се обърка!') => {
        let msg = Object.assign({}, message);

        msg.variant = 'danger';
        msg.title = null;
        msg.body = text;

        setState(prev => ({
            ...prev,
            messages: prev.messages.concat(msg)
        }));

        clearMessage();
    }

    const showWarning = (text = '') => {
        let msg = Object.assign({}, message);

        msg.variant = 'warning';
        msg.title = null;
        msg.body = text;

        setState(prev => ({
            ...prev,
            messages: prev.messages.concat(msg)
        }));

        clearMessage();
    }

    const showToast = (title = '', text = 'Нещо се обърка!', icon = null, key = null) => {
        let msg = Object.assign({}, toast);

        msg.title = title;
        msg.body = text;
        msg.icon = icon;
        msg.key = key;

        setState(prev => ({
            ...prev,
            messages: prev.messages.concat(msg)
        }));

        clearMessage();
    }

    const clearMessage = () => {
        setTimeout(() => {
            setState(prev => ({
                ...prev,
                messages: prev.messages.filter((m, i) => i !== 0)
            }));
        }, 5000);
    }

    const removeMessage = (key) => {
        setState(prev => ({
            ...prev,
            messages: prev.messages.filter((m, i) => m.key !== key)
        }));
    }

    /**
     * 
     * @param {String} key 
     * @returns {LockedFilter}
     */
    const getLockedFilter = (key) => {
        // const filters = cloneDeep(state.lockedFilters);
        const filters = (state.lockedFilters);

        if (!filters[key]) {
            filters[key] = new LockedFilter(key);
        }

        const filter = filters[key];

        return filter;
    }

    const lockFilter = (key) => {
        const filter = getLockedFilter(key);

        if (filter.storage === null) {
            filter.setStorage('session');
        }

        else if (filter.storage === 'session') {
            filter.setStorage('local');
        }

        else if (filter.storage === 'local') {
            filter.setStorage(null);
        }

        setState(prev => ({
            ...prev,
            lockedFilters: {
                ...prev.lockedFilters,
                [key]: filter
            }
        }));

    }

    const exportedData = {
        showSuccess,
        showError,
        showWarning,
        getLockedFilter,
        lockFilter,
        isOnline,
        state,
    }

    return <AppContext.Provider value={exportedData} {...props} />;
};

export const useAppContext = () => useContext(AppContext);

export default AppProvider;
