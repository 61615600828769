import { useState, useImperativeHandle, forwardRef } from 'react'
import { Modal, Button, Row, Col, FloatingLabel, Form } from 'react-bootstrap';
import { createPortal } from 'react-dom';

import SaveButton from 'components/misc/Button'
import { buildUrl } from 'helpers/Url';
import IFrame from 'components/partials/Iframe';
import Loader from 'components/misc/Loader';

function Print(props, ref) {

    const defaultData = {
        type: 'print-html'
    }

    const [state, setState] = useState({
        show: false,
        params: null,
        data: defaultData,
        onSuccess: null,
        onClose: null,
        onEntering: null,
        onExiting: null,
    });

    useImperativeHandle(ref, () => ({
        open: () => {
            handleShow();
        },
        close: () => {
            handleClose();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        },
        onCancel: fn => {
            setState(prev => ({
                ...prev,
                onCancel: fn
            }));
        }
    }));

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const handleShow = () => {
        show();
    }


    const handleClose = () => {
        hide();
    }

    const handleSave = e => {
        e.preventDefault();

        // setState(prev => ({
        //     ...prev,
        //     loading: true,
        // }));



        // window.open(full);

    }

    const getUrl = () => {
        let url = process.env.REACT_APP_URL + '/api/' + props.url;
        let filter = props.filter;

        let full = buildUrl(url, {
            ...filter,
            type: state.data.type,
            export: 1
        });

        // console.log(full);

        return full;
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: value
            }
        }))
    }

    const style = {
        button: {
            margin: '0 10px',
            minWidth: '120px'
        }
    }

    return createPortal(
        <Modal size="xl" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
            <form onSubmit={handleSave}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Отпечатване
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="mb-3">
                        <Col>
                            <FloatingLabel label="Тип">
                                <Form.Select
                                    name="type"
                                    value={state.data.type || 'excel'}
                                    onChange={handleInputChange}
                                >
                                    <option value="print-html">HTML (.html)</option>
                                    <option value="print-pdf">Print format (.pdf)</option>
                                </Form.Select>
                            </FloatingLabel>
                        </Col>
                    </Row>

                    <IFrame key={getUrl()} src={getUrl()} style={{ height: '40vh' }}>
                        <Loader />
                    </IFrame>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Отказ
                    </Button>
                    <SaveButton
                        loading={state.loading}
                        className="save"
                    />
                </Modal.Footer>
            </form>
        </Modal>,
        document.body
    )

}

export default forwardRef(Print);