import { forwardRef, useImperativeHandle, useState } from "react";
import { PrinterFill } from "react-bootstrap-icons";
import SaveButton from './Button';
import printJS from 'print-js'
import { useAppContext } from "providers/App";

function PrintButton(props, ref) {

    const app = useAppContext();

    const url = props.url;
    const onClick = props.onClick || null;
    const variant = props.variant || 'dark';
    const size = props.size || '';

    const [isPrintLoading, setIsPrintLoading] = useState(false);

    useImperativeHandle(ref, () => ({
        print: print
    }));

    const handlePrint = e => {
        e.preventDefault();

        print(url);

    }

    const print = url => {
        if (!url) {
            return app.showError('Не е зададен URL');
        }

        printJS({
            printable: url,
            onLoadingStart: () => {
                setIsPrintLoading(true);
            },
            onLoadingEnd: () => {
                setIsPrintLoading(false);
            },
            onError: () => {
                app.showError('Възникна грешка при заявката за принтиране');
            },
            onIncompatibleBrowser: () => {
                app.showError('Вашият браузър не поддържа тази функционалност');
            }
        });
    }

    return (
        <SaveButton
            variant={variant}
            size={size}
            loading={isPrintLoading}
            onClick={onClick || handlePrint}
            style={props.style || {}}
        >
            {props.children || <PrinterFill />}
        </SaveButton>
    )
}

export default forwardRef(PrintButton);