import { Dropdown } from 'react-bootstrap';

function Options(props) {
    return (
        <>
            {props.data.allow_edit &&
                <Dropdown.Item onClick={e => props.handleEdit(props.data.id)}>
                    Редакция
                </Dropdown.Item>
            }
            {props.data.allow_delete &&
                <Dropdown.Item onClick={e => props.handleDelete(props.data.id)}>
                    Изтриване
                </Dropdown.Item>
            }
        </>
    )
}

export default Options;