import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import moment from "moment"
import { FloatingLabel, Form, Dropdown, Row, Col } from 'react-bootstrap'

//providers
import { useAuthDataContext } from "providers/Auth"

//helpers
import { useQuery } from 'helpers/Url'
import Api from 'helpers/Api'
import Loader from 'components/misc/Loader'
import Autocomplete from 'components/misc/Autocomplete'
import Category from 'components/partials/filter/Category'
import DatePicker from 'components/partials/filter/DatePicker'

function Filter(props) {
    const history = useNavigate()
    const query = useQuery()

    const auth = useAuthDataContext();

    const [filters, setFilters] = useState({
        show: false,
    });

    useEffect(() => {
        //
    }, []);


    const handleSearch = e => {
        const name = e.target.name
        const value = e.target.value

        props.handleSearch(name, value)
    }

    const handleCheckboxChange = e => {
        const name = e.target.name
        const value = e.target.checked ? 1 : 0

        props.handleSearch(name, value)
    }

    return (
        <div className="row page-filter">
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <FloatingLabel label="Търсене">
                    <Form.Control
                        type="text"
                        placeholder="Търсене"
                        name="search"
                        value={props.filter.search || ''}
                        onChange={handleSearch}
                        autoFocus
                    />
                </FloatingLabel>
            </div>
            <div
                className="col col-auto mb-2"
            >
                <Form.Check
                    type="switch"
                    name="bad_title"
                    label="Непрепоръчителна дължина - title"
                    checked={Number(props.filter.bad_title) === 1}
                    onChange={handleCheckboxChange}
                />
            </div>
            <div
                className="col col-auto mb-2"
            >
                <Form.Check
                    type="switch"
                    name="bad_meta_description"
                    label="Непрепоръчителна дължина - meta description"
                    checked={Number(props.filter.bad_meta_description) === 1}
                    onChange={handleCheckboxChange}
                />
            </div>
        </div>
    )
}

export default Filter;