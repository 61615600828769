import { Dropdown } from 'react-bootstrap';

function Options(props) {
    return (
        <>
            <Dropdown.Item onClick={e => props.handleEdit(props.data.id)}>
                Редакция
            </Dropdown.Item>
            <Dropdown.Item onClick={e => props.handleDelete(props.data.id)}>
                Изтриване
            </Dropdown.Item>
        </>
    )
}

export default Options;