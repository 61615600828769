import axios from "axios";
import { useEffect, useState } from "react";
import { Pie } from '@ant-design/plots';
import Api from "helpers/Api";
import Alert from "components/misc/Alert";

const fakeData = [
    {
        type: 'Кеш',
        value: 120
    }
]

function Paymethods(props) {
    const [state, setState] = useState({
        loading: true,
        refresh: false,
        setFilter: false,
        data: [

        ],
        filter: {

        },
        isFake: false,
        chart: null,
        request: null,
    });

    const [data, setData] = useState([]);

    useEffect(() => {
        if (props.refresh) {
            loadData();
        }
    }, [state.refresh, props.refresh]);

    useEffect(() => {
        if (state.loading) {
            return;
        }

        let data = [];

        state.data.map(c => {
            let group = {
                type: c.type,
                value: c.value || 0,
            };

            data.push(group);
        });

        setData(data);
    }, [state.data])

    const loadData = () => {
        if (state.request) {
            state.request.cancel();
        }

        let request = axios.CancelToken.source();

        setState(prev => ({
            ...prev,
            request: request,
            loading: true,
        }));

        let url = 'dashboard/totals-by-paymethods';

        Api.get(url, {
            params: state.filter,
            cancelToken: request.token
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data.items.length ? res.data.items : [],
                isFake: res.data.items.length ? false : true,
                currency: res.data.currency,
                loading: false,
                sorting: false,
                filter: {
                    ...prev.filter,
                    ...res.data.filter,
                }
            }));



        });
    }

    const setChart = chart => {
        setState(prev => ({
            ...prev,
            chart: chart
        }));
    }

    // console.log(data);

    const getHeight = () => {
        if (state.data.length > 5) {
            return 200;
        }

        return 250;
    }

    const config = {
        appendPadding: 10,
        data,
        height: getHeight(),
        angleField: 'value',
        colorField: 'type',
        radius: 1,
        innerRadius: 0.7,
        label: {
            type: 'inner',
            offset: '-50%',
            content: '{value} ' + state.currency?.sufix,
            style: {
                textAlign: 'center',
            },
            autoRotate: false,
        },
        interactions: [
            {
                type: 'element-selected',
            },
            {
                type: 'element-active',
            },
        ],
        statistic: {
            title: false,
            content: {
                style: {
                    whiteSpace: 'pre-wrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                content: '',
            },
        },
        legend: false,
        // theme: {
        //     colors10: [
        //         "#025DF4",
        //         "#DB6BCF",
        //         "#2498D1",
        //         "#BBBDE6",
        //         "#4045B2",
        //         "#21A97A",
        //         "#FF745A",
        //         "#007E99",
        //         "#FFA8A8",
        //         "#2391FF"
        //     ],
        //     colors20: [
        //         "#025DF4",
        //         "#DB6BCF",
        //         "#2498D1",
        //         "#BBBDE6",
        //         "#4045B2",
        //         "#21A97A",
        //         "#FF745A",
        //         "#007E99",
        //         "#FFA8A8",
        //         "#2391FF",
        //         "#FFC328",
        //         "#A0DC2C",
        //         "#946DFF",
        //         "#626681",
        //         "#EB4185",
        //         "#CD8150",
        //         "#36BCCB",
        //         "#327039",
        //         "#803488",
        //         "#83BC99"
        //     ]
        // }
    };

    const getColor = index => {
        if (!state.chart) {
            return;
        }

        let theme = state.chart.chart.themeObject;

        let palette;

        if (state.data.length > 10) {
            palette = theme.colors20;
        } else {
            palette = theme.colors10;
        }

        return palette[index];
    }

    // console.log(state.chart.chart.themeObject)

    return (
        <div className="card card-h-100">
            <div className="d-flex card-header justify-content-between align-items-center">
                <h4 className="header-title">Оборот по начин на плащане</h4>

            </div>
            <div className="card-body chart pt-0">
                <div className="graph">
                    <Pie {...config} onReady={e => setChart(e)} />
                </div>
                <div className="chart-widget-list">
                    {state.data.map((paymethod, i) =>
                        <p key={paymethod.type} className="text-muted">
                            <i className="square" style={{ backgroundColor: getColor(i) }}></i> {paymethod.type}
                            <span className="float-end">{paymethod.value.toFixed(2)} {state.currency?.sufix}</span>
                        </p>
                    )}
                </div>
            </div>

        </div>
    )
}

export default Paymethods;