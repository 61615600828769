import { useRef } from 'react';
import Form from '../partials/Form';
import { useClientContext } from '../Provider';
import SaveButton from 'components/misc/Button';
import Vies from '../partials/Vies';

function Index(props) {

    const context = useClientContext();

    const formRef = useRef(null);

    const refresh = () => {

    }

    return (
        <>
            <Form
                ref={formRef}
                id={props.id}
                show={props.show}
                hide={props.hide}
                onSuccess={refresh}
                viesModalRef={props.viesModalRef}
            />

            <div
                className="mt-3"
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <SaveButton
                    loading={context.state?.loading}
                    className="save"
                    onClick={e => formRef.current?.save(e)}
                />
            </div>
        </>
    )
}

export default Index;