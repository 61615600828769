import { useEffect, useRef, useState } from "react";
import Autocomplete from "components/misc/Autocomplete";
import { useNestedState } from "helpers/NestedState";
import { Alert, Button, Col, Form, InputGroup, Row, FloatingLabel, Table } from "react-bootstrap";
import HelpInfo from 'components/misc/Info';
import { TrashFill, Upload } from "react-bootstrap-icons";
import Api from "helpers/Api";
import Loader from "components/misc/Loader";
import Categories from "../partials/Categories"
import './style.scss';
import TableHeader from "components/misc/TableHeader";
import TableCol from "components/misc/TableCol";
import PriceValue from "components/partials/PriceValue";
import NoDataFound from "components/misc/NoDataFound";
import Pagination from "components/misc/Pagination";
import { TYPES } from "constants/imports";
import { useAuthDataContext } from "providers/Auth";

let timeout;

function Index(props) {

    const auth = useAuthDataContext();

    // const { state, setState } = props;

    const { attributes, setAttribute } = props;

    const validations = props.validations;

    const showModal = props.show;
    const hideModal = props.hide;

    const addArticleModalRef = props.addArticleModalRef;
    const categoryModalRef = props.categoryModalRef;
    const articleDetailModalRef = props.articleDetailModalRef;
    const addArticlesFromBarsyModalRef = props.addArticlesFromBarsyModalRef;
    const questionModalRef = props.questionModalRef;
    const importModalRef = props.importModalRef;
    const tableRef = useRef(null);

    const taxGroups = props.taxGroups

    const [state, setState] = useState({
        sorting: false,
        loading: true,
        refresh: false,
        setFilter: false,
        data: [],
        pages: 0,
        total: 0,
        filter: {
            page: 1,
        },
    });

    useEffect(() => {
        loadData();
    }, [props.state.id]);

    useEffect(() => {
        if (state.refresh) {
            loadData();
        }
    }, [state.refresh]);

    const loadData = () => {

        loading(true);

        let url = 'price_rules/articles';

        Api.post(url, {
            id: props.state.id,
            ...state.filter
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data.items,
                total: res.data.total,
                pages: res.data.pages,
                filter: {
                    ...prev.filter,
                    ...res.data.filter,
                }
            }));

            if (res.data.pages > 0 && state.filter.page > res.data.pages) {
                return handlePage({ selected: res.data.pages - 1 });
            }

            loading(false);
            sorting(false);
        });
    }

    const handleInputChange = (key, val, index) => {
        setState(prev => ({
            ...prev,
            data: Object.values({
                ...prev.data,
                [index]: {
                    ...prev.data[index],
                    [key]: val
                }
            })
        }));


        // да запазим въведената цена за артикула
        // дори и при смяна на филтъра или страницата
        let row = getRow(index);

        // console.log(row);

        if (row) {
            setAttribute(row.id, key, val);
        }
    }

    const getRow = index => {
        return state.data.find((k, i) => Number(i) === Number(index));
    }

    const getAttribute = (row, key) => {
        let attrs = attributes[row.id];

        if (attrs && attrs[key]) {
            return attrs[key];
        }

        let val = row[key];

        return val;
    }

    const removeArticle = (rowId) => {
        hideModal(() => {
            let modal = questionModalRef.current;

            if (modal) {
                modal.open('Сигурни ли сте, че искате да премахнете този артикул?');

                modal.onSuccess(() => {
                    Api.post('price_rules/articles/remove', {
                        id: props.state.id,
                        row_id: rowId,
                    }).finally(() => {
                        showModal();
                    });
                });

                modal.onCancel(() => {
                    showModal();
                })
            }
        });
    }

    const addArticle = () => {
        let modal = addArticleModalRef.current;

        if (!modal) {
            return;
        }

        hideModal(() => {
            modal.add(props.state.id);
        });

        modal.onSuccess(() => {
            showModal();
        });

        modal.onClose(() => {
            showModal();
        })
    }

    const handleCategory = () => {
        let modal = categoryModalRef.current;

        if (!modal) {
            return;
        }

        hideModal(() => {
            modal.add();
        });

        modal.onSuccess(() => {
            showModal();
        });

        modal.onClose(() => {
            showModal();
        })
    }

    const handleArticleDetails = () => {
        let modal = articleDetailModalRef.current;

        if (!modal) {
            return;
        }

        hideModal(() => {
            modal.add();
        });

        modal.onSuccess(() => {
            showModal();
        });

        modal.onClose(() => {
            showModal();
        })
    }

    const addArticlesFromBarsy = () => {
        let modal = addArticlesFromBarsyModalRef.current;

        if (!modal) {
            return;
        }

        hideModal(() => {
            modal.add(props.state.id);
        });

        modal.onSuccess(() => {
            showModal();
        });

        modal.onClose(() => {
            showModal();
        })
    }

    const handleImport = () => {
        let modal = importModalRef.current;

        if (!modal) {
            return;
        }

        hideModal(() => {
            modal.open();
        });

        modal.setParams({
            id: props.state.id,
        });

        // няма нужда да се презарежда заявката ръчно,
        // защото тя се зарежда при показване на модала
        modal.onSuccess(() => {
            // alert(1);
        });

        modal.onClose(() => {
            showModal();
        })
    }

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }));
    }

    const sorting = (sorting) => {
        setState(prev => ({
            ...prev,
            sorting: Boolean(sorting)
        }));
    }

    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout);

        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [key]: val,
            },
        }));

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: 1
                },
                refresh: new Date().getTime()
            }));
        }, delay);
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            refresh: new Date().getTime()
        }));
    }

    const handleSort = (sort, order) => {
        setState(prev => ({
            ...prev,
            sorting: true,
            filter: {
                ...prev.filter,
                sort: sort,
                order: order,
                page: 1,
            },
            refresh: new Date().getTime()
        }));
    }

    const showArticle = id => {
        props.showArticle(id);
    }

    const getImportType = (type) => {
        return {
            0: 'Категории',
            1: 'Ръчно',
            2: 'Импорт',
            3: 'Характеристики',
        }[type] || '';
    }

    return (
        <>
            {!props.state.id
                ?
                <NoDataFound>
                    Ще можете да добавите артикули след като добавите ценовото правило
                </NoDataFound>
                :
                <>
                    <div style={{ marginBottom: '20px' }}>
                        <InputGroup size="sm">
                            <Form.Control
                                type="search"
                                name="search"
                                value={state.filter.search || ''}
                                size="sm"
                                onChange={e => handleSearch(e.target.name, e.target.value)}
                                placeholder="Търсене"
                            />
                        </InputGroup>
                    </div>

                    {state.loading && !state.sorting ?
                        <Loader />
                        :
                        <>
                            {state.data.length === 0 &&
                                <NoDataFound></NoDataFound>
                            }

                            {state.data.length > 0 &&
                                <>
                                    <Table className="xxl" striped hover ref={tableRef}>
                                        <TableHeader
                                            tableRef={tableRef}
                                            activeSortKey={state.filter.sort}
                                            activeSortDir={state.filter.order}
                                            onSort={(col, dir) => handleSort(col, dir)}
                                        >
                                            <TableCol sortable sortKey="id" style={{ width: '3%' }}>ID</TableCol>
                                            <TableCol sortable sortKey="article_name" style={{ width: '15%' }}>Артикул</TableCol>
                                            <TableCol className="text-center" sortable sortKey="actual_price" style={{ width: '10%' }}>Базова <br /> цена</TableCol>
                                            {auth.getUser().permission('show_delivery_price') &&
                                                <TableCol className="text-center" sortable sortKey="avg_delivery_price_with_vat" style={{ width: '10%' }}>Себестойност <br /> с ДДС</TableCol>
                                            }
                                            <TableCol className="text-center" sortable sortKey="with_tax" style={{ width: '5%' }}>с ДДС</TableCol>
                                            <TableCol className="text-center" sortable sortKey="tax_id" style={{ width: '7%' }}>ДДС <br /> група</TableCol>
                                            <TableCol className="text-center" sortable sortKey="price" style={{ width: '10%' }}>Изчислена <br /> цена</TableCol>
                                            <TableCol className="text-center" sortable sortKey="prefered_price" style={{ width: '13%' }}>Предпочитана <br /> цена</TableCol>
                                            <TableCol className="text-center" sortable sortKey="type" style={{ width: '7%' }}>Добавен <br /> през</TableCol>
                                            <TableCol className="options" style={{ width: '5%' }}>Опции</TableCol>
                                        </TableHeader>
                                        <tbody>
                                            {state.data.map((c, index) =>
                                                <tr
                                                    key={'c-' + c.id}
                                                    className={c.deleted_at ? 'deleted' : ''}
                                                >
                                                    <td>
                                                        {c.id || ''}
                                                    </td>
                                                    <td>
                                                        <span className="link" onClick={e => showArticle(c.article_id)}>
                                                            {c.article?.article_name}
                                                        </span>
                                                    </td>
                                                    <td className="text-center">
                                                        {c.base_price !== null &&
                                                            <>
                                                                <PriceValue prefix={c.article?.currency?.prefix} sufix={c.article?.currency?.sufix}>{c.base_price}</PriceValue>
                                                            </>
                                                        }
                                                    </td>
                                                    {auth.getUser().permission('show_delivery_price') &&
                                                        <td className="text-center">
                                                            <PriceValue prefix={c.article?.currency?.prefix} sufix={c.article?.currency?.sufix}>{c.article?.avg_delivery_price_with_vat}</PriceValue>
                                                        </td>
                                                    }
                                                    <td className="text-center">
                                                        {c.with_tax ? 'ДА' : 'НЕ'}
                                                    </td>
                                                    <td className="text-center">
                                                        {c.article?.tax_group?.name}
                                                    </td>
                                                    <td className="text-center">
                                                        {c.price !== null &&
                                                            <>
                                                                <PriceValue prefix={c.article?.currency?.prefix} sufix={c.article?.currency?.sufix}>{c.price}</PriceValue>
                                                            </>
                                                        }
                                                    </td>
                                                    <td className="text-center">
                                                        <InputGroup size="sm" style={{ minWidth: '55px' }}>
                                                            <Form.Control
                                                                type="number"
                                                                step="0.0001"
                                                                // name={`articles[${index}][prefered_price]`}
                                                                value={getAttribute(c, 'prefered_price') || ''}
                                                                onChange={e => handleInputChange('prefered_price', e.target.value, index)}
                                                                isInvalid={Boolean(validations && validations.articles && validations.articles[index] && validations.articles[index].prefered_price && (validations.articles[index].prefered_price[0] || validations.articles[index].prefered_price))}
                                                                size="sm"
                                                                className="price-input"
                                                            />

                                                            {(c.article?.currency) &&
                                                                <InputGroup.Text>
                                                                    {c.article?.currency?.prefix || c.article?.currency?.sufix || '-'}
                                                                </InputGroup.Text>
                                                            }

                                                            {/* <Form.Control.Feedback type="invalid">
                                                                        {validations && validations.rows && validations.rows[index] && validations.rows[index].amount && (validations.rows[index].amount[0] || validations.rows[index].amount)}
                                                                    </Form.Control.Feedback> */}
                                                        </InputGroup>
                                                    </td>
                                                    <td className="text-center">
                                                        {getImportType(c.type)}
                                                    </td>
                                                    <td className="options">
                                                        <Button variant="danger" size="sm" onClick={e => removeArticle(c.id)}>
                                                            <TrashFill />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>

                                    <Pagination
                                        className="mt-3"
                                        page={state.filter.page}
                                        pages={state.pages}
                                        total={state.total}
                                        handlePage={handlePage}
                                    />

                                </>
                            }

                            <div className={'mt-3'}>
                                <Button variant="outline-dark" size="sm" onClick={addArticle}>
                                    Добави артикул
                                </Button>

                                <Button variant="outline-dark" size="sm" style={{ marginLeft: '10px' }} onClick={handleCategory}>
                                    Добавяне по категории
                                </Button>

                                <Button variant="outline-dark" size="sm" style={{ marginLeft: '10px' }} onClick={handleArticleDetails}>
                                    Добавяне по характеристики
                                </Button>

                                {/* <Button variant="outline-dark" size="sm" style={{ marginLeft: '10px' }} onClick={addArticlesFromBarsy}>
                                    Добавяне от масив
                                </Button> */}

                                <Button variant="outline-dark" size="sm" onClick={handleImport} disabled={auth.getUser().permission('import_index') === false} style={{ marginLeft: '10px' }}>
                                    <Upload /> Импорт
                                </Button>
                            </div>

                        </>
                    }
                </>
            }
        </>
    )
}

export default Index;
