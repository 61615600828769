import Layout from "components/layout/Layout";
import { Button, Table } from "react-bootstrap";
import emptyFolder256 from 'assets/img/icons/forbidden-folder@256.png';
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuthDataContext } from "providers/Auth";
import Api from "helpers/Api";
import { useQuery } from "helpers/Url";

function Unauthorized() {

    const navigate = useNavigate();
    const location = useLocation();
    const auth = useAuthDataContext();

    const query = useQuery();

    const user = auth.getUser();

    const [state, setState] = useState({
        permission: null
    });

    useEffect(() => {
        let module = query.get('module');
        let permission = query.get('permission');
        let previousLocation = query.get('from');

        let hasAccess = true;

        if (module) {
            if (user) {
                if (!user.module(module)) {
                    hasAccess = false;
                }
            } else {
                hasAccess = false;
            }
        }

        if (hasAccess) {
            if (permission) {
                if (user) {
                    if (!user.hasAny(permission)) {
                        hasAccess = false;
                    }
                } else {
                    hasAccess = false;
                }
            }
        }

        if (hasAccess && previousLocation) {
            let url = new URL(previousLocation, window.location.origin);

            // да премахнем id на търговския обект
            // за да избегнем infinite loop
            url.searchParams.delete('sid');

            let redirect = url.pathname + url.search;

            navigate(redirect, {
                replace: true,
            });
        }

        if (permission) {
            loadPermission(permission);
        }

    }, [location, user?.getStoreId()]);

    const loadPermission = permission => {
        Api.get('store/common/permission', {
            params: {
                key: permission
            }
        }).then(res => {
            setState(prev => ({
                ...prev,
                permission: res.data.permission
            }));
        })
    }

    return (
        <Layout>
            <div className="container-fluid">
                <div className="panel">
                    <div style={{ marginTop: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                        {/* <img src={emptyFolder256} alt="" style={{ width: '160px' }} /> */}
                        <div style={{ textAlign: 'center', fontSize: '1.5rem', fontWeight: '300' }}>
                            Нямате достъп до тази страница!
                        </div>
                        {state.permission &&
                            <div className="mt-2" style={{ textAlign: 'center', fontSize: '1.25rem', fontWeight: '300' }}>
                                Необходими права: <u>{state.permission.module?.name} / {state?.permission?.name}</u>
                            </div>
                        }
                        <br />
                        <Table style={{ maxWidth: '500px' }}>
                            <tbody>
                                <tr>
                                    <th className="th">
                                        Потребител:
                                    </th>
                                    <th>
                                        {user?.getName()}
                                    </th>
                                </tr>
                                <tr>
                                    <th className="th">
                                        Роля:
                                    </th>
                                    <th>
                                        {user?.getRole()?.name}
                                    </th>
                                </tr>
                                <tr>
                                    <th className="th">
                                        Търговски обект:
                                    </th>
                                    <th>
                                        {user?.getStore()?.name}
                                    </th>
                                </tr>
                                <tr>
                                    <th className="th">
                                        Действие:
                                    </th>
                                    <th>
                                        <div style={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            maxWidth: '200px'
                                        }}>
                                            {query.get('from')}
                                        </div>
                                    </th>
                                </tr>
                                <tr>
                                    <th className="th">
                                        Необходими права:
                                    </th>
                                    <th>
                                        {query.get('permission')}
                                    </th>
                                </tr>
                            </tbody>
                        </Table>
                        <br />
                        <Button variant="dark" onClick={() => window.location = '/'}>
                            Начало
                        </Button>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Unauthorized;