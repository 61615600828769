import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import moment from 'moment'
import { Modal, Button, Col, Row, Card, Tabs, Tab, FloatingLabel, Form } from 'react-bootstrap';

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//providers
import { useLanguageContext } from 'providers/Language'

//misc
import SaveButton from 'components/misc/Button'
import Info from 'components/modals/Info';
import HelpInfo from 'components/misc/Info';

function AddOrEdit(props, ref) {

    const langs = useLanguageContext();
    const defaultData = {
        image: {},
        logo: {},
        banner: {},
        option: {
            langs: {}
        }
    }

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        edit: false,
        data: defaultData,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null
    });

    const [validations, setValidations] = useValidation();

    const inputImageRef = useRef(null);
    const inputBannerRef = useRef(null);
    const inputFileRef = useRef(null);
    const validationModalRef = useRef(null);

    useImperativeHandle(ref, () => ({
        add: () => {
            handleShow();
        },
        edit: (id) => {
            edit(id);
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        }
    }));

    useEffect(() => {
        loadData();
    }, [state.edit]);

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const handleShow = () => {
        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
        }));
        show();
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
        }));

        setValidations(null);

        hide();
    }

    const edit = id => {
        setState(prev => ({
            ...prev,
            id: id,
            edit: new Date().getTime()
        }));

        show();
    }

    const loadData = () => {
        if (!state.id) {
            return;
        }

        Api.get('brands_options/show?id=' + state.id)
            .then(res => {
                let data = res.data;

                data.banner = {}
                data.logo = {}
                data.image = {}

                if (data.option) {
                    data.banner = data.option.banner_url;
                    data.logo = data.option.logo_url;
                    data.image = data.option.image_url;
                }

                setState(prev => ({
                    ...prev,
                    data
                }))
            });
    }

    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = 'brands_options/update';

        let data = new FormData(e.target);

        if (state.id) {
            data.append('value_id', state.id)
        }

        if (state.data.logo.was_recently_attached) {
            data.append('logo', state.data.logo);
        }

        if (state.data.banner.was_recently_attached) {
            data.append('banner', state.data.banner);
        }

        if (state.data.image.was_recently_attached) {
            data.append('image', state.data.image);
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (typeof state.onSuccess === 'function') {
                state.onSuccess(res.data)
            }

            hide()
        }).catch(error => {
            const _err = error.response;
            if (_err && _err.status && _err.status === 422) {
                hide(() => {
                    let modal = validationModalRef.current;

                    if (modal) {
                        modal.open();

                        modal.onClose(() => {
                            setValidations(_err.data.errors)
                            show();
                        });
                    }
                });
            }

        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }));
        });
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        // setState(prev => ({
        //     ...prev,
        //     data: {
        //         ...prev.data,
        //         option: {
        //             ...prev.data.option,
        //             [name]: value
        //         }
        //     }
        // }))

        setState(name, value, 'data.option')
    }

    const handleDescriptionChange = e => {
        const value = e.target.value
        const name = e.target.name

        setState(name, value, 'data.option')
    }

    const handleShowAttach = e => {
        e.preventDefault();

        inputFileRef.current.click();
    }

    const handleAttach = e => {
        let file = e.target.files[0];
        let url = URL.createObjectURL(file);

        file.id = Math.random().toString(7).substring(2);
        file.was_recently_attached = true;
        file.url = url;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                logo: file
            }
        }));
    }

    const handleShowBannerAttach = e => {
        e.preventDefault();

        inputBannerRef.current.click();
    }

    const handleBannerAttach = e => {
        let file = e.target.files[0];
        let url = URL.createObjectURL(file);

        file.id = Math.random().toString(7).substring(2);
        file.was_recently_attached = true;
        file.url = url;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                banner: file
            }
        }));
    }

    const handleShowImageAttach = e => {
        e.preventDefault();

        inputImageRef.current.click();
    }

    const handleImageAttach = e => {
        let file = e.target.files[0];
        let url = URL.createObjectURL(file);

        file.id = Math.random().toString(7).substring(2);
        file.was_recently_attached = true;
        file.url = url;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                image: file
            }
        }));
    }


    return (
        <>
            <Info
                ref={validationModalRef}
                mainMessage="Моля, попълнете всички задължителни полета, означени с червен цвят!"
            />

            <Modal size="lg" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <form onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Марка
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Card className="mb-3">
                            <Card.Header>
                                Администрация на банер
                                <HelpInfo marginLeft="10px">
                                    Тази информация се отнася за банера, който се намира в каталог продукти.
                                </HelpInfo>
                            </Card.Header>
                            <Card.Body>

                                <Tabs
                                    defaultActiveKey={langs.data[0].id}
                                    className="mb-3"
                                    transition={false}
                                >
                                    {langs.data.map(lang =>
                                        <Tab key={lang.id} eventKey={lang.id} title={lang.name} tabClassName={validations && validations.langs && validations.langs[lang.id] ? 'validate' : ''}>
                                            <Col className="mb-3">
                                                <FloatingLabel label="Описание">
                                                    <Form.Control
                                                        as="textarea"
                                                        name={`langs[${lang.id}][description]`}
                                                        placeholder="Описание"
                                                        onChange={(e) => handleDescriptionChange(e)}
                                                        value={state.data.option?.langs?.[lang.id]?.description || ''}
                                                        style={{ height: '300px' }}
                                                        isInvalid={Boolean(validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].description)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].description && (validations.langs[lang.id].description[0] || validations.langs[lang.id].description)}
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>
                                            </Col>
                                            <Row>
                                                {/* ЛОГО */}
                                                <Col>
                                                    <div style={{ height: !state.data.logo?.url ? '0' : 'auto' }} className={state.data.logo?.url ? "mb-3" : ""}>
                                                        <>
                                                            <div className="file">
                                                                <img style={{ maxHeight: '100px' }} src={state.data.logo.url} onError={e => e.target.classList.add('hidden')} />
                                                            </div>

                                                            <div className="validate">
                                                                {validations?.image}
                                                            </div>
                                                        </>
                                                    </div>

                                                    <Button variant="outline-dark" size="sm" onClick={handleShowAttach}>
                                                        Прикачете лого
                                                    </Button>

                                                    <input ref={inputFileRef} type="file" accept=".png,.jpeg,.jpg" onChange={handleAttach} hidden />
                                                </Col>

                                                {/* БАНЕР */}
                                                <Col>
                                                    <div style={{ height: !state.data.banner?.url ? '0' : 'auto' }} className={state.data.banner?.url ? "mb-3" : ""}>
                                                        <>
                                                            <div className="file">
                                                                <img style={{ maxHeight: '100px' }} src={state.data.banner.url} onError={e => e.target.classList.add('hidden')} />
                                                            </div>

                                                            <div className="validate">
                                                                {validations?.image}
                                                            </div>
                                                        </>
                                                    </div>

                                                    <Button variant="outline-dark" size="sm" onClick={handleShowBannerAttach}>
                                                        Прикачете банер
                                                    </Button>

                                                    <input ref={inputBannerRef} type="file" accept=".png,.jpeg,.jpg" onChange={handleBannerAttach} hidden />
                                                </Col>
                                            </Row>
                                        </Tab>
                                    )}
                                </Tabs>

                            </Card.Body>
                        </Card>

                        <Card>
                            <Card.Header>


                                <Row>
                                    <Col xs="auto">
                                        Секция марки (начална страница)
                                        <HelpInfo marginLeft="10px">
                                            Отнася се за секцията, изобразяваща всички марки на началната страница.
                                            <br />
                                            Имайте предвид, че марки без прикачено изображение няма да се показват дори да бъдат избрани.
                                        </HelpInfo>
                                    </Col>
                                    <Col xs="auto" style={{ marginLeft: 'auto' }}>
                                        <label>
                                            Показване
                                        </label>
                                        <div className="custom-radio" style={{ marginLeft: '3px' }}>
                                            <label>
                                                <input type="radio" name="show_homepage" value={0} checked={Number(state.data.option?.show_homepage) === 0} onChange={handleInputChange} />
                                                <span>
                                                    НЕ
                                                </span>
                                            </label>
                                            <label>
                                                <input type="radio" name="show_homepage" value={1} checked={Number(state.data.option?.show_homepage) === 1} onChange={handleInputChange} />
                                                <span>
                                                    ДА
                                                </span>
                                            </label>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                <Col>
                                    <div style={{ height: !state.data.image?.url ? '0' : 'auto' }} className={state.data.image?.url ? "mb-3" : ""}>
                                        <>
                                            <div className="file">
                                                <img style={{ maxHeight: '100px' }} src={state.data.image?.url} onError={e => e.target.classList.add('hidden')} />
                                            </div>

                                            <div className="validate">
                                                {validations?.image}
                                            </div>
                                        </>
                                    </div>

                                    <Button variant="outline-dark" size="sm" onClick={handleShowImageAttach} disabled={Number(state.data.option?.show_homepage) === 0}>
                                        Прикачете изображение
                                    </Button>

                                    <input ref={inputImageRef} type="file" accept=".png,.jpeg,.jpg" onChange={handleImageAttach} hidden />
                                </Col>
                            </Card.Body>
                        </Card>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            className="save"
                        />
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default forwardRef(AddOrEdit);
