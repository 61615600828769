import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import { Modal, Button, Tabs, Tab, FloatingLabel, Form, Row, Col, Table } from 'react-bootstrap';

//helpers
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//providers
import { useLanguageContext } from 'providers/Language'

//misc
import Autocomplete from 'components/misc/Autocomplete';
import SaveButton from 'components/misc/Button'
import Info from 'components/modals/Info';
import HelpInfo from 'components/misc/Info'

import { calcDiscount } from 'helpers/Price';
import Refs from 'Refs';
import moment from 'moment';
import PriceValue from 'components/partials/PriceValue';

function SelectPrepaidPayments(props, ref) {

    const langs = useLanguageContext();

    const defaultData = {
        account: null,
        payments: [],
        totalSum: null,
        usedSum: null,
        currency: null,
    }

    const data = props.data || defaultData;

    const [state, setState] = useNestedState({
        show: false,
        data: data,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null,
        onClose: null,
        onSave: null,
    });

    const [usages, setUsages] = useState({});

    const [fromClientBalance, setFromClientBalance] = useState(false);

    const [validations, setValidations] = useValidation();

    useImperativeHandle(ref, () => ({
        open: (data) => {
            open(data);
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        },
        onSave: fn => {
            setState(prev => ({
                ...prev,
                onSave: fn
            }));
        }
    }));

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const open = (data) => {
        setState(prev => ({
            ...prev,
            data: data,
            loading: false,
        }));

        show();
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
        }));

        setValidations(null);

        if (typeof state.onClose === 'function') {
            hide(state.onClose);
        } else {
            hide();
        }
    }

    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        let data = {
            usedSum: getTotalUsedPrice(),
            fromClientBalance: fromClientBalance,
            usages: usages
        }

        hide(state.onClose);

        if (typeof state.onSave === 'function') {
            return state.onSave(data);
        }
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleCheckboxChange = e => {
        const name = e.target.name;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: prev.data[name] ? false : true
            }
        }));
    }

    const isPaymentSelected = payment => {
        if (fromClientBalance) {
            return false;
        }

        return payment.selected;
    }

    const togglePayment = payment => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                payments: prev.data.payments.map(p => {
                    if (p.id === payment.id) {
                        p.selected = !p.selected;
                    }

                    return p;
                })
            }
        }))
    }

    const getUsedPrice = payment => {
        if (fromClientBalance) {
            return null;
        }

        if (!payment.selected) {
            return null;
        }

        return usages[payment.id];
    }

    const getUsedPriceFromBalance = () => {
        if (!fromClientBalance) {
            return 0;
        }

        let total = state.data.totalSum;
        let balance = state.data.balance;

        if (balance > total) {
            return total;
        }

        return balance;
    }

    const getTotalUsedPrice = () => {
        if (fromClientBalance) {
            return getUsedPriceFromBalance();
        }

        let total = 0;

        Object.values(usages).map(u => total += u);

        return total;
    }

    useEffect(() => {
        let total = state.data.totalSum;
        let left = total;

        let payments = [...state.data.payments];

        let usages = {};

        for (let i = 0; i < payments.length; i++) {
            let payment = payments[i];

            if (payment.selected) {
                // ако сумата по плащането е повече от необходимото за изваждане количество
                if (payment.money_left > left) {
                    usages[payment.id] = left;
                    left = 0;
                }
                // ако сумата е по-малко ще извадим цялото количество
                // от плащането и ще продължим да търсим количества от другите плащания
                else {
                    usages[payment.id] = payment.money_left;
                    left -= payment.money_left;
                }
            }

            if (left === 0) {
                break;
            }
        }

        // console.log(usages);

        setUsages(usages);

    }, [state.data.payments])

    // console.log(validations)

    return (
        <>

            <Modal size="md" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <form onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Избор на авансови плащания
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Table className="xxl" bordered>
                            <thead>
                                <tr>
                                    <th>
                                        ID
                                    </th>
                                    <th>
                                        От дата
                                    </th>
                                    <th style={{ width: '35%' }}>
                                        Сума
                                    </th>
                                    <th style={{ width: '25%' }}>
                                        Ползваме
                                    </th>
                                    <th className="text-center">

                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {state.data.payments.map(payment =>
                                    <tr key={payment.id}>
                                        <td>
                                            {payment.id}
                                        </td>
                                        <td>
                                            {payment.date ? moment(payment.date).format('DD.MM.YYYY HH:mm') : ''}
                                        </td>
                                        <td>
                                            <PriceValue prefix={state.data.currency?.prefix} sufix={state.data.currency?.sufix}>{payment.money_left}</PriceValue> / <PriceValue prefix={state.data.currency?.prefix} sufix={state.data.currency?.sufix}>{payment.amount}</PriceValue>
                                        </td>
                                        <td>
                                            <PriceValue prefix={state.data.currency?.prefix} sufix={state.data.currency?.sufix}>{getUsedPrice(payment)}</PriceValue>
                                        </td>
                                        <td className="text-center">
                                            <input
                                                type="checkbox"
                                                checked={isPaymentSelected(payment)}
                                                onChange={e => togglePayment(payment)}
                                                disabled={fromClientBalance ? true : false}
                                            />
                                        </td>
                                    </tr>
                                )}
                                <tr>
                                    <td colSpan={2}>
                                        Клиентски портфейл
                                    </td>
                                    <td>
                                        <PriceValue prefix={state.data.currency?.prefix} sufix={state.data.currency?.sufix}>{state.data.balance}</PriceValue>
                                    </td>
                                    <td>
                                        <PriceValue prefix={state.data.currency?.prefix} sufix={state.data.currency?.sufix}>{getUsedPriceFromBalance()}</PriceValue>
                                    </td>
                                    <td className="text-center">
                                        <input
                                            type="checkbox"
                                            checked={fromClientBalance}
                                            onChange={e => setFromClientBalance(!fromClientBalance)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2} className={`${state.data.totalSum > getTotalUsedPrice() ? 'negative-value' : 'positive-value'}`}>
                                        <b>Дължими</b>
                                    </td>
                                    <td className={`${state.data.totalSum > getTotalUsedPrice() ? 'negative-value' : 'positive-value'}`}>
                                        <PriceValue prefix={state.data.currency?.prefix} sufix={state.data.currency?.sufix}>{state.data.totalSum}</PriceValue>
                                    </td>
                                    <td className={`${state.data.totalSum > getTotalUsedPrice() ? 'negative-value' : 'positive-value'}`}>
                                        <PriceValue prefix={state.data.currency?.prefix} sufix={state.data.currency?.sufix}>{getTotalUsedPrice()}</PriceValue>
                                    </td>
                                    <td className={`${state.data.totalSum > getTotalUsedPrice() ? 'negative-value' : 'positive-value'}`}>

                                    </td>
                                </tr>
                            </tbody>
                        </Table>



                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            className="save"
                        >
                            Избери
                        </SaveButton>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default forwardRef(SelectPrepaidPayments);
