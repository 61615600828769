import { forwardRef, useImperativeHandle, useState } from 'react'
import { Modal, Table, Button, Row, Col } from 'react-bootstrap';

function DevicePreview(props, ref) {
    const defaultData = {}
    const [state, setState] = useState({
        show: false,
        data: defaultData,
        loading: false,
        onClose: null,
    });

    useImperativeHandle(ref, () => ({
        open: (device) => {
            handleShow(device);
        },
        close: () => {
            handleClose();
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        },
    }));

    const show = () => {
        setState(prev => ({
            ...prev,
            show: true,
        }));
    }

    const hide = () => {
        setState(prev => ({
            ...prev,
            show: false,
        }));

        if (typeof state.onClose === 'function') {
            state.onClose();
        }
    }

    const handleShow = (device) => {
        setState(prev => ({
            ...prev,
            data: device
        }));

        show();
    }

    const handleClose = () => {
        hide();

        if (typeof state.onClose === 'function') {
            state.onClose();
        }
    }

    const handleSpeed = (speed) => {
        switch (speed) {
            case 1:
                return '115200'
                break;
            case 2:
                return '57600'
                break;
            case 3:
                return '38400'
                break;
            case 4:
                return '19200'
                break;
            case 5:
                return '9600'
                break;
            case 6:
                return '4800'
                break;
            case 7:
                return '2400'
                break;
            case 8:
                return '1200'
                break;
        }
        return ''
    }

    const handleFlowControl = (flowctrl) => {
        switch (flowctrl) {
            case 1:
                return 'None'
                break;
            case 2:
                return 'Hardware (RTS/CTS)'
                break;
            case 3:
                return 'Hardware (DTR/DSR)'
                break;
            case 4:
                return 'Software'
                break;

        }
        return 'None'
    }

    const handleParity = (parity) => {
        switch (parity) {
            case 1:
                return 'None'
                break;
            case 2:
                return 'Odd'
                break;
            case 3:
                return 'Even'
                break;

        }
        return 'None'
    }

    const handleDataBit = (databits) => {
        switch (databits) {
            case 1:
                return '8'
                break;
            case 2:
                return '7'
                break;
        }
        return '7'
    }

    const handleCodePage = (code_page) => {
        switch (code_page) {
            case 1:
                return 'cp856-bul'
                break;
            case 2:
                return 'cp866'
                break;
            case 2:
                return 'cp1251'
                break;
            case 2:
                return 'utf-8'
                break;
            case 2:
                return 'windows-1251'
                break;

        }
        return ''
    }

    return (
        <>
            <Modal size="lg" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Преглед на устройство
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="mb-3">
                        <Col sm={4}>
                            <img src={state.data?.picture_url || state.data?.base_device?.picture_url || ''} style={{ width: '200px' }} loading="lazy" alt="" />
                        </Col>
                        <Col sm={8}>
                            <Table>
                                <tbody>
                                    <tr>
                                        <td className='th'>ID</td>
                                        <td>{state.data?.id || ''}</td>
                                    </tr>
                                    <tr>
                                        <td className='th'>Производител</td>
                                        <td>{state.data?.brand?.name || ''}</td>
                                    </tr>
                                    <tr>
                                        <td className='th'>Модел</td>
                                        <td>{state.data?.name || ''}</td>
                                    </tr>
                                    <tr>
                                        <td className='th'>Тип</td>
                                        <td>{state.data?.category?.name || ''}</td>
                                    </tr>
                                    <tr>
                                        <td className='th'>Описание</td>
                                        <td>{state.data?.description || ''}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>

                    <Table size="sm" bordered>
                        <tbody>
                            <tr>
                                <td className="th">
                                    Скорост
                                </td>
                                <td>
                                    {handleSpeed(state.data.speed)}
                                </td>
                                <td className="th">
                                    Flow Control
                                </td>
                                <td>
                                    {handleFlowControl(state.data.flowctrl)}
                                </td>
                                <td className="th">
                                    Parity
                                </td>
                                <td>
                                    {handleParity(state.data.parity)}
                                </td>
                            </tr>

                            <tr>
                                <td className="th">
                                    Data Bits
                                </td>
                                <td>
                                    {handleDataBit(state.data.databits)}
                                </td>
                                <td className="th">
                                    Stop Bit
                                </td>
                                <td>
                                    {state.data?.stopbit}
                                </td>
                                <td className="th">
                                    Символа на ред
                                </td>
                                <td>
                                    {state.data?.row_length || ''}
                                </td>
                            </tr>

                            <tr>
                                <td className="th">
                                    Кодова таблица
                                </td>
                                <td>
                                    {handleCodePage(state.data.code_page)}
                                </td>
                                <td className="th">
                                    Оператор номер
                                </td>
                                <td>
                                    {state.data?.op_num}
                                </td>
                                <td className="th">
                                    Оператор код
                                </td>
                                <td>
                                    {state.data?.op_pass || ''}
                                </td>
                            </tr>
                        </tbody>
                    </Table>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={handleClose}>
                        Затвори
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default forwardRef(DevicePreview);
