export default class User {

    constructor(data) {
        this.data = data;
    }

    // isLogged() {
    //     return this.getId() ? true : false;
    // }

    getId() {
        if (this.data) {
            return this.data.id;
        }
        return null;
    }

    getData() {
        return this.data;
    }

    getName() {
        return this.data?.name;
    }

    getUsername() {
        return this.data?.username;
    }

    getEmail() {
        return this.data?.email
    }

    getAvatar() {
        return this.data?.avatar
    }

    getType() {
        if (this.data) {
            return this.data.type;
        }
        return null;
    }

    getRole() {
        if (this.data) {
            return this.data.role;
        }
        return null;
    }

    // access(key) {

    //     if (this.isSuperAdmin()) {
    //         return true;
    //     }

    //     if (this.data && this.data.access && typeof this.data.access === 'object') {
    //         return this.data.access[key] || false;
    //     }

    //     return false;
    // }

    module(key) {
        if (this.isSuperAdmin()) {
            return true;
        }

        if (this.data) {
            if (Array.isArray(this.data.modules)) {
                return this.data.modules.includes(key);
            }
        }

        return false;
    }

    permission(key) {
        if (this.isSuperAdmin()) {
            return true;
        }

        if (this.data) {
            if (Array.isArray(this.data.permissions)) {
                return this.data.permissions.includes(key);
            }
        }

        return false;
    }

    hasAny(key) {
        let keys = key.split(',');

        for (let i = 0; i < keys.length; i++) {
            let key = keys[i].trim();

            if (this.permission(key)) {
                return true;
            }
        }

        return false;
    }

    hasAll(key) {
        let keys = key.split(',');

        for (let i = 0; i < keys.length; i++) {
            let key = keys[i].trim();

            if (this.permission(key) === false) {
                return false;
            }
        }

        return true;
    }

    isAdmin() {
        if (this.data) {
            return Boolean(this.data.is_admin);
        }
        return false;
    }

    isSuperAdmin() {
        if (this.data) {
            return Boolean(this.data.is_superadmin);
        }
        return false;
    }

    isEmailVerified() {
        if (this.data) {
            return Boolean(this.data.is_email_verified);
        }
        return false;
    }

    mustVerifyEmail() {
        if (this.data) {
            return Boolean(this.data.must_verify_email);
        }
        return false;
    }

    getSetting(key) {
        if (Array.isArray(this.settings)) {
            let setting = this.settings.find(s => s.key === key);

            if (setting) {
                return setting.value;
            }
        }

        return undefined;
    }

    getStoreId() {
        return this.data?.store_id;
    }

    getStore() {
        return this.data?.store;
    }

    getCashdesks() {
        return this.data?.store?.cashdesks || [];
    }
}