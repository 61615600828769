import React, { useEffect, useRef, useState } from 'react';
import Api from 'helpers/Api';
import { useQuery } from 'helpers/Url';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Alert, Button, Table } from 'react-bootstrap';

import Options from './partials/Options';

import Question from 'components/modals/Question';
import Item from './partials/Item';
import AddOrEdit from './partials/AddOrEdit';
import Layout from 'components/layout/Layout';

//misc
import MenuOptions from 'components/misc/MenuOptions'
import Pagination from 'components/misc/Pagination'
import Loader from 'components/misc/Loader';
import NoDataFound from 'components/misc/NoDataFound';

let timeout;

function Index() {

    const location = useLocation();
    const history = useNavigate();
    const query = useQuery();
    // const auth = useAuthDataContext();

    const addOrEditModalRef = useRef(null);
    const deleteModalRef = useRef(null);

    const [state, setState] = useState({
        loading: true,
        refresh: false,
        setFilter: false,
        data: [],
        pages: 0,
        total: 0,
        filter: {
            page: 1,
        },
    });

    useEffect(() => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: query.get('page') || 1,
                search: query.get('search') || ''
            },
            refresh: new Date().getTime()
        }))
    }, [location.search]);

    useEffect(() => {
        if (state.setFilter) {
            Object.entries(state.filter).map(filter => {
                // if (filter[1]) {
                query.set(filter[0], filter[1]);
                // }
            });

            history('?' + query.toString());
        }

    }, [state.setFilter]);

    useEffect(() => {
        if (state.refresh) {
            loadData();
        }
    }, [state.refresh]);

    const loadData = () => {

        loading(true);

        let url = 'categories/get';

        Api.get(url, {
            params: state.filter
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data.items,
                total: res.data.total,
                pages: res.data.pages
            }));

            if (res.data.pages > 0 && state.filter.page > res.data.pages) {
                return handlePage({ selected: res.data.pages - 1 });
            }

            loading(false);
        });
    }

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }));
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: reset ? 1 : prev.filter.page
            },
            setFilter: new Date().getTime(),
            refresh: new Date().getTime(),
        }));
    }

    const handleAdd = id => {
        let modal = addOrEditModalRef.current;

        if (id) {
            modal.add(id)
        } else {
            modal.add()
        }

        modal.onSuccess(() => {
            refresh(true);
        });
    }

    // update
    const handleEdit = id => {
        let modal = addOrEditModalRef.current;

        modal.edit(id);

        modal.onSuccess(() => {
            refresh(false);
        });
    }

    // Search
    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout);

        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [key]: val,
            },
        }));

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: 1
                },
                setFilter: new Date().getTime()
            }));
        }, delay);
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            setFilter: new Date().getTime()
        }));
    }

    return (
        <>

            <AddOrEdit
                ref={addOrEditModalRef}
            />

            <Question
                ref={deleteModalRef}
                mainMessage="Сигурни ли сте?"
                agreeBtnText="Изтриване"
                agreeBtnClass="remove"
            />

            <Layout>
                <div className="container-fluid">

                    <div className="page-head">
                        <h1 className="display-6">
                            Категории
                        </h1>
                    </div>

                    <br />

                    <div className="panel">

                        {state.loading
                            ?
                            <Loader />
                            :
                            state.data.length === 0 ?
                                <NoDataFound>
                                    <div>
                                        Няма намерена информация!
                                    </div>
                                    <br />
                                    <Button variant="outline-dark" onClick={e => handleAdd()}>
                                        Създай нова категория
                                    </Button>
                                </NoDataFound>
                                :
                                <>
                                    <Table striped hover>
                                        <thead>
                                            <tr>
                                                <th>Име</th>
                                                <th className="options">
                                                    Опции
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {state.data.map(c =>
                                                <React.Fragment key={'c-' + c.id}>
                                                    <tr>
                                                        <td data-id={c.id} data-parent={c.parent_id}>
                                                            {c.translation?.name}
                                                        </td>

                                                        <td className="options">
                                                            <MenuOptions>
                                                                <Options
                                                                    data={c}
                                                                    handleEdit={handleEdit}
                                                                />
                                                            </MenuOptions>
                                                        </td>
                                                    </tr>
                                                    {c.children.map((item, index) =>
                                                        <Item
                                                            handleEdit={handleEdit}
                                                            handleAdd={handleAdd}
                                                            key={index}
                                                            level={1}
                                                            {...item}
                                                        />)}
                                                </React.Fragment>
                                            )}
                                        </tbody>
                                    </Table>

                                    <Pagination
                                        className="mt-3"
                                        page={state.filter.page}
                                        pages={state.pages}
                                        total={state.total}
                                        handlePage={handlePage}
                                    />
                                </>
                        }


                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Index;
