import { forwardRef, useImperativeHandle } from "react";
import { Form } from "react-bootstrap";

function Category(props, ref) {

    useImperativeHandle(ref, () => ({
        getName: getName,
        getType: getType,
        getTitle: getTitle,
        hasSettings: hasSettings,
        getSettings: getSettings,
        getDefaultOptions: () => ({
            delimiter: '>',
            decategorize: 0,
        })
    }));

    const getName = () => {
        return 'Категория';
    }

    const getType = () => {
        return 'category';
    }

    const getTitle = () => {
        return null;
    }

    const hasSettings = () => true;

    const getSettings = ({ state, setConfig }) => {

        return (
            <div>
                <label title="Пр. Жени>Дрехи">
                    Йерархичен разделител:
                </label>
                <Form.Control
                    type="text"
                    size="sm"
                    value={state.options?.delimiter || ''}
                    onChange={e => setConfig('delimiter', e.target.value)}
                />

                <br />

                <Form.Check
                    id={'cb-decategorize'}
                    label="Декатегоризиране на текущите"
                    title="Премахване на текущите връзки с категории за артикула, ако целта е той да бъде категоризиран само в посочената категория"
                    checked={Number(state.options?.decategorize) === 1}
                    onChange={e => setConfig('decategorize', e.target.checked ? 1 : 0)}
                />
            </div>
        )
    }

    return getName();
}

export default forwardRef(Category)