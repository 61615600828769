import React, { useState, useImperativeHandle, forwardRef, useEffect } from 'react'
import { Modal, Button, Row, Col, Form, Table, Card } from 'react-bootstrap';
import { createPortal } from 'react-dom';

import SaveButton from 'components/misc/Button';
import Info from 'components/misc/Info';

import { useAuthDataContext } from 'providers/Auth';
import { useAppContext } from 'providers/App';

import { TYPES } from 'constants/imports';
import { ROWS } from './Rows';

// const refs = Refs.getInstance();

function Columns(props, ref) {

    const auth = useAuthDataContext();
    const app = useAppContext();

    const TYPE = props.type;

    const rows = ROWS[TYPE] || [];

    const defaultData = {
        type: '',
        name: '',
        options: {},
    }

    const [components, setComponents] = useState([]);
    const [refs, setRefs] = useState({});

    const [state, setState] = useState({
        show: false,
        params: null,
        colIndex: null,
        data: defaultData,
        operation: 'load',
        onSuccess: null,
        onClose: null,
        onEntering: null,
        onExiting: null,
    });

    useImperativeHandle(ref, () => ({
        open: (config, colIndex) => {
            handleShow(config, colIndex);
        },
        close: () => {
            handleClose();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        },
        onCancel: fn => {
            setState(prev => ({
                ...prev,
                onCancel: fn
            }));
        }
    }));

    useEffect(() => {
        let data = [];

        for (let i = 0; i < rows.length; i++) {
            data.push(
                React.createElement(rows[i], {
                    ref: (ref) => {
                        setRefs(prev => ({
                            ...prev,
                            [i]: ref
                        }))
                    },
                })
            )
        }

        setComponents(data);
    }, [])

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const handleShow = (config, colIndex) => {
        setState(prev => ({
            ...prev,
            colIndex: colIndex,
            data: {
                ...prev.data,
                type: config.type || '',
                name: config.name || '',
                options: config.options || {},
            }
        }));

        show();
    }

    const handleClose = () => {
        hide(state.onClose);
    }

    const handleSave = e => {
        e.preventDefault();

        state.onSuccess(state.data);

        hide(state.onClose);
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: value
            }
        }))
    }

    const handleOperationChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const handleColumnChange = (type, name, options) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                type: type,
                name: name,
                options: options
            }
        }))
    }

    const handleOptionsChange = (key, value) => {
        // console.log(key, value);

        if (typeof key === 'object') {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    options: {
                        ...prev.data.options,
                        ...key
                    }
                }
            }))
        } else {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    options: {
                        ...prev.data.options,
                        [key]: value
                    }
                }
            }))
        }
    }

    const getColumn = index => {
        return refs[index];
    }

    const getActiveColumn = () => {
        let type = state.data.type;

        if (!type) {
            return null;
        }

        for (let i = 0; i < rows.length; i++) {
            let col = getColumn(i);

            if (col && col.getType() === type) {
                return col;
            }
        }

        return null;
    }

    const render = (fromIndex, toIndex) => {
        let data = [];

        let rows = [];

        let j = 0;

        for (let i = fromIndex; i <= toIndex; i++) {
            let component = components[i];

            data.push(
                <td key={i} style={{ width: '33.33333%' }}>
                    <div style={{ display: 'flex' }}>
                        <Form.Check
                            id={'cb-' + i}
                            type="radio"
                            label={component}
                            name="type"
                            value={getColumn(i)?.getType() || ''}
                            checked={state.data.type === getColumn(i)?.getType()}
                            onChange={e => handleColumnChange(getColumn(i)?.getType(), getColumn(i)?.getName(), getColumn(i)?.getDefaultOptions({ createOrUpdate: props.createOrUpdate }))}
                            className={getColumn(i)?.getType() === 'ignore' ? 'red' : ''}
                        />
                        {getColumn(i)?.getTitle() &&
                            <Info style={{ marginLeft: 'auto' }}>{getColumn(i)?.getTitle()}</Info>
                        }
                    </div>
                </td>
            )

            if ((j + 1) % 3 === 0 || (toIndex === i)) {
                rows.push(<tr key={i}>
                    {data}
                </tr>);

                if (j > 0) {
                    data = [];
                }
            }

            j++;
        }

        return rows;
    }

    const renderTable = () => {
        if (TYPE === TYPES.ARTICLE) {
            return (
                <tbody>
                    {render(0, 27)}
                    <tr>
                        <td colSpan={3}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <b>Колони за операция</b> <Form.Select
                                    name="operation"
                                    value={state.operation || ''}
                                    onChange={handleOperationChange}
                                    size="sm"
                                    style={{ width: 'auto', marginLeft: '5px' }}
                                >
                                    <option value="load">Зареждане</option>
                                </Form.Select>
                            </div>
                        </td>
                    </tr>
                    {render(28, 30)}
                </tbody>
            )
        } else {
            return (
                <tbody>
                    {render(0, components.length - 1)}
                </tbody>
            )
        }
    }

    // console.log(state.data);
    // console.log(refs)
    // console.log(components)

    return createPortal(
        <Modal size="xl" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Конфигуриране на колона
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs={9}>
                        <Table className="big" bordered>
                            {renderTable()}
                        </Table>
                    </Col>
                    <Col xs={3}>
                        {getActiveColumn()?.hasSettings({
                            createOrUpdate: props.createOrUpdate
                        }) &&
                            <Card style={{ position: 'sticky', top: '10px' }}>
                                <Card.Header>
                                    <span>Настройки на колоната</span>
                                </Card.Header>
                                <Card.Body>
                                    {getActiveColumn()?.getSettings({
                                        colIndex: state.colIndex,
                                        state: state.data,
                                        setConfig: handleOptionsChange,
                                        createOrUpdate: props.createOrUpdate,
                                        getValues: props.getValues
                                    })}
                                </Card.Body>
                            </Card>
                        }
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Отказ
                </Button>
                <SaveButton
                    loading={state.loading}
                    className="save"
                    onClick={handleSave}
                >
                    Конфигурирай
                </SaveButton>
            </Modal.Footer>
        </Modal>,
        document.body
    )

}

export default forwardRef(Columns);