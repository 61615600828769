import { useState, useEffect } from 'react'
import { Button, Dropdown, Table } from 'react-bootstrap';
import Api from 'helpers/Api';
import Loader from 'components/misc/Loader';
import moment from 'moment';
import PriceValue from 'components/partials/PriceValue';
import { Pencil, Upc } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import Rows from './Rows';

function Index(props) {

    const { show, hide } = props;

    const navigate = useNavigate();

    const defaultData = {
        rows: []
    };

    const [state, setState] = useState({
        data: defaultData,
        loading: false,
    });

    useEffect(() => {
        setState(prev => ({
            ...prev,
            loading: true
        }));

        Api.get('storeloads/show', {
            params: {
                id: props.id
            }
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data,
                loading: false,
            }));
        });
    }, [props.id]);

    const showArticle = id => {
        props.showArticle(id);
    }

    const handleEdit = () => {
        navigate('/storage/loads/' + state.data.id + '/edit');
    }

    const handleCreateAccount = () => {
        props.hide();

        navigate('/accounts/new?from_load_id=' + state.data.id);
    }

    const handleCreateStoreMove = () => {
        props.hide();

        navigate('/storage/moves/new?from_load_id=' + state.data.id);
    }

    const handleCreateInvoice = () => {
        props.hide();

        navigate('/invoices/new?from_load_id=' + state.data.id);
    }

    const handleCreateStoreReturn = () => {
        props.hide();

        navigate('/storage/loads/new?from_load_id=' + state.data.id + '&return=1');
    }

    const handlePrintLabels = () => {
        props.hide();

        navigate('/articles-print-list-free?from_load_id=' + state.data.id);
    }

    return (

        state.loading
            ?
            <Loader />
            :
            <>
                <Table size="sm" bordered>
                    <tbody>
                        <tr>
                            <td className="th">
                                ID
                            </td>
                            <td>
                                {state.data.id}
                            </td>
                            <td className="th">
                                Създал
                            </td>
                            <td>
                                {state.data?.user?.username}
                            </td>
                            <td className="th">
                                Създадено на
                            </td>
                            <td>
                                {state.data.created_at ? moment(state.data.created_at).format('DD.MM.YYYY HH:mm') : ''}
                            </td>
                        </tr>
                        <tr>
                            <td className="th">
                                Операция
                            </td>
                            <td>
                                {state.data?.operation?.translation?.name || state.data?.operation?.name}
                            </td>
                            <td className="th">
                                Статус
                            </td>
                            <td>
                                {state.data?.status?.translation?.name || state.data?.status?.name}
                            </td>
                            <td className="th">
                                Затворено на
                            </td>
                            <td>
                                {state.data.closed_at ? moment(state.data.closed_at).format('DD.MM.YYYY HH:mm') : ''}
                            </td>
                        </tr>
                        <tr>
                            <td className="th">
                                Склад
                            </td>
                            <td>
                                {state.data?.depot?.name}
                            </td>
                            <td className="th">
                                Търговски обект
                            </td>
                            <td>
                                {state.data?.depot?.store?.translation?.name || state.data?.depot?.store?.name}
                            </td>
                            <td className="th">
                                Заявка №
                            </td>
                            <td>
                                {state.data?.order_no}
                            </td>
                        </tr>
                        <tr>
                            <td className="th">
                                Тип документ
                            </td>
                            <td>
                                {state.data?.documenttype?.translation?.name || state.data?.documenttype?.name}
                            </td>
                            <td className="th">
                                Документ №
                            </td>
                            <td>
                                {state.data?.document_no}
                            </td>
                            <td className="th">
                                Документ дата
                            </td>
                            <td>
                                {state.data.document_date ? moment(state.data.document_date).format('DD.MM.YYYY HH:mm') : ''}
                            </td>
                        </tr>
                        <tr>
                            <td className="th">
                                Доставчик
                            </td>
                            <td>
                                {state.data?.supplier?.name}
                            </td>
                            <td className="th">
                                Регистрация по ДДС
                            </td>
                            <td>
                                {state.data.has_tax ? 'ДА' : 'НЕ'}
                            </td>
                            <td className="th">
                                Цени
                            </td>
                            <td>
                                {state.data.with_tax ? 'С ДДС' : 'Без ДДС'}
                            </td>
                        </tr>
                        <tr>
                            <td className="th">
                                Платена сума
                            </td>
                            <td>
                                {(state.data.total_paid || 0).toFixed(2)}  {state.data?.paymentmethod && ' / ' + state.data?.paymentmethod?.translation?.name || state.data?.paymentmethod?.name}
                            </td>
                            <td className="th">
                                Срок за плащане
                            </td>
                            <td>
                                {state.data.paid_due_date ? moment(state.data.paid_due_date).format('DD.MM.YYYY HH:mm') : ''}
                            </td>
                            <td className="th">
                                Валута / Курс
                            </td>
                            <td>
                                {state.data?.currency?.name || '-'} / {state.data?.currency_rate ? state.data?.currency_rate.toFixed(4) : '-'}
                            </td>
                        </tr>
                        <tr>
                            <td className="th">
                                Бележки
                            </td>
                            <td colspan={5}>
                                {state.data.description}
                            </td>
                        </tr>
                    </tbody>
                </Table>

                <div className="mt-3">
                    <Button size="sm" variant="primary" onClick={() => handleEdit()} disabled={state.data.allow_edit === false}>
                        <Pencil /> Редактиране
                    </Button>
                    <Dropdown drop="down" style={{ display: 'inline' }}>
                        <Dropdown.Toggle size="sm" style={{ marginLeft: '10px' }}>
                            Направи...
                        </Dropdown.Toggle>
                        <Dropdown.Menu align="start" style={{ padding: '15px', width: '150px' }}>
                            <Button size="sm" variant="primary" onClick={() => handleCreateAccount()} style={{ width: '100%' }}>
                                Сметка
                            </Button>
                            <br />
                            <Button size="sm" variant="primary" onClick={() => handleCreateStoreMove()} style={{ width: '100%', marginTop: '10px' }}>
                                Прехвърляне
                            </Button>
                            <br />
                            <Button size="sm" variant="primary" onClick={() => handleCreateInvoice()} style={{ width: '100%', marginTop: '10px' }}>
                                Фактура
                            </Button>
                            <br />
                            <Button size="sm" variant="primary" onClick={() => handleCreateStoreReturn()} style={{ width: '100%', marginTop: '10px' }}>
                                Връщане
                            </Button>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Button size="sm" variant="secondary" onClick={() => handlePrintLabels()} style={{ marginLeft: '10px' }}>
                        <Upc /> Печат етикети
                    </Button>
                </div>

                <Rows
                    id={state.data.id}
                    show={show}
                    hide={hide}
                />
            </>
    )
}

export default Index;