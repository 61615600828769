import { forwardRef, useState } from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';
import { bg } from 'date-fns/locale'

import moment from 'moment';

import ReactDatePicker from 'react-datepicker';
import Info from 'components/misc/Info';
import LockedFilter from 'components/misc/LockedFIlter';

// import 'react-datepicker/src/stylesheets/datepicker.scss'
// import 'react-datepicker/dist/react-datepicker.min.css';

import 'assets/scss/datepicker.scss';

const FloatingLabelInput = forwardRef(({ value, onClick, ...otherProps }, ref) => {

    // console.log(otherProps)

    return (
        <FloatingLabel className={`form-datepicker ${otherProps.inputType === 'time' && 'time'}`} label={otherProps.placeHolder} style={{ cursor: 'pointer' }}>
            <Form.Control
                type="text"
                placeholder={otherProps.placeHolder}
                value={value}
                onClick={onClick}
                readOnly
                disabled={otherProps.isDisabled}
                isInvalid={otherProps.isInvalid}
                style={{ cursor: 'pointer' }}
                ref={ref}
            />
            {otherProps.info &&
                <Info>{otherProps.info}</Info>
            }
            {otherProps.lock &&
                <LockedFilter lock={otherProps.lock} />
            }
            <Form.Control.Feedback type="invalid">
                {otherProps.error}
            </Form.Control.Feedback>
        </FloatingLabel>
    )
});

const Input = forwardRef(({ value, onClick, ...otherProps }, ref) => {

    // console.log(otherProps)

    return (
        <div className={`form-datepicker ${otherProps.inputType === 'time' && 'time'}`} style={{ cursor: 'pointer' }}>
            <Form.Control
                type="text"
                placeholder="дд.мм.гггг г."
                value={value}
                onClick={onClick}
                readOnly
                disabled={otherProps.isDisabled}
                isInvalid={otherProps.isInvalid}
                size={otherProps.size}
                style={{ cursor: 'pointer' }}
                ref={ref}
            />
            <Form.Control.Feedback type="invalid">
                {otherProps.error}
            </Form.Control.Feedback>
        </div>
    )
});

function DatePicker(props) {
    // const [startDate, setStartDate] = useState(new Date());

    const type = props.type || "date";
    const name = props.name;
    const placeholder = props.placeholder;
    const value = props.value || null;
    const onChange = props.onChange || (() => { });
    const disabled = Boolean(props.disabled);
    const isInvalid = Boolean(props.isInvalid);
    const error = props.error;
    const info = props.info;
    const size = props.size;
    const variant = props.variant || 'floatingLabel';
    const lock = props.lock;

    const showTimeSelect = type === 'datetime-local' || type === 'time';
    const showTimeSelectOnly = type === 'time';

    const timeIntervals = type === 'time' ? 1 : 15;

    const showMonthYearDropdown = type !== 'time';

    const dateFormat = type === 'datetime-local' ? 'dd.MM.yyyy HH:mm' : type === 'date' ? 'dd.MM.yyyy' : type === 'time' ? 'HH:mm' : ''; // date-fns
    const inputDateFormat = type === 'datetime-local' ? 'YYYY-MM-DD HH:mm:ss' : type === 'date' ? 'YYYY-MM-DD' : type === 'time' ? 'HH:mm' : ''; // moment

    const getValue = () => {
        // console.log(value)

        let val = getValueObj();

        if (val) {
            return val.toDate();
        }

        return null;
    }

    const getValueObj = () => {
        // console.log(value)

        // return null;

        if (type === 'time') {
            if (value) {
                let date = moment();
                let time = moment(value, 'HH:mm');

                date.set({
                    hour: time.get('hour'),
                    minute: time.get('minute')
                });

                // console.log(date);

                return date;
            }
        }

        if (typeof value === String) {
            return moment(value);
        }

        if (typeof value === Date) {
            return value;
        }

        if (value) {
            return moment(value);
        }

        return null;
    }

    const handleChange = date => {
        // console.log(date);

        let obj = moment(date);

        // console.log(obj);

        let event = {
            target: {
                name: name,
                value: type === 'time' ? obj.format('HH:mm') : obj
            }
        }

        // console.log(event);

        // onChange(obj);
        onChange(event);
    }

    const setToday = () => {
        handleChange(new Date());
    }

    const clear = () => {
        let event = {
            target: {
                name: name,
                value: ''
            }
        }

        onChange(event);
    }

    const getInput = () => {
        if (variant === 'basic') {
            return <Input
                inputType={type}
                placeHolder={placeholder}
                isDisabled={disabled}
                isInvalid={isInvalid}
                error={error}
                size={size}
            />
        }

        return <FloatingLabelInput
            inputType={type}
            placeHolder={placeholder}
            isDisabled={disabled}
            isInvalid={isInvalid}
            error={error}
            size={size}
            info={info}
            lock={lock}
        />
    }

    // console.log(getValue())

    return (
        <>
            <ReactDatePicker
                showPopperArrow={false}
                selected={getValue()}
                onChange={handleChange}
                customInput={getInput()}
                dateFormat={dateFormat}
                showTimeSelect={showTimeSelect}
                showTimeSelectOnly={showTimeSelectOnly}
                timeIntervals={timeIntervals}
                timeCaption="Час"
                // minDate={moment().subtract(10, 'years').toDate()}
                // maxDate={moment().add(1, 'months').toDate()}
                // showMonthYearDropdown={showMonthYearDropdown}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                // dropdownMode="select"
                calendarStartDay={1}
                locale={bg}
            // todayButton="Днес"
            >
                <div className="react-datepicker__today-button" style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '3px 5px',
                    cursor: 'default',
                    width: '100%'
                }}>
                    <span style={{ cursor: 'pointer' }} onClick={e => clear()}>
                        Изчисти
                    </span>
                    <span style={{ cursor: 'pointer' }} onClick={e => setToday()}>
                        {showTimeSelect ? 'Сега' : 'Днес'}
                    </span>
                </div>
            </ReactDatePicker>

            <input type="hidden" name={name} value={getValueObj() ? getValueObj().format(inputDateFormat) : ''} disabled={disabled} />
        </>
    )
}

export default DatePicker;