import { Dropdown } from 'react-bootstrap';

function Options(props) {
    return (
        <>
            <Dropdown.Item onClick={e => props.handleView(props.data)}>
                Преглед
            </Dropdown.Item>
            <Dropdown.Item onClick={e => props.handleCreateInvoice(props.data)}>
                Създай фактура
            </Dropdown.Item>
        </>
    )
}

export default Options;