import { Dropdown } from 'react-bootstrap';

function Options(props) {
    return (
        <>
            {props.data.deleted_at
                ?
                <Dropdown.Item disabled={props.data.allow_edit === false} onClick={e => props.handleRestore(props.data)}>
                    Възстанови
                </Dropdown.Item>
                :
                <>
                    <Dropdown.Item disabled={props.data.allow_view === false} onClick={e => props.handleView(props.data)}>
                        Преглед
                    </Dropdown.Item>
                    <Dropdown.Item disabled={props.data.allow_edit === false} onClick={e => props.handleEdit(props.data)}>
                        Редакция
                    </Dropdown.Item>
                    <Dropdown.Item disabled={props.data.allow_delete === false} onClick={e => props.handleDelete(props.data)}>
                        Изтриване
                    </Dropdown.Item>
                </>
            }
        </>
    )
}

export default Options;