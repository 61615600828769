import { OverlayTrigger, Tooltip } from 'react-bootstrap';

function Title(props) {
    const id = Math.random().toString();
    const placement = props.placement || 'auto';

    return (
        <OverlayTrigger
            placement={'auto-start'}
            delay={400}
            // show
            overlay={
                <Tooltip className={props.className} id={'tooltip-info-' + id}>
                    {props.title}
                </Tooltip>
            }
        >
            <span>
                {props.children}
            </span>
        </OverlayTrigger>
    )
}

export default Title;
