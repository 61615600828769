import { useState, useEffect, useRef } from 'react'
import { Card, Col, Row, Table } from 'react-bootstrap';
import Api from 'helpers/Api';
import Loader from 'components/misc/Loader';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import FilePreview from 'components/partials/FilePreview';
import PriceValue from 'components/partials/PriceValue';
import { useAuthDataContext } from 'providers/Auth';

function Index(props) {

    const id = Number(props.id);
    const { show, hide } = props;

    const auth = useAuthDataContext();
    const navigate = useNavigate();

    const user = auth.getUser();

    const defaultData = {
        article: null,
    };

    const [state, setState] = useState({
        data: defaultData,
        loading: false,
        onEntering: null,
        onExiting: null
    });

    useEffect(() => {
        setState(prev => ({
            ...prev,
            loading: state.data.article ? false : true
        }));

        Api.get('articles/view', {
            params: {
                id: props.id
            }
        }).then(res => {

            let data = res.data;

            if (data.article.details) {
                data.article.details = res.data.article.details.map(detail => {
                    return {
                        name: detail?.detail?.translation?.name || detail?.detail?.name,
                        value: detail?.value?.translation?.name || detail?.value?.name,
                        detail_id: detail.detail_id,
                        value_id: detail.value_id,
                    }
                });
            } else {
                data.details = [];
            }

            setState(prev => ({
                ...prev,
                data: data,
                loading: false,
            }));
        });
    }, [props.id]);


    const createMarkup = html => {
        return { __html: html };
    }

    return (

        state.loading
            ?
            <div style={{ padding: '150px 0' }}>
                <Loader />
            </div>
            :
            <>
                <Row>
                    <Col sm={12}>
                        <Card className="mb-3">
                            <Card.Header>
                                Данни
                            </Card.Header>
                            <Card.Body style={{ padding: 0 }}>
                                <Row style={{ padding: '15px' }}>
                                    <Col sm={3}>
                                        <FilePreview
                                            file={state.data.article?.files ? state.data.article?.files[0] : null}
                                        />
                                    </Col>
                                    <Col sm={9}>
                                        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                            <span style={{
                                                fontSize: '1.1rem'
                                            }}>
                                                {state.data.article?.article_name}
                                            </span>

                                            <div style={{ display: 'flex', gap: '10px', marginTop: 'auto', padding: '5px', overflowX: 'auto', overflowY: 'hidden' }}>
                                                {state.data.article?.files.map((file, i) => {
                                                    if (i > 0) {
                                                        return <div key={i} style={{ width: '60px', height: '60px' }}>
                                                            <FilePreview
                                                                file={file}
                                                            />
                                                        </div>
                                                    }
                                                })}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <Table size="sm" className="no-shadow no-hover" bordered>
                                    <tbody>
                                        <tr>
                                            <td className="th" style={{ width: '25%' }}>
                                                Референтен номер
                                            </td>
                                            <td style={{ width: '25%' }}>
                                                {state.data.article?.ref_num}
                                            </td>
                                            <td className="th" style={{ width: '25%' }}>
                                                Баркод
                                            </td>
                                            <td style={{ width: '25%' }}>
                                                {state.data.article?.barcode}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="th">
                                                Цена продажба
                                            </td>
                                            <td>
                                                {state.data.article?.currency?.prefix} {state.data.article?.actual_price !== null ? <PriceValue>{parseFloat(state.data.article?.actual_price || 0)}</PriceValue> : ''}  {state.data.article?.currency?.sufix}
                                            </td>
                                            <td className="th">
                                                Последно зареждане
                                            </td>
                                            <td>
                                                {state.data.article?.currency?.prefix} {state.data.article?.current_delivery_price !== null ? <PriceValue>{parseFloat(state.data.article?.current_delivery_price || 0)}</PriceValue> : ''}  {state.data.article?.currency?.sufix}
                                            </td>
                                        </tr>
                                        {user.permission('show_delivery_price') &&
                                            <tr>
                                                <td className="th">
                                                    Себестойност (без ДДС)
                                                </td>
                                                <td>
                                                    {state.data.article?.currency?.prefix} {state.data.article?.avg_delivery_price !== null ? <PriceValue>{parseFloat(state.data.article?.avg_delivery_price || 0)}</PriceValue> : ''}  {state.data.article?.currency?.sufix}
                                                </td>
                                                <td className="th">
                                                    Себестойност (+ ДДС)
                                                </td>
                                                <td>
                                                    {state.data.article?.currency?.prefix} {state.data.article?.avg_delivery_price_with_vat !== null ? <PriceValue>{parseFloat(state.data.article?.avg_delivery_price_with_vat || 0)}</PriceValue> : ''}  {state.data.article?.currency?.sufix}
                                                </td>
                                            </tr>
                                        }
                                        <tr>
                                            <td className="th">
                                                Единица продажба
                                            </td>
                                            <td>
                                                {state.data.article?.amount_unit} {state.data.article?.amounttype?.translation?.name_short}
                                            </td>
                                            <td className="th">
                                                Код
                                            </td>
                                            <td>
                                                {state.data.article?.code}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="th">
                                                Създаден на
                                            </td>
                                            <td>
                                                {state.data.article?.created_at !== null ? moment(state.data.article?.created_at).format('DD.MM.YYYY HH:mm') : ''}
                                            </td>
                                            <td className="th">
                                                Създаден от
                                            </td>
                                            <td>
                                                {state.data.article?.user?.username || ''}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="th">
                                                Актуализиран на
                                            </td>
                                            <td>
                                                {state.data.article?.updated_at !== null ? moment(state.data.article?.updated_at).format('DD.MM.YYYY HH:mm') : ''}
                                            </td>
                                            <td className="th">
                                                Промяна наличност
                                            </td>
                                            <td>
                                                {state.data.article?.amount_last_update !== null ? moment(state.data.article?.amount_last_update).format('DD.MM.YYYY HH:mm') : ''}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="th">
                                                Мин. складово к-во
                                            </td>
                                            <td>
                                                {state.data.article?.min_quantity || '-'} {state.data.article?.amounttype?.translation?.name_short}
                                            </td>
                                            <td className="th">
                                                Макс. складово к-во
                                            </td>
                                            <td>
                                                {state.data.article?.max_quantity || '-'} {state.data.article?.amounttype?.translation?.name_short}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="th">
                                                Публичен
                                            </td>
                                            <td>
                                                {state.data.article?.is_public
                                                    ?
                                                    <span className="green-value">Да</span>
                                                    :
                                                    <span className="red-value">Не</span>
                                                }
                                            </td>
                                            <td className="th">
                                                Продажба
                                            </td>
                                            <td>
                                                {state.data.article?.is_for_sale
                                                    ?
                                                    <span className="green-value">Да</span>
                                                    :
                                                    <span className="red-value">Не</span>
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="th">
                                                Интрастат
                                            </td>
                                            <td>
                                                {state.data.article?.intrastat}
                                            </td>
                                            <td className="th">
                                                ДДС група
                                            </td>
                                            <td>
                                                {state.data.article?.taxgroup?.name}
                                            </td>
                                        </tr>
                                        {user.permission('show_delivery_price') &&
                                            <tr>
                                                <td className="th">
                                                    Марж
                                                </td>
                                                <td>
                                                    <PriceValue>{state.data.article?.margin_perc}</PriceValue>%
                                                </td>
                                                <td className="th">
                                                    Надценка
                                                </td>
                                                <td>
                                                    <PriceValue>{state.data.article?.profit_perc}</PriceValue>%
                                                </td>
                                            </tr>
                                        }
                                        {state.data.article?.translation?.description && state.data.article?.translation?.description.length > 0 &&
                                            <tr>
                                                <td colSpan={4} style={{ background: 'transparent' }}>
                                                    <div dangerouslySetInnerHTML={createMarkup(state.data.article?.translation?.description)} />
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                        {state.data.article?.details.length > 0 &&
                            <Card>
                                <Card.Header>
                                    Характеристики
                                </Card.Header>
                                <Card.Body style={{ padding: 0 }}>
                                    <Table size="sm" className="no-shadow no-hover" bordered>
                                        <tbody>
                                            {state.data.article?.details.map((d, i) =>
                                                <tr key={i}>
                                                    <td>
                                                        {d?.name}
                                                    </td>
                                                    <td>
                                                        {d?.value}
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        }
                    </Col>

                </Row>
            </>
    )
}

export default Index;