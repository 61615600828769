import { useState, useEffect } from 'react'
import { FloatingLabel, Form, Dropdown, Button } from 'react-bootstrap'
import { cloneDeep } from 'lodash';

import Api from 'helpers/Api'
import Loader from 'components/misc/Loader'
import NoDataFound from 'components/misc/NoDataFound'
import { diff, unique } from 'helpers/Arrays'
import ArticleDetailValue from './ArticleDetailValue'

function ArticleDetail(props) {

    const inputName = props.inputName || '';
    const position = props.position || 'start';

    const [state, setState] = useState({
        data: [],
        show: false,
        selected: {},
        loading: true,
    });

    useEffect(() => {
        Api.get('autocomplete/article-details-with-values')
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: Array.isArray(res.data.items) ? res.data.items : [],
                    loading: false,
                }));
            });
    }, []);

    useEffect(() => {
        // console.log(props.selected);

        if (typeof props.selected === 'object') {
            setState(prev => ({
                ...prev,
                selected: props.selected
            }));
        }
    }, [props.selected]);

    const toggleDropdown = () => {
        setState(prev => ({
            ...prev,
            show: !prev.show,
        }))
    }

    const getFilterValue = () => {
        if (Object.values(state.selected).length === 0) {
            return 'Няма избрани';
        }

        let text = [];

        state.data.map(detail => {
            let el = {
                name: detail.name,
                values: []
            }

            if (hasDetailSelectedValue(detail)) {

                detail.values.map(value => {
                    if (isValueSelected(value, detail)) {
                        el.values.push(value.name)
                    }
                });

                text.push(el);
            }
        });

        return text.map(d => {
            return d.name + ': ' + d.values.join(', ')
        }).join(' | ');

        return state.selected.length + ' избрани';
    }

    const hasDetailSelectedValue = detail => {

        let selected = 0;

        (detail.values || []).map(value => {
            if (isValueSelected(value, detail)) {
                selected++;
            }
        });

        if (selected > 0) {
            return true;
        }

        return false;
    }

    const selectValue = (value, detail) => {
        let selected = cloneDeep(state.selected);

        if (isValueSelected(value, detail)) {
            selected[detail.id] = selected[detail.id].filter(id => Number(id) !== Number(value.id));

            if (selected[detail.id].length === 0) {
                delete selected[detail.id];
            }
        } else {
            if (!selected[detail.id]) {
                selected[detail.id] = [];
            }

            selected[detail.id] = selected[detail.id].concat(value.id);
        }

        setState(prev => ({
            ...prev,
            selected: selected
        }))

        if (typeof props.onChange === 'function') {
            props.onChange(selected);
        }
    }

    const isValueSelected = (value, detail) => {
        return (state.selected[detail.id] || []).findIndex(id => Number(id) === Number(value.id)) > -1;
    }

    const setDetailValues = (detailId, values) => {
        setState(prev => ({
            ...prev,
            data: prev.data.map(detail => {
                if (detail.id === detailId) {
                    detail.values = values;
                }

                return detail;
            })
        }))
    }

    const clear = () => {
        let selected = {};

        setState(prev => ({
            ...prev,
            selected: selected
        }))

        if (typeof props.onChange === 'function') {
            props.onChange(selected);
        }
    }

    return (
        <Dropdown drop="down" onToggle={toggleDropdown} show={state.show}>
            <FloatingLabel label="Характеристики">
                <Form.Control
                    type="text"
                    placeholder="Характеристики"
                    value={getFilterValue()}
                    onClick={toggleDropdown}
                    readOnly
                    style={{ cursor: 'pointer' }}
                />
            </FloatingLabel>
            <Dropdown.Menu align="start" className={position} style={{ padding: '15px', width: '500px' }}>
                {state.loading
                    ?
                    <Loader />
                    :
                    state.data.length === 0
                        ?
                        <NoDataFound size="sm">
                            Няма информация за показване!
                        </NoDataFound>
                        :
                        <>
                            <div className="details-filter">
                                {state.data.map(detail =>
                                    <ArticleDetailValue
                                        key={detail.id}
                                        detail={detail}
                                        isValueSelected={isValueSelected}
                                        onChange={selectValue}
                                        setDetailValues={setDetailValues}
                                    />
                                )}
                            </div>

                            <Button size="sm" className="mt-3" style={{ width: '100%' }} onClick={clear}>Изчисти всички</Button>
                        </>
                }
            </Dropdown.Menu>
        </Dropdown >
    )
}

export default ArticleDetail;