import { useAuthDataContext } from "providers/Auth";
import { useState } from "react";
import { Button, Offcanvas } from "react-bootstrap";
import { List } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

function MobileNavBar(props) {

    const auth = useAuthDataContext();

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const user = auth.getUser();

    return (
        <>
            <Button variant="outline-dark" onClick={handleShow}>
                <List />
            </Button>

            <Offcanvas show={show} onHide={handleClose} placement="end">
                <Offcanvas.Header>
                    <Offcanvas.Title>
                        <span>
                            {user?.getName() || user?.getUsername()}
                        </span>
                    </Offcanvas.Title>
                    <Link className="nav-link" to="/logout">Изход</Link>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <ul className="mobile-nav nav">
                        {props.children}
                    </ul>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default MobileNavBar;