import Container from "Container";

class Middleware {

    /**
     * 
     * @param {Container} app 
     */
    constructor(app) {
        this.app = app;
        this.auth = app.getAuth();
        this.user = app.getUser();
        this.navigate = app.getNavigate();
        this.location = app.getLocation();
        this.query = app.getQuery();
    }

    redirect(to, from = null) {
        this.navigate(to, {
            replace: true,
            state: {
                from: from
            }
        });
    }

    redirectWithState(to, state = {}) {
        this.navigate(to + '?' + new URLSearchParams(state).toString(), {
            replace: true,
            // state: state
        });
    }

    run(props, next) {

    }
}

export default Middleware;