import React, { useState, useImperativeHandle, forwardRef, useEffect, useRef } from 'react'
import { Modal, Button, FloatingLabel, Form, Row, Col } from 'react-bootstrap';
import SaveButton from 'components/misc/Button';
import Loader from 'components/misc/Loader';
import Api from 'helpers/Api';
import { useValidation } from 'helpers/Validation';
import Error from 'components/modals/Error';
import Info from 'components/misc/Info';
import DatePicker from 'components/partials/filter/DatePicker';
import { useAuthDataContext } from 'providers/Auth';

function New(props, ref) {

    const auth = useAuthDataContext();

    const defaultData = {};

    const [state, setState] = useState({
        show: false,
        params: null,
        data: defaultData,
        loading: false,
        onSuccess: null,
        onClose: null,
    });

    const [depots, setDepots] = useState([]);

    const [validations, setValidations] = useValidation();

    const errorModalRef = useRef(null);

    useImperativeHandle(ref, () => ({
        open: () => {
            handleShow();
        },
        close: () => {
            handleClose();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        }
    }));

    useEffect(() => {
        Api.get('revisions/depots')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setDepots(res.data);
                }
            });
    }, [auth.getUser().getStoreId()]);

    const handleShow = () => {
        setState(prev => ({
            ...prev,
            show: true,
        }));
    }

    const handleClose = () => {
        setState(prev => ({
            ...prev,
            show: false,
            data: defaultData
        }));

        setValidations(null);

        if (typeof state.onClose === 'function') {
            state.onClose();
        }
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: value
            }
        }));
    }

    const handleSave = e => {
        e.preventDefault();

        setValidations(null);

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        let url = 'revisions/add';

        let data = new FormData(e.target);

        Api.post(url, data)
            .then(res => {
                if (typeof state.onSuccess === 'function') {
                    state.onSuccess(res.data);
                }
            })
            .catch(error => {
                const _err = error.response;

                if (_err && _err.status) {
                    if (_err.status === 422) {
                        setValidations(_err.data.errors);
                    } else {
                        let modal = errorModalRef.current;

                        if (modal) {
                            modal.open(_err.data.error || _err.data.message);
                        }
                    }
                }

            }).finally(() => {
                setState(prev => ({
                    ...prev,
                    loading: false,
                }));
            });
    }

    return (
        <>

            <Error
                ref={errorModalRef}
            />

            <Modal centered show={state.show} onHide={handleClose}>
                <Form noValidate onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Нова ревизия
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FloatingLabel label="Склад" className="mb-3">
                            <Form.Select
                                name="depot_id"
                                value={state.data.depot_id || ''}
                                onChange={handleInputChange}
                                isInvalid={Boolean(validations && validations.depot_id)}
                            >
                                <option value=""></option>
                                {depots.map(o =>
                                    <option key={o.id} value={o.id}>{o?.store?.translation?.name || o?.store?.name} - {o?.translation?.name || o.name}</option>
                                )}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                {validations && validations.depot_id && (validations.depot_id[0] || validations.depot_id)}
                            </Form.Control.Feedback>
                        </FloatingLabel>

                        <FloatingLabel label="Вид" className="mb-3">
                            <Form.Select
                                name="view_type_id"
                                value={state.data.view_type_id || ''}
                                onChange={handleInputChange}
                                isInvalid={Boolean(validations && validations.view_type_id)}
                            >
                                <option value={1}>Всички артикули по категории</option>
                                <option value={2}>Избрани артикули</option>
                                <option value={3}>Всички артикули с наличност</option>
                                <option value={4}>Всички артикули</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                {validations && validations.view_type_id && (validations.view_type_id[0] || validations.view_type_id)}
                            </Form.Control.Feedback>
                        </FloatingLabel>

                        <Row>
                            <Col>
                                <FloatingLabel label="Наличност към" className="mb-3">
                                    <Form.Select
                                        name="date_type"
                                        value={state.data.date_type || ''}
                                        onChange={handleInputChange}
                                        isInvalid={Boolean(validations && validations.view_type_id)}
                                    >
                                        <option value="now">Наличност към момента</option>
                                        <option value="date">Наличност към дата</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                            <Col className="mb-3">
                                <DatePicker
                                    placeholder="Дата"
                                    type="datetime-local"
                                    name="to_date"
                                    value={state.data.date_type === 'date' && state.data.to_date ? state.data.to_date : ''}
                                    onChange={handleInputChange}
                                    isInvalid={Boolean(validations?.to_date)}
                                    error={validations?.to_date && (validations.to_date[0] || validations.to_date)}
                                    disabled={state.data.date_type === 'date' ? false : true}
                                    info="Можете да изберете дата, към която искате да бъдат количествата в ревизията. Избраната дата трябва да бъде между датата на последната затворена ревизия за избрания склад и текущия момент."
                                />
                            </Col>
                        </Row>

                        <FloatingLabel label="Калкулация по">
                            <Form.Select
                                name="price_mode_id"
                                value={state.data.price_mode_id || ''}
                                onChange={handleInputChange}
                                isInvalid={Boolean(validations && validations.price_mode_id)}
                            >
                                <option value={1}>Продажни цени</option>
                                <option value={2}>Доставни цени</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                {validations && validations.price_mode_id && (validations.price_mode_id[0] || validations.price_mode_id)}
                            </Form.Control.Feedback>
                        </FloatingLabel>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                        />
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

export default forwardRef(New);