import { Dropdown } from 'react-bootstrap'
import { ThreeDotsVertical } from 'react-bootstrap-icons';
import MenuOptionsDropdown from './MenuOptionsDropdown';

function MenuOptions(props) {
    return (
        <div className="dropdown-options">
            <Dropdown drop="down">
                <Dropdown.Toggle as="span" variant="outline-dark">
                    <ThreeDotsVertical />
                </Dropdown.Toggle>

                <MenuOptionsDropdown {...props} />
            </Dropdown>
        </div>
    )
}

export default MenuOptions;