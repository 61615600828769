import { isFloat, isNumeric } from "helpers/Price";

function PriceValue(props) {
    let value = props.children;
    let prefix = props.prefix;
    let sufix = props.sufix;

    let pad = isNumeric(props.pad) ? props.pad : 2;

    const getText = () => {
        // if (value === null) {
        //     return null;
        // }

        if (isNumeric(value)) {
            // value = value.toFixed(10);

            // if (value === 0) {
            //     return 0;
            // }

            if (!isFloat(value)) {
                value = parseFloat(value || 0).toFixed(pad);
            }

            // da se mahne, ako trqbva da se pokazvat poveche simvoli
            value = parseFloat(value || 0).toFixed(pad);

            value = value.replace(/\d(?=(\d{3})+\.)/g, '$&,');

            let str = value.toString();
            let parts = str.split('.');

            let part1 = parts[0];
            let part2 = '';
            let part3 = '';

            if (parts[1]) {
                parts[1].split('').map((d, i) => {
                    if (i < 2) {
                        part2 += d;
                    } else {
                        part3 += d;
                    }
                });
            }

            // console.log(part1);
            // console.log(part2);
            // console.log(part3);

            let price = [part1, part2.padEnd(pad, '0')].filter(p => p).join('.');
            let overflow = part3;

            return (
                <span>
                    {prefix &&
                        <span style={{ marginRight: '3px' }}>
                            {prefix}
                        </span>
                    }
                    <span style={{}}>
                        {price}
                    </span>
                    {overflow &&
                        <span style={{ color: '#999', fontWeight: '100' }}>
                            {overflow}
                        </span>
                    }
                    {sufix &&
                        <span style={{ marginLeft: sufix === '%' ? '0' : '3px' }}>
                            {sufix}
                        </span>
                    }
                </span>
            )
        } else {
            return (
                <span>
                    {prefix &&
                        <span style={{ marginRight: '3px' }}>
                            {prefix}
                        </span>
                    }
                    <span>{parseFloat(value || 0).toFixed(pad)}</span>
                    {sufix &&
                        <span style={{ marginLeft: sufix === '%' ? '0' : '3px' }}>
                            {sufix}
                        </span>
                    }
                </span>
            )
        }
    }

    return (
        getText()
    )
}

export default PriceValue;