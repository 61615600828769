import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState, Fragment } from 'react'
import { CaretRight, CaretDownFill } from 'react-bootstrap-icons'

//plugins
import { Modal, Button, FloatingLabel, Form, Row, Col } from 'react-bootstrap';

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//misc
import SaveButton from 'components/misc/Button';

import './style.scss'

function Categories(props, ref) {
    const validationModalRef = useRef(null);

    const id = props.id

    const defaultData = {
        categories: [],
    }

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        edit: false,
        data: defaultData,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null,
        onClose: null,
        refresh: null
    });

    const [categories, setCategories] = useState({
        data: [],
        show: false,
        toggled: [],
        selected: [],
        loading: true,
    });

    const [validations, setValidations] = useValidation();

    useImperativeHandle(ref, () => ({
        add: () => {
            handleShow();
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        }
    }));

    useEffect(() => {
        if (!state.show) {
            return;
        }

        Api.get('price_rules/categories')
            .then(res => {
                let categories = res.data;

                if (Array.isArray(res.data)) {
                    setCategories(prev => ({
                        ...prev,
                        data: res.data,
                        loading: false,
                        toggled: res.data.filter(c => c.children.length < 10).map(c => c.id),
                    }));
                }

                Api.get('price_rules/categories/show?id=' + id)
                    .then(res => {
                        let data = res.data;
                        let selected = [];

                        if (data.length > 0) {
                            data.map(t => {
                                selected.push(Number(t.category_id))
                            })
                        }

                        setCategories(prev => ({
                            ...prev,
                            selected: selected,
                        }));

                        selected.map(id => {
                            let category = findCategory(id, categories);

                            if (category) {
                                while (category) {
                                    category = category.parent;

                                    if (category) {
                                        expandCategory(category, false);
                                    }
                                }
                            }
                        });
                    });
            });
    }, [state.show]);

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const handleShow = () => {
        show();
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
        }));

        setValidations(null);

        if (typeof state.onClose === 'function') {
            hide(state.onClose);
        } else {
            hide();
        }
    }

    const handleSave = e => {
        e.preventDefault();

        if (!id) return

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = 'price_rules/categories/save';

        let data = new FormData(e.target);

        data.append('id', id)

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (typeof state.onSuccess === 'function') {
                state.onSuccess(res.data)
            }

            hide()
        }).catch(error => {
            const _err = error.response;
            if (_err && _err.status && _err.status === 422) {
                hide(() => {
                    let modal = validationModalRef.current;

                    if (modal) {
                        modal.open();

                        modal.onClose(() => {
                            setValidations(_err.data.errors)
                            show();
                        });
                    }
                });
            }

        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }));
        });
    }

    const findCategory = (id, categories, recursive = true) => {

        for (let i = 0; i < categories.length; i++) {
            let category = categories[i];

            if (Number(category.id) === Number(id)) {
                return category;
            }

            let found = findCategory(id, category.children);

            if (found) {
                return found;
            }

        }

    }

    const toggleCategories = () => {
        setCategories(prev => ({
            ...prev,
            show: !prev.show,
        }))
    }

    const expandCategory = (category, check = true) => {

        if (!category) {
            return;
        }

        if (check && isCategoryExpanded(category)) {
            setCategories(prev => ({
                ...prev,
                toggled: prev.toggled.filter(i => Number(i) !== Number(category.id))
            }));
        } else {
            setCategories(prev => ({
                ...prev,
                toggled: prev.toggled.concat(Number(category.id)),
            }));
        }
    }

    const isCategoryExpanded = category => {

        if (!category) {
            return false;
        }

        let expanded = categories.toggled.indexOf(Number(category.id)) > -1;

        if (expanded) {
            return true;
        }

        return false;
    }

    const isCategorySelected = (category, recursive = true) => {

        if (!category) {
            return false;
        }

        let selected = categories.selected.indexOf(Number(category.id)) > -1;

        if (selected) {
            return true;
        }

        if (recursive && category.parent) {
            return isCategorySelected(category.parent);
        }

        return false;
    }


    const toggleCategory = category => {

        if (isCategorySelected(category, true) && !isCategorySelected(category, false)) {

            // todo
        }

        let selected = [...categories.selected];

        if (isCategorySelected(category, false)) {
            selected = selected.filter(i => Number(i) !== Number(category.id));
        } else {
            selected = selected.concat(Number(category.id));
        }

        setCategories(prev => ({
            ...prev,
            selected: selected
        }));
    }

    const renderCategories = (categories, level = 0) => {
        return categories.map(category =>
            <React.Fragment key={category.id}>
                <div style={{ display: level === 0 || isCategoryExpanded(category.parent) ? 'flex' : 'none', alignItems: 'center', paddingLeft: (level * 20) + 'px', }}>

                    <span
                        style={{ marginRight: '3px', marginBottom: '3px', cursor: 'pointer', width: '16px' }}
                        onClick={e => expandCategory(category)}
                    >
                        {category.children.length > 0
                            ?
                            isCategoryExpanded(category) ? <CaretDownFill size="13" /> : <CaretRight size="13" />
                            :
                            ''
                        }
                    </span>

                    <Form.Check
                        type="checkbox"
                        id={'cbc-' + category.id}
                        name="categories[]"
                        value={category.id}
                        label={category?.translation?.name || category.name}
                        checked={isCategorySelected(category)}
                        disabled={isCategorySelected(category.parent)}
                        onChange={e => toggleCategory(category)}
                        style={{
                            minHeight: 'auto',
                            margin: 0
                        }}
                    />
                </div>
                {isCategoryExpanded(category) && renderCategories(category.children, level + 1)}
            </React.Fragment>
        );
    }

    return (
        <>
            <Modal size="lg" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <form onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Масов избор на артикули по категории
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ marginLeft: '20px' }} className="categories">
                            {renderCategories(categories.data)}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            className="save"
                        />
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default forwardRef(Categories);
