import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom"
import moment from "moment"
import { FloatingLabel, Form, Col } from 'react-bootstrap'

//helpers
import { useQuery } from 'helpers/Url'

//misc
import Autocomplete from "components/misc/Autocomplete"

function DeviceSelectionFilter(props) {
    const history = useNavigate()
    const query = useQuery()
    const categories = props.categories

    const handleSearch = e => {
        const name = e.target.name
        const value = e.target.value

        props.handleSearch(name, value)
    }

    const handleTypeChange = data => {
        let checked = []
        data.map(t => {
            checked.push(t.id)
        })

        props.handleSearch('category_id', checked)
    }


    return (
        <div className="row page-filter">
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <FloatingLabel label="Търсене">
                    <Form.Control
                        type="text"
                        placeholder="Търсене"
                        name="search"
                        value={props.filter.search || ''}
                        onChange={handleSearch}
                    />
                </FloatingLabel>
            </div>

            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <Col>
                    <Autocomplete
                        inputPlaceholder="Тип"
                        renderText={data => data?.name}
                        data={categories}
                        selectedIds={props.filter?.category_id || []}
                        onChange={data => handleTypeChange(data)}
                        multiple
                        filter
                    />
                </Col>
            </div>
        </div>
    )
}

export default DeviceSelectionFilter;
