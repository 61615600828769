import PageLoader from "components/misc/PageLoader";
import { buildUrl } from "helpers/Url";
import { useAuthDataContext } from "providers/Auth";
import { useEffect } from "react";

function Index() {
    const auth = useAuthDataContext();

    useEffect(() => {
        let url = buildUrl(process.env.REACT_APP_URL + '/api/admin/pages/redirect', {
            token: auth.getToken()
        });

        // console.log(url)

        window.location.replace(url);
    }, []);

    return (
        <PageLoader />
    )
}

export default Index;