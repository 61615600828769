import { createContext, useContext, useMemo, useState } from 'react';
import { useNestedState } from 'helpers/NestedState';
import { useValidation } from 'helpers/Validation';

const ClientContext = createContext({});

const Provider = props => {

    const defaultData = {
        langs: {},
        file: null
    }

    const [state, setState] = useNestedState({
        id: null,
        data: defaultData,
        initialData: defaultData,
        loading: false,
        mainTabId: 'main',
    });

    const [validations, setValidations] = useValidation();

    const resetData = () => {
        setState(prev => ({
            ...prev,
            id: null,
            data: defaultData,
            loading: false,
            mainTabId: 'main',
        }));

        setValidations(null);
    }

    const value = {
        state,
        setState,
        validations,
        setValidations,
        resetData,
    }

    return <ClientContext.Provider value={value} {...props} />;
}

export const useClientContext = () => useContext(ClientContext);

export default Provider;