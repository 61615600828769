import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import { sortable } from 'react-sortable';
import moment from 'moment'
import { Modal, Button, Tabs, Tab, FloatingLabel, Form, Alert, Row, Col, Card } from 'react-bootstrap';
import {
    TrashFill,
} from 'react-bootstrap-icons';

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//providers
import { useLanguageContext } from 'providers/Language'

//misc
import SaveButton from 'components/misc/Button'
import Info from 'components/modals/Info';
import DatePicker from '../../../partials/filter/DatePicker';

const WEEK_DAYS = [
    {
        'id': 1,
        'name': 'Понеделник'
    },
    {
        'id': 2,
        'name': 'Вторник'
    },
    {
        'id': 3,
        'name': 'Сряда'
    },
    {
        'id': 4,
        'name': 'Четвъртък'
    },
    {
        'id': 5,
        'name': 'Петък'
    },
    {
        'id': 6,
        'name': 'Събота'
    },
    {
        'id': 7,
        'name': 'Неделя'
    }
]

function SortableItem(props) {
    return (
        <div {...props}>
            {props.children}
        </div>
    )
}

const SortItem = sortable(SortableItem);

function AddOrEdit(props, ref) {

    const defaultData = {
        logo: {},
        option: {
            files: [],
            times: []
        }
    }

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        edit: false,
        data: defaultData,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null
    });

    const [validations, setValidations] = useValidation();

    const inputFileRef = useRef(null);
    const inputGalleryRef = useRef(null);
    const validationModalRef = useRef(null);

    useImperativeHandle(ref, () => ({
        add: () => {
            handleShow();
        },
        edit: (id) => {
            edit(id);
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        }
    }));

    useEffect(() => {
        loadData();
    }, [state.edit]);

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const handleShow = () => {
        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
        }));
        show();
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
        }));

        setValidations(null);

        hide();
    }

    const edit = id => {
        setState(prev => ({
            ...prev,
            id: id,
            edit: new Date().getTime()
        }));

        show();
    }

    const loadData = () => {
        if (!state.id) {
            return;
        }

        Api.get('stores_options/show?id=' + state.id)
            .then(res => {

                let data = res.data;

                data.logo = {}
                if (!data.option.id) {
                    data.option = {
                        times: []
                    }
                }
                data.option.logo = data.option.logo_url

                setState(prev => ({
                    ...prev,
                    data
                }))
            });
    }


    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = 'stores_options/update';

        let data = new FormData(e.target);

        if (state.id) {
            data.append('store_id', state.id)
        }

        if (state.data.option.logo.was_recently_attached) {
            data.append('logo', state.data.option.logo);
        }

        state.data.option.files.map((file, i) => {
            if (file.was_recently_attached) {
                data.append('files[' + i + ']', file);
            }
        });

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (typeof state.onSuccess === 'function') {
                state.onSuccess(res.data)
            }

            hide()
        }).catch(error => {
            const _err = error.response;
            if (_err && _err.status && _err.status === 422) {
                hide(() => {
                    let modal = validationModalRef.current;

                    if (modal) {
                        modal.open();

                        modal.onClose(() => {
                            setValidations(_err.data.errors)
                            show();
                        });
                    }
                });
            }

        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }));
        });
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleTimeChange = (e, index) => {
        const value = e.target.value
        let name = e.target.name

        name = name.split('[')[0]

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                option: {
                    ...prev.data.option,
                    times: Object.values({
                        ...prev.data.option.times,
                        [index]: {
                            ...prev.data.option.times[index],
                            [name]: value
                        }
                    })
                }
            }
        }));
    }

    const removeTime = (index) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                option: {
                    ...prev.data.option,
                    times: prev.data.option.times.filter((d, i) => Number(i) !== Number(index))
                }
            }
        }));
    }

    const addTime = () => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                option: {
                    ...prev.data.option,
                    times: prev.data.option.times.concat({})
                }
            }
        }));
    }

    const handleShowAttach = e => {
        e.preventDefault();

        inputFileRef.current.click();
    }

    const handleAttach = e => {
        let file = e.target.files[0];
        let url = URL.createObjectURL(file);

        file.id = Math.random().toString(7).substring(2);
        file.was_recently_attached = true;
        file.url = url;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                option: {
                    ...prev.data.option,
                    logo: file
                }
            }
        }));
    }

    const handleShowAttachGallery = e => {
        e.preventDefault();

        inputGalleryRef.current.click();
    }

    const handleAttachGallery = e => {
        let files = [...e.target.files];

        files.forEach((f, i) => {
            let url = URL.createObjectURL(f);

            files[i].id = Math.random().toString(7).substring(2);
            files[i].was_recently_attached = true;
            files[i].url = url;
        });

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                // files:
                option: {
                    ...prev.data.option,
                    files: prev.data.option.files.concat(files)
                }
            }
        }));
    }

    const handleDetachImage = (id, index) => {

        let file = state.data.option.files.filter((f, i) => {
            return Number(f.id) === Number(id);
        })[0];

        if (!file.was_recently_attached) {
            Api.post('stores_options/delete-file', {
                id: id
            }).then(res => {
                if (res.data.success) {
                    removeImage(id);
                }
            });
        } else {
            removeImage(id);
        }

        if (validations?.files && validations.files[index]) {
            delete validations.files[index];
        }

        setValidations(validations);
    }

    const removeImage = id => {
        let files = state.data.option.files.filter((f, i) => {
            return Number(f.id) !== Number(id);
        });

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                option: {
                    ...prev.data.option,
                    files: files
                }
            }
        }));
    }

    const handleSortFiles = items => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                option: {
                    ...prev.data.option,
                    files: items
                }
            }
        }));
    }

    return (
        <>
            <Info
                ref={validationModalRef}
                mainMessage="Моля, попълнете всички задължителни полета, означени с червен цвят!"
            />

            <Modal size="lg" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <form onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Търговски обект
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        {/* ЛОГО */}
                        <Col className="mb-3">
                            <div style={{ height: !state.data.option?.logo?.url ? '0' : 'auto' }} className={state.data.option?.logo?.url ? "mb-3" : ""}>
                                <>
                                    <div className="file">
                                        <img style={{ maxHeight: '100px' }} src={state.data.option?.logo?.url} onError={e => e.target.classList.add('hidden')} />
                                    </div>

                                    <div className="validate">
                                        {validations?.image}
                                    </div>
                                </>
                            </div>

                            <Button variant="outline-dark" size="sm" onClick={handleShowAttach}>
                                Прикачете лого
                            </Button>

                            <input ref={inputFileRef} type="file" accept=".png,.jpeg,.jpg" onChange={handleAttach} hidden />
                        </Col>

                        <Card className='mb-3'>
                            <Card.Header>
                                Работно време на обекта
                            </Card.Header>
                            <Card.Body>
                                {
                                    state.data.option?.times.length == 0 ?

                                        <Alert variant="primary">
                                            Няма информация за работно време
                                        </Alert>
                                        :
                                        state.data.option?.times.map((t, index) =>
                                            <Row key={`t-${index}`}>

                                                <Form.Control
                                                    key={`time-${t.id}`}
                                                    type="hidden"
                                                    name={`time_id[]`}
                                                    value={t.id || ''}
                                                />
                                                <Col>
                                                    <FloatingLabel label="Ден от седмицата*">
                                                        <Form.Select
                                                            name="day_id[]"
                                                            value={t.day_id || ''}
                                                            onChange={(e) => handleTimeChange(e, index)}
                                                            isInvalid={Boolean(validations && validations.pricelist_type_id)}
                                                        >
                                                            {WEEK_DAYS.map(t =>
                                                                <option key={`weekday-${t.id}`} value={t.id}>{t.name}</option>
                                                            )}
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.pricelist_type_id && (validations.pricelist_type_id[0] || validations.pricelist_type_id)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>

                                                <Col className="mb-3">
                                                    <DatePicker
                                                        type="time"
                                                        placeholder="От час"
                                                        name={`from_time[]`}
                                                        onChange={(e) => handleTimeChange(e, index)}
                                                        value={t.from_time || ''}
                                                        isInvalid={Boolean(validations?.from_time && validations.from_time[index])}
                                                        error={validations?.from_time && validations.from_time[index]}
                                                    />
                                                </Col>
                                                <Col className="mb-3">
                                                    <DatePicker
                                                        type="time"
                                                        placeholder="До час"
                                                        name={`to_time[]`}
                                                        onChange={(e) => handleTimeChange(e, index)}
                                                        value={t.to_time || ''}
                                                        isInvalid={Boolean(validations?.to_time && validations.to_time[index])}
                                                        error={validations?.to_time && validations.to_time[index]}
                                                    />
                                                </Col>
                                                <Col className="mb-3" style={{ display: 'flex', alignItems: 'center' }} xs="auto">
                                                    <Button variant="danger" size="sm" onClick={e => removeTime(index)}>
                                                        <TrashFill />
                                                    </Button>
                                                </Col>
                                            </Row>)}
                                <Button variant="outline-dark" size="sm" onClick={addTime}>
                                    Добави период по час
                                </Button>
                            </Card.Body>
                        </Card>

                        <Card>
                            <Card.Header>
                                Галерия
                            </Card.Header>
                            <Card.Body>
                                {state.data.option.files.length === 0
                                    ?
                                    <Alert variant="primary">
                                        Няма прикачени изображения
                                    </Alert>
                                    :
                                    <>
                                        <Alert variant="primary">
                                            Можете да размествате подредбата на изображенията като придърпвате дадено изображение измежду останалите.
                                        </Alert>
                                        <div className="files mb-3 sortable-list">
                                            {state.data.option.files.map((file, index) =>
                                                <SortItem
                                                    key={index}
                                                    items={state.data.option.files}
                                                    onSortItems={handleSortFiles}
                                                    sortId={index}
                                                    className="file-container"
                                                >
                                                    <div className="file">

                                                        {file.type === 'video/mp4'
                                                            ?
                                                            <video src={file.url} onError={e => e.target.classList.add('hidden')} />
                                                            :
                                                            <img src={file.url} onError={e => e.target.classList.add('hidden')} />
                                                        }

                                                        <div className="buttons">
                                                            <Button variant="danger" size="sm" onClick={e => handleDetachImage(file.id, index)}>
                                                                <TrashFill />
                                                            </Button>
                                                        </div>
                                                    </div>

                                                    <div className="validate">
                                                        {validations?.files && validations.files[index] && (validations.files[index][0] || validations.files[index])}
                                                    </div>

                                                    <input name={`files_position[${index}]`} value={file.was_recently_attached ? 0 : file.id} hidden readOnly />
                                                </SortItem>

                                            )}
                                        </div>
                                    </>
                                }

                                <Button variant="outline-dark" size="sm" onClick={handleShowAttachGallery}>
                                    Прикачете изображения
                                </Button>

                                <input style={{ display: 'none' }} ref={inputGalleryRef} type="file" accept=".png,.jpeg,.jpg,.gif,.webp,.mp4" onChange={handleAttachGallery} multiple />
                            </Card.Body>
                        </Card>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            className="save"
                        />
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default forwardRef(AddOrEdit);
