export const WARRANTY = 1;
export const CONTRACT = 2;
export const OFFER = 3;
export const PPP = 4;

export const TYPES = [
    {
        id: WARRANTY,
        type: 'warranty',
        name: 'Гаранция',
        new: '/warranty/new'
    },
    {
        id: CONTRACT,
        type: 'contract',
        name: 'Договор',
        new: '/contracts/new'
    },
    // {
    //     id: OFFER,
    //     type: 'offer',
    //     name: 'Оферта',
    //     new: '/offers/new',
    // },
    {
        id: PPP,
        type: 'protocol',
        name: 'Приемо-предавателен протокол',
        new: '/protocols/new'
    },
];