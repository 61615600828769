import { useEffect, useRef, useState } from 'react';
import Api from 'helpers/Api';
import { useQuery } from 'helpers/Url';
import { useLocation, useNavigate, Link, useParams } from 'react-router-dom';
import { Alert, Button, Table, FloatingLabel, Form, Row, Col, Container, InputGroup } from 'react-bootstrap';
import { ArrowLeftCircle, Rainbow, TrashFill, ArrowLeftRight, ArrowsMove } from 'react-bootstrap-icons';
import { useNestedState } from 'helpers/NestedState';
import { useCurrencyContext } from 'providers/Currency';
import { useValidation } from 'helpers/Validation';
import { useAuthDataContext } from 'providers/Auth';
import { cloneDeep } from 'lodash';
import { sortable } from 'react-sortable';

import Question from 'components/modals/Question';
import Layout from 'components/layout/Layout';

//misc
import Loader from 'components/misc/Loader';
import moment from 'moment';
import Autocomplete from 'components/misc/Autocomplete';
import SaveButton from 'components/misc/Button';
import { addExtraPrice, calcExtraPrice, isNumeric, removeExtraPrice, vatPrice } from 'helpers/Price';
import Info from 'components/misc/Info';
import Error from 'components/modals/Error';
import Refs from 'Refs';
import { WARRANTY } from 'constants/documents';
import DatePicker from 'components/partials/filter/DatePicker';

function SortableItem(props) {
    return (
        <tr {...props}>
            {props.children}
        </tr>
    )
}

const SortItem = sortable(SortableItem);

let timeout;

function Index() {

    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const query = useQuery();

    const auth = useAuthDataContext();
    const currencies = useCurrencyContext();

    const [state, setState] = useNestedState({
        loading: false,
        loadingBase: false,
        dataLoading: false,
        data: {
            is_template: false,
            rows: [{}],
        },
        timeout: null
    });


    const [statuses, setStatuses] = useState([]);
    const [admins, setAdmins] = useState([]);

    const [validations, setValidations] = useValidation();

    const formRef = useRef(null);
    const errorModalRef = useRef(null);

    useEffect(() => {
        if (params.id) {
            loadData();
        }
    }, [params.id]);

    // зареждане на данни от шаблон
    useEffect(() => {
        if (params.id) {
            return;
        }

        // от шаблон
        if (query.get('from_template_id')) {
            loadDataFromTemplate();
        }

        // от документ (нова версия)
        if (query.get('from_document_id')) {
            loadDataFromDocument();
        }

        // от сметка
        if (query.get('from_account_id')) {
            loadDataFromAccount();
        }

        // от прехвърляне
        if (query.get('from_move_id')) {
            loadDataFromStoreMove();
        }

    }, [location]);

    useEffect(() => {
        Api.get('documents/statuses', {
            params: {
                type_id: WARRANTY
            }
        }).then(res => {
            if (Array.isArray(res.data)) {
                setStatuses(res.data);
            }
        });

        Api.get('documents/admins').then(res => {
            if (Array.isArray(res.data)) {
                setAdmins(res.data);
            }
        });
    }, []);

    useEffect(() => {
        if (state.data.rows.length === 0) {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    rows: [{}]
                }
            }));
        }
    }, [state.data.rows]);

    const loadData = () => {

        loading(true);

        let url = 'documents/show';

        Api.get(url, {
            params: {
                id: params.id
            }
        }).then(res => {
            handleResponse(res);
        }).catch(err => {
            handleError(err);
        }).finally(() => {
            loading(false);
        })
    }

    const loadDataFromTemplate = () => {
        loading(true);

        let url = 'documents/from-template';

        Api.get(url, {
            params: {
                template_id: query.get('from_template_id'),
            }
        }).then(res => {
            handleResponse(res);
        }).catch(err => {
            handleError(err);
        }).finally(() => {
            loading(false);
        })
    }

    const loadDataFromDocument = () => {
        loading(true);

        let url = 'documents/from-document';

        Api.get(url, {
            params: {
                document_id: query.get('from_document_id'),
            }
        }).then(res => {
            handleResponse(res);
        }).catch(err => {
            handleError(err);
        }).finally(() => {
            loading(false);
        })
    }

    const loadDataFromAccount = () => {
        loading(true);

        let url = 'documents/from-account';

        Api.get(url, {
            params: {
                account_id: query.get('from_account_id'),
            }
        }).then(res => {
            handleResponse(res);
        }).catch(err => {
            handleError(err);
        }).finally(() => {
            loading(false);
        })
    }

    const loadDataFromStoreMove = () => {
        loading(true);

        let url = 'documents/from-storemove';

        Api.get(url, {
            params: {
                move_id: query.get('from_move_id'),
            }
        }).then(res => {
            handleResponse(res);
        }).catch(err => {
            handleError(err);
        }).finally(() => {
            loading(false);
        })
    }

    const handleResponse = res => {
        let data = res.data;

        let rows = data.rows || [];

        // data.currency_rate = data.currency?.rate;

        rows = rows.map(row => {

            return row;
        });

        data.rows = rows;

        setState(prev => ({
            ...prev,
            data: data,
        }));
    }

    const handleError = error => {
        console.log(error);

        const _err = error.response;

        if (_err && _err.status) {
            if (_err.status === 422) {
                setValidations(_err.data.errors);
            } else {
                let modal = errorModalRef.current;

                if (modal) {
                    modal.open(_err.data.error || _err.data.message);

                    modal.onClose(() => {
                        navigate(-1, {
                            replace: true
                        });
                    });
                }
            }
        }
    }

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            dataLoading: Boolean(loading)
        }));
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleCheckboxChange = e => {
        const name = e.target.name;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: prev.data[name] ? false : true
            }
        }));
    }

    const handleChangeClient = data => {

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                client: data,
                client_id: data?.id || '',
            }
        }));
    }

    const handleAddClient = () => {
        let modal = Refs.getInstance().getRef('newClient');

        if (modal) {
            modal.add();
            modal.onSuccess(client => {
                if (client) {
                    handleChangeClient(client);
                }
            });
        }
    }

    const save = () => {
        if (state.loading) {
            return;
        }

        setState(prev => ({
            ...prev,
            loading: true,
            loadingBase: true,
        }));

        setValidations(null);

        let url = params.id ? 'documents/edit' : 'documents/add';

        let form = formRef.current;

        let data = new FormData(form);

        data.append('type_id', WARRANTY);

        if (params.id) {
            data.append('id', params.id)
        }

        if (query.get('from_template_id')) {
            data.append('from_template_id', query.get('from_template_id'));
        }

        if (query.get('from_document_id')) {
            data.append('from_document_id', query.get('from_document_id'));
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            navigate('/warranty');
        }).catch(error => {
            const _err = error.response;

            if (_err && _err.status) {
                if (_err.status === 422) {
                    setValidations(_err.data.errors);
                } else {
                    let modal = errorModalRef.current;

                    if (modal) {
                        modal.open(_err.data.error || _err.data.message);
                    }
                }
            }

        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
                loadingBase: false,
            }));
        });
    }

    const handleSave = e => {
        e.preventDefault();

        save();
    }

    const handleAddRow = e => {
        e.preventDefault()

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: prev.data.rows.concat({})
            }
        }))
    }

    const handleRemoveRow = (e, index) => {
        e.preventDefault()

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: prev.data.rows.filter((row, i) => i !== index)
            }
        }))
    }

    const handleChangeArticle = (data, index) => {

        let row = state.data.rows[index];

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: Object.values({
                    ...prev.data.rows,
                    [index]: {
                        ...prev.data.rows[index],
                        article: data,
                        article_id: data.id,
                    }
                })
            }
        }));

    }

    const handleSortRows = items => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: items
            }
        }));
    }

    return (
        <>

            <Error
                ref={errorModalRef}
            />

            <Layout>
                <div className="container-fluid">

                    <div className="page-head">
                        <h1 className="display-6">
                            <Link to={-1} className="link-dark text-decoration-none">
                                <ArrowLeftCircle />
                            </Link> {params.id ? 'Редактиране на гаранция' : 'Нова гаранция'}
                        </h1>

                        <div className="buttons">

                        </div>
                    </div>

                    <br />

                    <div className="panel">

                        {state.dataLoading
                            ?
                            <Loader />
                            :
                            <>
                                <Form ref={formRef}>
                                    {/* <Container> */}
                                    <div className="form-panel">
                                        <div className="head">
                                            Данни за документа
                                        </div>
                                        <Row>
                                            <Col className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Гаранция
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder=""
                                                            name="ref"
                                                            value={state.data.id || ''}
                                                            readOnly
                                                            size="sm"
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Създал
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder=""
                                                            name="ref"
                                                            value={state.data?.creator?.username || ''}
                                                            readOnly
                                                            size="sm"
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Създадено на
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder=""
                                                            name="ref"
                                                            value={state.data.created_at ? moment(state.data.created_at).format('DD.MM.YYYY HH:mm') : ''}
                                                            readOnly
                                                            size="sm"
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Отговорник
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Select
                                                            name="owner_id"
                                                            value={state.data?.owner_id || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations?.owner_id)}
                                                            size="sm"
                                                        >
                                                            <option value=""></option>
                                                            {admins.map(o =>
                                                                <option key={o.id} value={o.id}>{o.name}</option>
                                                            )}

                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations?.owner_id && (validations.owner_id[0] || validations.owner_id)}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Клиент
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Autocomplete
                                                            variant="basic"
                                                            url="autocomplete/clients"
                                                            inputName="client_name"
                                                            inputIdName="client_id"
                                                            inputValue={state.data?.client?.name}
                                                            inputIdValue={state.data?.client_id || ''}
                                                            onChange={handleChangeClient}
                                                            onClickAddButton={handleAddClient}
                                                            error={Boolean(validations?.client_id)}
                                                            helperText={validations?.client_id && (validations.client_id[0] || validations.client_id)}
                                                            renderText={data => {
                                                                return (
                                                                    <div style={{ display: 'flex', width: '100%' }}>
                                                                        <div style={{ width: '10%' }}>
                                                                            {data.id}
                                                                        </div>
                                                                        <div style={{ width: '90%' }}>
                                                                            {data.name}
                                                                            <br />
                                                                            {data.email}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }}
                                                            renderInputText={data => data.name}
                                                            size="sm"
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Статус
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Select
                                                            name="status_id"
                                                            value={state.data?.status_id || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations?.status_id)}
                                                            size="sm"
                                                        >
                                                            {statuses.map(o =>
                                                                <option key={o.id} value={o.id}>{o?.translation?.name || o.name}</option>
                                                            )}

                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations?.status_id && (validations.status_id[0] || validations.status_id)}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={4}>
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Име на документа
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Control
                                                            placeholder=""
                                                            name="document_name"
                                                            value={state.data?.document_name || ''}
                                                            onChange={handleInputChange}
                                                            size="sm"
                                                            isInvalid={Boolean(validations?.document_name)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations?.document_name && (validations.document_name[0] || validations.document_name)}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col sm={4} className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Вальор
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <DatePicker
                                                            variant="basic"
                                                            type="datetime-local"
                                                            name="value_date"
                                                            value={state.data?.value_date ? moment(state.data.value_date).format('YYYY-MM-DDTHH:mm') : ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations?.value_date)}
                                                            size="sm"
                                                            error={validations?.value_date && (validations.value_date[0] || validations.value_date)}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col sm={4} className="">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Шаблон <Info>Ако документът бъде маркиран като шаблон, ще имате възможност да го избирате като такъв при съставянето на нов документ</Info>
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <div className="custom-radio">
                                                            <label>
                                                                <input type="radio" name="is_template" value={0} checked={Number(state.data.is_template) === 0} onChange={handleInputChange} />
                                                                <span>
                                                                    НЕ
                                                                </span>
                                                            </label>
                                                            <label>
                                                                <input type="radio" name="is_template" value={1} checked={Number(state.data.is_template) === 1} onChange={handleInputChange} />
                                                                <span>
                                                                    ДА
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                    </div>


                                    <div className="form-panel mt-3">
                                        <div className="head">
                                            Артикули
                                        </div>
                                        <Table className="valign-top big">
                                            <thead>
                                                <tr>
                                                    <th style={{ width: '5%' }}>
                                                        #
                                                    </th>
                                                    <th style={{ width: '45%' }}>
                                                        Артикул
                                                    </th>

                                                    <th style={{ width: '20%' }}>
                                                        Сериен номер
                                                    </th>
                                                    <th style={{ width: '20%' }}>
                                                        Гар. срок
                                                    </th>
                                                    <th className="options">

                                                    </th>
                                                </tr>

                                            </thead>
                                            <tbody>
                                                {state.data.rows.map((row, index) =>
                                                    <SortItem
                                                        key={index}
                                                        items={state.data.rows}
                                                        onSortItems={handleSortRows}
                                                        sortId={index}
                                                        className=""
                                                    >
                                                        <td title={row.id || '-'} style={{ width: '5%' }}>
                                                            {index + 1}

                                                            <input type="hidden" name={`rows[${index}][id]`} value={state.data.rows[index].id || ''} />
                                                            <input type="hidden" name={`rows[${index}][position]`} value={index} />
                                                        </td>
                                                        <td style={{ width: '45%' }}>
                                                            <Autocomplete
                                                                variant="basic"
                                                                url="autocomplete/articles"
                                                                inputName="article_name"
                                                                inputIdName={`rows[${index}][article_id]`}
                                                                inputValue={row.article?.name || ''}
                                                                inputIdValue={row.article_id || ''}
                                                                onChange={data => handleChangeArticle(data, index)}
                                                                error={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].article_id && (validations.rows[index].article_id[0] || validations.rows[index].article_id))}
                                                                helperText={validations && validations.rows && validations.rows[index] && validations.rows[index].article_id && (validations.rows[index].article_id[0] || validations.rows[index].article_id)}
                                                                renderText={data => {
                                                                    return (
                                                                        <div style={{ display: 'flex', width: '100%' }}>
                                                                            <div style={{ width: 'max-content', marginRight: '10px' }}>
                                                                                {data.id}
                                                                            </div>
                                                                            <div>
                                                                                {data.name}
                                                                                <br />
                                                                                {data.article_name}
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }}
                                                                renderInputText={data => data.article_name}
                                                                size="sm"
                                                                listStyle={{
                                                                    minWidth: 'max-content'
                                                                }}
                                                                autoFocus
                                                            />
                                                        </td>

                                                        <td style={{ width: '20%' }}>
                                                            <InputGroup size="sm" style={{ minWidth: '95px' }}>
                                                                <Form.Control
                                                                    type="text"
                                                                    step="0.0001"
                                                                    name={`rows[${index}][serial_number]`}
                                                                    value={row.serial_number || ''}
                                                                    onChange={e => handleInputChange(e)}
                                                                    isInvalid={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].serial_number && (validations.rows[index].serial_number[0] || validations.rows[index].serial_number))}
                                                                    size="sm"
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {validations && validations.rows && validations.rows[index] && validations.rows[index].serial_number && (validations.rows[index].serial_number[0] || validations.rows[index].serial_number)}
                                                                </Form.Control.Feedback>
                                                            </InputGroup>
                                                        </td>

                                                        <td style={{ width: '20%' }}>
                                                            <InputGroup size="sm" style={{ minWidth: '95px' }}>
                                                                <Form.Control
                                                                    type="number"
                                                                    step="1"
                                                                    name={`rows[${index}][warranty]`}
                                                                    value={row.warranty || ''}
                                                                    onChange={e => handleInputChange(e)}
                                                                    isInvalid={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].warranty && (validations.rows[index].warranty[0] || validations.rows[index].warranty))}
                                                                    size="sm"
                                                                    className="price-input"
                                                                />

                                                                <InputGroup.Text>
                                                                    месеца
                                                                </InputGroup.Text>

                                                                <Form.Control.Feedback type="invalid">
                                                                    {validations && validations.rows && validations.rows[index] && validations.rows[index].warranty && (validations.rows[index].warranty[0] || validations.rows[index].warranty)}
                                                                </Form.Control.Feedback>
                                                            </InputGroup>
                                                        </td>

                                                        <td className="options">
                                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                <Button variant="danger" size="sm" onClick={e => handleRemoveRow(e, index)}>
                                                                    <TrashFill />
                                                                </Button>
                                                                <Button variant="outline" size="sm" style={{ marginLeft: '5px', cursor: 'move' }} onClick={e => e.preventDefault()}>
                                                                    <ArrowsMove />
                                                                </Button>
                                                            </div>
                                                        </td>

                                                    </SortItem>
                                                )}
                                                {/* <tr>
                                                    <td colSpan={4}>
                                                        Общо
                                                    </td>
                                                    <td className="text-right">
                                                        {getTotalQty().toFixed(4)}
                                                    </td>
                                                </tr> */}

                                            </tbody>
                                        </Table>

                                        <Button variant="outline-dark" className="mt-3" size="sm" onClick={handleAddRow}>
                                            Нов ред
                                        </Button>
                                    </div>


                                    <Row>
                                        <Col sm={12}>
                                            <div className="form-panel mt-3">
                                                <div className="head">
                                                    Бележки
                                                </div>
                                                <Form.Control
                                                    type="text"
                                                    as="textarea"
                                                    // placeholder="Бележки"
                                                    name="description"
                                                    value={state.data?.description || ''}
                                                    onChange={handleInputChange}
                                                    isInvalid={Boolean(validations?.description)}
                                                    rows={6}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {validations?.description && (validations.description[0] || validations.description)}
                                                </Form.Control.Feedback>
                                            </div>

                                        </Col>

                                    </Row>

                                    <div className="buttons mt-3">
                                        <SaveButton
                                            loading={state.loadingBase}
                                            disabled={state.loading}
                                            className="save"
                                            onClick={handleSave}
                                        >
                                            Запази данните
                                        </SaveButton>

                                    </div>
                                    {/* </Container> */}

                                </Form>
                            </>
                        }

                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Index;