import React, { useState, useImperativeHandle, forwardRef, useEffect, useRef } from 'react'
import { Modal, Button, FloatingLabel, Form, Row, Col } from 'react-bootstrap';
import SaveButton from 'components/misc/Button';
import Loader from 'components/misc/Loader';
import Api from 'helpers/Api';
import { useValidation } from 'helpers/Validation';
import Error from 'components/modals/Error';
import Info from 'components/misc/Info';
import { PPP } from 'constants/documents';
import { useNavigate } from 'react-router-dom';

function New(props, ref) {

    const navigate = useNavigate();

    const defaultData = {};

    const [state, setState] = useState({
        show: false,
        params: null,
        data: defaultData,
        loading: false,
        onSuccess: null,
        onClose: null,
    });

    const [templates, setTemplates] = useState([]);

    const [validations, setValidations] = useValidation();

    const errorModalRef = useRef(null);

    useImperativeHandle(ref, () => ({
        open: () => {
            handleShow();
        },
        close: () => {
            handleClose();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        }
    }));

    useEffect(() => {
        Api.get('documents/templates', {
            params: {
                type_id: PPP
            }
        }).then(res => {
            if (Array.isArray(res.data)) {
                setTemplates(res.data);
            }
        });
    }, []);

    const handleShow = () => {
        setState(prev => ({
            ...prev,
            show: true,
        }));
    }

    const handleClose = () => {
        setState(prev => ({
            ...prev,
            show: false,
            data: defaultData
        }));

        setValidations(null);

        if (typeof state.onClose === 'function') {
            state.onClose();
        }
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: value
            }
        }));
    }

    const handleSave = e => {
        e.preventDefault();

        navigate('/protocols/new?from_template_id=' + (state.data.template_id || ''));
    }

    return (
        <>

            <Error
                ref={errorModalRef}
            />

            <Modal centered show={state.show} onHide={handleClose}>
                <Form noValidate onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Изберете шаблон
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FloatingLabel label="Шаблон" className="mb-3">
                            <Form.Select
                                name="template_id"
                                value={state.data.template_id || ''}
                                onChange={handleInputChange}
                                isInvalid={Boolean(validations && validations.template_id)}
                            >
                                <option value="">Нов празен документ</option>
                                {templates.map(o =>
                                    <option key={o.id} value={o.id}>{o.document_name}</option>
                                )}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                {validations && validations.template_id && (validations.template_id[0] || validations.template_id)}
                            </Form.Control.Feedback>
                        </FloatingLabel>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                        >
                            Напред
                        </SaveButton>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

export default forwardRef(New);