import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import { Modal, Button, Col, Row, FloatingLabel, Form, } from 'react-bootstrap';

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//providers
import { useLanguageContext } from 'providers/Language'

//misc
import Question from 'components/modals/Question';
import SaveButton from 'components/misc/Button'
import Info from 'components/modals/Info';
import Autocomplete from 'components/misc/Autocomplete';
import HelpInfo from 'components/misc/Info';

function AddArticle(props, ref) {

    const defaultData = {

    }

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        refresh: false,
        data: defaultData,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null,
        onClose: null,
    });

    const [validations, setValidations] = useValidation();

    useImperativeHandle(ref, () => ({
        add: (id) => {
            open(id);
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        }
    }));


    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const open = (id) => {
        setState(prev => ({
            ...prev,
            data: defaultData,
            id: id,
        }));

        show();
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
            subjectId: null,
            subjectType: null
        }));

        setValidations(null);

        if (typeof state.onClose === 'function') {
            hide(state.onClose);
        } else {
            hide();
        }
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            refresh: new Date().getTime(),
        }));
    }

    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = 'price_rules/articles/add';

        let data = new FormData(e.target);

        if (state.id) {
            data.append('id', state.id)
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (typeof state.onSuccess === 'function') {
                state.onSuccess(res.data);
            }

            hide(state.onClose);
        }).catch(error => {
            const _err = error.response;

            if (_err && _err.status && _err.status === 422) {
                setValidations(_err.data.errors);
            }
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }));
        });
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleDateChange = e => {
        const name = e.target.name + '_ymd'
        const value = e.target.value

        setState(name, value, 'data')
    }

    return (
        <>

            <Modal size="md" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <form onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Добавяне на артикул
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Row className="mb-3">
                            <Col>
                                <Autocomplete
                                    url="autocomplete/articles"
                                    inputPlaceholder="Артикул"
                                    inputName="article_name"
                                    inputIdName="article_id"
                                    // inputValue={state.data?.rows[index] && state.data?.rows[index]?.article?.name ? state.data?.rows[index]?.article?.name : ''}
                                    // inputIdValue={state.data?.rows[index] && state.data?.rows[index].article_id ? state.data?.rows[index].article_id : ''}
                                    // onChange={data => handleChangeArticle(data, index)}
                                    error={Boolean(validations && validations.article_id)}
                                    helperText={validations && validations.article_id && (validations.article_id[0] || validations.article_id)}
                                    renderText={data => {
                                        return (
                                            <div style={{ display: 'flex', width: '100%' }}>
                                                <div style={{ width: 'max-content', marginRight: '10px' }}>
                                                    {data.id}
                                                </div>
                                                <div>
                                                    {data.name}
                                                    <br />
                                                    {data.article_name}
                                                </div>
                                            </div>
                                        )
                                    }}
                                    renderInputText={data => data.article_name}
                                    size="sm"
                                    autoFocus
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <FloatingLabel label="Предпочитана цена">
                                    <Form.Control
                                        type="number"
                                        min="0"
                                        step="0.01"
                                        name={`prefered_price`}
                                        autoComplete="new-password"
                                        value={state.data.prefered_price || ''}
                                        placeholder="Предпочитана цена"
                                        onChange={(e) => handleInputChange(e)}
                                        isInvalid={Boolean(validations?.prefered_price)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {validations?.prefered_price && (validations?.prefered_price[0] || validations?.prefered_price)}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            className="save"
                        />
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default forwardRef(AddArticle);
