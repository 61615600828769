export default class Container {
    static instance = null;

    auth = null;
    location = null;
    navigate = null;
    query = null;
    wait = false;

    static getInstance() {
        if (Container.instance === null) {
            Container.instance = new Container();
        }

        return Container.instance;
    }

    setAuth(auth) {
        this.auth = auth;
    }

    setLocation(location) {
        this.location = location;
    }

    setNavigate(navigate) {
        this.navigate = navigate;
    }

    setQuery(query) {
        this.query = query;
    }

    getAuth() {
        return this.auth;
    }

    getUser() {
        return this.auth?.getUser();
    }

    getLocation() {
        return this.location;
    }

    getNavigate() {
        return this.navigate;
    }

    getQuery() {
        return this.query;
    }
}
