import Layout from 'components/layout/Layout';
import PageLoader from 'components/misc/PageLoader';
import { useQuery } from 'helpers/Url';
import { useAuthDataContext } from 'providers/Auth';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import Clients from './partials/Clients';
import Payments from './partials/Payments';
import Articles from './partials/Articles';

import 'assets/scss/dashboard.scss';
import Storeloads from './partials/Storeloads';
import Storemoves from './partials/Storemoves';
import Revisions from './partials/Revisions';
import Orders from './partials/Orders';
import Accounts from './partials/Accounts';
import Invoices from './partials/Invoices';
import PackageCards from './partials/PackageCards';
import NoDataFound from 'components/misc/NoDataFound';

const components = {
    'clients': Clients,
    'payments': Payments,
    'articles': Articles,
    'storeloads': Storeloads,
    'storemoves': Storemoves,
    'revisions': Revisions,
    'orders': Orders,
    'accounts': Accounts,
    'invoices': Invoices,
    'packageCards': PackageCards
}

function Index() {
    const auth = useAuthDataContext();
    const location = useLocation();
    const navigate = useNavigate();

    const query = useQuery();

    const [keyword, setKeyword] = useState('');

    const [state, setState] = useState({
        refresh: false,
        status: {},
        results: {}
    });

    useEffect(() => {
        setKeyword(query.get('keyword'));
    }, [location.search])

    useEffect(() => {
        setState(prev => ({
            ...prev,
            refresh: new Date()
        }));
    }, [auth.getUser().getStoreId()]);

    const highlight = (string) => {
        let regex = new RegExp(keyword, 'gi');

        let str = String(string || '').replace(regex, str => {
            return '<b style="color: #0088ff">' + str + '</b>';
        });

        return str;
    }

    const createMarkup = str => {
        return { __html: str };
    }

    const setStatus = (id, status) => {
        setState(prev => ({
            ...prev,
            status: {
                ...prev.status,
                [id]: status
            }
        }));
    }

    const setIsLoading = (id, loading) => {
        setStatus(id, loading ? 'loading' : 'loaded');
    }

    const setResults = (id, results) => {
        setState(prev => ({
            ...prev,
            results: {
                ...prev.results,
                [id]: results
            }
        }));
    }

    const isLoading = () => {
        return Object.values(state.status).filter(status => status === 'loading').length > 0;
    }

    const hasResult = () => {
        return Object.values(state.results).filter(results => results > 0).length > 0;
    }

    // console.log(isLoading())
    // console.log(hasResult());

    // console.log(state)

    return (
        <Layout>
            <div className="container-fluid">

                <div className="page-head">
                    <h1 className="display-6">
                        Резултати от търсенето
                    </h1>
                </div>

                <br />

                <div id="dashboard" className="panel">
                    {!isLoading() && !hasResult() &&
                        <NoDataFound />
                    }

                    <Row>
                        {Object.entries(components).map(component =>
                            React.createElement(component[1], {
                                keyword: keyword,
                                refresh: state.refresh,
                                highlight: highlight,
                                createMarkup: createMarkup,
                                onLoadStart: () => setIsLoading(component[0], true),
                                onLoadEnd: () => setIsLoading(component[0], false),
                                onResult: len => setResults(component[0], len)
                            })
                        )}
                    </Row>
                </div>
            </div>
        </Layout>
    )
};

export default Index;