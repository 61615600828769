import ReactDOM from 'react-dom';

function FlashMessage() {
    return (
        <div className="" onClick={e => alert(1)}>
            123
        </div>
    )
}

export default FlashMessage;