import Api from "helpers/Api";
import { Button, Col, Row } from "react-bootstrap";

function SchemeInput(props) {

    const { state, setState, validations, setValidations } = props;

    const handleShowAttach = e => {
        e.preventDefault();

        // inputFileRef.current.click();

        e.currentTarget.parentNode.querySelector('input').click();
    }

    const handleAttach = (e) => {
        let file = e.target.files[0];
        let url = URL.createObjectURL(file);

        file.id = Math.random().toString(7).substring(2);
        file.was_recently_attached = true;
        file.url = url;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                scheme: file
            }
        }));
    }

    const handleDetach = (e) => {
        let file = state.data.scheme;

        if (!file.was_recently_attached) {
            Api.post('articles/delete-scheme', {
                id: state.data.id
            }).then(res => {
                if (res.data.success) {
                    removeFile();
                }
            });
        } else {
            removeFile();
        }

        if (validations && validations.scheme) {
            delete validations.scheme;
        }

        setValidations(validations);
    }

    const removeFile = () => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                scheme: null
            }
        }));
    }

    return (
        <Row className="mb-3">
            <Col>
                {state.data.scheme &&
                    <div className="mb-3">
                        <>
                            <div className="file">
                                <a href={state.data.scheme?.url} target="_blank">
                                    {state.data.scheme?.name}
                                </a>
                            </div>

                            <div className="validate">
                                {validations?.scheme}
                            </div>
                        </>
                    </div>
                }

                <Button variant="outline-dark" size="sm" onClick={handleShowAttach}>
                    Прикачете схема
                </Button>

                {state.data.scheme &&
                    <Button variant="outline-danger" size="sm" onClick={e => handleDetach(e)} style={{ marginLeft: '5px' }}>
                        Премахнете схемата
                    </Button>
                }

                <input type="file" accept="image/*" onChange={e => handleAttach(e)} hidden />
            </Col>
        </Row>
    )
}

export default SchemeInput;