import { useEffect, useRef, useState } from 'react';
import { buildUrl, useQuery } from 'helpers/Url';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Alert, Button, Table, OverlayTrigger, Tooltip, Form } from 'react-bootstrap';
import { ArrowLeftCircle, Files, List, Plus, PrinterFill, TrashFill } from 'react-bootstrap-icons';
import axios from 'axios';

import Options from './partials/Options';

import Layout from 'components/layout/Layout';

//misc

import { useAuthDataContext } from 'providers/Auth';

import PrintOptions from './partials/PrintOptions';
import PrintCopyOptions from './partials/PrintCopyOptions';
import Autocomplete from 'components/misc/Autocomplete';
import Loader from 'components/misc/Loader';
import { useValidation } from 'helpers/Validation';
import Error from 'components/modals/Error';
import Api from 'helpers/Api';

let timeout;

function PrintListFree() {

    const location = useLocation();
    const navigate = useNavigate();
    const query = useQuery();
    const auth = useAuthDataContext();

    const printOptionsModalRef = useRef(null);
    const printCopyOptionsModalRef = useRef(null);

    const [state, setState] = useState({
        data: {
            rows: []
        },
        dataLoading: false,
    });

    const [validations, setValidations] = useValidation();

    const errorModalRef = useRef(null);

    useEffect(() => {

        let rows = [];

        let ids = query.get('articles');

        if (ids) {
            ids = JSON.parse(ids);

            ids.map(id => {
                rows.push({
                    article_id: Object.keys(id)[0],
                    barcode: null,
                    copies: Object.values(id)[0],
                });
            });
        } else {
            rows.push({});
        }

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: rows
            }
        }));
    }, []);

    useEffect(() => {
        // от зареждане
        if (query.get('from_load_id')) {
            loadRowsFromStoreLoad();
        }

        // от прехвърляне
        if (query.get('from_move_id')) {
            loadRowsFromStoreMove();
        }
    }, [location]);

    // useEffect(() => {
    //     if (state.data.rows.length === 0) {
    //         setState(prev => ({
    //             ...prev,
    //             data: {
    //                 ...prev.data,
    //                 rows: [{}]
    //             }
    //         }));
    //     }
    // }, [state.data.rows]);

    const loadRowsFromStoreLoad = () => {
        loading(true);

        let url = 'articles/print-list-from-load';

        Api.get(url, {
            params: {
                load_id: query.get('from_load_id'),
                rows: query.getAll('rows[]')
            }
        }).then(res => {
            handleResponse(res);
        }).catch(err => {
            handleError(err);
        }).finally(() => {
            loading(false);
        })
    }

    const loadRowsFromStoreMove = () => {
        loading(true);

        let url = 'articles/print-list-from-move';

        Api.get(url, {
            params: {
                move_id: query.get('from_move_id'),
                rows: query.getAll('rows[]')
            }
        }).then(res => {
            handleResponse(res);
        }).catch(err => {
            handleError(err);
        }).finally(() => {
            loading(false);
        })
    }

    const handleResponse = res => {
        let data = res.data;

        let rows = data.rows || [];

        rows = rows.map(row => {
            return {
                article_id: row.article_id,
                barcode: row.article.barcode,
                copies: row.quantity,
            };
        });

        data.rows = rows;

        setState(prev => ({
            ...prev,
            data: data,
        }));
    }

    const handleError = error => {
        console.log(error);

        const _err = error.response;

        if (_err && _err.status) {
            if (_err.status === 422) {
                setValidations(_err.data.errors);
            } else {
                let modal = errorModalRef.current;

                if (modal) {
                    modal.open(_err.data.error || _err.data.message);

                    modal.onClose(() => {
                        navigate(-1, {
                            replace: true
                        });
                    });
                }
            }
        }
    }

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            dataLoading: Boolean(loading)
        }));
    }

    const handleInputChange = (e, index) => {
        let name = e.target.name;
        let value = e.target.value;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: Object.values({
                    ...prev.data.rows,
                    [index]: {
                        ...prev.data.rows[index],
                        [name]: value,
                    }
                })
            }
        }))
    }

    const handleAddRow = e => {
        e.preventDefault()

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: prev.data.rows.concat({})
            }
        }))
    }

    const handleRemoveRow = (e, index) => {
        e.preventDefault()

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: prev.data.rows.filter((row, i) => i !== index)
            }
        }))
    }

    const handleChangeArticle = (data, index) => {

        let row = state.data.rows[index];

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: Object.values({
                    ...prev.data.rows,
                    [index]: {
                        ...prev.data.rows[index],
                        article_id: data.id,
                        barcode: data.barcode,
                        copies: 1,
                    }
                })
            }
        }));

    }

    const handleChangeArticleName = (data, index) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: Object.values({
                    ...prev.data.rows,
                    [index]: {
                        ...prev.data.rows[index],
                        article_id: null,
                        name: data,
                        barcode: null,
                    }
                })
            }
        }));
    }

    const getRowsWithArticle = () => {
        return state.data.rows.filter(r => r.article_id);
    }

    const getSelectedCount = () => {
        // само редовете с избран артикул да се четат
        let rows = getRowsWithArticle();

        return rows.length;
    }

    const showPrintOptions = () => {
        let modal = printOptionsModalRef.current;

        if (!modal) {
            return;
        }

        modal.open();

        modal.onSuccess(({ label_id, skip }) => {
            let base = process.env.REACT_APP_URL + '/api/articles/print';

            let ids = [];

            getRowsWithArticle().map(item => {
                ids.push({
                    article_id: item.article_id,
                    count: item.copies
                });
            })

            // let ids = selected;

            let url = buildUrl(base, {
                ids: JSON.stringify(ids),
                label_id: label_id,
                skip: skip,
                token: auth.getToken()
            });

            // console.log(url);

            window.open(url);
        });
    }

    const showPriceList = () => {
        navigate('/articles-print-list');
    }

    const showCopyOptions = () => {
        let modal = printCopyOptionsModalRef.current;

        if (!modal) {
            return;
        }

        modal.open();

        modal.onSuccess(({ quantity }) => {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    rows: prev.data.rows.map(row => {
                        row.copies = quantity;

                        return row;
                    })
                }
            }));
        });
    }

    return (
        <>
            <PrintOptions
                ref={printOptionsModalRef}
            />

            <PrintCopyOptions
                ref={printCopyOptionsModalRef}
            />

            <Error
                ref={errorModalRef}
            />

            <Layout>
                <div className="container-fluid">

                    <div className="page-head">
                        <h1 className="display-6">
                            <Link to="/articles" className="link-dark text-decoration-none">
                                <ArrowLeftCircle />
                            </Link> Списък артикули за печат - свободен избор
                        </h1>

                        <div className="buttons">

                        </div>
                    </div>

                    <br />

                    <div className="panel">

                        <div className="pagination-with-options mb-3">
                            <Button
                                variant="dark"
                                // size="sm"
                                onClick={showCopyOptions}
                            >
                                <Files /> Брой копия за всички
                            </Button>
                            <div className="table-options">
                                <Button
                                    variant="dark"
                                    // size="sm"
                                    onClick={showPriceList}
                                >
                                    <List /> Списъчен режим
                                </Button>
                            </div>
                        </div>

                        {state.dataLoading
                            ?
                            <Loader />
                            :
                            <>
                                <Table className="valign-top big">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '5%' }}>
                                                ID
                                            </th>
                                            <th style={{ width: '40%' }}>
                                                Артикул
                                            </th>
                                            <th>
                                                Баркод
                                            </th>
                                            <th className="text-center" style={{ width: '100px' }}>
                                                Копия
                                            </th>
                                            <th className="options">

                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {state.data.rows.map((row, index) =>
                                            <tr key={index}>
                                                <td>
                                                    {row.article_id}
                                                </td>
                                                <td>
                                                    <Autocomplete
                                                        variant="basic"
                                                        url="autocomplete/articles"
                                                        inputIdValue={row.article_id || ''}
                                                        onChange={data => handleChangeArticle(data, index)}
                                                        onInputChange={data => handleChangeArticleName(data, index)}
                                                        renderText={data => {
                                                            return (
                                                                <div style={{ display: 'flex', width: '100%' }}>
                                                                    <div style={{ width: 'max-content', marginRight: '10px' }}>
                                                                        {data.id}
                                                                    </div>
                                                                    <div>
                                                                        {data.name}
                                                                        <br />
                                                                        {data.article_name}
                                                                    </div>
                                                                </div>
                                                            )
                                                        }}
                                                        renderInputText={data => data.article_name}
                                                        size="sm"
                                                        autoFocus
                                                    />
                                                </td>
                                                <td>
                                                    {row.barcode}
                                                </td>
                                                <td className="text-center">
                                                    <Form.Control
                                                        type="number"
                                                        min="1"
                                                        step="1"
                                                        name="copies"
                                                        value={row.copies || ''}
                                                        onChange={e => handleInputChange(e, index)}
                                                        size="sm"
                                                    />
                                                </td>
                                                <td className="options">
                                                    <Button variant="danger" size="sm" onClick={e => handleRemoveRow(e, index)}>
                                                        <TrashFill />
                                                    </Button>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>

                                <Button variant="outline-dark" className="mt-3" size="sm" onClick={handleAddRow}>
                                    Нов ред
                                </Button>
                            </>
                        }
                    </div>
                </div>

                {getSelectedCount() > 0 ?
                    <div className="baloon-selected">
                        Избрани артикули: {getSelectedCount()} бр.
                        <div className="buttons">
                            <Button variant="primary" onClick={showPrintOptions}>
                                <PrinterFill /> <span>
                                    Печат
                                </span>
                            </Button>
                        </div>
                    </div>
                    : ''
                }
            </Layout>
        </>
    )
}

export default PrintListFree;
