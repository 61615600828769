import { useEffect, useRef, useState } from 'react';
import Api from 'helpers/Api';
import { useQuery } from 'helpers/Url';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Alert, Button, Table } from 'react-bootstrap';
import { Check } from 'react-bootstrap-icons';
import Options from './partials/Options';

import Question from 'components/modals/Question';
import Filter from './partials/Filter';
import AddOrEdit from './partials/AddOrEdit';
import Layout from 'components/layout/Layout';

//misc
import MenuOptions from 'components/misc/MenuOptions'
import Pagination from 'components/misc/Pagination'
import Loader from 'components/misc/Loader';
import SaveButton from 'components/misc/Button';
import { useAuthDataContext } from 'providers/Auth';

let timeout;

function Index() {

    const location = useLocation();
    const history = useNavigate();
    const query = useQuery();
    const auth = useAuthDataContext();

    const addOrEditModalRef = useRef(null);
    const deleteModalRef = useRef(null);

    const [state, setState] = useState({
        loading: true,
        refresh: false,
        setFilter: false,
        data: [],
        pages: 0,
        total: 0,
        filter: {
            page: 1,
        },
    });

    const [loadingSync, setLoadingSync] = useState(false);

    useEffect(() => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: query.get('page') || 1,
                search: query.get('search') || ''
            },
            refresh: new Date().getTime()
        }))
    }, [location.search]);

    useEffect(() => {
        if (state.setFilter) {
            Object.entries(state.filter).map(filter => {
                // if (filter[1]) {
                query.set(filter[0], filter[1]);
                // }
            });

            history('?' + query.toString());
        }

    }, [state.setFilter]);

    useEffect(() => {
        if (state.refresh) {
            loadData();
        }
    }, [state.refresh]);

    const loadData = () => {

        loading(true);

        let url = 'currencies/get';

        Api.get(url, {
            params: state.filter
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data.items,
                total: res.data.total,
                pages: res.data.pages
            }));

            if (res.data.pages > 0 && state.filter.page > res.data.pages) {
                return handlePage({ selected: res.data.pages - 1 });
            }

            loading(false);
        });
    }

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }));
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: reset ? 1 : prev.filter.page
            },
            setFilter: new Date().getTime(),
            refresh: new Date().getTime(),
        }));
    }

    const handleAdd = () => {
        let modal = addOrEditModalRef.current;

        modal.add();

        modal.onSuccess(() => {
            refresh(true);
        });
    }

    // update
    const handleEdit = id => {
        let modal = addOrEditModalRef.current;

        modal.edit(id);

        modal.onSuccess(() => {
            refresh(false);
        });
    }

    // delete
    const handleDelete = id => {
        let modal = deleteModalRef.current;

        modal.open();
        modal.onSuccess(() => {
            handleDestroy(id);
        })
    }

    const handleDestroy = id => {
        Api.post('currencies/delete', {
            id: id
        }).then(res => {
            if (res.data.success) {
                refresh(false);
            }
        });
    }

    // Search
    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout);

        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [key]: val,
            },
        }));

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: 1
                },
                setFilter: new Date().getTime()
            }));
        }, delay);
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            setFilter: new Date().getTime()
        }));
    }

    const handleSync = () => {

        setLoadingSync(true);

        let url = `currencies/sync-all`;

        Api.post(url).then(res => {
            refresh();
        }).finally(() => {
            setLoadingSync(false);
        })
    }

    return (
        <>

            <AddOrEdit
                ref={addOrEditModalRef}
            />

            <Question
                ref={deleteModalRef}
                mainMessage="Сигурни ли сте?"
                agreeBtnText="Изтриване"
                agreeBtnClass="remove"
            />

            <Layout>
                <div className="container-fluid">

                    <div className="page-head">
                        <h1 className="display-6">
                            Валути
                        </h1>

                        <div className="buttons">
                            <Button variant="outline-dark" onClick={handleAdd} disabled={auth.getUser().permission('currencies_edit') === false}>
                                Добави
                            </Button>

                            <SaveButton
                                style={{ marginLeft: '10px' }}
                                variant="outline-dark"
                                onClick={handleSync}
                                loading={loadingSync}
                                disabled={auth.getUser().permission('currencies_edit') === false}
                            >
                                Синхронизиране
                            </SaveButton>

                        </div>
                    </div>

                    <br />

                    <div className="panel">
                        <Filter
                            filter={state.filter}
                            handleSearch={handleSearch}
                        />

                        {state.loading
                            ?
                            <Loader />
                            :
                            state.data.length === 0 ?
                                <Alert variant="info">
                                    Няма намерена информация!
                                </Alert>
                                :
                                <>
                                    <Table striped responsive hover>
                                        <thead>
                                            <tr>
                                                <th style={{ width: '60%' }}>Наименование</th>
                                                <th className="text-center">Префикс</th>
                                                <th className="text-center">Суфикс</th>
                                                <th className="text-center">По подразбиране</th>
                                                <th className="text-right">Курс</th>
                                                <th className="options">
                                                    Опции
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {state.data.map(c =>
                                                <tr
                                                    key={'c-' + c.id}
                                                >
                                                    <td>
                                                        {c.name}
                                                    </td>

                                                    <td className="text-center">
                                                        {c.prefix}
                                                    </td>

                                                    <td className="text-center">
                                                        {c.sufix}
                                                    </td>

                                                    <td className="text-center">
                                                        {c.default ? <Check style={{ fontSize: '26px', color: '#1E8C45' }} /> : ''}
                                                    </td>

                                                    <td className="text-right">
                                                        {c.rate}
                                                    </td>

                                                    <td className="options">
                                                        <MenuOptions>
                                                            <Options
                                                                data={c}
                                                                handleEdit={handleEdit}
                                                                handleDelete={handleDelete}
                                                            />
                                                        </MenuOptions>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>

                                    <Pagination
                                        className="mt-3"
                                        page={state.filter.page}
                                        pages={state.pages}
                                        total={state.total}
                                        handlePage={handlePage}
                                    />
                                </>
                        }


                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Index;
