import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import moment from "moment"
import { FloatingLabel, Form, Dropdown, Row, Col } from 'react-bootstrap'

//providers
import { useAuthDataContext } from "providers/Auth"

//helpers
import { useQuery } from 'helpers/Url'
import Api from 'helpers/Api'
import Loader from 'components/misc/Loader'
import Autocomplete from 'components/misc/Autocomplete'
import DatePicker from 'components/partials/filter/DatePicker'

function Filter(props) {
    const history = useNavigate()
    const query = useQuery()

    const auth = useAuthDataContext();

    const [filters, setFilters] = useState({
        show: false,
    });

    useEffect(() => {

    }, []);

    const handleDateChange = (date, name) => {
        props.handleSearch(name, moment(date).format('YYYY-MM-DD'));
    }

    const handleSearch = e => {
        const name = e.target.name
        const value = e.target.value

        props.handleSearch(name, value)
    }

    const handleFromDateChange = e => {
        const name = e.target.name
        const value = e.target.value

        let date = value ? moment(value).format('YYYY-MM-DD') : '';

        props.handleSearch({
            created_from_date: date,
            created_to_date: date,
        });
    }

    const handleToDateChange = e => {
        const value = e.target.value

        let date = value ? moment(value).format('YYYY-MM-DD') : '';

        if (date && props.filter.created_from_date > date) {
            return;
        }

        props.handleSearch({
            created_to_date: date,
        });
    }

    const handleChangeUser = data => {
        props.handleSearch({
            creator_id: data?.id || '',
        });
    }

    const handleTypeChange = data => {
        let checked = data.map(d => d.id);

        props.handleSearch('type_id', checked);
    }

    const handleCompanyChange = data => {
        let checked = data.map(d => d.id);

        props.handleSearch('company_name', checked);
    }

    const handleChangeArticle = data => {
        props.handleSearch({
            article_id: data?.id || '',
        });
    }

    const handleChangeClient = data => {
        props.handleSearch({
            client_id: data?.id || '',
        });
    }

    const handleChangeStore = data => {
        let checked = data.map(d => d.id);

        props.handleSearch('store_id', checked);
    }

    const handleChangeMethod = data => {
        let checked = data.map(d => d.id);

        props.handleSearch('paymethod_id', checked);
    }

    const toggleFilters = () => {
        setFilters(prev => ({
            ...prev,
            show: !prev.show,
        }))
    }

    const getMoreFiltersValue = () => {
        let data = [];

        if (props.filter.created_from_date) {
            data.push(1);
        }

        if (props.filter.created_to_date) {
            data.push(1);
        }

        if (props.filter.creator_id) {
            data.push(1);
        }

        if (props.filter.deleted) {
            data.push(1);
        }

        if (data.length === 0) {
            return 'Няма избрани';
        }

        return data.length + ' избрани';
    }

    return (
        <div className="row page-filter">
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <FloatingLabel label="Търсене">
                    <Form.Control
                        type="text"
                        placeholder="Търсене"
                        name="search"
                        value={props.filter.search || ''}
                        onChange={handleSearch}
                        autoFocus
                    />
                </FloatingLabel>
            </div>
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <Autocomplete
                    inputPlaceholder="Тип"
                    url="invoices/types"
                    inputIdName="type_id[]"
                    selectedIds={props.filter.type_id || []}
                    multiple
                    onChange={handleTypeChange}
                    filterable={false}
                />
            </div>
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <Autocomplete
                    inputPlaceholder="Клиент"
                    url="autocomplete/clients"
                    inputIdName="client_id"
                    // inputValue={state.data?.supplier?.name}
                    inputIdValue={props.filter.client_id || ''}
                    onChange={handleChangeClient}
                    onInputChange={handleChangeClient}
                />
            </div>
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <Autocomplete
                    inputPlaceholder="Доставчик"
                    url="invoices/sellers"
                    inputIdName="company_name[]"
                    selectedIds={props.filter.company_name || []}
                    multiple
                    onChange={handleCompanyChange}
                    filterable={false}
                    renderInputText={data => data.name}
                    renderText={data => data.name}
                />
            </div>
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <Autocomplete
                    inputPlaceholder="Артикул"
                    url="autocomplete/articles"
                    inputIdName="article_id"
                    // inputValue={state.data?.supplier?.name}
                    inputIdValue={props.filter.article_id || ''}
                    onChange={handleChangeArticle}
                    onInputChange={handleChangeArticle}
                    renderText={data => {
                        return (
                            <div style={{ display: 'flex', width: '100%' }}>
                                <div style={{ width: 'max-content', marginRight: '10px' }}>
                                    {data.id}
                                </div>
                                <div>
                                    {data.name}
                                    <br />
                                    {data.article_name}
                                </div>
                            </div>
                        )
                    }}
                    renderInputText={data => data.article_name}
                />
            </div>
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <Dropdown drop="down" onToggle={toggleFilters} show={filters.show}>
                    <FloatingLabel label="Още филтри">
                        <Form.Control
                            type="text"
                            placeholder="Още филтри"
                            value={getMoreFiltersValue()}
                            onClick={toggleFilters}
                            readOnly
                            style={{
                                cursor: 'pointer'
                            }}
                        />
                    </FloatingLabel>
                    <Dropdown.Menu align="start" style={{ padding: '15px', width: '100%' }}>
                        <Row>
                            <Col className="mb-3">
                                <DatePicker
                                    placeholder="Издадена от"
                                    name="created_from_date"
                                    value={props.filter.created_from_date || ''}
                                    onChange={handleFromDateChange}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-3">
                                <DatePicker
                                    placeholder="Издадена до"
                                    name="created_to_date"
                                    value={props.filter.created_to_date || ''}
                                    onChange={handleToDateChange}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-3">
                                <Autocomplete
                                    inputPlaceholder="Създадено от"
                                    url="autocomplete/admins"
                                    inputIdName="creator_id"
                                    // inputValue={state.data?.supplier?.name}
                                    inputIdValue={props.filter.creator_id || ''}
                                    onChange={handleChangeUser}
                                    onInputChange={handleChangeUser}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-3">
                                <FloatingLabel label="Сметка №">
                                    <Form.Control
                                        type="text"
                                        placeholder="Сметка №"
                                        name="account_id"
                                        value={props.filter.account_id || ''}
                                        onChange={handleSearch}
                                        autoFocus
                                    />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-3">
                                <Autocomplete
                                    url="autocomplete/stores"
                                    params={{
                                        permission: 'invoices_index'
                                    }}
                                    inputPlaceholder="Търговски обект"
                                    selectedIds={props.filter.store_id || []}
                                    renderText={data => data?.translation?.name || data?.name}
                                    onChange={data => handleChangeStore(data)}
                                    multiple
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-3">
                                <Autocomplete
                                    url="autocomplete/payment-methods"
                                    inputPlaceholder="Начин на плащане"
                                    selectedIds={props.filter.paymethod_id || []}
                                    renderText={data => data?.name}
                                    onChange={data => handleChangeMethod(data)}
                                    multiple
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FloatingLabel label="Статус">
                                    <Form.Select
                                        name="deleted"
                                        value={props.filter.deleted || ''}
                                        onChange={handleSearch}
                                    >
                                        <option value="">По подразбиране</option>
                                        <option value="only-active">Покажи само издадени</option>
                                        <option value="only-deleted">Покажи само анулирани</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    )
}

export default Filter;
