import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import moment from "moment"
import { FloatingLabel, Form, Dropdown, Row, Col } from 'react-bootstrap'

//providers
import { useAuthDataContext } from "providers/Auth"

//helpers
import { useQuery } from 'helpers/Url'
import Api from 'helpers/Api'
import Loader from 'components/misc/Loader'
import Autocomplete from 'components/misc/Autocomplete'
import DatePicker from 'components/partials/filter/DatePicker'

function Filter(props) {
    const history = useNavigate()
    const query = useQuery()

    const auth = useAuthDataContext();

    const [depots, setDepots] = useState([]);
    const [statuses, setStatuses] = useState([]);

    const [filters, setFilters] = useState({
        show: false,
    });

    useEffect(() => {
        Api.get('revisions/statuses')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setStatuses(res.data);
                }
            });
    }, []);

    useEffect(() => {
        Api.get('revisions/depots')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setDepots(res.data);
                }
            });
    }, [auth.getUser()?.getStoreId()]);

    const handleDateChange = (date, name) => {
        props.handleSearch(name, moment(date).format('YYYY-MM-DD'));
    }

    const handleSearch = e => {
        const name = e.target.name
        const value = e.target.value

        props.handleSearch(name, value)
    }

    const handleFromDateChange = e => {
        const name = e.target.name
        const value = e.target.value

        let date = value ? moment(value).format('YYYY-MM-DD') : '';

        props.handleSearch({
            created_from_date: date,
            created_to_date: date,
        });
    }

    const handleToDateChange = e => {
        const value = e.target.value

        let date = value ? moment(value).format('YYYY-MM-DD') : '';

        if (date && props.filter.created_from_date > date) {
            return;
        }

        props.handleSearch({
            created_to_date: date,
        });
    }

    const handleChangeUser = data => {
        props.handleSearch({
            creator_id: data.id,
        });
    }

    const handleChangeAcceptor = data => {
        props.handleSearch({
            acceptor_id: data.id,
        });
    }

    const toggleFilters = () => {
        setFilters(prev => ({
            ...prev,
            show: !prev.show,
        }))
    }

    const getMoreFiltersValue = () => {
        let data = [];

        if (props.filter.created_from_date) {
            data.push(1);
        }

        if (props.filter.created_to_date) {
            data.push(1);
        }

        if (props.filter.creator_id) {
            data.push(1);
        }

        if (props.filter.acceptor_id) {
            data.push(1);
        }

        if (props.filter.document_date) {
            data.push(1);
        }

        if (props.filter.deleted) {
            data.push(1);
        }

        if (data.length === 0) {
            return 'Няма избрани';
        }

        return data.length + ' избрани';
    }

    return (
        <div className="row page-filter">
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <FloatingLabel label="Статус">
                    <Form.Select
                        name="status_id"
                        value={props.filter.status_id || ''}
                        onChange={handleSearch}
                    >
                        <option value="">Всички</option>
                        {statuses.map(o =>
                            <option key={o.id} value={o.id}>{o?.translation?.name || o.name}</option>
                        )}
                    </Form.Select>
                </FloatingLabel>
            </div>
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <FloatingLabel label="Склад">
                    <Form.Select
                        name="depot_id"
                        value={props.filter.depot_id || ''}
                        onChange={handleSearch}
                    >
                        <option value="">Всички</option>
                        {depots.map(o =>
                            <option key={o.id} value={o.id}>{o?.store?.translation?.name || o?.store?.name} - {o?.translation?.name || o.name}</option>
                        )}
                    </Form.Select>
                </FloatingLabel>
            </div>
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <Dropdown drop="down" onToggle={toggleFilters} show={filters.show}>
                    <FloatingLabel label="Още филтри">
                        <Form.Control
                            type="text"
                            placeholder="Още филтри"
                            value={getMoreFiltersValue()}
                            onClick={toggleFilters}
                            readOnly
                            style={{
                                cursor: 'pointer'
                            }}
                        />
                    </FloatingLabel>
                    <Dropdown.Menu align="start" style={{ padding: '15px', width: '340px' }}>
                        <Row>
                            <Col className="mb-3">
                                <DatePicker
                                    placeholder="Създаден от"
                                    name="created_from_date"
                                    value={props.filter.created_from_date || ''}
                                    onChange={handleFromDateChange}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-3">
                                <DatePicker
                                    placeholder="Създаден до"
                                    name="created_to_date"
                                    value={props.filter.created_to_date || ''}
                                    onChange={handleToDateChange}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-3">
                                <Autocomplete
                                    inputPlaceholder="Създадено от"
                                    url="autocomplete/admins"
                                    inputIdName="creator_id"
                                    // inputValue={state.data?.supplier?.name}
                                    inputIdValue={props.filter.creator_id || ''}
                                    onChange={handleChangeUser}
                                />
                            </Col>
                            <Col className="mb-3">
                                <Autocomplete
                                    inputPlaceholder="Приключено от"
                                    url="autocomplete/admins"
                                    inputIdName="acceptor_id"
                                    // inputValue={state.data?.supplier?.name}
                                    inputIdValue={props.filter.acceptor_id || ''}
                                    onChange={handleChangeAcceptor}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FloatingLabel label="Изтрити">
                                    <Form.Select
                                        name="deleted"
                                        value={props.filter.deleted || ''}
                                        onChange={handleSearch}
                                    >
                                        <option value="">По подразбиране</option>
                                        <option value="with-deleted">Покажи изтрити</option>
                                        <option value="only-deleted">Покажи само изтрити</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    )
}

export default Filter;