import axios from "axios";
import TableCol from "components/misc/TableCol";
import TableHeader from "components/misc/TableHeader";
import PriceValue from "components/partials/PriceValue";
import Api from "helpers/Api";
import moment from "moment";
import { useAppContext } from "providers/App";
import { useAuthDataContext } from "providers/Auth";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Refs from "Refs";

function Payments(props) {

    const app = useAppContext();
    const auth = useAuthDataContext();
    const navigate = useNavigate();

    const tableRef = useRef(null);

    const { createMarkup, highlight } = props;

    const [state, setState] = useState({
        loading: true,
        refresh: false,
        setFilter: false,
        data: [

        ],
        filter: {

        },
        request: null,
    });

    useEffect(() => {
        if (props.refresh) {
            loadData();
        }
    }, [state.refresh, props.refresh, props.keyword]);

    useEffect(() => {
        if (state.loading) {
            props.onLoadStart();
        } else {
            props.onLoadEnd();
        }
    }, [state.loading]);

    useEffect(() => {
        props.onResult(state.data.length);
    }, [state.data]);

    const loadData = () => {
        if (state.request) {
            state.request.cancel();
        }

        let request = axios.CancelToken.source();

        setState(prev => ({
            ...prev,
            request: request,
            loading: true,
        }));

        let url = 'search/payments';

        Api.get(url, {
            params: {
                keyword: props.keyword
            },
            cancelToken: request.token
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data.items,
                loading: false,
                sorting: false,
                filter: {
                    ...prev.filter,
                    ...res.data.filter,
                }
            }));
        });
    }

    const showPayment = id => {
        let modal = Refs.getInstance().getRef('payment');

        modal.open(id);
    }

    const showPayments = () => {
        navigate('/payments?search=' + props.keyword);
    }

    if (state.data.length === 0) {
        return null;
    }

    return (
        <Col xl={6} lg={6}>
            <div className="card">
                <div className="d-flex card-header justify-content-between align-items-start">
                    <h4 className="header-title">Плащания</h4>

                    <Button
                        variant="dark"
                        size="sm"
                        onClick={showPayments}
                    >
                        Всички плащания
                    </Button>
                </div>
                <div className="card-body with-table pt-0">
                    <Table className="" responsive striped hover ref={tableRef}>
                        <TableHeader
                            tableRef={tableRef}
                        >
                            <TableCol>ID</TableCol>
                            <TableCol>Дата</TableCol>
                            <TableCol>Клиент</TableCol>
                            <TableCol>Референция</TableCol>
                            <TableCol className="text-right">Сума</TableCol>
                        </TableHeader>
                        <tbody>
                            {state.data.map(c =>
                                <tr
                                    key={'c-' + c.id}
                                    className={c.deleted ? 'deleted' : ''}
                                >
                                    <td>
                                        <span className="link" onClick={e => showPayment(c.id)} dangerouslySetInnerHTML={createMarkup(highlight(c.id))} />
                                    </td>
                                    <td>
                                        <span>
                                            {c.date ? moment(c.date).format('DD.MM.YYYY HH:mm') : ''}
                                        </span>
                                    </td>
                                    <td>
                                        <span dangerouslySetInnerHTML={createMarkup(highlight(c.client?.name))} />
                                    </td>
                                    <td>
                                        <span dangerouslySetInnerHTML={createMarkup(highlight(c.ref))} />
                                    </td>
                                    <td className="text-right">
                                        <span>
                                            <PriceValue>{c.amount}</PriceValue>
                                        </span>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
            </div>
        </Col>
    )
}

export default Payments;