import axios from "axios";
import { useEffect, useState } from "react";
import { Line, Area } from '@ant-design/plots';
import Api from "helpers/Api";

function Turnover(props) {
    const [state, setState] = useState({
        loading: true,
        refresh: false,
        setFilter: false,
        data: {
            curr: {},
            prev: {}
        },
        filter: {

        },
        chart: null,
        request: null,
    });

    const [data, setData] = useState([]);

    useEffect(() => {
        if (props.refresh) {
            loadData();
        }
    }, [state.refresh, props.refresh]);

    useEffect(() => {
        if (state.loading) {
            return;
        }

        let data = [];

        state.data.curr.dates.map(c => {
            let group = {
                xField: c.label,
                yField: c.stats?.total_real || 0,
                seriesField: 'Тази седмица'
            };

            data.push(group);

        });

        state.data.prev.dates.map(c => {
            let group = {
                xField: c.label,
                yField: c.stats?.total_real || 0,
                seriesField: 'Миналата седмица'
            };

            data.push(group);
        });

        setData(data);
    }, [state.data])

    const loadData = () => {
        if (state.request) {
            state.request.cancel();
        }

        let request = axios.CancelToken.source();

        setState(prev => ({
            ...prev,
            request: request,
            loading: true,
        }));

        let url = 'dashboard/turnover';

        Api.get(url, {
            params: state.filter,
            cancelToken: request.token
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: {
                    curr: res.data.curr,
                    prev: res.data.prev,
                },
                currency: res.data.currency,
                loading: false,
                sorting: false,
                filter: {
                    ...prev.filter,
                    ...res.data.filter,
                }
            }));



        });
    }

    const setChart = chart => {
        setState(prev => ({
            ...prev,
            chart: chart
        }));
    }

    const config = {
        data,
        height: 220,
        xField: 'xField',
        yField: 'yField',
        seriesField: 'seriesField',
        // color: ['l(270) 0:#C871F3 0.5:#C871F3 1:#F871CF', 'l(270) 0:#0DCFF5 0.5:#40E0D4 1:#83F8A9'],
        isGroup: true,
        columnStyle: {
            radius: [20, 20, 0, 0],
        },
        // xAxis: {
        //     type: 'time',
        //     mask: state.filter.group === 'day' ? 'DD-MM-YYYY' : 'MM-YYYY',
        // },
        yAxis: {
            label: {
                formatter: (v) => Number(v).toFixed(0),
            },
        },
        tooltip: {
            formatter: (datum) => {
                return {
                    name: datum.seriesField,
                    value: datum.yField.toFixed(2),
                };
            },
        },
        legend: false,
        line: {
            size: [0, 0],
        },
        areaStyle: {
            fillOpacity: 0.7,
        },
        smooth: true,
    };

    return (
        <div className="card card-h-100">
            <div className="d-flex card-header justify-content-between align-items-center">
                <h4 className="header-title">Общ оборот</h4>

            </div>
            <div className="card-body chart pt-0">
                <div className="chart-content-bg">
                    <div className="row text-center">
                        <div className="col-sm-6">
                            <p className="text-muted mb-0 mt-3">Тази седмица</p>
                            <h2 className="fw-normal mb-3">
                                <small className="circle" style={{ backgroundColor: 'rgb(91, 143, 249)' }}></small>
                                <span>
                                    {state.data.curr.totals?.total_real.toFixed(2)} {state.currency?.sufix}
                                </span>
                            </h2>
                        </div>
                        <div className="col-sm-6">
                            <p className="text-muted mb-0 mt-3">Предишна седмица</p>
                            <h2 className="fw-normal mb-3">
                                <small className="circle" style={{ backgroundColor: 'rgb(90, 216, 166)' }}></small>
                                <span>
                                    {state.data.prev.totals?.total_real.toFixed(2)} {state.currency?.sufix}
                                </span>
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="graph">
                    <Area {...config} onReady={e => setChart(e)} />
                </div>

            </div>
        </div>
    )
}

export default Turnover;