import { useState, useEffect, useRef } from 'react'
import { Alert, Card, Table } from 'react-bootstrap';
import Api from 'helpers/Api';
import Loader from 'components/misc/Loader';
import moment from 'moment';
import NoDataFound from 'components/misc/NoDataFound';
import Pagination from 'components/misc/Pagination';
import TableHeader from 'components/misc/TableHeader';
import TableCol from 'components/misc/TableCol';
import PriceValue from 'components/partials/PriceValue';
import { diff, intersect, unique } from 'helpers/Arrays';
import Refs from 'Refs';

function Index(props) {

    const defaultData = [];

    const tableRef = useRef(null);

    const [state, setState] = useState({
        data: defaultData,
        pages: 0,
        total: 0,
        loading: false,
        filter: {
            page: 1,
        },
        refresh: false,
    });

    const validations = props.validations;

    const [selected, setSelected] = useState(null);

    useEffect(() => {

        loading(true);

        Api.get('payments/clients/payments-for-storno', {
            params: {
                client_id: props.clientId,
                ...state.filter,
            }
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data.items,
                total: res.data.total,
                pages: res.data.pages,
                filter: {
                    ...prev.filter,
                    ...res.data.filter,
                },
            }));

            if (res.data.pages > 0 && state.filter.page > res.data.pages) {
                return handlePage({ selected: res.data.pages - 1 });
            }

            loading(false);
            sorting(false);
        });
    }, [props.clientId, state.refresh]);

    useEffect(() => {
        // let total = 0;

        // selected.map(r => {
        //     total += r.price;
        // });

        // console.log(total);

        props.onChange(selected);

    }, [selected]);

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }));
    }

    const sorting = (sorting) => {
        setState(prev => ({
            ...prev,
            sorting: Boolean(sorting)
        }));
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            refresh: new Date()
        }));
    }

    const handleSort = (sort, order) => {
        setState(prev => ({
            ...prev,
            sorting: true,
            filter: {
                ...prev.filter,
                sort: sort,
                order: order,
                page: 1,
            },
            refresh: new Date().getTime()
        }));
    }

    const showAccount = (id) => {
        let modal = Refs.getInstance().getRef('account');

        if (!modal) {
            return;
        }

        props.hide(() => {
            modal.open(id);
        });

        modal.onClose(() => {
            props.show();
        });

    }

    const handleSelect = payment => {
        setSelected(
            toSelectObj(payment)
        );
    }

    const toSelectObj = payment => {
        return {
            id: Number(payment.id),
            price: payment.max_storno_amount,
        }
    }


    return (
        <Card className="">
            <Card.Header>
                Плащания възможни за сторниране
            </Card.Header>
            <Card.Body>

                {validations?.payment_id &&
                    <Alert variant="danger">{validations?.payment_id}</Alert>
                }

                {state.loading && !state.sorting
                    ?
                    <Loader />
                    :
                    state.data.length === 0 ?
                        <Alert style={{ margin: 0 }}>Няма намерени записи</Alert>
                        :
                        <>

                            <Table className="xxl" striped bordered ref={tableRef}>
                                <TableHeader
                                    tableRef={tableRef}
                                    activeSortKey={state.filter.sort}
                                    activeSortDir={state.filter.order}
                                    onSort={(col, dir) => handleSort(col, dir)}
                                >
                                    <TableCol className="text-center" style={{ width: '5%' }}>

                                    </TableCol>
                                    <TableCol sortable sortKey="id">ID</TableCol>
                                    <TableCol className="text-center" sortable sortKey="amount">Платени</TableCol>
                                    <TableCol className="text-center" sortable sortKey="money_left">Оставащи</TableCol>
                                    <TableCol className="text-center" sortable sortKey="storno_amount">Сторнирани</TableCol>
                                    <TableCol className="text-center" sortable sortKey="max_storno_amount">Възможни за сторниране</TableCol>
                                    <TableCol className="text-center" sortable sortKey="paymethod_id">Начин на плащане</TableCol>
                                    <TableCol className="text-left" sortKey="accounts">Сметки</TableCol>
                                    <TableCol className="text-left" sortable sortKey="created_at">Съзадено на</TableCol>
                                    <TableCol className="text-left" sortable sortKey="description">Описание</TableCol>
                                </TableHeader>
                                <tbody>
                                    {state.data.map((r, i) =>
                                        <tr key={r.id}>
                                            <td className="text-center">
                                                <input
                                                    type="radio"
                                                    name="payment_id"
                                                    value={r.id}
                                                    onChange={e => handleSelect(r)}
                                                    checked={selected?.id === r.id}
                                                />
                                            </td>
                                            <td>
                                                {r.id}
                                            </td>
                                            <td className={`text-right ${r.amount < 0 && 'negative-value'}`}>
                                                <PriceValue prefix={r.currency?.prefix} sufix={r.currency?.sufix}>{r.amount}</PriceValue>
                                            </td>
                                            <td className={`text-right ${r.money_left < 0 && 'negative-value'}`}>
                                                <PriceValue prefix={r.currency?.prefix} sufix={r.currency?.sufix}>{r.money_left}</PriceValue>
                                            </td>
                                            <td className={`text-right ${r.storno_amount > 0 && 'negative-value'}`}>
                                                <PriceValue prefix={r.currency?.prefix} sufix={r.currency?.sufix}>{r.storno_amount}</PriceValue>
                                            </td>
                                            <td className={`text-right ${r.storno_amount > 0 && 'negative-value'}`}>
                                                <PriceValue prefix={r.currency?.prefix} sufix={r.currency?.sufix}>{r.max_storno_amount}</PriceValue>
                                            </td>
                                            <td>
                                                {r?.paymentmethod?.name}
                                            </td>
                                            <td>
                                                {r.accounts && r.accounts.map(account =>
                                                    <span
                                                        key={account.id}
                                                        className="link"
                                                        onClick={e => showAccount(account.id)}
                                                        style={{ display: 'block' }}
                                                    >
                                                        {account.id}
                                                    </span>
                                                )}
                                            </td>
                                            <td>
                                                {r.created_at ? moment(r.created_at).format('DD.MM.YYYY HH:mm') : ''}
                                            </td>
                                            <td>
                                                {r?.description}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>

                            <Pagination
                                page={state.filter.page}
                                pages={state.pages}
                                handlePage={handlePage}
                            />
                        </>
                }
            </Card.Body>
        </Card>
    )
}

export default Index;