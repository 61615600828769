import { forwardRef, useImperativeHandle } from "react";
import { Form } from "react-bootstrap";

function ArticleID(props, ref) {

    useImperativeHandle(ref, () => ({
        getName: getName,
        getType: getType,
        getTitle: getTitle,
        hasSettings: hasSettings,
        getSettings: getSettings,
        getDefaultOptions: () => ({ action: 'abort' })
    }));

    const getName = () => {
        return 'Артикул ID';
    }

    const getType = () => {
        return 'article_id';
    }

    const getTitle = () => {
        return 'Идентификатор на артикула';
    }

    const hasSettings = () => true;

    const getSettings = ({ state, setConfig, createOrUpdate }) => {

        if (createOrUpdate === 'create') {
            return (
                <div>
                    <label>
                        Действия при дублиране:
                    </label>
                    <div>

                        <Form.Check
                            id={'cb-update'}
                            type="radio"
                            label="Актуализирай"
                            checked={state.options?.action === 'update'}
                            onChange={e => setConfig('action', 'update')}
                        />
                        <Form.Check
                            id={'cb-abort'}
                            type="radio"
                            label="Прекрати импорта"
                            checked={state.options?.action === 'abort'}
                            onChange={e => setConfig('action', 'abort')}
                        />
                        <Form.Check
                            id={'cb_ignore'}
                            type="radio"
                            label="Игнорирай"
                            checked={state.options?.action === 'ignore'}
                            onChange={e => setConfig('action', 'ignore')}
                        />
                    </div>
                </div>
            )
        }

        if (createOrUpdate === 'update') {
            return (
                <div>
                    <label>
                        Действия при липса:
                    </label>
                    <div>

                        <Form.Check
                            id={'cb-abort'}
                            type="radio"
                            label="Прекрати импорта"
                            checked={state.options?.action === 'abort'}
                            onChange={e => setConfig('action', 'abort')}
                        />
                        <Form.Check
                            id={'cb_ignore'}
                            type="radio"
                            label="Игнорирай"
                            checked={state.options?.action === 'ignore'}
                            onChange={e => setConfig('action', 'ignore')}
                        />
                    </div>
                </div>
            )
        }
    }

    return getName();
}

export default forwardRef(ArticleID)