import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import moment from "moment"
import { FloatingLabel, Form, Dropdown, Row, Col } from 'react-bootstrap'

//providers
import { useAuthDataContext } from "providers/Auth"

//helpers
import { useQuery } from 'helpers/Url'
import Api from 'helpers/Api'
import Loader from 'components/misc/Loader'
import Autocomplete from 'components/misc/Autocomplete'
import Category from 'components/partials/filter/Category'
import DatePicker from 'components/partials/filter/DatePicker'
import StoreAndCashdesk from 'components/partials/filter/StoreAndCashdesk'

function Filter(props) {
    const history = useNavigate()
    const query = useQuery()

    const auth = useAuthDataContext();

    const [statuses, setStatuses] = useState([]);

    const [filters, setFilters] = useState({
        show: false,
    });

    useEffect(() => {
        Api.get('accounts/statuses')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setStatuses(res.data);
                }
            });
    }, []);

    const handleDateChange = (date, name) => {
        props.handleSearch(name, moment(date).format('YYYY-MM-DD'));
    }

    const handleSearch = e => {
        const name = e.target.name
        const value = e.target.value

        props.handleSearch(name, value)
    }

    const handleFromDateChange = e => {
        const name = e.target.name
        const value = e.target.value

        let date = value ? moment(value).format('YYYY-MM-DD') : '';

        props.handleSearch({
            created_from_date: date,
            created_to_date: date,
        });
    }

    const handleToDateChange = e => {
        const value = e.target.value

        let date = value ? moment(value).format('YYYY-MM-DD') : '';

        if (date && props.filter.created_from_date > date) {
            return;
        }

        props.handleSearch({
            created_to_date: date,
        });
    }

    const handleCashdeskChange = selected => {
        props.handleSearch('cashdesk_id', selected)
    }

    const handleChangeArticle = data => {
        props.handleSearch({
            article_id: data?.id || '',
        });
    }

    const handleCategoryChange = selected => {
        props.handleSearch('category_id', selected)
    }

    const handleChangeUser = data => {
        let checked = data.map(d => d.id);

        props.handleSearch({
            creator_id: checked,
        });
    }

    const handleChangeClient = data => {
        props.handleSearch({
            client_id: data?.id || '',
        });
    }

    const handleChangeSpeditor = data => {
        let checked = data.map(d => d.id);

        props.handleSearch({
            speditor_id: checked
        });
    }

    const handleChangePaymentMethod = data => {
        let checked = data.map(d => d.id);

        props.handleSearch({
            paymethod_id: checked
        });
    }

    const toggleFilters = () => {
        setFilters(prev => ({
            ...prev,
            show: !prev.show,
        }))
    }

    const getMoreFiltersValue = () => {
        let data = [];

        if (props.filter.created_from_date) {
            data.push(1);
        }

        if (props.filter.created_to_date) {
            data.push(1);
        }

        if ((props.filter.creator_id || []).length > 0) {
            data.push(1);
        }

        if ((props.filter.speditor_id || []).length > 0) {
            data.push(1);
        }

        if (props.filter.tracking_number) {
            data.push(1);
        }

        if (props.filter.article_id) {
            data.push(1);
        }

        if ((props.filter.category_id || []).length > 0) {
            data.push(1);
        }

        if ((props.filter.paymethod_id || []).length > 0) {
            data.push(1);
        }

        if (props.filter.paid_status) {
            data.push(1);
        }

        if (props.filter.deleted) {
            data.push(1);
        }

        if (data.length === 0) {
            return 'Няма избрани';
        }

        return data.length + ' избрани';
    }

    return (
        <div className="row page-filter">
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <FloatingLabel label="Търсене">
                    <Form.Control
                        type="text"
                        placeholder="Търсене"
                        name="search"
                        value={props.filter.search || ''}
                        onChange={handleSearch}
                        autoFocus
                    />
                </FloatingLabel>
            </div>
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <FloatingLabel label="Номер на сметка">
                    <Form.Control
                        type="text"
                        placeholder="Номер на сметка"
                        name="account_num"
                        value={props.filter.account_num || ''}
                        onChange={handleSearch}
                    />
                </FloatingLabel>
            </div>
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <FloatingLabel label="Статус">
                    <Form.Select
                        name="status_id"
                        value={props.filter.status_id || ''}
                        onChange={handleSearch}
                    >
                        <option value="">Всички</option>
                        {statuses.map(o =>
                            <option key={o.id} value={o.id}>{o?.translation?.name || o.name}</option>
                        )}
                    </Form.Select>
                </FloatingLabel>
            </div>

            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <StoreAndCashdesk
                    selected={props.filter.cashdesk_id || []}
                    onChange={handleCashdeskChange}
                    permission="accounts_opened, accounts_closed"
                />
            </div>
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <Autocomplete
                    inputPlaceholder="Клиент"
                    url="autocomplete/clients"
                    inputIdName="client_id"
                    // inputValue={state.data?.supplier?.name}
                    inputIdValue={props.filter.client_id || ''}
                    onChange={handleChangeClient}
                    onInputChange={handleChangeClient}
                />
            </div>
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <Dropdown drop="down" onToggle={toggleFilters} show={filters.show}>
                    <FloatingLabel label="Още филтри">
                        <Form.Control
                            type="text"
                            placeholder="Още филтри"
                            value={getMoreFiltersValue()}
                            onClick={toggleFilters}
                            readOnly
                            style={{
                                cursor: 'pointer'
                            }}
                        />
                    </FloatingLabel>
                    <Dropdown.Menu align="end" style={{ padding: '15px', width: '450px', right: 0 }}>
                        <Row>
                            <Col className="mb-3">
                                <DatePicker
                                    placeholder="Създаден от"
                                    name="created_from_date"
                                    value={props.filter.created_from_date || ''}
                                    onChange={handleFromDateChange}
                                />
                            </Col>
                            <Col className="mb-3">
                                <DatePicker
                                    placeholder="Създаден до"
                                    name="created_to_date"
                                    value={props.filter.created_to_date || ''}
                                    onChange={handleToDateChange}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-3">
                                <Autocomplete
                                    inputPlaceholder="Потребител"
                                    url="autocomplete/admins"
                                    inputIdName="creator_id[]"
                                    selectedIds={props.filter.creator_id || []}
                                    onChange={handleChangeUser}
                                    onInputChange={handleChangeUser}
                                    multiple
                                />
                            </Col>
                            <Col className="mb-3">
                                <Autocomplete
                                    inputPlaceholder="Начин на плащане"
                                    url="autocomplete/payment-methods"
                                    inputIdName="paymethod_id[]"
                                    selectedIds={props.filter.paymethod_id || []}
                                    onChange={handleChangePaymentMethod}
                                    onInputChange={handleChangePaymentMethod}
                                    multiple
                                    filterable={false}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-3">
                                <Autocomplete
                                    inputPlaceholder="Спедитор"
                                    url="autocomplete/speditors"
                                    inputIdName="speditor_id"
                                    selectedIds={props.filter.speditor_id || []}
                                    onChange={handleChangeSpeditor}
                                    // onInputChange={handleChangeSpeditor}
                                    multiple
                                    filterable={false}
                                />
                            </Col>
                            <Col>
                                <FloatingLabel label="Товарителница">
                                    <Form.Control
                                        type="text"
                                        placeholder="Товарителница"
                                        name="tracking_number"
                                        value={props.filter.tracking_number || ''}
                                        onChange={handleSearch}
                                    />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-3">
                                <Autocomplete
                                    inputPlaceholder="Артикул"
                                    url="autocomplete/articles"
                                    inputIdName="article_id"
                                    // inputValue={state.data?.supplier?.name}
                                    inputIdValue={props.filter.article_id || ''}
                                    onChange={handleChangeArticle}
                                    onInputChange={handleChangeArticle}
                                    renderText={data => {
                                        return (
                                            <div style={{ display: 'flex', width: '100%' }}>
                                                <div style={{ width: 'max-content', marginRight: '10px' }}>
                                                    {data.id}
                                                </div>
                                                <div>
                                                    {data.name}
                                                    <br />
                                                    {data.article_name}
                                                </div>
                                            </div>
                                        )
                                    }}
                                    renderInputText={data => data.article_name}
                                    listStyle={{
                                        minWidth: 'max-content'
                                    }}
                                />
                            </Col>
                            <Col>
                                <Category
                                    selected={props.filter.category_id || []}
                                    onChange={handleCategoryChange}
                                    position="end"
                                />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <FloatingLabel label="Платена сума">
                                    <Form.Select
                                        name="paid_status"
                                        value={props.filter.paid_status || ''}
                                        onChange={handleSearch}
                                    >
                                        <option value="">Всички</option>
                                        <option value="paid">Изцяло платено</option>
                                        <option value="part">Частично платено</option>
                                        <option value="not-paid">Без плащане</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FloatingLabel label="Изтрити">
                                    <Form.Select
                                        name="deleted"
                                        value={props.filter.deleted || ''}
                                        onChange={handleSearch}
                                    >
                                        <option value="">По подразбиране</option>
                                        <option value="with-deleted">Покажи изтрити</option>
                                        <option value="only-deleted">Покажи само изтрити</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div >
    )
}

export default Filter;