import React, { useState, useImperativeHandle, forwardRef, useEffect } from 'react'
import { Modal, Button, Tabs, Tab } from 'react-bootstrap';
import SaveButton from 'components/misc/Button';
import Loader from 'components/misc/Loader';
import Home from './Home';
import StorageMovements from './StorageMovements';
import { useAppContext } from 'providers/App';
import { useAuthDataContext } from 'providers/Auth';
import Refs from 'Refs';

function View(props, ref) {

    const tabs = [
        {
            id: 'home',
            name: 'Преглед',
            component: Home
        },
        {
            id: 'storage',
            name: 'Складови движения',
            component: StorageMovements
        }
    ];

    const app = useAppContext();
    const auth = useAuthDataContext();

    const [state, setState] = useState({
        show: false,
        params: null,
        id: null,
        tabId: null,
        onClose: null,
    });

    useImperativeHandle(ref, () => ({
        open: (id) => {
            handleShow(id);
        },
        close: () => {
            handleClose();
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        }
    }));

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const handleShow = (id) => {
        setState(prev => ({
            ...prev,
            id: id,
            tabId: getDefaultActiveTab()?.id,
        }));

        show();
    }

    const handleClose = () => {
        setState(prev => ({
            ...prev,
            show: false,
            tabId: tabs[0].id
        }));

        if (typeof state.onClose === 'function') {
            hide(state.onClose);
        } else {
            hide();
        }

        setState(prev => ({
            ...prev,
            onClose: null,
        }));
    }

    const setKey = id => {
        setState(prev => ({
            ...prev,
            tabId: id,
        }));
    }

    const hasTabPermission = key => {
        let map = {
            home: 'storeloads_view',
            storage: 'storeloads_store_movements',
        };

        let permission = map[key];

        if (permission && auth.getUser().permission(permission) === false) {
            return false;
        }

        return true;
    }

    const getDefaultActiveTab = () => {
        return tabs.find(tab => {
            return hasTabPermission(tab.id);
        });
    }

    const showArticle = id => {
        let modal = Refs.getInstance().getRef('article');

        if (!modal) {
            return;
        }

        hide(() => {
            modal.open(id);
        });

        modal.onClose(() => {
            show();
        });
    }

    return (
        <Modal size="xl" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Преглед на зареждане
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tabs
                    activeKey={state.tabId}
                    onSelect={(k) => setKey(k)}
                    className="mb-3"
                    mountOnEnter
                >
                    {tabs.map(tab =>
                        <Tab key={tab.id} eventKey={tab.id} title={tab.name}>
                            {React.createElement(tab.component, {
                                id: state.id,
                                show: show,
                                hide: hide,
                                showArticle: showArticle
                            })}
                        </Tab>
                    )}
                </Tabs>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Затвори
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default forwardRef(View);