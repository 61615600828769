import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { InfoCircleFill, InfoCircle } from 'react-bootstrap-icons';

function Info(props) {
    const id = Math.random().toString();
    const placement = props.placement || 'auto';
    const marginLeft = props.marginLeft || '';

    return (
        <OverlayTrigger
            placement={'auto-start'}
            delay={400}
            // show
            overlay={
                <Tooltip className={props.className} id={'tooltip-info-' + id}>
                    {props.children}
                </Tooltip>
            }
        >
            <span style={{ marginLeft: marginLeft, cursor: 'help', ...props.style }} className="tooltip-info-icon">
                <InfoCircle width={14} height={14} />
            </span>
        </OverlayTrigger>
    )
}

export default Info;
