import { useEffect, useState } from 'react';
import logo from 'assets/img/logo.svg';
import { Link, useNavigate } from 'react-router-dom';
import {
    CaretDownFill,
    CaretRightFill,
    TagsFill,
    ListNested,
    Truck,
    Shop,
    Translate,
    GearFill,
    House,
    People,
    CurrencyExchange,
    FolderPlus,
    ArrowLeftRight,
    GeoAltFill,
    CashStack,
    Coin,
    BoxSeam,
    Building,
    Boxes,
    GeoAlt,
    Printer,
    CashCoin,
    Archive,
    Display,
    Phone,
    GraphUp,
    PersonVcard,
    List,
    Book,
    Flag,
} from 'react-bootstrap-icons';

import {
    Articles,
    Brands,
    CashDesk,
    Catalogue,
    Cms,
    Contract,
    Documents,
    Home,
    Intrastat,
    Invoice,
    MailTemplates,
    Money,
    NewOrder,
    Orders,
    PriceRules,
    Protocol,
    Report,
    Returns,
    Reviews,
    Revision,
    Settings,
    Storage,
    Users,
    Warranty
} from 'components/misc/Icons';

import { Button, Dropdown, Form, Offcanvas } from 'react-bootstrap';
import { useAuthDataContext } from 'providers/Auth';

import Api from 'helpers/Api';
import Refs from 'Refs';
import { useQuery } from 'helpers/Url';
import MobileNavBar from './MobileNavBar';

//images
import userIcon from 'assets/img/header/icons/user.svg'
import logoutIcon from 'assets/img/header/icons/logout.svg'
import Search from './Search';

function Index(props) {

    const auth = useAuthDataContext();

    const navigate = useNavigate();
    const query = useQuery();

    const [state, setState] = useState({
        stores: [],
        showStores: false,
    });

    useEffect(() => {
        Api.get('store/common/stores')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setState(prev => ({
                        ...prev,
                        stores: res.data,
                    }));
                }
            });
    }, []);

    const toggleStores = () => {
        setState(prev => ({
            ...prev,
            showStores: !prev.showStores,
        }));
    }

    const setActiveStore = id => {
        // достатъчно е да променим url
        // промяната се извършва от App.js, който следи за промяна в url
        query.set('sid', id);

        navigate('?' + query.toString(), {
            replace: false,
        });
    }

    const handleCreatePayment = e => {
        e.preventDefault();

        let modal = Refs.getInstance().getRef('newPayment');

        if (!modal) {
            return;
        }

        modal.add();

        modal.onSuccess(() => {
            navigate('/payments');
        });
    }

    const user = auth.getUser();


    const getNavItems = () => {
        return (
            <>
                <li>
                    <Link className="nav-link" to="/"><Home className="icon" /> Начало</Link>
                </li>
                <li>
                    <span className="nav-link"><Cms className="icon" /> Управление <CaretDownFill className="caret" /></span>
                    <ul>
                        {user.permission('eshop_manage') &&
                            <li>
                                <span className="nav-link"><Display className="icon" /> Уебсайт<CaretRightFill className="caret" /></span>
                                <ul>
                                    <li>
                                        <Link className="nav-link" to="/pages"> Страници</Link>
                                    </li>
                                    {/* <li>
                                        <Link className="nav-link" to="/config">Настройки</Link>
                                    </li> */}
                                    <li>
                                        <Link className="nav-link" to="/seo">SEO съдържание</Link>
                                    </li>
                                </ul>
                            </li>
                        }
                        <li>
                            <Link className="nav-link" to="/projects"><Flag className="icon" />Проекти</Link>
                        </li>
                        {/* <li>
                            <Link className="nav-link" to="/reviews"><Reviews className="icon" />Отзиви</Link>
                        </li> */}
                        <li>
                            <Link className="nav-link" to="/catalogues"><Book className="icon" />Каталози</Link>
                        </li>
                    </ul>
                </li>
                {user.hasAny('articles_index, categories_index, pricelists_index, suppliers_index, speditors_index') &&
                    <li>
                        <span className="nav-link"><Catalogue className="icon" /> Каталог <CaretDownFill className="caret" /></span>
                        <ul>
                            {user.permission('articles_index') &&
                                <li>
                                    <Link className="nav-link" to="/articles"><Articles className="icon" /> Артикули</Link>
                                </li>
                            }
                            {user.permission('categories_index') &&
                                <li>
                                    <Link className="nav-link" to="/categories"><ListNested className="icon" /> Категории</Link>
                                </li>
                            }
                            {/* {user.permission('pricelists_index') &&
                                <li>
                                    <span className="nav-link"><PriceRules className="icon" />Ценови правила <CaretRightFill className="caret" /></span>
                                    <ul>
                                        <li>
                                            <Link className="nav-link" to="/price-rules">Правила</Link>
                                        </li>
                                        <li>
                                            <Link className="nav-link" to="/price-rules/groups">Групи</Link>
                                        </li>
                                    </ul>
                                </li>
                            } */}
                            {/* {user.permission('clients_index') &&
                                <li>
                                    <Link className="nav-link" to="/clients"><People className="icon" />Клиенти</Link>
                                </li>
                            }
                            {user.permission('suppliers_index') &&
                                <li>
                                    <Link className="nav-link" to="/suppliers"><Truck className="icon" />Доставчици</Link>
                                </li>
                            }
                            {user.permission('speditors_index') &&
                                <li>
                                    <Link className="nav-link" to="/speditors"><BoxSeam className="icon" />Спедитори</Link>
                                </li>
                            } */}
                            {/* <li>
                                <Link className="nav-link" to="/brands"><Brands className="icon" />Марки</Link>
                            </li> */}
                        </ul>
                    </li>
                }
                {/* {user.hasAny('storeloads_opened, storeloads_closed, storemoves_opened, storemoves_closed, revisions_opened, revisions_closed') &&
                    <li>
                        <span className="nav-link"><Storage className="icon" /> Склад <CaretDownFill className="caret" /></span>
                        <ul>
                            {user.hasAny('storeloads_opened, storeloads_closed') &&
                                <li>
                                    <Link className="nav-link" to="/storage/loads"><FolderPlus className="icon" /> Зареждания</Link>
                                </li>
                            }
                            {user.hasAny('storemoves_opened, storemoves_closed') &&
                                <li>
                                    <Link className="nav-link" to="/storage/moves"><ArrowLeftRight className="icon" /> Прехвърляния</Link>
                                </li>
                            }
                            {user.hasAny('revisions_opened, revisions_closed') &&
                                <li>
                                    <Link className="nav-link" to="/storage/revisions"><Revision className="icon" /> Ревизии</Link>
                                </li>
                            }
                        </ul>
                    </li>
                } */}
                {/* <li>
                    <span className="nav-link"><CashDesk className="icon" /> Каса <CaretDownFill className="caret" /></span>
                    <ul>
                        {user.permission('accounts_edit') &&
                            <li>
                                <Link className="nav-link" to="/accounts/new"><TagsFill className="icon" /> Нова продажба</Link>
                            </li>
                        }
                        {user.hasAny('accounts_opened, accounts_closed') &&
                            <li>
                                <span className="nav-link"><Coin className="icon" />Сметки <CaretRightFill className="caret" /></span>
                                <ul>
                                    {user.permission('accounts_opened') &&
                                        <li>
                                            <Link className="nav-link" to="/accounts?status_id=1">Отворени</Link>
                                        </li>
                                    }
                                    {user.permission('accounts_closed') &&
                                        <li>
                                            <Link className="nav-link" to="/accounts?status_id=2">Затворени</Link>
                                        </li>
                                    }
                                    {user.hasAny('accounts_opened, accounts_closed') &&
                                        <li>
                                            <Link className="nav-link" to="/accounts">Всички</Link>
                                        </li>
                                    }
                                    {user.permission('accounts_edit') &&
                                        <li>
                                            <Link className="nav-link" to="/accounts/new">Нова продажба</Link>
                                        </li>
                                    }
                                </ul>
                            </li>
                        }
                        {user.permission('payments_index') &&
                            <li>
                                <span className="nav-link"><Money className="icon" />Плащания <CaretRightFill className="caret" /></span>
                                <ul>
                                    <li>
                                        <Link className="nav-link" to="/payments">Последни</Link>
                                    </li>
                                    <li>
                                        <Link className="nav-link" to="/" onClick={handleCreatePayment}>Ново плащане</Link>
                                    </li>
                                </ul>
                            </li>
                        }
                        {user.hasAny('clientorders_index, clientorders_edit') &&
                            <li>
                                <span className="nav-link"><Orders className="icon" />Клиентски заявки <CaretRightFill className="caret" /></span>
                                <ul>
                                    {user.permission('clientorders_index') &&
                                        <li>
                                            <Link className="nav-link" to="/orders">Всички заявки</Link>
                                        </li>
                                    }
                                    {user.permission('clientorders_edit') &&
                                        <li>
                                            <Link className="nav-link" to="/orders/new">Нова заявка</Link>
                                        </li>
                                    }
                                </ul>
                            </li>
                        }
                        {user.permission('clientorders_index') &&
                            <li>
                                <Link className="nav-link" to="/returns"><Returns className="icon" />Връщания</Link>
                            </li>
                        }
                        {user.permission('cards_instances') &&
                            <li>
                                <Link className="nav-link" to="/cards"><PersonVcard className="icon" />Пакетни карти</Link>
                            </li>
                        }
                    </ul>
                </li> */}
                {/* {user.hasAny('invoices_index, document_warranty_view, document_contract_view, document_acceptance_protocol_view, intrastat') &&
                    <li>
                        <span className="nav-link"><Documents className="icon" /> Документи <CaretDownFill className="caret" /></span>
                        <ul>
                            {user.permission('invoices_index') &&
                                <li>
                                    <span className="nav-link"><Invoice className="icon" />Фактури <CaretRightFill className="caret" /></span>
                                    <ul>
                                        <li>
                                            <Link className="nav-link" to="/invoices">Всички</Link>
                                        </li>
                                        {user.permission('invoices_create') &&
                                            <li>
                                                <Link className="nav-link" to="/invoices/new">Нова фактура</Link>
                                            </li>
                                        }
                                    </ul>
                                </li>
                            }
                            {user.permission('document_warranty_view') &&
                                <li>
                                    <span className="nav-link"><Warranty className="icon" />Гаранции <CaretRightFill className="caret" /></span>
                                    <ul>
                                        <li>
                                            <Link className="nav-link" to="/warranty?status_id=1">Отворени</Link>
                                        </li>
                                        <li>
                                            <Link className="nav-link" to="/warranty?status_id=2">Изпратени</Link>
                                        </li>
                                        <li>
                                            <Link className="nav-link" to="/warranty?status_id=3">Затворени</Link>
                                        </li>
                                        <li>
                                            <Link className="nav-link" to="/warranty">Всички</Link>
                                        </li>
                                        <li>
                                            <Link className="nav-link" to="/warranty?is_template=1">Шаблони</Link>
                                        </li>
                                        <li>
                                            <Link className="nav-link" to="/warranty/new">Нова гаранция</Link>
                                        </li>
                                    </ul>
                                </li>
                            }
                            {user.permission('document_contract_view') &&
                                <li>
                                    <span className="nav-link"><Contract className="icon" />Договори <CaretRightFill className="caret" /></span>
                                    <ul>
                                        <li>
                                            <Link className="nav-link" to="/contracts?status_id=4">Отворени</Link>
                                        </li>
                                        <li>
                                            <Link className="nav-link" to="/contracts?status_id=5">Изпратени</Link>
                                        </li>
                                        <li>
                                            <Link className="nav-link" to="/contracts?status_id=6">Затворени</Link>
                                        </li>
                                        <li>
                                            <Link className="nav-link" to="/contracts">Всички</Link>
                                        </li>
                                        <li>
                                            <Link className="nav-link" to="/contracts?is_template=1">Шаблони</Link>
                                        </li>
                                        <li>
                                            <Link className="nav-link" to="/contracts/new">Нов договор</Link>
                                        </li>
                                    </ul>
                                </li>
                            }
                            {user.permission('document_acceptance_protocol_view') &&
                                <li>
                                    <span className="nav-link"><Protocol className="icon" />ППП <CaretRightFill className="caret" /></span>
                                    <ul>
                                        <li>
                                            <Link className="nav-link" to="/protocols?status_id=10">Отворени</Link>
                                        </li>
                                        <li>
                                            <Link className="nav-link" to="/protocols?status_id=11">Изпратени</Link>
                                        </li>
                                        <li>
                                            <Link className="nav-link" to="/protocols?status_id=12">Затворени</Link>
                                        </li>
                                        <li>
                                            <Link className="nav-link" to="/protocols">Всички</Link>
                                        </li>
                                        <li>
                                            <Link className="nav-link" to="/protocols?is_template=1">Шаблони</Link>
                                        </li>
                                        <li>
                                            <Link className="nav-link" to="/protocols/new">Нов протокол</Link>
                                        </li>
                                    </ul>
                                </li>
                            }
                            {user.permission('intrastat') &&
                                <li>
                                    <Link className="nav-link" to="/intrastats"><Intrastat className="icon" />Интрастат</Link>
                                </li>
                            }
                        </ul>
                    </li>
                } */}
                {/* <li>
                    <span className="nav-link"><Report className="icon" /> Справки <CaretDownFill className="caret" /></span>
                    <ul>
                        {user.hasAny('mon_totals, mon_poses, mon_paymethods, mon_users, mon_clientgroups, mon_clients, mon_articles, mon_period_graphs, mon_summary_by_barsys, report_full_access') &&
                            <li>
                                <span className="nav-link"><Display className="icon" />Мониторинг <CaretRightFill className="caret" /></span>
                                <ul>
                                    {user.hasAny('mon_totals, mon_poses, mon_paymethods, mon_users, mon_clientgroups, mon_clients, mon_articles, report_full_access') &&
                                        <li>
                                            <span className="nav-link">Обороти<CaretRightFill className="caret" /></span>
                                            <ul>
                                                {user.hasAny('mon_totals, report_full_access') &&
                                                    <li>
                                                        <Link className="nav-link" to="/reports/monitoring/totals">Общо</Link>
                                                    </li>
                                                }
                                                {user.hasAny('mon_poses, report_full_access') &&
                                                    <li>
                                                        <Link className="nav-link" to="/reports/monitoring/totals-by-cashdesks">По каси</Link>
                                                    </li>
                                                }
                                                {user.hasAny('mon_paymethods, report_full_access') &&
                                                    <li>
                                                        <Link className="nav-link" to="/reports/monitoring/totals-by-paymethods">По начини на плащане</Link>
                                                    </li>
                                                }
                                                {user.hasAny('mon_users, report_full_access') &&
                                                    <li>
                                                        <Link className="nav-link" to="/reports/monitoring/totals-by-users">По потребители</Link>
                                                    </li>
                                                }
                                                {user.hasAny('mon_users, report_full_access') &&
                                                    <li>
                                                        <Link className="nav-link" to="/reports/monitoring/totals-by-users-paymethods">По потребители и начин на плащане</Link>
                                                    </li>
                                                }
                                                {user.hasAny('mon_clientgroups, report_full_access') &&
                                                    <li>
                                                        <Link className="nav-link" to="/reports/monitoring/totals-by-client-groups">По клиентски групи</Link>
                                                    </li>
                                                }
                                                {user.hasAny('mon_clients, report_full_access') &&
                                                    <li>
                                                        <Link className="nav-link" to="/reports/monitoring/totals-by-clients">По клиенти</Link>
                                                    </li>
                                                }
                                                {user.hasAny('mon_articles, report_full_access') &&
                                                    <li>
                                                        <Link className="nav-link" to="/reports/monitoring/totals-by-articles">По артикули</Link>
                                                    </li>
                                                }
                                            </ul>
                                        </li>
                                    }
                                    {user.hasAny('mon_period_graphs, report_full_access') &&
                                        <li>
                                            <Link className="nav-link" to="/reports/monitoring/load">Натоварване</Link>
                                        </li>
                                    }
                                    {user.hasAny('mon_summary_by_barsys, report_full_access') &&
                                        <li>
                                            <Link className="nav-link" to="/reports/monitoring/totals-by-stores">По търговски обекти</Link>
                                        </li>
                                    }
                                    {user.hasAny('mon_period_status, report_full_access') &&
                                        <li>
                                            <Link className="nav-link" to="/reports/monitoring/period-status">Приключване</Link>
                                        </li>
                                    }
                                </ul>
                            </li>
                        }
                        <li>
                            <span className="nav-link"><GraphUp className="icon" />Продажби <CaretRightFill className="caret" /></span>
                            <ul>
                                {user.hasAny('reports_sales_by_periods, reports_periods, report_full_access') &&
                                    <li>
                                        <span className="nav-link">По периоди<CaretRightFill className="caret" /></span>
                                        <ul>
                                            <li>
                                                <Link className="nav-link" to="/reports/sales/periods/totals">Общо</Link>
                                            </li>
                                        </ul>
                                    </li>
                                }
                                {user.hasAny('reports_sales_by_articles, reports_sales_by_articles_and_weekdays, reports_sales_by_hours, reports_sales_by_articles_and_months, reports_sales_by_articles_and_barsys, report_full_access') &&
                                    <li>
                                        <span className="nav-link">По артикули<CaretRightFill className="caret" /></span>
                                        <ul>
                                            {user.hasAny('reports_sales_by_articles, report_full_access') &&
                                                <li>
                                                    <Link className="nav-link" to="/reports/sales/articles/totals">Общо</Link>
                                                </li>
                                            }
                                            {user.hasAny('reports_sales_by_articles_and_weekdays, report_full_access') &&
                                                <li>
                                                    <Link className="nav-link" to="/reports/sales/articles/weekdays">По артикули и дни</Link>
                                                </li>
                                            }
                                            {user.hasAny('reports_sales_by_articles_and_weekdays, report_full_access') &&
                                                <li>
                                                    <Link className="nav-link" to="/reports/sales/articles/days">По артикули и ден</Link>
                                                </li>
                                            }
                                            {user.hasAny('reports_sales_by_hours, report_full_access') &&
                                                <li>
                                                    <Link className="nav-link" to="/reports/sales/articles/hours">По артикули и часове</Link>
                                                </li>
                                            }
                                            {user.hasAny('reports_sales_by_articles_and_months, report_full_access') &&
                                                <li>
                                                    <Link className="nav-link" to="/reports/sales/articles/months">По артикули и месеци</Link>
                                                </li>
                                            }
                                            {user.hasAny('reports_sales_by_articles_and_barsys, report_full_access') &&
                                                <li>
                                                    <Link className="nav-link" to="/reports/sales/articles/stores">По търговски обекти</Link>
                                                </li>
                                            }
                                            {user.hasAny('reports_sales_by_articles, report_full_access') &&
                                                <li>
                                                    <Link className="nav-link" to="/reports/sales/articles/sellout">Sellout</Link>
                                                </li>
                                            }
                                        </ul>
                                    </li>
                                }
                                {user.hasAny('reports_sales_by_cats, reports_sales_by_cats_and_weekdays, reports_sales_by_cats_and_hours, report_full_access') &&
                                    <li>
                                        <span className="nav-link">По категории<CaretRightFill className="caret" /></span>
                                        <ul>
                                            {user.hasAny('reports_sales_by_cats, report_full_access') &&
                                                <li>
                                                    <Link className="nav-link" to="/reports/sales/categories/totals">Общо</Link>
                                                </li>
                                            }
                                            {user.hasAny('reports_sales_by_cats_and_weekdays, report_full_access') &&
                                                <li>
                                                    <Link className="nav-link" to="/reports/sales/categories/weekdays">По категории и дни</Link>
                                                </li>
                                            }
                                            {user.hasAny('reports_sales_by_cats_and_hours, report_full_access') &&
                                                <li>
                                                    <Link className="nav-link" to="/reports/sales/categories/hours">По категории и часове</Link>
                                                </li>
                                            }
                                        </ul>
                                    </li>
                                }
                                {user.hasAny('reports_sales_by_barsys, reports_sales_by_barsys_periods, report_full_access') &&
                                    <li>
                                        <span className="nav-link">По търговски обекти<CaretRightFill className="caret" /></span>
                                        <ul>
                                            {user.hasAny('reports_sales_by_barsys, report_full_access') &&
                                                <li>
                                                    <Link className="nav-link" to="/reports/sales/stores/totals">Общо</Link>
                                                </li>
                                            }
                                            {user.hasAny('reports_sales_by_barsys_periods, report_full_access') &&
                                                <li>
                                                    <Link className="nav-link" to="/reports/sales/stores/periods">По търговски обекти и периоди</Link>
                                                </li>
                                            }
                                        </ul>
                                    </li>
                                }
                                {user.hasAny('reports_sales_by_users_totals, reports_sales_by_users_paymethods, report_full_access') &&
                                    <li>
                                        <span className="nav-link">По потребители<CaretRightFill className="caret" /></span>
                                        <ul>
                                            {user.hasAny('reports_sales_by_users_totals, report_full_access') &&
                                                <li>
                                                    <Link className="nav-link" to="/reports/sales/users/totals">Оборот по сметки</Link>
                                                </li>
                                            }
                                            {user.hasAny('reports_sales_by_users_paymethods, report_full_access') &&
                                                <li>
                                                    <Link className="nav-link" to="/reports/sales/users/paymethods">По начини на плащане</Link>
                                                </li>
                                            }
                                        </ul>
                                    </li>
                                }
                                {user.hasAny('reports_clients_totals, reports_clients_groups_totals, reports_clients_by_months, report_full_access') &&
                                    <li>
                                        <span className="nav-link">По клиенти<CaretRightFill className="caret" /></span>
                                        <ul>
                                            {user.hasAny('reports_clients_totals, report_full_access') &&
                                                <li>
                                                    <Link className="nav-link" to="/reports/sales/clients/totals">Общо</Link>
                                                </li>
                                            }
                                            {user.hasAny('reports_clients_groups_totals, report_full_access') &&
                                                <li>
                                                    <Link className="nav-link" to="/reports/sales/clients/groups">По клиентски групи</Link>
                                                </li>
                                            }
                                            {user.hasAny('reports_clients_by_months, report_full_access') &&
                                                <li>
                                                    <Link className="nav-link" to="/reports/sales/clients/months">По клиенти и месеци</Link>
                                                </li>
                                            }
                                        </ul>
                                    </li>
                                }
                                {user.hasAny('reports_sales_by_orders, report_full_access') &&
                                    <li>
                                        <span className="nav-link">По поръчки<CaretRightFill className="caret" /></span>
                                        <ul>
                                            {user.hasAny('reports_sales_by_orders, report_full_access') &&
                                                <li>
                                                    <Link className="nav-link" to="/reports/sales/accountsrows/details">Подробно</Link>
                                                </li>
                                            }
                                        </ul>
                                    </li>
                                }
                            </ul>
                        </li>
                        {user.hasAny('reports_articles_actual_prices, reports_articles_delivery_prices, report_full_access') &&
                            <li>
                                <span className="nav-link"><Articles className="icon" />Артикули <CaretRightFill className="caret" /></span>
                                <ul>
                                    {user.hasAny('reports_articles_actual_prices, report_full_access') &&
                                        <li>
                                            <Link className="nav-link" to="/reports/articles/actual-prices">Промени в продажни цени</Link>
                                        </li>
                                    }
                                    {user.hasAny('reports_articles_delivery_prices, report_full_access') &&
                                        <li>
                                            <Link className="nav-link" to="/reports/articles/delivery-prices">Промени в доставни цени</Link>
                                        </li>
                                    }
                                </ul>
                            </li>
                        }
                        <li>
                            <span className="nav-link"><Boxes className="icon" />Склад <CaretRightFill className="caret" /></span>
                            <ul>
                                {user.hasAny('store_amounts, store_amounts_by_stores, store_amounts_by_barsys, reports_store_amounts_by_date, report_full_access') &&
                                    <li>
                                        <span className="nav-link">Наличности<CaretRightFill className="caret" /></span>
                                        <ul>
                                            {user.hasAny('store_amounts, report_full_access') &&
                                                <li>
                                                    <Link className="nav-link" to="/reports/storage/total-amounts">Общо наличности</Link>
                                                </li>
                                            }
                                            {user.hasAny('store_amounts_by_stores, report_full_access') &&
                                                <li>
                                                    <Link className="nav-link" to="/reports/storage/amounts-by-depots">По складове</Link>
                                                </li>
                                            }
                                            {user.hasAny('store_amounts_by_barsys, report_full_access') &&
                                                <li>
                                                    <Link className="nav-link" to="/reports/storage/amounts-by-stores">По търговски обекти</Link>
                                                </li>
                                            }
                                            {user.hasAny('store_amounts_by_stores, report_full_access') &&
                                                <li>
                                                    <Link className="nav-link" to="/reports/storage/amounts-by-totals">По складове общо</Link>
                                                </li>
                                            }
                                            {user.hasAny('reports_store_amounts_by_date, report_full_access') &&
                                                <li>
                                                    <Link className="nav-link" to="/reports/storage/amounts-by-date">Към дата</Link>
                                                </li>
                                            }
                                            {user.hasAny('store_amounts_by_barsys, report_full_access') &&
                                                <li>
                                                    <Link className="nav-link" to="/reports/storage/amounts-by-date-stores">Към дата и търговски обекти</Link>
                                                </li>
                                            }
                                        </ul>
                                    </li>
                                }
                                {user.hasAny('reports_store_outgoes, reports_store_list_details, report_full_access') &&
                                    <li>
                                        <span className="nav-link">Складови движения<CaretRightFill className="caret" /></span>
                                        <ul>
                                            {user.hasAny('reports_store_outgoes, report_full_access') &&
                                                <li>
                                                    <Link className="nav-link" to="/reports/storage/outgoes">Движения по операции</Link>
                                                </li>
                                            }
                                            {user.hasAny('reports_store_outgoes, report_full_access') &&
                                                <li>
                                                    <Link className="nav-link" to="/reports/storage/list-resume">Движения по операции сумарно</Link>
                                                </li>
                                            }
                                            {user.hasAny('reports_store_list_details, report_full_access') &&
                                                <li>
                                                    <Link className="nav-link" to="/reports/storage/list-details">Движения на артикули</Link>
                                                </li>
                                            }
                                            {user.hasAny('reports_store_list_details, report_full_access') &&
                                                <li>
                                                    <Link className="nav-link" to="/reports/storage/outgoes-sum">Движения по артикули сумарно</Link>
                                                </li>
                                            }
                                        </ul>
                                    </li>
                                }
                                {user.hasAny('reports_storeloads, report_storeloads_totals, report_store_loads_by_supplier, report_full_access') &&
                                    <li>
                                        <span className="nav-link">Зареждания <CaretRightFill className="caret" /></span>
                                        <ul>
                                            {user.hasAny('report_storeloads_totals, report_full_access') &&
                                                <li>
                                                    <Link className="nav-link" to="/reports/storeloads/totals">Общо</Link>
                                                </li>
                                            }
                                            {user.hasAny('report_storeloads_totals, report_full_access') &&
                                                <li>
                                                    <Link className="nav-link" to="/reports/storeloads/articles">По артикули</Link>
                                                </li>
                                            }
                                            {user.hasAny('report_storeloads_totals, report_full_access') &&
                                                <li>
                                                    <Link className="nav-link" to="/reports/storeloads/operations">По операции</Link>
                                                </li>
                                            }
                                            {user.hasAny('report_store_loads_by_supplier, report_full_access') &&
                                                <li>
                                                    <Link className="nav-link" to="/reports/storeloads/suppliers">По доставчици</Link>
                                                </li>
                                            }
                                        </ul>
                                    </li>
                                }
                            </ul>
                        </li>
                    </ul>
                </li> */}
                <li>
                    <span className="nav-link"><Settings className="icon" /> Настройки <CaretDownFill className="caret" /></span>
                    <ul>
                        {user.hasAny('barsys_index, poses_index, companies_index, paymentmethods_index, printers_index, depots_index, languages_index, currencies_index') &&
                            <li>
                                <span className="nav-link"><GearFill className="icon" />Основни <CaretRightFill className="caret" /></span>
                                <ul>
                                    {/* {user.permission('barsys_index') &&
                                        <li>
                                            <Link className="nav-link" to="/stores"><Shop className="icon" />Търговски обекти</Link>
                                        </li>
                                    } */}
                                    {/* {user.permission('poses_index') &&
                                        <li>
                                            <Link className="nav-link" to="/cashdesks"><CashStack className="icon" />Каси</Link>
                                        </li>
                                    } */}
                                    {/* {user.permission('companies_index') &&
                                        <li>
                                            <Link className="nav-link" to="/companies"><Building className="icon" />Фирми</Link>
                                        </li>
                                    } */}
                                    {/* {user.permission('paymentmethods_index') &&
                                        <li>
                                            <Link className="nav-link" to="/payment-methods"><CashCoin className="icon" />Начини на плащане</Link>
                                        </li>
                                    } */}
                                    {/* {user.permission('printers_index') &&
                                        <li>
                                            <Link className="nav-link" to="/devices"><Printer className="icon" />Устройства</Link>
                                        </li>
                                    } */}
                                    {/* {user.permission('depots_index') &&
                                        <li>
                                            <Link className="nav-link" to="/depots"><House className="icon" />Складове</Link>
                                        </li>
                                    } */}
                                    {user.permission('languages_index') &&
                                        <li>
                                            <Link className="nav-link" to="/languages"><Translate className="icon" />Езици</Link>
                                        </li>
                                    }
                                    {/* {user.permission('currencies_index') &&
                                        <li>
                                            <Link className="nav-link" to="/currencies"><CurrencyExchange className="icon" />Валути</Link>
                                        </li>
                                    } */}
                                    {/* {user.isSuperAdmin() &&
                                        <li>
                                            <Link className="nav-link" to="/countries"><GeoAlt className="icon" />Държави</Link>
                                        </li>
                                    } */}
                                </ul>
                            </li>
                        }
                        {user.hasAny('cards_instances, cards_index, cards_articlegroups, amounttypes_edit, articles_details') &&
                            <li>
                                <span className="nav-link"><Articles className="icon" />Артикули<CaretRightFill className="caret" /></span>
                                <ul>
                                    {/* {user.hasAny('cards_instances, cards_index, cards_articlegroups') &&
                                        <li>
                                            <span className="nav-link">Пакетни карти <CaretRightFill className="caret" /></span>
                                            <ul>
                                                {user.permission('cards_index') &&
                                                    <li>
                                                        <Link className="nav-link" to="/cards/types">Видове карти</Link>
                                                    </li>
                                                }
                                                {user.permission('cards_articlegroups') &&
                                                    <li>
                                                        <Link className="nav-link" to="/groups/articles">Групи артикули</Link>
                                                    </li>
                                                }
                                                {user.permission('cards_instances') &&
                                                    <li>
                                                        <Link className="nav-link" to="/cards">Издадени карти</Link>
                                                    </li>
                                                }
                                            </ul>
                                        </li>
                                    } */}
                                    {user.hasAny('amounttypes_edit') &&
                                        <li>
                                            <Link className="nav-link" to="/units">Мерни единици</Link>
                                        </li>
                                    }
                                    {user.hasAny('articles_details') &&
                                        <li>
                                            <Link className="nav-link" to="/features">Характеристики</Link>
                                        </li>
                                    }
                                </ul>
                            </li>
                        }
                        {/* <li>
                            <span className="nav-link"><People className="icon" />Клиенти<CaretRightFill className="caret" /></span>
                            <ul>
                                {user.permission('clients_index') &&
                                    <li>
                                        <Link className="nav-link" to="/clients">Списък</Link>
                                    </li>
                                }
                                {user.permission('clients_groupsindex') &&
                                    <li>
                                        <Link className="nav-link" to="/clients/groups">Групи клиенти</Link>
                                    </li>
                                }
                                <li>
                                    <Link className="nav-link" to="/clients/sources">Източник клиенти</Link>
                                </li>
                            </ul>
                        </li> */}

                        {/* {user.hasAny('reasons_revision') &&
                            <li>
                                <span className="nav-link"><People className="icon" />Причини<CaretRightFill className="caret" /></span>
                                <ul>
                                    {user.permission('reasons_revision') &&
                                        <li>
                                            <Link className="nav-link" to="/reasons/revisions">Причини ревизии</Link>
                                        </li>
                                    }
                                </ul>
                            </li>
                        } */}

                        {/* {user.isSuperAdmin() &&
                            <li>
                                <Link className="nav-link" to="/email_templates"><MailTemplates className="icon" />Шаблони за email</Link>
                            </li>
                        } */}

                        {user.hasAny('users_index, roles_index, rights_index, rights_by_barsys') &&
                            <li>
                                <span className="nav-link"><Users className="icon" />Потребители<CaretRightFill className="caret" /></span>
                                <ul>
                                    {user.permission('users_index') &&
                                        <li>
                                            <Link className="nav-link" to="/admins">Списък</Link>
                                        </li>
                                    }
                                    {user.permission('roles_index') &&
                                        <li>
                                            <Link className="nav-link" to="/roles">Роли</Link>
                                        </li>
                                    }
                                    {/* {user.permission('rights_index') &&
                                        <li>
                                            <Link className="nav-link" to="/roles/permissions">Права по роли</Link>
                                        </li>
                                    }
                                    {user.permission('rights_by_barsys') &&
                                        <li>
                                            <Link className="nav-link" to="/stores/permissions">Права за търговски обекти</Link>
                                        </li>
                                    } */}
                                </ul>
                            </li>
                        }
                        {/* {user.hasAny('misc_get_lable_templates, clientorders_statuses') &&
                            <li>
                                <span className="nav-link"><Archive className="icon" />Други<CaretRightFill className="caret" /></span>
                                <ul>
                                    {user.permission('misc_get_lable_templates') &&
                                        <li>
                                            <Link className="nav-link" to="/labels">Редактор етикети</Link>
                                        </li>
                                    }
                                    {user.permission('clientorders_statuses') &&
                                        <li>
                                            <Link className="nav-link" to="/orders-statuses">Статуси на клиентски заявки</Link>
                                        </li>
                                    }
                                    {user.permission('clientorders_statuses') &&
                                        <li>
                                            <Link className="nav-link" to="/returns-statuses">Статуси на заявки за връщане</Link>
                                        </li>
                                    }
                                </ul>
                            </li>
                        } */}
                    </ul>
                </li>
            </>
        )
    }

    return (
        <nav className="navbar">
            <div className="container-fluid">
                <div className="left">
                    <Link className="navbar-brand" to="/">
                        <img src={logo} alt="" height="40" />
                    </Link>

                    <Dropdown className="store-context" onToggle={toggleStores} show={state.showStores}>
                        <a className="link" onClick={toggleStores}>
                            {user?.getData()?.store?.translation?.name || user?.getData()?.store?.name} <CaretDownFill />
                        </a>

                        <Dropdown.Menu align="start">
                            {state.stores.map(store =>
                                <Dropdown.Item key={store.id} onClick={e => setActiveStore(store.id)}>{store?.translation?.name || store.name}</Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>

                <ul className="nav gt-1280 main">
                    {getNavItems()}
                </ul>

                <div className="mobile-nav-btn lw-1280">
                    <MobileNavBar>
                        {getNavItems()}
                    </MobileNavBar>
                </div>

                <div className="right gt-1280">
                    <Search />

                    <Link
                        to={'/'}
                        className="user"
                    >
                        <img src={user.getAvatar() || userIcon} alt="" />
                    </Link>
                    <span className="logout">
                        <Link to="/logout">
                            <img src={logoutIcon} alt="" />
                        </Link>
                    </span>
                </div>
            </div>
        </nav>
    )
}

export default Index;
