import { useState, useEffect, useRef } from 'react'
import { Button, Table } from 'react-bootstrap';
import Api from 'helpers/Api';
import Loader from 'components/misc/Loader';
import moment from 'moment';
import NoDataFound from 'components/misc/NoDataFound';
import Pagination from 'components/misc/Pagination';
import TableHeader from 'components/misc/TableHeader';
import TableCol from 'components/misc/TableCol';
import Options from 'components/partials/notes/Options';
import MenuOptions from 'components/misc/MenuOptions';
import PriceValue from 'components/partials/PriceValue';

function Index(props) {

    const defaultData = [];

    const tableRef = useRef(null);

    const [state, setState] = useState({
        data: defaultData,
        pages: 0,
        total: 0,
        loading: false,
        filter: {
            page: 1,
        },
        refresh: false,
    });

    useEffect(() => {

        loading(true);

        Api.get('orders/rows-by-supplier', {
            params: {
                id: props.id,
                ...state.filter,
            }
        }).then(res => {
            setState(prev => ({
                ...prev,
                order: res.data.order,
                data: res.data.items,
                total: res.data.total,
                pages: res.data.pages,
                filter: {
                    ...prev.filter,
                    ...res.data.filter,
                },
            }));

            if (res.data.pages > 0 && state.filter.page > res.data.pages) {
                return handlePage({ selected: res.data.pages - 1 });
            }

            loading(false);
            sorting(false);
        });
    }, [props.id, state.refresh]);

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }));
    }

    const sorting = (sorting) => {
        setState(prev => ({
            ...prev,
            sorting: Boolean(sorting)
        }));
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            refresh: new Date()
        }));
    }

    const handleSort = (sort, order) => {
        setState(prev => ({
            ...prev,
            sorting: true,
            filter: {
                ...prev.filter,
                sort: sort,
                order: order,
                page: 1,
            },
            refresh: new Date().getTime()
        }));
    }

    const handleAdd = () => {
        props.addNote();
    }

    const handleEdit = id => {
        props.editNote(id);
    }

    const handleDelete = id => {
        props.deleteNote(id);
    }

    const showArticle = id => {
        props.showArticle(id);
    }

    return (

        state.loading && !state.sorting
            ?
            <Loader />
            :
            state.data.length === 0 ?
                <NoDataFound />
                :
                <>

                    <Table className="mt-3" size="sm" striped ref={tableRef}>
                        <TableHeader
                            tableRef={tableRef}
                            activeSortKey={state.filter.sort}
                            activeSortDir={state.filter.order}
                            onSort={(col, dir) => handleSort(col, dir)}
                        >
                            <TableCol sortable sortKey="id" style={{ width: '5%' }}>ID</TableCol>
                            <TableCol sortable sortKey="supplier">Доставчик</TableCol>
                            <TableCol sortable sortKey="article_name">Артикул</TableCol>
                            <TableCol sortable sortKey="amount" className="text-right">Количество</TableCol>
                            <TableCol sortable sortKey="current_price" className="text-right">Единична цена</TableCol>
                            <TableCol sortable sortKey="total_sum" className="text-right">Сума</TableCol>
                        </TableHeader>
                        <tbody>
                            {state.data.map((r, i) =>
                                <tr key={i}>
                                    <td>
                                        {r.id}
                                    </td>
                                    <td>
                                        {r?.article?.supplier?.name}
                                    </td>
                                    <td>
                                        <span className="link" onClick={e => showArticle(r.article?.id)}>
                                            {r?.article?.article_name}
                                        </span>
                                    </td>
                                    <td className="text-right">
                                        {r.amount} {r?.article?.amount_type_short}
                                    </td>
                                    <td className="text-right">
                                        {state.order?.currency?.prefix} <PriceValue>{r.current_price}</PriceValue> {state.order?.currency?.sufix}
                                    </td>
                                    <td className="text-right">
                                        {state.order?.currency?.prefix} <PriceValue>{r.total_sum}</PriceValue> {state.order?.currency?.sufix}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>

                    <Pagination
                        page={state.filter.page}
                        pages={state.pages}
                        handlePage={handlePage}
                    />
                </>
    )
}

export default Index;