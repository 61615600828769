import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import { Modal, Button, Row, Col, FloatingLabel, Form } from 'react-bootstrap';

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//providers
import { useCurrencyContext } from 'providers/Currency';
import { useAppContext } from 'providers/App';
import { useAuthDataContext } from 'providers/Auth';

//misc
import SaveButton from 'components/misc/Button'
import Info from 'components/modals/Info';

function AddOrEdit(props, ref) {

    const app = useAppContext();
    const auth = useAuthDataContext();
    const currencies = useCurrencyContext();

    const defaultData = {
        langs: {}
    }

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        edit: false,
        data: defaultData,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null,
        onClose: null,
    });

    const [validations, setValidations] = useValidation();

    const validationModalRef = useRef(null);

    useImperativeHandle(ref, () => ({
        add: () => {
            handleShow();
        },
        edit: (id) => {
            edit(id);
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        }
    }));

    useEffect(() => {
        if (!state.show) {
            return;
        }

        if (auth.getUser().permission('suppliers_edit') === false) {
            app.showError('Нямате право да редактирате доставчик!');

            hide(state.onClose);
        }

    }, [state.show]);

    useEffect(() => {
        loadData();
    }, [state.edit]);

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const handleShow = () => {
        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
        }));

        show();
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
        }));

        setValidations(null);

        if (typeof state.onClose === 'function') {
            hide(state.onClose);
        } else {
            hide();
        }

    }

    const edit = id => {
        setState(prev => ({
            ...prev,
            id: id,
            edit: new Date().getTime()
        }));

        show();
    }

    const loadData = () => {
        if (!state.id) {
            return;
        }

        Api.get('suppliers/edit?id=' + state.id)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data
                }))
            });
    }


    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = state.id ? 'suppliers/update' : 'suppliers/add';

        let data = new FormData(e.target);

        if (state.id) {
            data.append('id', state.id)
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (typeof state.onSuccess === 'function') {
                state.onSuccess(res.data)
            }

            hide()
        }).catch(error => {
            const _err = error.response;
            if (_err && _err.status && _err.status === 422) {
                hide(() => {
                    let modal = validationModalRef.current;

                    if (modal) {
                        modal.open();

                        modal.onClose(() => {
                            setValidations(_err.data.errors)
                            show();
                        });
                    }
                });
            }
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }));
        });
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleDateChange = e => {
        const name = e.target.name + '_ymd'
        const value = e.target.value

        setState(name, value, 'data')
    }

    return (
        <>
            <Info
                ref={validationModalRef}
                mainMessage="Моля, попълнете всички задължителни полета, означени с червен цвят!"
            />


            <Modal size="lg" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <form onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Доставчик
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <FloatingLabel label="Име*" className="mb-3">
                            <Form.Control
                                type="text"
                                name={`name`}
                                value={state.data.name || ''}
                                placeholder="Име"
                                onChange={(e) => handleInputChange(e)}
                                isInvalid={Boolean(validations?.name)}
                            />
                            <Form.Control.Feedback type="invalid">
                                {validations?.name}
                            </Form.Control.Feedback>
                        </FloatingLabel>
                        <Row>
                            <Col>
                                <FloatingLabel label="ЕИК" className="mb-3">
                                    <Form.Control
                                        type="text"
                                        name={`bulstat`}
                                        value={state.data.bulstat || ''}
                                        placeholder="ЕИК"
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                </FloatingLabel>
                            </Col>
                            <Col>
                                <FloatingLabel label="ДДС №" className="mb-3">
                                    <Form.Control
                                        type="text"
                                        name={`vat_num`}
                                        value={state.data.vat_num || ''}
                                        placeholder="ДДС №"
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                </FloatingLabel>
                            </Col>
                            <Col>
                                <FloatingLabel label="МОЛ" className="mb-3">
                                    <Form.Control
                                        type="text"
                                        name={`mol`}
                                        value={state.data.mol || ''}
                                        placeholder="МОЛ"
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                </FloatingLabel>
                            </Col>
                        </Row>

                        <FloatingLabel label="Телефони" className="mb-3">
                            <Form.Control
                                type="text"
                                name={`tel`}
                                value={state.data.tel || ''}
                                placeholder="Телефони"
                                onChange={(e) => handleInputChange(e)}
                            />
                        </FloatingLabel>

                        <Row>
                            <Col>
                                <FloatingLabel label="Адрес" className="mb-3">
                                    <Form.Control
                                        type="text"
                                        name={`address`}
                                        value={state.data.address || ''}
                                        placeholder="Адрес"
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                </FloatingLabel>
                            </Col>
                            <Col>
                                <FloatingLabel label="Лица за контакти" className="mb-3">
                                    <Form.Control
                                        type="text"
                                        name={`contact_persons`}
                                        value={state.data.contact_persons || ''}
                                        placeholder="Лица за контакти"
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                </FloatingLabel>
                            </Col>
                        </Row>

                        <FloatingLabel label="Бележки" className="mb-3">
                            <Form.Control
                                as="textarea"
                                style={{ height: '100px' }}
                                name={`note`}
                                value={state.data.note || ''}
                                placeholder="Бележки"
                                onChange={(e) => handleInputChange(e)}
                            />
                        </FloatingLabel>
                        <Row>
                            <Col>
                                <FloatingLabel label="Email" className="mb-3">
                                    <Form.Control
                                        type="text"
                                        name={`email`}
                                        value={state.data.email || ''}
                                        placeholder="Email"
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                </FloatingLabel>
                            </Col>
                            {/* <Col>
                            <FloatingLabel label="Export ID" className="mb-3">
                                <Form.Control
                                    type="text"
                                    name={`export_id`}
                                    value={state.data.export_id || ''}
                                    placeholder="Export ID"
                                    onChange={(e) => handleInputChange(e)}
                                />
                            </FloatingLabel>
                        </Col> */}
                        </Row>

                        <Row>
                            <Col>
                                <FloatingLabel label="Регистрация по ДДС">
                                    <Form.Select
                                        name="has_tax"
                                        value={state.data.has_tax || 0}
                                        onChange={handleInputChange}
                                    >
                                        <option key={0} value={0}>НЕ</option>
                                        <option key={1} value={1}>ДА</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>

                            <Col>
                                <FloatingLabel label="Цени по подразбиране">
                                    <Form.Select
                                        name="default_price_mode"
                                        value={state.data.default_price_mode || ''}
                                        onChange={handleInputChange}
                                    >
                                        <option key={0} value={0}>без ДДС</option>
                                        <option key={1} value={1}>с ДДС</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>

                            <Col>
                                <FloatingLabel label="Валута по подразбиране">
                                    <Form.Select
                                        name="default_currency_id"
                                        value={state.data.default_currency_id || ''}
                                        onChange={handleInputChange}
                                        isInvalid={Boolean(validations?.default_currency_id)}
                                    >
                                        <option value=""></option>
                                        {currencies.data.map(c =>
                                            <option key={c.id} value={c.id}>{c.name}</option>
                                        )}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {validations?.default_currency_id}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            className="save"
                        />
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default forwardRef(AddOrEdit);
