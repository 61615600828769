import { useEffect } from 'react';
import loader from '../../../assets/img/loader.svg';
import { useAuthDataContext } from '../../../providers/Auth';
import PageLoader from '../../misc/PageLoader';

function Index() {

    const auth = useAuthDataContext();

    useEffect(() => {
        auth.logout(false);
    }, []);

    return (
        <PageLoader />
    )
}

export default Index;