import { createContext, useContext, useMemo, useState } from 'react';
import Api from 'helpers/Api';

export const CurrencyContext = createContext({});

const CurrencyProvider = props => {

    const [currencies, setCurrencies] = useState([]);

    const loadData = async () => {
        return await Api.get('currencies')
            .then(res => {
                setCurrencies(res.data);
            });
    }

    const getDefault = () => {
        return currencies.find(c => Number(c.default) === 1);
    }

    const data = useMemo(() => (currencies), [currencies]);

    const value = {
        data,
        loadData,
        getDefault
    }

    return <CurrencyContext.Provider value={value} {...props} />;
}

export const useCurrencyContext = () => useContext(CurrencyContext);

export default CurrencyProvider;