import { forwardRef, useImperativeHandle, useRef } from 'react'

//plugins
import { Modal, Button, Row, Col, FloatingLabel, Form, Alert, Card } from 'react-bootstrap';

//helpers
import { useNestedState } from 'helpers/NestedState'
import { useValidation } from 'helpers/Validation'


//misc
import SaveButton from 'components/misc/Button'
import HelpInfo from 'components/misc/Info'

function Index(props, ref) {

    const defaultData = {
        test_env: 0,
        public_key: {},
        private_key: {},
        private_key_filename: null,
        public_key_filename: null,
    }

    const inputFileRef = useRef(null);
    const inputPrivateFileRef = useRef(null);

    const [validations, setValidations] = useValidation();

    const [state, setState] = useNestedState({
        show: false,
        data: defaultData,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null,
        onClose: null
    });

    useImperativeHandle(ref, () => ({
        show: (settings) => {
            handleShow(settings);
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        }
    }));


    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const handleShow = (settings) => {
        setState(prev => ({
            ...prev,
            data: settings,
            id: null,
        }));

        show();
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
        }));

        if (typeof state.onClose === 'function') {
            state.onClose()
        }

        setValidations(null);

        hide()
    }

    const handleSave = e => {
        e.preventDefault();

        if (state.data.password || state.data.password_confirmation) {
            if (state.data.password !== state.data.password_confirmation) {
                let error = {
                    password: [
                        'Паролите не съвпадат'
                    ]
                }

                setValidations(error)
                return;
            }
        }

        if (typeof state.onSuccess === 'function') {
            state.onSuccess(state.data)
        }

        setValidations(null);

        hide()
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleCheckboxChange = e => {
        const name = e.target.name;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: prev.data[name] ? 0 : 1
            }
        }));
    }

    const handleShowAttach = e => {
        e.preventDefault();

        inputFileRef.current.click();
    }

    const handleAttach = e => {
        let files = [...e.target.files];

        files.forEach((f, i) => {
            let url = URL.createObjectURL(f);

            files[i].id = Math.random().toString(7).substring(2);
            files[i].was_recently_attached = true;
            files[i].url = url;
        });

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                public_key: files[0],
                public_key_filename: files[0].name
            }
        }));
    }

    const handleShowAttachPrivate = e => {
        e.preventDefault();

        inputPrivateFileRef.current.click();
    }

    const handleAttachPrivate = e => {
        let files = [...e.target.files];

        files.forEach((f, i) => {
            let url = URL.createObjectURL(f);

            files[i].id = Math.random().toString(7).substring(2);
            files[i].was_recently_attached = true;
            files[i].url = url;
        });

        if (!files[0]) return;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                private_key: files[0],
                private_key_filename: files[0].name
            }
        }));
    }

    return (
        <>
            <Modal size="lg" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <form onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Настройки
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="mb-3">
                            <Col>
                                <FloatingLabel label="Име">
                                    <Form.Control
                                        type="text"
                                        placeholder="Име"
                                        disabled
                                        value={'Борика виртуален ПОС'}
                                    />
                                </FloatingLabel>
                            </Col>
                            <Col>
                                <FloatingLabel label="Търговец">
                                    <Form.Control
                                        type="text"
                                        placeholder="Търговец"
                                        name="merchant_name"
                                        value={state.data.merchant_name || ''}
                                        onChange={handleInputChange}
                                    />
                                    <HelpInfo marginLeft="10px" placement="top">Име на фирмата на търговеца.</HelpInfo>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Col className="mb-3" style={{ display: 'flex' }}>
                            <Form.Check
                                type="switch"
                                name="test_env"
                                label="Тестова среда"
                                value="1"
                                checked={Number(state.data?.test_env) === 1}
                                onChange={handleCheckboxChange}
                            />
                        </Col>
                        <Row className="mb-3">
                            <Col>
                                <FloatingLabel label="Merchant ID">
                                    <Form.Control
                                        type="text"
                                        placeholder="Merchant ID"
                                        name="merchant_id"
                                        value={state.data.merchant_id || ''}
                                        onChange={handleInputChange}
                                    />

                                </FloatingLabel>
                            </Col>
                            <Col>
                                <FloatingLabel label="Terminal ID">
                                    <Form.Control
                                        type="text"
                                        placeholder="Terminal ID"
                                        name="terminal_id"
                                        value={state.data.terminal_id || ''}
                                        onChange={handleInputChange}
                                    />

                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Card className='mb-3'>
                            <Card.Header>
                                Частен ключ (сертификат)
                            </Card.Header>
                            <Card.Body>
                                {state.data.private_key_filename ?
                                    <p>{state.data.private_key_filename || ''}</p>
                                    : ''
                                }

                                <Button variant="outline-dark" size="sm" onClick={handleShowAttachPrivate}>
                                    Прикачете файл
                                </Button>
                                <input ref={inputPrivateFileRef} type="file" onChange={handleAttachPrivate} hidden />
                            </Card.Body>
                        </Card>

                        <Row className="mb-3">
                            <Col>
                                <FloatingLabel label="Парола (частен ключ)">
                                    <Form.Control
                                        type="password"
                                        placeholder="Парола (частен ключ)"
                                        name="password"
                                        value={state.data.password || ''}
                                        onChange={handleInputChange}
                                        isInvalid={Boolean(validations && validations.password)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {validations && validations.password && (validations.password[0] || validations.password)}
                                    </Form.Control.Feedback>
                                    <HelpInfo marginLeft="10px" placement="top">Парола на частният ключ.</HelpInfo>
                                </FloatingLabel>
                            </Col>
                            <Col>
                                <FloatingLabel label="Повтаряне на парола">
                                    <Form.Control
                                        type="password"
                                        placeholder="Повтаряне на парола"
                                        name="password_confirmation"
                                        value={state.data.password_confirmation || ''}
                                        onChange={handleInputChange}
                                        isInvalid={Boolean(validations && validations.password)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {validations && validations.password && (validations.password[0] || validations.password)}
                                    </Form.Control.Feedback>
                                    <HelpInfo marginLeft="10px" placement="top">Парола на частният ключ.</HelpInfo>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Card>
                            <Card.Header>
                                Публичен ключ
                            </Card.Header>
                            <Card.Body>
                                {state.data.public_key_filename ?
                                    <p>{state.data.public_key_filename || ''}</p>
                                    : ''
                                }

                                <Button variant="outline-dark" size="sm" onClick={handleShowAttach}>
                                    Прикачете файл
                                </Button>
                                <input ref={inputFileRef} type="file" onChange={handleAttach} hidden />
                            </Card.Body>
                        </Card>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            className="save"
                        />
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default forwardRef(Index);
