import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import moment from "moment"
import { FloatingLabel, Form, Dropdown, Row, Col } from 'react-bootstrap'
import { CaretLeft, CaretDown, CaretRight, CaretDownFill } from 'react-bootstrap-icons'

//providers
// import { useAuthDataContext } from "providers/Auth"

//helpers
import { useQuery } from 'helpers/Url'
import Api from 'helpers/Api'
import Loader from 'components/misc/Loader'
import NoDataFound from 'components/misc/NoDataFound'
import Autocomplete from 'components/misc/Autocomplete'
import HelpInfo from 'components/misc/Info';
import DatePicker from 'components/partials/filter/DatePicker'

function Filter(props) {
    const history = useNavigate()
    const query = useQuery()

    // const auth = useAuthDataContext();

    const [filters, setFilters] = useState({
        show: false,
    });

    const handleDateChange = (date, name) => {
        props.handleSearch(name, moment(date).format('YYYY-MM-DD'));
    }

    const handleSearch = e => {
        const name = e.target.name
        const value = e.target.value

        props.handleSearch(name, value)
    }

    const handleFromDateChange = e => {
        const name = e.target.name
        const value = e.target.value

        let date = value ? moment(value).format('YYYY-MM-DD') : '';

        props.handleSearch({
            valid_from: date,
            valid_to: date,
        });
    }

    const handleToDateChange = e => {
        const value = e.target.value

        let date = value ? moment(value).format('YYYY-MM-DD') : '';
        if (value < props.filter.valid_from) {
            return;
        }

        props.handleSearch({
            valid_to: date,
        });
    }

    const getMoreFiltersValue = () => {
        let data = [];

        if (props.filter.valid_from) {
            data.push(1);
        }

        if (props.filter.valid_to) {
            data.push(1);
        }

        if (props.filter.credit_from) {
            data.push(1);
        }

        if (props.filter.credit_to) {
            data.push(1);
        }

        if (props.filter.uses_from) {
            data.push(1);
        }

        if (props.filter.uses_to) {
            data.push(1);
        }

        if (data.length === 0) {
            return 'Няма избрани';
        }

        return data.length + ' избрани';
    }

    const toggleFilters = () => {
        setFilters(prev => ({
            ...prev,
            show: !prev.show,
        }))
    }

    const handleClientChange = (data) => {
        props.handleSearch({
            client_id: data.id,
        });
    }

    const toggleCard = data => {
        let selected = [];

        data.map(card => {
            selected = selected.concat(Number(card.id));
        });

        props.handleSearch('card_id', selected)
    }

    const handleCheckedTypes = (data) => {
        let toggled = [];
        props.filter.card_id.map(t => {
            data.map(d => {
                if (t == d.id) {
                    toggled = toggled.concat(d);
                }
            })
        })
        return toggled
    }

    return (
        <div className="row page-filter">
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <FloatingLabel label="Търсене">
                    <Form.Control
                        type="text"
                        placeholder="Търсене"
                        name="search"
                        value={props.filter.search || ''}
                        onChange={handleSearch}
                    />
                </FloatingLabel>
            </div>
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <FloatingLabel label="Номер на карта">
                    <Form.Control
                        type="text"
                        placeholder="Номер на карта"
                        name="num"
                        value={props.filter.num || ''}
                        onChange={handleSearch}
                    />
                </FloatingLabel>
            </div>
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <Col>
                    <Autocomplete
                        url="autocomplete/clients"
                        inputPlaceholder="Клиент"
                        inputIdName={`client_id`}
                        renderText={data => data?.name}
                        inputIdValue={props.filter.client_id}
                        onChange={data => handleClientChange(data)}
                    />
                </Col>
            </div>
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <Col>
                    <Autocomplete
                        url="autocomplete/cards-types"
                        inputPlaceholder="Тип карта"
                        inputIdName={`card_id[]`}
                        selectedIds={props.filter.card_id || []}
                        renderText={data => data?.name}
                        onChange={data => toggleCard(data)}
                        multiple
                    />
                </Col>
            </div>
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <Dropdown drop="down" onToggle={toggleFilters} show={filters.show}>
                    <FloatingLabel label="Още филтри">
                        <Form.Control
                            type="text"
                            placeholder="Още филтри"
                            value={getMoreFiltersValue()}
                            onClick={toggleFilters}
                            readOnly
                            style={{
                                cursor: 'pointer'
                            }}
                        />
                    </FloatingLabel>
                    <Dropdown.Menu align="start" style={{ padding: '15px', width: '100%' }}>
                        <Row>
                            <Col className="mb-3">
                                <DatePicker
                                    placeholder="Валидна от"
                                    name="valid_from"
                                    value={props.filter.valid_from || ''}
                                    onChange={handleFromDateChange}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-3">
                                <DatePicker
                                    placeholder="Валидна до"
                                    name="valid_to"
                                    value={props.filter.valid_to || ''}
                                    onChange={handleToDateChange}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <FloatingLabel label="Ост. използвания от">
                                    <Form.Control
                                        type="text"
                                        placeholder="Ост. използвания от"
                                        name="uses_from"
                                        value={props.filter.uses_from || ''}
                                        onChange={handleSearch}
                                    />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <FloatingLabel label="Ост. използвания до">
                                    <Form.Control
                                        type="text"
                                        placeholder="Ост. използвания до"
                                        name="uses_to"
                                        value={props.filter.uses_to || ''}
                                        onChange={handleSearch}
                                    />
                                    <HelpInfo placement="top">празно - без ограничение</HelpInfo>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <FloatingLabel label="Ост. кредит от">
                                    <Form.Control
                                        type="text"
                                        placeholder="Ост. кредит от"
                                        name="credit_from"
                                        value={props.filter.credit_from || ''}
                                        onChange={handleSearch}
                                    />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FloatingLabel label="Ост. кредит до">
                                    <Form.Control
                                        type="text"
                                        placeholder="Ост. кредит до"
                                        name="credit_to"
                                        value={props.filter.credit_to || ''}
                                        onChange={handleSearch}
                                    />
                                    <HelpInfo placement="top">празно - без ограничение</HelpInfo>
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    )
}

export default Filter;
