import { useEffect, useRef, useState } from 'react';
import Api from 'helpers/Api';
import { useQuery } from 'helpers/Url';
import { useLocation, useNavigate, Link, useParams } from 'react-router-dom';
import { Alert, Button, Table, OverlayTrigger, Tooltip, FloatingLabel, Form, Row, Col, Container, InputGroup } from 'react-bootstrap';
import { ArrowLeftCircle, FileText, Plus, PlusCircle, Rainbow, TrashFill, Upload } from 'react-bootstrap-icons';
import { useNestedState } from 'helpers/NestedState';
import { useCurrencyContext } from 'providers/Currency';
import { useValidation } from 'helpers/Validation';
import { useAuthDataContext } from 'providers/Auth';
import { cloneDeep } from 'lodash';

import Question from 'components/modals/Question';
import Layout from 'components/layout/Layout';
import Supplier from 'components/pages/suppliers/partials/AddOrEdit';
import Article from 'components/pages/articles/partials/AddOrEdit';

//misc
import Loader from 'components/misc/Loader';
import moment from 'moment';
import Autocomplete from 'components/misc/Autocomplete';
import SaveButton from 'components/misc/Button';
import { addExtraPrice, calcExtraPrice, isNumeric, negativeValue, positiveValue, removeExtraPrice, vatPrice } from 'helpers/Price';
import Info from 'components/misc/Info';
import Error from 'components/modals/Error';
import DatePicker from 'components/partials/filter/DatePicker';
import Import from 'components/modals/Import';
import { TYPES } from 'constants/imports';
import Intrastat from './partials/Intrastat';
import Expenses from './partials/Expenses';
import PriceValue from 'components/partials/PriceValue';
import Refs from 'Refs';

let timeout;

function Index() {

    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const query = useQuery();

    const auth = useAuthDataContext();
    const currencies = useCurrencyContext();

    const [state, setState] = useNestedState({
        loading: false,
        loadingBase: false,
        loadingExit: false,
        loadingClose: false,
        dataLoading: false,
        data: {
            has_tax: 1,
            with_tax: 0,
            currency_id: currencies.getDefault()?.id || '',
            currency_rate: currencies.getDefault()?.rate || '',
            currency: currencies.getDefault(),
            rows: [{}],
            allow_close: true,
        },
    });

    const [operations, setOperations] = useState([]);
    const [depots, setDepots] = useState([]);
    const [documentTypes, setDocumentTypes] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [taxGroups, setTaxGroups] = useState([]);

    const [validations, setValidations] = useValidation();

    const formRef = useRef(null);
    const supplierModalRef = useRef(null);
    const articleModalRef = useRef(null);
    const saveAndCloseModalRef = useRef(null);
    const errorModalRef = useRef(null);
    const importModalRef = useRef(null);
    const intrastatModalRef = useRef(null);
    const expensesModalRef = useRef(null);

    useEffect(() => {
        Api.get('storeloads/operations')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setOperations(res.data);
                }
            });

        Api.get('storeloads/documents-types')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setDocumentTypes(res.data);
                }
            });

        Api.get('storeloads/payment-methods')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setPaymentMethods(res.data);
                }
            });

        Api.get('storeloads/tax-groups')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setTaxGroups(res.data);
                }
            });
    }, []);

    useEffect(() => {
        Api.get('storeloads/depots')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setDepots(res.data);
                }
            });
    }, [auth.getUser()?.getStoreId()]);

    useEffect(() => {
        if (params.id) {
            loadData();
        }
    }, [params.id]);

    // зареждане на данни от поръчка/сметка
    useEffect(() => {
        if (params.id) {
            return;
        }

        // от зареждане
        if (query.get('from_load_id') && query.get('return')) {
            loadDataFromStoreLoad();
        }

        // от документ
        if (query.get('from_document_id')) {
            loadDataFromDocument();
        }
    }, [location]);

    useEffect(() => {
        if (state.data.rows.length === 0) {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    rows: [{}]
                }
            }));
        }
    }, [state.data.rows]);

    const loadData = () => {

        loading(true);

        let url = 'storeloads/show';

        Api.get(url, {
            params: {
                id: params.id
            }
        }).then(res => {
            handleResponse(res);
        }).catch(err => {
            handleError(err);
        }).finally(() => {
            loading(false);
        })
    }

    const loadDataFromStoreLoad = () => {

        loading(true);

        let url = 'storeloads/from-storeload';

        Api.get(url, {
            params: {
                load_id: query.get('from_load_id'),
                return: query.get('return'),
            }
        }).then(res => {
            handleResponse(res);
        }).catch(err => {
            handleError(err);
        }).finally(() => {
            loading(false);
        })
    }

    const loadDataFromDocument = () => {

        loading(true);

        let url = 'storeloads/from-document';

        Api.get(url, {
            params: {
                document_id: query.get('from_document_id')
            }
        }).then(res => {
            handleResponse(res);
        }).catch(err => {
            handleError(err);
        }).finally(() => {
            loading(false);
        })
    }

    const handleResponse = res => {
        let data = res.data;

        let rows = data.rows;

        rows = rows.map(row => {
            row.current_price_gross = row.current_price_with_vat;
            row.latest_current_price = row?.article?.current_delivery_price;
            row.latest_current_price_gross = calcGrossPrice(row?.article?.current_delivery_price, row.delivery_tax_id);

            let currentPrice = parseFloat(row.current_price_gross || 0);

            if (Number(data.with_tax) === 1 || Number(data.has_tax) === 0) {
                currentPrice = parseFloat(row.current_price || 0);
            }

            row.profit_perc = calcExtraPrice(currentPrice, row.actual_price);

            return row;
        });

        data.rows = rows;

        setState(prev => ({
            ...prev,
            data: data,
        }));
    }

    const handleError = error => {
        console.log(error);

        const _err = error.response;

        if (_err && _err.status) {
            if (_err.status === 422) {
                setValidations(_err.data.errors);
            } else {
                let modal = errorModalRef.current;

                if (modal) {
                    modal.open(_err.data.error || _err.data.message);

                    modal.onClose(() => {
                        navigate(-1, {
                            replace: true
                        });
                    });
                }
            }
        }
    }

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            dataLoading: Boolean(loading)
        }));
    }

    const isLoad = () => {
        return Number(state.data.operation_id) === 1;
    }

    const isReturn = () => {
        return Number(state.data.operation_id) === 2;
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleCheckboxChange = e => {
        const name = e.target.name;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: prev.data[name] ? false : true
            }
        }));
    }

    const handleCurrencyChange = e => {
        const name = e.target.name
        const value = e.target.value

        setCurrency(value);
    }

    const setCurrency = id => {
        let currency = currencies.data.find(c => Number(c.id) === Number(id));

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                currency_id: id,
                currency: currency,
                currency_rate: currency ? currency.rate : prev.data.currency_rate,
            }
        }));
    }

    const save = (close = false, exit = false) => {
        if (state.loading) {
            return;
        }

        setValidations(null);

        let url = params.id ? 'storeloads/edit' : 'storeloads/add';

        let form = formRef.current;

        let data = new FormData(form);

        if (params.id) {
            data.append('id', params.id)
        }

        if (close) {
            data.append('close', 1);
        }

        if (query.get('from_load_id')) {
            data.append('from_load_id', query.get('from_load_id'));
            data.append('return', query.get('return'));
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (exit) {
                navigate('/storage/loads');
            } else {
                navigate('/storage/loads/' + res.data.id + '/edit', {
                    replace: true
                });
            }
        }).catch(error => {
            const _err = error.response;

            if (_err && _err.status) {
                if (_err.status === 422) {
                    setValidations(_err.data.errors);
                } else {
                    let modal = errorModalRef.current;

                    if (modal) {
                        modal.open(_err.data.error || _err.data.message);
                    }
                }
            }

        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
                loadingBase: false,
                loadingExit: false,
                loadingClose: false,
            }));
        });
    }

    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
            loadingBase: true,
            loadingExit: false,
            loadingClose: false,
        }));

        save(false, false);
    }

    const handleSaveAndExit = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
            loadingBase: false,
            loadingExit: true,
            loadingClose: false,
        }));

        save(false, true);
    }

    const handleSaveAndClose = e => {
        e.preventDefault();

        let modal = saveAndCloseModalRef.current;

        if (modal) {
            modal.open();

            modal.onSuccess(() => {
                setState(prev => ({
                    ...prev,
                    loading: true,
                    loadingBase: false,
                    loadingExit: false,
                    loadingClose: true,
                }));

                save(true, false);
            });
        }
    }

    const handleChangeSupplier = data => {

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                supplier: data,
                supplier_id: data.id,
                with_tax: Number(data.default_price_mode) === 1 ? 1 : 0
            }
        }));

        if (data.default_currency_id) {
            setCurrency(data.default_currency_id);
        }
    }

    const handleAddSupplier = () => {
        let modal = supplierModalRef.current;

        if (modal) {
            modal.add();
            modal.onSuccess(supplier => {
                if (supplier) {
                    handleChangeSupplier(supplier);
                }
            });
        }
    }

    const handleAddRow = e => {
        e.preventDefault()

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: prev.data.rows.concat({})
            }
        }))
    }

    const handleRemoveRow = (e, index) => {
        e.preventDefault()

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: prev.data.rows.filter((row, i) => i !== index)
            }
        }))
    }

    const handleChangeArticle = (data, index) => {

        let row = state.data.rows[index];

        let actualPrice = data.actual_price || row.actual_price || 0;
        let currentPrice = row.current_price || data.current_price || 0;

        // let currentGrossPrice = calcGrossPrice(currentPrice, data.tax_id);

        // if (Number(state.data.with_tax) === 1 || Number(state.data.has_tax) === 0) {
        //     return parseFloat(row.current_price_gross || 0);
        // }

        // let profitPerc = calcExtraPrice(currentGrossPrice, actualPrice);

        let latestCurrentPrice = data.current_price;
        let latestCurrentGrossPrice = calcGrossPrice(data.current_price, data.tax_id);

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: Object.values({
                    ...prev.data.rows,
                    [index]: {
                        ...prev.data.rows[index],
                        article: data,
                        article_id: data.id,
                        delivery_tax_id: data.tax_id || prev.data.rows[index].delivery_tax_id,
                        latest_current_price: latestCurrentPrice,
                        latest_current_price_gross: latestCurrentGrossPrice,
                        // current_price: currentPrice,
                        // current_price_gross: currentGrossPrice,
                        actual_price: actualPrice,
                        // profit_perc: profitPerc,
                        // quantity: 1
                    }
                })
            }
        }));

    }

    const handleAddArticle = (index) => {
        let modal = Refs.getInstance().getRef('newArticle');

        if (modal) {
            modal.add();
            modal.onSuccess(article => {

                // console.log(article);

                if (article) {
                    handleChangeArticle(article, index);
                }
            });
        }
    }



    // при смяна на количеството 
    const handleQtyChange = (e, index) => {
        let name = e.target.name
        let value = e.target.value

        if (isReturn()) {
            if (value) {
                value = negativeValue(value);
            }
        } else {
            if (value) {
                value = positiveValue(value);
            }
        }

        setState(name, value, 'data')

        setCurrentPrice(index, value, state.data.rows[index].delivery_total);
    }

    // при смяна на доставната цена
    const handleDeliveryTotalChange = (e, index) => {
        handleInputChange(e);

        let value = e.target.value;

        setCurrentPrice(index, state.data.rows[index].quantity, value);
    }

    // при смяна на ддс
    const handleDeliveryTaxChange = (e, index) => {
        handleInputChange(e);

        setCurrentPrice(index, state.data.rows[index].quantity, state.data.rows[index].delivery_total);
    }

    // при смяна на единичната цена
    const handleCurrentPriceChange = (e, index) => {
        handleInputChange(e);

        let value = e.target.value;

        setDeliveryTotal(index, value, state.data.rows[index].quantity);
        setCurrentGrossPrice(index, value);
    }

    const setDeliveryTotal = (index, currentPrice, qty) => {
        if (!isNumeric(currentPrice)) {
            currentPrice = 0;
        }

        if (!isNumeric(qty)) {
            qty = 0;
        }

        let deliveryTotal = currentPrice * qty;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: Object.values({
                    ...prev.data.rows,
                    [index]: {
                        ...prev.data.rows[index],
                        delivery_total: deliveryTotal.toFixed(4),
                    }
                })
            }
        }));
    }

    const setCurrentPrice = (index, qty, deliveryTotal) => {

        if (!isNumeric(qty)) {
            qty = 0;
        }

        if (!isNumeric(deliveryTotal)) {
            deliveryTotal = 0;
        }

        let current = Number(deliveryTotal) / Number(qty);

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: Object.values({
                    ...prev.data.rows,
                    [index]: {
                        ...prev.data.rows[index],
                        current_price: current.toFixed(4),
                    }
                })
            }
        }));

        setCurrentGrossPrice(index, current);

        return current;
    }

    const setCurrentGrossPrice = (index, currentPrice, withTax = false) => {

        let row = state.data.rows[index];

        let gross = currentPrice;

        if (withTax === false) {
            gross = calcGrossPrice(currentPrice, row.delivery_tax_id);
        }

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: Object.values({
                    ...prev.data.rows,
                    [index]: {
                        ...prev.data.rows[index],
                        current_price_gross: gross,
                    }
                })
            }
        }));

        let current = gross;

        if (Number(state.data.with_tax) === 1 || Number(state.data.has_tax) === 0) {
            current = currentPrice;
        }

        setProfitPerc(index, current, state.data.rows[index].actual_price || gross);
    }

    const calcGrossPrice = (price, taxId) => {

        if (!isNumeric(price)) {
            price = 0;
        }

        let tax = getTaxById(taxId);

        let gross = addExtraPrice(price, tax?.value);

        return gross.toFixed(4);
    }

    const getTaxById = id => {
        return taxGroups.find(g => Number(g.id) === Number(id));
    }

    // при смяна на надценката
    const handleProfitPercChange = (e, index) => {
        handleInputChange(e);

        let value = e.target.value;

        setActualPrice(index, getCurrentPrice(index), value);
    }

    const setActualPrice = (index, currentPrice, profit) => {

        let actual = addExtraPrice(currentPrice, profit);

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: Object.values({
                    ...prev.data.rows,
                    [index]: {
                        ...prev.data.rows[index],
                        actual_price: actual.toFixed(4),
                    }
                })
            }
        }));
    }

    // при смяна на продажната цена
    const handleActualPriceChange = (e, index) => {
        handleInputChange(e);

        let value = e.target.value;

        setProfitPerc(index, getCurrentPrice(index), value);
    }

    const setProfitPerc = (index, current, actual) => {

        let percent = calcExtraPrice(current, actual);

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: Object.values({
                    ...prev.data.rows,
                    [index]: {
                        ...prev.data.rows[index],
                        profit_perc: percent,
                    }
                })
            }
        }));
    }

    // при смяна на Без ДДС / С ДДС
    useEffect(() => {
        let rows = cloneDeep(state.data.rows);

        rows = rows.map((row, index) => {

            let currentPrice = getCurrentPrice(index);
            let profitPerc = calcExtraPrice(currentPrice, row.actual_price);

            row.profit_perc = profitPerc;

            return row;
        });

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: rows
            }
        }));
    }, [state.data.with_tax, state.data.has_tax]);

    // при сямана на Регистрация по ДДС
    // useEffect(() => {
    //     let hasTax = Number(state.data.has_tax);

    //     console.log(hasTax);

    //     if (hasTax === 0) {
    //         setState(prev => ({
    //             ...prev,
    //             data: {
    //                 ...prev.data,
    //                 with_tax: 1
    //             }
    //         }));
    //     }
    // }, [state.data.has_tax]);

    const getTotalQty = () => {
        let total = 0;

        state.data.rows.map(row => {
            total += parseFloat(row.quantity || 0);
        });

        return total;
    }

    const getTotalDelivery = () => {
        let total = 0;

        state.data.rows.map(row => {
            total += parseFloat(row.current_price || 0) * parseFloat(positiveValue(row.quantity) || 0);
        });

        return total;
    }

    const getTotalNet = () => {
        let total = 0;

        let hasTax = Number(state.data.has_tax) === 1;
        let withTax = Number(state.data.with_tax) === 1;

        state.data.rows.map(row => {
            let delivery = parseFloat(row.current_price || 0) * parseFloat(positiveValue(row.quantity) || 0);
            let tax = getTaxById(row.delivery_tax_id);

            if (!hasTax) {
                total += delivery;
            } else {
                if (withTax) {
                    total += removeExtraPrice(delivery, tax?.value);
                } else {
                    total += delivery;
                }
            }
        });

        return total;
    }

    const getTotalVat = () => {
        let total = 0;

        let hasTax = Number(state.data.has_tax) === 1;
        let withTax = Number(state.data.with_tax) === 1;

        if (!hasTax) {
            return 0;
        }

        state.data.rows.map(row => {
            let delivery = parseFloat(row.current_price || 0) * parseFloat(positiveValue(row.quantity) || 0);
            let tax = getTaxById(row.delivery_tax_id);

            if (withTax) {
                total += delivery - removeExtraPrice(delivery, tax?.value);
            } else {
                total += vatPrice(delivery, tax?.value);
            }
        });

        return total;
    }

    const getTotalGross = () => {
        return getTotalNet() + getTotalVat();
    }

    const getLatestCurrentPrice = index => {
        let row = state.data?.rows[index];

        if (!row) {
            return 0;
        }

        if (Number(state.data.with_tax) === 1 || Number(state.data.has_tax) === 0) {
            return parseFloat(row.latest_current_price_gross);
        }

        return parseFloat(row.latest_current_price);
    }

    const getCurrentPrice = index => {

        let row = state.data?.rows[index];

        if (!row) {
            return 0;
        }

        if (Number(state.data.with_tax) === 1 || Number(state.data.has_tax) === 0) {
            return parseFloat(row.current_price || 0);
        }

        return parseFloat(row.current_price_gross || 0);
    }

    const getCurrentPriceVal = index => {
        let row = state.data?.rows[index];

        if (!row) {
            return 0;
        }

        if (Number(state.data.with_tax) === 1 || Number(state.data.has_tax) === 0) {
            return parseFloat(row.current_price_gross || 0);
        }

        return parseFloat(row.current_price || 0);
    }

    const handleImport = () => {
        let modal = importModalRef.current;

        if (!modal) {
            return;
        }

        modal.open();
        // modal.setParams(state.data);

        modal.onChunk(({ is_test, type_id, results }) => {
            // console.log(data);

            // setState(prev => ({
            //     ...prev,
            //     data: {
            //         ...prev.data,
            //         rows: results
            //     }
            // }))

            // замяна или добавяне
            let erase = Number(type_id) === 1;

            let rows;

            if (erase) {
                rows = [];
            } else {
                rows = cloneDeep(state.data.rows);
            }

            // console.log(erase)
            // console.log(rows)
            // console.log(results);

            results.map((row, index) => {
                let data = row.article;

                let actualPrice = data.actual_price || row.actual_price || 0;
                let currentPrice = row.current_price || data.current_price || 0;

                let taxId = row.delivery_tax_id || data.tax_id;

                let currentGrossPrice = calcGrossPrice(currentPrice, taxId);

                // if (Number(state.data.with_tax) === 1 || Number(state.data.has_tax) === 0) {
                //     return parseFloat(row.current_price_gross || 0);
                // }

                let profitPerc = calcExtraPrice(currentGrossPrice, actualPrice);

                let latestCurrentPrice = data.current_price;
                let latestCurrentGrossPrice = calcGrossPrice(data.current_price, taxId);

                rows.push({
                    ...row,
                    delivery_tax_id: taxId,
                    latest_current_price: latestCurrentPrice,
                    latest_current_price_gross: latestCurrentGrossPrice,
                    current_price: currentPrice,
                    current_price_gross: currentGrossPrice,
                    actual_price: actualPrice,
                    profit_perc: profitPerc,
                });

            });

            // console.log(rows);

            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    rows: Object.values(rows)
                }
            }));
        })

        modal.onSuccess(() => {
            // alert(1);
        })
    }

    const handleEditIntrastat = e => {
        e.preventDefault();

        let modal = intrastatModalRef.current;

        if (!modal) {
            return;
        }

        modal.edit(state.data.id);
    }

    const handleEditExpenses = e => {
        e.preventDefault();

        let modal = expensesModalRef.current;

        if (!modal) {
            return;
        }

        modal.edit(state.data.id);

        modal.onSuccess(({ expenses_sum }) => {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    expenses_sum: expenses_sum
                }
            }));
        });
    }

    // console.log(state.data.rows);

    return (
        <>
            <Supplier
                ref={supplierModalRef}
            />

            <Question
                ref={saveAndCloseModalRef}
                mainMessage="Сигурни ли сте, че искате да затворите това зареждане?"
            />

            <Error
                ref={errorModalRef}
            />

            <Import
                ref={importModalRef}
                type={TYPES.STORELOAD}
                createText="Замяна"
                updateText="Добавяне"
            />

            <Intrastat
                ref={intrastatModalRef}
            />

            <Expenses
                ref={expensesModalRef}
            />

            <Layout>
                <div className="container-fluid">

                    <div className="page-head">
                        <h1 className="display-6">
                            <Link to={-1} className="link-dark text-decoration-none">
                                <ArrowLeftCircle />
                            </Link> {params.id ? 'Редактиране на зареждане' : 'Ново зареждане'}
                        </h1>

                        <div className="buttons">

                        </div>
                    </div>

                    <br />

                    <div className="panel">

                        {state.dataLoading
                            ?
                            <Loader />
                            :
                            <>
                                <Form ref={formRef}>
                                    {/* <Container> */}
                                    <div className="form-panel">
                                        <div className="head">
                                            Данни за зареждането
                                        </div>
                                        <Row>
                                            <Col className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Зареждане №
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder=""
                                                            name="ref"
                                                            value={state.data.id || ''}
                                                            readOnly
                                                            size="sm"
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Създал
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder=""
                                                            name="ref"
                                                            value={state.data?.user?.username || ''}
                                                            readOnly
                                                            size="sm"
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Създадено на
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder=""
                                                            name="ref"
                                                            value={state.data.created_at ? moment(state.data.created_at).format('DD.MM.YYYY HH:mm') : ''}
                                                            readOnly
                                                            size="sm"
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Операция
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Select
                                                            name="operation_id"
                                                            value={state.data?.operation_id || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations?.operation_id)}
                                                            size="sm"
                                                        >
                                                            {operations.map(o =>
                                                                <option key={o.id} value={o.id}>{o?.translation?.name || o.name}</option>
                                                            )}

                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations?.operation_id && (validations.operation_id[0] || validations.operation_id)}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Затворено на
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder=""
                                                            name="ref"
                                                            value={''}
                                                            readOnly
                                                            size="sm"
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Статус
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder=""
                                                            name="ref"
                                                            value={state.data?.status?.translation?.name || state.data?.status?.name || ''}
                                                            readOnly
                                                            size="sm"
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>

                                            <Col className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Склад
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Select
                                                            name="depot_id"
                                                            value={state.data?.depot_id || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations?.depot_id)}
                                                            size="sm"
                                                        >
                                                            <option value=""></option>
                                                            {depots.map(o =>
                                                                <option key={o.id} value={o.id}>{o?.translation?.name || o.name}</option>
                                                            )}

                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations?.depot_id && (validations.depot_id[0] || validations.depot_id)}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group>
                                            </Col>

                                            <Col className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Търговски обект
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder=""
                                                            value={state.data?.depot?.store?.translation?.name || state.data?.depot?.store?.name || ''}
                                                            readOnly
                                                            size="sm"
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Заявка №
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder=""
                                                            value={''}
                                                            readOnly
                                                            size="sm"
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Тип документ
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Select
                                                            name="document_type_id"
                                                            value={state.data?.document_type_id || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations?.document_type_id)}
                                                            size="sm"
                                                        >
                                                            {documentTypes.map(o =>
                                                                <option key={o.id} value={o.id}>{o?.translation?.name || o.name}</option>
                                                            )}

                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations?.document_type_id && (validations.document_type_id[0] || validations.document_type_id)}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group>
                                            </Col>

                                            <Col className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Документ №
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder=""
                                                            name="document_no"
                                                            value={state.data?.document_no || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations?.document_no)}
                                                            size="sm"
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations?.document_no && (validations.document_no[0] || validations.document_no)}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Документ дата
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <DatePicker
                                                            variant="basic"
                                                            type="datetime-local"
                                                            name="document_date"
                                                            value={state.data?.document_date ? moment(state.data.document_date).format('YYYY-MM-DDTHH:mm') : ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations?.document_date)}
                                                            size="sm"
                                                            error={validations?.document_date && (validations.document_date[0] || validations.document_date)}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Доставчик
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Autocomplete
                                                            variant="basic"
                                                            url="autocomplete/suppliers"
                                                            inputName="supplier_name"
                                                            inputIdName="supplier_id"
                                                            inputValue={state.data?.supplier?.name}
                                                            inputIdValue={state.data?.supplier_id || ''}
                                                            onChange={handleChangeSupplier}
                                                            onClickAddButton={handleAddSupplier}
                                                            error={Boolean(validations?.supplier_id)}
                                                            helperText={validations?.supplier_id && (validations.supplier_id[0] || validations.supplier_id)}
                                                            size="sm"
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Регистрация по ДДС <Info>Ако доставчика няма регистрация, ДДС-то в тотала ще се нулира автоматично.</Info>
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <div className="custom-radio">
                                                            <label>
                                                                <input type="radio" name="has_tax" value={0} checked={Number(state.data.has_tax) === 0} onChange={handleInputChange} />
                                                                <span>
                                                                    НЕ
                                                                </span>
                                                            </label>
                                                            <label>
                                                                <input type="radio" name="has_tax" value={1} checked={Number(state.data.has_tax) === 1} onChange={handleInputChange} />
                                                                <span>
                                                                    ДА
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Единичните цени са <Info>Ако единичните цени в документа са с включено ДДС отбележете опция С ДДС, за да се изчислят правилно себестойностите.</Info>
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        {Number(state.data.has_tax) === 1 &&
                                                            <div className="custom-radio">
                                                                <label>
                                                                    <input type="radio" name="with_tax" value={0} checked={Number(state.data.with_tax) === 0} onChange={handleInputChange} />
                                                                    <span>
                                                                        Без ДДС
                                                                    </span>
                                                                </label>
                                                                <label>
                                                                    <input type="radio" name="with_tax" value={1} checked={Number(state.data.with_tax) === 1} onChange={handleInputChange} />
                                                                    <span>
                                                                        С ДДС
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        }
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            {auth.getUser().permission('storeloads_edit_total_paid') &&
                                                <Col className="mb-3" sm={4}>
                                                    <Form.Group as={Row}>
                                                        <Form.Label column="sm" sm={4} className="text-left">
                                                            Платена сума / Начин на плащане
                                                        </Form.Label>
                                                        <Col sm={8}>
                                                            <Row>
                                                                <Col xs={6}>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder=""
                                                                        name="total_paid"
                                                                        value={state.data?.total_paid || ''}
                                                                        onChange={handleInputChange}
                                                                        isInvalid={Boolean(validations?.total_paid)}
                                                                        size="sm"
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {validations?.total_paid && (validations.total_paid[0] || validations.total_paid)}
                                                                    </Form.Control.Feedback>
                                                                </Col>
                                                                <Col xs={6}>
                                                                    <Form.Select
                                                                        name="payment_method_id"
                                                                        value={state.data?.payment_method_id || ''}
                                                                        onChange={handleInputChange}
                                                                        isInvalid={Boolean(validations?.payment_method_id)}
                                                                        size="sm"
                                                                    >
                                                                        <option value=""></option>
                                                                        {paymentMethods.map(o =>
                                                                            <option key={o.id} value={o.id}>{o?.translation?.name || o.name}</option>
                                                                        )}

                                                                    </Form.Select>
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {validations?.payment_method_id && (validations.payment_method_id[0] || validations.payment_method_id)}
                                                                    </Form.Control.Feedback>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Form.Group>
                                                </Col>
                                            }
                                            <Col className="mb-3" sm={4}>
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Срок за плащане
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <DatePicker
                                                            variant="basic"
                                                            name="paid_due_date"
                                                            value={state.data?.paid_due_date ? moment(state.data.paid_due_date).format('YYYY-MM-DD') : ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations?.paid_due_date)}
                                                            size="sm"
                                                            error={validations?.paid_due_date && (validations.paid_due_date[0] || validations.paid_due_date)}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col className="mb-3" sm={4}>
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Валута / Курс
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Row>
                                                            <Col xs={8}>
                                                                <Form.Select
                                                                    name="currency_id"
                                                                    value={state.data?.currency_id || ''}
                                                                    onChange={handleCurrencyChange}
                                                                    isInvalid={Boolean(validations?.currency_id)}
                                                                    size="sm"
                                                                >
                                                                    {currencies.data.map(o =>
                                                                        <option key={o.id} value={o.id}>{o?.translation?.name || o.name}</option>
                                                                    )}

                                                                </Form.Select>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {validations?.currency_id && (validations.currency_id[0] || validations.currency_id)}
                                                                </Form.Control.Feedback>
                                                            </Col>
                                                            <Col xs={4}>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder=""
                                                                    name="currency_rate"
                                                                    value={state.data?.currency_rate || ''}
                                                                    onChange={handleInputChange}
                                                                    isInvalid={Boolean(validations?.currency_rate)}
                                                                    size="sm"
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {validations?.currency_rate && (validations.currency_rate[0] || validations.currency_rate)}
                                                                </Form.Control.Feedback>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                    </div>

                                    <div className="mt-3">
                                        <Button size="sm" variant="primary" onClick={handleEditIntrastat} disabled={params.id ? false : true}>
                                            <FileText /> Интрастат
                                        </Button>
                                        <Button size="sm" variant="primary" onClick={handleEditExpenses} disabled={params.id ? false : true} style={{ marginLeft: '5px' }}>
                                            <PlusCircle /> Допълнителни разходи {state.data.expenses_sum !== null &&
                                                <span>(<PriceValue prefix={state.data.currency?.prefix} sufix={state.data.currency?.sufix}>{state.data.expenses_sum}</PriceValue>)</span>
                                            }
                                        </Button>
                                    </div>

                                    <div className="form-panel mt-3">
                                        <div className="head">
                                            Артикули
                                        </div>
                                        <Table className="valign-top big">
                                            <thead>
                                                <tr>
                                                    <th style={{ width: '5%' }}>
                                                        #
                                                    </th>
                                                    <th style={{ width: '20%' }}>
                                                        Артикул
                                                    </th>
                                                    <th>
                                                        Количество
                                                    </th>
                                                    {auth.getUser().permission('storeloads_edit_delivery_price') &&
                                                        <>
                                                            <th>
                                                                Сума <Info placement="top">Въвежда се общата цена за цялото доставено количество.</Info>
                                                            </th>
                                                            <th>
                                                                ДДС Група
                                                            </th>
                                                            <th>
                                                                Ед. цена <Info placement="top">Изчислява се цената за единица количество (кг, л, брой).</Info>
                                                            </th>
                                                            <th>
                                                                Последно <Info placement="top">Доставна цена на последното зареждане.</Info>
                                                            </th>
                                                        </>
                                                    }
                                                    {auth.getUser().permission('storeloads_edit_actual_price') &&
                                                        <th className="text-center">
                                                            Продажна цена <Info placement="top">Възможност за задаване на продажна цена директно или чрез процент печалба спрямо доставната цена.</Info>
                                                        </th>
                                                    }
                                                    <th>
                                                        Бележки
                                                    </th>
                                                    <th className="options">

                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {state.data.rows.map((row, index) =>
                                                    <tr key={'r-' + index}>
                                                        <td title={row.id || '-'} style={{ width: '5%' }}>
                                                            {index + 1}

                                                            <input type="hidden" name={`rows[${index}][id]`} value={state.data.rows[index].id || ''} />
                                                        </td>
                                                        <td>
                                                            <Autocomplete
                                                                variant="basic"
                                                                url="autocomplete/articles"
                                                                inputName="article_name"
                                                                inputIdName={`rows[${index}][article_id]`}
                                                                inputValue={state.data?.rows[index] && state.data?.rows[index]?.article?.name ? state.data?.rows[index]?.article?.name : ''}
                                                                inputIdValue={state.data?.rows[index] && state.data?.rows[index].article_id ? state.data?.rows[index].article_id : ''}
                                                                onChange={data => handleChangeArticle(data, index)}
                                                                onClickAddButton={e => handleAddArticle(index)}
                                                                error={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].article_id && (validations.rows[index].article_id[0] || validations.rows[index].article_id))}
                                                                helperText={validations && validations.rows && validations.rows[index] && validations.rows[index].article_id && (validations.rows[index].article_id[0] || validations.rows[index].article_id)}
                                                                renderText={data => {
                                                                    return (
                                                                        <div style={{ display: 'flex', width: '100%' }}>
                                                                            <div style={{ width: 'max-content', marginRight: '10px' }}>
                                                                                {data.id}
                                                                            </div>
                                                                            <div>
                                                                                {data.name}
                                                                                <br />
                                                                                {data.article_name}
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }}
                                                                renderInputText={data => data.article_name}
                                                                size="sm"
                                                                listStyle={{
                                                                    minWidth: 'max-content'
                                                                }}
                                                                autoFocus
                                                            />
                                                        </td>
                                                        <td>
                                                            <InputGroup size="sm" style={{ minWidth: '95px' }}>
                                                                <Form.Control
                                                                    type="number"
                                                                    step="0.0001"
                                                                    name={`rows[${index}][quantity]`}
                                                                    value={state.data?.rows[index] && state.data?.rows[index].quantity ? state.data?.rows[index].quantity : ''}
                                                                    onChange={e => handleQtyChange(e, index)}
                                                                    isInvalid={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].quantity && (validations.rows[index].quantity[0] || validations.rows[index].quantity))}
                                                                    size="sm"
                                                                    className="price-input"
                                                                />

                                                                <InputGroup.Text>
                                                                    {state.data?.rows[index]?.article?.amount_type_short || '-'}
                                                                </InputGroup.Text>


                                                                {/* <Form.Control.Feedback type="invalid">
                                                                        {validations && validations.rows && validations.rows[index] && validations.rows[index].quantity && (validations.rows[index].quantity[0] || validations.rows[index].quantity)}
                                                                    </Form.Control.Feedback> */}
                                                            </InputGroup>

                                                        </td>
                                                        {auth.getUser().permission('storeloads_edit_delivery_price') &&
                                                            <>
                                                                <td>
                                                                    <InputGroup size="sm" style={{ minWidth: '95px' }}>
                                                                        <Form.Control
                                                                            type="number"
                                                                            step="0.01"
                                                                            name={`rows[${index}][delivery_total]`}
                                                                            value={state.data?.rows[index] && state.data?.rows[index].delivery_total ? state.data?.rows[index].delivery_total : ''}
                                                                            onChange={e => handleDeliveryTotalChange(e, index)}
                                                                            isInvalid={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].delivery_total && (validations.rows[index].delivery_total[0] || validations.rows[index].delivery_total))}
                                                                            size="sm"
                                                                            className="price-input"
                                                                        />

                                                                        {state.data?.currency &&
                                                                            <InputGroup.Text>
                                                                                {state.data.currency.prefix || state.data.currency.sufix || '-'}
                                                                            </InputGroup.Text>
                                                                        }

                                                                        {/* <Form.Control.Feedback type="invalid">
                                                                        {validations && validations.rows && validations.rows[index] && validations.rows[index].delivery_total && (validations.rows[index].delivery_total[0] || validations.rows[index].delivery_total)}
                                                                    </Form.Control.Feedback> */}
                                                                    </InputGroup>
                                                                </td>
                                                                <td>
                                                                    <Form.Select
                                                                        name={`rows[${index}][delivery_tax_id]`}
                                                                        value={state.data?.rows[index] && state.data?.rows[index].delivery_tax_id ? state.data?.rows[index].delivery_tax_id : ''}
                                                                        onChange={e => handleDeliveryTaxChange(e, index)}
                                                                        isInvalid={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].delivery_tax_id && (validations.rows[index].delivery_tax_id[0] || validations.rows[index].delivery_total))}
                                                                        size="sm"
                                                                        style={{ minWidth: '95px' }}
                                                                    >
                                                                        <option value=""></option>
                                                                        {taxGroups.map(o =>
                                                                            <option key={o.id} value={o.id}>{o?.translation?.name || o.name}</option>
                                                                        )}

                                                                    </Form.Select>
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {validations && validations.rows && validations.rows[index] && validations.rows[index].delivery_tax_id && (validations.rows[index].delivery_tax_id[0] || validations.rows[index].delivery_tax_id)}
                                                                    </Form.Control.Feedback>
                                                                </td>
                                                                <td>
                                                                    <InputGroup size="sm" style={{ minWidth: '95px' }}>
                                                                        <Form.Control
                                                                            type="number"
                                                                            step="0.01"
                                                                            name={`rows[${index}][current_price]`}
                                                                            value={state.data?.rows[index] && state.data?.rows[index].current_price ? state.data?.rows[index].current_price : ''}
                                                                            onChange={e => handleCurrentPriceChange(e, index)}
                                                                            isInvalid={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].current_price && (validations.rows[index].current_price[0] || validations.rows[index].current_price))}
                                                                            size="sm"
                                                                            className="price-input"
                                                                        />

                                                                        {state.data?.currency &&
                                                                            <InputGroup.Text>
                                                                                {state.data.currency.prefix || state.data.currency.sufix || '-'}/{state.data?.rows[index]?.article?.amount_type_short || '-'}
                                                                            </InputGroup.Text>
                                                                        }

                                                                        {/* <Form.Control.Feedback type="invalid">
                                                                        {validations && validations.rows && validations.rows[index] && validations.rows[index].current_price && (validations.rows[index].current_price[0] || validations.rows[index].current_price)}
                                                                    </Form.Control.Feedback> */}
                                                                    </InputGroup>
                                                                </td>
                                                                <td>
                                                                    <InputGroup size="sm" style={{ minWidth: '95px' }}>
                                                                        <Form.Control
                                                                            type="number"
                                                                            step="0.01"
                                                                            value={getLatestCurrentPrice(index).toFixed(2)}
                                                                            size="sm"
                                                                            className={`price-input ${getLatestCurrentPrice(index).toFixed(2) < getCurrentPriceVal(index).toFixed(2) ? 'negative-value' : ''}`}
                                                                            disabled
                                                                        />

                                                                        {state.data?.rows[index]?.article?.currency &&
                                                                            <InputGroup.Text>
                                                                                {state.data?.rows[index].article.currency.prefix || state.data?.rows[index].article.currency.sufix || '-'}/{state.data?.rows[index]?.article?.amount_type_short || '-'}
                                                                            </InputGroup.Text>
                                                                        }


                                                                    </InputGroup>
                                                                </td>
                                                            </>
                                                        }
                                                        {auth.getUser().permission('storeloads_edit_actual_price') &&
                                                            <td>
                                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                                    <span style={{ width: '20%', fontSize: '12px', textAlign: 'right' }}>
                                                                        <span>
                                                                            {getCurrentPrice(index).toFixed(2)}
                                                                        </span>
                                                                        <span style={{ margin: '0 3px' }}>
                                                                            x
                                                                        </span>
                                                                    </span>
                                                                    <InputGroup size="sm" style={{ width: '40%', minWidth: '95px' }}>
                                                                        <Form.Control
                                                                            type="number"
                                                                            step="0.01"
                                                                            name={`rows[${index}][profit_perc]`}
                                                                            value={state.data?.rows[index].profit_perc || ''}
                                                                            onChange={e => handleProfitPercChange(e, index)}
                                                                            isInvalid={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].profit_perc && (validations.rows[index].profit_perc[0] || validations.rows[index].profit_perc))}
                                                                            size="sm"
                                                                            className={`price-input ${state.data?.rows[index].profit_perc > 0 ? 'positive-value' : state.data?.rows[index].profit_perc < 0 ? 'negative-value' : ''}`}
                                                                        />
                                                                        <InputGroup.Text>
                                                                            %
                                                                        </InputGroup.Text>
                                                                    </InputGroup>
                                                                    <span style={{ margin: '0 3px' }}>
                                                                        =
                                                                    </span>
                                                                    <InputGroup size="sm" style={{ width: '40%', minWidth: '125px' }}>
                                                                        <Form.Control
                                                                            type="number"
                                                                            step="0.01"
                                                                            name={`rows[${index}][actual_price]`}
                                                                            value={state.data?.rows[index].actual_price || ''}
                                                                            onChange={e => handleActualPriceChange(e, index)}
                                                                            isInvalid={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].actual_price && (validations.rows[index].actual_price[0] || validations.rows[index].actual_price))}
                                                                            size="sm"
                                                                            className="price-input"
                                                                        />

                                                                        {state.data?.currency &&
                                                                            <InputGroup.Text>
                                                                                {state.data.currency.prefix || state.data.currency.sufix || '-'}/{state.data?.rows[index]?.article?.amount_type_short || '-'}
                                                                            </InputGroup.Text>
                                                                        }

                                                                        {/* <Form.Control.Feedback type="invalid">
                                                                        {validations && validations.rows && validations.rows[index] && validations.rows[index].actual_price && (validations.rows[index].actual_price[0] || validations.rows[index].actual_price)}
                                                                    </Form.Control.Feedback> */}
                                                                    </InputGroup>
                                                                </div>
                                                            </td>
                                                        }
                                                        <td>
                                                            <Form.Control
                                                                type="text"
                                                                name={`rows[${index}][notes]`}
                                                                value={state.data?.rows[index] && state.data?.rows[index].notes ? state.data?.rows[index].notes : ''}
                                                                onChange={handleInputChange}
                                                                isInvalid={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].notes && (validations.rows[index].notes[0] || validations.rows[index].notes))}
                                                                size="sm"
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {validations && validations.rows && validations.rows[index] && validations.rows[index].notes && (validations.rows[index].notes[0] || validations.rows[index].notes)}
                                                            </Form.Control.Feedback>
                                                        </td>
                                                        <td className="options">
                                                            <Button variant="danger" size="sm" onClick={e => handleRemoveRow(e, index)}>
                                                                <TrashFill />
                                                            </Button>
                                                        </td>

                                                    </tr>
                                                )}
                                                {/* <tr>
                                                    <td colSpan={2}>
                                                        Сума
                                                    </td>
                                                    <td>
                                                        {getTotalQty().toFixed(4)}
                                                    </td>
                                                    <td colSpan={7}>
                                                        {getTotalDelivery().toFixed(4)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={3}>
                                                        Данъчна основа
                                                    </td>

                                                    <td colSpan={7}>
                                                        {getTotalNet().toFixed(4)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={3}>
                                                        ДДС
                                                    </td>

                                                    <td colSpan={7}>
                                                        {getTotalVat().toFixed(4)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={3}>
                                                        Общо
                                                    </td>

                                                    <td colSpan={7}>
                                                        {getTotalGross().toFixed(4)}
                                                    </td>
                                                </tr> */}
                                            </tbody>
                                        </Table>

                                        <Button variant="outline-dark" className="mt-3" size="sm" onClick={handleAddRow}>
                                            Нов ред
                                        </Button>
                                        <Button variant="outline-dark" className="mt-3" size="sm" onClick={handleImport} disabled={auth.getUser().permission('import_index') === false} style={{ marginLeft: '5px' }}>
                                            <Upload /> Импорт
                                        </Button>
                                    </div>


                                    <Row>
                                        <Col sm={8} className="mt-3">
                                            <div className="form-panel full-height">
                                                <div className="head">
                                                    Бележки
                                                </div>
                                                <Form.Control
                                                    type="text"
                                                    as="textarea"
                                                    // placeholder="Бележки"
                                                    name="description"
                                                    value={state.data?.description || ''}
                                                    onChange={handleInputChange}
                                                    isInvalid={Boolean(validations?.description)}
                                                    rows={6}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {validations?.description && (validations.description[0] || validations.description)}
                                                </Form.Control.Feedback>
                                            </div>

                                        </Col>
                                        <Col sm={4} className="mt-3">
                                            <div className="form-panel full-height">
                                                <div className="head">
                                                    Общо
                                                </div>

                                                <Row>
                                                    <Col className="mb-3">
                                                        <Form.Group as={Row}>
                                                            <Form.Label column="sm" sm={6} className="text-left">
                                                                Сума
                                                            </Form.Label>
                                                            <Col sm={6}>
                                                                <InputGroup size="sm">
                                                                    <Form.Control
                                                                        type="number"
                                                                        step="0.01"
                                                                        readOnly
                                                                        value={getTotalDelivery().toFixed(4)}
                                                                        size="sm"
                                                                        className="price-input"
                                                                    />
                                                                    {state.data?.currency &&
                                                                        <InputGroup.Text>
                                                                            {state.data.currency.prefix || state.data.currency.sufix || '-'}
                                                                        </InputGroup.Text>
                                                                    }
                                                                </InputGroup>
                                                            </Col>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className="mb-3">
                                                        <Form.Group as={Row}>
                                                            <Form.Label column="sm" sm={6} className="text-left">
                                                                Данъчна основа
                                                            </Form.Label>
                                                            <Col sm={6}>
                                                                <InputGroup size="sm">
                                                                    <Form.Control
                                                                        type="number"
                                                                        step="0.01"
                                                                        readOnly
                                                                        value={getTotalNet().toFixed(4)}
                                                                        size="sm"
                                                                        className="price-input"
                                                                    />
                                                                    {state.data?.currency &&
                                                                        <InputGroup.Text>
                                                                            {state.data.currency.prefix || state.data.currency.sufix || '-'}
                                                                        </InputGroup.Text>
                                                                    }
                                                                </InputGroup>
                                                            </Col>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className="mb-3">
                                                        <Form.Group as={Row}>
                                                            <Form.Label column="sm" sm={6} className="text-left">
                                                                ДДС
                                                            </Form.Label>
                                                            <Col sm={6}>
                                                                <InputGroup size="sm">
                                                                    <Form.Control
                                                                        type="number"
                                                                        step="0.01"
                                                                        readOnly
                                                                        value={getTotalVat().toFixed(4)}
                                                                        size="sm"
                                                                        className="price-input"
                                                                    />
                                                                    {state.data?.currency &&
                                                                        <InputGroup.Text>
                                                                            {state.data.currency.prefix || state.data.currency.sufix || '-'}
                                                                        </InputGroup.Text>
                                                                    }
                                                                </InputGroup>
                                                            </Col>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col>
                                                        <Form.Group as={Row}>
                                                            <Form.Label column="sm" sm={6} className="text-left">
                                                                Общо
                                                            </Form.Label>
                                                            <Col sm={6}>
                                                                <InputGroup size="sm">
                                                                    <Form.Control
                                                                        type="number"
                                                                        step="0.01"
                                                                        readOnly
                                                                        value={getTotalGross().toFixed(4)}
                                                                        size="sm"
                                                                        className="price-input"
                                                                    />
                                                                    {state.data?.currency &&
                                                                        <InputGroup.Text>
                                                                            {state.data.currency.prefix || state.data.currency.sufix || '-'}
                                                                        </InputGroup.Text>
                                                                    }
                                                                </InputGroup>
                                                            </Col>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>

                                    <div className="buttons mt-3">
                                        <Button
                                            variant="secondary"
                                            onClick={e => navigate(-1)}
                                        >
                                            Отказ
                                        </Button>
                                        <SaveButton
                                            loading={state.loadingExit}
                                            disabled={state.loading}
                                            className="save"
                                            onClick={handleSaveAndExit}
                                        >
                                            Запази данните
                                        </SaveButton>
                                        <SaveButton
                                            loading={state.loadingBase}
                                            disabled={state.loading}
                                            className="save"
                                            onClick={handleSave}
                                        >
                                            Запази и продължи
                                        </SaveButton>
                                        {state.data.allow_close &&
                                            <SaveButton
                                                loading={state.loadingClose}
                                                disabled={state.loading}
                                                className="save"
                                                onClick={handleSaveAndClose}
                                            >
                                                Запази и вкарай в склада
                                            </SaveButton>
                                        }

                                    </div>
                                    {/* </Container> */}

                                </Form>
                            </>
                        }

                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Index;