import { useState, useImperativeHandle, forwardRef, useRef, useEffect } from 'react'
import { Modal, Button, Alert, Form } from 'react-bootstrap';
import { createPortal } from 'react-dom';

import { useAuthDataContext } from 'providers/Auth';
import { useAppContext } from 'providers/App';

import SaveButton from 'components/misc/Button';

function TextUpload(props, ref) {

    const auth = useAuthDataContext();
    const app = useAppContext();

    const defaultData = {

    }

    const [state, setState] = useState({
        show: false,
        params: null,
        data: defaultData,
        onSuccess: null,
        onClose: null,
        onEntering: null,
        onExiting: null,
    });

    const tableRef = useRef();

    useImperativeHandle(ref, () => ({
        open: () => {
            handleShow();
        },
        close: () => {
            handleClose();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        },
        onCancel: fn => {
            setState(prev => ({
                ...prev,
                onCancel: fn
            }));
        }
    }));

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const handleShow = () => {
        setState(prev => ({
            ...prev,
            data: defaultData
        }));

        show();
    }

    const handleClose = () => {
        hide(state.onClose);
    }

    const handleSave = e => {
        e.preventDefault();

        // setState(prev => ({
        //     ...prev,
        //     loading: true,
        // }));

        hide(state.onClose);

        state.onSuccess(state.data.text);
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: value
            }
        }))
    }

    return createPortal(
        <Modal size="lg" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Въвеждане на свободни текстови данни за импорт
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Alert>
                    Този метод е създаден за бързо качване на данни, чрез ръчно въвеждане.
                    <br />
                    При ръчно въвеждане спазвайте CSV формата (отделяне на колоните със запетайка, а редовете с нов ред)
                </Alert>

                <Form.Control
                    as="textarea"
                    name="text"
                    onChange={handleInputChange}
                    style={{
                        height: '400px'
                    }}
                    autoFocus
                >

                </Form.Control>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Затвори
                </Button>
                <SaveButton onClick={handleSave}>
                    Качване
                </SaveButton>
            </Modal.Footer>
        </Modal>,
        document.body
    )

}

export default forwardRef(TextUpload);