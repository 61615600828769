import { useNavigate } from "react-router-dom"
import moment from "moment"
import { FloatingLabel, Form } from 'react-bootstrap'

//helpers
import { useQuery } from 'helpers/Url'

function Filter(props) {
    const history = useNavigate()
    const query = useQuery()

    const handleSearch = e => {
        const name = e.target.name
        const value = e.target.value

        props.handleSearch(name, value)
    }

    return (
        <div className="row page-filter">
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <FloatingLabel label="Роля">
                    <Form.Select
                        name="role_id"
                        value={props.filter.role_id || ''}
                        onChange={e => handleSearch(e)}
                    >
                        {props.roles.map(r => <option value={`${r.id}`}>{r.name}</option>)}
                    </Form.Select>
                </FloatingLabel>
            </div>
        </div>
    )
}

export default Filter;
