import PriceRule from 'components/pages/price_rules/partials/AddOrEdit';
import NewArticle from 'components/pages/articles/partials/AddOrEdit';
import Article from 'components/pages/articles/view/View';
import ArticleProvider from 'components/pages/articles/Provider';
import Device from 'components/pages/devices/partials/AddOrEdit';
import DeviceSelection from 'components/pages/devices/partials/DeviceSelection';
import Account from 'components/pages/accounts/view/View';
import Order from 'components/pages/orders/view/View';
import NewPayment from 'components/pages/payments/partials/AddOrEdit';
import Payment from 'components/pages/payments/view/View';
import NewClient from 'components/pages/clients/partials/AddOrEdit';
import Client from 'components/pages/clients/view/View';
import ClientProvider from 'components/pages/clients/Provider';
import Address from 'components/pages/clients/partials/Addresses';
import Return from 'components/pages/returns/view/View';
import NewReturn from 'components/pages/returns/partials/New';
import NewPackageCard from 'components/pages/package_card/partials/AddOrEdit';
import NewCompany from 'components/pages/companies/partials/AddOrEdit';
import Invoice from 'components/pages/invoices/view/View';
import Warranty from 'components/pages/warranty/view/View';
import Contract from 'components/pages/contracts/view/View';
import Protocol from 'components/pages/protocols/view/View';
import DocumentViewGroup from 'components/partials/DocumentViewGroup';
import StoreLoad from 'components/pages/storeloads/view/View';
import StoreMove from 'components/pages/storemoves/view/View';
import Revision from 'components/pages/revisions/view/View';
import StorageOperationViewGroup from 'components/partials/StorageOperationViewGroup';
import ArticleAvailability from 'components/pages/articles/partials/Availability';
import Supplier from 'components/pages/suppliers/partials/AddOrEdit';
import Info from 'components/modals/Info';
import PackageCard from 'components/pages/package_card/partials/AddOrEdit';
import Error from 'components/modals/Error';
import Question from 'components/modals/Question';
import Refs from 'Refs';
import Search from 'components/pages/search/partials/Search';

function Index(props) {
    const refs = Refs.getInstance();

    return (
        <>
            <PriceRule
                ref={ref => refs.setRef('priceRule', ref)}
            />

            <ArticleProvider>
                <NewArticle
                    ref={ref => refs.setRef('newArticle', ref)}
                />
                <Article
                    ref={ref => refs.setRef('article', ref)}
                />
            </ArticleProvider>

            <ArticleAvailability
                ref={ref => refs.setRef('articleAvailability', ref)}
            />

            <Device
                ref={ref => refs.setRef('device', ref)}
            />

            <DeviceSelection
                ref={ref => refs.setRef('deviceSelection', ref)}
            />

            <Account
                ref={ref => refs.setRef('account', ref)}
            />

            <Order
                ref={ref => refs.setRef('order', ref)}
            />

            <NewPayment
                ref={ref => refs.setRef('newPayment', ref)}
            />

            <Payment
                ref={ref => refs.setRef('payment', ref)}
            />

            <ClientProvider>
                <NewClient
                    ref={ref => refs.setRef('newClient', ref)}
                />
                <Client
                    ref={ref => refs.setRef('client', ref)}
                />
            </ClientProvider>

            <Address
                ref={ref => refs.setRef('address', ref)}
            />

            <Return
                ref={ref => refs.setRef('return', ref)}
            />

            <NewReturn
                ref={ref => refs.setRef('newReturn', ref)}
            />

            <NewPackageCard
                ref={ref => refs.setRef('newPackageCard', ref)}
            />

            <NewCompany
                ref={ref => refs.setRef('newCompany', ref)}
            />

            <Invoice
                ref={ref => refs.setRef('invoice', ref)}
            />

            <Warranty
                ref={ref => refs.setRef('warranty', ref)}
            />

            <Protocol
                ref={ref => refs.setRef('protocol', ref)}
            />

            <Contract
                ref={ref => refs.setRef('contract', ref)}
            />

            <DocumentViewGroup
                ref={ref => refs.setRef('document', ref)}
            />

            <StoreLoad
                ref={ref => refs.setRef('load', ref)}
            />

            <StoreMove
                ref={ref => refs.setRef('move', ref)}
            />

            <Revision
                ref={ref => refs.setRef('revision', ref)}
            />

            <StorageOperationViewGroup
                ref={ref => refs.setRef('storageOperation', ref)}
            />

            <Supplier
                ref={ref => refs.setRef('supplier', ref)}
            />

            <PackageCard
                ref={ref => refs.setRef('packageCard', ref)}
            />

            <Info
                ref={ref => refs.setRef('info', ref)}
            />

            <Error
                ref={ref => refs.setRef('error', ref)}
            />

            <Question
                ref={ref => refs.setRef('question', ref)}
            />

            <Question
                ref={ref => refs.setRef('delete', ref)}
                mainMessage="Сигурни ли сте?"
                agreeBtnText="Изтриване"
                agreeBtnClass="remove"
            />

            <Search
                ref={ref => refs.setRef('search', ref)}
            />
        </>
    )
}

export default Index;