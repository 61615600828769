import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import { Button, Col, Row, Nav, FloatingLabel, Form, Tab, Alert, Card } from 'react-bootstrap';
import { FileArrowDownFill } from 'react-bootstrap-icons';

//helpers
import Api from 'helpers/Api'

//providers
import { useLanguageContext } from 'providers/Language'
import { useClientContext } from '../Provider';
import { useAppContext } from 'providers/App';

//misc
import Autocomplete from 'components/misc/Autocomplete';
import HelpInfo from 'components/misc/Info';
import SaveButton from 'components/misc/Button';
import Refs from 'Refs';
import Vies from './Vies';

function Index(props, ref) {

    const app = useAppContext();
    const langs = useLanguageContext();
    const context = useClientContext();

    const { state, setState } = context;
    const { validations, setValidations } = context;

    const [clientMethods, setClientMethods] = useState([]);
    const [clientGroups, setClientGroups] = useState([]);
    const [clientSources, setClientSources] = useState([]);

    const [vies, setVies] = useState({
        loading: false,
        data: {}
    });

    useImperativeHandle(ref, () => ({
        save: (e) => {
            handleSave(e);
        },
    }));

    const formRef = useRef(null);
    const inputFileRef = useRef(null);

    useEffect(() => {
        loadData();
    }, [props.id]);

    useEffect(() => {
        Api.get('clients/clients-groups')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setClientGroups(res.data);
                }
            });

        Api.get('clients/clients-sources')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setClientSources(res.data);
                }
            });

        Api.get('clients/payment-methods')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setClientMethods(res.data);
                }
            });
    }, []);

    useEffect(() => {
        if (state.refresh) {
            loadData();
        }
    }, [state.refresh]);

    const loadData = () => {
        if (!props.id) {
            return;
        }

        if (state.data.id === props.id) {
            return;
        }

        Api.get('clients/show?id=' + props.id)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data,
                    initialData: res.data,
                }))
            });
    }

    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = state.data.id ? 'clients/update' : 'clients/add';

        let data = new FormData(formRef.current);

        if (state.data.file?.was_recently_attached) {
            data.append('image', state.data.file);
        }

        if (state.data.id) {
            data.append('id', state.data.id)
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (typeof props.onSuccess === 'function') {
                props.onSuccess(res.data)
            }

            app.showSuccess();
        }).catch(error => {
            const _err = error.response;

            if (_err && _err.status && _err.status === 422) {

                setValidations(_err.data.errors)

                props.hide(() => {
                    let modal = Refs.getInstance().getRef('info');

                    if (modal) {
                        modal.open('Моля попълнете всички полета означени с червено');

                        modal.onClose(() => {
                            props.show();
                        });
                    }
                });
            } else {
                app.showError();
            }
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }));
        });
    }

    const setMainTab = key => {
        setState(prev => ({
            ...prev,
            mainTabId: key
        }));
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleDiscountChange = e => {
        const name = e.target.name
        let value = e.target.value

        if (!value.includes('+') && !value.includes('-') && value.length > 0) {
            value = '-' + value;
        }

        if (value.includes('+')) {
            value = value.replace('-', '')
        }

        setState(name, value, 'data')
    }

    const handleDateChange = e => {
        const name = e.target.name + '_ymd'
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleCheckboxChange = e => {
        const name = e.target.name;
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: prev.data[name] ? false : true
            }
        }));
    }

    const handleClientChange = (data) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                client_name: data.name,
                client_id: data.id
            }
        }));
    }

    const handleGenerateCode = (e) => {
        e.preventDefault();

        Api.get('clients/generate-code')
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: {
                        ...prev.data,
                        ['client_card']: res.data
                    }
                }));
            });
    }

    const handleAdd = () => {
        let modal = Refs.getInstance().getRef('address');

        if (modal) {

            props.hide(() => {
                modal.add(state.data.id);

                modal.onClose(() => {
                    props.show();
                });

                modal.onSuccess((data) => {
                    setState(prev => ({
                        ...prev,
                        data: {
                            ...prev.data,
                            addresses: prev.data.addresses.concat(data)
                        }
                    }));

                    props.show()
                });

            });

        }

    }

    // update
    const handleEdit = id => {
        let modal = Refs.getInstance().getRef('address');

        if (modal) {

            props.hide(() => {
                modal.edit(id);

                modal.onClose(() => {
                    props.show();
                });

                modal.onSuccess((data) => {
                    setState(prev => ({
                        ...prev,
                        data: {
                            ...prev.data,
                            addresses: prev.data.addresses.map(t => {
                                if (t.id == data.id) {
                                    t = data;
                                }

                                return t;
                            })
                        }
                    }));

                    props.show();
                });

            });

        }

    }

    // delete
    const handleDelete = id => {
        let modal = Refs.getInstance().getRef('delete');

        if (modal) {
            props.hide(() => {
                modal.open();

                modal.onSuccess(() => {
                    handleDestroy(id);
                    props.show()
                });

                modal.onCancel(() => {
                    props.show();
                });
            });
        }
    }

    const handleDestroy = id => {
        Api.post('clients/addresses/delete', {
            id: id
        }).then(res => {
            if (res.data.success) {
                setState(prev => ({
                    ...prev,
                    data: {
                        ...prev.data,
                        addresses: prev.data.addresses.filter(t => t.id != id)
                    }
                }));
            }
        });
    }

    const handleAddressPreview = (address) => {
        if (!address) {
            return;
        }

        let values = [];

        if (address.delivery_type_id == 1) {
            values = [address.country_name, address.dest_name_full, address.address];
        }
        if (address.delivery_type_id == 2) {
            values = [address.dest_name_full, address.office_name];
        }
        if (address.delivery_type_id == 3) {
            values = [address.store.translation?.name];
        }

        values = values.filter(t => t).join(' <br/> ')

        return values;
    }

    const handleMainTabValidate = () => {
        let valid = false;
        let main = ['name', 'email', 'tel', 'group_id', 'discount', 'client_card'];

        if (validations) {
            main.map(t => {
                if (validations[t] && state.mainTabId !== 'main') {
                    valid = true
                }
            })
        }

        return valid;
    }

    const handleExtraTabValidate = (tab) => {
        let valid = false;
        let main = ['source_id', 'client_id', 'recom', 'description', 'lang_id'];

        if (validations) {
            main.map(t => {
                if (validations[t] && state.mainTabId !== 'extra') {
                    valid = true
                }
            })
        }

        return valid;
    }

    const handleInvoicesTabValidate = (tab) => {
        let valid = false;
        let main = ['company_name', 'email_invoices', 'bulstat', 'vat_num', 'mol', 'receiver_name', 'town', 'address', 'zip', 'method_id'];

        if (validations) {
            main.map(t => {
                if (validations[t] && state.mainTabId !== 'invoices') {
                    valid = true
                }
            })
        }

        return valid;
    }

    const handlePrivateTabValidate = () => {
        let valid = false;
        let main = ['client_auth', 'client_pass'];

        if (validations) {
            main.map(t => {
                if (validations[t] && state.mainTabId !== 'private') {
                    valid = true
                }
            })
        }

        return valid;
    }

    const handleAttach = e => {
        let file = e.target.files[0];
        let url = URL.createObjectURL(file);

        file.id = Math.random().toString(7).substring(2);
        file.was_recently_attached = true;
        file.url = url;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                file: file
            }
        }));
    }

    const handleShowAttach = e => {
        e.preventDefault();

        inputFileRef.current.click();
    }

    const checkVatNumber = e => {
        e.preventDefault();

        let num = state.data.vat_num;

        if (!num) {
            return;
        }

        setVies(prev => ({
            ...prev,
            loading: true,
        }));

        Api.get('vies/search', {
            params: {
                vat_number: num
            }
        }).then(res => {
            if (res.data.valid) {
                let modal = props.viesModalRef.current;

                if (!modal) {
                    return;
                }

                let data = res.data;

                props.hide(() => {
                    modal.open(data);
                });

                modal.onClose(() => {
                    props.show();
                });

                modal.onSuccess(() => {
                    let address = parseViesAddress(data);

                    setState(prev => ({
                        ...prev,
                        data: {
                            ...prev.data,
                            company_name: data.name,
                            town: address.town,
                            address: address.address,
                            zip: address.zip
                        }
                    }))
                });
            }
        }).finally(() => {
            setVies(prev => ({
                ...prev,
                loading: false,
            }));
        })
    }

    const parseViesAddress = data => {
        let full = data.address;

        let town = (full.split(',').pop().split(/(\d)/)[0] || '').trim();

        ['гр.', 'с.',].forEach(el => {
            if (town.startsWith(el)) {
                town = town.replace(el, '');
            }
        });

        let zip = full.split(' ').pop();

        let address = (full.split('обл')[0] || '').trim();

        return {
            town: town,
            zip: zip,
            address: address,
        }
    }

    return (
        <form ref={formRef} onSubmit={handleSave}>
            <Tab.Container activeKey={state.mainTabId} onSelect={key => setMainTab(key)}>
                <Row>
                    <Col sm={3} >
                        <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                                <Nav.Link style={{ color: handleMainTabValidate() ? 'red' : '' }} eventKey="main">Основни данни</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link style={{ color: handleExtraTabValidate() ? 'red' : '' }} eventKey="extra">Допълнителни данни</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link style={{ color: handleInvoicesTabValidate() ? 'red' : '' }} eventKey="invoices">За фактура</Nav.Link>
                            </Nav.Item>
                            {state.data.id &&
                                <Nav.Item>
                                    <Nav.Link eventKey="addresses">За доставка</Nav.Link>
                                </Nav.Item>
                            }
                            <Nav.Item>
                                <Nav.Link style={{ color: handlePrivateTabValidate() ? 'red' : '' }} eventKey="private">Служебни</Nav.Link>
                            </Nav.Item>

                            <Nav.Item>
                                <Nav.Link eventKey="image">Изображение</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col sm={9}>
                        <Tab.Content>
                            <Tab.Pane eventKey="main">

                                <FloatingLabel label="Име на клиент" className="mb-3">
                                    <Form.Control
                                        type="text"
                                        name={`name`}
                                        autoComplete="new-password"
                                        value={state.data.name || ''}
                                        placeholder="Име на клиент"
                                        onChange={(e) => handleInputChange(e)}
                                        isInvalid={Boolean(validations?.name)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {validations?.name && (validations?.name[0] || validations?.name)}
                                    </Form.Control.Feedback>
                                </FloatingLabel>

                                <Row>
                                    <Col>
                                        <FloatingLabel label="Е-поща" className="mb-3">
                                            <Form.Control
                                                type="text"
                                                name={`email`}
                                                autoComplete="new-password"
                                                value={state.data.email || ''}
                                                placeholder="Е-поща"
                                                onChange={(e) => handleInputChange(e)}
                                                isInvalid={Boolean(validations?.email)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {validations?.email && (validations?.email[0] || validations?.email)}
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col>
                                        <FloatingLabel label="Телефон" className="mb-3">
                                            <Form.Control
                                                type="text"
                                                name={`tel`}
                                                autoComplete="new-password"
                                                value={state.data.tel || ''}
                                                placeholder="Телефон"
                                                onChange={(e) => handleInputChange(e)}
                                                isInvalid={Boolean(validations?.tel)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {validations?.tel && (validations?.tel[0] || validations?.tel)}
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <FloatingLabel label="Клиентска група">
                                            <Form.Select
                                                name="group_id"
                                                value={state.data.group_id || ''}
                                                onChange={handleInputChange}
                                            >
                                                {clientGroups.map(c =>
                                                    <option key={c.id} value={c.id}>{c.name}</option>
                                                )}
                                            </Form.Select>
                                            <HelpInfo placement="top">Групира клиентите по общ признак. Използва се за филтриране в справки</HelpInfo>
                                        </FloatingLabel>
                                    </Col>
                                    <Col>
                                        <FloatingLabel label="Отстъпка / Надценка" className="mb-3">
                                            <Form.Control
                                                type="text"
                                                name={`discount`}
                                                autoComplete="new-password"
                                                value={state.data.discount || ''}
                                                placeholder="Отстъпка / Надценка"
                                                onChange={(e) => handleDiscountChange(e)}
                                                isInvalid={Boolean(validations?.discount)}
                                            />
                                            <HelpInfo placement="top">
                                                минус - отстъпка в проценти
                                                <br />
                                                плюс - надценка в проценти
                                            </HelpInfo>
                                            <Form.Control.Feedback type="invalid">
                                                {validations?.discount && (validations?.discount[0] || validations?.discount)}
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <FloatingLabel label="Клиентска карта" className="mb-3">
                                            <Form.Control
                                                type="text"
                                                name={`client_card`}
                                                autoComplete="new-password"
                                                value={state.data.client_card || ''}
                                                placeholder="Клиентска карта"
                                                onChange={(e) => handleInputChange(e)}
                                                isInvalid={Boolean(validations?.client_card)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {validations?.client_card && (validations?.client_card[0] || validations?.client_card)}
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col className="mt-2">
                                        <Button variant="primary" onClick={e => handleGenerateCode(e)}>
                                            Генериране
                                        </Button>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <FloatingLabel label="Портфейл лимит" className="mb-3">
                                            <Form.Control
                                                type="number"
                                                name={`credit_limit`}
                                                autoComplete="new-password"
                                                value={state.data.credit_limit || ''}
                                                placeholder="Портфейл лимит"
                                                onChange={(e) => handleInputChange(e)}
                                                isInvalid={Boolean(validations?.credit_limit)}
                                            />
                                            <HelpInfo placement="top">
                                                Максимална сума, която клиента може да използва при задлъжняване
                                            </HelpInfo>
                                            <Form.Control.Feedback type="invalid">
                                                {validations?.credit_limit && (validations?.credit_limit[0] || validations?.credit_limit)}
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col className="mb-3">
                                        <Form.Check
                                            type="switch"
                                            name="allow_client_nocard"
                                            value={1}
                                            label="Използване без карта"
                                            checked={Number(state.data?.allow_client_nocard) === 1}
                                            onChange={handleCheckboxChange}
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col className="mb-3">
                                        <Form.Check
                                            type="switch"
                                            name="allow_credit_nocard"
                                            value={1}
                                            label="Портфейл без карта"
                                            checked={Number(state.data?.allow_credit_nocard) === 1}
                                            onChange={handleCheckboxChange}
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col className="mb-3">
                                        <Form.Check
                                            type="switch"
                                            name="allow_discount_nocard"
                                            value={1}
                                            label="Отстъпка без карта"
                                            checked={Number(state.data?.allow_discount_nocard) === 1}
                                            onChange={handleCheckboxChange}
                                        />
                                    </Col>
                                </Row>


                                <Row>
                                    <Col>
                                        <Form.Check
                                            type="switch"
                                            name="show_additional_info"
                                            value={1}
                                            label="Показване на допълнителна информация в онлайн магазина"
                                            checked={Number(state.data?.show_additional_info) === 1}
                                            onChange={handleCheckboxChange}
                                        />
                                    </Col>
                                </Row>

                            </Tab.Pane>

                            <Tab.Pane eventKey="extra">
                                <Col className="mb-3">
                                    <FloatingLabel label="Източник">
                                        <Form.Select
                                            name="source_id"
                                            value={state.data.source_id || ''}
                                            onChange={handleInputChange}
                                        >
                                            <option value=""></option>
                                            {clientSources.map(c =>
                                                <option key={c.id} value={c.id}>{c.translation?.name}</option>
                                            )}
                                        </Form.Select>
                                        <HelpInfo placement="top">От къде е възникнал контакта с този клиент</HelpInfo>
                                    </FloatingLabel>
                                </Col>

                                <Col className="mb-3">
                                    <Autocomplete
                                        url="autocomplete/clients"
                                        inputPlaceholder="Препоръчан от"
                                        inputIdName={`client_id`}
                                        renderText={data => data?.name}
                                        inputValue={state.data.client_name || ''}
                                        inputIdValue={state.data.client_id || ''}
                                        error={Boolean(validations?.client_id)}
                                        helperText={validations?.client_id && (validations?.client_id[0] || validations?.client_id)}

                                        onChange={data => handleClientChange(data)}
                                    />
                                </Col>

                                <FloatingLabel label="Препоръчан" className="mb-3">
                                    <Form.Control
                                        as="textarea"
                                        style={{ height: '100px' }}
                                        name={`recom`}
                                        autoComplete="new-password"
                                        value={state.data.recom || ''}
                                        placeholder="Препоръчан"
                                        onChange={(e) => handleInputChange(e)}
                                        isInvalid={Boolean(validations?.recom)}
                                    />
                                    <HelpInfo placement="top">Свободен текст за източника</HelpInfo>
                                    <Form.Control.Feedback type="invalid">
                                        {validations?.recom && (validations?.recom[0] || validations?.recom)}
                                    </Form.Control.Feedback>
                                </FloatingLabel>

                                <FloatingLabel label="Описание" className="mb-3">
                                    <Form.Control
                                        as="textarea"
                                        style={{ height: '100px' }}
                                        name={`description`}
                                        autoComplete="new-password"
                                        value={state.data.description || ''}
                                        placeholder="Описание"
                                        onChange={(e) => handleInputChange(e)}
                                        isInvalid={Boolean(validations?.description)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {validations?.description && (validations?.description[0] || validations?.description)}
                                    </Form.Control.Feedback>
                                </FloatingLabel>

                                <Col>
                                    <FloatingLabel label="Език">
                                        <Form.Select
                                            name="lang_id"
                                            value={state.data.lang_id || ''}
                                            onChange={handleInputChange}
                                        >
                                            <option value="">Език по подразбиране</option>
                                            {langs.data.map(c =>
                                                <option key={c.id} value={c.id}>{c.name}</option>
                                            )}
                                        </Form.Select>
                                        <HelpInfo placement="top">Предпочитан език от клиента</HelpInfo>
                                    </FloatingLabel>
                                </Col>

                            </Tab.Pane>

                            <Tab.Pane eventKey="invoices">
                                <FloatingLabel label="Име на компания" className="mb-3">
                                    <Form.Control
                                        type="text"
                                        name={`company_name`}
                                        autoComplete="new-password"
                                        value={state.data.company_name || ''}
                                        placeholder="Име на компания"
                                        onChange={(e) => handleInputChange(e)}
                                        isInvalid={Boolean(validations?.company_name)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {validations?.company_name && (validations?.company_name[0] || validations?.company_name)}
                                    </Form.Control.Feedback>
                                </FloatingLabel>

                                <FloatingLabel label="E-поща за фактури" className="mb-3">
                                    <Form.Control
                                        type="text"
                                        name={`email_invoices`}
                                        autoComplete="new-password"
                                        value={state.data.email_invoices || ''}
                                        placeholder="E-поща за фактури"
                                        onChange={(e) => handleInputChange(e)}
                                        isInvalid={Boolean(validations?.email_invoices)}
                                    />
                                    <HelpInfo placement="top">Използва се за изпращане на фактури</HelpInfo>
                                    <Form.Control.Feedback type="invalid">
                                        {validations?.email_invoices && (validations?.email_invoices[0] || validations?.email_invoices)}
                                    </Form.Control.Feedback>
                                </FloatingLabel>

                                <Row>
                                    <Col>
                                        <FloatingLabel label="ЕИК" className="mb-3">
                                            <Form.Control
                                                type="text"
                                                name={`bulstat`}
                                                autoComplete="new-password"
                                                value={state.data.bulstat || ''}
                                                placeholder="ЕИК"
                                                onChange={(e) => handleInputChange(e)}
                                                isInvalid={Boolean(validations?.bulstat)}
                                            />
                                            <HelpInfo placement="top">БУЛСТАТ</HelpInfo>
                                            <Form.Control.Feedback type="invalid">
                                                {validations?.bulstat && (validations?.bulstat[0] || validations?.bulstat)}
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>

                                    <Col>
                                        <FloatingLabel label="ДДС №" className="mb-3">
                                            <Form.Control
                                                type="text"
                                                name={`vat_num`}
                                                autoComplete="new-password"
                                                value={state.data.vat_num || ''}
                                                placeholder="ДДС №"
                                                onChange={(e) => handleInputChange(e)}
                                                isInvalid={Boolean(validations?.vat_num)}
                                                style={{
                                                    paddingRight: '50px'
                                                }}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {validations?.vat_num && (validations?.vat_num[0] || validations?.vat_num)}
                                            </Form.Control.Feedback>

                                            {!Boolean(validations?.vat_num) &&
                                                <SaveButton
                                                    size="sm"
                                                    variant="primary"
                                                    style={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        right: '15px',
                                                        transform: 'translate(0, -50%)',
                                                        lineHeight: '1'
                                                    }}
                                                    title="Изтегли данните от VIES"
                                                    onClick={checkVatNumber}
                                                    loading={vies.loading}
                                                >
                                                    <FileArrowDownFill />
                                                </SaveButton>
                                            }
                                        </FloatingLabel>
                                    </Col>

                                    <Col>
                                        <FloatingLabel label="МОЛ" className="mb-3">
                                            <Form.Control
                                                type="text"
                                                name={`mol`}
                                                autoComplete="new-password"
                                                value={state.data.mol || ''}
                                                placeholder="МОЛ"
                                                onChange={(e) => handleInputChange(e)}
                                                isInvalid={Boolean(validations?.mol)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {validations?.mol && (validations?.mol[0] || validations?.mol)}
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                </Row>

                                <FloatingLabel label="Получател" className="mb-3">
                                    <Form.Control
                                        type="text"
                                        name={`receiver_name`}
                                        autoComplete="new-password"
                                        value={state.data.receiver_name || ''}
                                        placeholder="Получател"
                                        onChange={(e) => handleInputChange(e)}
                                        isInvalid={Boolean(validations?.receiver_name)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {validations?.receiver_name && (validations?.receiver_name[0] || validations?.receiver_name)}
                                    </Form.Control.Feedback>
                                </FloatingLabel>

                                <Row>
                                    <Col>
                                        <FloatingLabel label="Град" className="mb-3">
                                            <Form.Control
                                                type="text"
                                                name={`town`}
                                                autoComplete="new-password"
                                                value={state.data.town || ''}
                                                placeholder="Град"
                                                onChange={(e) => handleInputChange(e)}
                                                isInvalid={Boolean(validations?.town)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {validations?.town && (validations?.town[0] || validations?.town)}
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>

                                    <Col>
                                        <FloatingLabel label="Адрес" className="mb-3">
                                            <Form.Control
                                                type="text"
                                                name={`address`}
                                                autoComplete="new-password"
                                                value={state.data.address || ''}
                                                placeholder="Адрес"
                                                onChange={(e) => handleInputChange(e)}
                                                isInvalid={Boolean(validations?.address)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {validations?.address && (validations?.address[0] || validations?.address)}
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <FloatingLabel label="Пощенски код" className="mb-3">
                                            <Form.Control
                                                type="text"
                                                name={`zip`}
                                                value={state.data.zip || ''}
                                                autoComplete="new-password"
                                                placeholder="Град"
                                                onChange={(e) => handleInputChange(e)}
                                                isInvalid={Boolean(validations?.zip)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {validations?.zip && (validations?.zip[0] || validations?.zip)}
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>

                                    <Col>
                                        <FloatingLabel label="Начин на плащане">
                                            <Form.Select
                                                name="method_id"
                                                value={state.data.method_id || ''}
                                                onChange={handleInputChange}
                                            >
                                                <option value=""></option>
                                                {clientMethods.map(c =>
                                                    <option key={c.id} value={c.id}>{c.translation?.name || c.name || ''}</option>
                                                )}
                                            </Form.Select>
                                            <HelpInfo placement="top">Начин на плащане по подразбиране за сметки. Може да се променя за конкретна операция</HelpInfo>
                                        </FloatingLabel>
                                    </Col>
                                </Row>

                            </Tab.Pane>

                            <Tab.Pane eventKey="addresses">
                                {state.data.addresses?.length == 0 ?
                                    <Alert variant="info">
                                        Няма намерена информация!
                                    </Alert> :
                                    <Row>
                                        {state.data.addresses?.map(a =>
                                            <Col key={a.id} md={6}>
                                                <Card className="mb-3 ml-3">
                                                    <Card.Body>
                                                        <Card.Title style={{ fontSize: '16px' }}>{a.delivery_type_id == 1 ? 'Адрес на клиент' : a.delivery_type_id == 2 ? 'Офис на куриер' : 'Търговски обект'}</Card.Title>
                                                        <Card.Text>
                                                            <span dangerouslySetInnerHTML={{ __html: handleAddressPreview(a) }} />
                                                        </Card.Text>
                                                        <Card.Link onClick={e => handleEdit(a.id)}>Редакция</Card.Link>
                                                        <Card.Link onClick={e => handleDelete(a.id)}>Изтриване</Card.Link>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        )}
                                    </Row>}

                                <div className="buttons">
                                    <Button variant="outline-dark" onClick={e => handleAdd()}>
                                        Добави
                                    </Button>
                                </div>
                            </Tab.Pane>

                            <Tab.Pane eventKey="private">
                                <FloatingLabel label="Потребителско име" className="mb-3">
                                    <Form.Control
                                        type="text"
                                        name={`client_auth`}
                                        value={state.data.client_auth || ''}
                                        autoComplete="new-password"
                                        placeholder="Получател"
                                        onChange={(e) => handleInputChange(e)}
                                        isInvalid={Boolean(validations?.client_auth)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {validations?.client_auth && (validations?.client_auth[0] || validations?.client_auth)}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                                <FloatingLabel label="Парола" className="mb-3">
                                    <Form.Control
                                        type="password"
                                        name={`client_pass`}
                                        value={state.data.client_pass || ''}
                                        autoComplete="new-password"
                                        placeholder="Получател"
                                        onChange={(e) => handleInputChange(e)}
                                        isInvalid={Boolean(validations?.client_pass)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {validations?.client_pass && (validations?.client_pass[0] || validations?.client_pass)}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                                <FloatingLabel label="Повтори парола" className="mb-3">
                                    <Form.Control
                                        type="password"
                                        name={`client_pass_confirmation`}
                                        autoComplete="new-password"
                                        placeholder="Получател"
                                        onChange={(e) => handleInputChange(e)}
                                        isInvalid={Boolean(validations?.client_pass)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {validations?.client_pass && (validations?.client_pass[0] || validations?.client_pass)}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Tab.Pane>

                            <Tab.Pane eventKey="image">
                                {!state.data.file?.url ? <Alert variant="info">
                                    Няма добавено изображение!
                                </Alert> :

                                    <div className="mb-3">
                                        <>
                                            <div className="file">
                                                <img style={{ maxHeight: '100px' }} src={state.data.file?.url} onError={e => e.target.classList.add('hidden')} />
                                            </div>

                                            <div className="validate">
                                                {validations?.image}
                                            </div>
                                        </>
                                    </div>
                                }

                                <Button variant="outline-dark" size="sm" onClick={handleShowAttach}>
                                    Прикачете изображение
                                </Button>

                                <input ref={inputFileRef} type="file" accept=".png,.jpeg,.jpg" onChange={handleAttach} hidden />
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </form>
    )
}

export default forwardRef(Index);