import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import { Modal, Button, Tabs, Tab, FloatingLabel, Form, Row, Col } from 'react-bootstrap';

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//providers
import { useLanguageContext } from 'providers/Language'

//misc
import Autocomplete from 'components/misc/Autocomplete';
import SaveButton from 'components/misc/Button'
import Info from 'components/modals/Info';
import HelpInfo from 'components/misc/Info'

function AddOrEdit(props, ref) {

    const langs = useLanguageContext();

    const defaultData = {
        langs: {},
    }

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        edit: false,
        data: defaultData,
        tabId: langs.data[0]?.id,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null
    });

    const [emailTemplates, setEmailTemplates] = useState([]);

    const [validations, setValidations] = useValidation();

    const validationModalRef = useRef(null);

    useImperativeHandle(ref, () => ({
        add: () => {
            handleShow();
        },
        edit: (id) => {
            edit(id);
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        }
    }));

    useEffect(() => {
        Api.get('orders-statuses/email-templates')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setEmailTemplates(res.data);
                }
            });
    }, []);

    useEffect(() => {
        loadData();
    }, [state.edit]);

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const handleShow = () => {
        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
        }));
        show();
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
        }));

        setValidations(null);

        hide();
    }

    const edit = id => {
        setState(prev => ({
            ...prev,
            id: id,
            edit: new Date().getTime()
        }));

        show();
    }

    const loadData = () => {
        if (!state.id) {
            return;
        }

        Api.get('/orders-statuses/show?id=' + state.id)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data
                }))
            });
    }


    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = state.id ? '/orders-statuses/update' : '/orders-statuses/add';

        let data = new FormData(e.target);

        if (state.id) {
            data.append('id', state.id)
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (typeof state.onSuccess === 'function') {
                state.onSuccess(res.data)
            }

            hide()
        }).catch(error => {
            const _err = error.response;
            if (_err && _err.status && _err.status === 422) {
                hide(() => {
                    let modal = validationModalRef.current;

                    if (modal) {
                        modal.open();

                        modal.onClose(() => {
                            setValidations(_err.data.errors)
                            show();
                        });
                    }
                });
            }

        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }));
        });
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleTabChange = id => {
        setState(prev => ({
            ...prev,
            tabId: Number(id)
        }));
    }

    const handleCheckboxChange = e => {
        const name = e.target.name;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: prev.data[name] ? false : true
            }
        }));
    }

    const handleEditRolesChange = (data) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                edit_roles: data,
            }
        }));
    }

    const handleRolesStatusChange = (data) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                roles_status: data,
            }
        }));
    }

    const handleAccountRoleChange = (data) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                roles_accounts: data,
            }
        }));
    }

    const handleRuleChange = (data) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rules: data,
            }
        }));
    }

    return (
        <>
            <Info
                ref={validationModalRef}
                mainMessage="Моля, попълнете всички задължителни полета, означени с червен цвят!"
            />

            <Modal size="lg" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <form onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Статус за клиентска заявка
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Col className="mb-3">
                            <FloatingLabel label="Име*">
                                <Form.Control
                                    type="text"
                                    placeholder="Име*"
                                    name="name"
                                    value={state.data.name || ''}
                                    onChange={handleInputChange}
                                    isInvalid={Boolean(validations && validations.name)}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {validations && validations.name && (validations.name[0] || validations.name)}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Col>

                        <Col className="mb-3">
                            <Tabs
                                activeKey={state.tabId}
                                onSelect={handleTabChange}
                                className="mb-3"
                            >
                                {langs.data.map(lang =>
                                    <Tab key={lang.id} eventKey={lang.id} title={lang.name} tabClassName={validations && validations.langs && validations.langs[lang.id] ? 'validate' : ''} />
                                )}
                            </Tabs>

                            {langs.data.map(lang =>
                                <div key={lang.id} className="mb-3" style={{ display: state.tabId === lang.id ? 'block' : 'none' }}>
                                    <Col>
                                        <FloatingLabel label="Публично име" className="mb-3">
                                            <Form.Control
                                                type="text"
                                                name={`langs[${lang.id}][public_name]`}
                                                placeholder="Име*"
                                                value={state.data.langs[lang.id]?.public_name || state.data.name || ''}
                                                onChange={handleInputChange}
                                                isInvalid={Boolean(validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].public_name)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].public_name && (validations.langs[lang.id].public_name[0] || validations.langs[lang.id].public_name)}
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col>
                                        <FloatingLabel label="Описание" className="mb-3">
                                            <Form.Control
                                                as={'textarea'}
                                                name={`langs[${lang.id}][description]`}
                                                placeholder="Описание"
                                                style={{ height: '100px' }}
                                                value={state.data.langs[lang.id]?.description || ''}
                                                onChange={handleInputChange}
                                                isInvalid={Boolean(validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].description)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {validations?.langs && validations.langs[lang.id] && validations.langs[lang.id].description && (validations.langs[lang.id].description[0] || validations.langs[lang.id].description)}
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                </div>
                            )}
                        </Col>

                        <Row className="mb-3">
                            <Col>
                                <Autocomplete
                                    multiple
                                    url="autocomplete/roles"
                                    inputPlaceholder="Роли-редактиране"
                                    selectedValues={state.data.edit_roles}
                                    inputIdName={`edit_role_id`}
                                    renderText={data => data?.name || ''}
                                    onChange={data => handleEditRolesChange(data)}
                                    helpInfo="Роли, които имат право да редактират клиентска заявка с текущия статус"
                                />
                            </Col>

                            <Col>
                                <Autocomplete
                                    multiple
                                    url="autocomplete/roles"
                                    inputPlaceholder="Роли-статус"
                                    inputIdName={`status_role_id`}
                                    selectedValues={state.data.roles_status}
                                    renderText={data => data?.name || ''}
                                    onChange={data => handleRolesStatusChange(data)}
                                    helpInfo="Роли, които имат право да променят статуса на клиентска заявка"
                                />
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col>
                                <Autocomplete
                                    multiple
                                    url="autocomplete/roles"
                                    inputPlaceholder="Роли-сметки"
                                    selectedValues={state.data.edit_roles}
                                    inputIdName={`role_account_id`}
                                    renderText={data => data?.name || ''}
                                    onChange={data => handleAccountRoleChange(data)}
                                    helpInfo="Роли, които имат право да създават сметка от клиентска заявка с този статус"
                                />
                            </Col>

                            <Col>
                                <Autocomplete
                                    multiple
                                    url="autocomplete/rules"
                                    inputPlaceholder="Задължителни полета"
                                    selectedValues={state.data.rules}
                                    inputIdName={`rule_id`}
                                    renderText={data => data?.name || ''}
                                    onChange={data => handleRuleChange(data)}
                                    helpInfo="Списък от полета, задължителни при попълване на клиентската заявка"
                                />
                            </Col>
                        </Row>

                        <Col className="mb-3" style={{ display: 'flex' }}>
                            <Form.Check
                                type="switch"
                                name="can_edit_rows"
                                label="Редактиране на редове"
                                value="1"
                                checked={Number(state.data?.can_edit_rows) === 1}
                                onChange={handleCheckboxChange}
                            />
                            <HelpInfo marginLeft="10px" placement="top">Определя дали артикулите в клиентски заявки с този статус могат да бъдат редактирани.</HelpInfo>
                        </Col>

                        <Col className="mb-3" style={{ display: 'flex' }}>
                            <Form.Check
                                type="switch"
                                name="ask_for_move"
                                value="1"
                                label="Питане за прехвърляне"
                                checked={Number(state.data?.ask_for_move) === 1}
                                onChange={handleCheckboxChange}
                            />
                            <HelpInfo marginLeft="10px" placement="top">Определя дали при смяна на този статус да пита за смяна на търговския обект, в който ще се обработва.</HelpInfo>
                        </Col>

                        <Col className="mb-3" style={{ display: 'flex' }}>
                            <Form.Check
                                type="switch"
                                name="reserve_amount"
                                value="1"
                                label="Резервиране на количества"
                                checked={Number(state.data?.reserve_amount) === 1}
                                onChange={handleCheckboxChange}
                            />
                            <HelpInfo marginLeft="10px" placement="top">Определя дали резервираме количествата на артикулите от клиентски заявки с този статус.</HelpInfo>
                        </Col>

                        <Col className="mb-3">
                            <Form.Label>Цвят</Form.Label>
                            <Form.Control
                                type="color"
                                name="color"
                                value={state.data.color || ''}
                                onChange={handleInputChange}
                                isInvalid={Boolean(validations && validations.color)}
                            />
                            <Form.Control.Feedback type="invalid">
                                {validations && validations.color && (validations.color[0] || validations.color)}
                            </Form.Control.Feedback>
                        </Col>

                        <Row className="mb-3">
                            <Col>
                                <FloatingLabel label="Email шаблон за клиента">
                                    <Form.Select
                                        name="mail_template_client"
                                        value={state.data.mail_template_client || ''}
                                        onChange={handleInputChange}
                                    >
                                        <option></option>
                                        {emailTemplates.map(t =>
                                            <option value={t.id}>{t.name || ''}</option>
                                        )}
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                            <Col>
                                <FloatingLabel label="Email шаблон за фирмата">
                                    <Form.Select
                                        name="mail_template_company"
                                        value={state.data.mail_template_company || ''}
                                        onChange={handleInputChange}
                                    >
                                        <option></option>
                                        {emailTemplates.map(t =>
                                            <option value={t.id}>{t.name || ''}</option>
                                        )}

                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                        </Row>

                        <Col>
                            <FloatingLabel label="Тежест*">
                                <Form.Control
                                    type="number"
                                    placeholder="Тежест*"
                                    min={1}
                                    name="weight"
                                    value={state.data.weight || ''}
                                    onChange={handleInputChange}
                                    isInvalid={Boolean(validations && validations.weight)}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {validations && validations.weight && (validations.weight[0] || validations.weight)}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Col>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            className="save"
                        />
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default forwardRef(AddOrEdit);
