function NotFound() {
    return (
        <div style={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <div style={{ textAlign: 'center' }}>
                <h1
                    style={{
                        fontSize: '12rem'
                    }}
                >
                    404
                </h1>
                <h2
                    style={{
                        fontSize: '3rem',
                        fontWeight: '100'
                    }}
                >
                    Тази страница не съществува!
                </h2>
            </div>
        </div>
    )
}

export default NotFound;