import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import moment from "moment"
import { FloatingLabel, Form, Dropdown, Row, Col } from 'react-bootstrap'

//providers
import { useAuthDataContext } from "providers/Auth"

//helpers
import { useQuery } from 'helpers/Url'
import Api from 'helpers/Api'
import Loader from 'components/misc/Loader'
import Autocomplete from 'components/misc/Autocomplete'
import DatePicker from 'components/partials/filter/DatePicker'

function Filter(props) {
    const history = useNavigate()
    const query = useQuery()

    const auth = useAuthDataContext();

    const [depots, setDepots] = useState([]);
    const [statuses, setStatuses] = useState([]);

    const [filters, setFilters] = useState({
        show: false,
    });

    useEffect(() => {

    }, []);

    const handleDateChange = (date, name) => {
        props.handleSearch(name, moment(date).format('YYYY-MM-DD'));
    }

    const handleSearch = e => {
        const name = e.target.name
        const value = e.target.value

        props.handleSearch(name, value)
    }

    const handleFromDateChange = e => {
        const name = e.target.name
        const value = e.target.value

        let date = value ? moment(value).format('YYYY-MM-DD') : '';

        props.handleSearch({
            created_from_date: date,
            created_to_date: date,
        });
    }

    const handleToDateChange = e => {
        const value = e.target.value

        let date = value ? moment(value).format('YYYY-MM-DD') : '';

        if (date && props.filter.created_from_date > date) {
            return;
        }

        props.handleSearch({
            created_to_date: date,
        });
    }

    const handleChangeUser = data => {
        let checked = data.map(d => d.id);

        props.handleSearch('creator_id', checked);
    }

    const handleChangeClient = data => {
        props.handleSearch({
            client_id: data?.id || '',
        });
    }

    const handleChangeStore = data => {
        let checked = data.map(d => d.id);

        props.handleSearch('store_id', checked);
    }

    const handleChangeMethod = data => {
        let checked = data.map(d => d.id);

        props.handleSearch('paymethod_id', checked);
    }

    return (
        <div className="row page-filter">
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <DatePicker
                    placeholder="Период от"
                    name="created_from_date"
                    value={props.filter.created_from_date || ''}
                    onChange={handleFromDateChange}
                />
            </div>
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <DatePicker
                    placeholder="Период до"
                    name="created_to_date"
                    value={props.filter.created_to_date || ''}
                    onChange={handleToDateChange}
                />
            </div>
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <Autocomplete
                    inputPlaceholder="Персонал"
                    url="autocomplete/admins"
                    inputIdName="creator_id[]"
                    selectedIds={props.filter.creator_id || []}
                    onChange={handleChangeUser}
                    multiple
                />
            </div>
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <Autocomplete
                    inputPlaceholder="Клиент"
                    url="autocomplete/clients"
                    inputIdName="client_id"
                    inputIdValue={props.filter.client_id || ''}
                    onChange={handleChangeClient}
                    onInputChange={handleChangeClient}
                />
            </div>
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <Autocomplete
                    url="autocomplete/stores"
                    params={{
                        permission: 'payments_index'
                    }}
                    inputPlaceholder="Търговски обект"
                    selectedIds={props.filter.store_id || []}
                    renderText={data => data?.translation?.name || data?.name}
                    onChange={data => handleChangeStore(data)}
                    multiple
                />
            </div>
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <Autocomplete
                    url="autocomplete/payment-methods"
                    inputPlaceholder="Начин на плащане"
                    selectedIds={props.filter.paymethod_id || []}
                    renderText={data => data?.name}
                    onChange={data => handleChangeMethod(data)}
                    multiple
                />
            </div>
        </div>
    )
}

export default Filter;
