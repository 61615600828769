import { useEffect, useRef, useState } from 'react';
import Api from 'helpers/Api';
import { useQuery } from 'helpers/Url';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Alert, Button, Table, Form } from 'react-bootstrap';
import Layout from 'components/layout/Layout';
import Success from 'components/modals/Success';

//misc
import Item from './partials/Item';
import Loader from 'components/misc/Loader';
import SaveButton from 'components/misc/Button';
import { usePermissionsContext } from 'providers/Permissions';
import TableHeader from 'components/misc/TableHeader';
import NoDataFound from 'components/misc/NoDataFound';
import Filter from './partials/Filter';

let timeout;

function Index() {
    const successModalRed = useRef(null);
    const formRef = useRef(null);
    const tableRef = useRef(null);

    const permission = usePermissionsContext();

    const [state, setState] = useState({
        loading: true,
        loadingButton: false,
        refresh: false,
        data: [],
        roles: [],
        checkedRole: {},
        checkedGroup: {},
        checkedPermission: {},
        loadData: false,
        filter: {

        }
    });

    const [roles, setRoles] = useState([]);
    const [permissionsRoles, setPermissionsRoles] = useState([]);

    useEffect(() => {
        Api.get('permissions/get-roles')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setRoles(res.data);

                    setState(prev => ({
                        ...prev,
                        loadData: new Date().getTime()
                    }));
                }
            })

        Api.get('permissions/roles')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setPermissionsRoles(res.data);
                    setState(prev => ({
                        ...prev,
                        loadData: new Date().getTime()
                    }));
                }
            })

        loadData();
    }, [state.refresh])

    useEffect(() => {
        handlePermissionChecked()
    }, [state.loadData])

    const loadData = () => {

        loading(true);

        let url = 'permissions/get';

        Api.get(url)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data.items,
                    loadData: new Date().getTime()
                }));

                loading(false);
            });
    }

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }));
    }

    const refresh = () => {
        setState(prev => ({
            ...prev,
            refresh: new Date().getTime(),
        }));
    }

    const handleSave = e => {
        e.preventDefault()
        loadingButton(true)

        if (state.loading) {
            return;
        }

        let form = formRef.current;

        let data = new FormData(form);

        Api.post('permissions/set', data)
            .then(res => {
                let modal = successModalRed.current;
                modal.open();
                modal.onSuccess(() => {
                    loadingButton(false)
                })
            }).catch(error => {
                // 
            })
    }

    const loadingButton = (loading) => {
        setState(prev => ({
            ...prev,
            loadingButton: Boolean(loading)
        }));
    }

    const handlePermissionChecked = () => {
        permission.handlePermissionChecked(state.data, roles, permissionsRoles)
    }

    const handleRoleChange = (role_id) => {
        let roleName = 'role-' + role_id

        let obj = permission.state.checkedPermission
        let groupObj = permission.state.checkedGroup
        let roleObj = permission.state.checkedRole

        roleObj[roleName] = !permission.state.checkedRole[roleName]
        state.data.map(data => {
            let groupName = 'group-' + data.id + '-' + role_id
            data.permissions.map(p => {
                let name = 'permission-' + p.id + '-' + role_id
                obj[name] = roleObj[roleName];
                groupObj[groupName] = roleObj[roleName];
            })
        })


        let updateData = ({
            checkedPermission: obj,
            checkedGroup: groupObj,
            checkedRole: roleObj
        })

        permission.updateState(updateData)
    }

    // Search
    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout);

        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [key]: val,
            },
        }));
    }


    return (
        <>

            <Success
                ref={successModalRed}
                mainMessage="Данните бяха запазени успешно"
            />

            <Layout>
                <div className="container-fluid">

                    <div className="page-head">
                        <h1 className="display-6">
                            Права по роли
                        </h1>

                        <div className="buttons">

                            <SaveButton
                                loading={state.loadingButton}
                                className="save"
                                onClick={handleSave}
                            >
                                Запази данните
                            </SaveButton>

                        </div>
                    </div>

                    <br />

                    <div className="panel">
                        <Filter
                            filter={state.filter}
                            handleSearch={handleSearch}
                        />

                        <Alert>
                            Настройка на глобални права, важащи за всички търоговски обекти. Ако искате да зададете отделни права за определен търговски обект, използвате "Права по търговски обекти"
                        </Alert>

                        {state.loading
                            ?
                            <Loader />
                            :
                            state.data.length === 0 ?
                                <NoDataFound />
                                :
                                <>
                                    <Form ref={formRef}>
                                        <Table className="xxl" striped responsive hover ref={tableRef}>
                                            <TableHeader
                                                tableRef={tableRef}
                                            >
                                                <th style={{ width: '60%' }}>Име</th>
                                                {roles.map(r =>
                                                    <th key={r.id}>
                                                        <Form.Check
                                                            key={r.id}
                                                            type="switch"
                                                            name={`role-${r.id}`}
                                                            onChange={() => handleRoleChange(r.id)}
                                                            label={r.name}
                                                            checked={state.checkedRole['role-' + r.id] || permission.state.checkedRole['role-' + r.id]}
                                                            className="rotated"
                                                        />
                                                    </th>
                                                )}
                                            </TableHeader>
                                            <tbody>
                                                {state.data.map((c, key) =>
                                                    <Item
                                                        key={key}
                                                        // handlePermissionChange={handlePermissionChange}
                                                        // handlePermissionChecked={handlePermissionChecked}
                                                        // handleGroupChange={handleGroupChange}
                                                        roles={roles}
                                                        permissionsRoles={permissionsRoles}
                                                        modules={state.data}
                                                        checkedGroup={state.checkedGroup}
                                                        checkedPermission={state.checkedPermission}
                                                        data={c}
                                                        filter={state.filter}
                                                    />
                                                )}
                                            </tbody>
                                        </Table>

                                        <div className="buttons mt-3 ">
                                            <SaveButton
                                                loading={state.loadingButton}
                                                className="save"
                                                onClick={handleSave}
                                            >
                                                Запази данните
                                            </SaveButton>
                                        </div>
                                    </Form>
                                </>
                        }
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Index;
