import axios from "axios";
import Autocomplete from "components/misc/Autocomplete";
import Loader from "components/misc/Loader";
import NoDataFound from "components/misc/NoDataFound";
import Pagination from "components/misc/Pagination";
import TableHeader from "components/misc/TableHeader";
import TableOptions from "components/misc/TableOptions";
import Category from "components/partials/filter/Category";
import DynamicTableBodyCol from "components/partials/table/DynamicTableBodyCol";
import DynamicTableCol from "components/partials/table/DynamicTableCol";
import Api from "helpers/Api";
import { useAppContext } from "providers/App";
import { useAuthDataContext } from "providers/Auth";
import { Fragment, useEffect, useRef, useState } from "react";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { Dash, Plus } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";

let timeout;

function Rows(props) {

    const app = useAppContext();
    const auth = useAuthDataContext();
    const navigate = useNavigate();

    const { show, hide } = props;

    const tableRef = useRef(null);

    const [state, setState] = useState({
        sorting: false,
        loading: true,
        refresh: false,
        data: [],
        groupped: false,
        totals: {},
        headings: {},
        types: {},
        pages: 0,
        total: 0,
        filter: {
            page: 1,
        },
        columns: {
            all: {},
            details: {},
            selected: [],
            sortable: [],
            sort: null,
            order: null,
        },
        tableKey: '',
        request: null,
    });

    const [expanded, setExpanded] = useState([]);

    useEffect(() => {
        loadData();
    }, [state.refresh]);

    const getUrl = () => {
        return 'revisions/show-rows?revision_id=' + props.id;
    }

    const loadData = () => {

        if (!props.id) {
            return;
        }

        if (state.request) {
            state.request.cancel();
        }

        let request = axios.CancelToken.source();

        setState(prev => ({
            ...prev,
            request: request,
            loading: true,
        }));

        let url = getUrl();

        Api.get(url, {
            params: state.filter,
            cancelToken: request.token
        }).then(res => {
            setState(prev => ({
                ...prev,
                currency: res.data.currency,
                data: res.data.items,
                groupped: res.data.groupped,
                total: res.data.total,
                pages: res.data.pages,
                totals: res.data.totals,
                headings: res.data.headings,
                types: res.data.types,
                columns: res.data.columns,
                tableKey: res.data.tableKey,
                loading: false,
                sorting: false,
                filter: {
                    ...prev.filter,
                    ...res.data.filter,
                }
            }));

            setExpanded(res.data.items.map(store => store.id));

            if (res.data.pages > 0 && state.filter.page > res.data.pages) {
                return handlePage({ selected: res.data.pages - 1 });
            }
        });
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: reset ? 1 : prev.filter.page
            },
            refresh: new Date().getTime(),
        }));
    }

    const refreshTable = () => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                sort: '',
                order: '',
                page: 1
            },
            refresh: new Date().getTime(),
        }));
    }

    // Search
    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout);

        if (typeof key === 'object') {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    ...key
                },
            }));
        } else {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    [key]: val,
                },
            }));
        }

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: 1
                },
                refresh: new Date().getTime()
            }));
        }, delay);
    }

    const handleChangeArticle = data => {
        handleSearch({
            article_id: data?.id || '',
        });
    }

    const handleCategoryChange = selected => {
        handleSearch('category_id', selected)
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            refresh: new Date().getTime()
        }));
    }

    const handleSort = (sort, order) => {
        setState(prev => ({
            ...prev,
            sorting: true,
            filter: {
                ...prev.filter,
                sort: sort,
                order: order,
                page: 1,
            },
            refresh: new Date().getTime()
        }));
    }

    const expand = id => {
        if (isExpanded(id)) {
            setExpanded(prev => prev.filter(c => c !== id));
        } else {
            setExpanded(prev => prev.concat(id));
        }
    }

    const isExpanded = (id) => {
        return expanded.indexOf(id) > -1;
    }

    const isVisible = id => {
        return isExpanded(id);
    }

    const renderRows = () => {
        let output = [];

        if (state.groupped) {
            state.data.map((category, index) => {
                output.push(
                    <Fragment
                        key={'c-' + category.id}
                    >
                        <tr style={{ background: 'cornsilk' }}>
                            <td
                                colSpan={category.colspan}
                                style={{
                                    padding: '0.3rem',
                                    fontSize: '12px',
                                    cursor: 'pointer'
                                }}
                                onClick={() => expand(category.id)}
                            >
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}>
                                    <div>
                                        {isExpanded(category.id) ? <Dash /> : <Plus />} <b style={{ color: '#505050' }}>{category.name}</b> - <i>{category.total} ред(а)</i>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        {category.items.map((c, rowIndex) =>
                            <tr
                                key={rowIndex}
                                className={c.deleted ? 'deleted' : ''}
                                style={{ display: isVisible(category.id) ? 'table-row' : 'none' }}
                            >
                                {Object.entries(state.headings).map((heading, i) =>
                                    <DynamicTableBodyCol
                                        key={heading[0]}
                                        type={state.types[heading[0]]}
                                        name={c[heading[0]]}
                                        data={c}
                                        currency={state.currency}
                                        onShowArticle={hide}
                                        onHideArticle={show}
                                    />
                                )}
                            </tr>
                        )}
                    </Fragment>
                )
            });
        } else {
            state.data.map((c, index) => {
                output.push(
                    <tr
                        key={index}
                        className={c.deleted ? 'deleted' : ''}
                    >
                        {Object.entries(state.headings).map((heading, i) =>
                            <DynamicTableBodyCol
                                key={heading[0]}
                                type={state.types[heading[0]]}
                                name={c[heading[0]]}
                                data={c}
                                currency={state.currency}
                                onShowArticle={hide}
                                onHideArticle={show}
                            />
                        )}
                    </tr>
                )
            })
        }

        return output;
    }

    return (
        <div className="mt-3">
            <div className="pagination-with-options mb-3">
                <Row style={{ alignItems: 'center' }}>
                    <Col>
                        <Form.Control
                            type="search"
                            name="search"
                            value={state.filter.search || ''}
                            size="sm"
                            onChange={e => handleSearch(e.target.name, e.target.value)}
                            placeholder="Търсене"
                            style={{
                                width: 'auto'
                            }}
                        />
                    </Col>
                    <Col>
                        <Autocomplete
                            variant="basic"
                            size="sm"
                            inputPlaceholder="Артикул"
                            url="autocomplete/articles"
                            inputIdName="article_id"
                            // inputValue={state.data?.supplier?.name}
                            // inputIdValue={props.filter.article_id || ''}
                            onChange={handleChangeArticle}
                            onInputChange={handleChangeArticle}
                            renderText={data => {
                                return (
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: 'max-content', marginRight: '10px' }}>
                                            {data.id}
                                        </div>
                                        <div>
                                            {data.name}
                                            <br />
                                            {data.article_name}
                                        </div>
                                    </div>
                                )
                            }}
                            renderInputText={data => data.article_name}
                            listStyle={{
                                minWidth: 'max-content'
                            }}
                        />
                    </Col>
                    <Col>
                        <Category
                            variant="basic"
                            size="sm"
                            onChange={handleCategoryChange}
                        />
                    </Col>
                </Row>

                <TableOptions
                    url={getUrl()}
                    filter={state.filter}
                    columns={state.columns}
                    tableKey={state.tableKey}
                    refresh={refreshTable}
                // onExport={hide}
                />
            </div>

            {state.loading && !state.sorting
                ?
                <div style={{ padding: '150px 0' }}>
                    <Loader />
                </div>
                :
                state.data.length === 0 ?
                    <NoDataFound />
                    :
                    <>
                        <Table className={Object.entries(state.headings).length > 14 ? 'xxl' : 'big'} responsive striped hover ref={tableRef}>
                            <TableHeader
                                tableRef={tableRef}
                                activeSortKey={state.filter.sort}
                                activeSortDir={state.filter.order}
                                onSort={(col, dir) => handleSort(col, dir)}
                            >
                                {Object.entries(state.headings).map((heading, i) =>
                                    <DynamicTableCol
                                        key={i}
                                        type={state.types[heading[0]]}
                                        name={heading[1]}
                                        title={state.columns.description[heading[0]]}
                                        sortKey={heading[0]}
                                        sortable={state.columns.sortable.indexOf(heading[0]) > -1}
                                    />
                                )}
                            </TableHeader>
                            <tbody>
                                {renderRows()}
                                {state.totals.map((c, i) =>
                                    <tr key={i} className="total">
                                        {Object.entries(c.items).map((heading, i) =>
                                            <DynamicTableBodyCol
                                                key={heading[0]}
                                                type={state.types[heading[0]]}
                                                name={heading[1]}
                                                data={c.items}
                                                currency={state.currency}
                                                colspan={c.colspan[heading[0]]}
                                            />
                                        )}
                                    </tr>
                                )}
                            </tbody>
                        </Table>

                        <Pagination
                            className="mt-3"
                            page={state.filter.page}
                            pages={state.pages}
                            total={state.total}
                            handlePage={handlePage}
                        />
                    </>
            }
        </div>
    )
}

export default Rows;