import React, { useState, useImperativeHandle, forwardRef, useRef, useEffect } from 'react'
import { Modal, Button, Tabs, Tab } from 'react-bootstrap';
import SaveButton from 'components/misc/Button';
import Loader from 'components/misc/Loader';
import Home from './Home';
import Edit from './Edit';
import Notes from './Notes';
import Payments from './Payments';
import PriceRules from './PriceRules';

import { useClientContext } from '../Provider';
import { useAppContext } from 'providers/App';
import { useAuthDataContext } from 'providers/Auth';
import Note from 'components/partials/notes/AddOrEdit';

import Api from 'helpers/Api';

import { MODELS } from 'constants/models';
import Refs from 'Refs';
import Vies from '../partials/Vies';

function View(props, ref) {

    const tabs = [
        {
            id: 'home',
            name: 'Преглед',
            component: Home
        },
        {
            id: 'edit',
            name: 'Редактиране',
            component: Edit
        },
        {
            id: 'payments',
            name: 'Портфейл',
            component: Payments
        },
        {
            id: 'price_rules',
            name: 'Ценови правила',
            component: PriceRules
        },
        {
            id: 'notes',
            name: 'Бележки',
            component: Notes
        },
    ];

    const app = useAppContext();
    const auth = useAuthDataContext();

    const [state, setState] = useState({
        show: false,
        params: null,
        id: null,
        tabId: null,
        onClose: null,
    });

    const context = useClientContext();

    const noteModalRef = useRef(null);
    const viesModalRef = useRef(null);

    useImperativeHandle(ref, () => ({
        open: (id) => {
            handleShow(id);
        },
        close: () => {
            hide();
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        }
    }));

    useEffect(() => {
        if (!state.show) {
            return;
        }

        if (auth.getUser().permission('clients_view') === false) {
            app.showError('Нямате право да преглеждате клиент!');

            hide(state.onClose);
        }

    }, [state.show]);

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const handleShow = (id) => {
        setState(prev => ({
            ...prev,
            id: id,
            tabId: getDefaultActiveTab()?.id,
        }));

        show();
    }

    const handleClose = () => {
        setState(prev => ({
            ...prev,
            show: false,
            tabId: tabs[0].id
        }));

        context.resetData();

        if (typeof state.onClose === 'function') {
            hide(state.onClose);
        } else {
            hide();
        }

        setState(prev => ({
            ...prev,
            onClose: null,
        }));
    }

    const setKey = id => {
        setState(prev => ({
            ...prev,
            tabId: id,
        }));
    }

    const hasTabPermission = key => {
        let map = {
            home: 'clients_view',
            edit: 'clients_edit',
            payments: 'clients_clientpayments',
            price_rules: 'clients_edit',
            notes: 'clients_notes',
        };

        let permission = map[key];

        if (permission && auth.getUser().permission(permission) === false) {
            return false;
        }

        return true;
    }

    const getDefaultActiveTab = () => {
        return tabs.find(tab => {
            return hasTabPermission(tab.id);
        });
    }

    const addNote = () => {

        let modal = noteModalRef.current;

        if (!modal) {
            return;
        }

        hide(() => {
            modal.add(state.id, MODELS.CLIENT);
        });

        modal.onClose(() => {
            show();
        });
    }

    const editNote = (id) => {

        let modal = noteModalRef.current;

        if (!modal) {
            return;
        }

        hide(() => {
            modal.edit(id);
        });

        modal.onClose(() => {
            show();
        });
    }

    const deleteNote = (id) => {

        let modal = Refs.getInstance().getRef('delete');

        if (!modal) {
            return;
        }

        hide(() => {
            modal.open('Сигурни ли сте, че искате да изтриете тази бележка?');
        });

        modal.onCancel(() => {
            show();
        });

        modal.onSuccess(() => {
            destroyNote(id, () => {
                show();
            });
        });
    }

    const destroyNote = (id, cb) => {
        Api.post('notes/delete', {
            id: id
        }).then(res => {
            cb();
        });
    }

    const showAccount = (id) => {
        let modal = Refs.getInstance().getRef('account');

        if (!modal) {
            return;
        }

        hide(() => {
            modal.open(id);
        });

        modal.onClose(() => {
            show();
        });
    }

    const showPriceRule = (id) => {
        let modal = Refs.getInstance().getRef('priceRule');

        if (!modal) {
            return;
        }

        hide(() => {
            modal.edit(id);
        });

        modal.onClose(() => {
            show();
        });
    }

    return (
        <>
            <Note
                ref={noteModalRef}
            />

            <Vies
                ref={viesModalRef}
            />

            <Modal size="xl" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Преглед на клиент
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tabs
                        activeKey={state.tabId}
                        onSelect={(k) => setKey(k)}
                        className="mb-3"
                        mountOnEnter
                        unmountOnExit
                    >
                        {tabs.map(tab =>
                            <Tab key={tab.id} eventKey={tab.id} title={tab.name}>
                                {React.createElement(tab.component, {
                                    id: state.id,
                                    show: show,
                                    hide: hide,
                                    addNote: addNote,
                                    editNote: editNote,
                                    deleteNote: deleteNote,
                                    showAccount: showAccount,
                                    showPriceRule: showPriceRule,
                                    viesModalRef: viesModalRef,
                                })}
                            </Tab>
                        )}
                    </Tabs>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Затвори
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default forwardRef(View);