import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import moment from 'moment'
import { Modal, Button, Col, Row, Nav, FloatingLabel, Form, TabContainer, Tab, Alert } from 'react-bootstrap';

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//providers
import { useLanguageContext } from 'providers/Language'

//misc
import SaveButton from 'components/misc/Button'
import Info from 'components/modals/Info';
import Autocomplete from 'components/misc/Autocomplete';
import HelpInfo from 'components/misc/Info';

function Addresses(props, ref) {

    const langs = useLanguageContext();

    const defaultData = {
        langs: {},
        delivery_type_id: 1
    }

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        client_id: null,
        edit: false,
        data: defaultData,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null,
        onClose: null
    });

    const [speditors, setSpeditors] = useState([]);

    const [validations, setValidations] = useValidation();

    const validationModalRef = useRef(null);
    const destinationsRef = useRef(null);
    const locationsRef = useRef(null);
    const officesRef = useRef(null);

    useImperativeHandle(ref, () => ({
        add: (clientId) => {
            add(clientId);
        },
        edit: (id) => {
            edit(id);
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        }
    }));

    useEffect(() => {
        loadData();
    }, [state.edit]);

    useEffect(() => {
        if (!state.show) {
            return;
        }

        if (speditors.length === 0) {
            Api.get('clients/addresses/speditors')
                .then(res => {
                    if (Array.isArray(res.data)) {
                        setSpeditors(res.data);
                    }
                });
        }
    }, [state.show]);

    useEffect(() => {
        if (!state.show) {
            return;
        }

        if (state.edit) {
            return;
        }

        Api.get('clients/show', {
            params: {
                id: state.client_id
            }
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    contact_name: res.data.name,
                    client_email: res.data.email,
                    client_tel: res.data.tel,
                }
            }));
        });

    }, [state.show, state.edit, state.client_id]);

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const add = (clientId) => {
        setState(prev => ({
            ...prev,
            data: defaultData,
            client_id: clientId,
            id: null,
        }));

        show();
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
        }));

        setValidations(null);

        if (typeof state.onClose === 'function') {
            state.onClose();
        }

        hide();
    }

    const edit = id => {
        setState(prev => ({
            ...prev,
            id: id,
            edit: new Date().getTime()
        }));

        show();
    }

    const loadData = () => {
        if (!state.id) {
            return;
        }

        Api.get('clients/addresses/show?id=' + state.id)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data,
                    client_id: res.data.client_id
                }))
            });
    }

    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = state.id ? 'clients/addresses/update' : 'clients/addresses/add';

        let data = new FormData(e.target);
        data.append('client_id', state.client_id)

        if (state.id) {
            data.append('id', state.id)
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (typeof state.onSuccess === 'function') {
                state.onSuccess(res.data)
            }

            hide()
        }).catch(error => {
            const _err = error.response;
            if (_err && _err.status && _err.status === 422) {
                hide(() => {
                    let modal = validationModalRef.current;

                    if (modal) {
                        modal.open();

                        modal.onClose(() => {
                            setValidations(_err.data.errors)
                            show();
                        });
                    }
                });
            }
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }));
        });
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleCountryChange = data => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                country_id: data?.id || null,
                country_name: data?.translation?.name || data?.name || data,
                dest_id: null,
                dest_name: '',
            }
        }));

        let destinations = destinationsRef.current;

        if (destinations) {
            destinations.reset();
        }
    }

    const handleCountryInputChange = data => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                country_id: null,
                country_name: data,
                dest_id: null,
                dest_name: '',
            }
        }));

        let destinations = destinationsRef.current;

        if (destinations) {
            destinations.reset();
        }
    }

    const handleDestinationChange = data => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                dest_id: data?.id || null,
                dest_name_full: data?.dest_name || data,
                zip: data?.postcode || '',
            }
        }));
    }

    const handleDestinationInputChange = data => {
        console.log(data);

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                dest_id: null,
                dest_name_full: data,
            }
        }));
    }

    const handleSpeditorChange = e => {
        let value = e.target.value;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                speditor_id: value,
                dest_id: null,
                dest_name_full: '',
                office_id: null,
                office_name: '',
            }
        }));

        let locations = locationsRef.current;

        if (locations) {
            locations.reset();
        }

        let offices = officesRef.current;

        if (offices) {
            offices.reset();
        }
    }

    const handleLocationChange = data => {
        // setState(prev => ({
        //     ...prev,
        //     data: {
        //         ...prev.data,
        //         dest_id: data?.id || null,
        //         dest_name_full: data?.name || data,
        //         office_id: null,
        //         office_name: '',
        //     }
        // }));

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                dest_id: data?.id || null,
                dest_name_full: data?.dest_name || data,
                office_id: null,
                office_name: '',
            }
        }));

        let offices = officesRef.current;

        if (offices) {
            offices.reset();
        }
    }

    const handleLocationInputChange = data => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                dest_id: null,
                dest_name_full: data,
                office_id: null,
                office_name: '',
            }
        }));

        let offices = officesRef.current;

        if (offices) {
            offices.reset();
        }
    }

    const handleOfficeChange = data => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                office_id: data?.id || null,
                office_name: data?.name || data,
            }
        }));
    }

    const handleOfficeInputChange = data => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                office_id: null,
                office_name: data,
            }
        }));
    }

    return (
        <>
            <Info
                ref={validationModalRef}
                mainMessage="Моля, попълнете всички задължителни полета, означени с червен цвят!"
            />

            <Modal size="lg" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <form onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Адрес за доставка
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <FloatingLabel label="Тип доставка" className="mb-3">
                            <Form.Select
                                name="delivery_type_id"
                                value={state.data.delivery_type_id || ''}
                                onChange={handleInputChange}
                                isInvalid={Boolean(validations?.delivery_type_id)}
                            >
                                <option key={1} value={1}>Адрес на клиент</option>
                                <option key={2} value={2}>Офис на куриер</option>
                                <option key={3} value={3}>Търговски обект</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                {validations?.delivery_type_id}
                            </Form.Control.Feedback>
                        </FloatingLabel>

                        <Row>
                            <Col>
                                <FloatingLabel label="Име" className="mb-3">
                                    <Form.Control
                                        type="text"
                                        name={`contact_name`}
                                        autoComplete="new-password"
                                        value={state.data.contact_name || ''}
                                        placeholder="Име"
                                        onChange={(e) => handleInputChange(e)}
                                        isInvalid={Boolean(validations?.contact_name)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {validations?.contact_name && (validations?.contact_name[0] || validations?.contact_name)}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col>
                                <FloatingLabel label="Е-поща" className="mb-3">
                                    <Form.Control
                                        type="text"
                                        name={`client_email`}
                                        autoComplete="new-password"
                                        value={state.data.client_email || ''}
                                        placeholder="Е-поща"
                                        onChange={(e) => handleInputChange(e)}
                                        isInvalid={Boolean(validations?.client_email)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {validations?.client_email && (validations?.client_email[0] || validations?.client_email)}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col>
                                <FloatingLabel label="Телефон" className="mb-3">
                                    <Form.Control
                                        type="text"
                                        name={`client_tel`}
                                        autoComplete="new-password"
                                        value={state.data.client_tel || ''}
                                        placeholder="Телефон"
                                        onChange={(e) => handleInputChange(e)}
                                        isInvalid={Boolean(validations?.client_tel)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {validations?.client_tel && (validations?.client_tel[0] || validations?.client_tel)}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>

                        {state.data.delivery_type_id == 1 ?
                            <>
                                <Row>
                                    <Col>
                                        <Autocomplete
                                            url="autocomplete/countries"
                                            inputPlaceholder="Държава"
                                            inputName="country_name"
                                            inputIdName="country_id"
                                            inputValue={state.data.country_name || ''}
                                            inputIdValue={state.data.country_id || ''}
                                            error={Boolean(validations?.country_id)}
                                            helperText={validations?.country_id && (validations?.country_id[0] || validations?.country_id)}
                                            onChange={data => handleCountryChange(data)}
                                            onInputChange={data => handleCountryInputChange(data)}
                                            renderText={data => data.translation?.name || data.name}
                                            renderInputText={data => data.translation?.name || data.name}
                                        />
                                    </Col>

                                    <Col>
                                        <Autocomplete
                                            ref={destinationsRef}
                                            url="autocomplete/destinations"
                                            params={{
                                                country_id: state.data.country_id,
                                            }}
                                            // requiredParams={['country_id']}
                                            inputPlaceholder="Населено място"
                                            inputName="dest_name_full"
                                            inputIdName="dest_id"
                                            inputValue={state.data.dest_name_full || ''}
                                            inputIdValue={state.data.dest_id || ''}
                                            error={Boolean(validations?.dest_id)}
                                            helperText={validations?.dest_id && (validations?.dest_id[0] || validations?.dest_id)}
                                            onChange={data => handleDestinationChange(data)}
                                            onInputChange={data => handleDestinationInputChange(data)}
                                            renderText={data => (
                                                <div style={{ display: 'flex', width: '100%', textTransform: 'uppercase' }}>
                                                    <div>
                                                        {data.dest_type} {data.dest_name} [{data.postcode}]
                                                        <br />
                                                        <span style={{ fontSize: '12px' }}>
                                                            ОБЛАСТ {data.dest_region}
                                                        </span>
                                                    </div>

                                                </div>
                                            )}
                                            renderInputText={data => (
                                                <>{data.dest_type} {data.dest_name}</>
                                            )}
                                        />
                                    </Col>

                                    <Col>
                                        <FloatingLabel label="Адрес" className="mb-3">
                                            <Form.Control
                                                type="text"
                                                name={`address`}
                                                autoComplete="new-password"
                                                value={state.data.address || ''}
                                                placeholder="Адрес"
                                                onChange={(e) => handleInputChange(e)}
                                                isInvalid={Boolean(validations?.address)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {validations?.address}
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                </Row>

                                <Row>

                                    <Col>
                                        <FloatingLabel label="Пощенски код" className="mb-3">
                                            <Form.Control
                                                type="text"
                                                name={`zip`}
                                                autoComplete="new-password"
                                                value={state.data.zip || ''}
                                                placeholder="Пощенски код"
                                                onChange={(e) => handleInputChange(e)}
                                                isInvalid={Boolean(validations?.zip)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {validations?.zip && (validations?.zip[0] || validations?.zip)}
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col>
                                        <FloatingLabel label="Кординати" className="mb-3">
                                            <Form.Control
                                                type="text"
                                                name={`coords`}
                                                autoComplete="new-password"
                                                value={state.data.coords || ''}
                                                placeholder="Кординати"
                                                onChange={(e) => handleInputChange(e)}
                                                isInvalid={Boolean(validations?.coords)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {validations?.coords && (validations?.coords[0] || validations?.coords)}
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>

                                    <Col>
                                        <FloatingLabel label="Ползван куриер" className="mb-3">
                                            <Form.Select
                                                name="speditor_id"
                                                value={state.data.speditor_id || ''}
                                                onChange={handleInputChange}
                                            >
                                                <option value="">Няма</option>
                                                {speditors.map(s =>
                                                    <option key={s.id} value={s.id}>{s.name}</option>
                                                )}
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Col>

                                </Row>

                                <FloatingLabel label="Описание" className="mb-3">
                                    <Form.Control
                                        as="textarea"
                                        name={`description`}
                                        autoComplete="new-password"
                                        value={state.data.description || ''}
                                        placeholder="Описание"
                                        style={{ height: '100px' }}
                                        onChange={(e) => handleInputChange(e)}
                                        isInvalid={Boolean(validations?.description)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {validations?.description && (validations?.description[0] || validations?.description)}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </> : ''}

                        {state.data.delivery_type_id == 2 ?
                            <Row>
                                <Col>
                                    <FloatingLabel label="Куриер" className="mb-3">
                                        <Form.Select
                                            name="speditor_id"
                                            value={state.data.speditor_id || ''}
                                            onChange={handleSpeditorChange}
                                        >
                                            <option>Няма</option>
                                            {speditors.map(s =>
                                                <option key={s.id} value={s.id}>{s.name}</option>
                                            )}
                                        </Form.Select>
                                    </FloatingLabel>
                                </Col>

                                <Col>
                                    <Autocomplete
                                        ref={locationsRef}
                                        // url="autocomplete/speditors/locations"
                                        url="autocomplete/destinations"
                                        // params={{
                                        //     speditor_id: state.data.speditor_id,
                                        // }}
                                        // requiredParams={['speditor_id']}
                                        inputPlaceholder="Населено място"
                                        inputName="dest_name_full"
                                        inputIdName="dest_id"
                                        inputValue={state.data.dest_name_full || ''}
                                        inputIdValue={state.data.dest_id || ''}
                                        error={Boolean(validations?.dest_name_full)}
                                        helperText={validations?.dest_name_full && (validations?.dest_name_full[0] || validations?.dest_name_full)}
                                        onChange={data => handleLocationChange(data)}
                                        onInputChange={data => handleLocationInputChange(data)}
                                        renderText={data => (
                                            <div style={{ display: 'flex', width: '100%', textTransform: 'uppercase' }}>
                                                <div>
                                                    {data.dest_type} {data.dest_name} [{data.postcode}]
                                                    <br />
                                                    <span style={{ fontSize: '12px' }}>
                                                        ОБЛАСТ {data.dest_region}
                                                    </span>
                                                </div>

                                            </div>
                                        )}
                                        renderInputText={data => (
                                            <>{data.dest_type} {data.dest_name}</>
                                        )}
                                    />
                                </Col>

                                <Col>
                                    <Autocomplete
                                        ref={officesRef}
                                        url="autocomplete/speditors/offices"
                                        params={{
                                            speditor_id: state.data.speditor_id,
                                            location_id: state.data.dest_id,
                                        }}
                                        requiredParams={['speditor_id', 'location_id']}
                                        inputPlaceholder="Офис"
                                        inputName="office_name"
                                        inputIdName="office_id"
                                        renderText={data => data?.name}
                                        inputValue={state.data.office_name || ''}
                                        inputIdValue={state.data.office_id || ''}
                                        error={Boolean(validations?.office_name)}
                                        helperText={validations?.office_name && (validations?.office_name[0] || validations?.office_name)}
                                        onChange={data => handleOfficeChange(data)}
                                        onInputChange={data => handleOfficeInputChange(data)}
                                    />
                                </Col>

                            </Row>
                            : ''}

                        {state.data.delivery_type_id == 3 ?
                            <Row>
                                <Col>
                                    <Autocomplete
                                        url="autocomplete/stores"
                                        params={{
                                            all: 1
                                        }}
                                        inputPlaceholder="Търговски обект"
                                        inputIdName={`store_id`}
                                        renderText={data => data?.translation?.name}
                                        inputValue={state.data.store_name || ''}
                                        inputIdValue={state.data.store_id || ''}
                                        error={Boolean(validations?.store_id)}
                                        helperText={validations?.store_id && (validations?.store_id[0] || validations?.store_id)}
                                    // onChange={data => handleStoreChange(data)}
                                    />
                                </Col>

                            </Row>
                            : ''}

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            className="save"
                        />
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default forwardRef(Addresses);
