import axios from "axios";
import TableCol from "components/misc/TableCol";
import TableHeader from "components/misc/TableHeader";
import PriceValue from "components/partials/PriceValue";
import Api from "helpers/Api";
import moment from "moment";
import { useAppContext } from "providers/App";
import { useAuthDataContext } from "providers/Auth";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Refs from "Refs";

function Revisions(props) {

    const app = useAppContext();
    const auth = useAuthDataContext();
    const navigate = useNavigate();

    const tableRef = useRef(null);

    const { createMarkup, highlight } = props;

    const [state, setState] = useState({
        loading: true,
        refresh: false,
        setFilter: false,
        data: [

        ],
        filter: {

        },
        request: null,
    });

    useEffect(() => {
        if (props.refresh) {
            loadData();
        }
    }, [state.refresh, props.refresh, props.keyword]);

    useEffect(() => {
        if (state.loading) {
            props.onLoadStart();
        } else {
            props.onLoadEnd();
        }
    }, [state.loading]);

    useEffect(() => {
        props.onResult(state.data.length);
    }, [state.data]);

    const loadData = () => {
        if (state.request) {
            state.request.cancel();
        }

        let request = axios.CancelToken.source();

        setState(prev => ({
            ...prev,
            request: request,
            loading: true,
        }));

        let url = 'search/revisions';

        Api.get(url, {
            params: {
                keyword: props.keyword
            },
            cancelToken: request.token
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data.items,
                loading: false,
                sorting: false,
                filter: {
                    ...prev.filter,
                    ...res.data.filter,
                }
            }));
        });
    }

    const showRevision = id => {
        let modal = Refs.getInstance().getRef('revision');

        modal.open(id);
    }

    const showRevisions = () => {
        navigate('/storage/revisions?search=' + props.keyword);
    }

    if (state.data.length === 0) {
        return null;
    }

    return (
        <Col xl={6} lg={6}>
            <div className="card">
                <div className="d-flex card-header justify-content-between align-items-start">
                    <h4 className="header-title">Ревизии </h4>

                    <Button
                        variant="dark"
                        size="sm"
                        onClick={showRevisions}
                    >
                        Всички ревизии
                    </Button>
                </div>
                <div className="card-body with-table pt-0">
                    <Table className="" responsive striped hover ref={tableRef}>
                        <TableHeader
                            tableRef={tableRef}
                        >
                            <TableCol>ID</TableCol>
                            <TableCol className="text-center">Статус</TableCol>
                            <TableCol>Склад</TableCol>
                            <TableCol>Дата</TableCol>
                            <TableCol>Създадено от</TableCol>
                        </TableHeader>
                        <tbody>
                            {state.data.map(c =>
                                <tr
                                    key={'c-' + c.id}
                                    className={c.deleted ? 'deleted' : ''}
                                >
                                    <td>
                                        <span className="link" onClick={e => showRevision(c.id)} dangerouslySetInnerHTML={createMarkup(highlight(c.id))} />
                                    </td>
                                    <td className={`text-center ${c.status?.code === 'open' ? 'blue-value' : c.status?.code === 'close' ? 'brown-value' : ''}`}>
                                        {c?.status?.translation?.name || c?.status?.name}
                                    </td>
                                    <td>
                                        {c?.depot?.store?.translation?.name || c?.depot?.store?.name} - {c?.depot?.name}
                                    </td>
                                    <td>
                                        <span>
                                            {c.created_at ? moment(c.created_at).format('DD.MM.YYYY HH:mm') : ''}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {c?.creator?.username}
                                        </span>
                                    </td>

                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
            </div>
        </Col>
    )
}

export default Revisions;