import { Dropdown } from 'react-bootstrap';

function Options(props) {
    return (
        <>
            <Dropdown.Item disabled={props.data.allow_edit === false} onClick={e => props.handleEdit(props.data)}>
                Редакция
            </Dropdown.Item>
            <Dropdown.Item disabled={props.data.allow_delete === false} onClick={e => props.handleDelete(props.data)}>
                Изтриване
            </Dropdown.Item>
        </>
    )
}

export default Options;