import { useState, useEffect } from 'react'
import { Table } from 'react-bootstrap';
import Api from 'helpers/Api';
import Loader from 'components/misc/Loader';
import moment from 'moment';
import PriceValue from 'components/partials/PriceValue';

function Index(props) {

    const defaultData = {
        rows: []
    };

    const [state, setState] = useState({
        data: defaultData,
        loading: false,
    });

    useEffect(() => {
        setState(prev => ({
            ...prev,
            loading: state.data.id ? false : true
        }));

        Api.get('payments/show', {
            params: {
                id: props.id
            }
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data,
                loading: false,
            }));
        });
    }, [props.id]);

    const showClient = id => {
        props.showClient(id);
    }

    return (

        state.loading
            ?
            <Loader />
            :
            <>
                <Table size="sm" bordered>
                    <tbody>
                        <tr>
                            <td className="th" style={{ width: '30%' }}>
                                ID
                            </td>
                            <td>
                                {state.data.id}
                            </td>
                        </tr>
                        <tr>
                            <td className="th" style={{ width: '30%' }}>
                                Дата
                            </td>
                            <td>
                                {state.data.date ? moment(state.data.date).format('DD.MM.YYYY HH:mm') : ''}
                            </td>
                        </tr>
                        <tr>
                            <td className="th" style={{ width: '30%' }}>
                                Вальор
                            </td>
                            <td>
                                {state.data.value_date ? moment(state.data.value_date).format('DD.MM.YYYY HH:mm') : ''}
                            </td>
                        </tr>
                        <tr>
                            <td className="th" style={{ width: '30%' }}>
                                Търговски обект
                            </td>
                            <td>
                                {state.data.store?.name}
                            </td>
                        </tr>
                        <tr>
                            <td className="th" style={{ width: '30%' }}>
                                Каса
                            </td>
                            <td>
                                {state.data.cashdesk?.name}
                            </td>
                        </tr>
                        <tr>
                            <td className="th" style={{ width: '30%' }}>
                                Оператор
                            </td>
                            <td>
                                {state.data.user?.username}
                            </td>
                        </tr>
                        <tr>
                            <td className="th" style={{ width: '30%' }}>
                                Клиент
                            </td>
                            <td>
                                <span className="link" onClick={e => showClient(state.data.client_id)}>
                                    {state.data.client?.name}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td className="th" style={{ width: '30%' }}>
                                Валута
                            </td>
                            <td>
                                {state.data.currency?.name} / {state.data.currency_rate}
                            </td>
                        </tr>
                        <tr>
                            <td className="th" style={{ width: '30%' }}>
                                Сума
                            </td>
                            <td>
                                <span className={state.data.amount < 0 && 'negative-value'}>
                                    <PriceValue prefix={state.data.currency?.prefix} sufix={state.data.currency?.sufix}>{state.data.amount}</PriceValue>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td className="th" style={{ width: '30%' }}>
                                Сметка(и)
                            </td>
                            <td>
                                {state.data.accounts && state.data.accounts.map(account =>
                                    <span
                                        key={account.id}
                                        // className="link"
                                        // onClick={e => showAccount(account.id)}
                                        style={{ display: 'block' }}
                                    >
                                        {account.id}: <span className={account.paid_sum < 0 && 'negative-value'}>
                                            <PriceValue prefix={state.data.currency?.prefix} sufix={state.data.currency?.sufix}>{account.paid_sum}</PriceValue>
                                        </span>
                                    </span>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td className="th" style={{ width: '30%' }}>
                                Остатък
                            </td>
                            <td>
                                <PriceValue prefix={state.data.currency?.prefix} sufix={state.data.currency?.sufix}>{state.data.money_left}</PriceValue>
                            </td>
                        </tr>
                        <tr>
                            <td className="th" style={{ width: '30%' }}>
                                Сторнирани
                            </td>
                            <td>
                                <PriceValue prefix={state.data.currency?.prefix} sufix={state.data.currency?.sufix}>{state.data.storno_amount}</PriceValue>
                            </td>
                        </tr>
                        <tr>
                            <td className="th" style={{ width: '30%' }}>
                                Начин
                            </td>
                            <td>
                                {state.data.paymentmethod?.name}
                            </td>
                        </tr>
                        <tr>
                            <td className="th" style={{ width: '30%' }}>
                                Референция
                            </td>
                            <td>
                                {state.data.ref}
                            </td>
                        </tr>
                        <tr>
                            <td className="th" style={{ width: '30%' }}>
                                Описание
                            </td>
                            <td>
                                {state.data.description}
                            </td>
                        </tr>
                        <tr>
                            <td className="th" style={{ width: '30%' }}>
                                Допълнителни данни
                            </td>
                            <td>
                                {state.data.extra_details}
                            </td>
                        </tr>
                    </tbody>
                </Table>


            </>
    )
}

export default Index;