import HomeIcon from 'assets/img/icons/icons/начало.png'
import CmsIcon from 'assets/img/icons/icons/управление.png'
import CatalogueIcon from 'assets/img/icons/icons/каталог.png'
import StorageIcon from 'assets/img/icons/icons/склад.png'
import CashDeskIcon from 'assets/img/icons/icons/каса.png'
import ReportIcon from 'assets/img/icons/icons/справки.png'
import SettingsIcon from 'assets/img/icons/icons/настройки.png'
import BrandsIcon from 'assets/img/icons/icons/марки.png'
import ArticlesIcon from 'assets/img/icons/icons/продукти.png'
import RevisionIcon from 'assets/img/icons/icons/revision.svg'
import NewOrderIcon from 'assets/img/icons/icons/нова заявка.png'
import OrdersIcon from 'assets/img/icons/icons/заявки.png'
import ReviewsIcon from 'assets/img/icons/icons/reviews.svg'
import ReturnsIcon from 'assets/img/icons/icons/returns.svg'
import ProtocolIcon from 'assets/img/icons/icons/ppp.svg'
import PriceRulesIcon from 'assets/img/icons/icons/money rules.svg'
import MailTemplatesIcon from 'assets/img/icons/icons/mail templates.svg'
import InvoiceIcon from 'assets/img/icons/icons/invoice.svg'
import IntrastatIcon from 'assets/img/icons/icons/intrastat.svg'
import WarranyIcon from 'assets/img/icons/icons/guarantee.svg'
import DocumentsIcon from 'assets/img/icons/icons/documents.svg'
import ContractIcon from 'assets/img/icons/icons/contract.svg'
import UsersIcon from 'assets/img/icons/icons/users.svg'
import MoenyIcon from 'assets/img/icons/icons/money.svg'

function Icon(props) {
    return (
        <img className={props.className} src={props.children} />
    )
}

function Home(props) {
    return <Icon {...props}>{HomeIcon}</Icon>
}

function Cms(props) {
    return <Icon {...props}>{CmsIcon}</Icon>
}

function Catalogue(props) {
    return <Icon {...props}>{CatalogueIcon}</Icon>
}

function Storage(props) {
    return <Icon {...props}>{StorageIcon}</Icon>
}

function CashDesk(props) {
    return <Icon {...props}>{CashDeskIcon}</Icon>
}

function Report(props) {
    return <Icon {...props}>{ReportIcon}</Icon>
}

function Settings(props) {
    return <Icon {...props}>{SettingsIcon}</Icon>
}

function Brands(props) {
    return <Icon {...props}>{BrandsIcon}</Icon>
}

function Articles(props) {
    return <Icon {...props}>{ArticlesIcon}</Icon>
}

function Revision(props) {
    return <Icon {...props}>{RevisionIcon}</Icon>
}

function NewOrder(props) {
    return <Icon {...props}>{NewOrderIcon}</Icon>
}

function Orders(props) {
    return <Icon {...props}>{OrdersIcon}</Icon>
}

function Reviews(props) {
    return <Icon {...props}>{ReviewsIcon}</Icon>
}

function Returns(props) {
    return <Icon {...props}>{ReturnsIcon}</Icon>
}

function Protocol(props) {
    return <Icon {...props}>{ProtocolIcon}</Icon>
}

function PriceRules(props) {
    return <Icon {...props}>{PriceRulesIcon}</Icon>
}

function MailTemplates(props) {
    return <Icon {...props}>{MailTemplatesIcon}</Icon>
}

function Invoice(props) {
    return <Icon {...props}>{InvoiceIcon}</Icon>
}

function Intrastat(props) {
    return <Icon {...props}>{IntrastatIcon}</Icon>
}

function Warranty(props) {
    return <Icon {...props}>{WarranyIcon}</Icon>
}

function Documents(props) {
    return <Icon {...props}>{DocumentsIcon}</Icon>
}

function Contract(props) {
    return <Icon {...props}>{ContractIcon}</Icon>
}

function Users(props) {
    return <Icon {...props}>{UsersIcon}</Icon>
}

function Money(props) {
    return <Icon {...props}>{MoenyIcon}</Icon>
}

export {
    Home,
    Cms,
    Catalogue,
    Storage,
    CashDesk,
    Report,
    Settings,
    Brands,
    Articles,
    Revision,
    NewOrder,
    Orders,
    Reviews,
    Returns,
    Protocol,
    PriceRules,
    MailTemplates,
    Invoice,
    Intrastat,
    Warranty,
    Documents,
    Contract,
    Users,
    Money,
}