import Loader from 'components/misc/Loader';
import Api from 'helpers/Api';
import { useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';

function ArticleDetailValue(props) {
    const detail = props.detail;
    const values = detail.values || [];

    const isValueSelected = props.isValueSelected;
    const onChange = props.onChange;
    const setDetailValues = props.setDetailValues;

    const scrollRef = useRef(null);

    const [state, setState] = useState({
        loading: false,
        hasMore: detail.values_count > values.length,
        page: 1,
        data: values
    });

    const loadData = page => {
        if (state.loading) {
            return false;
        }

        setState(prev => ({
            ...prev,
            loading: true,
            hasMore: false,
        }));

        Api.get('autocomplete/article-details-values', {
            params: {
                detail_id: detail.id,
                page: page
            }
        }).then(res => {
            setState(prev => ({
                ...prev,
                loading: false,
                data: prev.data.concat(res.data.items),
                hasMore: res.data.has_more,
            }));

            setDetailValues(detail.id, (props.detail.values || []).concat(res.data.items));
        })
    }

    return (
        <div className="detail">
            <div className="title">
                {detail.translation?.name || detail?.name}
            </div>
            <div className="body" ref={scrollRef}>
                <InfiniteScroll
                    pageStart={0}
                    loadMore={loadData}
                    hasMore={state.hasMore}
                    loader={<Loader key={0} />}
                    useWindow={false}
                    getScrollParent={() => scrollRef.current}
                >
                    {(state.data || []).map(value =>
                        <div key={value.id} className="value">
                            <label>
                                <input
                                    type="checkbox"
                                    checked={isValueSelected(value, detail) ? true : false}
                                    onChange={e => onChange(value, detail)}
                                />
                                <span> {value?.translation?.name || value?.name}</span>
                            </label>
                        </div>
                    )}
                </InfiniteScroll>
            </div>
        </div>
    )
}

export default ArticleDetailValue;