import { useValidation } from 'helpers/Validation';
import { useState, useImperativeHandle, forwardRef, useEffect, useRef } from 'react'
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Alert from 'components/misc/Alert';

function Search(props, ref) {

    const navigate = useNavigate();

    const defaultData = {

    };

    const [state, setState] = useState({
        show: false,
        params: null,
        data: defaultData,
        loading: false,
        onSuccess: null,
        onClose: null,
    });

    const [validations, setValidations] = useValidation();

    useImperativeHandle(ref, () => ({
        open: () => {
            handleShow();
        },
        close: () => {
            handleClose();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        }
    }));

    const handleShow = () => {
        setState(prev => ({
            ...prev,
            show: true,
        }));
    }

    const handleClose = e => {
        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            show: false,
            data: defaultData
        }));

        if (typeof state.onClose === 'function') {
            state.onClose();
        }
    }

    const hide = () => {
        setState(prev => ({
            ...prev,
            show: false,
        }));
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: value
            }
        }));
    }

    const handleSave = (e) => {
        e.preventDefault();

        setValidations(null);

        if (!state.data.keyword) {
            setValidations({
                keyword: 'Въведете ключова дума'
            });

            return;
        }

        navigate('/search?keyword=' + state.data.keyword);

        hide();
    }

    return (
        <>
            <Modal size="md" show={state.show} onHide={handleClose}>
                <Form onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Търсене във всичко
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col className="">
                                <Form.Control
                                    type="text"
                                    name="keyword"
                                    value={state.data.keyword || ''}
                                    onChange={handleInputChange}
                                    isInvalid={Boolean(validations?.keyword)}
                                    autoFocus
                                />
                                <Form.Control.Feedback type="invalid">
                                    {validations && validations.keyword}
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Отказ
                        </Button>
                        <Button variant="dark" type="submit">
                            Напред
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

export default forwardRef(Search);