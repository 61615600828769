import { Spinner, Overlay } from 'react-bootstrap';

function PageLoader(props) {
    return (
        <div className="overlay visible">
            <Spinner animation="border" variant="secondary" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    )
}

export default PageLoader;