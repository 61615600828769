import { useEffect, useRef, useState } from 'react';
import Api from 'helpers/Api';
import { useQuery } from 'helpers/Url';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Alert, Button, Table } from 'react-bootstrap';
import { useAuthDataContext } from 'providers/Auth';
import moment from 'moment'

import Options from './partials/Options';

import Question from 'components/modals/Question';
import Filter from './partials/Filter';
import DeviceSelection from './partials/DeviceSelection';
import AddOrEdit from './partials/AddOrEdit';
import Layout from 'components/layout/Layout';

//misc
import MenuOptions from 'components/misc/MenuOptions'
import Pagination from 'components/misc/Pagination'
import Loader from 'components/misc/Loader';
import TableHeader from 'components/misc/TableHeader';
import TableCol from 'components/misc/TableCol';
import NoDataFound from 'components/misc/NoDataFound';

let timeout;

function Index() {

    const location = useLocation();
    const history = useNavigate();
    const query = useQuery();
    const auth = useAuthDataContext();

    const tableRef = useRef(null);
    const addOrEditModalRef = useRef(null);
    const deviceSelectionModalRef = useRef(null);
    const deleteModalRef = useRef(null);

    const [state, setState] = useState({
        sorting: false,
        loading: true,
        refresh: false,
        setFilter: false,
        data: [],
        pages: 0,
        total: 0,
        filter: {
            page: 1,
        },
    });

    useEffect(() => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: query.get('page') || 1,
                search: query.get('search') || '',
                sort: query.get('sort') || '',
                order: query.get('order') || '',
            },
            refresh: new Date().getTime()
        }))
    }, [location.search]);

    useEffect(() => {
        if (state.setFilter) {
            Object.entries(state.filter).map(filter => {
                // if (filter[1]) {
                query.set(filter[0], filter[1]);
                // }
            });

            history('?' + query.toString());
        }

    }, [state.setFilter]);

    useEffect(() => {
        if (state.refresh) {
            loadData();
        }
    }, [state.refresh]);

    useEffect(() => {
        refresh();
    }, [auth.getUser()?.getStoreId()]);

    const loadData = () => {

        loading(true);

        let url = 'stores/devices/get';

        Api.get(url, {
            params: state.filter
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data.items,
                total: res.data.total,
                pages: res.data.pages,
                totals: res.data.totals,
                filter: {
                    ...prev.filter,
                    ...res.data.filter,
                }
            }));

            if (res.data.pages > 0 && state.filter.page > res.data.pages) {
                return handlePage({ selected: res.data.pages - 1 });
            }

            loading(false);
            sorting(false)
        });
    }

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }));
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: reset ? 1 : prev.filter.page
            },
            setFilter: new Date().getTime(),
            refresh: new Date().getTime(),
        }));
    }

    const handleAdd = () => {
        let modal = deviceSelectionModalRef.current;

        modal.open();

        modal.onSuccess(() => {
            refresh(true);
        });
    }

    // update
    const handleEdit = id => {
        let modal = addOrEditModalRef.current;

        modal.edit(id);

        modal.onSuccess(() => {
            refresh(false);
        });
    }

    // delete
    const handleDelete = id => {
        let modal = deleteModalRef.current;

        modal.open();
        modal.onSuccess(() => {
            handleDestroy(id);
        })
    }

    const handleDestroy = id => {
        Api.post('/stores/devices/delete', {
            id: id
        }).then(res => {
            if (res.data.success) {
                refresh(false);
            }
        });
    }

    // Search
    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout);

        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [key]: val,
            },
        }));

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: 1
                },
                setFilter: new Date().getTime()
            }));
        }, delay);
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            setFilter: new Date().getTime()
        }));
    }

    const handleURL = (conn_proto = null, ip_suffix = null, tcp_port = '') => {
        if (!ip_suffix || !conn_proto) {
            return '';
        }

        let port = tcp_port ? ':' + tcp_port : ''
        let protocol = '';

        switch (conn_proto) {
            case 1:
                protocol = 'tcp://'
                break;
            case 2:
                protocol = 'udp://'
                break;
            case 3:
                protocol = 'ipp://'
                break;
            case 4:
                protocol = 'http://'
                break;
            case 5:
                protocol = 'https://'
                break;
            default:
                break;
        }

        return protocol + ip_suffix + port

    }

    const handleSpeed = (speed) => {
        switch (speed) {
            case 1:
                return '115200'
                break;
            case 2:
                return '57600'
                break;
            case 3:
                return '38400'
                break;
            case 4:
                return '19200'
                break;
            case 5:
                return '9600'
                break;
            case 6:
                return '4800'
                break;
            case 7:
                return '2400'
                break;
            case 8:
                return '1200'
                break;
        }
        return ''
    }

    const sorting = (sorting) => {
        setState(prev => ({
            ...prev,
            sorting: Boolean(sorting)
        }));
    }

    const handleSort = (sort, order) => {
        setState(prev => ({
            ...prev,
            sorting: true,
            filter: {
                ...prev.filter,
                sort: sort,
                order: order,
                page: 1,
            },
            setFilter: new Date().getTime()
        }));
    }

    return (
        <>

            <AddOrEdit
                ref={addOrEditModalRef}
                refresh={refresh}
            />

            <DeviceSelection
                ref={deviceSelectionModalRef}
            />

            <Question
                ref={deleteModalRef}
                mainMessage="Сигурни ли сте?"
                agreeBtnText="Изтриване"
                agreeBtnClass="remove"
            />

            <Layout>
                <div className="container-fluid">

                    <div className="page-head">
                        <h1 className="display-6">
                            Списък с устройства
                        </h1>

                        <div className="buttons">
                            <Button variant="outline-dark" onClick={handleAdd} disabled={auth.getUser().permission('devices_edit') === false}>
                                Добави
                            </Button>
                        </div>
                    </div>

                    <br />

                    <div className="panel">
                        <Filter
                            filter={state.filter}
                            handleSearch={handleSearch}
                        />

                        {state.loading && !state.sorting
                            ?
                            <Loader />
                            :
                            state.data.length === 0 ?
                                <NoDataFound />
                                :
                                <>
                                    <Table responsive striped hover ref={tableRef}>
                                        <TableHeader
                                            tableRef={tableRef}
                                            activeSortKey={state.filter.sort}
                                            activeSortDir={state.filter.order}
                                            onSort={(col, dir) => handleSort(col, dir)}
                                        >
                                            <TableCol sortable sortKey="id">ID</TableCol>
                                            <TableCol sortable sortKey="name">Име</TableCol>
                                            <TableCol sortable sortKey="device_id">Модел</TableCol>
                                            <TableCol>URL</TableCol>
                                            <TableCol className="text-center" sortable sortKey="device">Ком.порт</TableCol>
                                            <TableCol className="text-center" sortable sortKey="speed">Скорост</TableCol>
                                            <TableCol className="text-center" sortable sortKey="created_at">Създаден</TableCol>
                                            <TableCol className="options">Опции</TableCol>
                                        </TableHeader>
                                        <tbody>
                                            {state.data.map(c =>
                                                <tr
                                                    key={'c-' + c.id}
                                                >
                                                    <td >
                                                        {c.id}
                                                    </td>
                                                    <td >
                                                        {c.name}
                                                    </td>
                                                    <td>
                                                        {c.base_device?.name || ''}
                                                    </td>
                                                    <td>
                                                        {handleURL(c.conn_proto, c.ip_suffix, c.tcp_port)}
                                                    </td>
                                                    <td className="text-center">
                                                        {c.device}
                                                    </td>
                                                    <td className="text-center">
                                                        {handleSpeed(c.speed)}
                                                    </td>
                                                    <td className="text-center">
                                                        {c.created_at !== null ? moment(c.created_at).format('DD.MM.YYYY HH:mm') : ''}
                                                    </td>

                                                    <td className="options">
                                                        <MenuOptions>
                                                            <Options
                                                                data={c}
                                                                handleEdit={handleEdit}
                                                                handleDelete={handleDelete}
                                                            />
                                                        </MenuOptions>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>

                                    <Pagination
                                        className="mt-3"
                                        page={state.filter.page}
                                        pages={state.pages}
                                        total={state.total}
                                        handlePage={handlePage}
                                    />
                                </>
                        }


                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Index;
