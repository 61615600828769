// import emptyFolder from 'assets/img/icons/empty-folder.png';
import emptyFolder256 from 'assets/img/icons/empty-folder@256.png';
// import emptyBox128 from 'assets/img/icons/empty-box@128.png';

function NoDataFound(props) {

    const size = props.size || 'lg';
    const text = props.text || props.children || 'Няма намерена информация!';

    const style = {
        img: {
            sm: {
                width: '128px',
            },
            lg: {
                width: '160px',
            }
        },
        text: {
            sm: {
                fontSize: '1rem'
            },
            lg: {
                fontSize: '1.25rem'
            }
        }
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
            <img src={emptyFolder256} alt="" style={style.img[size]} />
            <div style={{ textAlign: 'center', ...style.text[size] }}>
                {text}
            </div>
        </div>
    )
}

export default NoDataFound;