import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import moment from 'moment'
import { Modal, Button, Tabs, Tab, FloatingLabel, Form, Row, Col, Nav, Alert } from 'react-bootstrap';

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//misc
import SaveButton from 'components/misc/Button'
import HelpInfo from 'components/misc/Info'
import Info from 'components/modals/Info'
import Autocomplete from 'components/misc/Autocomplete';

function Intrastat(props, ref) {

    const defaultData = {

    }

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        edit: false,
        data: defaultData,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null,
    });

    const [countries, setCountries] = useState([]);
    const [transactionTypes, setTransactionTypes] = useState([]);
    const [deliveryTerms, setDeliveryTerms] = useState([]);
    const [transportTypes, setTransportTypes] = useState([]);
    const [worldCountries, setWorldCountries] = useState([]);
    const [regions, setRegions] = useState([]);

    const [validations, setValidations] = useValidation();

    const validationModalRef = useRef(null);

    useImperativeHandle(ref, () => ({
        add: (loadId) => {
            add(loadId);
        },
        edit: (loadId) => {
            edit(loadId);
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        }
    }));

    useEffect(() => {
        Api.get('intrastats/countries')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setCountries(res.data);
                }
            });

        Api.get('intrastats/transaction-types')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setTransactionTypes(res.data);
                }
            });

        Api.get('intrastats/delivery-terms')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setDeliveryTerms(res.data);
                }
            });

        Api.get('intrastats/transport-types')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setTransportTypes(res.data);
                }
            });

        Api.get('intrastats/world-countries')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setWorldCountries(res.data);
                }
            });

        Api.get('intrastats/regions')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setRegions(res.data);
                }
            });
    }, []);

    useEffect(() => {
        loadData();
    }, [state.edit]);

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
            intrastatId: null,
        }));

        setValidations(null);

        hide();
    }

    const add = (loadId) => {
        setState(prev => ({
            ...prev,
            data: defaultData,
            loadId: loadId,
            edit: new Date().getTime()
        }));

        show();
    }

    const edit = loadId => {
        setState(prev => ({
            ...prev,
            data: defaultData,
            loadId: loadId,
            edit: new Date().getTime()
        }));

        show();
    }

    const loadData = () => {
        if (!state.loadId) {
            return;
        }

        Api.get('storeloads/intrastat/show?id=' + state.loadId)
            .then(res => {
                let data = res.data;

                setState(prev => ({
                    ...prev,
                    data: data
                }));
            });
    }

    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = 'storeloads/intrastat/edit';

        let data = new FormData(e.target);

        if (state.loadId) {
            data.append('id', state.loadId)
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (typeof state.onSuccess === 'function') {
                state.onSuccess(res.data)
            }
            hide()
        }).catch(error => {
            const _err = error.response;

            if (_err && _err.status && _err.status === 422) {
                setValidations(_err.data.errors)
            }

        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }));
        });
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleCheckboxChange = e => {
        const name = e.target.name;
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: prev.data[name] ? false : true
            }
        }));
    }

    const handleChangeArticle = data => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                article: data,
                article_id: data.id
            }
        }))
    }

    return (
        <>
            <Info
                ref={validationModalRef}
                mainMessage="Моля, попълнете всички задължителни полета, означени с червен цвят!"
            />

            <Modal size="md" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <form onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Интрастат данни
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Row>
                            <Col className="mb-3">
                                <FloatingLabel label="Държава на изпращане">
                                    <Form.Select
                                        name="ms_cons_dest_code"
                                        value={state.data?.ms_cons_dest_code || ''}
                                        onChange={handleInputChange}
                                        isInvalid={Boolean(validations?.ms_cons_dest_code)}
                                    >
                                        <option value=""></option>
                                        {countries.map(c =>
                                            <option key={c.id} value={c.code}>{c.name}</option>
                                        )}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {validations?.ms_cons_dest_code && (validations.ms_cons_dest_code[0] || validations.ms_cons_dest_code)}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="mb-3">
                                <FloatingLabel label="Вид на сделката">
                                    <Form.Select
                                        name="nature_of_transaction_code"
                                        value={state.data?.nature_of_transaction_code || ''}
                                        onChange={handleInputChange}
                                        isInvalid={Boolean(validations?.nature_of_transaction_code)}
                                    >
                                        <option value=""></option>
                                        {transactionTypes.map(c =>
                                            <option key={c.id} value={c.code}>{c.name}</option>
                                        )}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {validations?.nature_of_transaction_code && (validations.nature_of_transaction_code[0] || validations.nature_of_transaction_code)}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="mb-3">
                                <FloatingLabel label="Условия на доставката">
                                    <Form.Select
                                        name="delivery_terms_code"
                                        value={state.data?.delivery_terms_code || ''}
                                        onChange={handleInputChange}
                                        isInvalid={Boolean(validations?.delivery_terms_code)}
                                    >
                                        <option value=""></option>
                                        {deliveryTerms.map(c =>
                                            <option key={c.id} value={c.code}>{c.name}</option>
                                        )}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {validations?.delivery_terms_code && (validations.delivery_terms_code[0] || validations.delivery_terms_code)}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="mb-3">
                                <FloatingLabel label="Вид транспорт">
                                    <Form.Select
                                        name="mode_of_transport_code"
                                        value={state.data?.mode_of_transport_code || ''}
                                        onChange={handleInputChange}
                                        isInvalid={Boolean(validations?.mode_of_transport_code)}
                                    >
                                        <option value=""></option>
                                        {transportTypes.map(c =>
                                            <option key={c.id} value={c.code}>{c.name}</option>
                                        )}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {validations?.mode_of_transport_code && (validations.mode_of_transport_code[0] || validations.mode_of_transport_code)}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="mb-3">
                                <FloatingLabel label="Нац. на т. средство">
                                    <Form.Select
                                        name="nationality_of_transport_vehicle"
                                        value={state.data?.nationality_of_transport_vehicle || ''}
                                        onChange={handleInputChange}
                                        isInvalid={Boolean(validations?.nationality_of_transport_vehicle)}
                                    >
                                        <option value=""></option>
                                        {worldCountries.map(c =>
                                            <option key={c.id} value={c.code}>{c.name}</option>
                                        )}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {validations?.nationality_of_transport_vehicle && (validations.nationality_of_transport_vehicle[0] || validations.nationality_of_transport_vehicle)}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <FloatingLabel label="Регион на потребление">
                                    <Form.Select
                                        name="region_code"
                                        value={state.data?.region_code || ''}
                                        onChange={handleInputChange}
                                        isInvalid={Boolean(validations?.region_code)}
                                    >
                                        <option value=""></option>
                                        {regions.map(c =>
                                            <option key={c.id} value={c.code}>{c.name}</option>
                                        )}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {validations?.region_code && (validations.region_code[0] || validations.region_code)}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            className="save"
                        />
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default forwardRef(Intrastat);
