import { forwardRef, useEffect, useImperativeHandle, useRef, useState, Fragment } from 'react'

//plugins
import moment from 'moment';
import { Modal, Button, Tabs, Tab, FloatingLabel, Form, Nav, Row, Col, Alert, Card } from 'react-bootstrap';

//icons
import { TrashFill, HouseDoorFill, Trash } from 'react-bootstrap-icons';

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//providers
import { useAuthDataContext } from 'providers/Auth';

//misc
import SaveButton from 'components/misc/Button'
import Info from 'components/modals/Info';
import HelpInfo from 'components/misc/Info';
import Autocomplete from 'components/misc/Autocomplete';
import DatePicker from 'components/partials/filter/DatePicker';

function AddOrEdit(props, ref) {

    const auth = useAuthDataContext();

    const defaultData = {
        valid_to_type: 1,
        reusable: 0,
        groups: [],
        categories: []
    }

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        edit: false,
        data: defaultData,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null,
    });

    const [cardsTypes, setCardsTypes] = useState([]);
    const [validations, setValidations] = useValidation();

    const validationModalRef = useRef(null);

    useImperativeHandle(ref, () => ({
        add: () => {
            add();
        },
        forClient: client => {
            forClient(client);
        },
        edit: (id) => {
            edit(id);
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        }
    }));

    useEffect(() => {
        if (!state.show) {
            return;
        }

        Api.get('cards/cards-types')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setCardsTypes(res.data);
                }
            });
    }, [state.show])

    useEffect(() => {
        loadData();
    }, [state.edit]);

    const show = (onEntering = null) => {
        let data = {}

        if (cardsTypes.length) {
            const cardType = cardsTypes[0];
            const valid_from = getFromDate(cardType.valid_from_type);
            const valid_to = getToDate(valid_from, cardType.valid_to_type, cardType.valid_to_num);

            data = {
                credit_left: cardType.credit,
                discount: cardType.discount,
                valid_from,
                valid_to
            }
        }

        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
            data: {
                ...prev.data,
                ...data
            }
        }))
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const forClient = client => {
        setState(prev => ({
            ...prev,
            id: null,
            data: {
                ...prev.data,
                client: client,
                client_id: client.id,
            }
        }));

        show();
    }

    const add = () => {
        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
        }));

        show();
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            initialData: defaultData,
            id: null,
            edit: false,
        }));

        setValidations(null);

        if (typeof state.onClose === 'function') {
            hide(state.onClose);
        } else {
            hide();
        }

        setState(prev => ({
            ...prev,
            onClose: null,
        }));
    }

    const edit = id => {
        setState(prev => ({
            ...prev,
            id: id,
            edit: new Date().getTime()
        }));

        show();
    }

    const loadData = () => {
        if (!state.id) {
            return;
        }

        Api.get('cards/show?id=' + state.id)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data,
                }))
            });
    }

    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = state.id ? 'cards/update' : 'cards/add';

        let data = new FormData(e.target);

        if (state.id) {
            data.append('id', state.id)
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (typeof state.onSuccess === 'function') {
                state.onSuccess(res.data)
            }

            handleClose()
        }).catch(error => {
            const _err = error.response;
            if (_err && _err.status && _err.status === 422) {
                hide(() => {
                    let modal = validationModalRef.current;

                    if (modal) {
                        modal.open();

                        modal.onClose(() => {
                            setValidations(_err.data.errors)
                            show();
                        });
                    }
                });
            }
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }));
        });
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value
        setState(name, value, 'data')
    }

    const handleDiscountChange = e => {
        const name = e.target.name
        let value = e.target.value

        if (!value.includes('+') && !value.includes('-') && value.length > 0) {
            value = '-' + value;
        }

        if (value.includes('+')) {
            value = value.replace('-', '')
        }

        setState(name, value, 'data')
    }

    const handleClientChange = (data) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                client: data,
                client_id: data.id,
            }
        }));
    }

    const handleCardTypeChange = e => {
        const name = e.target.name
        const value = e.target.value
        let cardType = cardsTypes.filter(t => Number(t.id) === Number(value));

        if (!cardType.length) {
            return;
        }

        cardType = cardType[0];

        let data = {}
        if (!state.edit) {
            let valid_from = getFromDate(cardType.valid_from_type);
            let valid_to = getToDate(valid_from, cardType.valid_to_type, cardType.valid_to_num);

            data = {
                credit_left: cardType.credit,
                discount: cardType.discount,
                valid_from,
                valid_to
            }
        }

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: value,
                ...data
            }
        }))
    }

    const getFromDate = (type) => {
        switch (type) {
            case 3:
                return getStartOfWeek();
                break;
            case 4:
                return getStartOfMonth();
                break;

            case 5:
                return getStartOfYear();
                break;
            default:
                return getCurrentDay();
                break;
        }
    }

    const getToDate = (date, type, datesToAdd) => {
        switch (type) {
            case 2:
                // add days
                return moment(date, 'YYYY-MM-DD').add(datesToAdd, 'years');
                break;
            case 3:
                //add weeks
                return moment(date, 'YYYY-MM-DD').add(datesToAdd, 'weeks');
                break;
            case 4:
                //add months
                return moment(date, 'YYYY-MM-DD').add(datesToAdd, 'months');
                break;
            case 5:
                //add years
                return moment(date, 'YYYY-MM-DD').add(datesToAdd, 'years');
                break;

        }
    }

    const getStartOfWeek = () => {
        const date = moment().startOf('isoweek').format('YYYY-MM-DD');
        return date;
    }

    const getStartOfMonth = () => {
        const date = moment().startOf('month').format('YYYY-MM-DD');
        return date;
    }

    const getStartOfYear = () => {
        const date = moment().startOf('month').format('YYYY-MM-DD');
        return date;
    }

    const getCurrentDay = () => {
        const date = moment().format('YYYY-MM-DD');
        return date;
    }

    return (
        <>
            <Info
                ref={validationModalRef}
                mainMessage="Моля, попълнете всички задължителни полета, означени с червен цвят!"
            />
            <Modal size="md" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <form onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Карта
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Col className="mb-3">
                            <FloatingLabel label="Тип карта*">
                                <Form.Select
                                    name="card_id"
                                    value={state.data.card_id || ''}
                                    onChange={handleCardTypeChange}
                                >
                                    {cardsTypes.map(c => <option key={c.id} value={c.id}>{c.name}</option>)}
                                </Form.Select>
                            </FloatingLabel>
                        </Col>
                        <Row>
                            <Col>
                                <FloatingLabel className="mb-3" label="Номер*">
                                    <Form.Control
                                        type="text"
                                        name={`num`}
                                        placeholder="Номер*"
                                        onChange={(e) => handleInputChange(e)}
                                        value={state.data?.num || ''}
                                        isInvalid={Boolean(validations && validations.num)}
                                    />

                                    <Form.Control.Feedback type="invalid">
                                        {validations && validations.num}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>

                        <Col className='mb-3'>
                            <Autocomplete
                                url="autocomplete/clients"
                                inputPlaceholder="Клиент"
                                inputIdName={`client_id`}
                                renderText={data => data?.name}
                                inputValue={state.data.client?.name || ''}
                                inputIdValue={state.data.client_id || ''}
                                error={Boolean(validations?.client_id)}
                                helperText={validations?.client_id && (validations?.client_id[0] || validations?.client_id)}
                                onChange={data => handleClientChange(data)}
                            />
                        </Col>

                        <Row>
                            <Col className="mb-3">
                                <DatePicker
                                    type="datetime-local"
                                    placeholder="Важи от*"
                                    name="valid_from"
                                    value={state.data?.valid_from ? moment(state.data.valid_from).format('YYYY-MM-DD HH:mm:ss') : ''}
                                    onChange={handleInputChange}
                                    isInvalid={Boolean(validations?.valid_from)}
                                    error={validations?.valid_from && (validations.valid_from[0] || validations.valid_from)}
                                    readOnly={auth.getUser().permission('cards_instanceeditdate') === false}
                                />
                            </Col>

                            <Col className="mb-3">
                                <DatePicker
                                    type="datetime-local"
                                    placeholder="Важи до"
                                    name="valid_to"
                                    value={state.data?.valid_to ? moment(state.data.valid_to).format('YYYY-MM-DD HH:mm:ss') : ''}
                                    onChange={handleInputChange}
                                    isInvalid={Boolean(validations?.valid_to)}
                                    error={validations?.valid_to && (validations.valid_to[0] || validations.valid_to)}
                                    readOnly={auth.getUser().permission('cards_instanceeditdate') === false}
                                    info="Празна дата означава без ограничение"
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <FloatingLabel className="mb-3" label="Оставащи използвания">
                                    <Form.Control
                                        type="text"
                                        name={`uses_left`}
                                        placeholder="Оставащи използвания"
                                        onChange={(e) => handleInputChange(e)}
                                        value={state.data?.uses_left || state.data?.uses_left == 0 ? state.data?.uses_left : ''}
                                        isInvalid={Boolean(validations && validations.uses_left)}
                                    />

                                    <Form.Control.Feedback type="invalid">
                                        {validations && validations.uses_left}
                                    </Form.Control.Feedback>
                                    <HelpInfo placement="top">празно - без ограничение</HelpInfo>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FloatingLabel className="mb-3" label="Отстъпка %">
                                    <Form.Control
                                        type="text"
                                        name={`discount`}
                                        placeholder="Отстъпка %"
                                        onChange={(e) => handleDiscountChange(e)}
                                        value={state.data?.discount || ''}
                                        isInvalid={Boolean(validations && validations.discount)}
                                    />

                                    <Form.Control.Feedback type="invalid">
                                        {validations && validations.discount}
                                    </Form.Control.Feedback>
                                    <HelpInfo placement="top">минус - отстъпка в проценти<br />плюс - надценка в проценти</HelpInfo>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FloatingLabel className="mb-3" label="Оставащ кредит">
                                    <Form.Control
                                        type="text"
                                        name={`credit_left`}
                                        placeholder="Оставащ кредит"
                                        onChange={(e) => handleInputChange(e)}
                                        value={state.data?.credit_left == '0' ? '0' : state.data?.credit_left || ''}
                                        isInvalid={Boolean(validations && validations.credit_left)}
                                    />

                                    <Form.Control.Feedback type="invalid">
                                        {validations && validations.credit_left}
                                    </Form.Control.Feedback>
                                    <HelpInfo placement="top">празно - без ограничение</HelpInfo>
                                </FloatingLabel>
                            </Col>

                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            className="save"
                        />
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default forwardRef(AddOrEdit);
