import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import { Modal, Button, Col, Row, FloatingLabel, Form } from 'react-bootstrap';

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//providers
import { useLanguageContext } from 'providers/Language'
import { useNavigate } from 'react-router-dom';

//misc
import Question from 'components/modals/Question';
import SaveButton from 'components/misc/Button'
import Info from 'components/modals/Info';
import HelpInfo from 'components/misc/Info';
import { useAppContext } from 'providers/App';
import Loader from 'components/misc/Loader';
import { TYPES as INVOICES_TYPES } from 'constants/invoices';
import { TYPES as DOCUMENTS_TYPES } from 'constants/documents';

function LinkDocument(props, ref) {

    const app = useAppContext();
    const navigate = useNavigate();

    const defaultData = {

    }

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        refresh: false,
        data: defaultData,
        loading: false,
        loadingBase: false,
        loadingData: false,
        loadingApply: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null,
        onClose: null,
    });

    const [validations, setValidations] = useValidation();

    const formRef = useRef(null);

    useImperativeHandle(ref, () => ({
        open: (id) => {
            open(id);
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        }
    }));

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const open = (id) => {
        setState(prev => ({
            ...prev,
            data: {
                num: id
            },
            id: id,
        }));

        show();
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
        }));

        setValidations(null);

        if (typeof state.onClose === 'function') {
            hide(state.onClose);
        } else {
            hide();
        }
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            refresh: new Date().getTime(),
        }));
    }

    const handleSave = e => {
        e.preventDefault();

        if (state.loading) {
            return;
        }

        setState(prev => ({
            ...prev,
            loading: true,
            loadingBase: true,
        }));

        setValidations(null);

        let url = 'accounts/link-document';

        let data = new FormData(e.target);

        data.append('id', state.id);

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (res.data.success) {
                state.onSuccess();

                hide(state.onClose);
            }
        }).catch(error => {
            const _err = error.response;

            if (_err && _err.status && _err.status === 422) {
                setValidations(_err.data.errors);
            }
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
                loadingBase: false,
                loadingApply: false,
            }));
        });
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    return (
        <>

            <Modal size="md" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <form ref={formRef} onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Прикачване на документ към сметка
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="mb-3">
                            <Col>
                                <FloatingLabel label="Вид документ">
                                    <Form.Select
                                        name="type_id"
                                        value={state.data.type_id || ''}
                                        onChange={handleInputChange}
                                        isInvalid={Boolean(validations?.type_id)}
                                    >
                                        <option></option>
                                        <optgroup label="Финансови">
                                            {INVOICES_TYPES.map(t =>
                                                <option key={t.id} value={t.type}>{t.name || ''}</option>
                                            )}
                                        </optgroup>
                                        <optgroup label="Други">
                                            {DOCUMENTS_TYPES.map(t =>
                                                <option key={t.id} value={t.type}>{t.name || ''}</option>
                                            )}
                                        </optgroup>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {validations?.type_id && (validations?.type_id[0] || validations?.type_id)}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <FloatingLabel label="Номер на документ">
                                    <Form.Control
                                        type="text"
                                        name={`no`}
                                        autoComplete="new-password"
                                        value={state.data.no || ''}
                                        placeholder="Номер на документ"
                                        onChange={(e) => handleInputChange(e)}
                                        isInvalid={Boolean(validations?.no)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {validations?.no && (validations?.no[0] || validations?.no)}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loadingBase}
                            disabled={state.loading}
                            className="save"
                            text="Прикачи"
                        />
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default forwardRef(LinkDocument);
