import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import { Modal, Button, Tabs, Tab, FloatingLabel, Form } from 'react-bootstrap';

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//misc
import SaveButton from 'components/misc/Button'
import { CaretDownFill, CaretRight } from 'react-bootstrap-icons';
import Loader from 'components/misc/Loader';

function AddOrEdit(props, ref) {

    const defaultData = {

    }

    const [state, setState] = useNestedState({
        show: false,
        data: defaultData,
        dataLoading: false,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null
    });

    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [expandedCategories, setExpandedCategories] = useState([]);

    const [validations, setValidations] = useValidation();

    const validationModalRef = useRef(null);

    useImperativeHandle(ref, () => ({
        add: () => {
            open();
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        }
    }));

    useEffect(() => {
        if (!state.show) {
            return;
        }

        setState(prev => ({
            ...prev,
            dataLoading: true,
        }));

        Api.get('navigation/categories')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setCategories(res.data);
                }
            })
            .finally(() => {
                setState(prev => ({
                    ...prev,
                    dataLoading: false,
                }));
            })

    }, [state.show]);

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }


    const open = () => {
        show();
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
        }));

        setValidations(null);

        hide();
    }

    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = 'navigation/add-categories'

        let data = new FormData(e.target);

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (typeof state.onSuccess === 'function') {
                state.onSuccess(res.data)
            }

            hide()
        }).catch(error => {
            const _err = error.response;

            if (_err && _err.status && _err.status === 422) {
                setValidations(_err.data.errors);
            }
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }));
        });
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleDateChange = e => {
        const name = e.target.name + '_ymd'
        const value = e.target.value

        setState(name, value, 'data')
    }

    const renderCategories = (categories, level = 0) => {
        return categories.map(category =>
            <React.Fragment key={category.id}>
                <div style={{ display: level === 0 || isCategoryExpanded(category.parent) ? 'flex' : 'none', alignItems: 'center', paddingLeft: (level * 20) + 'px', }}>

                    <span
                        style={{ marginRight: '3px', marginBottom: '3px', cursor: 'pointer', width: '16px' }}
                        onClick={e => expandCategory(category)}
                    >
                        {category.children.length > 0
                            ?
                            isCategoryExpanded(category) ? <CaretDownFill size="13" /> : <CaretRight size="13" />
                            :
                            ''
                        }
                    </span>

                    <Form.Check
                        type="checkbox"
                        id={'cbc-' + category.id}
                        name="categories[]"
                        value={category.id}
                        label={category?.translation?.name || category.name}
                        checked={isCategorySelected(category)}
                        disabled={category.selected ? true : false}
                        onChange={e => toggleCategory(category)}
                        style={{ color: hasChildCategorySelected(category) ? '#084298' : 'black' }}
                    />

                </div>
                <div style={{ display: isCategoryExpanded(category) ? 'initial' : 'none' }}>
                    {renderCategories(category.children, level + 1)}
                </div>
            </React.Fragment>
        );
    }

    const toggleCategory = category => {
        if (!category) {
            return;
        }

        if (isCategorySelected(category)) {
            setSelectedCategories(prev => prev.filter(i => Number(i) !== Number(category.id)))
        } else {
            setSelectedCategories(prev => prev.concat(Number(category.id)))
        }
    }

    const isCategorySelected = category => {
        if (!category) {
            return false;
        }

        let selected = selectedCategories.indexOf(Number(category.id)) > -1;

        if (selected) {
            return true;
        }

        return false;
    }

    const hasChildCategorySelected = category => {
        for (let i = 0; i < category.children.length; i++) {
            let child = category.children[i];

            if (isCategorySelected(child)) {
                return true;
            }

            if (hasChildCategorySelected(child)) {
                return true;
            }
        }

        return false;
    }

    const expandCategory = (category, check = true) => {

        if (!category) {
            return;
        }

        if (check && isCategoryExpanded(category)) {
            setExpandedCategories(prev => prev.filter(i => Number(i) !== Number(category.id)))
        } else {
            setExpandedCategories(prev => prev.concat(Number(category.id)))
        }
    }

    const isCategoryExpanded = category => {

        if (!category) {
            return false;
        }

        let expanded = expandedCategories.indexOf(Number(category.id)) > -1;

        if (expanded) {
            return true;
        }

        return false;
    }

    return (
        <>

            <Modal centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <form onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Изберете категория
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {state.dataLoading
                            ?
                            <Loader />
                            :
                            renderCategories(categories)
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            className="save"
                        />
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default forwardRef(AddOrEdit);
