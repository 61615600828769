import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import moment from 'moment'
import { Modal, Button, Tabs, Tab, FloatingLabel, Form, Row, Col, Nav, Card, Alert } from 'react-bootstrap';
import {
    TrashFill,
    PencilFill,
    Trash,
    XCircle,
    CheckCircle,
} from 'react-bootstrap-icons';

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//providers
import { useAppContext } from 'providers/App';
import { useAuthDataContext } from 'providers/Auth';

//misc
import SaveButton from 'components/misc/Button';
import Info from 'components/modals/Info';
import Question from 'components/modals/Question';
import HelpInfo from 'components/misc/Info';
import Autocomplete from 'components/misc/Autocomplete';
import EditClientModal from '../../clients_groups/partials/AddOrEdit';
import Articles from '../partials/Articles'
import Categories from '../partials/Categories';
import Stores from './Stores';
import AddArticle from './AddArticle';
import AddArticlesFromBarsy from './AddArticlesFromBarsy';

import Refs from 'Refs';

import DatePicker from 'components/partials/filter/DatePicker';
import Import from 'components/modals/Import';
import { TYPES } from 'constants/imports';

import './style.scss';
import ArticleDetails from './ArticleDetails';

const WEEK_DAYS = [
    {
        'id': 1,
        'name': 'Понеделник'
    },
    {
        'id': 2,
        'name': 'Вторник'
    },
    {
        'id': 3,
        'name': 'Сряда'
    },
    {
        'id': 4,
        'name': 'Четвъртък'
    },
    {
        'id': 5,
        'name': 'Петък'
    },
    {
        'id': 6,
        'name': 'Събота'
    },
    {
        'id': 7,
        'name': 'Неделя'
    }
]

function AddOrEdit(props, ref) {

    const defaultData = {
        modificator_mode: 0,
        modificators: [
            {},
            {},
            {}
        ],
        dates: [],
        times: [],
        week_days: [],
        clients: [],
        columnChecked: false,
        checkedGroup: [],
        checked_desks: [],
        articles: [],
        weight: 1
    }

    const app = useAppContext();
    const auth = useAuthDataContext();

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        edit: false,
        mainTabId: 'main',
        data: defaultData,
        loading: false,
        onSuccess: null,
        onClose: null,
        onEntering: null,
        onExiting: null,
        refresh: null
    });

    const [validations, setValidations] = useValidation();

    const [groups, setGroups] = useState([]);
    const [taxGroups, setTaxGroups] = useState([]);
    const [clientsGroups, setClientsGroups] = useState([]);
    const [articlesAttributes, setArticlesAttributes] = useState({});

    const questionModalRef = useRef(null);
    const validationModalRef = useRef(null);
    const editClientModalRef = useRef(null)
    const categoryModalRef = useRef(null);
    const articleDetailModalRef = useRef(null);
    const addArticleModalRef = useRef(null);
    const addArticlesFromBarsyModalRef = useRef(null);
    const importModalRef = useRef(null);

    useImperativeHandle(ref, () => ({
        add: () => {
            handleShow();
        },
        edit: (id) => {
            edit(id);
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        }
    }));

    useEffect(() => {
        if (!state.show) {
            return;
        }

        if (auth.getUser().permission('pricelists_edit') === false) {
            app.showError('Нямате право да редактирате ценово правило!');

            hide(state.onClose);
        }

    }, [state.show]);

    useEffect(() => {
        loadData();
    }, [state.edit, state.refresh]);

    useEffect(() => {
        if (!state.show) {
            return;
        }

        if (groups.length === 0) {
            Api.get('price_rules/groups')
                .then(res => {
                    if (Array.isArray(res.data)) {
                        setGroups(res.data);
                    }
                });
        }

        if (taxGroups.length === 0) {
            Api.get('price_rules/tax_groups')
                .then(res => {
                    if (Array.isArray(res.data)) {
                        setTaxGroups(res.data);
                    }
                });
        }

        if (clientsGroups.length === 0) {
            Api.get('price_rules/clients_groups')
                .then(res => {
                    if (Array.isArray(res.data)) {
                        setClientsGroups(res.data);
                    }
                });
        }
    }, [state.show])

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const handleShow = () => {
        setState(prev => ({
            ...prev,
            mainTabId: 'main',
            data: defaultData,
            id: null,
        }));

        setArticlesAttributes({});

        show();
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            mainTabId: 'main',
            data: defaultData,
            id: null,
        }));

        setArticlesAttributes({});

        setValidations(null);

        if (typeof state.onClose === 'function') {
            hide(state.onClose);
        } else {
            hide();
        }

        setState(prev => ({
            ...prev,
            onClose: null,
        }));
    }

    const edit = id => {
        setState(prev => ({
            ...prev,
            id: id,
            edit: new Date().getTime()
        }));

        show();
    }

    const loadData = () => {
        if (!state.id) {
            return;
        }

        Api.get('price_rules/show?id=' + state.id)
            .then(res => {
                let data = res.data;

                if (data.week_days) {
                    let week_days = []

                    res.data.week_days.map(day => {
                        week_days.push(day.day_id);
                    });

                    data.week_days = week_days;
                }

                if (data.modificators?.length == 0) {
                    data.modificators = defaultData.modificators;
                }

                if (data.clients_groups?.length > 0) {
                    let checkedGroup = data.clients_groups.map(t => t.id)
                    data.checkedGroup = checkedGroup;
                    if (checkedGroup.length == clientsGroups.length) {
                        data.columnChecked = true
                    }
                } else {
                    data.checkedGroup = []
                }

                data.checked_desks = [];

                if (data.cashdesks?.length > 0) {
                    data.cashdesks.map(cashdesk => {
                        data.checked_desks.push(cashdesk.id);
                    });
                }

                if (!res.data.modificator_mode) {
                    if (res.data.modificator) {
                        data.modificator_value = res.data.modificator.modificator_value
                    }
                }

                setState(prev => ({
                    ...prev,
                    data,
                    // mainTabId: 'main',
                }))
            });
    }

    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = state.id ? 'price_rules/update' : 'price_rules/add';

        let data = new FormData(e.target);

        if (state.id) {
            data.append('id', state.id)
        }

        data.append('articles', JSON.stringify(articlesAttributes))

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (typeof state.onSuccess === 'function') {
                state.onSuccess(res.data)
            }

            app.showSuccess();

            edit(res.data.id);
        }).catch(error => {
            const _err = error.response;
            if (_err && _err.status && _err.status === 422) {
                hide(() => {
                    let modal = validationModalRef.current;

                    if (modal) {
                        modal.open();

                        modal.onClose(() => {
                            setValidations(_err.data.errors)
                            show();
                        });
                    }
                });
            }

        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }));
        });
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const setMainTab = key => {
        setState(prev => ({
            ...prev,
            mainTabId: key
        }));
    }

    const handleCheckboxChange = e => {
        const name = e.target.name;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: prev.data[name] ? false : true
            }
        }));
    }

    const removeModificator = (index) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                modificators: prev.data.modificators.filter((d, i) => Number(i) !== Number(index))
            }
        }));
    }

    const addModificator = () => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                modificators: prev.data.modificators.concat({})
            }
        }));
    }

    const handleModificatorChange = (e, index) => {
        const value = e.target.value
        let name = e.target.name
        if (name.includes('[]')) {
            name = name.replace('[]', '')
        }

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                modificators: Object.values({
                    ...prev.data.modificators,
                    [index]: {
                        ...prev.data.modificators[index],
                        [name]: value
                    }
                })
            }
        }));
    }

    const removeTime = (index) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                times: prev.data.times.filter((d, i) => Number(i) !== Number(index))
            }
        }));
    }

    const addTime = () => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                times: prev.data.times.concat({})
            }
        }));
    }

    const removeDate = (index) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                dates: prev.data.dates.filter((d, i) => Number(i) !== Number(index))
            }
        }));
    }

    const addDate = () => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                dates: prev.data.dates.concat({})
            }
        }));
    }

    const handleDateChange = (e, index) => {
        const value = e.target.value
        let name = e.target.name

        name = name.split('[')[0]

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                dates: Object.values({
                    ...prev.data.dates,
                    [index]: {
                        ...prev.data.dates[index],
                        [name]: value
                    }
                })
            }
        }));
    }

    const handleTimeChange = (e, index) => {
        const value = e.target.value
        let name = e.target.name

        name = name.split('[')[0]

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                times: Object.values({
                    ...prev.data.times,
                    [index]: {
                        ...prev.data.times[index],
                        [name]: value
                    }
                })
            }
        }));
    }

    const handleWeekDays = (e, id) => {
        // TODO
        let week_days = state.data.week_days
        if (week_days.includes(id)) {
            week_days = week_days.filter((t) => Number(t) !== Number(id))
        } else {
            week_days.push(id)
        }

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                week_days: week_days
            }
        }));
    }

    const removeClient = (index) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                clients: prev.data.clients.filter((d, i) => Number(i) !== Number(index))
            }
        }));
    }

    const addClient = () => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                clients: prev.data.clients.concat({})
            }
        }));
    }

    const handleClient = (data, index) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                clients: Object.values({
                    ...prev.data.clients,
                    [index]: data
                })
            }
        }));
    }

    const handleClientGroup = (id) => {

        let columnChecked = false
        let checkedGroup = state.data.checkedGroup

        if (checkedGroup.includes(id)) {
            checkedGroup = checkedGroup.filter((t) => Number(t) !== Number(id))
        } else {
            checkedGroup.push(id)
        }

        if (checkedGroup.length == clientsGroups.length) {
            columnChecked = true
        }

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                columnChecked,
                checkedGroup
            }
        }));
    }

    const handleColumn = () => {
        let columnChecked = !state.data.columnChecked
        let checkedGroup = state.data.checkedGroup

        if (!columnChecked) {
            checkedGroup = [];
        } else {
            checkedGroup = clientsGroups.map(t => t.id)
        }

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                columnChecked,
                checkedGroup
            }
        }))
    }

    const handleEditClientGroup = (id) => {
        hide(() => {
            let modal = editClientModalRef.current;

            if (modal) {
                modal.edit(id);

                modal.onSuccess(() => {
                    setState(prev => ({
                        ...prev,
                        refresh: new Date().getTime()
                    }));
                    show();
                });

                modal.onClose(() => {
                    show();
                });

            }
        });
    }

    const handleModificatorTabs = e => {
        const name = e.target.name;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: prev.data[name] ? false : true
            }
        }));
    }

    const handleClientsTabValidate = () => {
        let valid = false;
        let main = ['client_id'];

        if (validations) {
            main.map(t => {
                if (validations[t] && state.mainTabId !== 'clients') {
                    valid = true
                }
            })
        }

        return valid;
    }

    const handleTimeFrameTabValidate = () => {
        let valid = false;
        let main = ['from_date', 'to_date', 'from_time', 'to_time'];

        if (validations) {
            main.map(t => {
                if (validations[t] && state.mainTabId !== 'timeframe') {
                    valid = true
                }
            })
        }

        return valid;
    }

    const handleMainTabValidate = () => {
        let valid = false;
        let main = ['name', 'group_id', 'pricelist_type_id', 'weight'];

        if (validations) {
            main.map(t => {
                if (validations[t] && state.mainTabId !== 'main') {
                    valid = true
                }
            })
        }

        return valid;
    }

    const handleRulesTabValidate = () => {
        let valid = false;
        let main = [
            'begin_price_type',
            'modificator_type',
            'modificator_mode',
            'single_modificator_value',
            'modificator_key',
            'modificator_value',
            'modificator_round_type'
        ];

        if (validations) {
            main.map(t => {
                if (validations[t] && state.mainTabId !== 'rules') {
                    valid = true
                }
            })
        }

        return valid;
    }

    const handleArticlesTabValidate = () => {
        let valid = false;
        let main = ['article_id'];

        if (validations) {
            main.map(t => {
                if (validations[t] && state.mainTabId !== 'articles') {
                    valid = true
                }
            })
        }

        return valid;
    }

    const handleRefresh = () => {
        setState(prev => ({
            ...prev,
            refresh: new Date().getTime()
        }));
    }

    const handleActivate = (activate = false) => {
        if (!state.data.id) return;

        if (activate == 1) {
            hide(() => {
                let modal = questionModalRef.current;

                if (modal) {
                    modal.open('Сигурни ли сте, че искате да го активирате?');

                    modal.onCancel(() => {
                        show();
                    })

                    modal.onSuccess(() => {
                        handleActivateSave(activate);
                        show()
                    })
                }
            });
        } else {
            handleActivateSave(activate)
        }
    }

    const handleActivateSave = (activate) => {
        let url = `price_rules/change_status?status=${activate ? 1 : 0}&id=${state.data.id}`

        Api.get(url).then(res => {
            handleRefresh();

            if (typeof state.onSuccess === 'function') {
                state.onSuccess(res.data)
            }
        })
    }

    const setArticleAttribute = (rowId, key, val) => {
        setArticlesAttributes(prev => ({
            ...prev,
            [rowId]: prev[rowId] ? {
                ...prev[rowId],
                [key]: val
            } : {
                [key]: val
            }
        }));
    }

    const showArticle = id => {
        let modal = Refs.getInstance().getRef('article');

        if (!modal) {
            return;
        }

        hide(() => {
            modal.open(id);
        });

        modal.onClose(() => {
            show();
        });
    }

    const hasTabPermission = key => {
        let map = {
            main: 'pricelists_edit',
            timeframe: 'pricelists_edit',
            rules: 'pricelists_edit',
            articles: 'pricelists_edit',
            clients: 'pricelists_edit',
            clients_groups: 'pricelists_edit',
            stores: 'pricelists_edit',
        };

        let permission = map[key];

        if (permission && auth.getUser().permission(permission) === false) {
            return false;
        }

        return true;
    }

    return (
        <>
            <Info
                ref={validationModalRef}
                mainMessage="Моля, попълнете всички задължителни полета, означени с червен цвят!"
            />

            <Question
                ref={questionModalRef}
            />

            <EditClientModal
                ref={editClientModalRef}
            />

            <Categories
                ref={categoryModalRef}
                id={state.id}
            />

            <ArticleDetails
                ref={articleDetailModalRef}
                id={state.id}
            />

            <AddArticle
                ref={addArticleModalRef}
                id={state.id}
            />

            <AddArticlesFromBarsy
                ref={addArticlesFromBarsyModalRef}
                id={state.id}
            />

            <Import
                ref={importModalRef}
                type={TYPES.PRICERULE}
                createText="Замяна"
                updateText="Добавяне"
            />

            <Modal enforceFocus={false} size="xl" centered backdrop="static" show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <form onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Ценово правило
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Tab.Container activeKey={state.mainTabId} onSelect={key => setMainTab(key)}>
                            <Row>
                                <Col xl={2} sm={2}>
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link style={{ color: handleMainTabValidate() ? 'red' : '' }} eventKey="main">Основни данни</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link style={{ color: handleTimeFrameTabValidate() ? 'red' : '' }} eventKey="timeframe">Времеви рамки</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link style={{ color: handleRulesTabValidate() ? 'red' : '' }} eventKey="rules">Правила</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link style={{ color: handleArticlesTabValidate() ? 'red' : '' }} eventKey="articles">Артикули</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link style={{ color: handleClientsTabValidate() ? 'red' : '' }} eventKey="clients">Клиенти</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="clients_groups">Клиентски групи</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="stores">Търговски обекти</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                <Col xl={10} sm={10}>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="main">

                                            <Col>
                                                <FloatingLabel className="mb-3" label="Име*">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Име*"
                                                        name="name"
                                                        value={state.data.name || ''}
                                                        onChange={handleInputChange}
                                                        isInvalid={Boolean(validations && validations.name)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {validations && validations.name && (validations.name[0] || validations.name)}
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>
                                            </Col>

                                            <Row className='mb-3'>
                                                <Col>
                                                    <FloatingLabel label="Тип*">
                                                        <Form.Select
                                                            name="pricelist_type_id"
                                                            value={state.data.pricelist_type_id || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.pricelist_type_id)}
                                                        >
                                                            <option value={1}>Цена - артикули</option>
                                                            {/* <option value={2}>Бонус - сметка</option>
                                                            <option value={3}>Отстъпка - сметка</option>
                                                            <option value={4}>Отстъпка - абонамент</option>
                                                            <option value={5}>Доставна цена</option>
                                                            <option value={6}>article_discount</option>
                                                            <option value={7}>Цена - артикул абонамент</option> */}
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.pricelist_type_id && (validations.pricelist_type_id[0] || validations.pricelist_type_id)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>

                                                <Col>
                                                    <FloatingLabel label="Група*">
                                                        <Form.Select
                                                            name="group_id"
                                                            value={state.data.group_id || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.group_id)}
                                                        >
                                                            {groups.map(g =>
                                                                <option key={`group_id-${g.id}`} value={g.id}>{g.name}</option>
                                                            )}

                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.group_id && (validations.group_id[0] || validations.group_id)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>

                                                <Col>
                                                    <FloatingLabel label="Тежест*">
                                                        <Form.Control
                                                            type="number"
                                                            placeholder="Тежест*"
                                                            name="weight"
                                                            min={1}
                                                            value={state.data.weight || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.weight)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.weight && (validations.weight[0] || validations.weight)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>

                                            <Col>
                                                <FloatingLabel label="Описание" >
                                                    <Form.Control
                                                        as="textarea"
                                                        style={{ height: '100px' }}
                                                        name={`description`}
                                                        autoComplete="new-password"
                                                        value={state.data.description || ''}
                                                        placeholder="Описание"
                                                        onChange={(e) => handleInputChange(e)}
                                                        isInvalid={Boolean(validations?.description)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {validations?.description && (validations?.description[0] || validations?.description)}
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>
                                            </Col>

                                        </Tab.Pane>

                                        <Tab.Pane eventKey="timeframe">
                                            <Card className="mb-3">
                                                <Card.Header>
                                                    По дати
                                                    <HelpInfo marginLeft="5px" className="ml-3" placement="top">
                                                        Задава периоди от дата до дата за които ценовото правило е в дейстиве.
                                                        Може да бъде един период или няколко отделени на нов ред. Датите могат да са от две последователни години.
                                                    </HelpInfo>
                                                </Card.Header>
                                                <Card.Body>
                                                    {
                                                        state.data.dates.length == 0 ?
                                                            <Alert variant="primary">
                                                                Няма информация за периоди по дати
                                                            </Alert>
                                                            :
                                                            state.data.dates.map((t, index) =>
                                                                <Row key={`date-${t.id}`} >

                                                                    <Form.Control
                                                                        key={`time-${t.id}`}
                                                                        type="hidden"
                                                                        name={`date_id[]`}
                                                                        value={t.id || ''}
                                                                    />

                                                                    <Col className="mb-3">
                                                                        <DatePicker
                                                                            placeholder="От дата"
                                                                            name={`from_date[]`}
                                                                            onChange={(e) => handleDateChange(e, index)}
                                                                            value={t.from_date ? moment(t.from_date).format('YYYY-MM-DD') : ''}
                                                                            isInvalid={Boolean(validations?.from_date && validations.from_date[index])}
                                                                            error={validations?.from_date && validations.from_date[index]}
                                                                        />
                                                                    </Col>
                                                                    <Col>
                                                                        <DatePicker
                                                                            placeholder="До дата"
                                                                            name={`to_date[]`}
                                                                            onChange={(e) => handleDateChange(e, index)}
                                                                            value={t.to_date ? moment(t.to_date).format('YYYY-MM-DD') : ''}
                                                                            isInvalid={Boolean(validations?.to_date && validations.to_date[index])}
                                                                            error={validations?.to_date && validations.to_date[index]}
                                                                        />
                                                                    </Col>
                                                                    <Col className="mb-3" style={{ display: 'flex', alignItems: 'center' }} xs="auto">
                                                                        <Button variant="danger" size="sm" onClick={e => removeDate(index)}>
                                                                            <TrashFill />
                                                                        </Button>
                                                                    </Col>
                                                                </Row>
                                                            )}
                                                    <Button variant="outline-dark" size="sm" onClick={addDate}>
                                                        Добави период по дата
                                                    </Button>
                                                </Card.Body>
                                            </Card>

                                            <Card className="mb-3">
                                                <Card.Header>
                                                    По ден от седмицата
                                                    <HelpInfo marginLeft="5px" placement="top">Може да бъде един или няколко дни</HelpInfo>
                                                </Card.Header>
                                                <Card.Body>
                                                    <Row>
                                                        {WEEK_DAYS.map((t, index) =>
                                                            <Col key={index}>
                                                                <Form.Check
                                                                    key={`week_day-${t.id}`}
                                                                    type="checkbox"
                                                                    name={`day_id[${t.id}]`}
                                                                    label={t.name}
                                                                    value={t.id}
                                                                    checked={state.data.week_days.includes(t.id)}
                                                                    onChange={(e) => handleWeekDays(e, t.id)}
                                                                />
                                                            </Col>
                                                        )}
                                                    </Row>
                                                </Card.Body>
                                            </Card>

                                            <Card >
                                                <Card.Header>
                                                    По часове
                                                    <HelpInfo marginLeft="5px" placement="top">
                                                        Задава часовите периоди, в които ценовото правило е в дейстиве. Може да бъде един или няколко периода.
                                                    </HelpInfo>
                                                </Card.Header>
                                                <Card.Body>
                                                    {
                                                        state.data.times.length == 0 ?

                                                            <Alert variant="primary">
                                                                Няма информация за периоди по часове
                                                            </Alert>
                                                            :
                                                            state.data.times.map((t, index) =>
                                                                <Row key={`t-${index}`}>

                                                                    <Form.Control
                                                                        key={`time-${t.id}`}
                                                                        type="hidden"
                                                                        name={`time_id[]`}
                                                                        value={t.id || ''}
                                                                    />

                                                                    <Col className="mb-3">
                                                                        <DatePicker
                                                                            type="time"
                                                                            placeholder="От час"
                                                                            name={`from_time[]`}
                                                                            onChange={(e) => handleTimeChange(e, index)}
                                                                            value={t.from_time || ''}
                                                                            isInvalid={Boolean(validations?.from_time && validations.from_time[index])}
                                                                            error={validations?.from_time && validations.from_time[index]}
                                                                        />
                                                                    </Col>
                                                                    <Col className="mb-3">
                                                                        <DatePicker
                                                                            type="time"
                                                                            placeholder="До час"
                                                                            name={`to_time[]`}
                                                                            onChange={(e) => handleTimeChange(e, index)}
                                                                            value={t.to_time || ''}
                                                                            isInvalid={Boolean(validations?.to_time && validations.to_time[index])}
                                                                            error={validations?.to_time && validations.to_time[index]}
                                                                        />
                                                                    </Col>
                                                                    <Col className="mb-3" style={{ display: 'flex', alignItems: 'center' }} xs="auto">
                                                                        <Button variant="danger" size="sm" onClick={e => removeTime(index)}>
                                                                            <TrashFill />
                                                                        </Button>
                                                                    </Col>
                                                                </Row>)}
                                                    <Button variant="outline-dark" size="sm" onClick={addTime}>
                                                        Добави период по час
                                                    </Button>
                                                </Card.Body>
                                            </Card>
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="rules">
                                            <Col>
                                                <FloatingLabel label="Цена основа*">
                                                    <Form.Select
                                                        name="begin_price_type"
                                                        value={state.data.begin_price_type || ''}
                                                        onChange={handleInputChange}
                                                        isInvalid={Boolean(validations && validations.begin_price_type)}
                                                    >
                                                        <option value={1}>Продажна цена</option>
                                                        <option value={2}>Средно претеглена себестойност</option>
                                                        <option value={3}>Последна себестойност</option>
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">
                                                        {validations && validations.begin_price_type && (validations.begin_price_type[0] || validations.begin_price_type)}
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>
                                            </Col>

                                            <Row className="mb-3 mt-3">
                                                <Col>
                                                    <Form.Check
                                                        type="switch"
                                                        name="price_tax_mode"
                                                        label={<div>С ДДС  <HelpInfo>Дали изчислената цена от ценовото правило да има включено ДДС или не</HelpInfo></div>}
                                                        checked={Number(state.data?.price_tax_mode) === 1}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row className="mb-3">
                                                <Col>
                                                    <FloatingLabel label="ДДС група	">
                                                        <Form.Select
                                                            name="price_tax_id"
                                                            value={state.data.price_tax_id || ''}
                                                            onChange={handleInputChange}
                                                        >
                                                            <option></option>
                                                            {taxGroups.map(g =>
                                                                <option key={`tax_id-${g.id}`} value={g.id}>{g.name}</option>
                                                            )}
                                                        </Form.Select>
                                                    </FloatingLabel>
                                                </Col>
                                                <Col>
                                                    <FloatingLabel label="Тип ценови модификатор*">
                                                        <Form.Select
                                                            name="modificator_type"
                                                            value={state.data.modificator_type || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.modificator_type)}
                                                        >
                                                            <option value={1}>Надценка в %</option>
                                                            <option value={2}>Отстъпка в %</option>
                                                            <option value={3}>Надценка в лв</option>
                                                            <option value={4}>Отстъпка в лв</option>
                                                            <option value={5}>Точна цена</option>
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.modificator_type && (validations.modificator_type[0] || validations.modificator_type)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>

                                            <Card className="mb-3">
                                                <Card.Header>
                                                    <Row>
                                                        <Col xs="auto">
                                                            Модификатор
                                                            <HelpInfo placement="top" marginLeft="5px">
                                                                Числова стойност на ценовия модификатор. Може и да е множествена, като тогава се определят прагове в лева или в единици, когато типа е Цена-артикул
                                                            </HelpInfo>
                                                        </Col>
                                                        <Col xs="auto" style={{ marginLeft: 'auto' }}>
                                                            <div className="custom-radio">
                                                                <label>
                                                                    <input type="radio" name="modificator_mode" value={0} checked={Number(state.data.modificator_mode) === 0} onChange={handleModificatorTabs} />
                                                                    <span>
                                                                        Единичен
                                                                    </span>
                                                                </label>
                                                                <label>
                                                                    <input type="radio" name="modificator_mode" value={1} checked={Number(state.data.modificator_mode) === 1} onChange={handleModificatorTabs} />
                                                                    <span>
                                                                        Множествен
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Card.Header>
                                                <Card.Body>

                                                    {Number(state.data.modificator_mode) === 0
                                                        ?
                                                        <Row>
                                                            <Col>
                                                                <FloatingLabel label={state.data.modificator_type > 2 ? 'Модификатор (лв)' : 'Модификатор процент (%)'}>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder={state.data.modificator_type > 2 ? 'лв' : 'Процент (%)'}
                                                                        name="single_modificator_value"
                                                                        value={state.data.single_modificator_value || ''}
                                                                        onChange={handleInputChange}
                                                                        isInvalid={Boolean(validations && validations.single_modificator_value)}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {validations && validations.single_modificator_value}
                                                                    </Form.Control.Feedback>
                                                                </FloatingLabel>
                                                            </Col>
                                                        </Row>
                                                        :
                                                        <>
                                                            {state.data.modificators.map((t, index) =>
                                                                <Row key={`m-${index}`} className="mb-3">
                                                                    <Form.Control type="hidden" name={`modificator_id[]`} value={t.id || ''} />
                                                                    <Col>
                                                                        <FloatingLabel label="При количество ≥ на ед.">
                                                                            <Form.Control
                                                                                key={`key-${t.id}`}
                                                                                type="text"
                                                                                placeholder="При количество ≥ на ед."
                                                                                name={`modificator_key[]`}
                                                                                value={t.modificator_key || ''}
                                                                                onChange={(e) => handleModificatorChange(e, index)}
                                                                                isInvalid={Boolean(validations?.modificator_key && validations.modificator_key[index])}
                                                                            />
                                                                            <Form.Control.Feedback type="invalid">
                                                                                {validations?.modificator_key && validations.modificator_key[index]}
                                                                            </Form.Control.Feedback>
                                                                        </FloatingLabel>
                                                                    </Col>
                                                                    <Col>
                                                                        <FloatingLabel label={state.data.modificator_type > 2 ? 'Модификатор (лв)' : 'Модификатор процент (%)'}>
                                                                            <Form.Control
                                                                                key={`percent-${t.id}`}
                                                                                type="text"
                                                                                placeholder={state.data.modificator_type > 2 ? 'лв' : 'Процент (%)'}
                                                                                name={`modificator_value[]`}
                                                                                value={t.modificator_value || ''}
                                                                                onChange={(e) => handleModificatorChange(e, index)}
                                                                                isInvalid={Boolean(validations?.modificator_value && validations.modificator_value[index])}
                                                                            />
                                                                            <Form.Control.Feedback type="invalid">
                                                                                {validations?.modificator_value && validations.modificator_value[index]}
                                                                            </Form.Control.Feedback>
                                                                        </FloatingLabel>
                                                                    </Col>
                                                                    {state.data.modificators.length > 2 ? <Col style={{ display: 'flex', alignItems: 'center' }} xs="auto">
                                                                        <Button variant="danger" size="sm" onClick={e => removeModificator(index)}>
                                                                            <TrashFill />
                                                                        </Button>
                                                                    </Col> : ''}
                                                                </Row>
                                                            )}
                                                            <Button variant="outline-dark" size="sm" onClick={addModificator}>
                                                                Добави нов модификатор
                                                            </Button>
                                                        </>}
                                                </Card.Body>
                                            </Card>

                                            <Col>
                                                <FloatingLabel label="Закръгляне*">
                                                    <Form.Select
                                                        name="modificator_round_type"
                                                        value={state.data.modificator_round_type || ''}
                                                        onChange={handleInputChange}
                                                        isInvalid={Boolean(validations && validations.modificator_round_type)}
                                                    >
                                                        <option value={''}>Няма</option>
                                                        <option value={1}>До цял лев</option>
                                                        <option value={2}>Надолу до цял лев</option>
                                                        <option value={3}>Нагоре до цял лев</option>

                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">
                                                        {validations && validations.modificator_round_type && (validations.modificator_round_type[0] || validations.modificator_round_type)}
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>
                                            </Col>

                                        </Tab.Pane>

                                        <Tab.Pane eventKey="articles">
                                            <Articles
                                                state={state}
                                                setState={setState}
                                                validations={validations}
                                                show={show}
                                                hide={hide}
                                                taxGroups={taxGroups}
                                                categoryModalRef={categoryModalRef}
                                                articleDetailModalRef={articleDetailModalRef}
                                                addArticleModalRef={addArticleModalRef}
                                                addArticlesFromBarsyModalRef={addArticlesFromBarsyModalRef}
                                                questionModalRef={questionModalRef}
                                                importModalRef={importModalRef}
                                                handleRefresh={handleRefresh}
                                                attributes={articlesAttributes}
                                                setAttribute={setArticleAttribute}
                                                showArticle={showArticle}
                                            />
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="clients">
                                            {state.data.clients.length == 0 ?

                                                <Alert variant="primary">
                                                    Няма информация за клиенти
                                                </Alert>
                                                :
                                                state.data.clients.map((t, index) =>
                                                    <Row key={`c-${t.id}-${index}`}>
                                                        <Col className="mb-3" xs={2}>
                                                            <FloatingLabel label="ID">
                                                                <Form.Control
                                                                    type="text"
                                                                    value={t.id || ''}
                                                                    disabled
                                                                />
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col xs={7}>
                                                            <Autocomplete
                                                                key={`client-${t.id}-${index}`}
                                                                url="autocomplete/clients"
                                                                inputPlaceholder="Клиент"
                                                                inputIdName={`client_id[${index}]`}
                                                                renderInputText={data => data?.name}
                                                                renderText={data => (
                                                                    <div>
                                                                        {data.name}
                                                                        <br />
                                                                        <span>
                                                                            {data.email}
                                                                        </span>
                                                                    </div>
                                                                )}
                                                                inputValue={t.name || ''}
                                                                inputIdValue={t.id || ''}
                                                                onChange={data => handleClient(data, index)}
                                                                error={Boolean(validations?.client_id && validations.client_id[index])}
                                                                helperText={validations?.client_id && validations.client_id[index]}
                                                            />
                                                        </Col>
                                                        <Col className="mb-3" xs={2}>
                                                            <FloatingLabel label="Отстъпка">
                                                                <Form.Control
                                                                    key={`client-discount-${t.id}-${index}`}
                                                                    type="text"
                                                                    value={`${t.discount ? t.discount + '%' : ''}`}
                                                                    disabled
                                                                />
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col className="mb-3" style={{ display: 'flex', alignItems: 'center' }} xs="auto">
                                                            <Button variant="danger" size="sm" onClick={e => removeClient(index)}>
                                                                <TrashFill />
                                                            </Button>
                                                        </Col>

                                                        <Form.Control
                                                            key={`client-hidden-${t.client_id}-${index}`}
                                                            type="hidden"
                                                            name={`price_rule_client_id[]`}
                                                            value={t.prc_id}
                                                        />
                                                    </Row>)}
                                            <Button variant="outline-dark" size="sm" onClick={addClient}>
                                                Добави клиент
                                            </Button>

                                        </Tab.Pane>

                                        <Tab.Pane eventKey="clients_groups">
                                            <Card>
                                                <Card.Header>
                                                    <Row>
                                                        <Col>
                                                            Включени клиентски групи
                                                        </Col>
                                                        <Col xs={1}>
                                                            <Form.Check
                                                                style={{ display: 'flex', justifyContent: 'flex-end' }}
                                                                type="switch"
                                                                name="column_check"
                                                                checked={state.data.columnChecked}
                                                                onChange={() => handleColumn()}
                                                            />
                                                        </Col>
                                                        <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                        </Col>
                                                    </Row>
                                                </Card.Header>
                                                <Card.Body>
                                                    {clientsGroups.length == 0 ?
                                                        <Alert variant="primary">
                                                            Няма информация за клиентски групи
                                                        </Alert>
                                                        :
                                                        clientsGroups.map((t, index) =>
                                                            <Row key={`cg-${index}`} className={index < clientsGroups.length - 1 ? 'mb-3' : ''}>
                                                                <Col>
                                                                    {t.name}
                                                                </Col>
                                                                <Col xs={1}>
                                                                    <Form.Check
                                                                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                                                                        key={`client-group-${t.id}`}
                                                                        name={`client_group_id[]`}
                                                                        value={t.id}
                                                                        type="switch"
                                                                        checked={state.data.checkedGroup.includes(t.id)}
                                                                        onChange={() => handleClientGroup(t.id)}
                                                                    />
                                                                </Col>
                                                                <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                    <Button variant="outline-dark" size="sm" onClick={() => handleEditClientGroup(t.id)}>
                                                                        <PencilFill />
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        )}
                                                </Card.Body>
                                            </Card>
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="stores">
                                            <Stores
                                                selected={state.data.checked_desks}
                                            />
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </Modal.Body>
                    <Modal.Footer style={{ justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', alignItems: 'center', }}>
                            {(state.data?.id) &&
                                <>
                                    <div style={{ marginRight: '10px' }}>
                                        Текущ статус: {state.data.status_name}
                                    </div>

                                    {state.data.allow_deactivate ?
                                        <Button variant="outline-danger" size="sm" onClick={() => handleActivate(false)}>
                                            <XCircle /> Деактивирай
                                        </Button>
                                        :
                                        state.data.allow_activate
                                            ?
                                            <Button variant="outline-success" size="sm" onClick={() => handleActivate(true)}>
                                                <CheckCircle /> Активирай
                                            </Button>
                                            :
                                            ''
                                    }
                                </>
                            }
                        </div>
                        <div>
                            <Button variant="secondary" onClick={handleClose} style={{ marginRight: '5px' }}>
                                Отказ
                            </Button>
                            <SaveButton
                                loading={state.loading}
                                className="save"
                            />
                        </div>

                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default forwardRef(AddOrEdit);
