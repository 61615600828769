import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import { Modal, Button, Col, Row, FloatingLabel, Form, Table, } from 'react-bootstrap';

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//providers
import { useLanguageContext } from 'providers/Language'

//misc
import Question from 'components/modals/Question';
import SaveButton from 'components/misc/Button'
import Info from 'components/modals/Info';
import Autocomplete from 'components/misc/Autocomplete';
import HelpInfo from 'components/misc/Info';
import moment from 'moment';

function PackageCard(props, ref) {

    const defaultData = {
        card: null,
        error: null,
    }

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        clientId: null,
        refresh: false,
        data: defaultData,
        loading: false,
        loadingBase: false,
        loadingApply: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null,
        onClose: null,
        onSave: null,
    });

    const [validations, setValidations] = useValidation();

    const formRef = useRef(null);

    useImperativeHandle(ref, () => ({
        open: (id, clientId) => {
            open(id, clientId);
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        },
        onSave: fn => {
            setState(prev => ({
                ...prev,
                onSave: fn
            }));
        }
    }));

    useEffect(() => {
        setState(prev => ({
            ...prev,
            loading: Boolean(props.loading)
        }));
    }, [props.loading]);

    useEffect(() => {
        setValidations(props.validations);
    }, [props.validations]);

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const open = (id, clientId) => {
        setState(prev => ({
            ...prev,
            data: defaultData,
            id: id,
            clientId: clientId,
        }));

        show();
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
            clientId: null,
            subjectId: null,
            subjectType: null
        }));

        setValidations(null);

        if (typeof state.onClose === 'function') {
            hide(state.onClose);
        } else {
            hide();
        }
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            refresh: new Date().getTime(),
        }));
    }

    const handleSave = e => {
        e.preventDefault();

        if (state.loading) {
            return;
        }

        setState(prev => ({
            ...prev,
            loading: true,
            loadingBase: true,
            data: {
                ...prev.data,
                card: null,
                error: null,
            }
        }));

        setValidations(null);

        let url = 'accounts/package-cards/find';

        let data = new FormData(e.target);

        data.append('client_id', state.clientId || '');

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    card: res.data.card,
                    error: res.data.error,
                }
            }));
        }).catch(error => {
            const _err = error.response;

            if (_err && _err.status && _err.status === 422) {
                setValidations(_err.data.errors);
            }
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
                loadingBase: false,
                loadingApply: false,
            }));
        });
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleDateChange = e => {
        const name = e.target.name + '_ymd'
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleApply = e => {
        e.preventDefault();

        if (state.loading) {
            return;
        }

        setState(prev => ({
            ...prev,
            loading: true,
            loadingApply: true,
            data: {
                ...prev.data,
                // card: null,
                // error: null,
            }
        }));

        setValidations(null);

        let url = 'accounts/package-cards/add';

        let data = new FormData(formRef.current);

        if (state.id) {
            data.append('id', state.id);
        }

        if (typeof state.onSave === 'function') {
            return state.onSave(data);
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (typeof state.onSuccess === 'function') {
                state.onSuccess(res);
            }

            hide(state.onClose);
        }).catch(error => {
            const _err = error.response;

            if (_err && _err.status && _err.status === 422) {
                setValidations(_err.data.errors);
            }
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
                loadingBase: false,
                loadingApply: false,
            }));
        });
    }

    return (
        <>

            <Modal size="md" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <form ref={formRef} onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Прилагане на пакетна карта
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Row>
                            <Col>
                                <FloatingLabel label="Номер">
                                    <Form.Control
                                        type="text"
                                        name={`num`}
                                        autoComplete="new-password"
                                        value={state.data.num || ''}
                                        placeholder="Номер"
                                        onChange={(e) => handleInputChange(e)}
                                        isInvalid={Boolean(validations?.num)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {validations?.num && (validations?.num[0] || validations?.num)}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>

                        {state.data.card &&
                            <Table className="mt-3" size="sm" bordered>
                                <tbody>
                                    <tr>
                                        <td className="th" style={{ width: '50%' }}>
                                            Име
                                        </td>
                                        <td>
                                            {state.data.card?.type?.name}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="th">
                                            Статус
                                        </td>
                                        <td className={`${state.data.error ? 'negative-value' : 'green-value'}`}>
                                            {state.data.error ? state.data.error : 'Валидна'}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="th">
                                            Собственик
                                        </td>
                                        <td>
                                            {state.data.card?.client ? state.data.card.client.name : 'Анонимен'}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="th">
                                            Отстъпка
                                        </td>
                                        <td>
                                            {state.data.card?.discount}%
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="th">
                                            Оставащи използвания общо
                                        </td>
                                        <td>
                                            {state.data.card?.uses_left}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="th">
                                            Оставаща сума кредит
                                        </td>
                                        <td>
                                            {state.data.card?.credit_left}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="th">
                                            Валидна от
                                        </td>
                                        <td>
                                            {state.data.card?.valid_from ? moment(state.data.card.valid_from).format('DD.MM.YYYY HH:mm') : ''}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="th">
                                            Валидна до
                                        </td>
                                        <td>
                                            {state.data.card?.valid_to ? moment(state.data.card.valid_to).format('DD.MM.YYYY HH:mm') : ''}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        }

                        {state.data.card && !state.data.error &&
                            <div className="mt-3" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <SaveButton
                                    loading={state.loadingApply}
                                    disabled={state.loading}
                                    className="save"
                                    text="Приложи"
                                    onClick={handleApply}
                                />
                            </div>
                        }

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loadingBase}
                            disabled={state.loading}
                            className="save"
                            text="Провери"
                        />
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default forwardRef(PackageCard);
