import { useEffect, useRef, useState } from 'react';
import { buildUrl, useQuery } from 'helpers/Url';
import { useLocation, useNavigate, Link, useParams } from 'react-router-dom';
import { Button, Table, Form, Row, Col, Container, InputGroup } from 'react-bootstrap';
import { ArrowLeftCircle, Download, FileArrowDown, Pencil, PlusCircle, TrashFill } from 'react-bootstrap-icons';
import { useNestedState } from 'helpers/NestedState';
import { useCurrencyContext } from 'providers/Currency';
import { useValidation } from 'helpers/Validation';
import { useAuthDataContext } from 'providers/Auth';
import { cloneDeep } from 'lodash';

import Question from 'components/modals/Question';
import Layout from 'components/layout/Layout';
import Api from 'helpers/Api';
import AddOrEdit from './partials/AddOrEdit';
import AddOrEditRow from './partials/AddOrEditRow';

//misc
import Loader from 'components/misc/Loader';
import moment from 'moment';
import Autocomplete from 'components/misc/Autocomplete';
import SaveButton from 'components/misc/Button';
import Pagination from 'components/misc/Pagination'
import Info from 'components/misc/Info';
import Error from 'components/modals/Error';
import TableHeader from 'components/misc/TableHeader';
import TableCol from 'components/misc/TableCol';
import TableOptions from 'components/misc/TableOptions';
import DynamicTableCol from 'components/partials/table/DynamicTableCol';
import DynamicTableBodyCol from 'components/partials/table/DynamicTableBodyCol';
import PriceValue from 'components/partials/PriceValue';
import Refs from 'Refs';
import NoDataFound from 'components/misc/NoDataFound';
import axios from 'axios';

let timeout;

function Index() {

    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const query = useQuery();

    const auth = useAuthDataContext();
    const currencies = useCurrencyContext();

    const [state, setState] = useNestedState({
        loading: false,
        loadingBase: false,
        loadingClose: false,
        loadingChanges: false,
        dataLoading: false,
        rowsLoading: false,
        data: {

        },
        rows: {
            rows: [{}],
            totals: [],
            headings: {},
            types: {},
            pages: 0,
            total: 0,
            filter: {
                page: 1,
            },
            columns: {
                all: {},
                details: {},
                selected: [],
                sortable: [],
                sort: null,
                order: null,
            },
            tableKey: '',
            request: null,
            refresh: false,
        },
        original: {},
        timeout: null
    });

    const [validations, setValidations] = useValidation();

    const addOrEditModalRef = useRef(null);
    const addOrEditRowModalRef = useRef(null);
    const formRef = useRef(null);
    const errorModalRef = useRef(null);
    const questionModalRef = useRef(null);
    const tableRef = useRef(null);

    useEffect(() => {
        if (params.id) {
            loadFullData();
        }
    }, [params.id]);

    useEffect(() => {
        setState(prev => ({
            ...prev,
            rows: {
                ...prev.rows,
                filter: {
                    ...prev.rows.filter,
                    page: query.get('page') || 1,
                    search: query.get('search') || '',
                },
                refresh: new Date().getTime()
            },
        }))
    }, [location.search]);

    useEffect(() => {
        if (state.rows.refresh) {

            setState(prev => ({
                ...prev,
                rowsLoading: true
            }));

            loadRows().then(() => {
                setState(prev => ({
                    ...prev,
                    rowsLoading: false
                }));
            });
        }
    }, [state.rows.refresh]);

    useEffect(() => {
        if (state.data.refresh) {

            setState(prev => ({
                ...prev,
                dataLoading: true
            }));

            loadData().then(() => {
                setState(prev => ({
                    ...prev,
                    dataLoading: false
                }));
            });
        }
    }, [state.data.refresh]);

    const loadFullData = (showLoading = true) => {
        if (showLoading) {
            loading(true);
        }

        let p1 = loadData();
        let p2 = loadRows();

        Promise.all([p1, p2]).then(() => {
            loading(false);
        });
    }

    const loadData = async () => {
        let url = 'intrastats/show';

        return Api.get(url, {
            params: {
                id: params.id
            }
        }).then(res => {
            setDataResponse(res.data);
        }).catch(err => {
            console.log(err);
        })
    }

    const loadRows = async () => {

        if (state.rows.request) {
            state.rows.request.cancel();
        }

        let request = axios.CancelToken.source();

        setState(prev => ({
            ...prev,
            rows: {
                ...prev.rows,
                request: request,
            }
        }));

        let url = getRowsUrl();

        return Api.get(url, {
            cancelToken: request.token
        }).then(res => {
            setRowsResponse(res.data);
        }).catch(err => {
            console.log(err);
        })
    }

    const getRowsUrl = () => {
        let url = 'intrastats/rows/all';

        return buildUrl(url, {
            ...state.rows.filter,
            id: params.id,
        });
    }

    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout);

        if (typeof key === 'object') {
            setState(prev => ({
                ...prev,
                rows: {
                    ...prev.rows,
                    filter: {
                        ...prev.rows.filter,
                        ...key
                    },
                }
            }));
        } else {
            setState(prev => ({
                ...prev,
                rows: {
                    ...prev.rows,
                    filter: {
                        ...prev.rows.filter,
                        [key]: val,
                    },
                }
            }));
        }

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                rows: {
                    ...prev.rows,
                    filter: {
                        ...prev.rows.filter,
                        page: 1
                    },
                    refresh: new Date().getTime()
                }
            }));
        }, delay);
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            rows: {
                ...prev.rows,
                filter: {
                    ...prev.rows.filter,
                    page: page.selected + 1,
                },
                refresh: new Date().getTime()
            }
        }));
    }

    const handleChangeArticle = data => {
        handleSearch('article_id', data?.id || '')
    }

    const handleEmptyValuesChange = data => {
        let selected = data.map(d => d.id);

        handleSearch('empty_values', selected);
    }

    const setDataResponse = data => {
        setState(prev => ({
            ...prev,
            data: data,
            original: cloneDeep(data),
        }));
    }

    const setRowsResponse = data => {
        console.log(data)

        let rows = data.items;

        rows = rows.map(row => {
            //

            return row;
        });

        setState(prev => ({
            ...prev,
            rows: {
                ...prev.rows,
                rows: rows,
                total: data.total,
                pages: data.pages,
                totals: data.totals,
                headings: data.headings,
                types: data.types,
                columns: data.columns,
                tableKey: data.tableKey,
                filter: {
                    ...prev.rows.filter,
                    ...data.filter,
                }
            }
        }));
    }

    const isDirty = (key, modificator = null) => {
        if (modificator) {
            return modificator(state.data[key]) !== modificator(state.original[key]);
        }

        return String(state.data[key]) !== String(state.original[key]);
    }

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            dataLoading: Boolean(loading)
        }));
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleRowChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'rows')
    }

    const handleToDateChange = e => {
        const value = e.target.value

        setState('to_date_local', value, 'data')
    }

    const handleCheckboxChange = e => {
        const name = e.target.name;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: prev.data[name] ? false : true
            }
        }));
    }

    // не се ползва
    const save = () => {
        return;

        if (state.loading) {
            return;
        }

        setState(prev => ({
            ...prev,
            loading: true,
            loadingBase: true,
        }));

        setValidations(null);

        let url = params.id ? 'revisions/edit' : 'revisions/add';

        let form = formRef.current;

        let data = new FormData(form);

        if (params.id) {
            data.append('id', params.id)
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            navigate('/storage/revisions');
        }).catch(error => {
            const _err = error.response;

            if (_err && _err.status) {
                if (_err.status === 422) {
                    setValidations(_err.data.errors);
                } else {
                    let modal = errorModalRef.current;

                    if (modal) {
                        modal.open(_err.data.error || _err.data.message);
                    }
                }
            }

        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
                loadingBase: false,
                loadingClose: false,
            }));
        });
    }

    const handleSave = e => {
        e.preventDefault();

        save();
    }

    const refreshTable = () => {
        setState(prev => ({
            ...prev,
            rows: {
                ...prev.rows,
                filter: {
                    ...prev.rows.filter,
                    sort: '',
                    order: '',
                    page: 1
                },
                refresh: new Date().getTime(),
            }
        }));
    }

    const handleShowArticle = id => {
        let modal = Refs.getInstance().getRef('article');

        if (!modal) {
            return;
        }

        modal.open(id);
    }

    const handleEdit = () => {
        if (state.data.deleted) {
            return;
        }

        let modal = addOrEditModalRef.current;

        modal.edit(state.data.id);

        modal.onSuccess(() => {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    refresh: new Date().getTime()
                },
            }))
        });
    }

    const handleExport = () => {
        window.open(state.data.download_url);
    }

    const handleAddRow = () => {
        let modal = addOrEditRowModalRef.current;

        modal.add(state.data.id);

        modal.onSuccess(() => {
            // setState(prev => ({
            //     ...prev,
            //     rows: {
            //         ...prev.rows,
            //         refresh: new Date().getTime()
            //     },
            // }))

            loadFullData(false);
        });
    }

    const handleEditRow = (e, id) => {
        e.preventDefault();

        let modal = addOrEditRowModalRef.current;

        modal.edit(id);

        modal.onSuccess(data => {
            // setState(prev => ({
            //     ...prev,
            //     rows: {
            //         ...prev.rows,
            //         rows: prev.rows.rows.map(r => {
            //             if (r.id === data.id) {
            //                 r = data;
            //             }

            //             return r;
            //         })
            //         // refresh: new Date().getTime()
            //     },
            // }))

            loadFullData(false);
        });
    }

    const handleRemoveRow = (e, id) => {
        e.preventDefault();

        let modal = questionModalRef.current;

        modal.open('Да се изтрие ли избрания ред?');

        modal.onSuccess(() => {
            deleteRow(id);
        });

    }

    const deleteRow = id => {
        Api.post('intrastats/rows/delete', {
            id: id
        }).then(res => {
            loadFullData(false);
        });
    }

    const handleShowLoads = (e, data) => {
        e.preventDefault();

        let loads = data.row.loadsdata;

        if (loads.length > 0) {
            let ids = loads.map(l => l.load_id);

            if (loads.length > 1) {
                showChooseStoreLoad(ids);
            } else {
                showStoreLoad(ids[0]);
            }
        } else {
            showNoStoreLoad();
        }
    }

    const showStoreLoad = id => {
        let modal = Refs.getInstance().getRef('load');

        if (!modal) {
            return;
        }

        modal.open(id);
    }

    const showNoStoreLoad = () => {
        let modal = errorModalRef.current;

        if (!modal) {
            return;
        }

        modal.open('Няма свързан номер на зареждане!');
    }

    const showChooseStoreLoad = (ids) => {
        let modal = errorModalRef.current;

        if (!modal) {
            return;
        }

        modal.open(
            <div style={{ textAlign: 'center' }}>
                Изберете номер на зареждане, което искате да видите:
                <br />
                <br />
                <div style={{ display: 'flex', justifyContent: 'center', }}>
                    {ids.map(id =>
                        <Button key={id} onClick={e => showStoreLoad(id)} style={{ marginRight: '5px' }}>
                            {id}
                        </Button>
                    )}
                </div>
            </div>
        )
    }

    const sorting = (sorting) => {
        setState(prev => ({
            ...prev,
            sorting: Boolean(sorting)
        }));
    }

    const handleSort = (sort, order) => {
        setState(prev => ({
            ...prev,
            rows: {
                ...prev.rows,
                sorting: true,
                filter: {
                    ...prev.filter,
                    sort: sort,
                    order: order,
                    page: 1,
                },
                refresh: new Date().getTime()
            }
        }));
    }

    // console.log(state.rows)

    return (
        <>

            <Question
                ref={questionModalRef}
            />

            <Error
                ref={errorModalRef}
            />

            <AddOrEdit
                ref={addOrEditModalRef}
            />

            <AddOrEditRow
                ref={addOrEditRowModalRef}
            />

            <Layout>
                <div className="container-fluid">

                    <div className="page-head">
                        <h1 className="display-6">
                            <Link to={-1} className="link-dark text-decoration-none">
                                <ArrowLeftCircle />
                            </Link> Интрастат декларация
                        </h1>

                        <div className="buttons">

                        </div>
                    </div>

                    <br />

                    <div className="panel">

                        {state.dataLoading
                            ?
                            <Loader />
                            :
                            <>
                                <Form ref={formRef}>
                                    {/* <Container> */}
                                    <div className="form-panel">
                                        <div className="head">
                                            Данни за документа
                                        </div>
                                        <Table size="sm" bordered>
                                            <tbody>
                                                <tr>
                                                    <td className="th">
                                                        ID
                                                    </td>
                                                    <td>
                                                        {state.data.id}
                                                    </td>
                                                    <td className="th">
                                                        Създадено на
                                                    </td>
                                                    <td>
                                                        {state.data.created_at ? moment(state.data.created_at).format('DD.MM.YYYY HH:mm') : ''}
                                                    </td>
                                                    <td className="th">
                                                        Обновено на
                                                    </td>
                                                    <td>
                                                        {state.data.updated_at ? moment(state.data.updated_at).format('DD.MM.YYYY HH:mm') : ''}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="th">
                                                        ID на декларацията
                                                    </td>
                                                    <td>
                                                        {state.data.declaration_id}
                                                    </td>
                                                    <td className="th">
                                                        Месец
                                                    </td>
                                                    <td>
                                                        {state.data.period ? moment(state.data.period).format('MM.YYYY') : ''}
                                                    </td>
                                                    <td className="th">
                                                        Брой транзакции
                                                    </td>
                                                    <td>
                                                        {state.data.rows_count}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="th">
                                                        Сума
                                                    </td>
                                                    <td>
                                                        <PriceValue prefix={state.data.currency?.prefix} sufix={state.data.currency?.sufix}>{state.data.total_sum}</PriceValue>
                                                    </td>
                                                    <td className="th">
                                                        Статистическа стойност
                                                    </td>
                                                    <td>
                                                        <PriceValue prefix={state.data.currency?.prefix} sufix={state.data.currency?.sufix}>{state.data.total_statistical_sum}</PriceValue>
                                                    </td>
                                                    <td className="th">
                                                        Тегло
                                                    </td>
                                                    <td>
                                                        {state.data.total_weight}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>

                                    <div className="pagination-with-options mt-3 mb-3">
                                        <div>
                                            <Button size="sm" variant="primary" onClick={() => handleEdit()}>
                                                <Pencil /> Редактиране
                                            </Button>
                                            <Button size="sm" variant="primary" onClick={() => handleAddRow()} style={{ marginLeft: '5px' }}>
                                                <PlusCircle /> Добави ред
                                            </Button>
                                            <Button size="sm" variant="primary" onClick={() => handleExport()} style={{ marginLeft: '5px' }}>
                                                <FileArrowDown /> Експорт XML
                                            </Button>
                                        </div>

                                        <TableOptions
                                            url={getRowsUrl()}
                                            filter={state.rows.filter}
                                            columns={state.rows.columns}
                                            tableKey={state.rows.tableKey}
                                            refresh={refreshTable}
                                        />
                                    </div>

                                    <div className="form-panel mt-3">
                                        <div className="head">
                                            Артикули
                                        </div>

                                        <div style={{ marginBottom: '20px' }}>
                                            <Row>
                                                <Col sm={6}>
                                                    <InputGroup size="sm">
                                                        <Form.Control
                                                            type="search"
                                                            name="search"
                                                            value={state.rows.filter.search || ''}
                                                            size="sm"
                                                            onChange={e => handleSearch(e.target.name, e.target.value)}
                                                            placeholder="Код на стоката"
                                                        />
                                                    </InputGroup>
                                                </Col>
                                                <Col>
                                                    <Autocomplete
                                                        variant="basic"
                                                        size="sm"
                                                        inputPlaceholder="Артикул"
                                                        url="autocomplete/articles"
                                                        inputIdName="article_id"
                                                        onChange={handleChangeArticle}
                                                        onInputChange={handleChangeArticle}
                                                        renderText={data => {
                                                            return (
                                                                <div style={{ display: 'flex', width: '100%' }}>
                                                                    <div style={{ width: 'max-content', marginRight: '10px' }}>
                                                                        {data.id}
                                                                    </div>
                                                                    <div>
                                                                        {data.name}
                                                                        <br />
                                                                        {data.article_name}
                                                                    </div>
                                                                </div>
                                                            )
                                                        }}
                                                        renderInputText={data => data.article_name}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Autocomplete
                                                        variant="basic"
                                                        size="sm"
                                                        inputPlaceholder="Липсващи стойности"
                                                        // selectedIds={props.selected || []}
                                                        data={[
                                                            {
                                                                id: 'cn8_code',
                                                                name: 'Код на стоката'
                                                            },
                                                            {
                                                                id: 'ms_cons_dest_code',
                                                                name: 'Държава на изпращане'
                                                            },
                                                            {
                                                                id: 'country_of_origin_code',
                                                                name: 'Страна на произход'
                                                            },
                                                            {
                                                                id: 'nature_of_transaction_code',
                                                                name: 'Вид на сделката'
                                                            },
                                                            {
                                                                id: 'delivery_terms_code',
                                                                name: 'Условия на доставката'
                                                            },
                                                            {
                                                                id: 'mode_of_transport_code',
                                                                name: 'Вид транспорт'
                                                            },
                                                            {
                                                                id: 'nationality_of_transport_vehicle',
                                                                name: 'Нац. на т. средство'
                                                            },
                                                            {
                                                                id: 'region_code',
                                                                name: 'Регион на потребление'
                                                            },
                                                            {
                                                                id: 'net_mass',
                                                                name: 'Нетно тегло в кг.'
                                                            },
                                                            {
                                                                id: 'supplementary_unit',
                                                                name: 'Кол. по доп. мярка'
                                                            },
                                                            {
                                                                id: 'invoiced_amount',
                                                                name: 'Стойност'
                                                            },
                                                            {
                                                                id: 'statistical_procedure_code',
                                                                name: 'Стат. стойност'
                                                            },
                                                        ]}
                                                        multiple
                                                        onChange={handleEmptyValuesChange}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>

                                        <div style={{ position: 'relative' }}>
                                            {state.rows.rows.length === 0
                                                ?
                                                <NoDataFound />
                                                :
                                                <>
                                                    <Table className="valign-top big" responsive ref={tableRef}>
                                                        <TableHeader
                                                            tableRef={tableRef}
                                                            activeSortKey={state.rows.filter.sort}
                                                            activeSortDir={state.rows.filter.order}
                                                            onSort={(col, dir) => handleSort(col, dir)}
                                                        >
                                                            {Object.entries(state.rows.headings).map((heading, i) =>
                                                                <DynamicTableCol
                                                                    key={i}
                                                                    type={state.rows.types[heading[0]]}
                                                                    name={heading[1]}
                                                                    title={state.rows.columns.description[heading[0]]}
                                                                    sortKey={heading[0]}
                                                                    sortable={state.rows.columns.sortable.indexOf(heading[0]) > -1}
                                                                />
                                                            )}
                                                            <th className="options">

                                                            </th>
                                                        </TableHeader>
                                                        <tbody>
                                                            {state.rows.rows.map((r, i) =>
                                                                <tr key={r.id}>
                                                                    {Object.entries(state.rows.headings).map((heading, i) =>
                                                                        heading[0] === 'id'
                                                                            ?
                                                                            <td>
                                                                                {i + 1}
                                                                            </td>
                                                                            :
                                                                            <DynamicTableBodyCol
                                                                                key={heading[0]}
                                                                                type={state.rows.types[heading[0]]}
                                                                                name={r[heading[0]]}
                                                                                data={r}
                                                                                currency={state.rows.currency}
                                                                            />
                                                                    )}
                                                                    <td className="options">
                                                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                            <Button variant="danger" size="sm" onClick={e => handleRemoveRow(e, r.id)} style={{ margin: '0 2.5px', fontSize: '.705rem' }}>
                                                                                <TrashFill />
                                                                            </Button>
                                                                            <Button variant="secondary" size="sm" onClick={e => handleShowLoads(e, r)} style={{ margin: '0 2.5px', fontSize: '.705rem' }}>
                                                                                <Download />
                                                                            </Button>
                                                                            <Button variant="secondary" size="sm" onClick={e => handleEditRow(e, r.id)} style={{ margin: '0 2.5px', fontSize: '.705rem' }}>
                                                                                <Pencil />
                                                                            </Button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                            {state.rows.totals.map((c, i) =>
                                                                <tr key={i} className="total">
                                                                    {Object.entries(c.items).map((heading, i) =>
                                                                        <DynamicTableBodyCol
                                                                            key={heading[0]}
                                                                            type={state.rows.types[heading[0]]}
                                                                            name={heading[1]}
                                                                            data={c.items}
                                                                            currency={state.currency}
                                                                            colspan={c.colspan[heading[0]]}
                                                                        />
                                                                    )}
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </Table>

                                                    <Pagination
                                                        className="mt-3"
                                                        page={state.rows.filter.page}
                                                        pages={state.rows.pages}
                                                        total={state.rows.total}
                                                        handlePage={handlePage}
                                                    />
                                                </>
                                            }

                                            <div style={{
                                                position: 'absolute',
                                                top: 0,
                                                bottom: 0,
                                                left: 0,
                                                right: 0,
                                                background: 'rgba(255,255,255,.7)',
                                                display: state.rowsLoading ? 'flex' : 'none',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                zIndex: 3
                                            }}>
                                                {/* <Loader /> */}
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="buttons mt-3">
                                        <SaveButton
                                            loading={state.loadingBase}
                                            disabled={state.loading}
                                            className="save"
                                            onClick={handleSave}
                                        >
                                            Запази данните
                                        </SaveButton>

                                    </div> */}
                                    {/* </Container> */}

                                </Form>
                            </>
                        }

                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Index;