import { useAppContext } from 'providers/App';
import { Dropdown } from 'react-bootstrap';

function Options(props) {
    const app = useAppContext();

    const handleView = () => {
        if (props.data.url) {
            return window.open(props.data.url);
        }

        app.showWarning('Няма зададен URL');
    }

    return (
        <>
            <Dropdown.Item disabled={props.data.allow_edit === false} onClick={e => handleView()}>
                Към страницата
            </Dropdown.Item>
            <Dropdown.Item disabled={props.data.allow_edit === false} onClick={e => props.handleEdit(props.data)}>
                Редакция
            </Dropdown.Item>
        </>
    )
}

export default Options;