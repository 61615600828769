import LockedFilter from "./LockedFilter";

class LockedFilters {
    static getFilters() {

        let filters = [];

        try {
            let data = this.getLocalStorage('filters') || {};

            for (let [key, storage] of Object.entries(data)) {
                let value = null;

                switch (storage) {
                    case 'session':
                        value = this.fromSession(key);
                        break;
                    case 'local':
                        value = this.fromLocal(key);
                        break;
                }

                if (value) {
                    value = JSON.parse(value);
                }

                const filter = new LockedFilter(
                    this.parseKey(key)
                );

                filter.setStorage(storage);
                filter.setValue(value);

                filters.push(filter);
            }

            // console.log(filters)

            return filters;
        } catch (e) {
            // 
        }

        return [];
    }

    static parseKey(key) {
        return key.split('filter_')[1];
    }

    /**
     * 
     * @param {LockedFilter} filter 
     */
    static setStorage(filter) {
        try {
            let data = this.getLocalStorage('filters');

            data[filter.getPrefixedKey()] = filter.storage;

            this.setLocalStorage('filters', data);

        } catch (e) {
            // 
        }
    }

    /**
     * 
     * @param {LockedFilter} filter 
     */
    static setValue(filter) {
        try {
            switch (filter.storage) {
                case 'session':
                    // записваме в sessionStorage
                    this.setSessionStorage(filter.getPrefixedKey(), filter.value);

                    // изтриваме от localStorage
                    this.removeLocalStorage(filter.getPrefixedKey());
                    break;
                case 'local':
                    // записваме в localStorage
                    this.setLocalStorage(filter.getPrefixedKey(), filter.value);

                    // изтриваме от sessionStorage
                    this.removeSessionStorage(filter.getPrefixedKey());
                    break;
                default:
                    this.removeLocalStorage(filter.getPrefixedKey());
                    this.removeSessionStorage(filter.getPrefixedKey());
                    break;
            }
        } catch (e) {
            // 
        }
    }

    // private
    static getLocalStorage(key) {
        try {
            let data = localStorage.getItem(key);

            data = JSON.parse(data);

            return data;
        } catch (e) {
            // 
        }

        return null;
    }

    static setLocalStorage(key, value) {
        try {
            localStorage.setItem(key, JSON.stringify(value));
        } catch (e) {
            // 
        }
    }

    static removeLocalStorage(key) {
        try {
            localStorage.removeItem(key);
        } catch (e) {
            // 
        }
    }

    static getSessionStorage(key) {
        try {
            let data = sessionStorage.getItem(key);

            data = JSON.parse(data);

            return data;
        } catch (e) {
            // 
        }

        return null;
    }

    static setSessionStorage(key, value) {
        try {
            sessionStorage.setItem(key, JSON.stringify(value));
        } catch (e) {
            // 
        }
    }

    static removeSessionStorage(key) {
        try {
            sessionStorage.removeItem(key);
        } catch (e) {
            // 
        }
    }


    static fromSession(key) {
        return sessionStorage.getItem(key);
    }

    static fromLocal(key) {
        return localStorage.getItem(key);
    }

}

export default LockedFilters;