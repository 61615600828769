import { Button } from 'react-bootstrap';
import MenuOptions from 'components/misc/MenuOptions'
import Options from './Options';
import { useEffect } from 'react';

function Item(props) {
    const data = props;

    const handleSpacing = (level) => {
        let levels = level > 1 ? '\u00A0\u00A0' : '';
        let new_level = levels.repeat(level)
        return new_level;
    }

    const handleInputChange = () => {
        props.handleInputChange()
    }
    return (
        <>
            {props.role_id != data.id ? <option onChange={handleInputChange} value={data.id} level={props.level} key={data.id} data-parent={data.parent_id}>
                {handleSpacing(props.level) + ' - ' + data.name}
            </option> : ''}
            {data.children && data.children.map((item, index) =>
                <Item
                    onChange={handleInputChange}
                    key={index}
                    role_id={props.role_id}
                    index={index}
                    level={props.level + 1}
                    {...item}
                />)}
        </>
    )
}

export default Item;
