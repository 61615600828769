import { useState, useEffect, useRef } from 'react'
import { Card, Col, Row, Table } from 'react-bootstrap';
import Api from 'helpers/Api';
import Loader from 'components/misc/Loader';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import FilePreview from './FilePreview';
import PriceValue from 'components/partials/PriceValue';
import Refs from 'Refs';

function Index(props) {

    const navigate = useNavigate();

    const defaultData = {
        client: null,
        accounts_totals: null
    };

    const [state, setState] = useState({
        data: defaultData,
        loading: false,
        onEntering: null,
        onExiting: null
    });

    useEffect(() => {
        setState(prev => ({
            ...prev,
            loading: true
        }));

        Api.get('clients/view', {
            params: {
                id: props.id
            }
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data,
                loading: false,
            }));
        });
    }, [props.id]);

    const handleCreateAccount = () => {
        props.hide();

        navigate('/accounts/new?client_id=' + state.data.client?.id);
    }

    const handleCreatePayment = () => {
        let modal = Refs.getInstance().getRef('newPayment');

        if (!modal) {
            return;
        }

        props.hide(() => {
            modal.forClient(state.data.client);
        });

        modal.onClose(() => {
            props.show();
        });
    }

    const handleCreatePackageCard = () => {
        let modal = Refs.getInstance().getRef('newPackageCard');

        if (!modal) {
            return;
        }

        props.hide(() => {
            modal.forClient(state.data.client);
        });

        modal.onClose(() => {
            props.show();
        });
    }

    const handleCreateOrder = () => {
        props.hide();

        navigate('/orders/new?client_id=' + state.data.client?.id);
    }

    return (

        state.loading
            ?
            <Loader />
            :
            <>
                <Row>
                    <Col sm={9}>
                        <Card className="mb-3">
                            <Card.Header>
                                Данни
                            </Card.Header>
                            <Card.Body style={{ padding: 0 }}>
                                <Row>
                                    <Col sm={3}>
                                        <div style={{ paddingLeft: '15px', paddingTop: '15px', }}>
                                            <FilePreview
                                                src={state.data.client?.image_url}
                                            />
                                        </div>
                                    </Col>
                                    <Col sm={9}>
                                        <Table size="sm" className="no-shadow no-hover" bordered>
                                            <tbody>
                                                <tr>
                                                    <td className="th">
                                                        Клиент
                                                    </td>
                                                    <td colSpan={3}>
                                                        {state.data.client?.name}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="th">
                                                        Отстъпка
                                                    </td>
                                                    <td>
                                                        {state.data.client?.discount || 0}%
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="th">
                                                        Телефон
                                                    </td>
                                                    <td>
                                                        {state.data.client?.tel}
                                                    </td>
                                                    <td className="th">
                                                        Е-поща
                                                    </td>
                                                    <td>
                                                        {state.data.client?.email}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="th">
                                                        Група
                                                    </td>
                                                    <td>
                                                        {state.data.client?.group?.name}
                                                    </td>
                                                    <td className="th">
                                                        Начин на плащане
                                                    </td>
                                                    <td>
                                                        {state.data.client?.paymentmethod?.name}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="th">
                                                        Портфейл лимит
                                                    </td>
                                                    <td>
                                                        <PriceValue prefix={state.data.currency?.prefix} sufix={state.data.currency?.sufix}>{state.data.client?.credit_limit}</PriceValue>
                                                    </td>
                                                    <td className="th">
                                                        Баланс на портфейл
                                                    </td>
                                                    <td>
                                                        <PriceValue prefix={state.data.currency?.prefix} sufix={state.data.currency?.sufix}>{state.data.client?.total_balance}</PriceValue>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="th">
                                                        Клиентски код
                                                    </td>
                                                    <td>
                                                        {state.data.client?.client_card}
                                                    </td>
                                                    <td className="th">
                                                        Използване без карта
                                                    </td>
                                                    <td>
                                                        {state.data.client?.allow_client_nocard ? 'ДА' : 'НЕ'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="th">
                                                        Портфейл без карта
                                                    </td>
                                                    <td>
                                                        {state.data.client?.allow_credit_nocard ? 'ДА' : 'НЕ'}
                                                    </td>
                                                    <td className="th">
                                                        Отстъпка без карта
                                                    </td>
                                                    <td>
                                                        {state.data.client?.allow_discount_nocard ? 'ДА' : 'НЕ'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="th">
                                                        Дата на създаване
                                                    </td>
                                                    <td>
                                                        {state.data.client?.created_at ? moment(state.data.client?.created_at).format('DD.MM.YYYY HH:mm') : ''}
                                                    </td>
                                                    <td className="th">
                                                        Последна актуализация
                                                    </td>
                                                    <td>
                                                        {state.data.client?.updated_at ? moment(state.data.client?.updated_at).format('DD.MM.YYYY HH:mm') : ''}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="th">
                                                        Източник
                                                    </td>
                                                    <td>
                                                        {state.data.client?.source?.translation?.name}
                                                    </td>
                                                    <td className="th">
                                                        Източник клиент
                                                    </td>
                                                    <td>
                                                        {state.data.client?.client?.name}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="th">
                                                        Maillist абонамент
                                                    </td>
                                                    <td>
                                                        TODO
                                                    </td>
                                                    <td className="th">
                                                        Актуализация с maillist
                                                    </td>
                                                    <td>
                                                        TODO
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <Card className="mb-3">
                            <Card.Header>
                                Оборот за разрешените обекти
                            </Card.Header>
                            <Card.Body style={{ padding: 0 }}>
                                <Table size="sm" className="no-shadow no-hover" bordered>
                                    <tbody>
                                        <tr>
                                            <td className="th">
                                                Тотал
                                            </td>
                                            <td colSpan={3}>
                                                <PriceValue prefix={state.data.currency?.prefix} sufix={state.data.currency?.sufix}>{state.data.accounts_totals?.sum}</PriceValue>
                                            </td>
                                            <td className="th">
                                                Общо отстъпки
                                            </td>
                                            <td colSpan={3}>
                                                <PriceValue prefix={state.data.currency?.prefix} sufix={state.data.currency?.sufix}>{state.data.accounts_totals?.discount}</PriceValue>
                                            </td>
                                            <td className="th">
                                                Общо оборот
                                            </td>
                                            <td colSpan={3}>
                                                <PriceValue prefix={state.data.currency?.prefix} sufix={state.data.currency?.sufix}>{state.data.accounts_totals?.real}</PriceValue>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="th">
                                                Брой сметки
                                            </td>
                                            <td colSpan={3}>
                                                {state.data.accounts_totals?.count}
                                            </td>
                                            <td className="th">
                                                Първа сметка
                                            </td>
                                            <td colSpan={3}>
                                                {state.data.accounts_totals?.first_account_date ? moment(state.data.accounts_totals?.first_account_date).format('DD.MM.YYYY HH:mm') : ''}
                                            </td>
                                            <td className="th">
                                                Последна сметка
                                            </td>
                                            <td colSpan={3}>
                                                {state.data.accounts_totals?.latest_account_date ? moment(state.data.accounts_totals?.latest_account_date).format('DD.MM.YYYY HH:mm') : ''}
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={3}>
                        <Card bg="light" className="mb-3">
                            <Card.Header>
                                Действия
                            </Card.Header>
                            <Card.Body style={{ padding: '1rem 0 0', fontSize: '0.8rem' }}>
                                <ul>
                                    <li>
                                        <span className="link" onClick={handleCreateAccount}>Нова сметка</span>
                                    </li>
                                    <li>
                                        <span className="link">Нова фактура (TODO)</span>
                                    </li>
                                    <li>
                                        <span className="link" onClick={handleCreatePayment}>Ново плащане</span>
                                    </li>
                                    <li>
                                        <span className="link" onClick={handleCreatePackageCard}>Нова пакетна карта</span>
                                    </li>
                                    <li>
                                        <span className="link" onClick={handleCreateOrder}>Нова клиентска заявка (TODO)</span>
                                    </li>
                                </ul>
                            </Card.Body>
                        </Card>
                        <Card bg="light">
                            <Card.Header>
                                Справки
                            </Card.Header>
                            <Card.Body style={{ padding: '1rem 0 0', fontSize: '0.8rem' }}>
                                <ul>
                                    <li>
                                        <span className="link" onClick={() => { }}>История по сметки (TODO)</span>
                                    </li>
                                    <li>
                                        <span className="link" onClick={() => { }}>История на промените (TODO)</span>
                                    </li>
                                    <li>
                                        <span className="link" onClick={() => { }}>Сметки (TODO)</span>
                                    </li>
                                    <li>
                                        <span className="link" onClick={() => { }}>Клиентски поръчки (TODO)</span>
                                    </li>
                                    <li>
                                        <span className="link" onClick={() => { }}>Фактури (TODO)</span>
                                    </li>
                                    <li>
                                        <span className="link" onClick={() => { }}>Оферти (TODO)</span>
                                    </li>
                                    <li>
                                        <span className="link" onClick={() => { }}>Договори (TODO)</span>
                                    </li>
                                    <li>
                                        <span className="link" onClick={() => { }}>Гаранции (TODO)</span>
                                    </li>
                                    <li>
                                        <span className="link" onClick={() => { }}>Приемо-пред. протоколи (TODO)</span>
                                    </li>
                                </ul>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </>
    )
}

export default Index;