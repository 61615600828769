import { TYPES } from "constants/features";
import { useRef } from "react";
import { Button, ButtonGroup, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { TrashFill } from "react-bootstrap-icons";
import { debounce } from "lodash";

function Value(props) {
    const { state, setState, validations } = props;
    const { index, lang, detail } = props;

    const { handleInputChange, removeDetail } = props;

    const inputFileRef = useRef(null);

    // console.log(state.data)

    const handleChange = (name, value) => {
        // return setState(prev => ({
        //     ...prev,
        //     data: {
        //         ...prev.data,
        //         values: prev.data.values.map((v, i) => {
        //             if (i === index) {
        //                 v[name] = value;
        //             }

        //             return v;
        //         })
        //     }
        // }))

        return setState(name, value, 'data');
    }

    const handleValueChange = debounce(handleChange, 200);

    const handleShowAttach = e => {
        e.preventDefault();

        inputFileRef.current.click();
    }

    const handleAttach = e => {
        let files = [...e.target.files];

        if (files.length === 0) {
            return;
        }

        files.forEach((f, i) => {
            let url = URL.createObjectURL(f);

            files[i].id = Math.random().toString(7).substring(2);
            files[i].was_recently_attached = true;
            files[i].url = url;
        });

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                values: Object.values({
                    ...prev.data.values,
                    [index]: {
                        ...prev.data.values[index],
                        file: files[0],
                        image_url: files[0].url,
                        filename: files[0].name
                    }
                })
            }
        }));
    }


    return (
        <Row className="mb-3" >
            <Col>
                <Row>
                    <Col>
                        <FloatingLabel label="Стойност">
                            <Form.Control
                                type="text"
                                name={`values[${index}][langs][${lang.id}][name]`}
                                placeholder="Стойност"
                                defaultValue={state.data.values[index]?.langs[lang.id]?.name || ''}
                                onChange={e => handleValueChange(e.target.name, e.target.value)}
                                isInvalid={Boolean(validations?.values && validations.values[index]?.langs[lang.id] && validations.values[index].langs[lang.id].name)}
                            />
                            <Form.Control.Feedback type="invalid">
                                {validations?.values && validations.values[index] && validations.values[index].langs[lang.id] && (validations.values[index].langs[lang.id].name[0] || validations.values[index].langs[lang.id].name)}
                            </Form.Control.Feedback>
                        </FloatingLabel>

                        <input hidden type="hidden" name={`values[${index}][id]`} value={detail.id} />
                    </Col>
                </Row>

                {Number(state.data.type_id) === TYPES.COLOR &&
                    <Row className="mt-2" style={{ alignItems: 'center' }}>
                        <Col xs="auto">
                            <Form.Control
                                type="color"
                                name={`values[${index}][color]`}
                                defaultValue={state.data.values[index]?.color || ''}
                                onChange={e => handleValueChange(e.target.name, e.target.value)}
                                isInvalid={Boolean(validations?.values && validations.values[index]?.color)}

                            />
                            <Form.Control.Feedback type="invalid">
                                {validations?.values && validations.values[index]?.color && (validations.values[index].color[0] || validations.values[index].color)}
                            </Form.Control.Feedback>
                        </Col>
                        <Col xs="auto">
                            или
                        </Col>
                        <Col xs="auto">
                            <ButtonGroup onClick={handleShowAttach} style={{ height: '35px' }}>
                                <picture style={{
                                    width: '40px',
                                    padding: 0,
                                    border: '1px solid',
                                    borderTopLeftRadius: '0.2rem',
                                    borderBottomLeftRadius: '0.2rem',
                                }}>
                                    <img
                                        key={state.data.values[index]?.image_url}
                                        src={state.data.values[index]?.image_url || ''}
                                        style={{
                                            width: '40px',
                                            height: '100%',
                                            borderRadius: 'inherit',
                                            // border: '1px solid',
                                            // borderTopLeftRadius: '0.2 rem',
                                            // borderBottomLeftRadius: '0.2 rem',
                                            objectFit: 'cover'
                                        }}
                                        onError={e => e.target.classList.add('hidden')}
                                    />
                                </picture>
                                <Button variant="outline-dark" size="sm">
                                    Прикачете изображение
                                </Button>
                            </ButtonGroup>
                            {/* файла не се изпраща чрез формата, а чрез append към FormData??? */}
                            <input ref={inputFileRef} type="file" name={`values[${index}][image]`} onChange={handleAttach} hidden accept="image/*" />
                            <Form.Control.Feedback type="invalid">
                                {validations?.values && validations.values[index]?.image && (validations.values[index].image[0] || validations.values[index].image)}
                            </Form.Control.Feedback>
                        </Col>
                    </Row>
                }

                {Number(state.data.type_id) === TYPES.BRAND &&
                    <Row className="mt-2" style={{ alignItems: 'center' }}>
                        <Col xs="auto">
                            <Button variant="outline-dark" size="sm" onClick={handleShowAttach}>
                                Прикачете лого
                            </Button>
                            <input ref={inputFileRef} type="file" name={`values[${index}][image]`} onChange={handleAttach} hidden accept="image/*" />
                            <Form.Control.Feedback type="invalid">
                                {validations?.values && validations.values[index]?.image && (validations.values[index].image[0] || validations.values[index].image)}
                            </Form.Control.Feedback>
                        </Col>
                        <Col>
                            {state.data.values[index]?.image_url &&
                                <picture style={{
                                    height: '40px',
                                    width: '80px',
                                    background: '#eee',
                                    borderRadius: '3px',
                                    border: '1px solid #ccc',
                                    padding: '3px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                    <img
                                        key={state.data.values[index]?.image_url}
                                        src={state.data.values[index]?.image_url || ''}
                                        style={{
                                            height: '100%',
                                            width: '100%',
                                            borderRadius: 'inherit',
                                            // border: '1px solid',
                                            // borderTopLeftRadius: '0.2 rem',
                                            // borderBottomLeftRadius: '0.2 rem',
                                            objectFit: 'contain'
                                        }}
                                        onError={e => e.target.classList.add('hidden')}
                                    />
                                </picture>
                            }
                        </Col>
                    </Row>
                }
            </Col>
            <Col style={{ marginTop: '13px' }} xs="auto">
                <Button variant="danger" size="sm" onClick={e => removeDetail(index)}>
                    <TrashFill />
                </Button>
            </Col>
        </Row>
    )
}

export default Value;