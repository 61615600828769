import { Dropdown } from 'react-bootstrap';

function Options(props) {
    return (
        <>
            {props.data.deleted
                ?
                <Dropdown.Item disabled={props.data.allow_edit === false} onClick={e => props.handleRestore(props.data.article)}>
                    Възстанови
                </Dropdown.Item>
                :
                <>
                    <Dropdown.Item onClick={e => props.handleShow(props.data.id)}>
                        Преглед
                    </Dropdown.Item>
                    <Dropdown.Item disabled={props.data.allow_edit === false} onClick={e => props.handleEdit(props.data.article)}>
                        Редакция
                    </Dropdown.Item>
                    <Dropdown.Item disabled={props.data.allow_delete === false} onClick={e => props.handleDelete(props.data.article)}>
                        Изтриване
                    </Dropdown.Item>
                </>
            }
        </>
    )
}

export default Options;