import { useState, useImperativeHandle, forwardRef } from 'react'
import { Modal, Button } from 'react-bootstrap';

function Error(props, ref) {
    const [state, setState] = useState({
        show: false,
        params: null,
        msg: null,
        onSuccess: null,
        onClose: null,
    });

    useImperativeHandle(ref, () => ({
        open: (msg = null) => {
            handleShow(msg);
        },
        close: (onClose) => {
            handleClose(onClose);
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        }
    }));

    const show = () => {
        setState(prev => ({
            ...prev,
            show: true,
        }));
    }

    const hide = () => {
        setState(prev => ({
            ...prev,
            show: false,
        }));

        if (typeof state.onClose === 'function') {
            state.onClose();
        }
    }

    const handleShow = (msg) => {
        setState(prev => ({
            ...prev,
            msg: msg
        }));

        show();
    }

    const handleAccept = () => {
        hide();

        if (state.onSuccess && typeof state.onSuccess === 'function') {
            return state.onSuccess();
        }
    }

    const handleClose = (onClose) => {
        hide();

        if (typeof onClose === 'function') {
            onClose();
        } else if (typeof state.onClose === 'function') {
            state.onClose();
        }

        setState(prev => ({
            ...prev,
            onClose: null,
        }));
    }

    const getText = () => {
        return state.msg || props.mainMessage;
    }

    const createMarkup = () => {
        return { __html: getText() };
    }

    return (
        <Modal centered show={state.show} onHide={handleClose}>
            <Modal.Body>
                <div style={{ fontSize: '1rem', textAlign: 'left', fontWeight: '300' }}>
                    {typeof getText() === 'object'
                        ?
                        getText()
                        :
                        <p style={{ margin: 0 }} dangerouslySetInnerHTML={createMarkup()} />
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="dark" onClick={handleAccept}>
                    {props.agreeBtnText || 'ОК'}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default forwardRef(Error);