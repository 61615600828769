import Middleware from './Middleware';

class SuperAdmin extends Middleware {
    run(props, next) {
        const location = this.location.pathname + this.location.search;

        let hasAccess = false;

        if (this.user && this.user.isSuperAdmin()) {
            hasAccess = true;
        }

        if (!hasAccess) {
            return this.redirectWithState('/unauthorized', {
                from: location,
                permission: 'superadmin'
            });
        }

        return next(this.app);
    }
}

export default SuperAdmin;