import { useEffect, useRef, useState } from 'react';
import Api from 'helpers/Api';
import { buildSearchParams, buildUrl, useQuery } from 'helpers/Url';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Alert, Button, Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';

import Options from './partials/Options';

import Question from 'components/modals/Question';
import Filter from './partials/Filter';
import Layout from 'components/layout/Layout';
import AddOrEdit from './partials/AddOrEdit';

//misc
import MenuOptions from 'components/misc/MenuOptions'
import Pagination from 'components/misc/Pagination'
import Loader from 'components/misc/Loader';
import NoDataFound from 'components/misc/NoDataFound';
import { useAuthDataContext } from 'providers/Auth';
import TableHeader from 'components/misc/TableHeader';
import TableCol from 'components/misc/TableCol';
import PriceValue from 'components/partials/PriceValue';

let timeout;

function Index() {

    const location = useLocation();
    const navigate = useNavigate();
    const query = useQuery();
    const auth = useAuthDataContext();

    const tableRef = useRef(null);
    const deleteModalRef = useRef(null);
    const addOrEditModalRef = useRef(null);

    const [state, setState] = useState({
        sorting: false,
        loading: true,
        refresh: false,
        setFilter: false,
        data: [],
        pages: 0,
        total: 0,
        currency: null,
        filter: {
            page: 1,
        },
    });

    useEffect(() => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: query.get('page') || 1,
                depot_id: query.get('depot_id') || '',
                created_from_date: query.get('created_from_date') || '',
                created_to_date: query.get('created_to_date') || '',
                deleted: query.get('deleted') || '',
                sort: query.get('sort') || '',
                order: query.get('order') || '',
            },
            refresh: new Date().getTime()
        }))
    }, [location.search]);

    useEffect(() => {
        if (state.setFilter) {
            navigate('?' + buildSearchParams(query, state.filter));
        }

    }, [state.setFilter]);

    useEffect(() => {
        if (state.refresh) {
            loadData();
        }
    }, [state.refresh]);

    const loadData = () => {

        loading(true);

        let url = 'intrastats/all';

        Api.get(url, {
            params: state.filter
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data.items,
                total: res.data.total,
                pages: res.data.pages,
                totals: res.data.totals,
                filter: {
                    ...prev.filter,
                    ...res.data.filter,
                }
            }));

            if (res.data.pages > 0 && state.filter.page > res.data.pages) {
                return handlePage({ selected: res.data.pages - 1 });
            }

            loading(false);
            sorting(false);
        });
    }

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }));
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: reset ? 1 : prev.filter.page
            },
            setFilter: new Date().getTime(),
            refresh: new Date().getTime(),
        }));
    }

    const handleView = data => {
        navigate('/intrastats/' + data.id + '/edit');
    }

    const handleAdd = () => {
        let modal = addOrEditModalRef.current;

        modal.add();

        modal.onSuccess(data => {
            navigate('/intrastats/' + data.id + '/edit');
        });
    }

    // update
    const handleEdit = data => {
        if (data.deleted) {
            return;
        }

        let modal = addOrEditModalRef.current;

        modal.edit(data.id);

        modal.onSuccess(() => {
            refresh(false);
        });
    }

    // delete
    const handleDelete = data => {
        let modal = deleteModalRef.current;

        modal.open();
        modal.onSuccess(() => {
            handleDestroy(data.id);
        })
    }

    const handleDestroy = id => {
        Api.post('intrastats/delete', {
            id: id
        }).then(res => {
            if (res.data.success) {
                refresh(false);
            }
        });
    }

    const handleRestore = data => {
        Api.post('intrastats/restore', {
            id: data.id
        }).then(res => {
            if (res.data.success) {
                refresh(false);
            }
        });
    }


    // Search
    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout);

        if (typeof key === 'object') {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    ...key
                },
            }));
        } else {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    [key]: val,
                },
            }));
        }

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: 1
                },
                setFilter: new Date().getTime()
            }));
        }, delay);
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            setFilter: new Date().getTime()
        }));
    }

    const sorting = (sorting) => {
        setState(prev => ({
            ...prev,
            sorting: Boolean(sorting)
        }));
    }

    const handleSort = (sort, order) => {
        setState(prev => ({
            ...prev,
            sorting: true,
            filter: {
                ...prev.filter,
                sort: sort,
                order: order,
                page: 1,
            },
            setFilter: new Date().getTime()
        }));
    }

    return (
        <>

            <Question
                ref={deleteModalRef}
                mainMessage="Сигурни ли сте?"
                agreeBtnText="Изтриване"
                agreeBtnClass="remove"
            />

            <AddOrEdit
                ref={addOrEditModalRef}
            />


            <Layout>
                <div className="container-fluid">

                    <div className="page-head">
                        <h1 className="display-6">
                            Списък с интрастат декларации
                        </h1>

                        <div className="buttons">
                            <Button variant="outline-dark" onClick={handleAdd}>
                                Нова интрастат декларация
                            </Button>
                        </div>
                    </div>

                    <br />

                    <div className="panel">
                        <Filter
                            filter={state.filter}
                            handleSearch={handleSearch}
                        />

                        {state.loading && !state.sorting
                            ?
                            <Loader />
                            :
                            state.data.length === 0 ?
                                <NoDataFound />
                                :
                                <>
                                    <Table responsive striped hover ref={tableRef}>
                                        <TableHeader
                                            tableRef={tableRef}
                                            activeSortKey={state.filter.sort}
                                            activeSortDir={state.filter.order}
                                            onSort={(col, dir) => handleSort(col, dir)}
                                        >
                                            <TableCol sortable sortKey="id">ID</TableCol>
                                            <TableCol sortable sortKey="created_at">Създаден</TableCol>
                                            <TableCol sortable sortKey="declaration_id">ID на декларацията</TableCol>
                                            <TableCol className="text-right" sortable sortKey="period">Месец</TableCol>
                                            <TableCol className="text-right" sortable sortKey="rows_count">Брой транзакции</TableCol>
                                            <TableCol className="text-right" sortable sortKey="total_sum">Сума</TableCol>
                                            <TableCol className="options">Опции</TableCol>
                                        </TableHeader>

                                        <tbody>
                                            {state.data.map((c, index) =>
                                                <tr
                                                    key={'c-' + c.id + 'index-' + index}
                                                    className={c.deleted ? 'deleted' : ''}
                                                >
                                                    <td>
                                                        {c.id}
                                                    </td>
                                                    <td>
                                                        {c.created_at ? moment(c.created_at).format('DD.MM.YYYY HH:mm') : ''}
                                                    </td>
                                                    <td>
                                                        {c.declaration_id}
                                                    </td>
                                                    <td className="text-right">
                                                        {c.period ? moment(c.period).format('MM.YYYY') : ''}
                                                    </td>
                                                    <td className="text-right">
                                                        {c.rows_count}
                                                    </td>
                                                    <td className="text-right">
                                                        <PriceValue prefix={c.currency?.prefix} sufix={c.currency?.sufix}>{c.total_sum}</PriceValue>
                                                    </td>
                                                    <td className="options">
                                                        <MenuOptions>
                                                            <Options
                                                                data={c}
                                                                handleView={handleView}
                                                                handleEdit={handleEdit}
                                                                handleDelete={handleDelete}
                                                                handleRestore={handleRestore}
                                                            />
                                                        </MenuOptions>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>

                                    <Pagination
                                        className="mt-3"
                                        page={state.filter.page}
                                        pages={state.pages}
                                        total={state.total}
                                        handlePage={handlePage}
                                    />
                                </>
                        }

                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Index;
