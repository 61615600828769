import React, { useState, useImperativeHandle, forwardRef, useEffect, useRef } from 'react'
import { Modal, Button, Tabs, Tab } from 'react-bootstrap';
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons';
import SaveButton from 'components/misc/Button';
import Loader from 'components/misc/Loader';
import Home from './Home';
import History from './History';
import Notes from './Notes';
import RowsBySuppliers from './RowsBySuppliers';
import PackageCards from './PackageCards';
import AddPackageCard from './partials/AddPackageCard';
import Payments from './Payments';
import Accounts from './Accounts';
import Returns from './Returns';

import SendMail from 'components/pages/orders/partials/SendMail';
import Note from 'components/partials/notes/AddOrEdit';
import Article from 'components/pages/articles/partials/AddOrEdit';
import SmartUcfStatus from 'components/pages/orders/partials/SmartUcfStatus';

import Question from 'components/modals/Question';
import Info from 'components/modals/Info';

import Api from 'helpers/Api';

import { MODELS } from 'constants/models';
import Refs from 'Refs';
import { useAppContext } from 'providers/App';
import { useAuthDataContext } from 'providers/Auth';

function View(props, ref) {

    const tabs = [
        {
            id: 'home',
            name: 'Преглед',
            component: Home
        },
        {
            id: 'history',
            name: 'История',
            component: History
        },
        {
            id: 'notes',
            name: 'Бележки',
            component: Notes
        },
        {
            id: 'by_suppliers',
            name: 'По доставчици',
            component: RowsBySuppliers
        },
        {
            id: 'accounts',
            name: 'Сметки',
            component: Accounts
        },
        {
            id: 'payments',
            name: 'Плащания',
            component: Payments
        },
        {
            id: 'cards',
            name: 'Пакетни карти',
            component: PackageCards
        },
        {
            id: 'returns',
            name: 'Връщания',
            component: Returns
        },
    ];

    const app = useAppContext();
    const auth = useAuthDataContext();

    const [state, setState] = useState({
        show: false,
        params: null,
        id: null,
        tabId: null,
        key: 1,
        onEntering: null,
        onExiting: null,
        onClose: null,
    });

    const sendMailModalRef = useRef(null);
    const noteModalRef = useRef(null);
    const articleModalRef = useRef(null);
    const addPackageCardModalRef = useRef(null);
    const questionModalRef = useRef(null);
    const infoModalRef = useRef(null);
    const smartUcfStatusModalRef = useRef(null);
    const leftArrowRef = useRef(null);
    const rightArrowRef = useRef(null);

    useImperativeHandle(ref, () => ({
        open: (id, resetTab = true) => {
            handleShow(id, resetTab);
        },
        close: () => {
            hide();
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        }
    }));

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const handleShow = (id, resetTab = true) => {
        setState(prev => ({
            ...prev,
            id: id,
            tabId: resetTab || !prev.tabId ? getDefaultActiveTab()?.id : prev.tabId,
        }));

        show();
    }

    const handleClose = () => {
        setState(prev => ({
            ...prev,
            show: false,
            tabId: tabs[0].id
        }));

        if (typeof state.onClose === 'function') {
            hide(state.onClose);
        } else {
            hide();
        }

        setState(prev => ({
            ...prev,
            onClose: null,
        }));
    }

    const setKey = id => {
        setState(prev => ({
            ...prev,
            tabId: id,
        }));
    }

    const refresh = () => {
        setState(prev => ({
            ...prev,
            key: new Date().getTime(),
        }));
    }

    const hasTabPermission = key => {
        let map = {
            notes: 'clientorders_notes',
        };

        let permission = map[key];

        if (permission && auth.getUser().permission(permission) === false) {
            return false;
        }

        return true;
    }

    const getDefaultActiveTab = () => {
        return tabs.find(tab => {
            return hasTabPermission(tab.id);
        });
    }

    const showArticle = id => {
        if (!id) {
            return;
        }

        let modal = Refs.getInstance().getRef('article');

        if (!modal) {
            return;
        }

        hide(() => {
            modal.open(id);
        });

        modal.onClose(() => {
            show();
        });
    }

    const showClient = id => {
        if (!id) {
            return;
        }

        let modal = Refs.getInstance().getRef('client');

        if (!modal) {
            return;
        }

        hide(() => {
            modal.open(id);
        });

        modal.onClose(() => {
            show();
        });
    }

    const sendMail = order => {
        if (!order) {
            return;
        }

        let modal = sendMailModalRef.current;

        if (!modal) {
            return;
        }

        hide(() => {
            modal.show(order.id, order.client_email);
        });

        modal.onClose(() => {
            show();
        });
    }

    const addNote = () => {

        let modal = noteModalRef.current;

        if (!modal) {
            return;
        }

        hide(() => {
            modal.add(state.id, MODELS.ORDER);
        });

        modal.onClose(() => {
            show();
        });
    }

    const editNote = (id) => {

        let modal = noteModalRef.current;

        if (!modal) {
            return;
        }

        hide(() => {
            modal.edit(id);
        });

        modal.onClose(() => {
            show();
        });
    }

    const deleteNote = (id) => {

        let modal = questionModalRef.current;

        if (!modal) {
            return;
        }

        hide(() => {
            modal.open('Сигурни ли сте, че искате да изтриете тази бележка?');
        });

        modal.onCancel(() => {
            show();
        });

        modal.onSuccess(() => {
            destroyNote(id, () => {
                show();
            });
        });
    }

    const destroyNote = (id, cb) => {
        Api.post('notes/delete', {
            id: id
        }).then(res => {
            cb();
        });
    }

    const addPackageCard = () => {

        let modal = addPackageCardModalRef.current;

        if (!modal) {
            return;
        }

        hide(() => {
            modal.add(state.id);
        });

        modal.onClose(() => {
            show();
        });
    }

    const deletePackageCard = (cardId) => {

        let modal = infoModalRef.current;

        if (!modal) {
            return;
        }

        hide(() => {
            modal.open('Това действие ще възстанови сумата и използванията на пакетната карта, но няма да прекалкулира цените на артикулите в клиентската заявка');
        });

        modal.onCancel(() => {
            show();
        });

        modal.onSuccess(() => {
            destroyPackageCard(cardId, () => {
                show();
            });
        });
    }

    const destroyPackageCard = (cardId, cb) => {
        Api.post('orders/package-cards/remove', {
            id: state.id,
            card_id: cardId
        }).then(res => {
            cb();
        });
    }

    const showAccount = (id) => {
        let modal = Refs.getInstance().getRef('account');

        if (!modal) {
            return;
        }

        hide(() => {
            modal.open(id);
        });

        modal.onClose(() => {
            show();
        });

    }

    const deleteAccount = (id) => {

        let modal = questionModalRef.current;

        if (!modal) {
            return;
        }

        hide(() => {
            modal.open('Сигурни ли сте, че искате да изтриете тази сметка?');
        });

        modal.onCancel(() => {
            show();
        });

        modal.onSuccess(() => {
            destroyAccount(id, () => {
                show();
            });
        });
    }

    const destroyAccount = (id, cb) => {
        Api.post('accounts/delete', {
            id: id
        }).then(res => {
            cb();
        });
    }

    const showPayment = (id) => {
        let modal = Refs.getInstance().getRef('payment');

        if (!modal) {
            return;
        }

        hide(() => {
            modal.open(id);
        });

        modal.onClose(() => {
            show();
        });

    }

    const showSmartUcfStatus = id => {
        let modal = smartUcfStatusModalRef.current;

        if (!modal) {
            return;
        }

        hide(() => {
            modal.show(state.id);
        });

        modal.onClose(() => {
            show();
        });
    }

    const newReturn = () => {
        let modal = Refs.getInstance().getRef('newReturn');

        if (!modal) {
            return;
        }

        hide(() => {
            modal.open(state.id);
        });

        modal.onClose(() => {
            show();
        });
    }

    const showReturn = (id) => {
        let modal = Refs.getInstance().getRef('return');

        if (!modal) {
            return;
        }

        hide(() => {
            modal.open(id);
        });

        modal.onClose(() => {
            show();
        });

    }

    const deleteReturn = (id) => {
        let modal = questionModalRef.current;

        if (!modal) {
            return;
        }

        hide(() => {
            modal.open('Сигурни ли сте, че искате да изтриете това връщане?');
        });

        modal.onCancel(() => {
            show();
        });

        modal.onSuccess(() => {
            destroyReturn(id, () => {
                show();
            });
        });
    }

    const destroyReturn = (id, cb) => {
        Api.post('returns/delete', {
            id: id
        }).then(res => {
            cb();
        });
    }

    useEffect(() => {
        if (!state.show) {
            return;
        }

        const onKeyPress = (e) => {
            if (e.key === 'ArrowRight') {
                rightArrowRef.current?.click();
            }

            if (e.key === 'ArrowLeft') {
                leftArrowRef.current?.click();
            }
        }

        window.addEventListener('keyup', onKeyPress);

        return () => {
            window.removeEventListener('keyup', onKeyPress);
        }
    }, [state.show]);

    const handleShowNextItem = e => {
        e.preventDefault();

        if (props.showNextItem) {
            props.showNextItem(state.id);
        }
    }

    const handleShowPrevItem = e => {
        e.preventDefault();

        if (props.showPrevItem) {
            props.showPrevItem(state.id);
        }
    }

    const handleRefresh = e => {
        e.preventDefault();

        refresh();
    }

    return (
        <>
            <SendMail
                ref={sendMailModalRef}
            />

            <Note
                ref={noteModalRef}
            />

            <AddPackageCard
                ref={addPackageCardModalRef}
            />

            <SmartUcfStatus
                ref={smartUcfStatusModalRef}
            />

            <Question
                ref={questionModalRef}
            />

            <Info
                ref={infoModalRef}
                showCancel
            />

            <Modal size="xl" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                {props.showPrevItem &&
                    <span ref={leftArrowRef} className="arrow left" onClick={handleShowPrevItem}>
                        <ChevronLeft />
                    </span>
                }
                {props.showNextItem &&
                    <span ref={rightArrowRef} className="arrow right" onClick={handleShowNextItem}>
                        <ChevronRight />
                    </span>
                }
                <Modal.Header>
                    <Modal.Title>
                        Преглед на клиентска заявка
                    </Modal.Title>
                    <div className="buttons">
                        <button className="btn-reload" onClick={handleRefresh}>

                        </button>
                        <button className="btn-close" onClick={handleClose}>

                        </button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Tabs
                        key={state.key}
                        activeKey={state.tabId}
                        onSelect={(k) => setKey(k)}
                        className="mb-3"
                        mountOnEnter
                    >
                        {tabs.map(tab =>
                            <Tab key={tab.id} eventKey={tab.id} title={tab.name} disabled={hasTabPermission(tab.id) === false}>
                                {React.createElement(tab.component, {
                                    id: state.id,
                                    show: show,
                                    hide: hide,
                                    showArticle: showArticle,
                                    showClient: showClient,
                                    sendMail: sendMail,
                                    addNote: addNote,
                                    editNote: editNote,
                                    deleteNote: deleteNote,
                                    addPackageCard: addPackageCard,
                                    deletePackageCard: deletePackageCard,
                                    showAccount: showAccount,
                                    deleteAccount: deleteAccount,
                                    showPayment: showPayment,
                                    showSmartUcfStatus: showSmartUcfStatus,
                                    newReturn: newReturn,
                                    showReturn: showReturn,
                                    deleteReturn: deleteReturn,
                                })}
                            </Tab>
                        )}
                    </Tabs>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Затвори
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default forwardRef(View);