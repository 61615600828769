import { useState, useEffect, useRef } from 'react'
import { Button, Col, Row } from 'react-bootstrap';
import { TrashFill } from 'react-bootstrap-icons';
import Api from 'helpers/Api';
import Loader from 'components/misc/Loader';
import SaveButton from 'components/misc/Button';
import Alert from 'components/misc/Alert';
import { useAppContext } from 'providers/App';
import { useValidation } from 'helpers/Validation';
import Autocomplete from 'components/misc/Autocomplete';

let timeout;

function Index(props) {

    const app = useAppContext();

    const formRef = useRef(null);

    const [state, setState] = useState({
        data: null,
        pages: 0,
        total: 0,
        totals: {},
        loading: true,
        filter: {
            page: 1,
        },
        refresh: false,
        loadingSave: false
    });

    const [validations, setValidations] = useValidation();

    useEffect(() => {

        if (!state.data) {
            loading(true);
        }

        Api.get('articles/details', {
            params: {
                id: props.id,
                ...state.filter,
            }
        }).then(res => {

            let data = res.data;

            if (data.details) {
                data.details = res.data.details.map(detail => {
                    return {
                        name: detail?.detail?.translation?.name || detail?.detail?.name,
                        value: detail?.value?.translation?.name || detail?.value?.name,
                        detail_id: detail.detail_id,
                        value_id: detail.value_id,
                    }
                });
            } else {
                data.details = [];
            }

            setState(prev => ({
                ...prev,
                data: data,
                filter: {
                    ...prev.filter,
                    ...res.data.filter,
                },
            }));

            if (res.data.pages > 0 && state.filter.page > res.data.pages) {
                return handlePage({ selected: res.data.pages - 1 });
            }

            loading(false);
            sorting(false);
        });
    }, [props.id, state.refresh]);

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }));
    }

    const sorting = (sorting) => {
        setState(prev => ({
            ...prev,
            sorting: Boolean(sorting)
        }));
    }

    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout);

        if (typeof key === 'object') {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    ...key
                },
            }));
        } else {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    [key]: val,
                },
            }));
        }

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: 1
                },
                refresh: new Date().getTime()
            }));
        }, delay);
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            refresh: new Date()
        }));
    }

    const handleSort = (sort, order) => {
        setState(prev => ({
            ...prev,
            sorting: true,
            filter: {
                ...prev.filter,
                sort: sort,
                order: order,
                page: 1,
            },
            refresh: new Date().getTime()
        }));
    }

    const handleSave = (e) => {
        e.preventDefault();

        if (state.loadingSave) {
            return;
        }

        setState(prev => ({
            ...prev,
            loadingSave: true,
        }));

        let data = new FormData(formRef.current);

        data.append('id', props.id);

        Api.post('articles/details', data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {

            if (typeof props.onSuccess === 'function') {
                props.onSuccess(res.data);
            }

            app.showSuccess();
        }).catch(error => {
            const _err = error.response;

            if (_err && _err.status && _err.status === 422) {
                setValidations(_err.data.errors)
            } else {
                app.showError();
            }

        }).finally(() => {
            setState(prev => ({
                ...prev,
                loadingSave: false,
            }));
        });
    }

    const addDetail = () => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                details: prev.data.details.concat({})
            }
        }));
    }

    const removeDetail = (index) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                details: prev.data.details.filter((d, i) => Number(i) !== Number(index))
            }
        }));
    }

    const handleDetailChange = (data, index) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                details: Object.values({
                    ...prev.data.details,
                    [index]: {
                        ...prev.data.details[index],
                        detail_id: data.id,
                        name: data.name,
                        value: '',
                        value_id: ''
                    }
                })
            }
        }))
    }

    return (

        <>
            {state.loading
                ?
                <div style={{ padding: '100px 0' }}>
                    <Loader />
                </div>
                :
                <>
                    {state.data.details.length === 0 &&
                        <Alert variant="primary">
                            Няма добавени характеристики
                        </Alert>
                    }

                    <form ref={formRef}>
                        {state.data.details.map((detail, index) =>
                            <Row key={'d-' + index}>
                                <Col className="mb-3">
                                    <Autocomplete
                                        url="autocomplete/article-details"
                                        inputPlaceholder="Име"
                                        inputName={`details[${index}][name]`}
                                        inputIdName={`details[${index}][id]`}
                                        inputValue={state.data.details[index].name || ''}
                                        inputIdValue={state.data.details[index].detail_id || ''}
                                        error={Boolean(validations?.details && validations.details[index] && validations.details[index].name)}
                                        helperText={validations?.details && validations.details[index] && validations.details[index].name && (validations.details[index].name[0] || validations.details[index].name)}
                                        onChange={data => handleDetailChange(data, index)}
                                        renderText={data => data?.translation?.name || data.name}
                                    />
                                </Col>
                                <Col className="mb-3">
                                    <Autocomplete
                                        url="autocomplete/article-details-values"
                                        params={{
                                            detail_id: state.data.details[index].detail_id,
                                        }}
                                        requiredParams={['detail_id']}
                                        inputPlaceholder="Стойност"
                                        inputName={`details[${index}][value]`}
                                        inputIdName={`details[${index}][value_id]`}
                                        // inputIdValue={`details[${index}][value_id]`}
                                        inputValue={state.data.details[index].value || ''}
                                        inputIdValue={state.data.details[index].value_id || ''}
                                        error={Boolean(validations?.details && validations.details[index] && validations.details[index].value)}
                                        helperText={validations?.details && validations.details[index] && validations.details[index].value && (validations.details[index].value[0] || validations.details[index].value)}
                                        // onChange={data => handleDetailChange(data,index)}
                                        renderInputText={data => data?.translation?.name || data.name}
                                        renderText={data => (
                                            data.color
                                                ?
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div style={{ width: 20, height: 20, marginRight: 20, backgroundColor: data.color }}>

                                                    </div>
                                                    <div>
                                                        {data?.translation?.name || data.name}
                                                    </div>
                                                </div>
                                                :
                                                data?.translation?.name || data.name
                                        )}
                                    />
                                </Col>
                                <Col className="mb-3" style={{ display: 'flex', alignItems: 'center' }} xs="auto">
                                    <Button variant="danger" size="sm" onClick={e => removeDetail(index)}>
                                        <TrashFill />
                                    </Button>
                                </Col>
                            </Row>
                        )}
                    </form>

                    <Button variant="outline-dark" size="sm" onClick={addDetail}>
                        Добави нова характеристика
                    </Button>

                    <div
                        className="mt-3"
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <SaveButton
                            loading={state.loadingSave}
                            className="save"
                            onClick={handleSave}
                        />
                    </div>
                </>
            }
        </>
    )
}

export default Index;