import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import moment from 'moment'
import { Modal, Button, Tabs, Tab, FloatingLabel, Form, Row, Col, Card } from 'react-bootstrap';

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//providers
import { useLanguageContext } from 'providers/Language'

//misc
import SaveButton from 'components/misc/Button'
import Info from 'components/modals/Info';
import HelpInfo from 'components/misc/Info'

function AddOrEdit(props, ref) {

    const defaultData = {
        image: {},
    }

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        edit: false,
        data: defaultData,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null
    });

    const [validations, setValidations] = useValidation();

    const inputImageRef = useRef(null);
    const validationModalRef = useRef(null);

    useImperativeHandle(ref, () => ({
        add: () => {
            handleShow();
        },
        edit: (id) => {
            edit(id);
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        }
    }));

    useEffect(() => {
        loadData();
    }, [state.edit]);

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const handleShow = () => {
        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
        }));
        show();
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
        }));

        setValidations(null);

        hide();
    }

    const edit = id => {
        setState(prev => ({
            ...prev,
            id: id,
            edit: new Date().getTime()
        }));

        show();
    }

    const loadData = () => {
        if (!state.id) {
            return;
        }

        Api.get('categories_options/show?id=' + state.id)
            .then(res => {

                let data = res.data;
                data.image = {}

                if (data.option) {
                    data.image = data.option.image_url;
                }

                setState(prev => ({
                    ...prev,
                    data
                }))

                if (data.option) data.in_header = res.data.option.in_header

                setState(prev => ({
                    ...prev,
                    data
                }))
            });
    }

    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = 'categories_options/update';

        let data = new FormData(e.target);

        if (state.id) {
            data.append('category_id', state.id)
        }

        if (state.data.image.was_recently_attached) {
            data.append('image', state.data.image);
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (typeof state.onSuccess === 'function') {
                state.onSuccess(res.data)
            }

            hide()
        }).catch(error => {
            const _err = error.response;
            if (_err && _err.status && _err.status === 422) {
                hide(() => {
                    let modal = validationModalRef.current;

                    if (modal) {
                        modal.open();

                        modal.onClose(() => {
                            setValidations(_err.data.errors)
                            show();
                        });
                    }
                });
            }

        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }));
        });
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleShowImageAttach = e => {
        e.preventDefault();

        inputImageRef.current.click();
    }

    const handleImageAttach = e => {
        let file = e.target.files[0];
        let url = URL.createObjectURL(file);

        file.id = Math.random().toString(7).substring(2);
        file.was_recently_attached = true;
        file.url = url;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                image: file
            }
        }));
    }

    const handleCheckboxChange = e => {
        const name = e.target.name;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: prev.data[name] ? false : true
            }
        }));
    }


    return (
        <>
            <Info
                ref={validationModalRef}
                mainMessage="Моля, попълнете всички задължителни полета, означени с червен цвят!"
            />

            <Modal size="lg" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <form onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Категория
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Col className="mb-3">
                            <FloatingLabel label="Име">
                                <Form.Control
                                    type="text"
                                    placeholder="Име"
                                    value={state.data.translation?.name || ''}
                                    disabled
                                />
                            </FloatingLabel>
                        </Col>

                        <Col className="mb-3" style={{ display: 'flex' }}>
                            <Form.Check
                                type="switch"
                                name="in_header"
                                label="Хедър"
                                value="1"
                                checked={Number(state.data?.in_header) === 1}
                                onChange={handleCheckboxChange}
                            />
                            <HelpInfo marginLeft="10px" placement="top">Избиране дали избраната от вас категория да се показва в навигацията на сайта.</HelpInfo>
                        </Col>

                        <Col>
                            <div style={{ height: !state.data.image?.url ? '0' : 'auto' }} className={state.data.image?.url ? "mb-3" : ""}>
                                <>
                                    <div className="file">
                                        <img style={{ maxHeight: '100px' }} src={state.data.image?.url} onError={e => e.target.classList.add('hidden')} />
                                    </div>

                                    <div className="validate">
                                        {validations?.image}
                                    </div>
                                </>
                            </div>

                            <Button variant="outline-dark" size="sm" onClick={handleShowImageAttach}>
                                Прикачете изображение
                            </Button>

                            <input ref={inputImageRef} type="file" accept=".png,.jpeg,.jpg" onChange={handleImageAttach} hidden />
                        </Col>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            className="save"
                        />
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default forwardRef(AddOrEdit);
