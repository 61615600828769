import { useEffect, useRef, useState } from 'react';
import Api from 'helpers/Api';
import { useQuery } from 'helpers/Url';
import { useLocation, useNavigate, Link, useParams } from 'react-router-dom';
import { Button, Table, FloatingLabel, Form, Row, Col, Container, InputGroup } from 'react-bootstrap';
import { ArrowLeftCircle, TrashFill, ArrowLeftRight, Upload, Check2Square } from 'react-bootstrap-icons';
import { useNestedState } from 'helpers/NestedState';
import { useCurrencyContext } from 'providers/Currency';
import { useValidation } from 'helpers/Validation';
import { useAuthDataContext } from 'providers/Auth';
import { cloneDeep } from 'lodash';

import Question from 'components/modals/Question';
import Layout from 'components/layout/Layout';

//misc
import Loader from 'components/misc/Loader';
import moment from 'moment';
import Autocomplete from 'components/misc/Autocomplete';
import SaveButton from 'components/misc/Button';
import { isNumeric } from 'helpers/Price';
import Info from 'components/misc/Info';
import Error from 'components/modals/Error';
import DatePicker from 'components/partials/filter/DatePicker';
import Import from 'components/modals/Import';
import { TYPES } from 'constants/imports';
import Refs from 'Refs';

let timeout;

function Index() {

    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const query = useQuery();

    const auth = useAuthDataContext();
    const currencies = useCurrencyContext();

    const user = auth.getUser();

    const [state, setState] = useNestedState({
        loading: false,
        loadingBase: false,
        loadingClose: false,
        loadingSend: false,
        loadingCancel: false,
        loadingAccept: false,
        dataLoading: false,
        data: {
            rows: [{}],
            allow_modify: user.permission('storemoves_edit') ? true : false,
            allow_change_depots: user.permission('storemoves_edit') ? true : false,
            allow_close: user.permission('storemoves_edit') ? true : false,
            allow_send: user.permission('storemoves_between_barsys') ? true : false,
        },
        timeout: null
    });

    const [availability, setAvailability] = useState([]);

    const [depots, setDepots] = useState([]);

    const [validations, setValidations] = useValidation();

    const formRef = useRef(null);
    const saveAndCloseModalRef = useRef(null);
    const saveAndSendModalRef = useRef(null);
    const errorModalRef = useRef(null);
    const importModalRef = useRef(null);

    useEffect(() => {
        console.log(availability)
    }, [availability]);

    useEffect(() => {
        Api.get('storemoves/depots')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setDepots(res.data);
                }
            });
    }, [auth.getUser()?.getStoreId()]);

    useEffect(() => {
        if (params.id) {
            loadData();
        }
    }, [params.id, auth.getUser()?.getStoreId()]);

    // зареждане на данни от поръчка/сметка
    useEffect(() => {
        if (params.id) {
            return;
        }

        // от зареждане
        if (query.get('from_load_id')) {
            loadDataFromStoreLoad();
        }

        // от прехвърляне
        if (query.get('from_move_id')) {
            loadDataFromStoreMove();
        }

    }, [location]);

    useEffect(() => {
        if (state.data.rows.length === 0) {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    rows: [{}]
                }
            }));
        }
    }, [state.data.rows]);

    useEffect(() => {
        getArticleAvailability();
    }, [state.data.from_depot_id, state.data.to_depot_id]);

    const loadData = () => {

        loading(true);

        let url = 'storemoves/show-edit-data';

        Api.get(url, {
            params: {
                id: params.id,
                move_id: query.get('from_move_id'),
            }
        }).then(res => {
            handleResponse(res);
        }).catch(err => {
            handleError(err);
        }).finally(() => {
            loading(false);
        })
    }

    const loadDataFromStoreLoad = () => {

        loading(true);

        let url = 'storemoves/from-storeload';

        Api.get(url, {
            params: {
                load_id: query.get('from_load_id')
            }
        }).then(res => {
            handleResponse(res);
        }).catch(err => {
            handleError(err);
        }).finally(() => {
            loading(false);
        })
    }

    const loadDataFromStoreMove = () => {

        loading(true);

        let url = 'storemoves/from-storemove';

        Api.get(url, {
            params: {
                move_id: query.get('from_move_id')
            }
        }).then(res => {
            handleResponse(res);
        }).catch(err => {
            handleError(err);
        }).finally(() => {
            loading(false);
        })
    }

    const handleResponse = res => {

        let data = res.data;

        let rows = data.rows;

        rows = rows.map(row => {
            //

            return row;
        });

        data.rows = rows;

        setState(prev => ({
            ...prev,
            data: data,
        }));
    }

    const handleError = error => {
        console.log(error);

        const _err = error.response;

        if (_err && _err.status) {
            if (_err.status === 422) {
                setValidations(_err.data.errors);
            } else {
                let modal = errorModalRef.current;

                if (modal) {
                    modal.open(_err.data.error || _err.data.message);

                    modal.onClose(() => {
                        navigate(-1, {
                            replace: true
                        });
                    });
                }
            }
        }
    }

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            dataLoading: Boolean(loading)
        }));
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleCheckboxChange = e => {
        const name = e.target.name;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: prev.data[name] ? false : true
            }
        }));
    }

    const save = (close = false, send = false) => {
        if (state.loading) {
            return;
        }

        setValidations(null);

        let url = params.id ? 'storemoves/edit' : 'storemoves/add';

        let form = formRef.current;

        let data = new FormData(form);

        if (params.id) {
            data.append('id', params.id)
        }

        if (close) {
            data.append('close', 1);
        }

        if (send) {
            data.append('send', 1);
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            navigate('/storage/moves');
        }).catch(error => {
            handleSaveError(error);
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
                loadingBase: false,
                loadingClose: false,
                loadingSend: false,
            }));
        });
    }

    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
            loadingBase: true,
        }));

        save(false);
    }

    const handleSaveAndClose = e => {
        e.preventDefault();

        let modal = saveAndCloseModalRef.current;

        if (modal) {
            modal.open();

            modal.onSuccess(() => {
                setState(prev => ({
                    ...prev,
                    loading: true,
                    loadingClose: true,
                }));

                save(true);
            });
        }

    }

    const handleSaveAndSend = e => {
        e.preventDefault();

        let modal = saveAndSendModalRef.current;

        if (modal) {
            modal.open();

            modal.onSuccess(() => {
                setState(prev => ({
                    ...prev,
                    loading: true,
                    loadingSend: true,
                }));

                save(false, true);
            });
        }

    }

    const handleSaveReceived = e => {
        e.preventDefault();

        if (state.loading) {
            return;
        }

        setState(prev => ({
            ...prev,
            loading: true,
            loadingBase: true,
        }));

        setValidations(null);

        let url = 'storemoves/edit-from-receiver';

        let form = formRef.current;

        let data = new FormData(form);

        data.append('id', params.id)

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            handleResponse(res);
        }).catch(error => {
            handleSaveError(error);
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
                loadingBase: false,
                loadingClose: false,
                loadingSend: false,
            }));
        });
    }

    const handleCancel = e => {
        e.preventDefault();

        let modal = Refs.getInstance().getRef('question');

        modal.open('Сигурни ли сте?');
        modal.onSuccess(() => {
            cancel();
        })
    }

    const cancel = () => {
        setState(prev => ({
            ...prev,
            loading: true,
            loadingCancel: true,
        }));

        Api.post('storemoves/cancel', {
            id: state.data.id
        }).then(res => {
            if (res.data.success) {
                navigate('/storage/moves');
            }
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
                loadingCancel: false,
            }));
        });
    }

    const handleAccept = e => {
        e.preventDefault();

        let modal = Refs.getInstance().getRef('question');

        modal.open('Сигурни ли сте?');
        modal.onSuccess(() => {
            accept();
        })
    }

    const accept = () => {
        setState(prev => ({
            ...prev,
            loading: true,
            loadingAccept: true,
        }));

        let form = formRef.current;

        let data = new FormData(form);

        data.append('id', params.id)

        Api.post('storemoves/accept', data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (res.data.success) {
                navigate('/storage/moves');
            }
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
                loadingAccept: false,
            }));
        });
    }

    const handleSaveError = error => {
        const _err = error.response;

        if (_err && _err.status) {
            if (_err.status === 422) {
                setValidations(_err.data.errors);
            } else {
                let modal = errorModalRef.current;

                if (modal) {
                    modal.open(_err.data.error || _err.data.message);
                }
            }
        }
    }

    const handleAddRow = e => {
        e.preventDefault()

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: prev.data.rows.concat({})
            }
        }))
    }

    const handleRemoveRow = (e, index) => {
        e.preventDefault()

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: prev.data.rows.filter((row, i) => i !== index)
            }
        }))
    }

    const handleAcceptRow = (e, index) => {
        e.preventDefault()

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: Object.values({
                    ...prev.data.rows,
                    [index]: {
                        ...prev.data.rows[index],
                        received_quantity: prev.data.rows[index].quantity
                    }
                })
            }
        }))
    }

    const handleChangeArticle = (data, index) => {

        let row = state.data.rows[index];

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                rows: Object.values({
                    ...prev.data.rows,
                    [index]: {
                        ...prev.data.rows[index],
                        article: data,
                        article_id: data.id,
                        quantity: 1
                    }
                })
            }
        }));

        getArticleAvailability(data.id);

    }

    const getArticleAvailability = (id) => {

        let ids = [];

        if (id) {
            ids = [id];
        } else {
            ids = state.data.rows.map(r => r.article_id).filter(r => r);
        }

        let fromDepotId = state.data.from_depot_id;
        let toDepotId = state.data.to_depot_id;

        let depots = [fromDepotId, toDepotId];

        let availabilityData = {};

        Api.get('articles/availability', {
            params: {
                id: ids,
                depot_id: depots
            }
        }).then(res => {
            res.data.map(data => {
                if (!availabilityData[data.article_id]) {
                    availabilityData[data.article_id] = {
                        quantity_from_depot_before: null,
                        quantity_to_depot_before: null
                    }
                }

                if (Number(data.depot_id) === Number(fromDepotId)) {
                    availabilityData[data.article_id].quantity_from_depot_before = data.quantity;
                } else if (Number(data.depot_id) === Number(toDepotId)) {
                    availabilityData[data.article_id].quantity_to_depot_before = data.quantity;
                }

            });

            setAvailability(prev => ({
                ...prev,
                ...availabilityData
            }));

        });

    }

    // при смяна на количеството 
    const handleQtyChange = (e, index) => {
        handleInputChange(e);

        let value = e.target.value;

    }

    const calcQuantityFromDepotAfter = (quantityBefore, quantity) => {
        return (parseFloat(quantityBefore) || 0) - parseFloat(quantity)
    }

    const calcQuantityToDepotAfter = (quantityBefore, quantity) => {
        return (parseFloat(quantityBefore) || 0) + parseFloat(quantity)
    }

    const getTotalQty = () => {
        let total = 0;

        state.data.rows.map(row => {
            total += parseFloat(row.quantity || 0);
        });

        return total;
    }

    const handleFromDepotChange = e => {
        let id = e.target.value;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                from_depot_id: id,
            }
        }))
    }

    const handleToDepotChange = e => {
        let id = e.target.value;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                to_depot_id: id,
            }
        }))
    }

    const getFromDepots = () => {
        let data = cloneDeep(depots).filter(d => Number(d.store_id) === Number(auth.getUser().getStoreId()));

        // return depots.filter(d => Number(d.id) !== Number(state.data.to_depot_id));

        return data;
    }

    const getToDepots = () => {
        let data = cloneDeep(depots).filter(d => Number(d.store_id) !== Number(auth.getUser().getStoreId()));

        // return depots.filter(d => Number(d.id) !== Number(state.data.from_depot_id));

        return data;
    }

    const handleSwitchDepots = () => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                from_depot_id: prev.data.to_depot_id,
                to_depot_id: prev.data.from_depot_id,
            }
        }))
    }

    const getQuantityFromDepotBefore = articleId => {
        return availability[articleId] && isNumeric(availability[articleId].quantity_from_depot_before) ? availability[articleId].quantity_from_depot_before : 0;
    }

    const getQuantityToDepotBefore = articleId => {
        return availability[articleId] && isNumeric(availability[articleId].quantity_to_depot_before) ? availability[articleId].quantity_to_depot_before : 0;
    }

    const getQuantityFromDepotAfter = row => {
        return calcQuantityFromDepotAfter(getQuantityFromDepotBefore(row.article_id), row.quantity);
    }

    const getQuantityToDepotAfter = row => {
        return calcQuantityToDepotAfter(getQuantityToDepotBefore(row.article_id), row.quantity);
    }

    const handleImport = () => {
        let modal = importModalRef.current;

        if (!modal) {
            return;
        }

        modal.open();
        // modal.setParams(state.data);

        modal.onChunk(({ is_test, type_id, results }) => {
            // console.log(data);

            // setState(prev => ({
            //     ...prev,
            //     data: {
            //         ...prev.data,
            //         rows: results
            //     }
            // }))

            // замяна или добавяне
            let erase = Number(type_id) === 1;

            let rows;

            if (erase) {
                rows = [];
            } else {
                rows = cloneDeep(state.data.rows);
            }

            // console.log(erase)
            // console.log(rows)
            // console.log(results);

            results.map((row, index) => {
                let data = row.article;

                rows.push(row);

                getArticleAvailability(data.id);
            });

            // console.log(rows);

            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    rows: Object.values(rows)
                }
            }));
        })

        modal.onSuccess(() => {
            // alert(1);
        })
    }

    const showArticle = id => {
        let modal = Refs.getInstance().getRef('article');

        if (!modal) {
            return;
        }

        modal.open(id);
    }

    const isAcceptButtonDisabled = () => {
        return state.data.rows.filter(row => Number(row.quantity) !== Number(row.received_quantity)).length > 0;
    }

    return (
        <>

            <Question
                ref={saveAndCloseModalRef}
                mainMessage="Сигурни ли сте, че искате да извършите това прехвърляне веднага към другия склад?"
            />

            <Question
                ref={saveAndSendModalRef}
            >
                <p style={{ fontSize: '1.25rem' }}>
                    Сигурни ли сте, че искате да изпратите това прехвърляне към другия склад?
                </p>
                <br />
                Веднъж изпратено прехвърлянето ще може да бъде редактирано само от получателя.
                <br />
                Стоките ще се прехвърлят между складовете след като бъде потвърдено от другата страна.
            </Question>

            <Error
                ref={errorModalRef}
            />

            <Import
                ref={importModalRef}
                type={TYPES.STOREMOVE}
                createText="Замяна"
                updateText="Добавяне"
            />

            <Layout>
                <div className="container-fluid">

                    <div className="page-head">
                        <h1 className="display-6">
                            <Link to={-1} className="link-dark text-decoration-none">
                                <ArrowLeftCircle />
                            </Link> {params.id ? 'Редактиране на прехвърляне' : 'Ново прехвърляне'}
                        </h1>

                        <div className="buttons">

                        </div>
                    </div>

                    <br />

                    <div className="panel">

                        {state.dataLoading
                            ?
                            <Loader />
                            :
                            <>
                                <Form ref={formRef}>
                                    {/* <Container> */}
                                    <div className="form-panel">
                                        <div className="head">
                                            Данни за прехвърлянето
                                        </div>
                                        <Row>
                                            <Col className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Прехвърляне №
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder=""
                                                            name="ref"
                                                            value={state.data.id || ''}
                                                            readOnly
                                                            size="sm"
                                                            disabled={state.data.allow_modify === false}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Създал
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder=""
                                                            name="ref"
                                                            value={state.data?.creator?.username || ''}
                                                            readOnly
                                                            size="sm"
                                                            disabled={state.data.allow_modify === false}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Създадено на
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder=""
                                                            name="ref"
                                                            value={state.data.created_at ? moment(state.data.created_at).format('DD.MM.YYYY HH:mm') : ''}
                                                            readOnly
                                                            size="sm"
                                                            disabled={state.data.allow_modify === false}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={4} className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Документ дата
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <DatePicker
                                                            variant="basic"
                                                            type="datetime-local"
                                                            name="document_date"
                                                            value={state.data?.document_date ? moment(state.data.document_date).format('YYYY-MM-DDTHH:mm') : ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations?.document_date)}
                                                            size="sm"
                                                            error={validations?.document_date && (validations.document_date[0] || validations.document_date)}
                                                            disabled={state.data.allow_modify === false}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col sm={4} className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Приел
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder=""
                                                            name="ref"
                                                            value={state.data?.acceptor?.name || ''}
                                                            readOnly
                                                            size="sm"
                                                            disabled={state.data.allow_modify === false}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col sm={4} className="mb-3">
                                                <Form.Group as={Row}>
                                                    <Form.Label column="sm" sm={4} className="text-left">
                                                        Статус
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder=""
                                                            name="ref"
                                                            value={state.data?.user_status?.translation?.name || state.data?.user_status?.name || ''}
                                                            readOnly
                                                            size="sm"
                                                            disabled={state.data.allow_modify === false}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                    </div>

                                    <Row className="mt-3" style={{ justifyContent: 'center' }}>
                                        <Col md={2}>
                                            <FloatingLabel label="От склад">
                                                <Form.Select
                                                    name="from_depot_id"
                                                    value={state.data.from_depot_id || ''}
                                                    onChange={handleFromDepotChange}
                                                    isInvalid={Boolean(validations?.from_depot_id)}
                                                    className="box-shadow"
                                                    disabled={state.data.allow_change_depots === false}
                                                >
                                                    {state.data.fromdepot
                                                        ?
                                                        <option value="">{state.data.fromdepot?.store?.name} - {state.data.fromdepot?.translation?.name || state.data.fromdepot.name}</option>
                                                        :
                                                        <>
                                                            <option value=""></option>
                                                            {getFromDepots().map(o =>
                                                                <option key={o.id} value={o.id}>
                                                                    {o?.store?.translation?.name || o?.store?.name} - {o?.translation?.name || o.name}
                                                                </option>
                                                            )}
                                                        </>
                                                    }
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    {validations?.from_depot_id && (validations.from_depot_id[0] || validations.from_depot_id)}
                                                </Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                        <Col xs="auto">
                                            <Button variant="outline-dark" onClick={handleSwitchDepots} style={{ marginTop: '10px' }} disabled>
                                                <ArrowLeftRight />
                                            </Button>
                                        </Col>
                                        <Col md={2}>
                                            <FloatingLabel label="В склад">
                                                <Form.Select
                                                    name="to_depot_id"
                                                    value={state.data.to_depot_id || ''}
                                                    onChange={handleToDepotChange}
                                                    isInvalid={Boolean(validations?.to_depot_id)}
                                                    className="box-shadow"
                                                    disabled={state.data.allow_change_depots === false}
                                                >
                                                    {state.data.todepot
                                                        ?
                                                        <option value="">{state.data.todepot?.store?.name} - {state.data.todepot?.translation?.name || state.data.todepot.name}</option>
                                                        :
                                                        <>
                                                            <option value=""></option>
                                                            <optgroup label="Локални">
                                                                {getFromDepots().map(o =>
                                                                    <option key={o.id} value={o.id}>
                                                                        {o?.store?.translation?.name || o?.store?.name} - {o?.translation?.name || o.name}
                                                                    </option>
                                                                )}
                                                            </optgroup>
                                                            <optgroup label="Отдалечени">
                                                                {getToDepots().map(o =>
                                                                    <option key={o.id} value={o.id}>
                                                                        {o?.store?.translation?.name || o?.store?.name} - {o?.translation?.name || o.name}
                                                                    </option>
                                                                )}
                                                            </optgroup>
                                                        </>
                                                    }
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    {validations?.to_depot_id && (validations.to_depot_id[0] || validations.to_depot_id)}
                                                </Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                    </Row>

                                    <div className="form-panel mt-3">
                                        <div className="head">
                                            Артикули
                                        </div>

                                        {state.data.allow_modify
                                            ?
                                            <Table className="valign-top big">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: '5%' }}>
                                                            #
                                                        </th>
                                                        <th style={{ width: '20%' }}>
                                                            Артикул
                                                        </th>
                                                        <th style={{ width: '10%' }}>
                                                            Текущо <Info placement="top">Текущо количество от артикула в склада, от който ще се мести стоката</Info>
                                                        </th>
                                                        <th style={{ width: '10%' }}>
                                                            След <Info placement="top">Количество от артикула, което ще остане в склада, от който се мести стоката</Info>
                                                        </th>
                                                        <th style={{ width: '10%' }}>
                                                            Количество <Info placement="top">Количество, което желаете да преместите</Info>
                                                        </th>
                                                        <th style={{ width: '10%' }}>
                                                            Текущо <Info placement="top">Текущо количество от артикула в склада, към който ще се мести стоката</Info>
                                                        </th>
                                                        <th style={{ width: '10%' }}>
                                                            След <Info placement="top">Количество от артикула, което ще има в склада, към който се мести стоката</Info>
                                                        </th>
                                                        <th style={{ width: '20%' }}>
                                                            Бележки
                                                        </th>
                                                        <th className="options">

                                                        </th>
                                                    </tr>

                                                </thead>
                                                <tbody>
                                                    {state.data.rows.map((row, index) =>
                                                        <tr key={'r-' + index}>
                                                            <td title={row.id || '-'} style={{ width: '5%' }}>
                                                                {index + 1}

                                                                <input type="hidden" name={`rows[${index}][id]`} value={state.data.rows[index].id || ''} />
                                                            </td>
                                                            <td style={{ width: '20%' }}>
                                                                <Autocomplete
                                                                    variant="basic"
                                                                    url="autocomplete/articles"
                                                                    inputName="article_name"
                                                                    inputIdName={`rows[${index}][article_id]`}
                                                                    inputValue={state.data?.rows[index] && state.data?.rows[index]?.article?.name ? state.data?.rows[index]?.article?.name : ''}
                                                                    inputIdValue={state.data?.rows[index] && state.data?.rows[index].article_id ? state.data?.rows[index].article_id : ''}
                                                                    onChange={data => handleChangeArticle(data, index)}
                                                                    error={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].article_id && (validations.rows[index].article_id[0] || validations.rows[index].article_id))}
                                                                    helperText={validations && validations.rows && validations.rows[index] && validations.rows[index].article_id && (validations.rows[index].article_id[0] || validations.rows[index].article_id)}
                                                                    renderText={data => {
                                                                        return (
                                                                            <div style={{ display: 'flex', width: '100%' }}>
                                                                                <div style={{ width: 'max-content', marginRight: '10px' }}>
                                                                                    {data.id}
                                                                                </div>
                                                                                <div>
                                                                                    {data.name}
                                                                                    <br />
                                                                                    {data.article_name}
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }}
                                                                    renderInputText={data => data.article_name}
                                                                    size="sm"
                                                                    listStyle={{
                                                                        minWidth: 'max-content'
                                                                    }}
                                                                    autoFocus
                                                                />
                                                            </td>
                                                            <td>
                                                                <InputGroup size="sm" style={{ minWidth: '95px' }}>
                                                                    <Form.Control
                                                                        type="number"
                                                                        step="0.0001"
                                                                        value={getQuantityFromDepotBefore(state.data?.rows[index].article_id)}
                                                                        size="sm"
                                                                        className={`price-input  ${getQuantityFromDepotBefore(state.data?.rows[index].article_id) < 0 && 'negative-value'}`}
                                                                        disabled
                                                                    />

                                                                    <InputGroup.Text>
                                                                        {state.data?.rows[index]?.article?.amount_type_short || '-'}
                                                                    </InputGroup.Text>
                                                                </InputGroup>
                                                            </td>
                                                            <td>
                                                                <InputGroup size="sm" style={{ minWidth: '95px' }}>
                                                                    <Form.Control
                                                                        type="number"
                                                                        step="0.0001"
                                                                        value={getQuantityFromDepotAfter(state.data?.rows[index])}
                                                                        size="sm"
                                                                        className={`price-input  ${getQuantityFromDepotAfter(state.data?.rows[index]) < 0 && 'negative-value'}`}
                                                                        disabled
                                                                    />

                                                                    <InputGroup.Text>
                                                                        {state.data?.rows[index]?.article?.amount_type_short || '-'}
                                                                    </InputGroup.Text>
                                                                </InputGroup>
                                                            </td>
                                                            <td style={{ width: '10%' }}>
                                                                <InputGroup size="sm" style={{ minWidth: '95px' }}>
                                                                    <Form.Control
                                                                        type="number"
                                                                        step="0.0001"
                                                                        name={`rows[${index}][quantity]`}
                                                                        value={state.data?.rows[index] && state.data?.rows[index].quantity ? state.data?.rows[index].quantity : ''}
                                                                        onChange={e => handleQtyChange(e, index)}
                                                                        isInvalid={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].quantity && (validations.rows[index].quantity[0] || validations.rows[index].quantity))}
                                                                        size="sm"
                                                                        className="price-input"
                                                                    />

                                                                    <InputGroup.Text>
                                                                        {state.data?.rows[index]?.article?.amount_type_short || '-'}
                                                                    </InputGroup.Text>

                                                                    {/* <Form.Control.Feedback type="invalid">
                                                                        {validations && validations.rows && validations.rows[index] && validations.rows[index].quantity && (validations.rows[index].quantity[0] || validations.rows[index].quantity)}
                                                                    </Form.Control.Feedback> */}
                                                                </InputGroup>

                                                            </td>
                                                            <td>
                                                                <InputGroup size="sm" style={{ minWidth: '95px' }}>
                                                                    <Form.Control
                                                                        type="number"
                                                                        step="0.0001"
                                                                        value={getQuantityToDepotBefore(state.data?.rows[index].article_id)}
                                                                        size="sm"
                                                                        className={`price-input  ${getQuantityToDepotBefore(state.data?.rows[index].article_id) < 0 && 'negative-value'}`}
                                                                        disabled
                                                                    />

                                                                    <InputGroup.Text>
                                                                        {state.data?.rows[index]?.article?.amount_type_short || '-'}
                                                                    </InputGroup.Text>
                                                                </InputGroup>
                                                            </td>
                                                            <td>
                                                                <InputGroup size="sm" style={{ minWidth: '95px' }}>
                                                                    <Form.Control
                                                                        type="number"
                                                                        step="0.0001"
                                                                        value={getQuantityToDepotAfter(state.data?.rows[index])}
                                                                        size="sm"
                                                                        className={`price-input  ${getQuantityToDepotAfter(state.data?.rows[index]) < 0 && 'negative-value'}`} disabled
                                                                    />

                                                                    <InputGroup.Text>
                                                                        {state.data?.rows[index]?.article?.amount_type_short || '-'}
                                                                    </InputGroup.Text>
                                                                </InputGroup>
                                                            </td>
                                                            <td style={{ width: '20%' }}>
                                                                <Form.Control
                                                                    type="text"
                                                                    name={`rows[${index}][notes]`}
                                                                    value={state.data?.rows[index] && state.data?.rows[index].notes ? state.data?.rows[index].notes : ''}
                                                                    onChange={handleInputChange}
                                                                    isInvalid={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].notes && (validations.rows[index].notes[0] || validations.rows[index].notes))}
                                                                    size="sm"
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {validations && validations.rows && validations.rows[index] && validations.rows[index].notes && (validations.rows[index].notes[0] || validations.rows[index].notes)}
                                                                </Form.Control.Feedback>
                                                            </td>
                                                            <td className="options">
                                                                <Button variant="danger" size="sm" onClick={e => handleRemoveRow(e, index)}>
                                                                    <TrashFill />
                                                                </Button>
                                                            </td>

                                                        </tr>
                                                    )}
                                                    {/* <tr>
                                                    <td colSpan={4}>
                                                        Общо
                                                    </td>
                                                    <td className="text-right">
                                                        {getTotalQty().toFixed(4)}
                                                    </td>
                                                </tr> */}

                                                </tbody>
                                            </Table>
                                            :
                                            <Table className="valign-top big">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: '5%' }}>
                                                            #
                                                        </th>
                                                        <th style={{ width: '40%' }}>
                                                            Артикул
                                                        </th>
                                                        <th style={{ width: '20%' }}>
                                                            Бележки
                                                        </th>
                                                        <th>
                                                            Изпратено количество
                                                        </th>
                                                        <th>
                                                            Получено количество
                                                        </th>
                                                        <th className="options">

                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {state.data.rows.map((row, index) =>
                                                        <tr key={'r-' + index}>
                                                            <td title={row.id || '-'} style={{ width: '5%' }}>
                                                                {index + 1}

                                                                <input type="hidden" name={`rows[${index}][id]`} value={state.data.rows[index].id || ''} />
                                                            </td>
                                                            <td style={{ width: '40%' }}>
                                                                <span className="link" onClick={e => showArticle(row.article_id)}>
                                                                    {row.article?.article_name}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                {row.notes}
                                                            </td>
                                                            <td>
                                                                {row.quantity} {row.article?.amount_type_short || '-'}
                                                            </td>
                                                            <td>
                                                                <InputGroup size="sm" style={{ minWidth: '95px' }}>
                                                                    <Form.Control
                                                                        type="number"
                                                                        step="0.0001"
                                                                        name={`rows[${index}][received_quantity]`}
                                                                        value={row.received_quantity || ''}
                                                                        onChange={handleInputChange}
                                                                        isInvalid={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].received_quantity && (validations.rows[index].received_quantity[0] || validations.rows[index].received_quantity))}
                                                                        size="sm"
                                                                        className={`price-input ${row.received_quantity ? (Number(row.quantity) === Number(row.received_quantity) ? 'positive-value' : 'negative-value') : ''} `}
                                                                    />

                                                                    <InputGroup.Text>
                                                                        {row.article?.amount_type_short || '-'}
                                                                    </InputGroup.Text>

                                                                    {/* <Form.Control.Feedback type="invalid">
                                                                        {validations && validations.rows && validations.rows[index] && validations.rows[index].received_quantity && (validations.rows[index].received_quantity[0] || validations.rows[index].received_quantity)}
                                                                    </Form.Control.Feedback> */}
                                                                </InputGroup>
                                                            </td>
                                                            <td className="options">
                                                                <Button variant="primary" size="sm" onClick={e => handleAcceptRow(e, index)}>
                                                                    <Check2Square />
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </Table>
                                        }

                                        {state.data.allow_modify &&
                                            <>
                                                <Button variant="outline-dark" className="mt-3" size="sm" onClick={handleAddRow}>
                                                    Нов ред
                                                </Button>
                                                <Button variant="outline-dark" className="mt-3" size="sm" onClick={handleImport} disabled={auth.getUser().permission('import_index') === false} style={{ marginLeft: '5px' }}>
                                                    <Upload /> Импорт
                                                </Button>
                                            </>
                                        }
                                    </div>


                                    <Row>
                                        <Col sm={12}>
                                            <div className="form-panel mt-3">
                                                <div className="head">
                                                    Бележки
                                                </div>
                                                <Form.Control
                                                    type="text"
                                                    as="textarea"
                                                    // placeholder="Бележки"
                                                    name="description"
                                                    value={state.data?.description || ''}
                                                    onChange={handleInputChange}
                                                    isInvalid={Boolean(validations?.description)}
                                                    rows={6}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {validations?.description && (validations.description[0] || validations.description)}
                                                </Form.Control.Feedback>
                                            </div>

                                        </Col>

                                    </Row>

                                    <div className="buttons mt-3">
                                        <Button
                                            variant="secondary"
                                            onClick={e => navigate(-1)}
                                        >
                                            Отказ
                                        </Button>
                                        {state.data.allow_modify &&
                                            <SaveButton
                                                loading={state.loadingBase}
                                                disabled={state.loading}
                                                className="save"
                                                onClick={handleSave}
                                            >
                                                Запази данните
                                            </SaveButton>
                                        }
                                        {state.data.allow_accept &&
                                            <SaveButton
                                                loading={state.loadingBase}
                                                disabled={state.loading}
                                                className="save"
                                                onClick={handleSaveReceived}
                                            >
                                                Запиши получените
                                            </SaveButton>
                                        }
                                        {state.data.allow_cancel &&
                                            <SaveButton
                                                loading={state.loadingCancel}
                                                disabled={state.loading}
                                                className="save"
                                                onClick={handleCancel}
                                                variant="danger"
                                            >
                                                Откажи прехвърлянето
                                            </SaveButton>
                                        }
                                        {state.data.allow_modify && state.data.allow_close &&
                                            <SaveButton
                                                loading={state.loadingClose}
                                                disabled={state.loading}
                                                className="save"
                                                onClick={handleSaveAndClose}
                                            >
                                                Запази и премести артикулите
                                            </SaveButton>
                                        }
                                        {state.data.allow_accept &&
                                            <SaveButton
                                                loading={state.loadingAccept}
                                                disabled={state.loading || isAcceptButtonDisabled()}
                                                className="save"
                                                onClick={handleAccept}
                                            >
                                                Приеми прехвърлянето
                                            </SaveButton>
                                        }
                                        {state.data.allow_send &&
                                            <SaveButton
                                                loading={state.loadingSend}
                                                disabled={state.loading}
                                                className="save"
                                                onClick={handleSaveAndSend}
                                            >
                                                {state.data.canceled
                                                    ?
                                                    'Запази и изпрати пак'
                                                    :
                                                    'Запази и изпрати'
                                                }
                                            </SaveButton>
                                        }
                                    </div>
                                    {/* </Container> */}

                                </Form>
                            </>
                        }

                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Index;