import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import { Modal, Button, Row, Col, FloatingLabel, Form } from 'react-bootstrap';

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//providers
import { useCurrencyContext } from 'providers/Currency';

//misc
import Autocomplete from 'components/misc/Autocomplete';
import SaveButton from 'components/misc/Button'
import Info from 'components/modals/Info';
import Item from './Item';

function AddOrEdit(props, ref) {

    const currencies = useCurrencyContext();

    const defaultData = {}
    const [methods, setMethods] = useState([]);
    const [categories, setCategories] = useState([]);
    const [parents, setParents] = useState([]);

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        edit: false,
        data: defaultData,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null,
        refresh: false
    });

    const [validations, setValidations] = useValidation();

    const validationModalRef = useRef(null);
    const inputFileRef = useRef(null);

    useImperativeHandle(ref, () => ({
        add: () => {
            handleShow();
        },
        edit: (id) => {
            edit(id);
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        }
    }));

    useEffect(() => {
        loadData();
    }, [state.edit]);

    useEffect(() => {
        Api.get('roles/get-roles')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setParents(res.data);
                }
            });

        Api.get('roles/categories')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setCategories(res.data);
                }
            });

        // Api.get('roles/payment-methods')
        //     .then(res => {
        //         if (Array.isArray(res.data)) {
        //             setMethods(res.data);
        //         }
        //     });
    }, [state.refresh]);


    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const handleShow = () => {
        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
        }));

        show();
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
        }));

        setValidations(null);

        hide();
    }

    const edit = id => {
        setState(prev => ({
            ...prev,
            id: id,
            edit: new Date().getTime()
        }));

        show();
    }

    const loadData = () => {
        if (!state.id) {
            return;
        }

        Api.get('roles/edit?id=' + state.id)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data
                }))
            });
    }

    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = state.id ? 'roles/update' : 'roles/add';

        let data = new FormData(e.target);

        if (state.id) {
            data.append('id', state.id)
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (typeof state.onSuccess === 'function') {
                state.onSuccess(res.data)
            }

            hide()
        }).catch(error => {
            const _err = error.response;
            if (_err && _err.status && _err.status === 422) {
                hide(() => {
                    let modal = validationModalRef.current;

                    if (modal) {
                        modal.open();

                        modal.onClose(() => {
                            setValidations(_err.data.errors)
                            show();
                        });
                    }
                });
            }
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }));
            refresh(true)
        });
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            refresh: new Date().getTime(),
        }));
    }

    const handleCheckboxChange = e => {
        const name = e.target.name;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: prev.data[name] ? false : true
            }
        }));
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleAllowedPaymentsChange = (data) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                allowed_payments: data,
            }
        }));
    }

    const handleDeniedPaymentsChange = (data) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                denied_payments: data,
            }
        }));
    }

    console.log(state.data);

    return (
        <>
            <Info
                ref={validationModalRef}
                mainMessage="Моля, попълнете всички задължителни полета, означени с червен цвят!"
            />

            <Modal centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <form onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Роля
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <FloatingLabel label="Име" className="mb-3">
                            <Form.Control
                                type="text"
                                name={`name`}
                                value={state.data.name || ''}
                                placeholder="Име"
                                onChange={(e) => handleInputChange(e)}
                                isInvalid={Boolean(validations?.name)}
                            />
                            <Form.Control.Feedback type="invalid">
                                {validations?.name}
                            </Form.Control.Feedback>
                        </FloatingLabel>

                        <Col className="mb-3">
                            <FloatingLabel label="Висшестоящ">
                                <Form.Select
                                    name="parent_id"
                                    value={state.data.parent_id || ''}
                                    onChange={handleInputChange}
                                >
                                    <option value=""></option>
                                    {parents.map((item, index) =>
                                        <Item
                                            onChange={handleInputChange}
                                            role_id={state.data.id}
                                            key={item.id}
                                            index={index}
                                            level={1}
                                            {...item}
                                        />
                                    )}
                                </Form.Select>
                            </FloatingLabel>
                        </Col>

                        <Col className="mb-3">
                            <FloatingLabel label="Категория">
                                <Form.Select
                                    name="admin_category_id"
                                    value={state.data.admin_category_id || ''}
                                    onChange={handleInputChange}
                                >
                                    {categories.map((item, index) =>
                                        <option
                                            value={item.id}
                                            key={item.id}
                                            index={index}
                                        >
                                            {item.translation?.name || ''}
                                        </option>
                                    )}
                                </Form.Select>
                            </FloatingLabel>
                        </Col>

                        <Col className="mb-3">
                            <Autocomplete
                                multiple
                                url="autocomplete/payment-methods"
                                inputPlaceholder="Разрешени начини на плащане"
                                selectedValues={state.data.allowed_payments}
                                inputIdName={`allowed_payment_id`}
                                renderText={data => data?.name || ''}
                                inputValue={state.data.client_name || ''}
                                inputIdValue={state.data.allowed_payment_id || ''}
                                error={Boolean(validations?.allowed_payment_id)}
                                helperText={validations?.allowed_payment_id && (validations?.allowed_payment_id[0] || validations?.allowed_payment_id)}
                                onChange={data => handleAllowedPaymentsChange(data)}
                            />
                        </Col>

                        <Col className="mb-3">
                            <Autocomplete
                                multiple
                                url="autocomplete/payment-methods"
                                inputPlaceholder="Забранени начини на плащане"
                                inputIdName={`denied_payment_id`}
                                selectedValues={state.data.denied_payments}
                                renderText={data => data?.name || ''}
                                inputValue={state.data.client_name || ''}
                                inputIdValue={state.data.denied_payment_id || ''}
                                error={Boolean(validations?.denied_payment_id)}
                                helperText={validations?.denied_payment_id && (validations?.denied_payment_id[0] || validations?.denied_payment_id)}

                                onChange={data => handleDeniedPaymentsChange(data)}
                            />
                        </Col>

                        <Form.Check
                            type="switch"
                            name="in_working_schedule"
                            label="Участие в работен график	"
                            className="mb-3 mt-3"
                            checked={Number(state.data?.in_working_schedule) === 1}
                            onChange={handleCheckboxChange}
                        />

                        <Form.Check
                            type="switch"
                            name="banned"
                            label="Блокиран"
                            className="mb-3 mt-3"
                            checked={Number(state.data?.banned) === 1}
                            onChange={handleCheckboxChange}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            className="save"
                        />
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default forwardRef(AddOrEdit);
