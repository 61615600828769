import LockedFilters from "./LockedFilters";

class LockedFilter {
    key;
    value = null;
    storage = null;

    constructor(key) {
        this.key = key;
    }

    setStorage(storage) {
        this.storage = storage;

        LockedFilters.setStorage(this);

        return this;
    }

    setValue(value) {
        this.value = value;

        LockedFilters.setValue(this);

        return this;
    }

    getValue() {
        return this.value;
    }

    getPrefixedKey() {
        return 'filter_' + this.key;
    }
}

export default LockedFilter;