import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import moment from "moment"
import { FloatingLabel, Form, Dropdown, Row, Col } from 'react-bootstrap'
import { CaretLeft, CaretDown, CaretRight, CaretDownFill } from 'react-bootstrap-icons'

//providers
import { useAuthDataContext } from "providers/Auth"

//helpers
import { useQuery } from 'helpers/Url'
import Api from 'helpers/Api'
import Loader from 'components/misc/Loader'
import Autocomplete from 'components/misc/Autocomplete'
import DatePicker from 'components/partials/filter/DatePicker'

function Filter(props) {
    const history = useNavigate()
    const query = useQuery()

    const auth = useAuthDataContext();

    const [operations, setOperations] = useState([]);
    const [depots, setDepots] = useState([]);
    const [documentTypes, setDocumentTypes] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState([]);

    const [filters, setFilters] = useState({
        show: false,
    });

    const hasOpenedPermission = auth.getUser().permission('storeloads_opened');
    const hasClosedPermission = auth.getUser().permission('storeloads_closed');

    useEffect(() => {
        Api.get('storeloads/operations')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setOperations(res.data);
                }
            });

        Api.get('storeloads/documents-types')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setDocumentTypes(res.data);
                }
            });

        Api.get('storeloads/payment-methods')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setPaymentMethods(res.data);
                }
            });
    }, []);

    useEffect(() => {
        Api.get('storeloads/depots')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setDepots(res.data);
                }
            });
    }, [auth.getUser()?.getStoreId()]);

    const handleDateChange = (date, name) => {
        props.handleSearch(name, date ? moment(date).format('YYYY-MM-DD') : '');
    }

    const handleSearch = e => {
        const name = e.target.name
        const value = e.target.value

        props.handleSearch(name, value)
    }

    const handleFromDateChange = e => {
        const name = e.target.name
        const value = e.target.value

        let date = value ? moment(value).format('YYYY-MM-DD') : '';

        props.handleSearch({
            created_from_date: date,
            created_to_date: date,
        });
    }

    const handleToDateChange = e => {
        const value = e.target.value

        let date = value ? moment(value).format('YYYY-MM-DD') : '';

        if (date && props.filter.created_from_date > date) {
            return;
        }

        props.handleSearch({
            created_to_date: date,
        });
    }

    const handleChangeSupplier = data => {
        props.handleSearch({
            supplier_id: data.id,
        });
    }

    const handleChangeUser = data => {
        props.handleSearch({
            user_id: data.id,
        });
    }

    const handleChangeArticle = data => {
        props.handleSearch({
            article_id: data?.id || '',
        });
    }

    const toggleFilters = () => {
        setFilters(prev => ({
            ...prev,
            show: !prev.show,
        }))
    }

    const getMoreFiltersValue = () => {
        let data = [];

        if (props.filter.created_from_date) {
            data.push(1);
        }

        if (props.filter.created_to_date) {
            data.push(1);
        }

        if (props.filter.depot_id) {
            data.push(1);
        }

        if (props.filter.user_id) {
            data.push(1);
        }

        if (props.filter.document_type_id) {
            data.push(1);
        }

        if (props.filter.document_no) {
            data.push(1);
        }

        if (props.filter.document_date) {
            data.push(1);
        }

        if (props.filter.supplier_id) {
            data.push(1);
        }

        if (props.filter.paid_status) {
            data.push(1);
        }

        if (props.filter.deleted) {
            data.push(1);
        }

        if (data.length === 0) {
            return 'Няма избрани';
        }

        return data.length + ' избрани';
    }

    return (
        <div className="row page-filter">
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <FloatingLabel label="Статус">
                    <Form.Select
                        name="status_id"
                        value={props.filter.status_id || ''}
                        onChange={handleSearch}
                    >
                        <option value="" disabled={auth.getUser().permission('storeloads_opened') === false || auth.getUser().permission('storeloads_closed') === false}>Всички</option>
                        <option value={1} disabled={auth.getUser().permission('storeloads_opened') === false}>Отворени</option>
                        <option value={2} disabled={auth.getUser().permission('storeloads_closed') === false}>Затворени</option>
                    </Form.Select>
                </FloatingLabel>
            </div>
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <FloatingLabel label="Операция">
                    <Form.Select
                        name="operation_id"
                        value={props.filter.operation_id || ''}
                        onChange={handleSearch}
                    >
                        <option value="">Всички</option>
                        {operations.map(o =>
                            <option key={o.id} value={o.id}>{o?.translation?.name || o.name}</option>
                        )}
                    </Form.Select>
                </FloatingLabel>
            </div>
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <Autocomplete
                    inputPlaceholder="Артикул"
                    url="autocomplete/articles"
                    inputIdName="article_id"
                    // inputValue={state.data?.supplier?.name}
                    inputIdValue={props.filter.article_id || ''}
                    onChange={handleChangeArticle}
                    onInputChange={handleChangeArticle}
                    renderText={data => {
                        return (
                            <div style={{ display: 'flex', width: '100%' }}>
                                <div style={{ width: 'max-content', marginRight: '10px' }}>
                                    {data.id}
                                </div>
                                <div>
                                    {data.name}
                                    <br />
                                    {data.article_name}
                                </div>
                            </div>
                        )
                    }}
                    renderInputText={data => data.article_name}
                />
            </div>
            <div
                className="col col-auto col-md-2 col-12 mb-2"
            >
                <Dropdown drop="down" onToggle={toggleFilters} show={filters.show}>
                    <FloatingLabel label="Още филтри">
                        <Form.Control
                            type="text"
                            placeholder="Още филтри"
                            value={getMoreFiltersValue()}
                            onClick={toggleFilters}
                            readOnly
                            style={{
                                cursor: 'pointer'
                            }}
                        />
                    </FloatingLabel>
                    <Dropdown.Menu align="start" style={{ padding: '15px', width: '340px' }}>
                        <Row>
                            <Col className="mb-3">
                                <DatePicker
                                    placeholder="Създаден от"
                                    name="created_from_date"
                                    value={props.filter.created_from_date || ''}
                                    onChange={handleFromDateChange}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-3">
                                <DatePicker
                                    placeholder="Създаден до"
                                    name="created_to_date"
                                    value={props.filter.created_to_date || ''}
                                    onChange={handleToDateChange}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-3">
                                <FloatingLabel label="Склад">
                                    <Form.Select
                                        name="depot_id"
                                        value={props.filter.depot_id || ''}
                                        onChange={handleSearch}
                                    >
                                        <option value="">Всички</option>
                                        {depots.map(o =>
                                            <option key={o.id} value={o.id}>{o?.translation?.name || o.name}</option>
                                        )}
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                            <Col className="mb-3">
                                <Autocomplete
                                    inputPlaceholder="Създадено от"
                                    url="autocomplete/admins"
                                    inputIdName="user_id"
                                    // inputValue={state.data?.supplier?.name}
                                    inputIdValue={props.filter.user_id || ''}
                                    onChange={handleChangeUser}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-3">
                                <FloatingLabel label="Тип документ">
                                    <Form.Select
                                        name="document_type_id"
                                        value={props.filter.document_type_id || ''}
                                        onChange={handleSearch}
                                    >
                                        <option value="">Всички</option>
                                        {documentTypes.map(o =>
                                            <option key={o.id} value={o.id}>{o?.translation?.name || o.name}</option>
                                        )}
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6} className="mb-3">
                                <FloatingLabel label="Документ №">
                                    <Form.Control
                                        type="text"
                                        placeholder="Документ №"
                                        name="document_no"
                                        value={props.filter.document_no || ''}
                                        onChange={handleSearch}
                                    />
                                </FloatingLabel>
                            </Col>
                            <Col sm={6} className="mb-3">
                                <DatePicker
                                    placeholder="Документ дата"
                                    name="document_date"
                                    value={props.filter.document_date || ''}
                                    onChange={e => handleDateChange(e.target.value, e.target.name)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-3">
                                <Autocomplete
                                    inputPlaceholder="Доставчик"
                                    url="autocomplete/suppliers"
                                    inputIdName="supplier_id"
                                    // inputValue={state.data?.supplier?.name}
                                    inputIdValue={props.filter.supplier_id || ''}
                                    onChange={handleChangeSupplier}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-3">
                                <FloatingLabel label="Платена сума">
                                    <Form.Select
                                        name="paid_status"
                                        value={props.filter.paid_status || ''}
                                        onChange={handleSearch}
                                    >
                                        <option value="">Всички</option>
                                        <option value="paid">Изцяло платено</option>
                                        <option value="part">Частично платено</option>
                                        <option value="not-paid">Без плащане</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FloatingLabel label="Изтрити">
                                    <Form.Select
                                        name="deleted"
                                        value={props.filter.deleted || ''}
                                        onChange={handleSearch}
                                    >
                                        <option value="">По подразбиране</option>
                                        <option value="with-deleted">Покажи изтрити</option>
                                        <option value="only-deleted">Покажи само изтрити</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    )
}

export default Filter;
