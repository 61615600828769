import React, { useState, useEffect } from 'react'
import { FloatingLabel, Form, Dropdown, Row, Col, Button, ButtonGroup } from 'react-bootstrap'

import Api from 'helpers/Api'
import Loader from 'components/misc/Loader'
import NoDataFound from 'components/misc/NoDataFound'
import { diff, unique } from 'helpers/Arrays'
import { useAuthDataContext } from 'providers/Auth'
import { CheckAll, X } from 'react-bootstrap-icons'

function StoreAndCashdesk(props) {

    const auth = useAuthDataContext();

    const cashdeskInputName = props.cashdeskInputName || '';
    const position = props.position || 'start';

    const [state, setState] = useState({
        data: [],
        show: false,
        selected: [],
        active: null,
        loading: true,
    });

    useEffect(() => {
        Api.get('autocomplete/cashdesks-by-store', {
            params: {
                permission: props.permission || ''
            }
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: Array.isArray(res.data.items) ? res.data.items : [],
                active: res.data.items[0] ? res.data.items[0].id : null,
                loading: false,
            }));
        });
    }, []);

    useEffect(() => {
        if (Array.isArray(props.selected)) {
            setState(prev => ({
                ...prev,
                selected: props.selected.map(s => Number(s))
            }));
        }
    }, [props.selected]);

    const toggleDropdown = () => {
        setState(prev => ({
            ...prev,
            show: !prev.show,
        }))
    }

    const getFilterValue = () => {
        if (state.selected.length === 0) {
            return 'Няма избрани';
        }

        if (state.selected.length > 3) {
            return state.selected.length + ' избрани';
        }

        let stores = state.data;

        let data = [];

        stores.map(store => {
            let cashdesks = store.cashdesks;

            cashdesks.map(cashdesk => {
                if (isCashdeskSelected(cashdesk)) {
                    data.push(cashdesk.name);
                }
            });
        });

        return data.join(', ');
    }

    const toggleStore = store => {
        setState(prev => ({
            ...prev,
            active: store.id
        }));
    }

    const isStoreActive = store => {
        return Number(state.active) === Number(store.id);
    }

    const selectStore = store => {
        let selected = [...state.selected];
        let cashdesks = store.cashdesks.map(cashdesk => Number(cashdesk.id));

        if (isStoreSelected(store)) {
            selected = diff(selected, cashdesks);
        } else {
            selected = unique(selected.concat(cashdesks));
        }

        setState(prev => ({
            ...prev,
            selected: selected
        }))

        if (typeof props.onChange === 'function') {
            props.onChange(selected);
        }
    }

    const isStoreSelected = store => {

        let selected = 0;

        store.cashdesks.map(cashdesk => {
            if (isCashdeskSelected(cashdesk)) {
                selected++;
            }
        });

        if (selected === store.cashdesks.length) {
            return true;
        }

        return false;
    }

    const hasStoreSelectedCashdesk = store => {

        let selected = 0;

        store.cashdesks.map(cashdesk => {
            if (isCashdeskSelected(cashdesk)) {
                selected++;
            }
        });

        if (selected > 0) {
            return true;
        }

        return false;
    }

    const selectCashdesk = cashdesk => {
        let selected = [...state.selected];

        if (isCashdeskSelected(cashdesk)) {
            selected = selected.filter(id => Number(id) !== Number(cashdesk.id));
        } else {
            selected = selected.concat(cashdesk.id);
        }

        setState(prev => ({
            ...prev,
            selected: selected
        }))

        if (typeof props.onChange === 'function') {
            props.onChange(selected);
        }
    }

    const isCashdeskSelected = cashdesk => {
        return state.selected.findIndex(id => Number(id) === Number(cashdesk.id)) > -1;
    }

    const selectAll = () => {
        let selected = [];
        let stores = [...state.data];

        stores.map(store => {
            let cashdesks = store.cashdesks.map(cashdesk => Number(cashdesk.id));

            selected = selected.concat(cashdesks);
        });

        setState(prev => ({
            ...prev,
            selected: selected
        }))

        if (typeof props.onChange === 'function') {
            props.onChange(selected);
        }
    }

    const removeAll = () => {
        let selected = [];

        setState(prev => ({
            ...prev,
            selected: selected
        }))

        if (typeof props.onChange === 'function') {
            props.onChange(selected);
        }
    }

    return (
        <Dropdown drop="down" onToggle={toggleDropdown} show={state.show}>
            <FloatingLabel label="Каса">
                <Form.Control
                    type="text"
                    placeholder="Каса"
                    value={getFilterValue()}
                    onClick={toggleDropdown}
                    readOnly
                    style={{ cursor: 'pointer' }}
                />
            </FloatingLabel>
            <Dropdown.Menu align="start" className={position} style={{ padding: '15px', width: '300px' }}>
                {state.loading
                    ?
                    <Loader />
                    :
                    state.data.length === 0
                        ?
                        <NoDataFound size="sm">
                            Няма каси за показване!
                        </NoDataFound>
                        :
                        <>
                            <div className="depots-filter">

                                {/* <div className="header">
                                    <div>
                                        Магазин
                                    </div>
                                    <div>
                                        Каси
                                    </div>
                                </div> */}
                                <div className="body">
                                    <div className="stores">
                                        {state.data.map((store, i) =>
                                            <React.Fragment key={store.id}>
                                                <div className={`store ${isStoreActive(store) ? 'active' : ''}`} onClick={e => toggleStore(store)}>
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            onChange={e => selectStore(store)}
                                                            checked={isStoreSelected(store)}
                                                        />
                                                        <span className={hasStoreSelectedCashdesk(store) ? 'has-selected-depot' : ''} onClick={e => e.preventDefault()}>
                                                            {store?.translation?.name || store?.name}
                                                        </span>
                                                    </label>
                                                </div>
                                            </React.Fragment>
                                        )}
                                    </div>

                                    {state.data.map((store, i) =>
                                        <div key={'cashdesks-' + i} className={`depots ${isStoreActive(store) ? 'active' : ''}`}>
                                            {store.cashdesks.map(cashdesk =>
                                                <div key={cashdesk.id} className="depot">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            onChange={e => selectCashdesk(cashdesk)}
                                                            checked={isCashdeskSelected(cashdesk)}
                                                            name={cashdeskInputName}
                                                            value={cashdesk.id}
                                                        />
                                                        <span>{cashdesk?.translation?.name || cashdesk?.name}</span>
                                                    </label>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>

                                <div className="all-options">
                                    <ButtonGroup style={{ width: '100%' }}>
                                        <Button variant="primary" size="sm" onClick={selectAll}>
                                            <CheckAll /> Всички
                                        </Button>
                                        <Button variant="secondary" size="sm" onClick={removeAll}>
                                            <X /> Изчисти
                                        </Button>
                                    </ButtonGroup>
                                </div>
                            </div>
                        </>
                }
            </Dropdown.Menu>
        </Dropdown >
    )
}

export default StoreAndCashdesk;