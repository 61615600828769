import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import { Modal, Button, Row, Col, Dropdown, FloatingLabel, Form } from 'react-bootstrap';
import { CaretLeft, CaretDown, CaretRight, CaretDownFill } from 'react-bootstrap-icons'

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//misc
import SaveButton from 'components/misc/Button'
import Info from 'components/modals/Info';
import HelpInfo from 'components/misc/Info'
import Loader from 'components/misc/Loader'
import NoDataFound from 'components/misc/NoDataFound'

function AddOrEdit(props, ref) {

    const defaultData = {}
    const defaultCategoryData = {
        data: [],
        show: false,
        toggled: [],
        selected: {},
        loading: true,
    }
    const [state, setState] = useNestedState({
        show: false,
        id: null,
        edit: false,
        data: defaultData,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null
    });

    const [salons, setSalons] = useState([]);

    const [categories, setCategories] = useState(defaultCategoryData);

    const [validations, setValidations] = useValidation();

    const validationModalRef = useRef(null);

    useImperativeHandle(ref, () => ({
        add: () => {
            handleShow();
        },
        edit: (id) => {
            edit(id);
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        }
    }));

    useEffect(() => {
        loadData();
    }, [state.edit]);

    useEffect(() => {
        Api.get('cashdesks/salons')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setSalons(res.data);
                }
            });

        Api.get('cashdesks/categories')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setCategories(prev => ({
                        ...prev,
                        data: res.data,
                        loading: false,
                        show: false,
                        selected: {}
                    }));
                }
            });
    }, []);

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const handleShow = () => {
        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
        }));

        setCategories(prev => ({
            ...prev,
            show: false,
            selected: {},
            toggled: []
        }))
        show();
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
        }));

        setCategories(prev => ({
            ...prev,
            show: false,
            selected: {},
            toggled: []
        }))

        setValidations(null);

        hide();
    }

    const edit = id => {
        setState(prev => ({
            ...prev,
            id: id,
            edit: new Date().getTime()
        }));

        show();
    }

    const loadData = () => {
        if (!state.id) {
            return;
        }

        Api.get('cashdesks/show?id=' + state.id)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data
                }))
                let toggled = []
                if (res.data.category) toggled = categoryExpandInitial(res.data.category, []) || []
                setCategories(prev => ({
                    ...prev,
                    selected: res.data.category,
                    toggled: toggled
                }));
            });
    }


    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = state.id ? 'cashdesks/update' : 'cashdesks/add';

        let data = new FormData(e.target);

        if (state.id) {
            data.append('id', state.id)
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (typeof state.onSuccess === 'function') {
                state.onSuccess(res.data)
            }

            hide()
        }).catch(error => {
            const _err = error.response;
            if (_err && _err.status && _err.status === 422) {
                hide(() => {
                    let modal = validationModalRef.current;

                    if (modal) {
                        modal.open();

                        modal.onClose(() => {
                            setValidations(_err.data.errors)
                            show();
                        });
                    }
                });
            }

        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }));
        });
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleCheckboxChange = e => {
        const name = e.target.name;
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: prev.data[name] ? false : true
            }
        }));
    }

    const renderCategories = (categories, level = 0) => {
        return categories.map(category =>
            <React.Fragment key={category.id}>
                <div style={{ display: level === 0 || isCategoryExpanded(category.parent_id) ? 'flex' : 'none', alignItems: 'center', paddingLeft: (level * 20) + 'px', }}>
                    {category.children.length > 0 &&
                        <span
                            style={{ marginRight: '3px', marginBottom: '3px', cursor: 'pointer' }}
                            onClick={e => expandCategory(category.id)}
                        >
                            {isCategoryExpanded(category.id) ? <CaretDownFill size="13" /> : <CaretRight size="13" />}
                        </span>
                    }
                    <Form.Check
                        type="checkbox"
                        id={'cbc-' + category.id}
                        name="cat_id"
                        value={category.id}
                        label={category?.translation?.name || category.name}
                        checked={isCategorySelected(category)}
                        onChange={e => toggleCategory(category)}
                    />
                </div>
                {isCategoryExpanded(category.id) && renderCategories(category.children, level + 1)}
            </React.Fragment>
        );
    }

    const expandCategory = id => {
        if (isCategoryExpanded(id)) {
            setCategories(prev => ({
                ...prev,
                toggled: prev.toggled.filter(i => Number(i) !== Number(id))
            }));
        } else {
            setCategories(prev => ({
                ...prev,
                toggled: prev.toggled.concat(Number(id)),
            }));
        }
    }

    const isCategoryExpanded = id => {
        return categories.toggled.indexOf(Number(id)) > -1;
    }

    const isCategorySelected = (category) => {

        if (!category) {
            return;
        }

        let selected = categories.selected?.id == category.id;

        if (selected) {
            return true;
        }

        return false;
    }

    const toggleCategory = category => {

        let selected = {};
        if (!isCategorySelected(category)) {
            selected = category
        }

        setCategories(prev => ({
            ...prev,
            selected: selected
        }));
    }

    const toggleCategories = () => {
        setCategories(prev => ({
            ...prev,
            show: !prev.show,
        }))
    }

    const categoryExpandInitial = (category, toggle = []) => {
        if (category.parent) {
            toggle = toggle.concat(Number(category.parent_id));
            return categoryExpandInitial(category.parent, toggle)
        } else {
            toggle = toggle.concat(Number(category.parent_id));
        }

        return toggle;
    }

    return (
        <>
            <Info
                ref={validationModalRef}
                mainMessage="Моля, попълнете всички задължителни полета, означени с червен цвят!"
            />

            <Modal size="lg" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <form onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Каса
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className='mb-3'>
                            <Col>
                                <FloatingLabel label="Име">
                                    <Form.Control
                                        type="text"
                                        placeholder="Име*"
                                        name="name"
                                        value={state.data.name || ''}
                                        onChange={handleInputChange}
                                        isInvalid={Boolean(validations && validations.name)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {validations && validations.name && (validations.name[0] || validations.name)}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col>
                                <FloatingLabel label="MAC адрес">
                                    <Form.Control
                                        type="text"
                                        placeholder="Mac адрес"
                                        name="mac"
                                        value={state.data.mac || ''}
                                        onChange={handleInputChange}
                                        isInvalid={Boolean(validations && validations.mac)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {validations && validations.mac && (validations.mac[0] || validations.mac)}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col>
                                <FloatingLabel label="IP адрес">
                                    <Form.Control
                                        type="text"
                                        placeholder="IP адрес"
                                        name="ip_suffix"
                                        value={state.data.ip_suffix || ''}
                                        onChange={handleInputChange}
                                        isInvalid={Boolean(validations && validations.ip_suffix)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {validations && validations.ip_suffix && (validations.ip_suffix[0] || validations.ip_suffix)}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col>
                                <FloatingLabel label="Салон">
                                    <Form.Select
                                        name="salon_id"
                                        value={state.data.salon_id || ''}
                                        onChange={handleInputChange}
                                        isInvalid={Boolean(validations && validations.salon_id)}
                                    >
                                        {salons.map(c =>
                                            <option value={c.id} key={c.id}>{c.name}</option>
                                        )}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {validations && validations.salon_id && (validations.salon_id[0] || validations.salon_id)}
                                    </Form.Control.Feedback>
                                    <HelpInfo placement="top">В кой салон е касата</HelpInfo>
                                </FloatingLabel>
                            </Col>

                            <Col>
                                <FloatingLabel label="Режим">
                                    <Form.Select
                                        name="mode"
                                        value={state.data.mode || ''}
                                        onChange={handleInputChange}
                                        isInvalid={Boolean(validations && validations.mode)}
                                    >
                                        <option value="1">Заведение</option>
                                        <option value="2">Магазин</option>
                                        <option value="3">Магазин разширен</option>
                                        <option value="4">Обслужваща каса</option>
                                        <option value="5">Самообслужване</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {validations && validations.mode && (validations.mode[0] || validations.mode)}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>

                        </Row>

                        <Col className="mb-3">
                            <FloatingLabel label="Брой клиенти в сметка*">
                                <Form.Select
                                    name="force_account_persons"
                                    value={state.data.force_account_persons || ''}
                                    onChange={handleInputChange}
                                    isInvalid={Boolean(validations && validations.force_account_persons)}
                                >
                                    <option value="1">Незадължително</option>
                                    <option value="2">Задължително при откриване на сметка</option>
                                    <option value="3">Задължително при закриване на сметка</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {validations && validations.force_account_persons && (validations.force_account_persons[0] || validations.force_account_persons)}
                                </Form.Control.Feedback>
                                <HelpInfo placement="top">Опция дали системата да пита за брой обслужвани клиенти в сметка.</HelpInfo>
                            </FloatingLabel>
                        </Col>

                        <Row className="mb-3">
                            <Col>
                                <FloatingLabel label="Междинна бележка*">
                                    <Form.Select
                                        name="intermediate_recipe"
                                        value={state.data.intermediate_recipe || ''}
                                        onChange={handleInputChange}
                                        isInvalid={Boolean(validations && validations.intermediate_recipe)}
                                    >
                                        <option value="1">Не се печата</option>
                                        <option value="2">Служебна без цени</option>
                                        <option value="3">Служебна с цени</option>
                                        <option value="4">Междинна с натрупана сума</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {validations && validations.intermediate_recipe && (validations.intermediate_recipe[0] || validations.intermediate_recipe)}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>

                            <Col>
                                <FloatingLabel label="Обслужващи бележки*">
                                    <Form.Select
                                        name="print_but_recipe"
                                        value={state.data.print_but_recipe || ''}
                                        onChange={handleInputChange}
                                        isInvalid={Boolean(validations && validations.print_but_recipe)}
                                    >
                                        <option value="1">Служебна без цени</option>
                                        <option value="2">Служебна с цени</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {validations && validations.print_but_recipe && (validations.print_but_recipe[0] || validations.print_but_recipe)}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col xs="auto">
                                <Form.Check
                                    type="switch"
                                    name="show_cats"
                                    label="Показване на категории"
                                    value={1}
                                    checked={Number(state.data?.show_cats) === 1}
                                    onChange={handleCheckboxChange}
                                />
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col>
                                <Dropdown drop="down" onToggle={toggleCategories} show={categories.show}>
                                    <FloatingLabel label="Начална категория">
                                        <Form.Control
                                            type="text"
                                            placeholder="Начална категория"
                                            value={categories.selected?.translation?.name || 'Няма избрана'}
                                            onClick={toggleCategories}
                                            readOnly
                                        />
                                    </FloatingLabel>
                                    <Dropdown.Menu align="start" style={{ padding: '15px', minWidth: '350px' }}>
                                        {categories.loading
                                            ?
                                            <Loader />
                                            :
                                            categories.data.length === 0
                                                ?
                                                <NoDataFound size="sm">
                                                    Няма категории за показване!
                                                </NoDataFound>
                                                :
                                                renderCategories(categories.data)
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>

                            <Col>
                                <FloatingLabel label="Интерфейс*">
                                    <Form.Select
                                        name="model"
                                        value={state.data.model || ''}
                                        onChange={handleInputChange}
                                        isInvalid={Boolean(validations && validations.model)}
                                    >
                                        <option value="1">POS Терминал</option>
                                        <option value="2">POS Терминал олекотен</option>
                                        <option value="3">Администрация</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {validations && validations.model && (validations.model[0] || validations.model)}
                                    </Form.Control.Feedback>
                                    <HelpInfo placement="top">Стартиране на интерфейс по подразбиране.</HelpInfo>
                                </FloatingLabel>
                            </Col>
                        </Row>

                        <Row>
                            <Col style={{ display: 'flex' }} xs="auto">
                                <Form.Check
                                    type="switch"
                                    name="generates_unp"
                                    label={`Без потвърждение`}
                                    value={1}
                                    checked={Number(state.data?.generates_unp) === 1}
                                    onChange={handleCheckboxChange}
                                />
                                <HelpInfo marginLeft="10px" placement="top">Отбележете, ако искате клиентските заявки направени от тази каса да не изискват потвърждение преди генериране на УНП.</HelpInfo>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            className="save"
                        />
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default forwardRef(AddOrEdit);
