import Api from "helpers/Api";
import { Button, Col, Row } from "react-bootstrap";

function CatalogueInput(props) {

    const { state, setState, validations, setValidations } = props;

    const handleShowAttach = e => {
        e.preventDefault();

        // inputFileRef.current.click();

        e.currentTarget.parentNode.querySelector('input').click();
    }

    const handleAttach = (e) => {
        let file = e.target.files[0];
        let url = URL.createObjectURL(file);

        file.id = Math.random().toString(7).substring(2);
        file.was_recently_attached = true;
        file.url = url;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                catalogue: file
            }
        }));
    }

    const handleDetach = (e) => {
        let file = state.data.catalogue;

        if (!file.was_recently_attached) {
            Api.post('articles/delete-catalogue', {
                id: state.data.id
            }).then(res => {
                if (res.data.success) {
                    removeFile();
                }
            });
        } else {
            removeFile();
        }

        if (validations && validations.catalogue) {
            delete validations.catalogue;
        }

        setValidations(validations);
    }

    const removeFile = () => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                catalogue: null
            }
        }));
    }

    return (
        <Row className="mb-3">
            <Col>
                {state.data.catalogue &&
                    <div className="mb-3">
                        <>
                            <div className="file">
                                <a href={state.data.catalogue?.url} target="_blank">
                                    {state.data.catalogue?.name}
                                </a>
                            </div>

                            <div className="validate">
                                {validations?.catalogue}
                            </div>
                        </>
                    </div>
                }

                <Button variant="outline-dark" size="sm" onClick={handleShowAttach}>
                    Прикачете каталог
                </Button>

                {state.data.catalogue &&
                    <Button variant="outline-danger" size="sm" onClick={e => handleDetach(e)} style={{ marginLeft: '5px' }}>
                        Премахнете каталога
                    </Button>
                }

                <input type="file" accept=".pdf" onChange={e => handleAttach(e)} hidden />
            </Col>
        </Row>
    )
}

export default CatalogueInput;