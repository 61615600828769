import { forwardRef, useEffect, useImperativeHandle, useRef, useState, Fragment } from 'react'

//plugins
import moment from 'moment';
import { Modal, Button, Tabs, Tab, FloatingLabel, Form, Nav, Row, Col, Alert, Card } from 'react-bootstrap';

//icons
import { TrashFill, HouseDoorFill, Trash } from 'react-bootstrap-icons';

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//providers
import { useAuthDataContext } from 'providers/Auth';

//misc
import SaveButton from 'components/misc/Button'
import Info from 'components/modals/Info';
import HelpInfo from 'components/misc/Info';
import Autocomplete from 'components/misc/Autocomplete';
import EditArticle from 'components/pages/articles/partials/AddOrEdit';
import './style.scss';
import Refs from 'Refs';


function AddOrEdit(props, ref) {

    const auth = useAuthDataContext();

    const defaultData = {
        valid_to_type: 1,
        reusable: 0,
        groups: [],
        categories: []
    }

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        edit: false,
        data: defaultData,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null,
        mainTabId: 'main',
    });

    const [categories, setCategories] = useState([]);
    const [taxGroups, setTaxGroups] = useState([]);
    const [validations, setValidations] = useValidation();

    const validationModalRef = useRef(null);

    useImperativeHandle(ref, () => ({
        add: () => {
            handleShow();
        },
        edit: (id) => {
            edit(id);
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        }
    }));

    useEffect(() => {
        Api.get('cards/types/categories')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setCategories(res.data);
                }
            });
        Api.get('cards/types/tax-groups')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setTaxGroups(res.data);
                }
            });
    }, [])

    useEffect(() => {
        loadData();
    }, [state.edit]);

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const handleShow = () => {
        setState(prev => ({
            ...prev,
            mainTabId: 'main',
            data: defaultData,
            id: null,
        }));

        show();
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            initialData: defaultData,
            id: null,
        }));

        setValidations(null);

        hide();
    }

    const edit = id => {
        setState(prev => ({
            ...prev,
            id: id,
            edit: new Date().getTime()
        }));

        show();
    }

    const loadData = () => {
        if (!state.id) {
            return;
        }

        Api.get('cards/types/show?id=' + state.id)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data,
                    mainTabId: 'main',
                }))
            });
    }

    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = state.id ? 'cards/types/update' : 'cards/types/add';

        let data = new FormData(e.target);

        if (state.id) {
            data.append('id', state.id)
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (typeof state.onSuccess === 'function') {
                state.onSuccess(res.data)
            }

            hide()
        }).catch(error => {
            const _err = error.response;
            if (_err && _err.status && _err.status === 422) {
                hide(() => {
                    let modal = validationModalRef.current;

                    if (modal) {
                        modal.open();

                        modal.onClose(() => {
                            setValidations(_err.data.errors)
                            show();
                        });
                    }
                });
            }
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }));
        });
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value
        setState(name, value, 'data')
    }

    const handleTaxChange = e => {
        const value = e.target.value

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                sale_article: {
                    ...prev.data.sale_article,
                    ['tax_id']: value
                }
            }
        }));
    }

    const handlePaymentTaxChange = e => {
        const value = e.target.value

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                payment_article: {
                    ...prev.data.payment_article,
                    ['tax_id']: value
                }
            }
        }));
    }

    const handleSaleBarcodeChange = e => {
        const value = e.target.value

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                sale_article: {
                    ...prev.data.sale_article,
                    ['barcode']: value
                }
            }
        }));
    }

    const handleCheckboxChange = e => {
        const name = e.target.name;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: prev.data[name] ? false : true
            }
        }));
    }

    const setMainTab = key => {
        setState(prev => ({
            ...prev,
            mainTabId: key
        }));
    }

    const hasTabPermission = key => {
        let map = {
            categories: 'cards_categories',
            article_groups: 'cards_articlegroups',
        };

        let permission = map[key];

        if (permission && auth.getUser().permission(permission) === false) {
            return false;
        }

        return true;
    }

    const handleDiscountChange = e => {
        const name = e.target.name
        let value = e.target.value

        if (!value.includes('+') && !value.includes('-') && value.length > 0) {
            value = '-' + value;
        }

        if (value.includes('+')) {
            value = value.replace('-', '')
        }

        setState(name, value, 'data')
    }

    const renderCategories = (categories, level = 0) => {
        return categories.map(category =>
            <Fragment key={category.id}>
                <Row>
                    <Col style={{ paddingLeft: (level * 20) + 'px', display: 'flex', alignItems: 'center' }}>
                        <Form.Check
                            type="checkbox"
                            id={'acbc-' + category.id}
                            name="categories[]"
                            value={category.id}
                            label={category?.translation?.name || category.name}
                            checked={isCategorySelected(category.id)}
                            onChange={e => toggleCategory(category)}
                        />
                    </Col>
                </Row>
                {renderCategories(category.children, level + 1)}
            </Fragment>
        );
    }

    const toggleCategory = category => {
        if (isCategorySelected(category.id)) {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    categories: prev.data.categories.filter(c => Number(c.id) !== Number(category.id)),
                    master_cat_id: isCategoryMaster(category.id) ? null : prev.data.master_cat_id,
                }
            }));
        } else {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    categories: prev.data.categories.concat(category),
                    master_cat_id: prev.data.categories.length === 0 ? category.id : prev.data.master_cat_id,
                }
            }));
        }
    }

    const isCategorySelected = id => {
        return state.data.categories.find(c => Number(c.id) === Number(id));
    }

    const isCategoryMaster = id => {
        return Number(state.data.master_cat_id) === Number(id);
    }

    const addDetail = () => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                groups: prev.data.groups.concat({})
            }
        }));
    }

    const removeDetail = (index) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                groups: prev.data.groups.filter((d, i) => Number(i) !== Number(index))
            }
        }));
    }

    const handleArticleAdd = (data, index) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                groups: Object.values({
                    ...prev.data.groups,
                    [index]: data
                })
            }
        }));
    }

    const handleGroupLimitChange = (e, index) => {
        const value = e.target.value
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                groups: Object.values({
                    ...prev.data.groups,
                    [index]: {
                        ...prev.data.groups[index],
                        ['amount_limit']: value
                    }
                })
            }
        }));
    }

    const handleMainTabValidate = () => {
        let valid = false;
        let main = ['name', 'discount'];

        if (validations) {
            main.map(t => {
                if (validations[t] && state.mainTabId !== 'main') {
                    valid = true
                }
            })
        }

        return valid;
    }

    const handleOpenArticle = (id) => {
        hide(() => {
            let modal = Refs.getInstance().getRef('article');

            modal.open(id);

            modal.onSuccess(() => {
                show();
            });
            modal.onClose(() => {
                show();
            })
        })

    }

    return (
        <>
            <Info
                ref={validationModalRef}
                mainMessage="Моля, попълнете всички задължителни полета, означени с червен цвят!"
            />

            <Modal size="lg" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <form onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Вид карта
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Tab.Container activeKey={state.mainTabId} onSelect={key => setMainTab(key)}>
                            <Row>
                                <Col sm={3}>
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link style={{ color: handleMainTabValidate() ? 'red' : '' }} eventKey="main">Основни данни</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="limits">Лимити</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="categories" disabled={hasTabPermission('categories') === false}>Категоризация</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="article_groups" disabled={hasTabPermission('article_groups') === false}>Групи артикули</Nav.Link>
                                        </Nav.Item>
                                        {state.id ? <Nav.Item>
                                            <Nav.Link eventKey="sale_payment">Продажба / Плащане</Nav.Link>
                                        </Nav.Item> : ''}
                                    </Nav>
                                </Col>
                                <Col sm={9}>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="main">

                                            <FloatingLabel className="mb-3" label="Име*">
                                                <Form.Control
                                                    type="text"
                                                    name={`name`}
                                                    placeholder="Име*"
                                                    onChange={(e) => handleInputChange(e)}
                                                    value={state.data?.name || ''}
                                                    isInvalid={Boolean(validations && validations.name)}
                                                />

                                                <Form.Control.Feedback type="invalid">
                                                    {validations && validations.name}
                                                </Form.Control.Feedback>
                                            </FloatingLabel>

                                            <Col>
                                                <Form.Check
                                                    type="switch"
                                                    name="sell_in_store"
                                                    label="В продажба"
                                                    className="mb-3 mt-3"
                                                    checked={Number(state.data?.sell_in_store) === 1}
                                                    onChange={handleCheckboxChange}
                                                />
                                            </Col>

                                            <Row>
                                                <Col>
                                                    <FloatingLabel className="mb-3" label="Цена">
                                                        <Form.Control
                                                            type="text"
                                                            name={`price`}
                                                            placeholder="Цена"
                                                            onChange={(e) => handleInputChange(e)}
                                                            value={state.data?.price || ''}
                                                            isInvalid={Boolean(validations && validations.price)}
                                                        />

                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.price}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                                <Col className="mb-3">
                                                    <FloatingLabel label="Важи от">
                                                        <Form.Select
                                                            name="valid_from_type"
                                                            value={state.data.valid_from_type || ''}
                                                            onChange={handleInputChange}
                                                        >
                                                            <option key="1" value="1">Момент на продажбата</option>
                                                            <option key="2" value="2">Начало на деня</option>
                                                            <option key="3" value="3">Начало на седмицата</option>
                                                            <option key="4" value="4">Начало на месеца</option>
                                                            <option key="5" value="5">Начало на годината</option>
                                                        </Form.Select>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col>
                                                    <FloatingLabel className="mb-3" label="Важи до">
                                                        <Form.Control
                                                            type="text"
                                                            name={`valid_to_num`}
                                                            placeholder="Важи до"
                                                            disabled={state.data.valid_to_type == 1 ? true : false}
                                                            onChange={(e) => handleInputChange(e)}
                                                            value={state.data.valid_to_type == 1 ? '' : state.data?.valid_to_num || 1}
                                                            isInvalid={Boolean(validations && validations.valid_to_num)}
                                                        />

                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.valid_to_num}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                                <Col className="mb-3">
                                                    <FloatingLabel label="Важи до (тип)">
                                                        <Form.Select
                                                            name="valid_to_type"
                                                            value={state.data.valid_to_type || ''}
                                                            onChange={handleInputChange}
                                                        >
                                                            <option key="1" value="1">Без ограничение</option>
                                                            <option key="2" value="2">Дни</option>
                                                            <option key="3" value="3">Седмици</option>
                                                            <option key="4" value="4">Месеца</option>
                                                            <option key="5" value="5">Години</option>
                                                        </Form.Select>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col>
                                                    <FloatingLabel className="mb-3" label="Отстъпка %">
                                                        <Form.Control
                                                            type="text"
                                                            name={`discount`}
                                                            placeholder="Отстъпка %"
                                                            onChange={(e) => handleDiscountChange(e)}
                                                            value={state.data?.discount || ''}
                                                            isInvalid={Boolean(validations && validations.discount)}
                                                        />

                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.discount}
                                                        </Form.Control.Feedback>
                                                        <HelpInfo placement="top">каква част от общата сума на артикула/сметката може да бъде покрита от картата минус - отстъпка в проценти плюс - не е допустима стойност</HelpInfo>
                                                    </FloatingLabel>
                                                </Col>

                                                <Col>
                                                    <FloatingLabel className="mb-3" label="Кредит сума">
                                                        <Form.Control
                                                            type="text"
                                                            name={`credit`}
                                                            placeholder="Кредит сума"
                                                            onChange={(e) => handleInputChange(e)}
                                                            value={state.data?.credit || ''}
                                                            isInvalid={Boolean(validations && validations.credit)}
                                                        />

                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.credit}
                                                        </Form.Control.Feedback>
                                                        <HelpInfo placement="top">Положителната стойност - сума която може да бъде изразходвана от създадената карта празно - без ограничение Отрицателна стойност - коефициент с който да бъде заредена картата, спрямо цената и количеството при поръка Пример: карта с цена 3 лева, количество на поръчката 5 и коефициент -1.2 ще създаде карта с кредит за използване 3 * 5 * -1.2 * -1 = 18 лв</HelpInfo>
                                                    </FloatingLabel>
                                                </Col>

                                                <Col className="mb-3">
                                                    <FloatingLabel label="Важи за">
                                                        <Form.Select
                                                            name="articles_price_type"
                                                            value={state.data.articles_price_type || ''}
                                                            onChange={handleInputChange}
                                                        >
                                                            <option key="1" value="1">Всички артикули</option>
                                                            <option key="2" value="2">Артикули без намаление</option>
                                                            <option key="3" value="3">Артикули с намаление</option>
                                                        </Form.Select>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col xs="auto">
                                                    <Form.Check
                                                        type="switch"
                                                        name="client_check"
                                                        label="Проверка на клиент"
                                                        className="mb-3 mt-3"
                                                        checked={Number(state.data?.client_check) === 1}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                </Col>
                                                <Col xs="auto">
                                                    <Form.Check
                                                        type="switch"
                                                        name="auto_num"
                                                        label="Автоматично генериране на код"
                                                        className="mb-3 mt-3"
                                                        checked={Number(state.data?.auto_num) === 1}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                </Col>
                                            </Row>

                                            <Col>
                                                <FloatingLabel label="Закръгляне">
                                                    <Form.Select
                                                        name="price_round_type"
                                                        value={state.data.price_round_type || ''}
                                                        onChange={handleInputChange}
                                                        className="mb-3 mt-3"
                                                    >
                                                        <option value="">Няма</option>
                                                        <option value="1">До цял лев</option>
                                                        <option value="2">Надолу до цял лев</option>
                                                        <option value="3">Нагоре до цял лев</option>
                                                    </Form.Select>
                                                </FloatingLabel>
                                            </Col>

                                            <Col>
                                                <Form.Check
                                                    type="switch"
                                                    name="reusable"
                                                    label="Може да се препродава"
                                                    className="mb-3 mt-3"
                                                    checked={Number(state.data?.reusable) === 1}
                                                    onChange={handleCheckboxChange}
                                                />
                                            </Col>

                                            {Number(state.data?.reusable) === 1 ?
                                                <>
                                                    <Col>
                                                        <Form.Check
                                                            type="switch"
                                                            name="reuse_add_credit"
                                                            label="Добави сумата по кредита при препродаване"
                                                            className="mb-3 mt-3"
                                                            checked={Number(state.data?.reuse_add_credit) === 1}
                                                            onChange={handleCheckboxChange}
                                                        />
                                                    </Col>

                                                    <Col>
                                                        <Form.Check
                                                            type="switch"
                                                            name="reuse_add_used"
                                                            label="Добави броя използвания при препродаване"
                                                            className="mb-3 mt-3"
                                                            checked={Number(state.data?.reuse_add_used) === 1}
                                                            onChange={handleCheckboxChange}
                                                        />
                                                    </Col>
                                                </>
                                                : ''}

                                            <FloatingLabel className="mb-3" label="Описание">
                                                <Form.Control
                                                    as="textarea"
                                                    name={`description`}
                                                    placeholder="Описание"
                                                    onChange={(e) => handleInputChange(e)}
                                                    value={state.data?.description || ''}
                                                    isInvalid={Boolean(validations && validations.description)}
                                                    style={{
                                                        height: '150px'
                                                    }}
                                                />

                                                <Form.Control.Feedback type="invalid">
                                                    {validations && validations.description}
                                                </Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="limits">
                                            <FloatingLabel className="mb-3" label="Брой използвания - общо">
                                                <Form.Control
                                                    type="text"
                                                    name={`use_limit`}
                                                    placeholder="Брой използвания - общо"
                                                    onChange={(e) => handleInputChange(e)}
                                                    value={state.data?.use_limit || ''}
                                                    isInvalid={Boolean(validations && validations.use_limit)}
                                                />

                                                <Form.Control.Feedback type="invalid">
                                                    {validations && validations.use_limit}
                                                </Form.Control.Feedback>
                                                <HelpInfo>Празно - без ограничение</HelpInfo>
                                            </FloatingLabel>

                                            {/* <Row>
                                                <Col>
                                                    <FloatingLabel className="mb-3" label="На всеки">
                                                        <Form.Control
                                                            type="text"
                                                            name={`period_limit_interval`}
                                                            placeholder="На всеки"
                                                            onChange={(e) => handleInputChange(e)}
                                                            value={state.data?.period_limit_interval || ''}
                                                            isInvalid={Boolean(validations && validations.period_limit_interval)}
                                                        />

                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.period_limit_interval}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>

                                                <Col className="mb-3">
                                                    <FloatingLabel label="Тип интервал">
                                                        <Form.Select
                                                            name="period_limit_type"
                                                            value={state.data.period_limit_type || ''}
                                                            onChange={handleInputChange}
                                                        >
                                                            <option key="1" value="1">Минути</option>
                                                            <option key="2" value="2">Часа</option>
                                                            <option key="3" value="3">Дена</option>
                                                        </Form.Select>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col>
                                                    <FloatingLabel className="mb-3" label="Лимит от бр използвания">
                                                        <Form.Control
                                                            type="text"
                                                            name={`period_limit_num`}
                                                            placeholder="Лимит от бр използвания"
                                                            onChange={(e) => handleInputChange(e)}
                                                            value={state.data?.period_limit_num || ''}
                                                            isInvalid={Boolean(validations && validations.period_limit_num)}
                                                        />

                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.period_limit_interval}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>

                                                <Col>
                                                    <FloatingLabel className="mb-3" label="Начало от">
                                                        <Form.Control
                                                            type="date"
                                                            name={`period_limit_start`}
                                                            onChange={(e) => handleInputChange(e)}
                                                            value={state.data?.period_limit_start ? moment(state.data.period_limit_start).format('YYYY-MM-DD') : ''}
                                                            isInvalid={Boolean(validations && validations.period_limit_start)}
                                                        />

                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.period_limit_start}
                                                        </Form.Control.Feedback>
                                                        <HelpInfo>Фиксиран начален час за периодите. Ако не бъде зададен, периодът е динамичен и времето се определя от момента на използването на картата.</HelpInfo>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row> */}

                                        </Tab.Pane>

                                        <Tab.Pane eventKey="categories">
                                            <div className="categories">
                                                {renderCategories(categories)}
                                            </div>
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="article_groups">
                                            <Col className="mb-3">
                                                {state.data.groups.length === 0 &&
                                                    <Alert variant="primary">
                                                        Няма добавени групи артикули
                                                    </Alert>
                                                }
                                                {state.data.groups.map((group, index) =>
                                                    <Row key={'d-' + index}>
                                                        <Col className="mb-3">
                                                            <Autocomplete
                                                                url="autocomplete/articles-groups"
                                                                inputPlaceholder="Група с артикули"
                                                                inputIdName={`group_id[${index}]`}
                                                                inputValue={group.name || ''}
                                                                inputIdValue={group.id || ''}
                                                                error={Boolean(validations?.article_id)}
                                                                helperText={validations?.article_id && (validations?.article_id[0] || validations?.article_id)}
                                                                onChange={data => handleArticleAdd(data, index)}
                                                            />
                                                        </Col>

                                                        <Col>
                                                            <FloatingLabel className="mb-3" label="Лимит">
                                                                <Form.Control
                                                                    type="text"
                                                                    name={`amount_limit[${index}]`}
                                                                    placeholder="Лимит"
                                                                    onChange={(e) => handleGroupLimitChange(e, index)}
                                                                    value={group.amount_limit || ''}
                                                                />
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col className="mb-3" style={{ display: 'flex', alignItems: 'center' }} xs="auto">
                                                            <Button variant="danger" size="sm" onClick={e => removeDetail(index)}>
                                                                <TrashFill />
                                                            </Button>
                                                        </Col>

                                                        <Form.Control
                                                            type="hidden"
                                                            name={`cardtype_group_id[]`}
                                                            value={group.ct_id}
                                                        />
                                                    </Row>
                                                )}
                                                <Button variant="outline-dark" size="sm" onClick={addDetail}>
                                                    Добави нова стойност
                                                </Button>
                                            </Col>
                                        </Tab.Pane>

                                        {state.id ?
                                            <Tab.Pane eventKey="sale_payment">

                                                <Card className="mb-3">
                                                    <Card.Header>Артикул при продажба</Card.Header>
                                                    <Card.Body>
                                                        <Row >
                                                            <p>ID: <span className="article-link" onClick={() => handleOpenArticle(state.data?.sale_article?.id)}>{state.data?.sale_article?.id || ''}</span></p>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <FloatingLabel className="mb-3" label="Име">
                                                                    <Form.Control
                                                                        type="text"
                                                                        disabled={true}
                                                                        placeholder="Име"
                                                                        value={state.data?.sale_article?.article_name || ''}
                                                                    />
                                                                </FloatingLabel>
                                                            </Col>

                                                            <Col className="mb-3">
                                                                <FloatingLabel label="ДДС група">
                                                                    <Form.Select
                                                                        name="sale_tax_id"
                                                                        value={state.data?.sale_article?.tax_id || ''}
                                                                        onChange={handleTaxChange}
                                                                    >
                                                                        {taxGroups.map(t =>
                                                                            <option key={t.id} value={t.id}>{t.name}</option>
                                                                        )}
                                                                    </Form.Select>
                                                                </FloatingLabel>
                                                            </Col>

                                                            <Col>
                                                                <FloatingLabel className="mb-3" label="Баркод">
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="sale_barcode"
                                                                        placeholder="Баркод"
                                                                        onChange={handleSaleBarcodeChange}
                                                                        value={state.data?.sale_article?.barcode || ''}
                                                                    />
                                                                </FloatingLabel>
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>

                                                <Card>
                                                    <Card.Header>Артикул при плащане</Card.Header>
                                                    <Card.Body>
                                                        <Row >
                                                            <p>ID: <span className="article-link" onClick={() => handleOpenArticle(state.data?.payment_article?.id)}>{state.data?.payment_article?.id || ''}</span></p>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <FloatingLabel className="mb-3" label="Име">
                                                                    <Form.Control
                                                                        type="text"
                                                                        disabled={true}
                                                                        placeholder="Име"
                                                                        value={state.data?.payment_article?.article_name || ''}
                                                                    />
                                                                </FloatingLabel>
                                                            </Col>


                                                            <Col className="mb-3">
                                                                <FloatingLabel label="ДДС група">
                                                                    <Form.Select
                                                                        name="payment_tax_id"
                                                                        value={state.data?.payment_article?.tax_id || ''}
                                                                        onChange={handlePaymentTaxChange}
                                                                    >
                                                                        {taxGroups.map(t =>
                                                                            <option key={t.id} value={t.id}>{t.name}</option>
                                                                        )}
                                                                    </Form.Select>
                                                                </FloatingLabel>
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>

                                            </Tab.Pane> : ''}
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            className="save"
                        />
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default forwardRef(AddOrEdit);
