import { forwardRef, useEffect, useImperativeHandle, useRef, useState, Fragment } from 'react'

//plugins
import { Modal, Button, FloatingLabel, Form, Row, Col } from 'react-bootstrap';
import { TrashFill } from 'react-bootstrap-icons';

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//misc
import SaveButton from 'components/misc/Button';
import Alert from 'components/misc/Alert';

import Autocomplete from 'components/misc/Autocomplete';

function ArticleDetails(props, ref) {
    const formRef = useRef(null);
    const validationModalRef = useRef(null);

    const id = props.id

    const defaultData = {
        details: []
    }

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        edit: false,
        data: defaultData,
        counter: 0,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null,
        onClose: null,
        refresh: null
    });

    const [validations, setValidations] = useValidation();

    useImperativeHandle(ref, () => ({
        add: () => {
            handleShow();
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        }
    }));

    useEffect(() => {
        if (!state.show) {
            return;
        }
        Api.get('price_rules/articledetails/show?id=' + id)
            .then(res => {
                let data = res.data;

                setState(prev => ({
                    ...prev,
                    data: {
                        details: data
                    }
                }))
            });

    }, [state.show]);

    useEffect(() => {
        if (state.data.details.length === 0) {
            setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    details: [{}]
                }
            }));
        }
    }, [state.data.details]);

    useEffect(() => {
        let form = formRef.current;

        if (!form) {
            return;
        }

        let data = new FormData(form);

        Api.post('price_rules/articledetails/count', data)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    counter: res.data.count
                }))
            });
    }, [state.data.details]);

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const handleShow = () => {
        show();
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
        }));

        setValidations(null);

        if (typeof state.onClose === 'function') {
            hide(state.onClose);
        } else {
            hide();
        }
    }

    const handleSave = e => {
        e.preventDefault();

        if (!id) return

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = 'price_rules/articledetails/save';

        let data = new FormData(e.target);

        data.append('id', id);

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (typeof state.onSuccess === 'function') {
                state.onSuccess(res.data)
            }

            hide()
        }).catch(error => {
            const _err = error.response;
            if (_err && _err.status && _err.status === 422) {
                hide(() => {
                    let modal = validationModalRef.current;

                    if (modal) {
                        modal.open();

                        modal.onClose(() => {
                            setValidations(_err.data.errors)
                            show();
                        });
                    }
                });
            }

        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }));
        });
    }

    const handleInputChange = (e, index) => {
        let name = e.target.name;
        let value = e.target.value;

        setState(name, value, 'data');
    }

    const addDetail = () => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                details: prev.data.details.concat({})
            }
        }));
    }

    const removeDetail = (index) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                details: prev.data.details.filter((d, i) => Number(i) !== Number(index))
            }
        }));
    }

    const handleDetailChange = (data, index) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                details: Object.values({
                    ...prev.data.details,
                    [index]: {
                        ...prev.data.details[index],
                        detail_id: data.id,
                        name: data.name,
                        value: '',
                        value_id: ''
                    }
                })
            }
        }))
    }

    const handleValueChange = (data, index) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                details: Object.values({
                    ...prev.data.details,
                    [index]: {
                        ...prev.data.details[index],
                        value: data?.name || data,
                        value_id: data?.id || ''
                    }
                })
            }
        }))
    }

    const handleGroupChange = (data, index) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                details: Object.values({
                    ...prev.data.details,
                    [index]: {
                        ...prev.data.details[index],
                        group: data || ''
                    }
                })
            }
        }))
    }

    const getGroups = () => {
        return [...Array(50).keys()];
    }

    return (
        <>
            <Modal size="lg" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <form ref={formRef} onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Масов избор на артикули по характеристики
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Alert>
                            Ако искате филтрираните артикули да отговарят на две или повече характеристи едновременно, може да им зададете една и съща група. Редовете без група ще филтрират с логическо "или".
                        </Alert>
                        {state.data.details.map((row, index) =>
                            <Row key={index} className="mb-3">
                                <input type="hidden" name={`details[${index}][id]`} value={row.id || ''} />
                                <Col>
                                    <Autocomplete
                                        url="autocomplete/article-details"
                                        inputPlaceholder="Име"
                                        inputName={`details[${index}][name]`}
                                        inputIdName={`details[${index}][detail_id]`}
                                        // inputValue={state.data.details[index].name || ''}
                                        inputIdValue={state.data.details[index].detail_id || ''}
                                        error={Boolean(validations?.details && validations.details[index] && validations.details[index].name)}
                                        helperText={validations?.details && validations.details[index] && validations.details[index].name && (validations.details[index].name[0] || validations.details[index].name)}
                                        onChange={data => handleDetailChange(data, index)}
                                        renderText={data => data?.translation?.name || data.name}
                                    />
                                </Col>
                                <Col >
                                    <Autocomplete
                                        url="autocomplete/article-details-values"
                                        params={{
                                            detail_id: state.data.details[index].detail_id,
                                        }}
                                        requiredParams={['detail_id']}
                                        inputPlaceholder="Стойност"
                                        inputName={`details[${index}][value]`}
                                        inputIdName={`details[${index}][value_id]`}
                                        // inputValue={state.data.details[index].value || ''}
                                        inputIdValue={state.data.details[index].value_id || ''}
                                        error={Boolean(validations?.details && validations.details[index] && validations.details[index].value)}
                                        helperText={validations?.details && validations.details[index] && validations.details[index].value && (validations.details[index].value[0] || validations.details[index].value)}
                                        onChange={data => handleValueChange(data, index)}
                                        onInputChange={data => handleValueChange(data, index)}
                                        renderInputText={data => data?.translation?.name || data.name}
                                        renderText={data => (
                                            data.color
                                                ?
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div style={{ width: 20, height: 20, marginRight: 20, backgroundColor: data.color }}>

                                                    </div>
                                                    <div>
                                                        {data?.translation?.name || data.name}
                                                    </div>
                                                </div>
                                                :
                                                data?.translation?.name || data.name
                                        )}
                                    />
                                </Col>
                                {/* <Col>
                                    <FloatingLabel label="Предпочитана цена">
                                        <Form.Control
                                            type="number"
                                            min="0"
                                            step="0.01"
                                            name={`details[${index}][prefered_price]`}
                                            autoComplete="new-password"
                                            value={state.data.details[index].prefered_price || ''}
                                            placeholder="Предпочитана цена"
                                            onChange={(e) => handleInputChange(e, index)}
                                            isInvalid={Boolean(validations?.details && validations.details[index] && validations.details[index].prefered_price)}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {validations?.details && validations.details[index] && validations.details[index].prefered_price && (validations.details[index].prefered_price[0] || validations.details[index].prefered_price)}
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col> */}
                                <Col>
                                    <FloatingLabel label="Група">
                                        <Form.Select
                                            name={`details[${index}][group]`}
                                            value={state.data.details[index].group || ''}
                                            onChange={(e) => handleGroupChange(e.target.value, index)}
                                            isInvalid={Boolean(validations?.details && validations.details[index] && validations.details[index].group)}
                                        >
                                            <option value=""></option>
                                            {getGroups().map(group =>
                                                <option key={group} value={group}>Група {group + 1}</option>
                                            )}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {validations?.details && validations.details[index] && validations.details[index].group && (validations.details[index].group[0] || validations.details[index].group)}
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                                <Col style={{ display: 'flex', alignItems: 'center' }} xs="auto">
                                    <Button variant="danger" size="sm" onClick={e => removeDetail(index)}>
                                        <TrashFill />
                                    </Button>
                                </Col>
                            </Row>
                        )}
                        <div className={'mt-3'}>
                            <Button variant="outline-dark" size="sm" onClick={addDetail}>
                                Нов ред
                            </Button>
                        </div>

                        <div className={'mt-3'}>
                            Текущо филтрирани артикули: {state.counter}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            className="save"
                        />
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default forwardRef(ArticleDetails);
