export const TYPES = {
    ARTICLE: 'article',
    STORELOAD: 'storeload',
    STOREMOVE: 'storemove',
    REVISION: 'revision',
    ACCOUNT: 'account',
    PRICERULE: 'pricerule',
};

export const OPERATION_TYPE_IDS = {
    article: 1,
    storeload: 101,
    storemove: 102,
    revision: 103,
    account: 1001,
    pricerule: 2001,
};