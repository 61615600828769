import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import moment from 'moment'
import { Modal, Button, Tabs, Tab, FloatingLabel, Form, Row, Col } from 'react-bootstrap';

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//providers
import { useLanguageContext } from 'providers/Language'

//misc
import Autocomplete from 'components/misc/Autocomplete';
import SaveButton from 'components/misc/Button'
import Info from 'components/modals/Info';
import HelpInfo from 'components/misc/Info'

// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from 'assets/js/ckeditor/src/ckeditor';
// import ClassicEditor from 'assets/js/ckeditor/build/ckeditor';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';

import { TYPE } from 'constants/email-markers';
import IFrame from 'components/partials/Iframe';
import Success from 'components/modals/Success';
import Error from 'components/modals/Error';
import Code from 'components/misc/Code';

const ClassicEditor = window.ClassicEditor;

let timeout;

function SendMail(props, ref) {

    const langs = useLanguageContext();

    const defaultData = {
        langs: {},
    }

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        tabId: 'preview',
        data: defaultData,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null,
        onClose: null,
    });

    const [markers, setMarkers] = useState([]);

    const [validations, setValidations] = useValidation();

    const successModalRef = useRef(null);
    const errorModalRef = useRef(null);

    useImperativeHandle(ref, () => ({
        show: (id, email) => {
            open(id, email);
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        }
    }));

    useEffect(() => {
        loadData();
    }, [state.refresh]);

    useEffect(() => {
        if (!state.show) {
            return;
        }

        Api.get(`email_templates/types`)
            .then(res => {
                if (Array.isArray(res.data)) {
                    setMarkers(res.data);
                }
            });
    }, [state.show]);

    useEffect(() => {
        clearTimeout(timeout);

        timeout = setTimeout(() => {
            loadPreview();
        }, 1300);

    }, [state.data.title, state.data.text]);

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const open = (id, email) => {
        setState(prev => ({
            ...prev,
            data: {
                ...defaultData,
                email: email,
            },
            id: id,
            tabId: 'preview',
            refresh: new Date()
        }));

        show();
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
        }));

        setValidations(null);

        if (typeof state.onClose === 'function') {
            hide(state.onClose);
        } else {
            hide();
        }
    }

    const loadData = () => {
        if (!state.id) {
            return;
        }

        Api.get('/returns/email-template', {
            params: {
                id: state.id,
                template_id: state.data.template_id,
            }
        }).then(res => {
            setState(prev => ({
                ...prev,
                data: res.data
            }))
        });
    }

    const loadPreview = () => {
        if (!state.show) {
            return;
        }

        Api.post('/returns/email-template-preview', {
            id: state.id,
            title: state.data.title,
            text: state.data.text,
        }).then(res => {
            setState(prev => ({
                ...prev,
                preview: res.data
            }))
        });
    }

    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = '/returns/email-send';

        let data = new FormData(e.target);

        if (state.id) {
            data.append('id', state.id)
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (res.data.success === true) {
                if (typeof state.onSuccess === 'function') {
                    state.onSuccess(res.data)
                }

                let modal = successModalRef.current;

                if (modal) {
                    hide(() => {
                        modal.open('Събщението е изпратено успешно!');
                    });

                    modal.onClose(state.onClose);
                }

            } else {
                let modal = errorModalRef.current;

                if (modal) {
                    hide(() => {
                        modal.open('Събщението не беше изпратено!');
                    });

                    modal.onClose(state.onClose);
                }
            }
        }).catch(error => {
            const _err = error.response;

            if (_err && _err.status && _err.status === 422) {
                setValidations(_err.data.errors)
            }

        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }));
        });
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleTemplateChange = e => {
        let value = e.target.value

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                template_id: value,
            },
            refresh: new Date()
        }));
    }

    const handleTabChange = id => {
        setState(prev => ({
            ...prev,
            tabId: id
        }));
    }

    const handleCheckboxChange = e => {
        const name = e.target.name;

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: prev.data[name] ? false : true
            }
        }));
    }

    const handleMarker = (marker) => {
        let str = state.data.text || '';

        str = str.substring(0, str.length - 4) + ` ${marker} ` + str.substring(str.length - 4, str.length);

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                text: str,
            }
        }));
    }

    return (
        <>
            <Success
                ref={successModalRef}
            />

            <Error
                ref={errorModalRef}
            />

            <Modal size="lg" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <form onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Изпращане на email
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Row>
                            <Col className="mb-3">
                                <FloatingLabel label="До*">
                                    <Form.Control
                                        type="text"
                                        placeholder="До*"
                                        name="email"
                                        value={state.data.email || ''}
                                        onChange={handleInputChange}
                                        isInvalid={Boolean(validations && validations.email)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {validations && validations.email && (validations.email[0] || validations.email)}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>

                        <Row >
                            <Col className="mb-3">
                                <FloatingLabel label="Шаблон">
                                    <Form.Select
                                        name="template_id"
                                        value={state.data.template_id || ''}
                                        onChange={handleTemplateChange}
                                    >
                                        <option></option>
                                        {(state.data.templates || []).map(t =>
                                            <option key={t.id} value={t.id}>{t.name || ''}</option>
                                        )}

                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                        </Row>

                        <Row >
                            <Col className="mb-3">
                                <FloatingLabel label="Относно" className="mb-3">
                                    <Form.Control
                                        type="text"
                                        name="title"
                                        placeholder="Относно*"
                                        value={state.data.title || ''}
                                        onChange={handleInputChange}
                                        isInvalid={Boolean(validations?.title)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {validations?.title && (validations.title[0] || validations.title)}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Tabs
                                    activeKey={state.tabId}
                                    onSelect={handleTabChange}
                                    className="mb-3"
                                >
                                    <Tab eventKey="text" title="Съобщение" tabClassName={validations && validations.text ? 'validate' : ''} />
                                    <Tab eventKey="preview" title="Визуализация" tabClassName={''} />

                                </Tabs>
                                <div className="mb-3" style={{ display: state.tabId === 'text' ? 'block' : 'none' }}>
                                    {/* <CKEditor
                                        editor={ClassicEditor}
                                        data={state.data.text || ''}
                                        onChange={(event, editor) => {
                                            setState('text', editor.getData(), 'data')
                                        }}
                                    /> */}

                                    <Code
                                        code={state.data.text}
                                        onChange={data => setState('text', data, 'data')}
                                    />

                                    <div className='validate'>
                                        {validations?.text && (validations.text[0] || validations.text)}
                                    </div>

                                    <br />

                                    <p><strong>Етикети, които се заместват:</strong></p>
                                    {markers.filter(m => m.type_id === null).map(m =>
                                        <p key={m.id} className="markers"><span onClick={() => handleMarker(m.marker)}>{m.marker}</span> - {m.marker_note}</p>
                                    )}

                                    <p style={{ marginTop: '1rem' }}><strong>Маркери към този тип:</strong></p>
                                    {markers.filter(m => m.type_id === TYPE.RETURN).map(m =>
                                        <p key={m.id} className="markers"><span onClick={() => handleMarker(m.marker)}>{m.marker}</span> - {m.marker_note}</p>
                                    )}
                                </div>

                                <div className="mb-3" style={{ display: state.tabId === 'preview' ? 'block' : 'none' }}>
                                    <IFrame style={{ height: '500px' }}>
                                        <div dangerouslySetInnerHTML={{ __html: state.preview?.text }} />
                                    </IFrame>
                                </div>

                                <input type="hidden" name="text" value={state.data.text || ''} readOnly />
                            </Col>
                        </Row>



                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            className="save"
                        >
                            Изпрати
                        </SaveButton>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default forwardRef(SendMail);
