import { Button, Form, InputGroup } from 'react-bootstrap';
import { TrashFill, InfoCircleFill } from 'react-bootstrap-icons';
import Autocomplete from 'components/misc/Autocomplete';

function TableRow(props) {

    const { row, index, visible } = props;
    const { state, validations } = props;

    const {
        handleChangeArticle,
        getRowStorageQuantity,
        handleQtyChange,
        getRowQuantityDiff,
        getRowAmountUnitDiff,
        getRowPriceDiff,
        handleChangeReason,
        handleRemoveRow,
        handleShowArticle
    } = props;

    return (
        <tr key={'r-' + index} style={{ display: visible ? 'table-row' : 'none' }}>
            <td title={row.id || '-'} style={{ width: '3%' }}>
                {index + 1}

                <input type="hidden" name={`rows[${index}][id]`} value={row.id || ''} />
            </td>
            <td style={{ width: '20%' }}>
                {state.data.view_type === 'selected'
                    ?
                    <Autocomplete
                        variant="basic"
                        url="autocomplete/articles"
                        inputName="article_name"
                        inputIdName={`rows[${index}][article_id]`}
                        inputValue={row?.article?.article_name || ''}
                        inputIdValue={row.article_id || ''}
                        onChange={data => handleChangeArticle(data, index)}
                        error={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].article_id && (validations.rows[index].article_id[0] || validations.rows[index].article_id))}
                        helperText={validations && validations.rows && validations.rows[index] && validations.rows[index].article_id && (validations.rows[index].article_id[0] || validations.rows[index].article_id)}
                        renderText={data => {
                            return (
                                <div style={{ display: 'flex', width: '100%' }}>
                                    <div style={{ width: 'max-content', marginRight: '10px' }}>
                                        {data.id}
                                    </div>
                                    <div>
                                        {data.name}
                                        <br />
                                        {data.article_name}
                                    </div>
                                </div>
                            )
                        }}
                        renderInputText={data => data.article_name}
                        size="sm"
                        autoFocus
                    />
                    :
                    <InputGroup size="sm">
                        <Form.Control
                            type="text"
                            value={row?.article?.article_name || ''}
                            size="sm"
                            disabled
                        />
                    </InputGroup>
                }
            </td>
            <td>
                <InputGroup size="sm" style={{ minWidth: '95px' }}>
                    <Form.Control
                        type="number"
                        value={row?.amount_unit || ''}
                        size="sm"
                        className={`price-input`}
                        disabled
                    />

                    <InputGroup.Text>
                        {row?.article?.amount_type_short || '-'}
                    </InputGroup.Text>
                </InputGroup>
            </td>
            <td>
                <InputGroup size="sm" style={{ minWidth: '95px' }}>
                    <Form.Control
                        type="number"
                        step="0.0001"
                        value={getRowStorageQuantity(row) != null ? getRowStorageQuantity(row) : ''}
                        size="sm"
                        className={`price-input`}
                        disabled
                    />

                    <InputGroup.Text>
                        {row?.article?.amount_type_short || '-'}
                    </InputGroup.Text>
                </InputGroup>
            </td>
            <td style={{ width: '10%' }}>
                <InputGroup size="sm" style={{ minWidth: '95px' }}>
                    <Form.Control
                        type="number"
                        step="0.0001"
                        name={`rows[${index}][actual_quantity]`}
                        value={row.actual_quantity || ''}
                        onChange={e => handleQtyChange(e, index)}
                        isInvalid={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].actual_quantity && (validations.rows[index].actual_quantity[0] || validations.rows[index].actual_quantity))}
                        size="sm"
                        className="price-input"
                    />

                    <InputGroup.Text>
                        {row?.article?.amount_type_short || '-'}
                    </InputGroup.Text>

                    {/* <Form.Control.Feedback type="invalid">
                        {validations && validations.rows && validations.rows[index] && validations.rows[index].actual_quantity && (validations.rows[index].actual_quantity[0] || validations.rows[index].actual_quantity)}
                    </Form.Control.Feedback> */}
                </InputGroup>

            </td>
            <td>
                <InputGroup size="sm" style={{ minWidth: '95px' }}>
                    <Form.Control
                        type="number"
                        step="0.0001"
                        value={getRowQuantityDiff(row) != null ? getRowQuantityDiff(row) : ''}
                        size="sm"
                        className={`price-input ${getRowQuantityDiff(row) < 0 ? 'negative-value' : ''}`}
                        disabled
                    />

                    <InputGroup.Text>
                        {row?.article?.amount_type_short || '-'}
                    </InputGroup.Text>
                </InputGroup>
            </td>
            <td>
                <InputGroup size="sm" style={{ minWidth: '95px' }}>
                    <Form.Control
                        type="number"
                        step="0.0001"
                        value={getRowAmountUnitDiff(row) != null ? getRowAmountUnitDiff(row) : ''}
                        size="sm"
                        className={`price-input ${getRowAmountUnitDiff(row) < 0 ? 'negative-value' : ''}`}
                        disabled
                    />

                    <InputGroup.Text>
                        {row?.article?.amount_type_short || '-'}
                    </InputGroup.Text>
                </InputGroup>
            </td>
            <td>
                <InputGroup size="sm" style={{ minWidth: '95px' }}>
                    <Form.Control
                        type="number"
                        step="0.0001"
                        value={getRowPriceDiff(row) != null ? getRowPriceDiff(row) : ''}
                        size="sm"
                        className={`price-input ${getRowPriceDiff(row) < 0 ? 'negative-value' : ''}`}
                        disabled
                    />

                    <InputGroup.Text>
                        {row?.article?.currency?.prefix || row?.article?.currency?.sufix || '-'}
                    </InputGroup.Text>
                </InputGroup>
            </td>
            <td style={{ width: '10%' }}>
                <Autocomplete
                    variant="basic"
                    url="autocomplete/revision-reasons"
                    inputName="reason_name"
                    inputIdName={`rows[${index}][reason_id]`}
                    inputValue={row?.reason?.name || ''}
                    inputIdValue={row.reason_id || ''}
                    onChange={data => handleChangeReason(data, index)}
                    error={Boolean(validations && validations.rows && validations.rows[index] && validations.rows[index].reason_id && (validations.rows[index].reason_id[0] || validations.rows[index].reason_id))}
                    helperText={validations && validations.rows && validations.rows[index] && validations.rows[index].reason_id && (validations.rows[index].reason_id[0] || validations.rows[index].reason_id)}
                    renderText={data => {
                        return (
                            <div style={{ display: 'flex', width: '100%' }}>
                                <div style={{ marginRight: '10px' }}>
                                    <div style={{ background: data.color, width: 20, height: 20, }}></div>
                                </div>
                                <div>
                                    {data?.translation?.name || data.name}
                                </div>
                            </div>
                        )
                    }}
                    renderInputText={data => data?.translation?.name || data.name}
                    size="sm"
                />
            </td>
            <td className="text-center" style={{ width: '5%' }}>
                <div style={{ display: 'flex', gap: '3px' }}>
                    {row.article &&
                        <Button variant="secondary" size="sm" onClick={e => handleShowArticle(row.article?.id)}>
                            <InfoCircleFill />
                        </Button>
                    }
                    {state.data.view_type === 'selected' &&
                        <Button variant="danger" size="sm" onClick={e => handleRemoveRow(e, index)}>
                            <TrashFill />
                        </Button>
                    }
                </div>
            </td>

        </tr>
    )
}

export default TableRow;