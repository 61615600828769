import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import moment from 'moment'
import { Modal, Button, Tabs, Tab, FloatingLabel, Form, Row, Col, Nav, Alert } from 'react-bootstrap';
import { TrashFill } from 'react-bootstrap-icons';

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//misc
import SaveButton from 'components/misc/Button'
import HelpInfo from 'components/misc/Info'
import Info from 'components/modals/Info'

function AddOrEdit(props, ref) {

    const defaultData = {
    }

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        edit: false,
        data: defaultData,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null,
        mainTabId: 'main',

    });

    const [validations, setValidations] = useValidation();

    const validationModalRef = useRef(null);

    useImperativeHandle(ref, () => ({
        add: () => {
            handleShow();
        },
        edit: (id) => {
            edit(id);
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        }
    }));

    useEffect(() => {
        loadData();
    }, [state.edit]);


    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const handleShow = () => {
        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
            mainTabId: 'main',
        }));
        show();
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
        }));

        setValidations(null);

        hide();
    }

    const edit = id => {
        setState(prev => ({
            ...prev,
            id: id,
            edit: new Date().getTime()
        }));

        show();
    }

    const loadData = () => {
        if (!state.id) {
            return;
        }

        Api.get('companies/show?id=' + state.id)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    mainTabId: 'main',
                    data: res.data
                }))
            });
    }

    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = state.id ? 'companies/update' : 'companies/add';

        let data = new FormData(e.target);

        if (state.id) {
            data.append('id', state.id)
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (typeof state.onSuccess === 'function') {
                state.onSuccess(res.data)
            }
            hide()
        }).catch(error => {
            const _err = error.response;
            if (_err && _err.status && _err.status === 422) {
                hide(() => {
                    let modal = validationModalRef.current;

                    if (modal) {
                        modal.open();

                        modal.onClose(() => {
                            setValidations(_err.data.errors)
                            show();
                        });
                    }
                });
            }

        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }));
        });
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleCheckboxChange = e => {
        const name = e.target.name;
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: prev.data[name] ? false : true
            }
        }));
    }

    const setMainTab = key => {
        setState(prev => ({
            ...prev,
            mainTabId: key
        }));
    }

    return (
        <>
            <Info
                ref={validationModalRef}
                mainMessage="Моля, попълнете всички задължителни полета, означени с червен цвят!"
            />

            <Modal size="lg" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <form onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Фирма
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Tab.Container activeKey={state.mainTabId} onSelect={key => setMainTab(key)}>
                            <Row>
                                <Col xl={3} sm={3}>
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link eventKey="main">Основни данни</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="contactData">Данни за контакт</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                <Col xl={9} sm={9}>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="main">
                                            <Row className="mb-3">
                                                <Col>
                                                    <FloatingLabel label="Популярно име*">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Популярно име*"
                                                            name="pop_name"
                                                            value={state.data.pop_name || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.pop_name)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.pop_name && (validations.pop_name[0] || validations.pop_name)}
                                                        </Form.Control.Feedback>
                                                        <HelpInfo placement="top">Свободно име, виждащо се само в системата</HelpInfo>
                                                    </FloatingLabel>
                                                </Col>

                                                <Col>
                                                    <FloatingLabel label="Име на фирма">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Име на фирма"
                                                            name="company_name"
                                                            value={state.data.company_name || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.company_name)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.company_name && (validations.company_name[0] || validations.company_name)}
                                                        </Form.Control.Feedback>
                                                        <HelpInfo placement="top">Име на юридическото лице по регистрация</HelpInfo>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>

                                            <Row className="mb-3">
                                                <Col>
                                                    <FloatingLabel label="ЕИК">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="ЕИК"
                                                            name="bulstat"
                                                            value={state.data.bulstat || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.bulstat)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.bulstat && (validations.bulstat[0] || validations.bulstat)}
                                                        </Form.Control.Feedback>
                                                        <HelpInfo placement="top">БУЛСТАТ</HelpInfo>
                                                    </FloatingLabel>
                                                </Col>

                                                <Col>
                                                    <FloatingLabel label="ДДС №">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="ДДС №"
                                                            name="vat_num"
                                                            value={state.data.vat_num || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.vat_num)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.vat_num && (validations.vat_num[0] || validations.vat_num)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>

                                            <Row className="mb-3">
                                                <Col>
                                                    <FloatingLabel label="МОЛ">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="МОЛ"
                                                            name="mol"
                                                            value={state.data.mol || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.mol)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.mol && (validations.mol[0] || validations.mol)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>

                                            <Row className="mb-3">
                                                <Col>
                                                    <FloatingLabel label="Град">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Град"
                                                            name="town"
                                                            value={state.data.town || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.town)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.town && (validations.town[0] || validations.town)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>

                                                <Col>
                                                    <FloatingLabel label="Адрес">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Адрес"
                                                            name="address"
                                                            value={state.data.address || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.address)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.address && (validations.address[0] || validations.address)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>

                                            <Row className="mb-3">
                                                <Col>
                                                    <FloatingLabel label="Текст основание за фактури">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Текст основание за фактури"
                                                            name="inv_default_dds_reason"
                                                            value={state.data.inv_default_dds_reason || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.inv_default_dds_reason)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.inv_default_dds_reason && (validations.inv_default_dds_reason[0] || validations.inv_default_dds_reason)}
                                                        </Form.Control.Feedback>
                                                        <HelpInfo placement="top">Текст по подразбиране за Основание при Нулева ставка</HelpInfo>
                                                    </FloatingLabel>
                                                </Col>

                                                <Col>
                                                    <FloatingLabel label="Стартов номер на фактури*">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Стартов номер на фактури*"
                                                            name="min_inv_num"
                                                            value={state.data.min_inv_num || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.min_inv_num)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.min_inv_num && (validations.min_inv_num[0] || validations.min_inv_num)}
                                                        </Form.Control.Feedback>
                                                        <HelpInfo placement="top">Номер, от който да започне издаване на фактури. Ако вече има издадени фактури, то този номер не може да бъде по-малък от последния издаден номер.</HelpInfo>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>

                                            <Col>
                                                <FloatingLabel label="Описание">
                                                    <Form.Control
                                                        as="textarea"
                                                        name={`inv_additional_text`}
                                                        placeholder="Описание"
                                                        onChange={(e) => handleInputChange(e)}
                                                        value={state.data.inv_additional_text || ''}
                                                        style={{ height: '100px' }}
                                                        isInvalid={Boolean(validations && validations.inv_additional_text)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {validations && validations.inv_additional_text && (validations.inv_additional_text[0] || validations.inv_additional_text)}
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>
                                            </Col>

                                        </Tab.Pane>
                                        <Tab.Pane eventKey="contactData">
                                            <Row className="mb-3">
                                                <Col>
                                                    <FloatingLabel label="Адрес за контакт">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Адрес за контакт"
                                                            name="office_address"
                                                            value={state.data.office_address || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.office_address)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.office_address && (validations.office_address[0] || validations.office_address)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>

                                                <Col>
                                                    <FloatingLabel label="Пощенски код">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Пощенски код"
                                                            name="zip"
                                                            value={state.data.zip || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.zip)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.zip && (validations.zip[0] || validations.zip)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>

                                            </Row>

                                            <Row className="mb-3">
                                                <Col>
                                                    <FloatingLabel label="Факс">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Факс"
                                                            name="fax"
                                                            value={state.data.fax || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.fax)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.fax && (validations.fax[0] || validations.fax)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>

                                                <Col>
                                                    <FloatingLabel label="Телефон">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Телефон"
                                                            name="phone"
                                                            value={state.data.phone || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.phone)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.phone && (validations.phone[0] || validations.phone)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>

                                            </Row>

                                            <Row className="mb-3">
                                                <Col>
                                                    <FloatingLabel label="Email">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Email"
                                                            name="email"
                                                            value={state.data.email || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.email)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.email && (validations.email[0] || validations.email)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>

                                                <Col>
                                                    <FloatingLabel label="Email оферти">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Email оферти"
                                                            name="email_offers"
                                                            value={state.data.email_offers || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.email_offers)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.email_offers && (validations.email_offers[0] || validations.email_offers)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>

                                            </Row>

                                            <Row>
                                                <Col>
                                                    <FloatingLabel label="Email фактури">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Email фактури"
                                                            name="email_invoices"
                                                            value={state.data.email_invoices || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.email_invoices)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.email_invoices && (validations.email_invoices[0] || validations.email_invoices)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>

                                                <Col>
                                                    <FloatingLabel label="Лице за контакти">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Лице за контакти"
                                                            name="contact_name"
                                                            value={state.data.contact_name || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.contact_name)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.contact_name && (validations.contact_name[0] || validations.contact_name)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>

                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            className="save"
                        />
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default forwardRef(AddOrEdit);
