import ReactDOM from 'react-dom';
import { Dropdown } from 'react-bootstrap'

function MenuOptionsDropdown(props) {
    return ReactDOM.createPortal(
        <Dropdown.Menu className="dropdown-menu-options" align="end">
            {props.children}
        </Dropdown.Menu>,
        document.body
    )
}

export default MenuOptionsDropdown;