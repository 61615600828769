import React, { useState, useImperativeHandle, forwardRef, useEffect, useRef } from 'react'
import { Modal, Button, Tabs, Tab } from 'react-bootstrap';
import SaveButton from 'components/misc/Button';
import Loader from 'components/misc/Loader';
import Home from './Home';
// import History from './History';
import OrdersHistory from './OrdersHistory';
import Notes from './Notes';
import Payments from './Payments';
import DeliveryData from './DeliveryData';
import StorageMovements from './StorageMovements';
import PackageCards from './PackageCards';
import AddPackageCard from './partials/AddPackageCard';
import Documents from './Documents';

import SendMail from 'components/pages/accounts/partials/SendMail';
import Note from 'components/partials/notes/AddOrEdit';
import Article from 'components/pages/articles/partials/AddOrEdit';
import LinkDocument from 'components/pages/accounts/partials/LinkDocument';
import NewDocument from 'components/pages/accounts/partials/NewDocument';

import Question from 'components/modals/Question';
import Info from 'components/modals/Info';

import Api from 'helpers/Api';

import { MODELS } from 'constants/models';
import Refs from 'Refs';
import { useAppContext } from 'providers/App';
import { useAuthDataContext } from 'providers/Auth';
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons';

function View(props, ref) {

    const tabs = [
        {
            id: 'home',
            name: 'Преглед',
            component: Home
        },
        {
            id: 'history',
            name: 'История',
            component: OrdersHistory
        },
        {
            id: 'movements',
            name: 'Складови движения',
            component: StorageMovements
        },
        {
            id: 'documents',
            name: 'Документи',
            component: Documents
        },
        {
            id: 'payments',
            name: 'Плащания',
            component: Payments
        },
        {
            id: 'notes',
            name: 'Бележки',
            component: Notes
        },
        {
            id: 'cards',
            name: 'Пакетни карти',
            component: PackageCards
        },
    ];

    const app = useAppContext();
    const auth = useAuthDataContext();

    const [state, setState] = useState({
        show: false,
        params: null,
        id: null,
        tabId: null,
        onEntering: null,
        onExiting: null,
        onClose: null,
    });

    const clientModalRef = useRef(null);
    const sendMailModalRef = useRef(null);
    const noteModalRef = useRef(null);
    const articleModalRef = useRef(null);
    const addPackageCardModalRef = useRef(null);
    const deliveryDataModalRef = useRef(null);
    const questionModalRef = useRef(null);
    const infoModalRef = useRef(null);
    const linkDocumentModalRef = useRef(null);
    const newDocumentModalRef = useRef(null);
    const leftArrowRef = useRef(null);
    const rightArrowRef = useRef(null);

    useImperativeHandle(ref, () => ({
        open: (id, resetTab = true) => {
            handleShow(id, resetTab);
        },
        close: () => {
            hide();
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        }
    }));

    useEffect(() => {
        if (!state.show) {
            return;
        }

        if (auth.getUser().permission('accounts_account_orders') === false) {
            app.showError('Нямате право да преглеждате сметка!');

            hide(state.onClose);
        }

    }, [state.show]);

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const handleShow = (id, resetTab = true) => {
        setState(prev => ({
            ...prev,
            id: id,
            tabId: resetTab || !prev.tabId ? getDefaultActiveTab()?.id : prev.tabId,
        }));

        show();
    }

    const handleClose = () => {
        setState(prev => ({
            ...prev,
            show: false,
            tabId: tabs[0].id
        }));

        if (typeof state.onClose === 'function') {
            hide(state.onClose);
        } else {
            hide();
        }

        setState(prev => ({
            ...prev,
            onClose: null,
        }));
    }

    const setKey = id => {
        setState(prev => ({
            ...prev,
            tabId: id,
        }));
    }

    const refresh = () => {
        setState(prev => ({
            ...prev,
            key: new Date().getTime(),
        }));
    }

    const hasTabPermission = key => {
        let map = {
            home: 'accounts_account_orders',
            // payments: 'payments_index',
            notes: 'accounts_account_notes',
            // cards: 'accounts_edit',
        }

        let permission = map[key];

        if (permission && auth.getUser().permission(permission) === false) {
            return false;
        }

        return true;
    }

    const getDefaultActiveTab = () => {
        return tabs.find(tab => {
            return hasTabPermission(tab.id);
        });
    }

    const showArticle = id => {
        if (!id) {
            return;
        }

        let modal = Refs.getInstance().getRef('article');

        if (!modal) {
            return;
        }

        hide(() => {
            modal.open(id);
        });

        modal.onClose(() => {
            show();
        });
    }

    const showClient = id => {
        if (!id) {
            return;
        }

        let modal = Refs.getInstance().getRef('client');

        if (!modal) {
            return;
        }

        hide(() => {
            modal.open(id);
        });

        modal.onClose(() => {
            show();
        });
    }

    const sendMail = account => {
        if (!account) {
            return;
        }

        let modal = sendMailModalRef.current;

        if (!modal) {
            return;
        }

        hide(() => {
            modal.show(account.id, account.client?.email);
        });

        modal.onClose(() => {
            show();
        });
    }

    const addNote = () => {

        let modal = noteModalRef.current;

        if (!modal) {
            return;
        }

        hide(() => {
            modal.add(state.id, MODELS.ACCOUNT);
        });

        modal.onClose(() => {
            show();
        });
    }

    const editNote = (id) => {

        let modal = noteModalRef.current;

        if (!modal) {
            return;
        }

        hide(() => {
            modal.edit(id);
        });

        modal.onClose(() => {
            show();
        });
    }

    const deleteNote = (id) => {

        let modal = questionModalRef.current;

        if (!modal) {
            return;
        }

        hide(() => {
            modal.open('Сигурни ли сте, че искате да изтриете тази бележка?');
        });

        modal.onCancel(() => {
            show();
        });

        modal.onSuccess(() => {
            destroyNote(id, () => {
                show();
            });
        });
    }

    const destroyNote = (id, cb) => {
        Api.post('notes/delete', {
            id: id
        }).then(res => {
            cb();
        });
    }

    const addPackageCard = () => {

        let modal = addPackageCardModalRef.current;

        if (!modal) {
            return;
        }

        hide(() => {
            modal.add(state.id);
        });

        modal.onClose(() => {
            show();
        });
    }

    const deletePackageCard = (cardId) => {

        let modal = infoModalRef.current;

        if (!modal) {
            return;
        }

        hide(() => {
            modal.open('Това действие ще възстанови сумата и използванията на пакетната карта, но няма да прекалкулира цените на артикулите в сметката');
        });

        modal.onCancel(() => {
            show();
        });

        modal.onSuccess(() => {
            destroyPackageCard(cardId, () => {
                show();
            });
        });
    }

    const destroyPackageCard = (cardId, cb) => {
        Api.post('accounts/package-cards/remove', {
            id: state.id,
            card_id: cardId
        }).then(res => {
            cb();
        });
    }

    const editDeliveryData = () => {

        let modal = deliveryDataModalRef.current;

        if (!modal) {
            return;
        }

        hide(() => {
            modal.open(state.id);
        });

        modal.onSuccess(() => {
            // ??
        });

        modal.onClose(() => {
            show();
        });
    }

    const showPayment = (id) => {
        let modal = Refs.getInstance().getRef('payment');

        if (!modal) {
            return;
        }

        hide(() => {
            modal.open(id);
        });

        modal.onClose(() => {
            show();
        });

    }

    const showInvoice = (id) => {
        let modal = Refs.getInstance().getRef('invoice');

        if (!modal) {
            return;
        }

        hide(() => {
            modal.open(id);
        });

        modal.onClose(() => {
            show();
        });
    }

    const deleteInvoice = (id) => {

        let modal = questionModalRef.current;

        if (!modal) {
            return;
        }

        hide(() => {
            modal.open('Сигурни ли сте, че искате да премахнете връзката между избрания документ и тази сметка?');
        });

        modal.onCancel(() => {
            show();
        });

        modal.onSuccess(() => {
            unlinkInvoice(id, () => {
                show();
            });
        });
    }

    const unlinkInvoice = (id, cb) => {
        Api.post('accounts/unlink-invoice', {
            id: state.id,
            invoice_id: id,
        }).then(res => {
            cb();
        });
    }

    const showDocument = (data) => {
        let modal = Refs.getInstance().getRef('document');

        if (!modal) {
            return;
        }

        hide(() => {
            modal.open(data, ref => {
                ref.onClose(() => {
                    show();
                });
            });
        });
    }

    const deleteDocument = (id) => {

        let modal = questionModalRef.current;

        if (!modal) {
            return;
        }

        hide(() => {
            modal.open('Сигурни ли сте, че искате да премахнете връзката между избрания документ и тази сметка?');
        });

        modal.onCancel(() => {
            show();
        });

        modal.onSuccess(() => {
            unlinkDocument(id, () => {
                show();
            });
        });
    }

    const unlinkDocument = (id, cb) => {
        Api.post('accounts/unlink-document', {
            id: state.id,
            document_id: id,
        }).then(res => {
            cb();
        });
    }

    const linkDocument = () => {
        let modal = linkDocumentModalRef.current;

        if (!modal) {
            return;
        }

        hide(() => {
            modal.open(state.id);
        });

        modal.onSuccess(() => {
            // 
        });

        modal.onClose(() => {
            show();
        });
    }

    const addDocument = () => {
        let modal = newDocumentModalRef.current;

        if (!modal) {
            return;
        }

        hide(() => {
            modal.open(state.id);
        });

        modal.onSuccess(() => {
            // 
        });

        modal.onClose(() => {
            show();
        });
    }

    useEffect(() => {
        if (!state.show) {
            return;
        }

        const onKeyPress = (e) => {
            if (e.key === 'ArrowRight') {
                rightArrowRef.current?.click();
            }

            if (e.key === 'ArrowLeft') {
                leftArrowRef.current?.click();
            }
        }

        window.addEventListener('keyup', onKeyPress);

        return () => {
            window.removeEventListener('keyup', onKeyPress);
        }
    }, [state.show]);

    const handleShowNextItem = e => {
        e.preventDefault();

        if (props.showNextItem) {
            props.showNextItem(state.id);
        }
    }

    const handleShowPrevItem = e => {
        e.preventDefault();

        if (props.showPrevItem) {
            props.showPrevItem(state.id);
        }
    }

    const handleRefresh = e => {
        e.preventDefault();

        refresh();
    }

    return (
        <>
            <SendMail
                ref={sendMailModalRef}
            />

            <Note
                ref={noteModalRef}
            />

            <Article
                ref={articleModalRef}
            />

            <AddPackageCard
                ref={addPackageCardModalRef}
            />

            <DeliveryData
                ref={deliveryDataModalRef}
            />

            <LinkDocument
                ref={linkDocumentModalRef}
            />

            <NewDocument
                ref={newDocumentModalRef}
            />

            <Question
                ref={questionModalRef}
            />

            <Info
                ref={infoModalRef}
                showCancel
            />

            <Modal size="xl" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                {props.showPrevItem &&
                    <span ref={leftArrowRef} className="arrow left" onClick={handleShowPrevItem}>
                        <ChevronLeft />
                    </span>
                }
                {props.showNextItem &&
                    <span ref={rightArrowRef} className="arrow right" onClick={handleShowNextItem}>
                        <ChevronRight />
                    </span>
                }
                <Modal.Header>
                    <Modal.Title>
                        Преглед на сметка
                    </Modal.Title>
                    <div className="buttons">
                        <button className="btn-reload" onClick={handleRefresh}>

                        </button>
                        <button className="btn-close" onClick={handleClose}>

                        </button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Tabs
                        key={state.key}
                        activeKey={state.tabId}
                        onSelect={(k) => setKey(k)}
                        className="mb-3"
                        mountOnEnter
                    >
                        {tabs.map(tab =>
                            <Tab key={tab.id} eventKey={tab.id} title={tab.name} disabled={hasTabPermission(tab.id) === false}>
                                {React.createElement(tab.component, {
                                    id: state.id,
                                    show: show,
                                    hide: hide,
                                    showArticle: showArticle,
                                    showClient: showClient,
                                    showPayment: showPayment,
                                    sendMail: sendMail,
                                    addNote: addNote,
                                    editNote: editNote,
                                    deleteNote: deleteNote,
                                    addPackageCard: addPackageCard,
                                    deletePackageCard: deletePackageCard,
                                    editDeliveryData: editDeliveryData,
                                    showInvoice: showInvoice,
                                    deleteInvoice: deleteInvoice,
                                    showDocument: showDocument,
                                    deleteDocument: deleteDocument,
                                    linkDocument: linkDocument,
                                    addDocument: addDocument
                                })}
                            </Tab>
                        )}
                    </Tabs>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Затвори
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default forwardRef(View);