import { useAppContext } from 'providers/App';
import React from 'react';
import ReactDOM from 'react-dom';

function FlashMessages() {
    const app = useAppContext()

    return (
        ReactDOM.createPortal(
            <div className="flash-messages">
                {[...app.state.messages].reverse().map((message, i) =>
                    React.createElement(message.component, {
                        key: i,
                        title: message.title,
                        body: message.body,
                        icon: message.icon,
                        variant: message.variant,
                        dismissible: true,
                    })
                )}
            </div>,
            document.body
        )
    )
}

export default FlashMessages;