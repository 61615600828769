import { useState, useEffect, useRef } from 'react'
import { Button, Dropdown, Table } from 'react-bootstrap';
import Api from 'helpers/Api';
import Loader from 'components/misc/Loader';
import moment from 'moment';
import NoDataFound from 'components/misc/NoDataFound';
import Pagination from 'components/misc/Pagination';
import TableHeader from 'components/misc/TableHeader';
import TableCol from 'components/misc/TableCol';
import Options from 'components/partials/notes/Options';
import MenuOptions from 'components/misc/MenuOptions';
import PriceValue from 'components/partials/PriceValue';
import { useNavigate } from 'react-router-dom';
import Refs from 'Refs';
import { useAuthDataContext } from 'providers/Auth';

function Index(props) {

    const auth = useAuthDataContext();
    const navigate = useNavigate();

    const defaultData = [];

    const tableRef = useRef(null);

    const [state, setState] = useState({
        data: defaultData,
        pages: 0,
        total: 0,
        loading: false,
        filter: {
            page: 1,
        },
        refresh: false,
    });

    useEffect(() => {

        loading(true);

        Api.get('orders/accounts', {
            params: {
                id: props.id,
                ...state.filter,
            }
        }).then(res => {
            setState(prev => ({
                ...prev,
                order: res.data.order,
                data: res.data.items,
                total: res.data.total,
                pages: res.data.pages,
                filter: {
                    ...prev.filter,
                    ...res.data.filter,
                },
            }));

            if (res.data.pages > 0 && state.filter.page > res.data.pages) {
                return handlePage({ selected: res.data.pages - 1 });
            }

            loading(false);
            sorting(false);
        });
    }, [props.id, state.refresh]);

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }));
    }

    const sorting = (sorting) => {
        setState(prev => ({
            ...prev,
            sorting: Boolean(sorting)
        }));
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            refresh: new Date()
        }));
    }

    const handleSort = (sort, order) => {
        setState(prev => ({
            ...prev,
            sorting: true,
            filter: {
                ...prev.filter,
                sort: sort,
                order: order,
                page: 1,
            },
            refresh: new Date().getTime()
        }));
    }

    const handleAdd = () => {
        navigate('/accounts/new?from_order_id=' + props.id);
    }

    const handleShow = id => {
        props.showAccount(id);
    }

    const handleEdit = id => {
        navigate('/accounts/' + id + '/edit');
    }

    const handleDelete = id => {
        props.deleteAccount(id);
    }

    const showClient = id => {
        props.showClient(id);
    }

    return (

        state.loading && !state.sorting
            ?
            <Loader />
            :
            state.data.length === 0 ?
                <NoDataFound>
                    <div>
                        Няма намерена информация!
                    </div>
                    <br />
                    <Button variant="outline-dark" onClick={e => handleAdd()} disabled={auth.getUser().permission('accounts_edit') === false}>
                        Създай сметка
                    </Button>
                </NoDataFound>
                :
                <>
                    <Button variant="outline-dark" onClick={e => handleAdd()} disabled={auth.getUser().permission('accounts_edit') === false}>
                        Създай сметка
                    </Button>

                    {/* <Button variant="outline-dark" onClick={e => () => { }} style={{ marginLeft: '5px' }}>
                        Създай фактура (TODO)
                    </Button> */}

                    <Table className="big mt-3" size="sm" striped ref={tableRef}>
                        <TableHeader
                            tableRef={tableRef}
                            activeSortKey={state.filter.sort}
                            activeSortDir={state.filter.order}
                            onSort={(col, dir) => handleSort(col, dir)}
                        >
                            <TableCol sortable sortKey="id" style={{ width: '5%' }}>ID</TableCol>
                            <TableCol sortable sortKey="created_at" className="text-left">Отворена</TableCol>
                            <TableCol sortable sortKey="closed_at" className="text-left">Затворена</TableCol>
                            <TableCol sortable sortKey="creator" className="text-right">Потребител</TableCol>
                            <TableCol sortable sortKey="client" className="text-left">Клиент</TableCol>
                            <TableCol sortable sortKey="discount" className="text-right">Отстъпка</TableCol>
                            <TableCol sortable sortKey="total_sum" className="text-right">Базова сума</TableCol>
                            <TableCol sortable sortKey="total_real" className="text-right">Крайна сума</TableCol>
                            <TableCol sortable sortKey="total_paid_sum" className="text-right">Платено</TableCol>
                            <TableCol sortable sortKey="paymethod" className="text-center">Начин на плащане</TableCol>
                            <TableCol className="options">Опции</TableCol>
                        </TableHeader>
                        <tbody>
                            {state.data.map((r, i) =>
                                <tr key={i}>
                                    <td>
                                        {r.id}
                                    </td>
                                    <td className="text-left">
                                        {r?.created_at ? moment(r.created_at).format('DD.MM.YYYY HH:mm') : ''}
                                    </td>
                                    <td className="text-left">
                                        {r?.closed_at ? moment(r.closed_at).format('DD.MM.YYYY HH:mm') : ''}
                                    </td>
                                    <td>
                                        {r.creator?.username}
                                    </td>
                                    <td>
                                        <span className="link" onClick={e => showClient(r.client_id)}>
                                            {r.client_name || r?.client?.name}
                                        </span>
                                    </td>
                                    <td className="text-right">
                                        <PriceValue>{r.discount}</PriceValue>%
                                    </td>
                                    <td className="text-right">
                                        {r?.currency?.prefix} <PriceValue>{r.total_sum}</PriceValue> {r?.currency?.sufix}
                                    </td>
                                    <td className="text-right">
                                        {r?.currency?.prefix} <PriceValue>{r.total_real}</PriceValue> {r?.currency?.sufix}
                                    </td>
                                    <td className="text-right">
                                        {r?.currency?.prefix} <PriceValue>{r.total_paid_sum}</PriceValue> {r?.currency?.sufix}
                                    </td>
                                    <td className="text-center">
                                        {r.paymentmethod?.translation?.name || r.paymentmethod?.name}
                                    </td>
                                    <td className="options">
                                        <MenuOptions>
                                            <Dropdown.Item onClick={e => handleShow(r.id)}>
                                                Преглед
                                            </Dropdown.Item>
                                            {r.allow_edit &&
                                                <Dropdown.Item onClick={e => handleEdit(r.id)}>
                                                    Редактиране
                                                </Dropdown.Item>
                                            }
                                            {r.allow_delete && auth.getUser().permission('clientorders_unlinkaccount') &&
                                                <Dropdown.Item onClick={e => handleDelete(r.id)}>
                                                    Изтриване
                                                </Dropdown.Item>
                                            }
                                        </MenuOptions>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>

                    <Pagination
                        page={state.filter.page}
                        pages={state.pages}
                        handlePage={handlePage}
                    />
                </>
    )
}

export default Index;