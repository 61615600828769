import React, { useState, useEffect } from 'react';
import { Alert as BootstrapAlert } from 'react-bootstrap';
import { CheckCircleFill, ExclamationTriangleFill, InfoCircleFill } from 'react-bootstrap-icons';

const icons = {
    success: CheckCircleFill,
    danger: ExclamationTriangleFill,
    primary: InfoCircleFill,
    warning: ExclamationTriangleFill
}

function Alert(props) {
    const [show, setShow] = useState(false);

    const variant = props.variant || 'primary';
    const title = props.title;
    const body = props.body || props.children;
    const dismissible = props.dismissible || false;

    useEffect(() => {
        setShow(props.show);
    }, [props.show]);

    const hasBoxShadow = () => {
        // ако има модал да няма сянка
        let el = document?.querySelector('.modal-backdrop:not([style="display:none"])');

        if (el) {
            return false;
        }

        return true;
    }

    const icon = icons[variant] || null;

    return (
        <BootstrapAlert variant={variant} show={show} className={props.className} style={{ boxShadow: hasBoxShadow() ? '0 0 10px #d1d1d1' : 'none', ...props.style }} onClose={() => setShow(false)} dismissible={dismissible}>
            {title && <BootstrapAlert.Heading>{title}</BootstrapAlert.Heading>}
            <div className="body" style={{ display: 'flex', alignItems: 'center' }}>
                {icon && React.createElement(icon, {
                    style: {
                        marginRight: '5px'
                    }
                })}
                <div className="text" style={{ width: 'fit-content' }}>
                    {body}
                </div>
            </div>
        </BootstrapAlert>
    );

}

export default Alert;