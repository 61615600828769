import { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react'
import { Button, Modal, Table } from 'react-bootstrap';
import { createPortal } from 'react-dom';

function Vies(props, ref) {

    const [state, setState] = useState({
        show: false,
        data: null,
        onClose: null,
        onSuccess: null,
    });

    useImperativeHandle(ref, () => ({
        open: (data) => {
            handleShow(data);
        },
        close: () => {
            hide();
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        }
    }));

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const handleShow = (data) => {
        setState(prev => ({
            ...prev,
            data: data,
        }));

        show();
    }

    const handleClose = () => {
        setState(prev => ({
            ...prev,
            show: false,
        }));

        if (typeof state.onClose === 'function') {
            hide(state.onClose);
        } else {
            hide();
        }

        setState(prev => ({
            ...prev,
            onClose: null,
        }));
    }

    const handleSave = () => {
        if (typeof state.onSuccess === 'function') {
            state.onSuccess(state.data);
        }

        handleClose();
    }

    return (
        createPortal(
            <Modal size="md" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Разпознат ДДС номер
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table>
                        <tbody>
                            <tr>
                                <td className="th" style={{ width: '20%' }}>
                                    ДДС №
                                </td>
                                <td>
                                    {state.data?.vatNumber}
                                </td>
                            </tr>
                            <tr>
                                <td className="th">
                                    Име
                                </td>
                                <td>
                                    {state.data?.name}
                                </td>
                            </tr>
                            <tr>
                                <td className="th">
                                    Адрес
                                </td>
                                <td>
                                    {state.data?.address}
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Затвори
                    </Button>
                    <Button className="save" onClick={handleSave}>
                        Използвай
                    </Button>
                </Modal.Footer>
            </Modal>,
            document.body
        )
    )
}

export default forwardRef(Vies);