import { useState, useEffect, useRef } from 'react'
import { Button, Dropdown, Table } from 'react-bootstrap';
import Api from 'helpers/Api';
import Loader from 'components/misc/Loader';
import moment from 'moment';
import NoDataFound from 'components/misc/NoDataFound';
import Pagination from 'components/misc/Pagination';
import TableHeader from 'components/misc/TableHeader';
import TableCol from 'components/misc/TableCol';
import Options from 'components/partials/notes/Options';
import MenuOptions from 'components/misc/MenuOptions';
import PriceValue from 'components/partials/PriceValue';
import { useNavigate } from 'react-router-dom';
import Refs from 'Refs';

function Index(props) {

    const navigate = useNavigate();

    const defaultData = [];

    const tableRef = useRef(null);

    const [state, setState] = useState({
        data: defaultData,
        pages: 0,
        total: 0,
        loading: false,
        filter: {
            page: 1,
        },
        refresh: false,
    });

    useEffect(() => {

        loading(true);

        Api.get('invoices/related', {
            params: {
                id: props.id,
                ...state.filter,
            }
        }).then(res => {
            setState(prev => ({
                ...prev,
                order: res.data.order,
                data: res.data.items,
                total: res.data.total,
                pages: res.data.pages,
                filter: {
                    ...prev.filter,
                    ...res.data.filter,
                },
            }));

            if (res.data.pages > 0 && state.filter.page > res.data.pages) {
                return handlePage({ selected: res.data.pages - 1 });
            }

            loading(false);
            sorting(false);
        });
    }, [props.id, state.refresh]);

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }));
    }

    const sorting = (sorting) => {
        setState(prev => ({
            ...prev,
            sorting: Boolean(sorting)
        }));
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            refresh: new Date()
        }));
    }

    const handleSort = (sort, order) => {
        setState(prev => ({
            ...prev,
            sorting: true,
            filter: {
                ...prev.filter,
                sort: sort,
                order: order,
                page: 1,
            },
            refresh: new Date().getTime()
        }));
    }

    const handleShow = id => {
        props.showInvoice(id);
    }

    const handleEdit = id => {
        navigate('/invoices/' + id + '/edit');
    }

    return (

        state.loading && !state.sorting
            ?
            <Loader />
            :
            state.data.length === 0 ?
                <NoDataFound>
                    <div>
                        Няма намерена информация!
                    </div>
                </NoDataFound>
                :
                <>
                    <Table className="big" size="sm" striped ref={tableRef}>
                        <TableHeader
                            tableRef={tableRef}
                            activeSortKey={state.filter.sort}
                            activeSortDir={state.filter.order}
                            onSort={(col, dir) => handleSort(col, dir)}
                        >
                            <TableCol sortable sortKey="id" style={{ width: '5%' }}>ID</TableCol>
                            <TableCol sortable sortKey="" className="text-left">Вид документ</TableCol>
                            <TableCol sortable sortKey="" className="text-right">Номер</TableCol>
                            <TableCol sortable sortKey="" className="text-left">Дата</TableCol>
                            <TableCol sortable sortKey="" className="text-right">Сума без ДДС</TableCol>
                            <TableCol sortable sortKey="" className="text-right">Сума</TableCol>
                            <TableCol sortable sortKey="" className="text-left">Плащане</TableCol>
                            <TableCol sortable sortKey="" className="text-right">Бр. сметки</TableCol>
                            <TableCol sortable sortKey="status" className="text-center">Статус</TableCol>
                            <TableCol className="options">Опции</TableCol>
                        </TableHeader>
                        <tbody>
                            {state.data.map((r, i) =>
                                <tr key={i}>
                                    <td>
                                        {r.id}
                                    </td>
                                    <td className="text-center" style={{ backgroundColor: r.type?.background, color: r.type?.color }}>
                                        {r.type?.name}
                                    </td>
                                    <td className="text-right">
                                        {r.no}
                                    </td>
                                    <td>
                                        {r.date ? moment(r.date).format('DD.MM.YYYY') : ''}
                                    </td>
                                    <td className="text-right">
                                        {r?.currency?.prefix} <PriceValue>{r.net_price}</PriceValue> {r?.currency?.sufix}
                                    </td>
                                    <td className="text-right">
                                        {r?.currency?.prefix} <PriceValue>{r.gross_price}</PriceValue> {r?.currency?.sufix}
                                    </td>
                                    <td>
                                        {r.paymethod?.name}
                                    </td>
                                    <td className="text-right">
                                        {r.accounts_count}
                                    </td>
                                    <td className={`text-center ${r.deleted ? 'negative-value' : 'positive-value'}`}>
                                        {r.deleted ? 'Анулирана' : 'Издадена'}
                                    </td>
                                    <td className="options">
                                        <MenuOptions>
                                            <Dropdown.Item onClick={e => handleShow(r.id)}>
                                                Преглед
                                            </Dropdown.Item>
                                            {r.allow_edit &&
                                                <Dropdown.Item onClick={e => handleEdit(r.id)}>
                                                    Редактиране
                                                </Dropdown.Item>
                                            }
                                        </MenuOptions>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>

                    <Pagination
                        page={state.filter.page}
                        pages={state.pages}
                        handlePage={handlePage}
                    />
                </>
    )
}

export default Index;