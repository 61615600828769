import { useLocation } from "react-router-dom";

/**
 * 
 * @param {number} index 
 * @returns string|null
 */
export const segment = index => {
    const location = window && window.location;

    if (location) {
        let path = location.pathname;
        let array = path.replace('/', '').split('/');
        let segment = array[index] || '';

        return segment;
    }

    return null;
}

// old
// export const useQuery = (query = null) => {
//     if (query === null) {
//         query = window && window.location.search;
//     }

//     return new URLSearchParams(query);
// }

export const useQuery = (query = null) => {
    const location = useLocation();

    if (query === null) {
        query = location.search;
    }

    return new URLSearchParams(query);
};

/**
 * 
 * @param {URLSearchParams} query 
 * @param {object} object 
 * @returns string
 */
export const buildSearchParams = (query, object, prefix = '') => {
    Object.entries(object).map(filter => {

        let value = filter[1];

        if (Array.isArray(value)) {
            if (prefix) {
                query.delete(prefix + '[' + filter[0] + '][]');

                value.map(val => {
                    query.append(prefix + '[' + filter[0] + '][]', val);
                });
            } else {
                query.delete(filter[0] + '[]');

                value.map(val => {
                    query.append(filter[0] + '[]', val);
                });
            }
        } else if (typeof value === 'object' && value !== null) {
            buildSearchParams(query, value, filter[0]);
        } else {
            query.set(filter[0], filter[1]);
        }
    });

    return decodeURIComponent(query.toString());
};

/**
 * 
 * @param {string} base 
 * @param {object} params 
 * @returns string|null
 */
export const buildUrl = (base, params) => {

    if (typeof base !== 'string') {
        return null;
    }

    const glue = url => {
        return url.indexOf('?') > - 1 ? '&' : '?';
    }

    Object.entries(params).map(p => {
        if (Array.isArray(p[1])) {
            p[1].map(o => {
                base += glue(base) + `${p[0]}[]` + '=' + o;
            });
        } else if (typeof p[1] === 'object') {
            base += glue(base) + p[0] + '=' + JSON.stringify(p[1]);
        } else {
            base += glue(base) + p[0] + '=' + p[1];
        }
    });

    return base;
}