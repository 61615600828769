import { set } from "lodash";
import React from "react";

export function useNestedState(initialState = null) {
    const [state, setState] = React.useState(initialState);

    const setNestedState = (input, value, prefix = null) => {
        // console.log(input, value);

        if (typeof input === 'function') {
            return setState(input);
        }

        // let v = set(state, input, value);

        // console.log(v);

        if (prefix) {
            input = prefix + '.' + input;
        }

        return setState(prev => ({
            ...prev,
            ...set(prev, input, value)
        }));
    }

    return [state, setNestedState];
}