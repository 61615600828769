import React, { forwardRef, useEffect, useState } from "react";
import noImage from 'assets/img/no-image.png';

function FilePreview(props, ref) {
    const [state, setState] = useState({
        src: null,
        type: null,
    });

    const src = props.src;
    const width = props.width || props.height || '100%';
    const height = props.height || props.width || '100%';

    useEffect(() => {
        setState(prev => ({
            ...prev,
            src: src || noImage,
        }))
    }, [props.src]);

    const handleClick = e => {
        e.preventDefault();

        window.open(e.target.src);
    }

    const handleError = e => {
        // e.target.classList.add('hidden')

        setState(prev => ({
            ...prev,
            src: noImage
        }));
    }

    // тук може да се добави load effect
    const handleLoad = e => {
        let img = e.target;

    }

    // console.log(state.src);

    return (
        <picture style={{
            width: width,
            height: height,
            aspectRatio: '1/1',
            backgroundImage: 'url(' + state.src + ')',
            backgroundSize: '500%',
            display: 'inline-block',
            boxShadow: '0 0 5px #bbb9b9',
        }}>
            {React.createElement('img', {
                src: state.src,
                loading: 'lazy',
                onLoad: e => handleLoad(e),
                onError: e => handleError(e),
                onClick: e => handleClick(e),
                style: {
                    objectFit: 'contain',
                    width: '100%',
                    height: '100%',
                    cursor: 'pointer'
                },
                alt: ""
            })}
        </picture>
    )
}

export default forwardRef(FilePreview);