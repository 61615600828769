import { Plus, Dash } from 'react-bootstrap-icons';

function TableCategoryRow(props) {

    const { category, total, expanded, expand } = props;

    const getTitle = (category) => {
        return getArray(category).reverse().join(' > ');
    }

    const getArray = category => {
        let name = [category.name];

        if (category.parent) {
            name = name.concat(
                getArray(category.parent)
            );
        }

        return name;
    }

    return (
        <tr style={{ background: 'cornsilk' }}>
            <td
                colSpan={10}
                style={{
                    padding: '0.3rem',
                    fontSize: '12px',
                    cursor: 'pointer'
                }}
                onClick={() => expand(category.id)}
            >
                {expanded ? <Dash /> : <Plus />} <b style={{ color: '#505050' }}>{getTitle(category)}</b> - <i>{total} ред(а)</i>
            </td>
        </tr>
    )
}

export default TableCategoryRow;