import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import { Modal, Button, Tabs, Tab, FloatingLabel, Form, Nav, Row, Col, Alert } from 'react-bootstrap';
import { TrashFill } from 'react-bootstrap-icons';

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//providers
import { useLanguageContext } from 'providers/Language'

//misc
import SaveButton from 'components/misc/Button'
import Info from 'components/modals/Info';
import Autocomplete from 'components/misc/Autocomplete';

function AddOrEdit(props, ref) {

    const langs = useLanguageContext();

    const defaultData = {
        langs: {},
        articles: [],
        vals: []
    }

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        edit: false,
        data: defaultData,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null
    });

    const [validations, setValidations] = useValidation();
    const validationModalRef = useRef(null);

    useImperativeHandle(ref, () => ({
        add: () => {
            handleShow();
        },
        edit: (id) => {
            edit(id);
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        }
    }));

    useEffect(() => {
        loadData();
    }, [state.edit]);

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const handleShow = () => {
        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
        }));

        show();
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            initialData: defaultData,
            id: null,
        }));

        setValidations(null);

        hide();
    }

    const edit = id => {
        setState(prev => ({
            ...prev,
            id: id,
            edit: new Date().getTime()
        }));

        show();
    }

    const loadData = () => {
        if (!state.id) {
            return;
        }

        Api.get('groups/articles/show?id=' + state.id)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data
                }))
            });
    }


    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = state.id ? 'groups/articles/update' : 'groups/articles/add';

        let data = new FormData(e.target);

        if (state.id) {
            data.append('id', state.id)
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (typeof state.onSuccess === 'function') {
                state.onSuccess(res.data)
            }

            hide()
        }).catch(error => {
            const _err = error.response;
            if (_err && _err.status && _err.status === 422) {
                hide(() => {
                    let modal = validationModalRef.current;

                    if (modal) {
                        modal.open();

                        modal.onClose(() => {
                            setValidations(_err.data.errors)
                            show();
                        });
                    }
                });
            }
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }));
        });
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const addDetail = () => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                articles: prev.data.articles.concat({})
            }
        }));
    }

    const removeDetail = (index) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                articles: prev.data.articles.filter((d, i) => Number(i) !== Number(index))
            }
        }));
    }

    const handleArticleAdd = (data, index) => {
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                articles: Object.values({
                    ...prev.data.articles,
                    [index]: data
                })
            }
        }));
    }

    return (
        <>
            <Info
                ref={validationModalRef}
                mainMessage="Моля, попълнете всички задължителни полета, означени с червен цвят!"
            />
            <Modal size="lg" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <form onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Група артикули
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <FloatingLabel className="mb-3" label="Име*">
                            <Form.Control
                                type="text"
                                name={`name`}
                                placeholder="Име*"
                                onChange={(e) => handleInputChange(e)}
                                value={state.data?.name || ''}
                                isInvalid={Boolean(validations && validations.name)}
                            />

                            <Form.Control.Feedback type="invalid">
                                {validations && validations.name}
                            </Form.Control.Feedback>
                        </FloatingLabel>

                        <FloatingLabel className="mb-3" label="Описание">
                            <Form.Control
                                as="textarea"
                                name={`description`}
                                placeholder="Описание"
                                onChange={(e) => handleInputChange(e)}
                                value={state.data?.description || ''}
                                isInvalid={Boolean(validations && validations.description)}
                            />

                            <Form.Control.Feedback type="invalid">
                                {validations && validations.description}
                            </Form.Control.Feedback>
                        </FloatingLabel>


                        <Col class="mb-3">
                            {state.data.articles.map((article, index) =>
                                <Row key={'d-' + index}>
                                    <Col className="mb-3">
                                        <Autocomplete
                                            url="autocomplete/articles"
                                            inputPlaceholder="Артикул"
                                            inputIdName={`article_id[]`}
                                            inputValue={article.article_name || ''}
                                            inputIdValue={article.id || ''}
                                            error={Boolean(validations?.article_id)}
                                            helperText={validations?.article_id && (validations?.article_id[0] || validations?.article_id)}
                                            onChange={data => handleArticleAdd(data, index)}
                                            renderText={data => {
                                                return (
                                                    <div style={{ display: 'flex', width: '100%' }}>
                                                        <div style={{ width: 'max-content', marginRight: '10px' }}>
                                                            {data.id}
                                                        </div>
                                                        <div>
                                                            {data.name}
                                                            <br />
                                                            {data.article_name}
                                                        </div>
                                                    </div>
                                                )
                                            }}
                                            renderInputText={data => data.article_name}
                                        />
                                    </Col>

                                    <Col className="mb-3">
                                        <FloatingLabel label="Продажна цена">
                                            <Form.Control
                                                type="text"
                                                disabled={true}
                                                value={article.actual_price || ''}
                                            />
                                        </FloatingLabel>
                                    </Col>

                                    <Col className="mb-3">
                                        <FloatingLabel label="Единица продажба">
                                            <Form.Control
                                                type="text"
                                                disabled={true}
                                                value={article.amount_unit || ''}
                                            />
                                        </FloatingLabel>
                                    </Col>

                                    <Col className="mb-3" style={{ display: 'flex', alignItems: 'center' }} xs="auto">
                                        <Button variant="danger" size="sm" onClick={e => removeDetail(index)}>
                                            <TrashFill />
                                        </Button>
                                    </Col>
                                </Row>
                            )}
                            <Button variant="outline-dark" size="sm" onClick={addDetail}>
                                Добави нова стойност
                            </Button>
                        </Col>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            className="save"
                        />
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default forwardRef(AddOrEdit);
