import Protocol from "components/pages/protocols/view/View";
import Warranty from "components/pages/warranty/view/View";
import Contract from "components/pages/contracts/view/View";

import { forwardRef, useImperativeHandle, useRef } from "react";

function DocumentViewGroup(props, ref) {
    const protocolModalRef = useRef(null);
    const warrantyModalRef = useRef(null);
    const contractModalRef = useRef(null);

    useImperativeHandle(ref, () => ({
        open: (data, cb) => {
            let ref = null;

            let type = data.type?.code;

            switch (type) {
                case 'protocol':
                    ref = protocolModalRef;
                    break;
                case 'warranty':
                    ref = warrantyModalRef;
                    break;
                case 'contract':
                    ref = contractModalRef;
                    break;
            }

            if (ref && ref.current) {
                ref.current.open(data.id);

                cb(ref.current);
            }
        },
    }));

    return (
        <>
            <Protocol
                ref={protocolModalRef}
            />
            <Warranty
                ref={warrantyModalRef}
            />
            <Contract
                ref={contractModalRef}
            />
        </>
    )
}

export default forwardRef(DocumentViewGroup);