import { useEffect, useRef, useState } from 'react';
import Api from 'helpers/Api';
import { buildSearchParams, buildUrl, useQuery } from 'helpers/Url';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Alert, Button, Table, OverlayTrigger, Tooltip, Dropdown } from 'react-bootstrap';
import moment from 'moment';

import Options from './partials/Options';

import Question from 'components/modals/Question';
import Filter from './partials/Filter';
import Layout from 'components/layout/Layout';
import View from './view/View';
import New from './partials/New';

//misc
import MenuOptions from 'components/misc/MenuOptions'
import Pagination from 'components/misc/Pagination'
import Loader from 'components/misc/Loader';
import NoDataFound from 'components/misc/NoDataFound';
import { useAuthDataContext } from 'providers/Auth';
import TableHeader from 'components/misc/TableHeader';
import TableCol from 'components/misc/TableCol';
import PriceValue from 'components/partials/PriceValue';
import { useAppContext } from 'providers/App';
import SendMail from './partials/SendMail';
import TableOptions from 'components/misc/TableOptions';
import DynamicTableCol from 'components/partials/table/DynamicTableCol';
import DynamicTableBodyCol from 'components/partials/table/DynamicTableBodyCol';
import axios from 'axios';

let timeout;

function Index() {

    const location = useLocation();
    const navigate = useNavigate();
    const query = useQuery();
    const auth = useAuthDataContext();
    const app = useAppContext();

    const tableRef = useRef(null);
    const deleteModalRef = useRef(null);
    const viewModalRef = useRef(null);
    const newModalRef = useRef(null);
    const sendMailModalRef = useRef(null);

    const [state, setState] = useState({
        sorting: false,
        loading: true,
        refresh: false,
        setFilter: false,
        currency: null,
        data: {},
        totals: {},
        headings: {},
        types: {},
        pages: 0,
        total: 0,
        filter: {
            page: 1,
        },
        columns: {
            all: {},
            details: {},
            selected: [],
            sortable: [],
            sort: null,
            order: null,
        },
        tableKey: '',
        request: null,
        onDataLoaded: null
    });

    const [selected, setSelected] = useState([]);

    useEffect(() => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: query.get('page') || 1,
                search: query.get('search') || '',
                type_id: query.getAll('type_id[]') || [],
                client_id: query.get('client_id') || '',
                company_id: query.getAll('company_id[]') || [],
                company_name: query.getAll('company_name[]') || [],
                article_id: query.get('article_id') || '',
                created_from_date: query.get('created_from_date') || '',
                created_to_date: query.get('created_to_date') || '',
                creator_id: query.get('creator_id') || '',
                account_id: query.get('account_id') || '',
                store_id: query.getAll('store_id[]') || [],
                paymethod_id: query.getAll('paymethod_id[]') || [],
                deleted: query.get('deleted') || '',
                sort: query.get('sort') || '',
                order: query.get('order') || '',
            },
            refresh: new Date().getTime()
        }))
    }, [location.search]);

    useEffect(() => {
        if (state.setFilter) {
            navigate('?' + buildSearchParams(query, state.filter));
        }

    }, [state.setFilter]);

    useEffect(() => {
        if (state.refresh) {
            loadData();
        }
    }, [state.refresh]);

    const loadData = () => {

        if (state.request) {
            state.request.cancel();
        }

        let request = axios.CancelToken.source();

        setState(prev => ({
            ...prev,
            request: request,
            loading: true,
        }));

        let url = 'invoices/all';

        Api.get(url, {
            params: state.filter,
            cancelToken: request.token
        }).then(res => {

            if (typeof state.onDataLoaded === 'function') {
                state.onDataLoaded(res.data);
            }

            setState(prev => ({
                ...prev,
                currency: res.data.currency,
                data: res.data.items,
                total: res.data.total,
                pages: res.data.pages,
                totals: res.data.totals,
                headings: res.data.headings,
                types: res.data.types,
                columns: res.data.columns,
                tableKey: res.data.tableKey,
                loading: false,
                sorting: false,
                onDataLoaded: null,
                filter: {
                    ...prev.filter,
                    ...res.data.filter,
                }
            }));

            if (res.data.pages > 0 && state.filter.page > res.data.pages) {
                return handlePage({ selected: res.data.pages - 1 });
            }
        });
    }

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }));
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: reset ? 1 : prev.filter.page
            },
            setFilter: new Date().getTime(),
            refresh: new Date().getTime(),
        }));
    }

    const refreshTable = () => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                sort: '',
                order: '',
                page: 1
            },
            setFilter: new Date().getTime(),
            refresh: new Date().getTime(),
        }));
    }

    const handleAdd = () => {
        navigate('/invoices/new');
    }

    // update
    const handleEdit = data => {
        if (data.deleted) {
            return;
        }

        navigate('/invoices/' + data.invoice_id + '/edit');
    }

    // delete
    const handleDelete = data => {
        let modal = deleteModalRef.current;

        modal.open();
        modal.onSuccess(() => {
            handleDestroy(data.id);
        })
    }

    const handleDestroy = id => {
        Api.post('invoices/delete', {
            id: id
        }).then(res => {
            if (res.data.success) {
                refresh(false);
            }
        });
    }

    const handleRestore = data => {
        Api.post('invoices/restore', {
            id: data.invoice_id
        }).then(res => {
            if (res.data.success) {
                refresh(false);
            }
        });
    }

    const handleShow = id => {
        let modal = viewModalRef.current;

        modal.open(id, false);
    }

    const handleView = data => {
        let modal = viewModalRef.current;

        modal.open(data.invoice_id);
    }

    // Search
    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout);

        if (typeof key === 'object') {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    ...key
                },
            }));
        } else {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    [key]: val,
                },
            }));
        }

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: 1
                },
                setFilter: new Date().getTime()
            }));
        }, delay);
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            setFilter: new Date().getTime()
        }));
    }

    const sorting = (sorting) => {
        setState(prev => ({
            ...prev,
            sorting: Boolean(sorting)
        }));
    }

    const handleSort = (sort, order) => {
        setState(prev => ({
            ...prev,
            sorting: true,
            filter: {
                ...prev.filter,
                sort: sort,
                order: order,
                page: 1,
            },
            setFilter: new Date().getTime()
        }));
    }

    const handleAddFromAccount = () => {
        let modal = newModalRef.current;

        if (!modal) {
            return;
        }

        modal.open();
    }

    const handleSend = () => {
        if (selected.length === 0) {
            return app.showWarning('Изберете поне 1 документ');
        }

        let modal = sendMailModalRef.current;

        if (!modal) {
            return;
        }

        modal.show(selected);
    }

    const handlePrint = (copy = false, withCopy = false) => {
        if (selected.length === 0) {
            return app.showWarning('Изберете поне 1 документ');
        }

        let params = new URLSearchParams();

        selected.map(acc => {
            params.append('ids[]', acc);
        });

        params.set('copy', Number(copy));
        params.set('withcopy', Number(withCopy));

        const url = process.env.REACT_APP_URL + '/api/invoices/bulk-stream?' + params.toString();

        window.open(url);
    }

    const toggleRow = (id) => {
        if (isSelected(id)) {
            setSelected(prev => prev.filter(i => Number(i) !== Number(id)));
        } else {
            setSelected(prev => prev.concat(Number(id)));
        }
    }

    const isSelected = id => {
        return selected.indexOf(Number(id)) > -1;
    }

    const toggleAll = () => {
        if (isAllSelected()) {
            setSelected([]);
        } else {
            setSelected(state.data.map(c => Number(c.id)));
        }
    }

    const isAllSelected = () => {
        let selected = true;

        state.data.map(c => {
            if (!isSelected(c.id)) {
                selected = false;
            }
        });

        return selected;
    }

    const showNextItem = (id) => {
        let table = tableRef.current;

        if (!table) {
            return;
        }

        // console.log(table);

        let thisRow = table.querySelector('tr[data-id="' + id + '"]');
        let nextRow = thisRow.nextSibling;

        if (nextRow && nextRow.getAttribute('data-id')) {
            showItem(
                nextRow.getAttribute('data-id')
            );
        } else {
            showNextPage();
        }
    }

    const showPrevItem = (id) => {
        let table = tableRef.current;

        if (!table) {
            return;
        }

        // console.log(table);

        let thisRow = table.querySelector('tr[data-id="' + id + '"]');
        let prevRow = thisRow.previousSibling;

        if (prevRow) {
            showItem(
                prevRow.getAttribute('data-id')
            );
        } else {
            showPrevPage();
        }
    }

    const showNextPage = () => {
        if (state.pages > state.filter.page) {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: Number(state.filter.page) + 1,
                },
                onDataLoaded: data => {
                    setTimeout(() => {
                        showItem(data.items[0].invoice_id);
                    }, 0)
                },
                setFilter: new Date().getTime(),
            }));
        }
    }

    const showPrevPage = () => {
        if (state.filter.page > 1) {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: Number(state.filter.page) - 1,
                },
                onDataLoaded: data => {
                    setTimeout(() => {
                        showItem(data.items[data.items.length - 1].invoice_id);
                    }, 0)
                },
                setFilter: new Date().getTime(),
            }));
        }
    }

    const showItem = id => {
        let table = tableRef.current;

        if (!table) {
            return;
        }

        let row = table.querySelector('tr[data-id="' + id + '"]');

        if (row) {
            row.click();

            handleShow(id);
        }
    }

    return (
        <>

            <Question
                ref={deleteModalRef}
                mainMessage="Сигурни ли сте?"
                agreeBtnText="Изтриване"
                agreeBtnClass="remove"
            />

            <View
                ref={viewModalRef}
                showNextItem={id => showNextItem(id)}
                showPrevItem={id => showPrevItem(id)}
            />

            <New
                ref={newModalRef}
            />

            <SendMail
                ref={sendMailModalRef}
            />

            <Layout>
                <div className="container-fluid">

                    <div className="page-head">
                        <h1 className="display-6">
                            Списък с платежни документи
                        </h1>

                        <div className="buttons">
                            <Button variant="outline-dark" onClick={handleAdd} disabled={auth.getUser().permission('invoices_create') === false}>
                                Нова фактура
                            </Button>

                            <Button variant="outline-dark" onClick={handleAddFromAccount} disabled={auth.getUser().permission('invoices_create') === false} style={{ marginLeft: '5px' }}>
                                Нова фактура по сметка(и)
                            </Button>
                        </div>
                    </div>

                    <br />

                    <div className="panel">
                        <Filter
                            filter={state.filter}
                            handleSearch={handleSearch}
                        />

                        {state.loading && !state.sorting
                            ?
                            <Loader />
                            :
                            state.data.length === 0 ?
                                <NoDataFound />
                                :
                                <>
                                    <div className="pagination-with-options mb-3">
                                        <Pagination
                                            page={state.filter.page}
                                            pages={state.pages}
                                            handlePage={handlePage}
                                        />

                                        <TableOptions
                                            url="invoices/all"
                                            filter={state.filter}
                                            columns={state.columns}
                                            tableKey={state.tableKey}
                                            refresh={refreshTable}
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <Dropdown drop="down" style={{ display: 'inline' }}>
                                            <Dropdown.Toggle variant="outline-dark" disabled={auth.getUser().permission('invoices_print') === false}>
                                                Печат
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu align="start" style={{ padding: '15px', width: '320px' }}>
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Button size="sm" variant="primary" onClick={e => handlePrint(false, false)}>
                                                        Оригинал
                                                    </Button>
                                                    <Button size="sm" variant="primary" style={{ marginLeft: '5px' }} onClick={e => handlePrint(true, false)}>
                                                        Копие
                                                    </Button>
                                                    <Button size="sm" variant="primary" style={{ marginLeft: '5px' }} onClick={e => handlePrint(false, true)}>
                                                        Оригинал+Копие
                                                    </Button>
                                                </div>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <Button variant="outline-dark" style={{ marginLeft: '5px' }} onClick={handleSend} disabled={auth.getUser().permission('invoices_mail') === false}>
                                            Изпращане
                                        </Button>
                                    </div>

                                    <Table className={Object.entries(state.headings).length > 14 ? 'xxl' : 'big'} responsive striped hover ref={tableRef}>
                                        <TableHeader
                                            tableRef={tableRef}
                                            activeSortKey={state.filter.sort}
                                            activeSortDir={state.filter.order}
                                            onSort={(col, dir) => handleSort(col, dir)}
                                        >
                                            <TableCol className="text-center">
                                                <input
                                                    type="checkbox"
                                                    checked={isAllSelected()}
                                                    onChange={e => toggleAll()}
                                                />
                                            </TableCol>

                                            {Object.entries(state.headings).map((heading, i) =>
                                                <DynamicTableCol
                                                    key={i}
                                                    type={state.types[heading[0]]}
                                                    name={heading[1]}
                                                    title={state.columns.description[heading[0]]}
                                                    sortKey={heading[0]}
                                                    sortable={state.columns.sortable.indexOf(heading[0]) > -1}
                                                />
                                            )}

                                            <TableCol className="options">Опции</TableCol>
                                        </TableHeader>
                                        <tbody>
                                            {state.data.map((c, index) =>
                                                <tr
                                                    key={'c-' + c.invoice_id}
                                                    className={c.deleted ? 'deleted' : ''}
                                                    data-id={c.invoice_id}
                                                >
                                                    <td className="text-center">
                                                        <input
                                                            type="checkbox"
                                                            value={1}
                                                            checked={isSelected(c.id)}
                                                            onChange={e => toggleRow(c.id)}
                                                        />
                                                    </td>

                                                    {Object.entries(state.headings).map((heading, i) =>
                                                        heading[0] === 'id'
                                                            ?
                                                            <td key={heading[0]}>
                                                                <span className="link" onClick={e => handleView(c)}>
                                                                    {c[heading[0]]}
                                                                </span>
                                                            </td>
                                                            :
                                                            heading[0] === 'type'
                                                                ?
                                                                <td key={heading[0]} className="text-center" style={{ backgroundColor: c.invoice?.type?.background, color: c.invoice?.type?.color }}>
                                                                    {c.type}
                                                                </td>
                                                                :
                                                                heading[0] === 'status'
                                                                    ?
                                                                    <td key={heading[0]} className={`text-center ${c.deleted ? 'negative-value' : 'positive-value'}`}>
                                                                        {c.deleted ? 'Анулирана' : 'Издадена'}
                                                                    </td>
                                                                    :
                                                                    <DynamicTableBodyCol
                                                                        key={heading[0]}
                                                                        type={state.types[heading[0]]}
                                                                        name={c[heading[0]]}
                                                                        data={c}
                                                                        currency={state.currency}
                                                                    />
                                                    )}

                                                    <td className="options">
                                                        <MenuOptions>
                                                            <Options
                                                                data={c}
                                                                handleEdit={handleEdit}
                                                                handleDelete={handleDelete}
                                                                handleRestore={handleRestore}
                                                                handleView={handleView}
                                                            />
                                                        </MenuOptions>
                                                    </td>
                                                </tr>
                                            )}
                                            {state.totals.map((c, i) =>
                                                <tr key={i} className="total">
                                                    {Object.entries(c.items).map((heading, i) =>
                                                        <DynamicTableBodyCol
                                                            key={heading[0]}
                                                            type={state.types[heading[0]]}
                                                            name={heading[1]}
                                                            data={c.items}
                                                            currency={state.currency}
                                                            colspan={c.colspan[heading[0]] + 1}
                                                        />
                                                    )}
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>

                                    <Pagination
                                        className="mt-3"
                                        page={state.filter.page}
                                        pages={state.pages}
                                        total={state.total}
                                        handlePage={handlePage}
                                    />
                                </>
                        }

                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Index;
