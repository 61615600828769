import { Dropdown } from 'react-bootstrap';

function Options(props) {

    const allowEdit = typeof props.allow_edit !== 'undefined' ? Boolean(props.allow_edit) : true;
    const allowDelete = typeof props.allow_delete !== 'undefined' ? Boolean(props.allow_delete) : true;

    return (
        <>
            <Dropdown.Item disabled={allowEdit === false} onClick={e => props.handleEdit(props.data.id)}>
                Редакция
            </Dropdown.Item>
            <Dropdown.Item disabled={allowDelete === false} onClick={e => props.handleDelete(props.data.id)}>
                Изтриване
            </Dropdown.Item>
        </>
    )
}

export default Options;