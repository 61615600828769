import { useEffect, useRef, useState } from 'react';
import Api from 'helpers/Api';
import { buildSearchParams, buildUrl, useQuery } from 'helpers/Url';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Button, Table } from 'react-bootstrap';
import { sortable } from 'react-sortable';
import axios from 'axios';

import Options from './partials/Options';

import Question from 'components/modals/Question';
import Import from 'components/modals/Import';
import Filter from './partials/Filter';
import AddOrEdit from './partials/AddOrEdit';
import Article from './view/View';
import MassOperationsColumns from './partials/MassOperationsColumns';
import Layout from 'components/layout/Layout';
import Provider from './Provider';

//misc
import MenuOptions from 'components/misc/MenuOptions'
import Pagination from 'components/misc/Pagination'
import Loader from 'components/misc/Loader';
import NoDataFound from 'components/misc/NoDataFound';
import HelpInfo from 'components/misc/Info';
import Title from 'components/misc/Title';
import FilePreview from '../../partials/FilePreview';
import PriceValue from 'components/partials/PriceValue';
import TableHeader from 'components/misc/TableHeader';
import TableCol from 'components/misc/TableCol';
import TableOptions from 'components/misc/TableOptions';
import DynamicTableCol from 'components/partials/table/DynamicTableCol';
import DynamicTableBodyCol from 'components/partials/table/DynamicTableBodyCol';
import SaveButton from 'components/misc/Button';
import Refs from 'Refs';

import { useAuthDataContext } from 'providers/Auth';
import { ArrowsMove, PencilFill, PencilSquare, PrinterFill, Upload } from 'react-bootstrap-icons';

import { TYPES } from 'constants/imports';

function SortableItem(props) {
    const childProps = props.childProps;

    return (
        <tr {...props} {...childProps}>
            {props.children}
        </tr>
    )
}

const SortItem = sortable(SortableItem);

let timeout;

function Index() {

    const location = useLocation();
    const history = useNavigate();
    const query = useQuery();
    const auth = useAuthDataContext();

    const tableRef = useRef(null);
    const addOrEditModalRef = useRef(null);
    const articleModalRef = useRef(null);
    const deleteModalRef = useRef(null);
    const massOperationsColumnsModalRef = useRef(null);
    const importModalRef = useRef(null);

    const [state, setState] = useState({
        sorting: false,
        loading: true,
        refresh: false,
        setFilter: false,
        data: {},
        headings: {},
        types: {},
        pages: 0,
        total: 0,
        sorted: false,
        filter: {

        },
        columns: {
            all: {},
            details: {},
            selected: [],
            sortable: [],
            sort: null,
            order: null,
        },
        tableKey: '',
        request: null,
        onDataLoaded: null
    });

    const [sortLoading, setSortLoading] = useState(false);

    useEffect(() => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: query.get('page') || 1,
                search: query.get('search') || '',
                ref: query.get('ref') || '',
                category_id: query.getAll('category_id[]') || [],
                detail_value_id: JSON.parse(query.get('detail_value_id')) || {},
                prices: query.getAll('prices[]') || [],
                created_from_date: query.get('created_from_date') || '',
                created_to_date: query.get('created_to_date') || '',
                updated_from_date: query.get('updated_from_date') || '',
                updated_to_date: query.get('updated_to_date') || '',
                barcode: query.get('barcode') || '',
                file: query.get('file') || '',
                deleted: query.get('deleted') || '',
                current_price_less_than_avg_delivery_price: query.get('current_price_less_than_avg_delivery_price') || 0,
                sort: query.get('sort') || '',
                order: query.get('order') || '',
            },
            refresh: new Date().getTime()
        }))
    }, [location.search]);

    useEffect(() => {
        if (state.setFilter) {
            history('?' + buildSearchParams(query, state.filter));
        }

    }, [state.setFilter]);

    useEffect(() => {
        if (state.refresh) {
            loadData();
        }
    }, [state.refresh]);

    useEffect(() => {
        refresh(false);
    }, [auth.getUser()?.getStoreId()]);

    const loadData = () => {

        if (state.request) {
            state.request.cancel();
        }

        let request = axios.CancelToken.source();

        setState(prev => ({
            ...prev,
            request: request,
            loading: true,
        }));

        let url = 'articles/all';

        Api.get(url, {
            params: state.filter,
            cancelToken: request.token
        }).then(res => {

            if (typeof state.onDataLoaded === 'function') {
                state.onDataLoaded(res.data);
            }

            setState(prev => ({
                ...prev,
                data: res.data.items,
                total: res.data.total,
                pages: res.data.pages,
                headings: res.data.headings,
                types: res.data.types,
                columns: res.data.columns,
                tableKey: res.data.tableKey,
                loading: false,
                sorting: false,
                onDataLoaded: null,
                filter: {
                    ...prev.filter,
                    ...res.data.filter,
                }
            }));

            if (res.data.pages > 0 && state.filter.page > res.data.pages) {
                return handlePage({ selected: res.data.pages - 1 });
            }
        });
    }

    const loading = (loading) => {
        setState(prev => ({
            ...prev,
            loading: Boolean(loading)
        }));
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: reset ? 1 : prev.filter.page
            },
            setFilter: new Date().getTime(),
            refresh: new Date().getTime(),
        }));
    }

    const refreshTable = () => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                sort: '',
                order: '',
                page: 1
            },
            setFilter: new Date().getTime(),
            refresh: new Date().getTime(),
        }));
    }

    const handleShow = (id, resetTab = true) => {
        // let modal = Refs.getInstance().getRef('article');
        let modal = articleModalRef.current;

        if (!modal) {
            return;
        }

        modal.open(id, resetTab);

        modal.onSuccess(() => {
            refresh(false);
        });
    }

    const handleAdd = () => {
        let modal = addOrEditModalRef.current;

        modal.add({ resource: false });

        modal.onSuccess(() => {
            refresh(true);
        });
    }

    // update
    const handleEdit = data => {
        if (data.deleted) {
            return;
        }

        let modal = addOrEditModalRef.current;

        modal.edit(data.id, { resource: false });

        modal.onSuccess(() => {
            refresh(false);
        });
    }

    // delete
    const handleDelete = data => {
        let modal = deleteModalRef.current;

        modal.open();
        modal.onSuccess(() => {
            handleDestroy(data.id);
        })
    }

    const handleDestroy = id => {
        Api.post('articles/delete', {
            id: id
        }).then(res => {
            if (res.data.success) {
                refresh(false);
            }
        });
    }

    const handleRestore = data => {
        Api.post('articles/restore', {
            id: data.id
        }).then(res => {
            if (res.data.success) {
                refresh();
            }
        });
    }

    // Search
    const handleSearch = (key, val, delay = 300) => {
        clearTimeout(timeout);

        if (typeof key === 'object') {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    ...key
                },
            }));
        } else {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    [key]: val,
                },
            }));
        }

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: 1
                },
                setFilter: new Date().getTime()
            }));
        }, delay);
    }

    const handlePage = page => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                page: page.selected + 1,
            },
            setFilter: new Date().getTime(),
        }));
    }

    const sorting = (sorting) => {
        setState(prev => ({
            ...prev,
            sorting: Boolean(sorting)
        }));
    }

    const handleSort = (sort, order) => {
        setState(prev => ({
            ...prev,
            sorting: true,
            filter: {
                ...prev.filter,
                sort: sort,
                order: order,
                page: 1,
            },
            setFilter: new Date().getTime()
        }));
    }

    const showPrintList = () => {
        history('/articles-print-list');
    }

    const showMassOperations = () => {
        let modal = massOperationsColumnsModalRef.current;

        if (!modal) {
            return;
        }

        modal.open();

        modal.onSuccess(columns => {
            let url = buildUrl('/articles-mass-update', {
                ...state.filter,
                columns: columns
            });

            history(url);
        });
    }

    const handleImport = () => {
        let modal = importModalRef.current;

        if (!modal) {
            return;
        }

        modal.open();

        modal.onSuccess(() => {
            refresh(true)
        })
    }

    const handleSortArticles = (items) => {
        setState(prev => ({
            ...prev,
            data: items,
            sorted: true
        }));
    }

    const handleSaveSort = e => {
        let items = state.data.map(item => item.article_id);

        setSortLoading(true);

        Api.post('articles/sort', {
            ids: items
        }).then(res => {
            setSortLoading(false);

            setState(prev => ({
                ...prev,
                sorted: false,
            }));
        });
    }

    const showNextItem = (id) => {
        let table = tableRef.current;

        if (!table) {
            return;
        }

        // console.log(table);

        let thisRow = table.querySelector('tr[data-id="' + id + '"]');
        let nextRow = thisRow.nextSibling;

        if (nextRow) {
            showItem(
                nextRow.getAttribute('data-id')
            );
        } else {
            showNextPage();
        }
    }

    const showPrevItem = (id) => {
        let table = tableRef.current;

        if (!table) {
            return;
        }

        // console.log(table);

        let thisRow = table.querySelector('tr[data-id="' + id + '"]');
        let prevRow = thisRow.previousSibling;

        if (prevRow) {
            showItem(
                prevRow.getAttribute('data-id')
            );
        } else {
            showPrevPage();
        }
    }

    const showNextPage = () => {
        if (state.pages > state.filter.page) {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: Number(state.filter.page) + 1,
                },
                onDataLoaded: data => {
                    setTimeout(() => {
                        showItem(data.items[0]?.article_id);
                    }, 0)
                },
                setFilter: new Date().getTime(),
            }));
        }
    }

    const showPrevPage = () => {
        if (state.filter.page > 1) {
            setState(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    page: Number(state.filter.page) - 1,
                },
                onDataLoaded: data => {
                    setTimeout(() => {
                        showItem(data.items[data.items.length - 1]?.article_id);
                    }, 0)
                },
                setFilter: new Date().getTime(),
            }));
        }
    }

    const showItem = id => {
        let table = tableRef.current;

        if (!table) {
            return;
        }

        let row = table.querySelector('tr[data-id="' + id + '"]');

        if (row) {
            row.click();

            handleShow(id, false);
        }
    }

    return (
        <>

            <Provider>
                <AddOrEdit
                    ref={addOrEditModalRef}
                />
                <Article
                    ref={articleModalRef}
                    showNextItem={id => showNextItem(id)}
                    showPrevItem={id => showPrevItem(id)}
                />
            </Provider>

            <Question
                ref={deleteModalRef}
                mainMessage="Внимание! При изтриване на този артикул ще бъдат изтрити и всички свързани с него продукти!"
                agreeBtnText="Изтриване"
                agreeBtnClass="remove"
            />

            <MassOperationsColumns
                ref={massOperationsColumnsModalRef}
            />

            <Import
                ref={importModalRef}
                type={TYPES.ARTICLE}
                onSuccess={() => refresh(true)}
            />

            <Layout>
                <div className="container-fluid">

                    <div className="page-head">
                        <h1 className="display-6">
                            Артикули
                        </h1>

                        <div className="buttons">
                            <Button variant="outline-dark" onClick={handleAdd} disabled={auth.getUser().permission('articles_edit') === false}>
                                Добави
                            </Button>
                            <Button variant="outline-dark" onClick={handleImport} disabled={auth.getUser().permission('import_index') === false} style={{ marginLeft: '5px' }}>
                                <Upload /> Импорт
                            </Button>
                        </div>
                    </div>

                    <br />

                    <div className="panel">
                        <Filter
                            filter={state.filter}
                            handleSearch={handleSearch}
                        />

                        {state.loading
                            ?
                            <Loader />
                            :
                            state.data.length === 0 ?
                                <NoDataFound />
                                :
                                <>
                                    <div className="pagination-with-options mb-3">
                                        <Pagination
                                            page={state.filter.page}
                                            pages={state.pages}
                                            handlePage={handlePage}
                                        />

                                        <TableOptions
                                            url="articles/all"
                                            filter={state.filter}
                                            columns={state.columns}
                                            tableKey={state.tableKey}
                                            refresh={refreshTable}
                                        >
                                            {/* <Button
                                                variant="dark"
                                                // size="sm"
                                                onClick={showPrintList}
                                            >
                                                <PrinterFill /> <span> Печат на етикети</span>
                                            </Button> */}

                                            <Button
                                                variant="dark"
                                                // size="sm"
                                                onClick={showMassOperations}
                                                disabled={auth.getUser().permission('articles_edit') === false}
                                                style={{ marginLeft: '10px' }}
                                            >
                                                <PencilSquare /> <span> Масови корекции</span>
                                            </Button>
                                        </TableOptions>
                                    </div>

                                    <Table className={Object.entries(state.headings).length > 14 ? 'xxl' : 'big'} responsive striped hover ref={tableRef}>
                                        <TableHeader
                                            tableRef={tableRef}
                                            activeSortKey={state.filter.sort}
                                            activeSortDir={state.filter.order}
                                            onSort={(col, dir) => handleSort(col, dir)}
                                        >
                                            {Object.entries(state.headings).map((heading, i) =>
                                                <DynamicTableCol
                                                    key={i}
                                                    type={state.types[heading[0]]}
                                                    name={heading[1]}
                                                    title={state.columns.description[heading[0]]}
                                                    sortKey={heading[0]}
                                                    sortable={state.columns.sortable.indexOf(heading[0]) > -1}
                                                />
                                            )}
                                            <th className="text-center" style={{ width: '5%' }}>Опции</th>
                                        </TableHeader>
                                        <tbody>
                                            {state.data.map((c, index) =>
                                                <SortItem
                                                    key={index}
                                                    childProps={{
                                                        'className': c.deleted ? 'deleted' : '',
                                                        'data-article-id': c.article_id
                                                    }}
                                                    items={state.data}
                                                    onSortItems={handleSortArticles}
                                                    sortId={index}

                                                >
                                                    {Object.entries(state.headings).map((heading, i) =>
                                                        <DynamicTableBodyCol
                                                            key={heading[0]}
                                                            type={state.types[heading[0]]}
                                                            name={c[heading[0]]}
                                                            data={c}
                                                            currency={state.currency}
                                                            handleShowArticle={handleShow}
                                                        // onArticleUpdate={() => refresh(false)}
                                                        />
                                                    )}

                                                    <td className="text-center">
                                                        <div style={{ display: 'flex' }}>
                                                            <MenuOptions>
                                                                <Options
                                                                    data={c}
                                                                    handleShow={handleShow}
                                                                    handleEdit={handleEdit}
                                                                    handleDelete={handleDelete}
                                                                    handleRestore={handleRestore}
                                                                />
                                                            </MenuOptions>
                                                            <Button variant="outline" size="sm" style={{ marginLeft: '5px', cursor: 'move' }} onClick={e => e.preventDefault()}>
                                                                <ArrowsMove />
                                                            </Button>
                                                        </div>
                                                    </td>
                                                </SortItem>
                                            )}
                                        </tbody>
                                    </Table>

                                    <Pagination
                                        className="mt-3"
                                        page={state.filter.page}
                                        pages={state.pages}
                                        total={state.total}
                                        handlePage={handlePage}
                                    />
                                </>
                        }

                    </div>
                </div>

                {state.sorted ?
                    <div className="baloon-selected">
                        <SaveButton
                            // variant="primary" 
                            loading={sortLoading}
                            onClick={handleSaveSort}

                        >
                            Запази подреждането
                        </SaveButton>
                    </div>
                    : ''
                }
            </Layout>
        </>
    )
}

export default Index;
