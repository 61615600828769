import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import { Modal, Button, Col, Row, Nav, FloatingLabel, Form, } from 'react-bootstrap';

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//providers
import { useLanguageContext } from 'providers/Language'

//misc
import Question from 'components/modals/Question';
import SaveButton from 'components/misc/Button'
import Info from 'components/modals/Info';
import Autocomplete from 'components/misc/Autocomplete';
import HelpInfo from 'components/misc/Info';

function AddOrEdit(props, ref) {

    const langs = useLanguageContext();

    const defaultData = {

    }

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        edit: false,
        refresh: false,
        data: defaultData,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null,
        onClose: null,
    });

    const [validations, setValidations] = useValidation();

    useImperativeHandle(ref, () => ({
        add: (id, type) => {
            open(id, type);
        },
        edit: (id) => {
            edit(id);
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        }
    }));

    useEffect(() => {
        loadData();
    }, [state.edit]);

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const open = (id, type) => {
        setState(prev => ({
            ...prev,
            data: defaultData,
            subjectId: id,
            subjectType: type
        }));

        show();
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
            subjectId: null,
            subjectType: null
        }));

        setValidations(null);

        if (typeof state.onClose === 'function') {
            hide(state.onClose);
        } else {
            hide();
        }
    }

    const edit = id => {
        setState(prev => ({
            ...prev,
            id: id,
            edit: new Date().getTime()
        }));

        show();
    }

    const refresh = (reset = true) => {
        setState(prev => ({
            ...prev,
            refresh: new Date().getTime(),
        }));
    }

    const loadData = () => {
        if (!state.id) {
            return;
        }

        Api.get('notes/show?id=' + state.id)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data,
                }))
            });
    }

    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = state.id ? 'notes/edit' : 'notes/add';

        let data = new FormData(e.target);

        if (state.id) {
            data.append('id', state.id)
        }

        if (state.subjectId) {
            data.append('subject_id', state.subjectId);
        }

        if (state.subjectType) {
            data.append('subject_type', state.subjectType);
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (typeof state.onSuccess === 'function') {
                state.onSuccess(res.data);
            }

            hide(state.onClose);
        }).catch(error => {
            const _err = error.response;

            if (_err && _err.status && _err.status === 422) {
                setValidations(_err.data.errors);
            }
        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }));
        });
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleDateChange = e => {
        const name = e.target.name + '_ymd'
        const value = e.target.value

        setState(name, value, 'data')
    }

    return (
        <>

            <Modal size="md" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <form onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Бележка
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Row>
                            <Col className="mb-3">
                                <FloatingLabel label="Заглавие">
                                    <Form.Control
                                        type="text"
                                        name={`title`}
                                        autoComplete="new-password"
                                        value={state.data.title || ''}
                                        placeholder="Заглавие"
                                        onChange={(e) => handleInputChange(e)}
                                        isInvalid={Boolean(validations?.title)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {validations?.title && (validations?.title[0] || validations?.title)}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <FloatingLabel label="Текст">
                                    <Form.Control
                                        type="text"
                                        as="textarea"
                                        name={`text`}
                                        autoComplete="new-password"
                                        value={state.data.text || ''}
                                        placeholder="Текст"
                                        onChange={(e) => handleInputChange(e)}
                                        isInvalid={Boolean(validations?.text)}
                                        rows={6}
                                        style={{
                                            height: '150px'
                                        }}
                                    />

                                    <Form.Control.Feedback type="invalid">
                                        {validations?.text && (validations?.text[0] || validations?.text)}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            className="save"
                        />
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default forwardRef(AddOrEdit);
