import { useValidation } from 'helpers/Validation';
import { useState } from 'react'
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function Search(props) {

    const navigate = useNavigate();

    const defaultData = {

    };

    const [state, setState] = useState({
        data: defaultData,
    });

    const [validations, setValidations] = useValidation();


    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: value
            }
        }));
    }

    const handleSave = (e) => {
        e.preventDefault();

        setValidations(null);

        if (!state.data.keyword) {
            setValidations({
                keyword: 'Въведете ключова дума'
            });

            return;
        }

        navigate('/search?keyword=' + state.data.keyword);

    }

    return (
        <>
            <Form onSubmit={handleSave}>
                <Form.Control
                    type="text"
                    name="keyword"
                    placeholder="Търсене във всичко"
                    value={state.data.keyword || ''}
                    onChange={handleInputChange}
                    isInvalid={Boolean(validations?.keyword)}
                />
            </Form>
        </>
    )
}

export default (Search);