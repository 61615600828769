import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

//plugins
import moment from 'moment'
import { Modal, Button, Tabs, Tab, FloatingLabel, Form, Row, Col, Nav, Alert } from 'react-bootstrap';

//helpers
import Api from 'helpers/Api'
import { useValidation } from 'helpers/Validation'
import { useNestedState } from 'helpers/NestedState'

//misc
import SaveButton from 'components/misc/Button'
import HelpInfo from 'components/misc/Info'
import Info from 'components/modals/Info'
import StoreAndDepot from 'components/partials/filter/StoreAndDepot';
import Autocomplete from 'components/misc/Autocomplete';
import DatePicker from 'components/partials/filter/DatePicker';

function AddOrEdit(props, ref) {

    const defaultData = {
        with_intrastat_num: 0
    }

    const [state, setState] = useNestedState({
        show: false,
        id: null,
        edit: false,
        data: defaultData,
        loading: false,
        onSuccess: null,
        onEntering: null,
        onExiting: null,
        mainTabId: 'main',
    });

    const [selectedDepots, setSelectedDepots] = useState([]);
    const [selectedSuppliers, setSelectedSuppliers] = useState([]);

    const [partyTypes, setPartyTypes] = useState([]);

    const [validations, setValidations] = useValidation();

    const validationModalRef = useRef(null);

    useImperativeHandle(ref, () => ({
        add: () => {
            handleShow();
        },
        edit: (id) => {
            edit(id);
        },
        hide: () => {
            hide();
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        }
    }));

    useEffect(() => {
        Api.get('intrastats/party-types')
            .then(res => {
                if (Array.isArray(res.data)) {
                    setPartyTypes(res.data);
                }
            });
    }, []);

    useEffect(() => {
        if (state.show) {
            loadData();
        }
    }, [state.show]);

    const show = (onEntering = null) => {
        setState(prev => ({
            ...prev,
            show: true,
            onEntering: onEntering,
        }));
    }

    const hide = (onExiting = null) => {
        setState(prev => ({
            ...prev,
            show: false,
            onExiting: onExiting
        }));
    }

    const handleShow = () => {
        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
            mainTabId: 'main',
        }));

        show();
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault();
        }

        setState(prev => ({
            ...prev,
            data: defaultData,
            id: null,
        }));

        setValidations(null);

        hide();
    }

    const edit = id => {
        setState(prev => ({
            ...prev,
            id: id,
            edit: new Date().getTime()
        }));

        show();
    }

    const loadData = () => {
        let url;

        if (state.id) {
            url = 'intrastats/show?id=' + state.id;
        } else {
            url = 'intrastats/latest-data';
        }

        Api.get(url)
            .then(res => {
                let data = res.data;

                let depots = res.data.depots.map(d => d.id);
                let suppliers = res.data.suppliers.map(d => d.id);

                setState(prev => ({
                    ...prev,
                    mainTabId: 'main',
                    data: data
                }));

                setSelectedDepots(depots);
                setSelectedSuppliers(suppliers);
            });

    }

    const handleSave = e => {
        e.preventDefault();

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = state.id ? 'intrastats/edit' : 'intrastats/add';

        let data = new FormData(e.target);

        if (state.id) {
            data.append('id', state.id)
        }

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            if (typeof state.onSuccess === 'function') {
                state.onSuccess(res.data)
            }
            hide()
        }).catch(error => {
            const _err = error.response;
            if (_err && _err.status && _err.status === 422) {
                hide(() => {
                    let modal = validationModalRef.current;

                    if (modal) {
                        modal.open();

                        modal.onClose(() => {
                            setValidations(_err.data.errors)
                            show();
                        });
                    }
                });
            }

        }).finally(() => {
            setState(prev => ({
                ...prev,
                loading: false,
            }));
        });
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setState(name, value, 'data')
    }

    const handleCheckboxChange = e => {
        const name = e.target.name;
        setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: prev.data[name] ? false : true
            }
        }));
    }

    const handleDepotChange = selected => {
        setSelectedDepots(selected);
    }

    const handleChangeSupplier = selected => {
        console.log(selected);
    }

    const setMainTab = key => {
        setState(prev => ({
            ...prev,
            mainTabId: key
        }));
    }

    const handleMainTabValidate = () => {
        let valid = false;

        let main = [
            'period',
            'declaration_id',
            'declaration_psi_id',
            'declaration_psi_type_id',
        ];

        if (validations) {
            main.map(t => {
                if (validations[t] && state.mainTabId !== 'main') {
                    valid = true
                }
            })
        }

        return valid;
    }

    const handleSecondTabValidate = () => {
        let valid = false;

        let main = [
            'party_id',
            'party_type_id',
        ];

        if (validations) {
            main.map(t => {
                if (validations[t] && state.mainTabId !== 'tab2') {
                    valid = true
                }
            })
        }

        return valid;
    }

    const handleThirdTabValidate = () => {
        let valid = false;

        let main = [
            'party_contact_person_name',
        ];

        if (validations) {
            main.map(t => {
                if (validations[t] && state.mainTabId !== 'tab3') {
                    valid = true
                }
            })
        }

        return valid;
    }

    return (
        <>
            <Info
                ref={validationModalRef}
                mainMessage="Моля, попълнете всички задължителни полета, означени с червен цвят!"
            />

            <Modal size="lg" centered show={state.show} onHide={handleClose} onEntering={state.onEntering} onExiting={state.onExiting}>
                <form onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Интрастат декларация
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Tab.Container activeKey={state.mainTabId} onSelect={key => setMainTab(key)}>
                            <Row>
                                <Col xl={4} sm={4}>
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link style={{ color: handleMainTabValidate() ? 'red' : '' }} eventKey="main">Основни данни</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link style={{ color: handleSecondTabValidate() ? 'red' : '' }} eventKey="tab2">Декларираща страна</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link style={{ color: handleThirdTabValidate() ? 'red' : '' }} eventKey="tab3">Лице за контакт</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                <Col xl={8} sm={8}>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="main">
                                            <Row>
                                                <Col className="mb-3">
                                                    <DatePicker
                                                        placeholder="Месец*"
                                                        name="period"
                                                        value={state.data.period ? moment(state.data.period).format('YYYY-MM-DD') : ''}
                                                        onChange={handleInputChange}
                                                        isInvalid={Boolean(validations && validations.period)}
                                                        error={validations && validations.period && (validations.period[0] || validations.period)}
                                                        disabled={state.data.id ? true : false}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col className="mb-3">
                                                    <StoreAndDepot
                                                        depotInputName="depot_id[]"
                                                        selected={selectedDepots}
                                                        onChange={handleDepotChange}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col className="mb-3">
                                                    <Autocomplete
                                                        inputPlaceholder="Доставчици"
                                                        url="autocomplete/suppliers"
                                                        inputIdName="supplier_id"
                                                        selectedIds={selectedSuppliers}
                                                        onChange={handleChangeSupplier}
                                                        multiple
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col className="mb-3">
                                                    <FloatingLabel label="Само артикули с интрастат номер">
                                                        <Form.Select
                                                            name="with_intrastat_num"
                                                            value={state.data?.with_intrastat_num || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations?.with_intrastat_num)}
                                                            disabled={state.data.id ? true : false}
                                                        >
                                                            <option value=""></option>
                                                            <option value="0">НЕ</option>
                                                            <option value="1">ДА</option>
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations?.with_intrastat_num && (validations.with_intrastat_num[0] || validations.with_intrastat_num)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col className="mb-3">
                                                    <FloatingLabel label="ID на декларацията">
                                                        <Form.Control
                                                            type="number"
                                                            min="1"
                                                            step="1"
                                                            placeholder="ID на декларацията"
                                                            name="declaration_id"
                                                            value={state.data.declaration_id || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.declaration_id)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.declaration_id && (validations.declaration_id[0] || validations.declaration_id)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col className="mb-3">
                                                    <FloatingLabel label="Интрастат оператор">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Интрастат оператор"
                                                            name="declaration_psi_id"
                                                            value={state.data.declaration_psi_id || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.declaration_psi_id)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.declaration_psi_id && (validations.declaration_psi_id[0] || validations.declaration_psi_id)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col className="mb-3">
                                                    <FloatingLabel label="Тип индентификатор за оператора">
                                                        <Form.Select
                                                            name="declaration_psi_type_id"
                                                            value={state.data?.declaration_psi_type_id || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations?.declaration_psi_type_id)}
                                                        >
                                                            <option value=""></option>
                                                            {partyTypes.map(t =>
                                                                <option key={t.id} value={t.id}>{t.name}</option>
                                                            )}
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations?.declaration_psi_type_id && (validations.declaration_psi_type_id[0] || validations.declaration_psi_type_id)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>

                                        </Tab.Pane>
                                        <Tab.Pane eventKey="tab2">
                                            <Row>
                                                <Col className="mb-3">
                                                    <FloatingLabel label="Идентификационен код">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Идентификационен код"
                                                            name="party_id"
                                                            value={state.data.party_id || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.party_id)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.party_id && (validations.party_id[0] || validations.party_id)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="mb-3">
                                                    <FloatingLabel label="Тип на идентификационния код">
                                                        <Form.Select
                                                            name="party_type_id"
                                                            value={state.data?.party_type_id || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations?.party_type_id)}
                                                        >
                                                            <option value=""></option>
                                                            {partyTypes.map(t =>
                                                                <option key={t.id} value={t.id}>{t.name}</option>
                                                            )}
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations?.party_type_id && (validations.party_type_id[0] || validations.party_type_id)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="mb-3">
                                                    <FloatingLabel label="Улица и номер">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Улица и номер"
                                                            name="party_street_number"
                                                            value={state.data.party_street_number || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.party_street_number)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.party_street_number && (validations.party_street_number[0] || validations.party_street_number)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="mb-3">
                                                    <FloatingLabel label="Град">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Град"
                                                            name="party_city"
                                                            value={state.data.party_city || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.party_city)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.party_city && (validations.party_city[0] || validations.party_city)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="mb-3">
                                                    <FloatingLabel label="Пощенски код">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Пощенски код"
                                                            name="party_postal_code"
                                                            value={state.data.party_postal_code || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.party_postal_code)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.party_postal_code && (validations.party_postal_code[0] || validations.party_postal_code)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="mb-3">
                                                    <FloatingLabel label="Телефонен номер">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Телефонен номер"
                                                            name="party_phone_number"
                                                            value={state.data.party_phone_number || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.party_phone_number)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.party_phone_number && (validations.party_phone_number[0] || validations.party_phone_number)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="mb-3">
                                                    <FloatingLabel label="Факс">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Факс"
                                                            name="party_fax_number"
                                                            value={state.data.party_fax_number || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.party_fax_number)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.party_fax_number && (validations.party_fax_number[0] || validations.party_fax_number)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="tab3">
                                            <Row>
                                                <Col className="mb-3">
                                                    <FloatingLabel label="Име">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Име"
                                                            name="party_contact_person_name"
                                                            value={state.data.party_contact_person_name || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.party_contact_person_name)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.party_contact_person_name && (validations.party_contact_person_name[0] || validations.party_contact_person_name)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="mb-3">
                                                    <FloatingLabel label="Улица и номер">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Улица и номер"
                                                            name="party_contact_person_street_number"
                                                            value={state.data.party_contact_person_street_number || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.party_contact_person_street_number)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.party_contact_person_street_number && (validations.party_contact_person_street_number[0] || validations.party_contact_person_street_number)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="mb-3">
                                                    <FloatingLabel label="Град">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Град"
                                                            name="party_contact_person_city"
                                                            value={state.data.party_contact_person_city || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.party_contact_person_city)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.party_contact_person_city && (validations.party_contact_person_city[0] || validations.party_contact_person_city)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="mb-3">
                                                    <FloatingLabel label="Пощенски код">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Пощенски код"
                                                            name="party_contact_person_postal_code"
                                                            value={state.data.party_contact_person_postal_code || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.party_contact_person_postal_code)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.party_contact_person_postal_code && (validations.party_contact_person_postal_code[0] || validations.party_contact_person_postal_code)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="mb-3">
                                                    <FloatingLabel label="Телефонен номер">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Телефонен номер"
                                                            name="party_contact_person_phone_number"
                                                            value={state.data.party_contact_person_phone_number || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.party_contact_person_phone_number)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.party_contact_person_phone_number && (validations.party_contact_person_phone_number[0] || validations.party_contact_person_phone_number)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="mb-3">
                                                    <FloatingLabel label="Мобилен телефон">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Мобилен телефон"
                                                            name="party_contact_person_mobile_phone_number"
                                                            value={state.data.party_contact_person_mobile_phone_number || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.party_contact_person_mobile_phone_number)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.party_contact_person_mobile_phone_number && (validations.party_contact_person_mobile_phone_number[0] || validations.party_contact_person_mobile_phone_number)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="mb-3">
                                                    <FloatingLabel label="Е-поща">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Е-поща"
                                                            name="party_contact_person_email"
                                                            value={state.data.party_contact_person_email || ''}
                                                            onChange={handleInputChange}
                                                            isInvalid={Boolean(validations && validations.party_contact_person_email)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {validations && validations.party_contact_person_email && (validations.party_contact_person_email[0] || validations.party_contact_person_email)}
                                                        </Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>
                                            </Row>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Отказ
                        </Button>
                        <SaveButton
                            loading={state.loading}
                            className="save"
                        />
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default forwardRef(AddOrEdit);
