import { Dropdown } from 'react-bootstrap';

function DeviceOptions(props) {
    return (
        <>
            <Dropdown.Item onClick={e => props.handleSelect(props.data)}>
                Избор
            </Dropdown.Item>
            <Dropdown.Item onClick={e => props.handlePreview(props.data)}>
                Преглед
            </Dropdown.Item>
        </>
    )
}

export default DeviceOptions;
